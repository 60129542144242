/*
#############################################################################
# Name : aboutKitchen.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to add and display the kitchen details of the chef
#############################################################################
*/
import React, { Component } from "react";
import axios from "axios";
import {
  getUserSummaryAPI,
} from "../../core/services/APIservice";
import { registrationPackageAPI, getChefRazorPayOrderIDAPI,chefOrderPaymentDetailAPI } from "../../core/services/Phase2APIservice";
import { withRouter } from "react-router-dom";
import config from "../../config/config";
// React Notification
import { NotificationManager } from "react-notifications";
import Recaptcha from "react-google-invisible-recaptcha";
import $ from "jquery";

class RegSubscriptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reg_actual_package_price: 0,
      reg_package_price: 0,
      reg_package_name: '',
	  isProcessing: false,
      mar_actual_package_price: 0,
      mar_package_price: 0,
      mar_package_name: '',
      orderAmount: 0,
      orderID: '',
	  razorPayDetail: [],
	  paymentStatus:'Failed',
	  regPackage: '',
	  marPackage: '',
	  marPackageDis: true,
	  goToThankYouPage: false,
	  discount_start_date: new Date(new Date().setDate(new Date().getDate())),
	  discount_end_date: new Date(new Date().setDate(new Date().getDate())),
      currentDate: new Date(new Date().setDate(new Date().getDate())),
    };
  }

  componentDidMount() {
    this.getChefPackageDetails();       // Kithcen Details data is fetched
	this.getPackageDetails();
    window.scrollTo(0, 0);
  }
  
/* package details */	
	getPackageDetails = () => {
		var userId = localStorage.getItem("userID");
		var token = localStorage.getItem("accessToken");
		var package_type = 'Registration Package';
		return registrationPackageAPI(userId, token)
		  .then((response) => {
			if (response.data.status === 200) {
				if(response.data['data'] != null){
					this.setState({
						reg_actual_package_price: response.data.data.registration_actual_package_price == undefined ? '' : response.data.data.registration_actual_package_price,
						reg_package_price: response.data.data.registration_package_price == undefined ? '' : response.data.data.registration_package_price,
						mar_actual_package_price: response.data.data.marketing_actual_package_price == undefined ? '' : response.data.data.marketing_actual_package_price,
						mar_package_price: response.data.data.marketing_package_price == undefined ? '' : response.data.data.marketing_package_price,
					});
				}else{
					this.setState({
						goToThankYouPage: true,
					});
				}
			}
		})
		.catch((error) => {
			/*  console.log(error); */
		});
	};
/* end package details */	

/* getChefPackageDetails function starts here */
getChefPackageDetails() {
	var userId = localStorage.getItem('userID');
	var accessToken = localStorage.getItem('accessToken');
	return getUserSummaryAPI(userId, accessToken).then((response) => {
		if (response.data['status'] === 200) {
			if(response.data['data'] != null){
				if(response.data.data.registration_package != ''){
					this.setState({
						marPackageDis: false,
						goToThankYouPage: true,
					});
				}
				this.setState({
					regPackage: response.data['data']['registration_package'], 
					marPackage: response.data['data']['marketing_package']
				});
			}
		}
	}).catch((error) => {
	})
}
/* getChefPackageDetails function ends here */

/* onPreviousBusinessSubmit function starts here */
onPreviousBusinessSubmit = (event) => {
event.preventDefault();
var userId = localStorage.getItem("userID");
var accessToken = localStorage.getItem("accessToken");
return getUserSummaryAPI(userId, accessToken)
  .then((response) => {
	if (response.data["status"] === 200) {
	  this.props.parentCallback(response.data["data"]);
	  localStorage.setItem("currentStatus", 6);
	}
  })
  .catch((error) => {});
};
/* onPreviousBusinessSubmit function ends here */
 
/* getPayRegPackage function starts here */
getPayRegPackage = (event) => {
	var userId = localStorage.getItem("userID");
	var token = localStorage.getItem("accessToken");
	let amount =  parseFloat(this.state.reg_package_price) * 100;
	let packagename =  'Registration Package';
	return getChefRazorPayOrderIDAPI(userId, token, amount, packagename)
	.then((response) => {
		if (response.data.data.status == "created") {
			this.setState({
				orderAmount: response.data.data.amount,
				orderID: response.data.data.id,
			});
			this.openCheckout('Registration Package');
		} else {
			if (response.status === 401) {
				NotificationManager.error(
					"Session expired. Please log in.",
					"Error!"
				);
				this.props.removeUserData();
				this.props.removeChefData();
				this.props.emptyCart();
				this.props.removeTimeSlot();
				localStorage.clear();
				this.props.history.push("diner-signin-with-otp");
			}
		}
	})
	.catch(function (error) {});
	event.preventDefault();
	/* this.setState({
		goToThankYouPage: true,
	}); */
	//localStorage.setItem('currentStatus', 7);
};
/* getPayRegPackage function ends here */
  
/* getPayMarPackage function starts here */
getPayMarPackage = (event) => {
	var userId = localStorage.getItem("userID");
	var token = localStorage.getItem("accessToken");
	let amount =  parseFloat(this.state.mar_package_price) * 100;
	let packagename =  'Marketing Package';
	return getChefRazorPayOrderIDAPI(userId, token, amount, packagename)
	.then((response) => {
		if (response.data.data.status == "created") {
			this.setState({
				orderAmount: response.data.data.amount,
				orderID: response.data.data.id,
			});
			this.openCheckout('Marketing Package');
		} else {
			if (response.status === 401) {
				NotificationManager.error(
					"Session expired. Please log in.",
					"Error!"
				);
				this.props.removeUserData();
				this.props.removeChefData();
				this.props.emptyCart();
				this.props.removeTimeSlot();
				localStorage.clear();
				this.props.history.push("diner-signin-with-otp");
			}
		}
	})
	.catch(function (error) {});
	event.preventDefault();
	//localStorage.setItem('currentStatus', 7);
};
/* getPayMarPackage function ends here */

/* getChefSubscribedPackage function starts here */
getChefSubscribedPackage = (paymentId, orderId, signature, packagename) => {

	var userId = localStorage.getItem("userID");
	var token = localStorage.getItem("accessToken");
	this.setState({
		isProcessing: true,
	});
	let orderData = {
		transaction_id: paymentId,
		amount_due: this.state.orderAmount / 100,
		amount_paid: this.state.orderAmount / 100,
		order_id: orderId,
		status: "created",
		//api_response: JSON.stringify(this.state.razorPayDetail),
		api_response: this.state.razorPayDetail,
		payment_api_status: this.state.paymentStatus,
		signature: signature,
		packagename: packagename,
	};

	return chefOrderPaymentDetailAPI(userId, token, orderData)
	.then((response) => {
		if (response.data.status == 200) {
				NotificationManager.success(
				response.data["message"],
				"Successful!",
				2000
			);
			if(packagename == 'Registration Package'){
				this.props.history.push("/thank-you");
			}else{
				window.location.reload();
			}
			
		} else {
			this.setState({
				isProcessing: false,
			});
			NotificationManager.error(response.data["message"], "Error!");
			window.location.reload();
		}
	})
	.catch(function (error) {
		this.setState({
			isProcessing: false,
		});
	});
};
/* getChefSubscribedPackage function ends here */
  
/* openCheckout function starts here */
openCheckout = (packagename) => {
	let paymentOptions = {
		order_id: this.state.orderID,
		key: config.razorKey, // 2000 paise = INR 20, amount in paisa
		name: "Chef Pin",
		description: "Purchase Description",
		image: "assets/images/icons/header-logo.svg",
		handler: (response) => {
			this.setState({
				razorPayDetail: response,
			});
			var redirect_url;
			if (
				typeof response.razorpay_payment_id == "undefined" ||
				response.razorpay_payment_id < 1 ||
				!response.razorpay_payment_id
			) {
				/* redirect_url = "/checkout"; */
			} else {
				this.setState(
				{
					paymentStatus: "Success",
				},
				() =>
					this.getChefSubscribedPackage(
						response.razorpay_payment_id,
						response.razorpay_order_id,
						response.razorpay_signature,
						packagename,
					)
				);
			}
		},
		prefill: {
			name: localStorage.getItem("userName"),
			email: localStorage.getItem("userEmail"),
			mobile: localStorage.getItem("userMobile"),
		},
		notes: {
			u_id: localStorage.getItem("userID"),
			packagename: packagename,
		},
		theme: {
			color: "#F37254",
		},
	};

	let rzp = new window.Razorpay({ ...paymentOptions });
	rzp.on("payment.failed", (response) => {
		this.setState(
		{
			paymentStatus: "Failed",
			razorPayDetail: response,
		},
		() => {
			if (response.error) {
				var userId = localStorage.getItem("userID");
				var token = localStorage.getItem("accessToken");
				let orderData = {
					transaction_id: response.error.metadata
					? response.error.metadata.payment_id
					: "",
					amount_due: this.state.orderAmount / 100,
					amount_paid: this.state.orderAmount / 100,
					order_id: this.state.orderID,
					status: "created",
					//api_response: JSON.stringify(this.state.razorPayDetail),
					api_response: this.state.razorPayDetail,
					payment_api_status: this.state.paymentStatus,
					payment_api_response: response,
					signature: "",
					packagename: packagename,
				};
				NotificationManager.error(response.error.description, "Error!");
				setTimeout(() => {
				  window.location.reload();
				}, 100)
			} else {
				this.getChefSubscribedPackage(
					response.error.metadata.payment_id,
					response.error.metadata.order_id,
					"",
					packagename,
				);
			}
			rzp.close();
		});
	});
	rzp.open();
};
/* openCheckout function ends here */

/* getThankYouPage function starts here */
getThankYouPage = (event) => {
	event.preventDefault();
	if (this.state.goToThankYouPage === true) {
		var userId = localStorage.getItem('userID');
		var accessToken = localStorage.getItem('accessToken');
		return getUserSummaryAPI(userId, accessToken)
			.then((response) => {
			if (response.data['status'] === 200) {
				this.props.parentCallback(response.data['data']);
				localStorage.setItem('currentStatus', 7);
			}
		})
		.catch((error) => {
			/* console.log(error); */
		});
	} else {
		NotificationManager.error('You have not subscribed the package', 'Error!');
	}
};
/* getThankYouPage function ends here */

/* onPreviousKitchenSubmit function starts here */
onPreviousKitchenSubmit = () => {
	localStorage.setItem("currentStatus", 5);
};
/* onPreviousKitchenSubmit function ends here */

/* onResolved function starts here */
onResolved() {
	alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
}
/* onResolved function ends here */

getRegPackage() {
    return <div>
		{this.state.reg_actual_package_price != this.state.reg_package_price ? 
			(<span>Registration Fee Rs.<del>{this.state.reg_actual_package_price}</del> Rs.{this.state.reg_package_price}<br/>
			(includes GST, payable annually)</span>
		) : (
			<span>Registration Fee Rs.{this.state.reg_package_price}<br/>
			(includes GST, payable annually)</span>
		)}
		</div>
}

getMarPackage() {
	return <div>
		{this.state.mar_actual_package_price != this.state.mar_package_price ? 
			(<span>Marketing Booster Pack Fee Rs.<del>{this.state.mar_actual_package_price}</del><br/> Special Offer Rs.{this.state.mar_package_price} per quarter<br/>
			(includes GST. Payable in advance)</span>
		) : (
			<span>Marketing Booster Pack Fee Rs.{this.state.mar_package_price} per quarter<br/>
			(includes GST. Payable in advance)</span>
		)}
		</div>
}

getBackgroundColor() {
    return <div className="row">
		{this.state.reg_package_price != '' ? (
		<div className={this.state.mar_package_price == '' ? "col-md-12 col-lg-12 col-xl-12" : "col-md-6 col-lg-6 col-xl-6"}>
		  <div className="form-group text-center">
			<center>
			<h4 className="">
				<span className="text-uppercase text-center text-md-center">
				  Registration Package
				</span>
			</h4>
			{/*<img
				src="/assets/images/chef_reg.png"
				alt="Chef registration package"
				className="img-fluid"/>*/}
				<div class="section-package-banner">
					<div class="section-package">
						<h3><center>Chef Pin Registration Benefits</center></h3>
						<b>Get Your Digital Presence</b>
						<ul>
							<li>Dedicated listing on the Chef Pin app and website</li>
							<li>Digitized Menu, shareable by a simple link</li>
							<li>Review & Rating feature (including for offline orders)</li>
							<li>Analytics - Complete backend admin panel with full control </li>
							<li>Visibility in app collections & search results</li>
							<li>Unlimited menu items & images</li>
						</ul>
						<b>Get Seamless Payment and Delivery</b>
						<ul>
							<li>Online ordering feature on the Chef Pin app @ 10% commission only (GST extra)</li>
							<li>Complete delivery management</li> 
							<li>Payment gateway integration</li>
							<li>SMS, email & in-app notifications for all orders</li> 
						</ul>
						<b>Get Chef Pin Expert Support</b>
						<ul>
							<li>Expert assistance for your orders</li>
							<li>Menu management and advice</li>
							<li>Guidance for acquiring FSSAI</li>
							<li>Handling of guest complaints</li> 
						</ul>
						<b>Get Promotional Support</b>
						<ul>
							<li>Benefit from Chef Pin run promotions & discounts</li>
							<li>Chef Pin branded stickers and tape (set quantity) </li>
							<li>In-app promotion notifications</li>
						</ul>
						<b>
							<center>
								{this.getRegPackage()}
							</center>
						</b>
					</div>
				</div>
				<p><br/>
				{this.state.regPackage == '' ? (
				
					<button
					  type="button"
					  className="btn"
					  onClick={this.getPayRegPackage}
					>
					  Pay Rs {this.state.reg_package_price}
					</button>
				  
				) : <button
					  type="button"
					  className="btn"
					  disabled='disabled'
					>
					  Activated till {this.state.regPackage}
					</button>}
				</p>
			</center>
		  </div>
		</div>
	):null}
	{this.state.mar_package_price != '' ? (
		<div className="col-md-6 col-lg-6 col-xl-6">
		  <div className="form-group text-center">
			<center>
			<h4 className="">
				<span className="text-uppercase text-center text-md-center">
				  Marketing Package
				</span>
			</h4>
			 {/*<img
					src="/assets/images/chef_mar.png"
					alt="Chef marketing package"
				className="img-fluid"/>*/}
				<div class="section-package-banner">
					<div class="section-package">
						<h3><center>Chef Pin Marketing Booster Pack</center></h3>
						<p><center>Enhance your Chef Pin listing with the Marketing Booster Pack. Get 7 amazing benefits which will make your listing stand out from the crowd and get you qualified leads to grow your home chef business!<br/><br/></center></p>
						<ul>
							<li>Assisted Review & Rating feature</li>
							<li>Leads from Abandoned carts, Food /cuisine/ Location, Party orders and Tiffin inquiry </li>
							<li>WhatsApp chat button on your page</li>
							<li>Promotion on Chef Pin’s whatsapp marketing campaign.</li>
							<li>Promotion on Chef Pin’s Social Media pages.</li>
							<li>Online/Web presence</li>
							<li>Shareable WhatsApp creatives with personalized discount</li>
						</ul><br/>
						<b>
							<center>
								{this.getMarPackage()}
							</center>
						</b>
					</div>
				</div>
				<p><br/>
				{this.state.marPackage == '' ? (
				
					<button
					  type="button"
					  className="btn"
					  disabled={this.state.marPackageDis}
					  onClick={this.getPayMarPackage}
					>
					  Pay Rs {this.state.mar_package_price}
					</button>
				  
				) : <button
					  type="button"
					  className="btn"
					  disabled='disabled'
					>
					  Activated till {this.state.marPackage}
					</button>}
				</p>
			</center>
		  </div>
		</div>
	):null}
	</div>
}

  render() {
    return (
      <div className="p-0">
        <div className="abt_form">
          <div className="inner">
            <form>
				{(this.state.mar_package_price == '' && this.state.reg_package_price == '') ? (
				 <div className="row">
					<div className="col-md-12 col-lg-12 col-xl-12">
						<div className="form-group text-center">
							<h4 className="">
								<span className="text-uppercase text-center text-md-center">
								  No Package found!
								</span>
							</h4>
						</div>
					</div>
				</div>
				): this.getBackgroundColor()}				
            </form>
          </div>
		  {localStorage.getItem('userVerified') == null ||
          localStorage.getItem('userVerified') == 'null' ? (
          <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100">
            <button
              type="submit"
              className="btn previous_btn"
              onClick={this.onPreviousBusinessSubmit}
            >
              PREVIOUS
            </button>
            {/*<button
              type="submit"
              className="btn"
              disabled={this.state.loading}
              onClick={this.getThankYouPage}
            >
              save and next
            </button>*/}
          </div>
		) : null}
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={config.siteKey}
            onResolved={this.onResolved}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(RegSubscriptionPage);
