import { URLs } from "../Url";

export const GetSubsctribe = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/subscribe-data",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const PostSubsctribe = async (name, email) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      email: email,
    });
    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/subscribe-create",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const Deletesubscribe = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/subscribe-delete/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
