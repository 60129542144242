import { SAVE_USER_DETAILS, REMOVE_USER_DETAILS } from "../actions/types";

const initialState = { items: [] }
export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_USER_DETAILS:
            return {
                items: action.payload.userData
            }

            case REMOVE_USER_DETAILS:
            return {
                items: action.payload.userData
            }

        default:
            return state;
    }
}