/*
#############################################################################
# Name : vipHomeChef.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the vip home chef data
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../components/dinnerHeader";
import DinerFooter from "../../../components/dinerFooter";
import WhatsAppButton from "../../../components/whatsappButton";
import LoadingDiv from "../../../components/LoadingDiv";
import {
  vipHomeChefListingAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
} from "../../../core/services/Phase2APIservice";
import Slider from "react-slick";
import DinerMenuTab from "../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../../config/config";
import PromotedBadge from "../../../components/PromotedBadge";
class VipHomeChef extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
      },
      vipChefArr: [],
      errorStatus: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getVipChef(); // Vip chef data is fetched
  }

  /* getVipChef function starts here */
  getVipChef = async () => {
    var city = localStorage.getItem("selectedLocationName");
    var pincode = localStorage.getItem("pincode");
    var latitude = localStorage.getItem("latitude");
    var longitude = localStorage.getItem("longitude");
    var userId = "";
    if (localStorage.getItem("userID")) {
      userId = localStorage.getItem("userID");
    }
    try {
      const response = await vipHomeChefListingAPI(
        city,
        pincode,
        latitude,
        longitude,
        userId
      );
      if (response.data.status === 200) {
        this.setState({
          vipChefArr: response.data.data.vip_chef_list,
          isLoading: false,
        });
        if (response.data.data.vip_chef_list.length === 0) {
          this.setState({
            errorStatus: true,
          });
        } else {
          this.setState({
            errorStatus: false,
          });
        }
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      /* console.log(error); */
    }
  };
  /* getVipChef function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getVipChef();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getVipChef();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
  };
  /* getInerPage function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <div>
            <section className="shoe_all_bg text-white">
              <div className="container">
                <h4>Must Try Meals</h4>
              </div>
            </section>
            <section className="selected_you selected_for_you see_space bg_none">
              <div className="container">
                {/* <a href="#" className=" filter_btn"><img src="assets/images/icons/filter.svg" alt="Right Arrow" /> Filters</a> */}
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <div className="row">
                      {this.state.vipChefArr.map((vip, i) => {
                        return (
                          <div
                            className="col-sm-6 col-lg-3 col-xl-3"
                            key={"rdsr" + i}
                          >
                            <div
                              className={
                                vip.is_chef_available === "1"
                                  ? "card"
                                  : "card unavailable-chef-card"
                              }
                            >
                              <div
                                className="direction"
                                onClick={() => {
                                  localStorage.getItem("userID") !== null
                                    ? vip.is_bookmarked == 1
                                      ? this.removeBookmark(vip.chef_id)
                                      : this.addBookmark(vip.chef_id)
                                    : this.storageHandler();
                                }}
                              >
                                <img
                                  src={
                                    vip.is_bookmarked == 1
                                      ? "/assets/images/icons/bookmark.svg"
                                      : "/assets/images/icons/bookmark-empty.svg"
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                onClick={() =>
                                  this.getInerPage(
                                    vip.chef_id,
                                    vip.slug_url,
                                    vip.name,
                                    vip.is_chef_available
                                  )
                                }
                              >
                                {vip.promoted == 1 ? <PromotedBadge /> : null}
                                <div className="img_wrap">
                                  {vip.is_offer_available === "1" ? (
                                    <div className="offer">OFFER</div>
                                  ) : null}
                                  {vip.profile_image != null ? (
                                    <img
                                      src={vip.profile_image}
                                      alt=" Selected for You "
                                      className=" img-fluid "
                                    />
                                  ) : (
                                    <img
                                      src="/assets/images/noimage.png "
                                      alt=" Selected for You "
                                      className=" img-fluid "
                                    />
                                  )}
                                </div>
                                <div className="text_wrap">
                                  <h6>
                                    {vip.verified == 1 ? (
                                      <span className="correct_icn">
                                        {vip.brand_name !== ""
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    ) : (
                                      <span>
                                        {vip.brand_name !== ""
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    )}
                                    {vip.total_ratings !== 0 ? (
                                      <span className="star">
                                        {vip.total_ratings}
                                        <span className="grey">/ 5</span>
                                      </span>
                                    ) : null}
                                  </h6>
                                  {vip.cuisines == "" ? (
                                    "-"
                                  ) : (
                                    <p className="sub_title">{vip.cuisines}</p>
                                  )}
                                  <ul>
                                    <li className="location_icn">
                                      {vip.locality == null &&
                                      vip.locality === "" ? null : (
                                        <span>{vip.locality}, </span>
                                      )}{" "}
                                      {vip.distance == null
                                        ? ""
                                        : parseFloat(vip.distance).toFixed(
                                            1
                                          )}{" "}
                                      kms
                                    </li>
                                    {vip.is_takeaway_available == 1 ? (
                                      <li>Available for Delivery & Takeaway</li>
                                    ) : (
                                      <li>Available for Delivery</li>
                                    )}
                                  </ul>
                                  {vip.special_dishes != "" ? (
                                    <p>
                                      {vip.name} is known for cooking the best{" "}
                                      {vip.special_dishes}.
                                    </p>
                                  ) : (
                                    <p>
                                      {vip.name} is known for cooking the best
                                      Dosa, Idli's, Vada's etc.
                                    </p>
                                  )}

                                  <div className={
                                      vip.gamification_data == null
                                        ? "badge_slide border-0"
                                        : "badge_slide"
                                    }>
                                    <Slider
                                      className="inner_slider"
                                      {...this.state.settings}
                                    >
                                      {vip.gamification_data != null ? (
                                        vip.gamification_data.map((item, i) => {
                                          return (
                                            <div
                                              className="box img-slider1"
                                              key={"grkw" + i}
                                            >
                                              <img
                                                src={item.image}
                                                alt={item.title}
                                              />
                                              <span className="text">
                                                {item.desc}
                                              </span>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div className="box">
                                          <span className="text">&nbsp;&nbsp;</span>
                                        </div>
                                      )}
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {this.state.errorStatus ? (
                      <div className="not_found">
                        <div className="container text-center">
                          <img
                            src="assets/images/icons/not-found.svg"
                            alt="Not Found"
                            className="img-fluid"
                          />
                          <h6>SORRY! NO RESULTS FOUND.</h6>
                          <p>
                            We can’t find anything related to your <br />{" "}
                            search. Try a different search.
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default VipHomeChef;
