import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from "../../components/whatsappButton";
import { Link, Redirect } from "react-router-dom";
import ChefAccountSidebar from "./chefAccountSidebar";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Moment from "moment";
import LoadingDiv from "../../components/LoadingDiv";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import {
  getBlogUploadAPI,
  getBlogListAPI,
} from "../../core/services/Phase2APIservice";
import Pagination from "react-js-pagination";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class ChefBlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      isLoading: true,
      blogTitle: "",
      blogFileData: "",
      blogFileName: "",
      blogDetails: [],
      activePage: 1,
      totalRecords: 10,
      formErrors: {
        blogTitle: "",
      },
      errorBlog: "",
      errorBlogUpload: "",
      blogFlag: false,
      uploadingBlogFlag: false,
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmitBlog = this.onSubmitBlog.bind(this);
  }

  componentDidMount() {
    this.getBlogList();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, isLoading: true }, () => {
      this.getBlogList();
    });
  }

  getBlogList = () => {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getBlogListAPI(userId, accessToken, currentPage)
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response.data.total_records);
          this.setState({
            blogDetails: response.data.data,
            totalRecords: response.data.total_records,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onUploadBlog = (event) => {
    this.setState({
      blogFlag: false,
    });
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;
    console.log(file);
    for (var x = 0; x < file.length; x++) {
      if (!file[x].name.match(/\.(doc|docx|pdf|DOC|DOCX|PDF)$/)) {
        this.setState({
          errorBlogUpload:
            "Please select valid file type. " +
            file[x].name +
            " is invalid type",
        });
        return false;
      } else {
        this.setState({ errorBlogUpload: "" });
      }
    }
    this.setState({
      blogFileData: event.target.files[0],
      blogFileName: event.target.files[0]["name"].slice(-20),
    });
  };

  onChangeInput = (e) => {
    this.setState({
      blogFlag: false,
    });
    // e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "blogTitle":
        formErrors.blogTitle = value === "" ? "Title is required" : "";
        break;
    }

    this.setState({ formErrors, [name]: value });
  };

  onSubmitBlog = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");

    if (this.state.blogFileName === "") {
      this.setState({ errorBlog: "Blog File is required" });
    } else {
      this.setState({
        errorBlog: "",
      });
    }

    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.blogTitle === "") {
      formErrors.blogTitle = "Title is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.setState({
        uploadingBlogFlag: true,
      });
      return getBlogUploadAPI(
        userId,
        accessToken,
        this.state.blogFileData,
        this.state.blogFileName,
        this.state.blogTitle
      )
        .then((response) => {
          if (response.data.status === 200) {
            console.log(response);
            this.setState({
              blogTitle: "",
              blogFileName: "",
              blogFileData: "",
              blogFlag: true,
              uploadingBlogFlag: false,
            });
            NotificationManager.success("Blog Added Successfully", "Success");
            this.getBlogList();
          } else {
            NotificationManager.error("Failed to save the blog", "Error!");
            this.setState({
              blogFlag: false,
              uploadingBlogFlag: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("error");
    }
  };

  getBlogClear = () => {
    this.setState({
      blogTitle: "",
      blogFileName: "",
      blogFileData: "",
    });
  };

  render() {
    if (this.props.userData.userType !== "Chef") {
      return (
        <Redirect
          to={
            "/signin-with-otp?redirect=" +
            this.props.location.pathname.split("/")[1]
          }
        />
      );
    }
    const { isLoading, deliveredOrders, formErrors } = this.state;
    const { history, mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        <section className="chef_flow registration bussiness">
          <div className="container">
            <div className="row justify-content-center">
              <ChefAccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="chef_right diner_my_acc">
                  <div className="sticky_wrap">
                    <h6 className="res_back_btn_wrap">
                      <a
                        onClick={() => this.props.removeMobileView()}
                        className="res_back_btn"
                      >
                        <img
                          src="assets/images/icons/left-arrow-black.svg"
                          alt="left-arrow-black"
                        />
                      </a>
                      My Blogs
                    </h6>
                  </div>
                  {/* <div className="tab-content" id="myTabContent"> */}
                  <div className="outer">
                    <div className="inner current_orders_card border-0">
                      <div className="order_wrap">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xl-10">
                            <div className="form-group">
                              <label className="d-block">
                                Enter title for your blog*
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter title"
                                name="blogTitle"
                                maxLength="40"
                                value={this.state.blogTitle}
                                onChange={this.onChangeInput}
                              />
                              <small className="text-danger">
                                {formErrors.blogTitle}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12 col-xl-10">
                            <div className="form-group">
                              <label className="d-block">
                                Upload your file below*
                              </label>
                              <div className="fileUpload d-flex">
                                <button className="fileUploadBtn form-control d-inline-flex"></button>
                                <div className="d-inline-flex align-items-center form-control">
                                  {this.state.blogFileName === "" ? (
                                    <span className="file_text">
                                      Choose file
                                      <small>No file selected</small>
                                    </span>
                                  ) : (
                                    <span className="file_text">
                                      {this.state.blogFileName}
                                    </span>
                                  )}
                                  <input
                                    type="file"
                                    className="uploadlogo"
                                    name="blog_upload"
                                    accept=".doc, .docx, .pdf, .DOC, .DOCX, .PDF"
                                    onChange={this.onUploadBlog}
                                  />
                                </div>
                              </div>
                              <small className="form-text text-muted mb-3">
                                Only one file can be uploaded to this field.
                                <br />
                                64 MB limit. Allowed types: doc docx pdf.
                              </small>
                              {this.state.uploadingBlogFlag ? (
                                <small className="form-text red_000">
                                File is getting uploaded ...
                                </small>
                              ) : null}
                              {this.state.errorBlogUpload ? null : (
                                <small className="text-danger">
                                  {this.state.errorBlog
                                    ? this.state.errorBlog
                                    : ""}
                                </small>
                              )}
                              <small className="text-danger">
                                {this.state.errorBlogUpload
                                  ? this.state.errorBlogUpload
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100">
                              <button
                                type="submit"
                                className="btn previous_btn"
                                onClick={this.getBlogClear}
                              >
                                cancel
                              </button>
                              <button
                                type="submit"
                                className="btn"
                                onClick={this.onSubmitBlog}
                                disabled={this.state.uploadingBlogFlag}
                              >
                                submit
                              </button>
                            </div>
                          </div>
                        </div>
                        {this.state.blogFlag ? (
                          <p className="thank_you">
                            Thank you for submitting your blog. We will review
                            and upload this on the platform soon.
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="outer">
                    <div className="inner current_orders_card border-0">
                      <div className="order_wrap px-0 table_wrap pb-0">
                        <h6>Blog History</h6>
                        <div className=" d-none d-md-block">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>File</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.blogDetails
                                ? this.state.blogDetails.map((blog, b) => {
                                    return (
                                      <tr key={b}>
                                        <td style={{"line-break": "anywhere"}}>{blog.document_title}</td>
                                        <td style={{"line-break": "anywhere"}}>
                                          <a
                                            href={blog.document_path}
                                            target="_blank"
                                            download
                                          >
                                            {blog.document_name}
                                          </a>
                                        </td>
                                        <td>
                                          {Moment(blog.created_at).format("ll")}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : "No Data Found"}
                            </tbody>
                          </table>
                        </div>
                        <div className=" d-block d-md-none">
                          <ul className="table_list">
                            {this.state.blogDetails ? (
                              this.state.blogDetails.map((blog, bl) => {
                                return (
                                  <li key={bl}>
                                    <span className="titile">
                                      {blog.document_title}
                                    </span>
                                    <span className="file_name">
                                      <a
                                        href={blog.document_path}
                                        target="_blank"
                                        download
                                      >
                                        {blog.document_name.slice(-20)}
                                      </a>
                                      <span className="date">
                                        {Moment(blog.created_at).format("ll")}
                                      </span>
                                    </span>
                                  </li>
                                );
                              })
                            ) : (
                              <li>No Data Found</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalRecords}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(ChefBlog);
