/*
#############################################################################
# Name : trendingHomeChef.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the trending home chef data
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../components/dinnerHeader";
import DinerFooter from "../../../components/dinerFooter";
import WhatsAppButton from "../../../components/whatsappButton";
import LoadingDiv from "../../../components/LoadingDiv";
import { newBasicChefListingAPI,getBookMarkAPI,  deleteBookMarkAPI } from "../../../core/services/Phase2APIservice";
import DinerMenuTab from "../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import config from "../../../config/config";
import { NotificationManager } from "react-notifications";
class TrendingHomeChef extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      basicChefArr: [],
      errorStatus: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getBasicChef(); // Basic chef data is fetched
  }

  /* getBasicChef function starts here */
  getBasicChef = async () => {
    var city = localStorage.getItem("selectedLocationName");
    var pincode = localStorage.getItem("pincode");
    var latitude = localStorage.getItem("latitude");
    var longitude = localStorage.getItem("longitude");
    var userId = "";
    if (localStorage.getItem("userID")) {
      userId = localStorage.getItem("userID");
    }
    try {
      const response = await newBasicChefListingAPI(
        city,
        pincode,
        latitude,
        longitude,
        userId
      );
      if (response.data.status === 200) {
        this.setState({
          basicChefArr: response.data.data.basic_chef_list,
          isLoading: false,
        });
        if (response.data.data.basic_chef_list.length === 0) {
          this.setState({
            errorStatus: true,
          });
        } else {
          this.setState({
            errorStatus: false,
          });
        }
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      /* console.log(error); */
    }
  };
  /* getBasicChef function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
    // var urlParam = config.slugify(kitchenName);
    // if (brandName.length > 0) {
    //  urlParam =  config.slugify(brandName);
    // }
    // if(chefAvailable === "1"){
    //  this.props.history.push("/chef-inner-homepage/" + slugUrl);
    // }
  };
  /* getInerPage function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getBasicChef();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getBasicChef();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <div>
            <section className="shoe_all_bg text-white">
              <div className="container">
                <h4>Trending Home Chefs</h4>
              </div>
            </section>
            <section className="selected_you trending_home_chiefs see_space bg_none see_all_design">
              <div className="container">
                <div className="row">
                  {this.state.basicChefArr.map((basic, i) => {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-3"
                        key={"jyhj" + i}
                      >
                        <div
                          id={"card_" + basic.chef_id}
                          data-tag="basicCard"
                          className={
                            basic.is_chef_available === "1"
                              ? "card"
                              : "card unavailable-chef-card"
                          }
                          onClick={(e) => {
                            console.log(e.currentTarget.dataset.tag);
                            if (e.currentTarget.dataset.tag === "basicCard") {
                              this.getInerPage(
                                basic.chef_id,
                                basic.slug_url,
                                basic.name,
                                basic.is_chef_available
                              );
                            }
                          }}
                        >
                          <div className="img_wrap">
                            {basic.profile_image != null ? (
                              <img
                                src={basic.profile_image}
                                alt=" Selected for You "
                                className=" img-fluid "
                              />
                            ) : (
                              <img
                                src="assets/images/noimage.png"
                                alt=" Selected for You "
                                className=" img-fluid "
                              />
                            )}
                            <div
                              className="direction"
                              id={"bookmark_" + basic.chef_id}
                              data-tag="basicBookmark"
                              onClick={(e) => {
                                console.log(e.currentTarget.dataset.tag);
                                e.stopPropagation();
                                if (
                                  e.currentTarget.dataset.tag ===
                                  "basicBookmark"
                                ) {
                                  {
                                    localStorage.getItem("userID") !== null
                                      ? basic.is_bookmarked == 1
                                        ? this.removeBookmark(basic.chef_id)
                                        : this.addBookmark(basic.chef_id)
                                      : this.storageHandler();
                                  }
                                }
                              }}
                            >
                              <img
                                id={"bookmark_" + basic.chef_id}
                                src={
                                  basic.is_bookmarked == 1
                                    ? "/assets/images/icons/bookmark.svg"
                                    : "/assets/images/icons/bookmark-empty.svg"
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="text_wrap">
                            <h6>
                              <span className="correct_icn bg_none">
                                {basic.brand_name !== ""
                                  ? basic.brand_name
                                  : basic.name}
                              </span>
                            </h6>
                            {basic.cuisines === "" ? (
                              "-"
                            ) : (
                              <p className="sub_title">{basic.cuisines}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {this.state.errorStatus ? (
                  <div className="not_found">
                    <div className="container text-center">
                      <img
                        src="/assets/images/icons/not-found.svg"
                        alt="Not Found"
                        className="img-fluid"
                      />
                      <h6>SORRY! NO RESULTS FOUND.</h6>
                      <p>
                        We can’t find anything related to your <br /> search.
                        Try a different search.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>
          </div>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default TrendingHomeChef;
