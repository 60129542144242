import React, { useState, useEffect } from "react";
import "../BackendStyle.css";
import { URLs } from "../Url";
import {
  GetImageSlider,
  handlePostImage,
  ImageSliderUpload,
  ImageSliderUpdate,
  handledeleteImage,
  DeleteImageSlider,
} from "./config";
function ImageSlider() {
  const [data, setdata] = useState([]);
  const [title, settitle] = useState();
  const [description, setdescription] = useState();
  const [category, setcategory] = useState();
  const [redirectionUrl, setredirectionUrl] = useState();

  const [image, setimage] = useState();
  const [updateimage, setupdateimage] = useState();
  const [deleteimage, setdeleteimage] = useState();
  const [UpdateId, setUpdateId] = useState();

  const [isLoading, setisLoading] = useState(true);
  const [show, setShow] = useState(true);
  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    const getgteanData = await GetImageSlider();
    if (getgteanData.success == true) {
      console.log(getgteanData);
      setdata(getgteanData.data);
    }
  };
  const handlerDelete = async (id, imageId) => {
    const getgteanData = await DeleteImageSlider(id);
    if (getgteanData.success == true) {
      ImageDeleteHandler(imageId);
      handlerData();
    }
  };
  const handlerUpdateData = async (Getimage) => {
    const ImageCheck = updateimage == undefined ? image : Getimage;

    const getgteanData = await ImageSliderUpdate(
      UpdateId,
      title,
      description,
      ImageCheck,
      category,
      redirectionUrl
    );
    if (getgteanData.success == true) {
      handlerData();
      if (updateimage != undefined) {
        ImageDeleteHandler(deleteimage);
        UpdateVar();
      }
      setShow(true);
    }
  };
  const handleImage = async () => {
    if (image != undefined) {
      const GetData = await handlePostImage(image);
      if (GetData.success == true) {
        if (show == "update") {
          handlerUpdateData(GetData.image);
        } else {
          handlerUploadData(GetData.image);
        }
      }
    } else {
      alert("Image is mendatory");
    }
  };
  const handlerUploadData = async (Getimage) => {
    const getgteanData = await ImageSliderUpload(
      title,
      description,
      Getimage,
      category,
      redirectionUrl
    );
    if (getgteanData.success == true) {
      handlerData();
      setShow(true);
    }
  };
  const ImageDeleteHandler = async (imageDelete) => {
    const getResponse = await handledeleteImage(imageDelete);
    if (getResponse.success == true) {
      UpdateVar();
    }
  };
  const UpdateItem = (item) => {
    settitle(item.title);
    setdescription(item.description);
    setcategory(item.category);
    setimage(item.image);
    setdeleteimage(item.image);
    setUpdateId(item._id);
    setShow("update");
  };
  const UpdateVar = () => {
    settitle();
    setdescription();
    setcategory();
    setimage();
    setupdateimage();
    setdeleteimage();
    setUpdateId();
  };
  const onImageChange = (event) => {
    console.log(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setupdateimage(URL.createObjectURL(event.target.files[0]));
      setimage(event.target.files[0]);
    }
  };

  const changeFruit = (newFruit) => {
    setcategory(newFruit);
  };
  return (
    <>
      {show == "update" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control"
                onChange={(event) => changeFruit(event.target.value)}
                value={category}
                defaultValue={category}
              >
                <option value="">Select Category</option>
                <option value="FANTASTIC TALENT OF HOME CHEFS">
                  FANTASTIC TALENT OF HOME CHEFS
                </option>
                <option value="MEET THE TALENTED HOME CHEFS">
                  MEET THE TALENTED HOME CHEFS
                </option>
              </select>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                class="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              ></input>
            </div>

            <div className="form-group">
              <label>Redirection URL</label>
              <input
                class="form-control"
                value={redirectionUrl}
                onChange={(e) => setredirectionUrl(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChange}
              />
            </div>
            <div className="form-group">
              {updateimage == undefined ? (
                <img
                  src={URLs.images + image}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              ) : (
                <img
                  src={updateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              )}
            </div>
            <div className="form-group">
              <label>Image Alt</label>
              <input
                class="form-control"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></input>
            </div>
            <button
              onClick={() => {
                if (updateimage == undefined) {
                  handlerUpdateData();
                } else {
                  handleImage();
                }
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : show == "add" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control"
                onChange={(event) => changeFruit(event.target.value)}
                value={category}
                defaultValue={category}
              >
                <option value="">Select Category</option>
                <option value="FANTASTIC TALENT OF HOME CHEFS">
                  FANTASTIC TALENT OF HOME CHEFS
                </option>
                <option value="MEET THE TALENTED HOME CHEFS">
                  MEET THE TALENTED HOME CHEFS
                </option>
              </select>
              {/* <input
                class="form-control"
                value={category}
                onChange={(e) => setcategory(e.target.value)}
              ></input> */}
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                class="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              ></input>
            </div>

            <div className="form-group">
              <label>Redirection URL</label>
              <input
                class="form-control"
                value={redirectionUrl}
                onChange={(e) => setredirectionUrl(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                // onChange={(e) => setimage(e.target.files[0])}
                onChange={onImageChange}
              />
            </div>
            {updateimage == undefined ? null : (
              <div className="form-group">
                <img
                  src={updateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              </div>
            )}
            <div className="form-group">
              <label>Image Alt</label>
              <input
                class="form-control"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></input>
            </div>
            <button
              onClick={() => {
                handleImage();
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className="row table-invoice">
            <div className="col-sm-12">
              <div className="table-in-card">
                <div class="d-flex justify-content-between">
                  <h3>Image Slider Section</h3>

                  <button
                    type="button"
                    onClick={() => setShow("add")}
                    class="btn btn-primary btn-xs"
                  >
                    <small>Add</small>
                  </button>
                </div>
                {data.length > 0 ? (
                  <>
                    <br />

                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Redirection URL</th>
                            <th>Image Alt</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data == undefined
                            ? null
                            : data.map((item) => {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        src={URLs.images + item.image}
                                        alt=""
                                        className="img-fluid"
                                        style={{ width: 100, height: 100 }}
                                      />
                                    </td>
                                    <td>{item.title}</td>
                                    <td>{item.category}</td>
                                    <td>{item.redirectionUrl}</td>
                                    <td>{item.altImage}</td>

                                    <td>
                                      <div class="d-flex justify-content-evenly">
                                        <button
                                          type="button"
                                          onClick={() => UpdateItem(item)}
                                          class="btnnnnn btn-primary"
                                          style={{ marginRight: 10 }}
                                        >
                                          <i class="fa fa-edit"></i>
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handlerDelete(item._id, item.image)
                                          }
                                          class="btnnnnn btn-danger"
                                        >
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ImageSlider;
