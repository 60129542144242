/*
#############################################################################
# Name : paymentThankYou.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the thank you data after the payment done successfully
#############################################################################
*/
import React from "react";
import DinerFooter from "../../../../components/dinerFooter";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import WhatsAppButton from "../../../../components/whatsappButton";
import { connect } from "react-redux";
import { emptyCart } from "../../../../redux/actions/cartActions";
import { removeTimeSlot } from "../../../../redux/actions/timeSlotActions";
import { Redirect, Link } from "react-router-dom";
import {
  addOrderNumber,
  removeOrderNumber
} from "../../../../redux/actions/orderNumberActions";
import config from "../../../../config/config";

class PaymentThankYouPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCity:"",
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    }
  }
  componentDidMount() {
    this.props.emptyCart();                 // emptycard function
    this.props.removeTimeSlot();            // removeTimeSlot function
    this.props.removeOrderNumber()          // removeOrderNumber function
    localStorage.removeItem('dinerTakeawayChoice');
    localStorage.removeItem("cartRandomStringId");
    if(localStorage.getItem("selectedLocation")){
      var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
      this.setState({
        selectedCity: selectedCity
      })
    }
  }
  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        <section className="sign_up_chef under_review thank_you">
          <div className="container text-center">
            <div className="">
              <div className="logo_wrap order-2 order-md-3 mt-0">
                <div className="logo_inner d-flex justify-content-center align-items-center">
                  <img
                    src="assets/images/icons/logo.png"
                    alt="Logo Icon"
                    className="img-fluid"
                  />
                </div>
              </div>
              <h2 className="fw_500 text-uppercase color_C07">Thank you!</h2>
              <p className="order-3 order-md-2 title">
              Your order has been placed. <br/>
                {"Waiting for Home Chef " + localStorage.getItem("chefName") + " to confirm your order."}
              </p>
            </div>
            <Link to={"/"+this.state.selectedCity+"/diner-homepage"} className="btn back_home">
              GO BACK TO HOMEPAGE
            </Link>

            <div className="note_checkout">
              <p>
                <span className="fw_500 d-block">NOTE :</span>
                You will receive a confirmation from the Home Chef for the order
                you have placed. If the Home Chef is not available/cannot
                deliver the order and the order is cancelled then you will
                receive a full refund.
              </p>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  timeSlot: state.timeSlot.items,
});

export default connect(mapStateToProps, {
  emptyCart, removeTimeSlot, addOrderNumber,
  removeOrderNumber
})(
  PaymentThankYouPage
);
