/*
#############################################################################
# Name : thankYou.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the thank you data
#############################################################################
*/
import React from "react";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import { withRouter, Redirect } from "react-router-dom";

class TiffinThankYouPage extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        var userId = "";
        userId = localStorage.getItem("userID");
        localStorage.setItem("currentStatus", 1);
        // if (localStorage.getItem("userID") === null) {
        //   this.props.history.push("/");
        // }
    }

    /* logoutHandler function starts here */
    logoutHandler = (e) => {
        localStorage.clear();
    };
    /* logoutHandler function ends here */

    render() {
        return (
            <div>
                <Header />
                <section className="sign_up_chef under_review">
                    <div className="container text-center">
                        <div className="d-flex flex-column">
                            <h2 className="fw_500 text-uppercase color_C07">
                                THANK YOU FOR SUBMITTING YOUR TIFFIN INQUIRY
                            </h2>
                            <p className="order-3 order-md-2">
                                We will contact you to understand your exact requirements and suggest home chefs who can fulfil your order. Thank you for your patience.
                            </p>
                            <div className="logo_wrap order-2 order-md-3">
                                <div className="logo_inner d-flex justify-content-center align-items-center">
                                    <img
                                        src="assets/images/icons/logo.png"
                                        alt="Logo Icon"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                        <a href="/" className="btn back_home">
                            GO BACK TO HOMEPAGE
                        </a>
                    </div>
                </section>
                <div className="nofooter">
                    <WhatsAppButton />
                </div>
            </div>
        );
    }
}

export default withRouter(TiffinThankYouPage);
