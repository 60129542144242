import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import DinnerHeader from "../components/dinnerHeader";
import HomeHeader from "./Home/HomeHeader";
import BenefitsMeal from "./Home/BenefitsMeal";
import MetaTags from "./MetaPages/MetaTags";
import Pages from "./Page/Pages";
import HeaderImages from "./Home/HeaderImages";
import ImageSlider from "./Home/ImageSlider";
import AddBlogCateorgy from "./Blog/Category";
import Subscribers from "./Subscribers/Subscribers";
import AddBlog from "./Blog/AddBlog";
import AddAuthor from "./Blog/AddAuthor";
import "./NewStyle.css";
// Each logical "route" has two components, one for
// the sidebar and one for the main area. We want to
// render both of them in different places when the
// path matches the current URL.

// We are going to use this route config in 2
// spots: once for the sidebar and once in the main
// content section. All routes are in the same
// order they would appear in a <Switch>.

export default function DashboardSideBar() {
  let history = useHistory();

  let { path, url } = useRouteMatch();
  const [show, setShow] = useState(false);
  const [showBlog, setShowBlog] = useState(false);
  const [ActiveClass, setActiveClass] = useState();
  const [selectNum, setselectNum] = useState();

  const switchSelect = (name) => {
    switch (name) {
      case "Home":
        setActiveClass("Home");
        setShow(!show);
        setShowBlog(false);
        break;
      // case "Blog":
      //   setActiveClass("Blog");
      //   setShowBlog(!showBlog);
      //   setShow(false);

      //   break;
      case "WithdrawRequest":
        setActiveClass("WithdrawRequest");

        break;
    }
  };
  const routes = [
    {
      path: path,
      exact: true,
      sidebar: () => null,
      main: () => <h2>Home</h2>,
    },
    {
      path: path + "/headertext",
      sidebar: () => null,
      main: () => <HomeHeader />,
    },
    {
      path: path + "/BenefitsMeal",
      sidebar: () => null,
      main: () => <BenefitsMeal />,
    },
    {
      path: path + "/ImageSlider",
      sidebar: () => null,
      main: () => <ImageSlider />,
    },
    {
      path: path + "/MetaTags",
      sidebar: () => null,
      main: () => <MetaTags />,
    },

    {
      path: path + "/header-image",
      sidebar: () => null,
      main: () => <HeaderImages />,
    },
    {
      path: path + "/Pages",
      sidebar: () => null,
      main: () => <Pages />,
    },

    {
      path: path + "/add-blogs",
      sidebar: () => null,
      main: () => <AddBlog />,
    },
    {
      path: path + "/add-category",
      sidebar: () => null,
      main: () => <AddBlogCateorgy />,
    },
    {
      path: path + "/add-author",
      sidebar: () => null,
      main: () => <AddAuthor />,
    },
    {
      path: path + "/subscribers",
      sidebar: () => null,
      main: () => <Subscribers />,
    },
  ];

  return (
    <>
      <DinnerHeader />
      <Router>
        <div style={{ display: "flex" }}>
          <div
            className="left2"
            // style={{
            //   padding: "10px",
            //   width: "20%",
            //   background: "#354052",
            // }}
          >
            <ul>
              <li>
                <Link to={`${url}`}>Dashboards</Link>
              </li>
              <li
                className={ActiveClass == "Home" ? "active" : null}
                onClick={() => {
                  setActiveClass("Home");
                }}
                type="button"
              >
                <Link>
                  <i className="fa fa-home fa-lg"></i> Home
                </Link>
              </li>
              {ActiveClass == "Home" ? (
                <ul>
                  <li>
                    <Link to={`${url}/headertext`}>Header Text</Link>
                  </li>

                  <li>
                    <Link to={`${url}/ImageSlider`}>Image Sliders</Link>
                  </li>
                </ul>
              ) : null}
              <li>
                <Link to={`${url}/Pages`}>
                  <i className="fa fa-home fa-lg"></i>Pages
                </Link>
              </li>
              <li>
                <Link to={`${url}/MetaTags`}>
                  <i className="fa fa-home fa-lg"></i>Meta Tags
                </Link>
              </li>
              <li>
                <Link to={`${url}/subscribers`}>
                  <i className="fa fa-home fa-lg"></i>Subscribers
                </Link>
              </li>
              <li
                type="button"
                onClick={() => setActiveClass("Blog")}
                className={ActiveClass == "Blog" ? "active" : null}
              >
                <Link>
                  <i className="fa fa-home fa-lg"></i>Blog
                </Link>
              </li>
              {ActiveClass == "Blog" ? (
                <div>
                  <ul>
                    <li>
                      <Link to={`${url}/add-category`}>Add Category</Link>
                    </li>
                    <li>
                      <Link to={`${url}/add-blogs`}>Add Blogs</Link>
                    </li>
                    <li>
                      <Link to={`${url}/add-author`}>Add Author</Link>
                    </li>
                  </ul>
                </div>
              ) : null}

              {/* <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={<route.sidebar />}
                  />
                ))}
              </Switch> */}
              <li
                onClick={() => {
                  localStorage.removeItem("authentication");
                  history.push("/");
                }}
                type="button"
              >
                <Link>
                  <i className="fa fa-home fa-lg"></i>Log Out
                </Link>
              </li>
              {/* <li>
                <Link to={`${url}/shoelaces`}>Shoelaces</Link>
              </li> */}
            </ul>
          </div>

          <div
            className="content"
            style={{ flex: 1, paddingLeft: "200px", backgroundColor: "#fff" }}
          >
            <Switch>
              {routes.map((route, index) => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </div>
        </div>
      </Router>
    </>
  );
}
