import { ADD_DINER_ALERT_COUNT, ADD_CHEF_ALERT_COUNT } from "../actions/types";

const initialState = { items: [] };
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_DINER_ALERT_COUNT:
      return {
        items: action.payload.alertData,
      };

    case ADD_CHEF_ALERT_COUNT:
      return {
        items: action.payload.alertData,
      };

    default:
      return state;
  }
}
