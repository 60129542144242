/*
#############################################################################
# Name : PastOrders.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the past orders details
#############################################################################
*/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import AccountSidebar from "../AccountSidebar";
import DinnerHeader from "../../../../../components/dinnerHeader";
import DinerFooter from "../../../../../components/dinerFooter";
import WhatsAppButton from "../../../../../components/whatsappButton";
import DinerMenuTab from "../../../../../components/dinerMenuTab";
import {
  getDinerPastOrdersAPI,
  submitDinerReviewAPI,
} from "../../../../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import LoadingDiv from "../../../../../components/LoadingDiv";
import Moment from "moment";
import { removeMobileView } from "../../../../../redux/actions/chefMenuActions";
import config from "../../../../../config/config";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};

class PastOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedCity: "",
      pastOrders: [],
      foodQuality: "",
      portionSize: "",
      packaging: "",
      valueForMoney: "",
      comment: "",
      formErrors: {
        foodQuality: "",
        portionSize: "",
        packaging: "",
        valueForMoney: "",
        comment: "",
      },
      ratingError: "",
      submitBtnText: "Submit",
      btnDisabled: false,
      selectedOrder: [],
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitRatings = this.submitRatings.bind(this);
  }
  componentDidMount() {
    if (this.props.userData.userId) {
      this.getDinerPastOrdersAPI(); //Diner past order detail is fetched
    }
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: selectedCity,
      });
    }
  }

  /* getDinerPastOrdersAPI function starts here */
  getDinerPastOrdersAPI = () => {
    return getDinerPastOrdersAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            pastOrders: response.data.data.order,
            isLoading: false,
          });
        } else if (response.data["status"] === 500) {
          NotificationManager.error(
            "Session expired. Please log in.",
            "Error!"
          );
          this.props.history.push("/diner-signin-with-otp");
          localStorage.clear();
        } else {
          /* NotificationManager.error(response.data.message, "Error!"); */
          this.setState({
            isLoading: false,
          });
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.history.push("diner-signin-with-otp");
            localStorage.clear();
          } else {
            NotificationManager.error(response.data.message, "Error!");
          }
        }
      })
      .catch((error) => {
        /*     NotificationManager.error(error, "Error!"); */
        this.setState({
          isLoading: false,
        });
      });
  };
  /* getDinerPastOrdersAPI function ends here */
  
  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
  };
  /* getInerPage function ends here */

  /* handleInputChange function starts here */
  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    let ratingError = this.state.ratingError;

    switch (name) {
      case "foodQuality":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "portionSize":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "packaging":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "valueForMoney":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "comment":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        break;

      default:
        break;
    }

    this.setState({ ratingError, [name]: value });
  };
  /* handleInputChange function ends here */

  /* submitRatings function starts here */
  submitRatings = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    let ratingError = this.state.ratingError;
    const {
      foodQuality,
      portionSize,
      packaging,
      valueForMoney,
      comment,
      selectedOrder,
    } = this.state;

    if (!foodQuality && !portionSize && !packaging && !valueForMoney) {
      ratingError = "Please select the rating for atleast one section.";
    }
    this.setState({ ratingError, [name]: value });
    setTimeout(() => {
      if (this.state.ratingError === "") {
        this.setState({
          submitBtnText: "Submitting...",
          btnDisabled: true,
        });

        let data = {
          user_id: this.props.userData.userId,
          order_number: this.state.selectedOrder.order_number,
          email: this.props.userData.userEmail,
          diner_id: this.props.userData.userId,
          food_quality: foodQuality,
          portion_size: portionSize,
          packaging: packaging,
          value_for_money: valueForMoney,
          review: comment,
        };

        return submitDinerReviewAPI(data, this.props.userData.accessToken)
          .then((response) => {
            if (response.data.status === 200) {
              document.getElementById("closeFormButton").click();
              NotificationManager.success(response.data.message, "Success!");
              this.setState({
                foodQuality: 0,
                portionSize: 0,
                packaging: 0,
                valueForMoney: 0,
                comment: "",
                foodQuality: "",
                portionSize: "",
                packaging: "",
                valueForMoney: "",
                comment: "",
                submitBtnText: "Submit",
                btnDisabled: false,
                selectedOrder: [],
              });
            } else {
              document.getElementById("closeFormButton").click();
              NotificationManager.error(response.data.message, "Error!");
              this.setState({
                foodQuality: 0,
                portionSize: 0,
                packaging: 0,
                valueForMoney: 0,
                comment: "",
                foodQuality: "",
                portionSize: "",
                packaging: "",
                valueForMoney: "",
                comment: "",
                submitBtnText: "Submit",
                btnDisabled: false,
                selectedOrder: [],
              });
            }
          })
          .catch((error) => {
            document.getElementById("closeFormButton").click();
            /*  NotificationManager.error(error, "Error!"); */
            this.setState({
              foodQuality: 0,
              portionSize: 0,
              packaging: 0,
              valueForMoney: 0,
              comment: "",
              foodQuality: "",
              portionSize: "",
              packaging: "",
              valueForMoney: "",
              comment: "",
              submitBtnText: "Submit",
              btnDisabled: false,
              selectedOrder: [],
              hideContent: window.matchMedia("(max-width: 991px)").matches,
            });
          });
      }
    }, 200);
  };
  /* submitRatings function ends here */

  /* checkComment function starts here */
  checkComment = (e) => {
    if (e.target.value !== "") {
      this.setState({
        comment: e.target.value,
        comment: "",
      });
    } else {
      this.setState({
        comment: "Please enter the comment.",
      });
    }
  };
  /* checkComment function ends here */

  render() {
    if (this.props.userData.userType !== "Diner") {
      return (
        <Redirect
          to={
            "/diner-signin-with-otp?redirect=" +
            this.props.location.pathname.split("/")[1]
          }
        />
      );
    }
    const { history, mobileView } = this.props;
    const {
      isLoading,
      pastOrders,
      foodQuality,
      portionSize,
      packaging,
      valueForMoney,
      selectedOrder,
      formErrors,
    } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : !pastOrders.length ? (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Past Orders
                      </h6>
                      <div className="inner">
                        <div className="not_found empty_section py-0">
                          <div className="text-center">
                            <img
                              src="assets/images/icons/no-past-orders.svg"
                              alt="Not Found"
                              className="img-fluid"
                            />
                            <h6 className="justify-content-center">
                              NO PAST ORDERS
                            </h6>
                            <p>
                              It looks like you have not placed any orders in
                              <br /> the past. Please check your cart or current
                              orders.
                            </p>
                            <Link
                              to={
                                "/" +
                                this.state.selectedCity +
                                "/diner-homepage"
                              }
                              className="btn"
                            >
                              START ORDERING!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Past Orders
                      </h6>
                      <div className="inner current_orders_card">
                        {pastOrders.map((order, i) => {
                          return (
                            <div
                              className={
                                order.is_takeaway_selected == "1"
                                  ? "order_wrap takeaway"
                                  : "order_wrap"
                              }
                              key={"tkhbfk" + i}
                            >
                              <div className="order_title delivered">
                                <h6 className="order_num">
                                  <span>
                                    {order.is_takeaway_selected == "0"
                                      ? "Delivery"
                                      : "Takeaway"}
                                  </span>
                                  Order Number : {"#" + order.order_number}
                                </h6>
                                <h6 className="order_num delivered">
                                  Delivered
                                </h6>
                              </div>
                              <div className="save_add_wrap">
                                <div className="card">
                                  <div className="box title_price">
                                    <h6>
                                      Home Chef{" "}
                                      {order.first_name + " " + order.last_name}{" "}
                                      <span className="mb-0">
                                        INR {order.amount_paid} (including taxes
                                        and charges)
                                      </span>
                                    </h6>
                                    <p className="name_add add">
                                      {order.drop_location}
                                    </p>
                                    {order.is_takeaway_selected == "1" && (
                                      <a
                                        target="_blank"
                                        href={
                                          "https://www.google.com/maps/dir/" +
                                          order.drop_location
                                            .split(" ")
                                            .join("+") +
                                          "/" +
                                          order.pickup_location
                                            .split(" ")
                                            .join("+")
                                        }
                                        className="link"
                                      >
                                        View on Google Maps
                                      </a>
                                    )}
                                  </div>
                                  <hr className="dash_line" />
                                  <div className="box">
                                    <h6 className="font-weight-normal badge_wrap_div">
                                      {order.order_details.map((item, i) => {
                                        return (
                                          <span
                                            key={"fshyt" + i}
                                            className="badge badge-light mr-2"
                                            style={{
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              backgroundColor: "#f2f2f2",
                                            }}
                                          >
                                            {item.item_name +
                                              " x " +
                                              item.itemQuantity}
                                          </span>
                                        );
                                      })}
                                    </h6>
                                    <p>
                                      Order placed :{" "}
                                      {Moment(order.created_at).format(
                                        "MMM DD, hh:mm a"
                                      )}
                                    </p>
                                    <p>
                                      Delivery time slot :{" "}
                                      {order.selected_time_slot}
                                    </p>
                                    {/* <p>Delivered at : {order.selected_time_slot}</p> */}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
								  <a
                                    target="_blank"
                                    className="btn add_new_btn"
                                    onClick={() =>
                                      this.getInerPage(order.chef_id, order.slug_url)
                                    }
                                  >
									Back To Chef Item's
                                  </a>
                                  <a
                                    href="rate-and-review.html"
                                    className="btn add_new_btn"
                                    data-toggle="modal"
                                    data-target="#rating_review"
                                    onClick={() =>
                                      this.setState({
                                        selectedOrder: order,
                                      })
                                    }
                                  >
                                    RATE & REVIEW
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="pins_achieved_modal modal fade enquire_orders request_custom bottom_pop diner_my_acc show"
          id="rating_review"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="rating_reviewTitle"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content bg-white">
              <div className="modal-header justify-content-start flex-column">
                <h5 className="modal-title ml-0 mb-0" id="rating_reviewTitle">
                  RATE & REVIEW ORDER
                  <button
                    onClick={() => {
                      this.setState({
                        foodQuality: 0,
                        portionSize: 0,
                        packaging: 0,
                        valueForMoney: 0,
                        comment: "",
                        foodQuality: "",
                        portionSize: "",
                        packaging: "",
                        valueForMoney: "",
                        comment: "",
                        submitBtnText: "Submit",
                        btnDisabled: false,
                        selectedOrder: [],
                      });
                      for (var i = 1; i <= 5; i++) {
                        document.querySelector(
                          "#foodQuality-rating-" + i
                        ).checked = false;
                        document.querySelector(
                          "#portionSize-rating-" + i
                        ).checked = false;
                        document.querySelector(
                          "#packaging-rating-" + i
                        ).checked = false;
                        document.querySelector(
                          "#valueForMoney-rating-" + i
                        ).checked = false;
                      }
                      document.getElementById("ratingForm").reset();
                    }}
                    type="button"
                    id="closeFormButton"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="assets/images/icons/black-close.svg"
                      alt="Close"
                    />
                  </button>
                </h5>
              </div>
              <div className="modal-body current_orders_card pb-0">
                <form onSubmit={this.submitRatings} id="ratingForm">
                  <div className="order_wrap px-0 pb-3 pt-0">
                    <div className="save_add_wrap">
                      <div className="card mb-0 pb-0 pt-0 shadow_none bg_none border-0">
                        <div className="box px-0 pt-0">
                          <div className="order_title delivered">
                            <h6 className="order_num">
                              <span>Delivery</span>Order Number :{" "}
                              {"#" + selectedOrder.order_number}
                            </h6>
                          </div>
                        </div>
                        <hr className="dash_line" />
                        <div className="box px-0 pb-0">
                          <h6 className="font-weight-normal">
                            Rate this order from Home Chef{" "}
                            {selectedOrder.first_name +
                              " " +
                              selectedOrder.last_name}
                          </h6>
                          <ul className="rate_star_list d-block">
                            <li>
                              <span className="fw_500">Food Quality</span>
                              <span className="star-cb-group">
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="foodQuality-rating-1"
                                    name="foodQuality"
                                    value="1"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="foodQuality-rating-2"
                                    name="foodQuality"
                                    value="2"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="foodQuality-rating-3"
                                    name="foodQuality"
                                    value="3"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="foodQuality-rating-4"
                                    name="foodQuality"
                                    value="4"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="foodQuality-rating-5"
                                    name="foodQuality"
                                    value="5"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                              </span>
                              {/* {
                                formErrors.foodQuality.length > 0 && (
                                  <small className="text-danger">{formErrors.foodQuality}</small>
                                )
                              } */}
                            </li>
                            <li>
                              <span className="fw_500">Portion Size</span>
                              <span className="star-cb-group">
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="portionSize-rating-1"
                                    name="portionSize"
                                    value="1"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="portionSize-rating-2"
                                    name="portionSize"
                                    value="2"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="portionSize-rating-3"
                                    name="portionSize"
                                    value="3"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="portionSize-rating-4"
                                    name="portionSize"
                                    value="4"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="portionSize-rating-5"
                                    name="portionSize"
                                    value="5"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                              </span>
                              {/* {
                                formErrors.portionSize.length > 0 && (
                                  <small className="text-danger">{formErrors.portionSize}</small>
                                )
                              } */}
                            </li>
                            <li>
                              <span className="fw_500">Packaging</span>
                              <span className="star-cb-group">
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="packaging-rating-1"
                                    name="packaging"
                                    value="1"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="packaging-rating-2"
                                    name="packaging"
                                    value="2"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="packaging-rating-3"
                                    name="packaging"
                                    value="3"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="packaging-rating-4"
                                    name="packaging"
                                    value="4"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="packaging-rating-5"
                                    name="packaging"
                                    value="5"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                              </span>
                              {/* {
                                formErrors.packaging.length > 0 && (
                                  <small className="text-danger">{formErrors.packaging}</small>
                                )
                              } */}
                            </li>
                            <li>
                              <span className="fw_500">Value for Money</span>
                              <span className="star-cb-group">
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="valueForMoney-rating-1"
                                    name="valueForMoney"
                                    value="1"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="valueForMoney-rating-2"
                                    name="valueForMoney"
                                    value="2"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="valueForMoney-rating-3"
                                    name="valueForMoney"
                                    value="3"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="valueForMoney-rating-4"
                                    name="valueForMoney"
                                    value="4"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                                <div className="wrap">
                                  <input
                                    type="checkbox"
                                    id="valueForMoney-rating-5"
                                    name="valueForMoney"
                                    value="5"
                                    onChange={this.handleInputChange}
                                  />
                                  <label className="icons star_icon"></label>
                                </div>
                              </span>
                              {/* {
                                formErrors.valueForMoney.length > 0 && (
                                  <small className="text-danger">{formErrors.valueForMoney}</small>
                                )
                              } */}
                            </li>
                          </ul>
                          {this.state.ratingError.length > 0 && (
                            <small className="text-danger">
                              {this.state.ratingError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order_wrap px-0 pt-0 pb-3">
                    <div className="save_add_wrap">
                      <div className="card shadow_none pt-0 pb-0 bg_none">
                        <div className="box px-0">
                          <h6 className="font-weight-normal">Write a review</h6>
                          <p>Review can be maximum 1000 characters in length</p>
                          <div className="form-group mb-0">
                            <textarea
                              className="form-control"
                              name="comment"
                              onChange={this.handleInputChange}
                              maxLength="1000"
                            ></textarea>
                          </div>
                          {formErrors.comment.length > 0 && (
                            <small className="text-danger">
                              {formErrors.comment}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          disabled={this.state.btnDisabled}
                          className="btn add_new_btn"
                          type="submit"
                        >
                          {this.state.submitBtnText}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(PastOrders);
