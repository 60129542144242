/*
#############################################################################
# Name : MyReviews.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display refund details
#############################################################################
*/
import React, { Component } from "react";
import AccountSidebar from "./AccountSidebar";
import {
  getDinerReviews,
} from "../../../../core/services/Phase2APIservice";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import LoadingDiv from "../../../../components/LoadingDiv";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import StarRatings from "react-star-ratings";
import Moment from "moment";
import config from "../../../../config/config";
import { removeMobileView } from "../../../../redux/actions/chefMenuActions";

class MyReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedCity: "",
      myReviews: [],
	  comment: "",
      formErrors: {
        foodQuality: "",
        portionSize: "",
        packaging: "",
        valueForMoney: "",
        comment: "",
      },
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    if(localStorage.getItem("selectedLocation")){
      var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
      this.setState({
        selectedCity: selectedCity
      })
    }
    this.getDinerReviews();        //Refund detail data is fetched
  }
 
  displayChefReply = (id) => {
    console.log(id);
    this.setState(
      {
        ratingID: id,
      },
      () => {
        this.setState({
          chefReplyFlag: !this.state.chefReplyFlag,
        });
        console.log(this.state.ratingID);
      },
      100
    );
  };
  /* getDinerReviews function starts here */
  getDinerReviews() {
    return getDinerReviews(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
			
          this.setState({
            myReviews: response.data.data,
            isLoading: false,
          });
		 
        } else {
          /*   NotificationManager.error(response.data.message, "Error!") */ 
          this.setState(
            {
              isLoading: false,
            }
          );
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.history.push("diner-signin-with-otp");
            localStorage.clear();
          } else {
            NotificationManager.error(response.data.message, "Error!");
          }
        }
      })
      .catch((error) => {
        /*   NotificationManager.error(error, "Error!"); */
        this.setState({
          isLoading: false,
        });
      });
  }
 /* getDinerReviews function ends here */

  render() {
    if (localStorage.getItem("accessToken") == null) {
      return <Redirect to="/" />;
    }
    const { myReviews, isLoading } = this.state;
    const { mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : !myReviews.length ? (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        My Reviews
                      </h6>
                      <div className="inner">
                        <div className="not_found empty_section py-0">
                          <div className="text-center">
                            <img
                              src="assets/images/icons/review-icon.svg"
                              alt="Not Found"
                              className="img-fluid"
                            />
                            <h6 className="justify-content-center">
                              No Reviews Found.
                            </h6>
                            <Link to={"/"+this.state.selectedCity+"/diner-homepage"} className="btn">
                              Go back to homepage
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        My Reviews
                      </h6>
                      <div className="inner current_orders_card">
                        {myReviews.map((review, i) => {
                          return (
                            <div className="order_wrap">
                              <div className="order_title">
                                <h6 className="order_num">
                                  <span>
                                    {review.order_number!= null
                                      ?  "Order Number : #" + review.order_number
                                      : null
									}
                                  </span>
                                </h6>
                              </div>
                              <div className="save_add_wrap">
                                <div className="card">
                                  <div className="box title_price">
                                    <h6>
                                      Home Chef{" "}
                                      {review.chef_name}{" "} Review Rating
                                    </h6>
                                  
                                  <hr className="dash_line" />
								  <ul className="rate_star_list diner_review">
									{review.average_rating != 0 ? (
										<li className="icons start border-0 font-weight-bold">
										<span className="fw_500">Food Quality</span><br/>
										  <StarRatings
											rating={
											  review.food_quality
												? parseFloat(review.food_quality)
												: 0
											}
											starDimension="30px"
											starSpacing="13px"
											starRatedColor="#FFC107"
											starEmptyColor="#EAEAEA"
										  />
										
										</li>
									) : null}
									
									{review.average_rating != 0 ? (
										<li className="icons start border-0 font-weight-bold">
										<span className="fw_500">Portion Size</span>
										  <StarRatings
											rating={
											  review.portion_size
												? parseFloat(review.portion_size)
												: 0
											}
											starDimension="30px"
											starSpacing="13px"
											starRatedColor="#FFC107"
											starEmptyColor="#EAEAEA"
										  />
										
										</li>
									) : null}
									
									{review.average_rating != 0 ? (
										<li className="icons start border-0 font-weight-bold">
										<span className="fw_500">Packaging</span>
										  <StarRatings
											rating={
											  review.packaging
												? parseFloat(review.packaging)
												: 0
											}
											starDimension="30px"
											starSpacing="13px"
											starRatedColor="#FFC107"
											starEmptyColor="#EAEAEA"
										  />
										
										</li>
									) : null}
									
									{review.average_rating != 0 ? (
										<li className="icons start border-0 font-weight-bold">
										<span className="fw_500">Value for Money</span>
										  <StarRatings
											rating={
											  review.value_for_money
												? parseFloat(review.value_for_money)
												: 0
											}
											starDimension="30px"
											starSpacing="13px"
											starRatedColor="#FFC107"
											starEmptyColor="#EAEAEA"
										  />
										
										</li>
									) : null}

								  </ul>
								  {review.review != null || review.review != "" ? (
								  <div className=" px-0 pt-0 pb-3">
									<div className="save_add_wrap">
									  <div className="card shadow_none pt-0 pb-0 bg_none">
										<div className="box px-0">
										  <h6 className="">Review</h6>
										  <div className="form-group mb-0">
											 {review.review}
										  </div>
									  </div>
									 </div>
								    </div>
								  </div>
								  ) : null}
								  {review.comments.length ? (
                                    <div className="view_comment_text">
                                      <hr className="dash_line"></hr>
                                      <span
                                        onClick={() =>
                                          this.displayChefReply(review.id)
                                        }
                                      >
                                        View Comment
                                      </span>
                                    </div>
                                  ) : null}
								  </div>
								{review.id === this.state.ratingID
									? review.comments.map((comment, c) => {
                                    return (
                                      <div
                                        className="wrap mb-0 chef_reviews_page"
                                        key={"adv" + c}
                                      >
                                        {review.id === this.state.ratingID ? (
                                          <div className="mediem_circle_loc">
                                            <div className="inner">
                                              <img
                                                src="/assets/images/user.png"
                                                alt="Profile Images"
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                        {review.id === this.state.ratingID ? (
                                          <div className="text">
                                            <h6>
                                              {comment.name}
                                              <p className="mb-0 d-none d-sm-inline-block">
                                                {Moment(
                                                  comment.created_at
                                                ).format("Do MMM, YYYY")}
                                              </p>
                                            </h6>
                                            <h6 className="d-inline-block d-sm-none mb-0">
                                              <p className="mb-0">
                                                {Moment(
                                                  comment.created_at
                                                ).format("Do MMM, YYYY")}
                                              </p>
                                            </h6>
                                            <p className="para">
                                              {comment.review_comments}
                                            </p>
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })
                                : null}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(MyReviews);
