import { URLs } from "../Url";

// Blog
export const BlogCategoryData = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/blog-category",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const CategoryPostdata = async (title) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      categoryname: title,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/add-blog-category",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const categoryeditPostdata = async (id, title) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      categoryname: title,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/update-blog-category/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const CategoryDelete = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/delete-blog-category/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const BlogData = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(URLs.domain + "/api/blog", requestOptions);
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const BlogDetailData = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/blog-detail/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const BlogDelete = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/delete-blog/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const BlogImageDelete = async (name) => {
  try {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/blog-deleteimage/" + name,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const BlogallImageDelete = async (filesName) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      fileName: filesName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/deleteAll-blogimage",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const Blogpostimage = async (updateimage) => {
  try {
    var formdata = new FormData();
    formdata.append("image", updateimage);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/blog-imageupload",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const BlogeditPostdata = async (
  id,
  updateimage,
  CatId,
  title,
  description,
  article,
  author,
  slug,
  imagealt,
  selectedtags
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      blogcategory_id: CatId,
      title: title,
      description: description,
      article: article,
      feturedimage: updateimage,
      feturedimage_alt: imagealt,
      slug: slug,
      author: author,
      tags: selectedtags,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/blog-update/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const BlogPostdata = async (
  updateimage,
  CatId,
  title,
  description,
  article,
  author,
  slug,
  imagealt,
  selectedtags
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      blogcategory_id: CatId,
      title: title,
      description: description,
      article: article,
      feturedimage: updateimage,
      feturedimage_alt: imagealt,
      slug: slug,
      author: author,
      tags: selectedtags,
    });
    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/blog-upload",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

///Author

export const AuthorData = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(URLs.domain + "/api/author", requestOptions);
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const PostAuthorData = async (author, image, image_alt) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      author: author,
      image: image,
      image_alt: image_alt,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/author-upload",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const UpdateAuthorData = async (id, author, image, image_alt) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      author: author,
      image: image,
      image_alt: image_alt,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/author-update/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const AuthorDelete = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/delete-author/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
