import React, { useState, useRef, useEffect } from "react";
import "../BackendStyle.css";

import { GetSubsctribe, Deletesubscribe } from "./config";

function Subscribers() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    const getgteanData = await GetSubsctribe();
    if (getgteanData.success == true) {
      console.log(getgteanData);
      setdata(getgteanData.data);
    }
  };

  const DeleteHandler = async (id) => {
    const getResponses = await Deletesubscribe(id);
    if (getResponses.success == true) {
      handlerData();
    }
  };

  return (
    <div>
      <div className="row table-invoice">
        <div className="col-sm-12">
          <div className="table-in-card">
            <div class="d-flex justify-content-between">
              <h3>Subscribers</h3>

              {/* <button
                type="button"
                onClick={() => setShow("add")}
                class="btn btn-primary btn-xs"
              >
                <small>Add</small>
              </button> */}
            </div>
            {data.length > 0 ? (
              <>
                <br />

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length == 0
                        ? null
                        : data.map((item) => {
                            return (
                              <tr>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>
                                  <div class="d-flex justify-content-between">
                                    <button
                                      type="button"
                                      onClick={() => DeleteHandler(item._id)}
                                      class="btnnnnn btn-danger"
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribers;
