/*
#############################################################################
# Name : aboutYou.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to save and update the chef about you detail
#############################################################################
*/
import React, { Component } from "react";
import axios from "axios";
import {
  detailAboutChefAPI,
  getUserSummaryAPI,
} from "../../core/services/APIservice";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import $ from "jquery";

const numberReg = /^[0]?[789]\d{9}$/;
const teleReg = /^[0-9\b]{6,}$/;
const stdReg = /^[0-9\b]{2,}$/;
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class AboutYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      userAccessToken: "",
      userName: "",
      userFirstName: "",
      userLastName: "",
      userMobile: "",
      userEmail: "",
      whatsNumber: "",
      whatsappSelected: false,
      loading: false,
      genderSelected: "Male",
      stdCode: "",
      profilePhoto: "",
      profilePhotoName: "",
      profilePhoto2: "",
      profilePhoto2Name: "",
      houseNo: "",
      floor: "",
      building: "",
      street: "",
      pincode: "",
      locality: "",
      city: "",
      enterState: "",
      landmark: "",
      facebookURL: "",
      instagramURL: "",
      twitterURL: "",
      websiteURL: "",
      brandName: "",
      telephoneNo: "",
      errorBrandImage: "",
      errorPincode: "",
      errorProfileValid: "",
      latitude: "",
      longitude: "",
      formErrors: {
        userMobile: "",
        whatsNumber: "",
        stdCode: "",
        telephoneNo: "",
        userFirstName: "",
        userLastName: "",
        genderSelected: "",
        houseNo: "",
        building: "",
        street: "",
        pincode: "",
        city: "",
        state: "",
      },
    };
    this.handleAboutYouChange = this.handleAboutYouChange.bind(this);
    this.onAboutYouSubmit = this.onAboutYouSubmit.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.getAboutYouDeatils();      // About You detail data is fetched 
    window.scrollTo(0, 0);
  }

  /* handleAboutYouChange function starts here */
  handleAboutYouChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "userFirstName":
        formErrors.userFirstName = value === "" ? "First name is required" : "";
        break;

      case "userLastName":
        formErrors.userLastName = value === "" ? "Last name is required" : "";
        break;

      case "userMobile":
        formErrors.userMobile =
          value === ""
            ? "Mobile number is required"
            : !numberReg.test(e.target.value)
            ? "Enter valid Mobile Number"
            : "";
        break;

      case "whatsNumber":
        formErrors.whatsNumber =
          value === ""
            ? "WhatsApp number is required"
            : !numberReg.test(e.target.value)
            ? "Enter valid WhatsApp number"
            : "";
        if (this.state.userMobile === e.target.value) {
          this.setState({
            whatsappSelected: true,
          });
        } else {
          this.setState({
            whatsappSelected: false,
          });
        }
        break;

      case "stdCode":
        formErrors.stdCode = !stdReg.test(e.target.value)
          ? "Enter valid STD Code"
          : "";
        break;

      case "telephoneNo":
        formErrors.telephoneNo = !teleReg.test(e.target.value)
          ? "Enter valid Telephone Number"
          : "";
        break;

      case "houseNo":
        formErrors.houseNo = value === "" ? "House/Flat No. is required" : "";
        break;
      case "building":
        formErrors.building = value === "" ? "Building is required" : "";
        break;

      case "street":
        formErrors.street = value === "" ? "Street is required" : "";
        break;

      case "pincode":
        formErrors.pincode = value === "" ? "Pincode is required" : "";
        break;

      case "city":
        formErrors.city = value === "" ? "City is required" : "";
        break;

      case "enterState":
        formErrors.enterState = value === "" ? "State is required" : "";
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
   /* handleAboutYouChange function ends here */

  /*  if(this.state.userMobile === this.state.whatsNumber){
  }else{
  } */

 /* getAboutYouDeatils function starts here */
  getAboutYouDeatils() {
    var id = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    this.setState({ userId: id, userAccessToken: token });
    return detailAboutChefAPI(id, token)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.props.userInfoDataCallBack(response.data["data"]);
          var firstname = response.data["data"]["first_name"];
          var lastname = response.data["data"]["last_name"];
          var mobile = response.data["data"]["mobile_number"];
          var mail = response.data["data"]["email"];
          var whatsapp = response.data["data"]["whatsapp_mobile_number"];
          var houseNo = response.data["data"]["house_or_flat_number"];
          var floor = response.data["data"]["floor_number"];
          var building = response.data["data"]["building_or_society_name"];
          var street = response.data["data"]["street_name"];
          var pincode = response.data["data"]["pincode"];
          var locality = response.data["data"]["locality"];
          var city = response.data["data"]["city"];
          var state = response.data["data"]["state"];
          var std = response.data["data"]["std_code"];
          var landmark = response.data["data"]["landmark"];
          var facebook = response.data["data"]["facebook"];
          var instagram = response.data["data"]["instagram"];
          var twitter = response.data["data"]["twitter"];
          var website = response.data["data"]["website_or_blog"];
          var brand = response.data["data"]["brand_name"];
          var telephone = response.data["data"]["telephone_number"];
          var gender = response.data["data"]["gender"];
          var lat = response.data["data"]["latitude"];
          var long = response.data["data"]["longitude"];
          var profileImage = /[^/]*$/.exec(
            response.data["data"]["profile_image"]
          )[0];
          var brandLogo = /[^/]*$/.exec(response.data["data"]["brand_logo"])[0];

          if (lat === null) {
            this.setState({
              latitude: "",
            });
          } else {
            this.setState({
              latitude: lat,
            });
          }

          if (long === null) {
            this.setState({
              longitude: "",
            });
          } else {
            this.setState({
              longitude: long,
            });
          }

          if (firstname === null) {
            this.setState({
              userFirstName: "",
            });
          } else {
            this.setState({
              userFirstName: firstname,
            });
          }

          if (lastname === null) {
            this.setState({
              userLastName: "",
            });
          } else {
            this.setState({
              userLastName: lastname,
            });
          }

          if (facebook === null) {
            this.setState({
              facebookURL: "",
            });
          } else {
            this.setState({
              facebookURL: facebook,
            });
          }

          if (instagram === null) {
            this.setState({
              instagramURL: "",
            });
          } else {
            this.setState({
              instagramURL: instagram,
            });
          }

          if (twitter === null) {
            this.setState({
              twitterURL: "",
            });
          } else {
            this.setState({
              twitterURL: twitter,
            });
          }

          if (website === null) {
            this.setState({
              websiteURL: "",
            });
          } else {
            this.setState({
              websiteURL: website,
            });
          }

          if (std === null) {
            this.setState({
              stdCode: "",
            });
          } else {
            this.setState({
              stdCode: std,
            });
          }

          if (landmark === null) {
            this.setState({
              landmark: "",
            });
          } else {
            this.setState({
              landmark: landmark,
            });
          }

          if (locality === null) {
            this.setState({
              locality: "",
            });
          } else {
            this.setState({
              locality: locality,
            });
          }

          if (brand === null) {
            this.setState({
              brandName: "",
            });
          } else {
            this.setState({
              brandName: brand,
            });
          }

          if (telephone === null) {
            this.setState({
              telephoneNo: "",
            });
          } else {
            this.setState({
              telephoneNo: telephone,
            });
          }

          if (mobile === null) {
            this.setState({
              userMobile: "",
            });
          } else {
            this.setState({
              userMobile: mobile,
            });
          }

          if (mail === null) {
            this.setState({
              userEmail: "",
            });
          } else {
            this.setState({
              userEmail: mail,
            });
          }

          if (whatsapp === null) {
            this.setState({
              whatsNumber: "",
            });
          } else {
            if (whatsapp === mobile) {
              this.setState({
                whatsappSelected: true,
              });
            } else {
              this.setState({
                whatsappSelected: false,
              });
            }
            this.setState({
              whatsNumber: whatsapp,
            });
          }

          if (houseNo === null) {
            this.setState({
              houseNo: "",
            });
          } else {
            this.setState({
              houseNo: houseNo,
            });
          }

          if (floor === null) {
            this.setState({
              floor: "",
            });
          } else {
            this.setState({
              floor: floor,
            });
          }

          if (building === null) {
            this.setState({
              building: "",
            });
          } else {
            this.setState({
              building: building,
            });
          }

          if (street === null) {
            this.setState({
              street: "",
            });
          } else {
            this.setState({
              street: street,
            });
          }

          if (pincode === null) {
            this.setState({
              pincode: "",
            });
          } else {
            this.setState({
              pincode: pincode,
            });
          }

          if (city === null) {
            this.setState({
              city: "",
            });
          } else {
            this.setState({
              city: city,
            });
          }

          if (state === null) {
            this.setState({
              enterState: "",
            });
          } else {
            this.setState({
              enterState: state,
            });
          }

          if (gender === null) {
            this.setState({
              genderSelected: "",
            });
          } else {
            this.setState({
              genderSelected: gender,
            });
          }

          if (profileImage === "null") {
            this.setState({
              profilePhotoName: "",
            });
          } else {
            this.setState({
              profilePhotoName: profileImage.slice(-20),
            });
          }

          if (brandLogo === "null") {
            this.setState({
              profilePhoto2Name: "",
            });
          } else {
            this.setState({
              profilePhoto2Name: brandLogo.slice(-20),
            });
          }
        }
      })
      .catch((error) => {});
  }
/* getAboutYouDeatils function ends here */

/* onChangeProfilePhoto function starts here */
  onChangeProfilePhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let formErrors = this.state.formErrors;
    var file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      this.setState({ errorProfileValid: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorProfileValid: "" });
    }
    this.setState({
      profilePhoto: event.target.files[0],
      profilePhotoName: event.target.files[0]["name"].slice(-20),
    });
    if (event.target.files[0]) {
      formErrors.profilePhotoName = "";
    }
  };
/* onChangeProfilePhoto function ends here */

/* onChangeProfilePhoto2 function starts here */
  onChangeProfilePhoto2 = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      this.setState({ errorBrandImage: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorBrandImage: "" });
    }
    this.setState({
      profilePhoto2: event.target.files[0],
      profilePhoto2Name: event.target.files[0]["name"].slice(-20),
    });
  };
/* onChangeProfilePhoto2 function ends here */

/* handleInputChange function starts here */
  handleInputChange(event) {
    const target = event.target;
    let formErrors = this.state.formErrors;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (value === false) {
      this.setState({ whatsNumber: "" });
    } else {
      formErrors.whatsNumber = "";
      this.setState({ whatsNumber: this.state.userMobile });
    }
    this.setState({
      [name]: value,
    });
  }
/* handleInputChange function ends here */

/* handleRadioChange function starts here */
  handleRadioChange(event) {
    let formErrors = this.state.formErrors;
    formErrors.genderSelected = "";
    this.setState({ genderSelected: event.target.value });
  }
/* handleRadioChange function ends here */

/* getAddressFromCode function starts here */
  getAddressFromCode = (event) => {
    if (event.target.value) {
      let formErrors = this.state.formErrors;
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=+" +
            event.target.value +
            "+&sensor=true&key=AIzaSyAM9tXQe4ikJvpt4PwbWVR9gVOaYPoWuT8",
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            var cityCnt = 0;
            var stateCnt = 0;
            response.data.results[0].address_components.forEach((item) => {
              item.types.forEach((type) => {
                if (
                  type === "administrative_area_level_2" ||
                  type === "locality"
                ) {
                  if (item.long_name) {
                    formErrors.city = "";
                  }
                  this.setState({
                    city: item.long_name,
                    errorPincode: "",
                    latitude: response.data.results[0].geometry.location.lat,
                    longitude: response.data.results[0].geometry.location.lng,
                  });
                  cityCnt++;
                }
                if (type === "administrative_area_level_1") {
                  if (item.long_name) {
                    formErrors.enterState = "";
                  }
                  this.setState({
                    enterState: item.long_name,
                    errorPincode: "",
                  });
                  stateCnt++;
                }
              });
            });

            if (cityCnt === 0) {
              this.setState({
                city: "",
              });
            }
            if (stateCnt === 0) {
              this.setState({
                enterState: "",
              });
            }
          } else {
            this.setState({
              city: "",
              enterState: "",
              errorPincode: "Pincode is invalid",
            });
          }
        })
        .catch((error) => {
          //NotificationManager.error(error);
          /* console.log(error); */
        });
    }
  };
/* getAddressFromCode function ends here */

/* onAboutYouSubmit function starts here */
  onAboutYouSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.userFirstName === "") {
      formErrors.userFirstName = "First Name is required";
    } else {
      formErrors.userFirstName = "";
    }

    if (this.state.userLastName === "") {
      formErrors.userLastName = "Last Name is required";
    } else {
      formErrors.userLastName = "";
    }

    if (this.state.userMobile === "") {
      formErrors.userMobile = "Mobile Number is required";
    } else {
      formErrors.userMobile = "";
    }

    if (this.state.whatsNumber === "") {
      formErrors.whatsNumber = "WhatsApp Number is required";
    } else {
      formErrors.whatsNumber = "";
    }

    if (this.state.userEmail === "") {
      formErrors.userEmail = "Email ID is required";
    } else {
      formErrors.userEmail = "";
    }

    if (this.state.genderSelected === "") {
      formErrors.genderSelected = "Gender is required";
    } else {
      formErrors.genderSelected = "";
    }

    if (this.state.profilePhotoName === "") {
      formErrors.profilePhotoName = "Profile Picture is required";
    } else {
      formErrors.profilePhotoName = "";
    }

    if (this.state.houseNo === "") {
      formErrors.houseNo = "House/Flat No. is required";
    } else {
      formErrors.houseNo = "";
    }

    if (this.state.building === "") {
      formErrors.building = "Building is required";
    } else {
      formErrors.building = "";
    }

    if (this.state.street === "") {
      formErrors.street = "Street is required";
    } else {
      formErrors.street = "";
    }

    if (this.state.pincode === "") {
      formErrors.pincode = "Pincode is required";
    } else {
      formErrors.pincode = "";
    }

    if (this.state.city === "") {
      formErrors.city = "City is required";
    } else {
      formErrors.city = "";
    }

    if (this.state.enterState === "") {
      formErrors.enterState = "State is required";
    } else {
      formErrors.enterState = "";
    }

    if (this.state.telephoneNo === "") {
      formErrors.telephoneNo = "";
    }

    if (this.state.stdCode === "") {
      formErrors.stdCode = "";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.recaptcha.execute();
      this.setState({ loading: true });
      var formdata = new FormData();
      formdata.append("user_id", this.state.userId);
      formdata.append("first_name", this.state.userFirstName);
      formdata.append("last_name", this.state.userLastName);
      formdata.append("mobile_number", this.state.userMobile);
      formdata.append("whatsapp_mobile_number", this.state.whatsNumber);
      formdata.append("email", this.state.userEmail);
      formdata.append("std_code", this.state.stdCode);
      formdata.append("brand_name", this.state.brandName);
      formdata.append("gender", this.state.genderSelected);
      if (this.state.profilePhoto !== "") {
        formdata.append(
          "profile_image",
          this.state.profilePhoto,
          this.state.profilePhotoName
        );
      }
      if (this.state.profilePhoto2 !== "") {
        formdata.append(
          "brand_logo",
          this.state.profilePhoto2,
          this.state.profilePhoto2Name
        );
      }
      formdata.append("telephone_number", this.state.telephoneNo);
      formdata.append("house_or_flat_number", this.state.houseNo);
      formdata.append("floor_number", this.state.floor);
      formdata.append("building_or_society_name", this.state.building);
      formdata.append("street_name", this.state.street);
      formdata.append("pincode", this.state.pincode);
      formdata.append("locality", this.state.locality);
      formdata.append("city", this.state.city);
      formdata.append("state", this.state.enterState);
      formdata.append("longitude", this.state.longitude);
      formdata.append("latitude", this.state.latitude);
      formdata.append("landmark", this.state.landmark);
      formdata.append("facebook", this.state.facebookURL);
      formdata.append("instagram", this.state.instagramURL);
      formdata.append("twitter", this.state.twitterURL);
      formdata.append("website_or_blog", this.state.websiteURL);

      axios
        .post(config.APIURL + "v1/store-details-about-chef", formdata, {
          headers: {
            Authorization: this.state.userAccessToken,
          },
        })
        .then((response) => {
          if (response.data.status === 200 && !response.data.error ) {
            var userId = localStorage.getItem("userID");
            var accessToken = localStorage.getItem("accessToken");
            this.props.profileDataCallBack(response.data["data"]);
            return getUserSummaryAPI(userId, accessToken)
              .then((response) => {
                if (response.data["status"] === 200) {
                  this.props.parentCallback(response.data["data"]);
                  this.setState({ loading: false });
                  localStorage.setItem("currentStatus", 2);
                  NotificationManager.success(
                    "User About You Detail Saved",
                    "Successful!",
                    3000
                  );
                } else {
                  this.setState({ loading: false });
                  NotificationManager.error(response.data["message"], "Error!");
                }
              })
              .catch((error) => {
                this.setState({ loading: false });
              });
          } else {
            this.setState({ loading: false });
            NotificationManager.error(response.data["message"], "Error!");
            if (response.status === 401) {
              NotificationManager.error(
                "Session expired. Please log in.",
                "Error!"
              );
              this.props.history.push("/signin-with-otp");
              localStorage.clear();
            }
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          NotificationManager.error(
            error.message,
            "Error!"
          );
          if (error.response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
          }
        });
    } else {
      this.setState({ loading: false });
      setTimeout(function () {
        var getfilterRowElement = $(".text-danger");
        $("small.text-danger").each(function () {
          var string = $(this).html();
          if (string != "") {
            $("html, body").animate(
              {
                scrollTop: $(this).parent().offset().top + -100,
              },
              500
            );
            return false;
          }
        });
      }, 100);
      this.recaptcha.reset();
    }
  };
/* onAboutYouSubmit function ends here */

/* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved function ends here */


  render() {
    const { formErrors } = this.state;
    return (
      <div className="abt_form ">
        <h4 className="d-flex justify-content-between align-items-center text-uppercase">
          <button className="save_btn back_btn BackBtn">
            <img
              src="/assets/images/icons/left-arrow.png"
              alt="Left Arrow Icon"
              className="img-fluid"
            />
            Back
          </button>
          About you
          <button className="save_btn disabled">Save</button>
        </h4>
        <form onSubmit={this.onAboutYouSubmit}>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon user_icon user_icon">
                <img
                  src="/assets/images/icons/user.png"
                  alt="User Icon"
                  className="img-fluid"
                />
              </span>
              Profile
            </h5>

            <div className="row">
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name*"
                  name="userFirstName"
                  value={this.state.userFirstName}
                  onChange={this.handleAboutYouChange}
                  readOnly
                />
                <small className="text-danger">
                  {formErrors.userFirstName}
                </small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name*"
                  name="userLastName"
                  value={this.state.userLastName}
                  onChange={this.handleAboutYouChange}
                  readOnly
                />
                <small className="text-danger">{formErrors.userLastName}</small>
              </div>
              <div className="form-group col-md-6 col-lg-12 col-xl-6">
                <div className="mobile_no_wrap">
                  <div className="form-control countrycode">
                    <span className="code">+91</span>
                  </div>
                  <input
                    type="tel"
                    className="form-control tel_number"
                    placeholder="Mobile Number*"
                    value={this.state.userMobile}
                    name="userMobile"
                    onChange={this.handleAboutYouChange}
                    maxLength="10"
                    readOnly
                  />
                </div>
                <small className="form-text text-muted">
                  Enter your 10 digit mobile number.
                </small>
                <small className="text-danger">{formErrors.userMobile}</small>
                <div className="custome_check">
                  <label>
                    WhatsApp Number same as Mobile Number.
                    <input
                      name="whatsappSelected"
                      type="checkbox"
                      checked={this.state.whatsappSelected}
                      onChange={this.handleInputChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="form-group col-md-6 col-lg-12 col-xl-6">
                <div className="mobile_no_wrap">
                  <div className="form-control countrycode">
                    <span className="code">+91</span>
                  </div>
                  <input
                    type="tel"
                    className="form-control tel_number"
                    placeholder="WhatsApp Number*"
                    value={this.state.whatsNumber}
                    name="whatsNumber"
                    onChange={this.handleAboutYouChange}
                    maxLength="10"
                  />
                </div>
                <small className="form-text text-muted">
                  Enter your 10 digit whatsapp number.
                </small>
                <small className="text-danger">{formErrors.whatsNumber}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email ID*"
                  value={this.state.userEmail}
                  name="userEmail"
                  onChange={this.handleAboutYouChange}
                  readOnly
                />
                <small className="text-danger">{formErrors.userEmail}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <div className="radio_wrap">
                  <label className="d-block">Gender*</label>
                  <ul className="radio_list">
                    <li>
                      <div className="custome_check">
                        <label>
                          Female
                          <input
                            type="radio"
                            name="Female"
                            onChange={this.handleRadioChange}
                            value="Female"
                            checked={this.state.genderSelected === "Female"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custome_check">
                        <label>
                          Male
                          <input
                            type="radio"
                            name="Male"
                            onChange={this.handleRadioChange}
                            value="Male"
                            checked={this.state.genderSelected === "Male"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <small className="text-danger">
                  {formErrors.genderSelected}
                </small>
              </div>
              <div className="form-group col-md-12 col-lg-12 col-xl-6">
                <label className="d-block">Upload your profile picture*</label>
                <div className="fileUpload d-flex">
                  <button className="fileUploadBtn form-control d-inline-flex"></button>
                  <div className="d-inline-flex align-items-center form-control">
                    {this.state.profilePhotoName === "" ? (
                      <span className="file_text">
                        Choose file
                        <small>No file selected</small>
                      </span>
                    ) : (
                      <span className="file_text">
                        {this.state.profilePhotoName}
                      </span>
                    )}
                    <input
                      type="file"
                      className="uploadlogo"
                      name="profile_image"
                      onChange={this.onChangeProfilePhoto}
                    />
                  </div>
                </div>
                <small className="form-text text-muted">
                  One file only. 64 MB limit. Allowed types: png gif jpg jpeg.
                </small>
                {this.state.errorProfileValid ? (
                  <small className="form-text red_000">
                    {this.state.errorProfileValid}
                  </small>
                ) : null}
                {formErrors.profilePhotoName ? (
                  <small className="text-danger">
                    {formErrors.profilePhotoName}
                  </small>
                ) : null}
              </div>
              <div className="form-group col-md-12 col-lg-12 col-xl-6">
                <label className="text-color-hide business_label_hide">
                  lorem for text hide
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Brand or Business Name"
                  name="brandName"
                  value={this.state.brandName}
                  onChange={this.handleAboutYouChange}
                />
                <small className="form-text text-muted">
                  Please enter how you would like your business to be known on
                  the platform.
                </small>
              </div>
              <div className="form-group col-md-12 col-lg-12 col-xl-6">
                <label className="d-block">Upload your brand logo</label>
                <div className="fileUpload d-flex">
                  <button className="fileUploadBtn form-control d-inline-flex"></button>
                  <div className="d-inline-flex align-items-center form-control">
                    {this.state.profilePhoto2Name === "" ? (
                      <span className="file_text">
                        Choose file
                        <small>No file selected</small>
                      </span>
                    ) : (
                      <span className="file_text">
                        {this.state.profilePhoto2Name}
                      </span>
                    )}
                    <input
                      type="file"
                      className="uploadlogo"
                      onChange={this.onChangeProfilePhoto2}
                    />
                  </div>
                </div>
                <small className="form-text text-muted">
                  One file only. 64 MB limit. Allowed types: png gif jpg jpeg.
                </small>
                <small className="form-text red_000">
                  {this.state.errorBrandImage ? this.state.errorBrandImage : ""}
                </small>
              </div>
              <div className="form-group col-md-12 col-lg-12 col-xl-6">
                <label className="text-color-hide std_label_hide">
                  lorem for text hide
                </label>
                <div className="mobile_no_wrap std_wrap">
                  <div className="countrycode p-0">
                    {/* <span className="code light_text">022</span> */}
                    <input
                      type="tel"
                      className="form-control tel_number p-0 px-1 px-md-2 text-center"
                      placeholder="STD"
                      name="stdCode"
                      value={this.state.stdCode}
                      onChange={this.handleAboutYouChange}
                      maxLength="5"
                    />
                  </div>
                  <input
                    type="tel"
                    className="form-control tel_number"
                    placeholder="Phone Number"
                    name="telephoneNo"
                    value={this.state.telephoneNo}
                    onChange={this.handleAboutYouChange}
                    maxLength="8"
                    minLength="6"
                  />
                </div>
                <small className="form-text text-muted">
                  STD Code with Landline Phone Number.
                </small>
                <small className="text-danger">{formErrors.stdCode}</small>
                <br />
                <small className="text-danger">{formErrors.telephoneNo}</small>
              </div>
            </div>
          </div>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon user_icon pin_icon">
                <img
                  src="/assets/images/icons/pin.png"
                  alt="Pin Icon"
                  className="img-fluid"
                />
              </span>
              Address
            </h5>

            <div className="row">
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="House/Flat No*"
                  name="houseNo"
                  value={this.state.houseNo}
                  onChange={this.handleAboutYouChange}
                />
                <small className="text-danger">{formErrors.houseNo}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Floor"
                  name="floor"
                  value={this.state.floor}
                  onChange={this.handleAboutYouChange}
                />
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Building/Society*"
                  name="building"
                  value={this.state.building}
                  onChange={this.handleAboutYouChange}
                />
                <small className="text-danger">{formErrors.building}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street*"
                  name="street"
                  value={this.state.street}
                  onChange={this.handleAboutYouChange}
                />
                <small className="text-danger">{formErrors.street}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pincode*"
                  name="pincode"
                  value={this.state.pincode}
                  onBlur={this.getAddressFromCode}
                  onChange={this.handleAboutYouChange}
                  maxLength="6"
                />
                <small className="form-text red_000">
                  {this.state.errorPincode ? this.state.errorPincode : ""}
                </small>
                <small className="text-danger">{formErrors.pincode}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Locality"
                  name="locality"
                  value={this.state.locality}
                  onChange={this.handleAboutYouChange}
                />
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City*"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleAboutYouChange}
                />
                <small className="text-danger">{formErrors.city}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State*"
                  name="enterState"
                  value={this.state.enterState}
                  onChange={this.handleAboutYouChange}
                />
                <small className="text-danger">{formErrors.enterState}</small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Landmark"
                  name="landmark"
                  value={this.state.landmark}
                  onChange={this.handleAboutYouChange}
                />
              </div>
            </div>
          </div>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon user_icon facebook_icon">
                <img
                  src="/assets/images/icons/facebook.png"
                  alt="Facebook Icon"
                  className="img-fluid"
                />
              </span>
              Social Media Handles
            </h5>

            <div className="row">
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Facebook"
                  name="facebookURL"
                  value={this.state.facebookURL}
                  onChange={this.handleAboutYouChange}
                />
                <small className="form-text text-muted">
                  Please copy and paste your Facebook profile link.
                </small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Instagram"
                  name="instagramURL"
                  value={this.state.instagramURL}
                  onChange={this.handleAboutYouChange}
                />
                <small className="form-text text-muted">
                  Please copy and paste your Instagram profile link.
                </small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Twitter"
                  name="twitterURL"
                  value={this.state.twitterURL}
                  onChange={this.handleAboutYouChange}
                />
                <small className="form-text text-muted">
                  Please copy and paste your Twitter profile link.
                </small>
              </div>
              <div className="form-group col-md-6 col-lg-6 col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Website/Blog"
                  name="websiteURL"
                  value={this.state.websiteURL}
                  onChange={this.handleAboutYouChange}
                />
                <small className="form-text text-muted">
                  Please copy and paste your Website/Blog link.
                </small>
              </div>
            </div>
          </div>

          <div className="form-group d-block text-right save_btn_next">
            <button
              type="submit"
              className="btn m"
              disabled={this.state.loading}
            >
              save and next
            </button>
          </div>
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={config.siteKey}
            onResolved={this.onResolved}
          />
        </form>
      </div>
    );
  }
}

export default AboutYouPage;
