/*
#############################################################################
# Name : userDetail.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to save the new chef details
#############################################################################
*/
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Header from '../../components/Header';
import WhatsAppButton from '../../components/whatsappButton';
import AboutYouPage from './aboutYou';
import AboutBusinessPage from './aboutBusiness';
import AboutKitchenPage from './aboutKitchen';
import RegSubscriptionPage from './regSubscription';
import SaveMenuPage from '../chefMenu/saveMenu';
import AddMenuPage from '../chefMenu/addMenu';
import UserAgreePage from '../landing/userAgree';
import { Link } from 'react-router-dom';
import { changeProfileImage, getUserSummaryAPI } from '../../core/services/APIservice';
import { connect } from "react-redux";

// React Notification
import { NotificationManager } from 'react-notifications';

class UserDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStatus: '',
            profilePhoto: '',
            userAboutYou: '',
            userBusiness: '',
            userKitchen: '',
            userAboutYouFlag: 0,
            userBusinessFlag: 0,
            userKitchenFlag: 0,
            userMenu: '',
            regPackage: '',
            mouSubmitted: '',
            isProfileSubmitted: '',
            marPackage: '',
            userMenu: '',
            errorUserImage: ""
        }
    }

    componentDidMount() {
        var userId = '';
        userId = localStorage.getItem('userID');
        if (localStorage.getItem('userID') === null) {
            this
                .props
                .history
                .push('/');
        }
        /* if (localStorage.getItem('userVerified') === '0') {
            this
                .props
                .history
                .push('/thank-you');
        } */
        var mobile = localStorage.getItem('userMobile');
        var email = localStorage.getItem('userEmail');
        var name = localStorage.getItem('userName');
        var image = localStorage.getItem('userImage');
        this.setState({ userMobile: mobile, userEmail: email, userName: name, profilePhoto: image });
        this.timerID = setInterval(() => this.checkStatus(), 1000);
        this.getSummary();              // Summary data is fetched
    }

    componentWillUnmount() {
        clearInterval(this.timerID);        // clearInterval data is fetched
    }

/* getSummaryFromCompoents function starts here */    
    getSummaryFromCompoents = () => {
        this.props.parentCallback("Hey Popsie, How’s it going?");
    }
/* getSummaryFromCompoents function ends here */    

/* checkStatus function starts here */    
    checkStatus() {
        var status = localStorage.getItem('currentStatus');
        if (status === null) {
            this.setState({ currentStatus: 1 });
        } else {
            this.setState({ currentStatus: parseInt(status) });
        }
        // this.getSummary();
    }
/* checkStatus function ends here */  

/* getSummary function starts here */  
    getSummary() {
        var userId = localStorage.getItem('userID');
        var accessToken = localStorage.getItem('accessToken');
        return getUserSummaryAPI(userId, accessToken).then((response) => {
            if (response.data['status'] === 200) {
                this.setState({
                    userAboutYou: response.data['data']['about_average'], userBusiness: response.data['data']['business_average'], userKitchen: response.data['data']['kitchen_average'], userMenu: response.data['data']['number_of_uploaded_menu'], userAboutYouFlag: response.data['data']['about_you_req_filled'], userBusinessFlag: response.data['data']['about_business_req_filled'], userKitchenFlag: response.data['data']['about_kitchen_req_filled'], regPackage: response.data['data']['registration_package'], marPackage: response.data['data']['marketing_package'], mouSubmitted: response.data['data']['is_mou_submitted'], isProfileSubmitted: response.data['data']['profile_submitted_at']
                })
            } else {
                if (response.status === 401) {
                    NotificationManager.error("Session expired. Please log in.", "Error!");
                    this.props.history.push('/signin-with-otp');
                    localStorage.clear();
                }
            }
        }).catch((error) => {
        })
    }
/* getSummary function ends here */

/* getSummaryFromComponents function starts here */ 
    getSummaryFromComponents = (childData) => {
        this.setState({
            userAboutYou: childData.about_average, userBusiness: childData.business_average, userKitchen: childData.kitchen_average, userMenu: childData.number_of_uploaded_menu, userAboutYouFlag: childData.about_you_req_filled, userBusinessFlag: childData.about_business_req_filled, userKitchenFlag: childData.about_kitchen_req_filled, regPackage: childData.registration_package, marPackage: childData.marketing_package, mouSubmitted: childData.is_mou_submitted, isProfileSubmitted: childData.profile_submitted_at
        })
    }
/* getSummaryFromComponents function ends here */ 

/* uploadProfile function starts here */ 
    uploadProfile() {
        document
            .getElementById("selectImage")
            .click()
    }
/* uploadProfile function ends here */ 

/* fileSelectHandler function starts here */ 
    fileSelectHandler = (event) => {
        var userId = localStorage.getItem('userID');
        var accessToken = localStorage.getItem('accessToken');
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
            this.setState({ errorUserImage: "Please select valid file type." });
            return false;
        } else {
            this.setState({ errorUserImage: "" });
        }
        var filename = event.target.files[0]['name'];
        return changeProfileImage(userId, accessToken, file, filename).then((response) => {
            if (response.data['status'] === 200) {
                this.setState({
                    profilePhoto: response.data['data']['profile_image']
                })
                localStorage.setItem('userImage', response.data['data']['profile_image']);
                setTimeout(() => {
                }, 1000);
                NotificationManager.success('Profile Image Uploaded', 'Successful!', 3000);
            }
        }).catch((error) => {
            NotificationManager.error('Failed to upload Profile Image', 'Error!');
        })
    }
/* fileSelectHandler function ends here */ 

/* getProfileFromComponent function starts here */ 
    getProfileFromComponent = (childData) => {
        this.setState({
            profilePhoto: childData.profile_image
        })
        localStorage.setItem('userImage', childData.profile_image)
    }
/* getProfileFromComponent function ends here */ 

/* getUserInfoFromComponent function starts here */ 
    getUserInfoFromComponent = (childData) => {
        this.setState({
            userName: childData.name,
            userMobile: childData.mobile_number
        })
        localStorage.setItem('userName', childData.name);
        localStorage.setItem('userMobile', childData.mobile_number);
    }
/* getUserInfoFromComponent function ends here */

/* getAboutYou function starts here */ 
    getAboutYou = (event) => {
        event.preventDefault();
        localStorage.setItem('currentStatus', 1);
    }
/* getAboutYou function ends here */

/* getAboutBusiness function starts here */
    getAboutBusiness = (event) => {
        event.preventDefault();
        localStorage.setItem('currentStatus', 2);
    }
/* getAboutBusiness function ends here */

/* getAboutKitchen function starts here */
    getAboutKitchen = (event) => {
        event.preventDefault();
        localStorage.setItem('currentStatus', 3);
    }
/* getAboutKitchen function ends here */

/* getAboutMenu function starts here */
    getAboutMenu = (event) => {
        event.preventDefault();
        localStorage.setItem('currentStatus', 5);
    }
/* getAboutMenu function ends here */

/* getMou function starts here */
    getMou = (event) => {
        event.preventDefault();
        localStorage.setItem('currentStatus', 6);
    }
/* getMou function ends here */

/* getUserPackage function starts here */
    getUserPackage = (event) => {
        event.preventDefault();
        localStorage.setItem('currentStatus', 7);
    }
/* getUserPackage function ends here */

    render() {
        if (this.props.userData.userType !== "Chef") {
            return <Redirect to="/" />
        }
        const pageStatus = this.state.currentStatus;
        return (
            <div>
                <Header />
                <section className="registration">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-xl-3" id="SidebarMenu">
                                <div id="sticky-anchor"></div>
                                <div id="menu-box" className="sidebar" data-simplebar>
                                    <div className="about_details text-center">
                                        <div className="profile_img mx-auto">
                                            <div className="inner">
                                                {this.state.profilePhoto === 'null'
                                                    ? <img
                                                        src="assets/images/icons/proflie-img2.png"
                                                        alt="profile img"
                                                        className="img-fluid" />
                                                    : <img src={this.state.profilePhoto} alt="profile img" className="img-fluid" />}
                                            </div>
                                            <button className="pro_edit_btn" onClick={this.uploadProfile}>
                                                <img
                                                    src="/assets/images/icons/photograph.png"
                                                    alt="Photograph Icon"
                                                    className="img-fluid" />
                                            </button>
                                            <input id='selectImage' hidden type="file" onChange={this.fileSelectHandler} />
                                        </div>
                                        <small className="form-text red_000">
                                            {this.state.errorUserImage ? this.state.errorUserImage : ""}
                                        </small>
                                        <h5>{this.state.userName}</h5>
                                        <h6>+91 {this.state.userMobile}</h6>
                                       {localStorage.getItem("userVerified") == "1" ? <Link to={"/chef-dashboard"} className="edit_profile_link">Go to Dashboard</Link>:<Link to={'/edit-profile'} className="edit_profile_link">Edit Profile</Link>} 
                                    </div>
                                    <p className="user_note_edite">Click below and start your Chef Pin registration</p>
                                    <div className="about_you_box">
                                        <ul>
                                            <li>
                                                <h6>
                                                    <span className="d-inline-flex align-items-center">About you
                                                    <button className="edit_btn" onClick={this.getAboutYou}></button>
                                                    </span>
                                                </h6>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: this.state.userAboutYou + '%'
                                                        }}
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                                <span className="complete_per">{this.state.userAboutYou}% Complete</span>
                                                {this.state.userAboutYou === 100 ? <p>Congratulations! You have completed this section.</p> : <p>{100 - this.state.userAboutYou}% left to complete this section!</p>}
                                            </li>

                                            <li className={this.state.userBusiness !== 0 || this.state.userAboutYouFlag == 1 ? '' : "disabled"}>
                                                <h6>
                                                    <span className="d-inline-flex align-items-center">About your business
                                                    <button className="edit_btn" onClick={this.getAboutBusiness}></button>
                                                    </span>
                                                </h6>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: this.state.userBusiness + '%'
                                                        }}
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                                <span className="complete_per">{this.state.userBusiness}% Complete</span>
                                                {this.state.userBusiness === 100 ? <p>Congratulations! You have completed this section.</p> : <p>{100 - this.state.userBusiness}% left to complete this section!</p>}
                                            </li>
                                            <li className={this.state.userKitchen !== 0 || this.state.userBusinessFlag == 1 ? '' : "disabled"}>
                                                <h6>
                                                    <span className="d-inline-flex align-items-center">About your kitchen
                                                    <button className="edit_btn" onClick={this.getAboutKitchen}></button>
                                                    </span>
                                                </h6>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: this.state.userKitchen + '%'
                                                        }}
                                                        aria-valuenow="100"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                                <span className="complete_per">{this.state.userKitchen}% Complete</span>
                                                {this.state.userKitchen === 100 ? <p>Congratulations! You have completed this section.</p> : <p>{100 - this.state.userKitchen}% left to complete this section!</p>}
                                            </li>
                                            <li className={this.state.userMenu !== 0 || this.state.userKitchenFlag == 1 ? '' : "disabled"}>
                                                <h6 className="d-flex justify-content-between align-items-center">
                                                    <span className="d-inline-flex align-items-center">Menu
                                                    <button className="edit_btn" onClick={this.getAboutMenu}></button>
                                                    </span>
                                                    <span className="complete_per item_updated d-inline-block mb-0">{this.state.userMenu} items uploaded</span>
                                                </h6>
                                                <p>Upload your menu and complete registration!</p>
                                                {/* <button className="add_items_btn d-flex align-items-center">
                                                <span className="plus_icon"></span>
                                                <span>Add items</span>
                                            </button> */}
                                            </li>
											<li className={this.state.userMenu !== 0 || (this.state.isProfileSubmitted !== null && this.state.isProfileSubmitted !== '' && this.state.mouSubmitted != 0) ? '' : "disabled"}>
                                                <h6 className="d-flex justify-content-between align-items-center">
                                                    <span className="d-inline-flex align-items-center">Mou
                                                    <button className="edit_btn" onClick={this.getMou}></button>
                                                    </span>
                                                    <span className="complete_per item_updated d-inline-block mb-0">{this.state.isProfileSubmitted !== null && this.state.isProfileSubmitted !== '' && this.state.mouSubmitted != 0 ? <p><b class="text-success">Accepted</b></p> : <p><b class="text-danger">Not Accepted</b></p>}</span>
                                                </h6>
                                                {/* <button className="add_items_btn d-flex align-items-center">
                                                <span className="plus_icon"></span>
                                                <span>Add items</span>
                                            </button> */}
                                            </li>
											<li className={this.state.userMenu !== 0  && 
											(this.state.mouSubmitted != 0 && 
											this.state.isProfileSubmitted != '' && 
											this.state.isProfileSubmitted != null) ? '' : "disabled"}>
                                                <h6 className="d-flex justify-content-between align-items-center">
                                                    <span className="d-inline-flex align-items-center">Subscription Package
                                                    <button className="edit_btn" onClick={this.getUserPackage}></button>
                                                    </span>
                                                </h6>
												{this.state.regPackage != '' ? <p>Reg. Package enabled till {this.state.regPackage}.</p> : <p><b class="text-danger">Not Registered</b></p>}
												{this.state.marPackage != '' ? <p>Mar. Package enabled till {this.state.marPackage}.</p> : <p></p>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-xl-9" id="SidebarContent">
                                <div className="top_progess">
                                    {pageStatus === 1
                                        ? <ul className="progressbar">

                                            <li className="active">
                                                <span className="check_icon"></span>
                                                <span className="text">About <br />you</span>
                                            </li>
                                            <li>
                                                <span className="check_icon"></span>
                                                <span className="text">About your business</span>
                                            </li>
                                            <li>
                                                <span className="check_icon"></span>
                                                <span className="text">About your kitchen</span>
                                            </li>
                                            <li>
                                                <span className="check_icon"></span>
                                                <span className="text">Menu</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Mou</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Package</span>
                                            </li>
                                        </ul>
                                        : <ul></ul>
                                    }
                                    {pageStatus === 2
                                        ? <ul className="progressbar">
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About you</span>
                                            </li>
                                            <li className="active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your business</span>
                                            </li>
                                            <li>
                                                <span className="check_icon"></span>
                                                <span className="text">About your kitchen</span>
                                            </li>
                                            <li>
                                                <span className="check_icon"></span>
                                                <span className="text">Menu</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Mou</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Package</span>
                                            </li>
                                        </ul>
                                        : <ul></ul>
                                    }
                                    {pageStatus === 3
                                        ? <ul className="progressbar">
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About you</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your business</span>
                                            </li>
                                            <li className="active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your kitchen</span>
                                            </li>
                                            <li>
                                                <span className="check_icon"></span>
                                                <span className="text">Menu</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Mou</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Package</span>
                                            </li>
                                        </ul>
                                        : <ul></ul>
                                    }
                                    {pageStatus === 4
                                        ? <ul className="progressbar">
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About you</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your business</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your kitchen</span>
                                            </li>
                                            <li className="active">
                                                <span className="check_icon"></span>
                                                <span className="text">Menu</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Mou</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Package</span>
                                            </li>
                                        </ul>
                                        : <ul></ul>
                                    }
                                    {pageStatus === 5
                                        ? <ul className="progressbar">
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About you</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your business</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your kitchen</span>
                                            </li>
                                            <li className="active">
                                                <span className="check_icon"></span>
                                                <span className="text">Menu</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Mou</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Package</span>
                                            </li>
                                        </ul>
                                        : <ul></ul>
                                    }
                                    {pageStatus === 6
                                        ? <ul className="progressbar">
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About you</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your business</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your kitchen</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">Menu</span>
                                            </li>
											<li className="active">
                                                <span className="check_icon"></span>
                                                <span className="text">Mou</span>
                                            </li>
											<li>
                                                <span className="check_icon"></span>
                                                <span className="text">Package</span>
                                            </li>
                                        </ul>
                                        : <ul></ul>
                                    }
									{pageStatus === 7
                                        ? <ul className="progressbar">
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About you</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your business</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">About your kitchen</span>
                                            </li>
                                            <li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">Menu</span>
                                            </li>
											<li className="active check_active">
                                                <span className="check_icon"></span>
                                                <span className="text">Mou</span>
                                            </li>
											<li className="active">
                                                <span className="check_icon"></span>
                                                <span className="text">Package</span>
                                            </li>
                                        </ul>
                                        : <ul></ul>
                                    }
                                </div>
                                {pageStatus === 1
                                    ? <AboutYouPage parentCallback={this.getSummaryFromComponents} profileDataCallBack={this.getProfileFromComponent} userInfoDataCallBack={this.getUserInfoFromComponent} />
                                    : null
                                }
                                {pageStatus === 2
                                    ? <AboutBusinessPage parentCallback={this.getSummaryFromComponents} />
                                    : null
                                }
                                {pageStatus === 3
                                    ? <AboutKitchenPage parentCallback={this.getSummaryFromComponents} />
                                    : null
                                }
                                {pageStatus === 4
                                    ? <SaveMenuPage parentCallback={this.getSummaryFromComponents} />
                                    : null
                                }
                                {pageStatus === 5
                                    ? <AddMenuPage parentCallback={this.getSummaryFromComponents} />
                                    : null
                                }
								
								{pageStatus === 6
                                    ? <UserAgreePage parentCallback={this.getSummaryFromComponents} />
                                    : null
                                }
								
								{pageStatus === 7
                                    ? <RegSubscriptionPage parentCallback={this.getSummaryFromComponents} />
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <div className="nofooter">
          <WhatsAppButton />
        </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.items,
  });
  
  export default connect(mapStateToProps)(
    UserDetailsPage
  );
  
