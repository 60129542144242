import { INCREASE_PRODUCT_QTY, DECREASE_PRODUCT_QTY, EMPTY_CART} from "./types";

export const increaseQuantity = (cartItems, menuItem) => (dispatch) => {
    const cartItemsArr = cartItems.slice();
    let itemAlreadyInCart = false;
    cartItemsArr.forEach(item => {
        if (item.menu_id == menuItem.menu_id) {
            itemAlreadyInCart = true;
            item.qty++;
        }
    });
    if (!itemAlreadyInCart) {
        cartItemsArr.push(menuItem)
    }
    return dispatch({
        type: INCREASE_PRODUCT_QTY,
        payload: {
            cartItems: cartItemsArr
        }
    });
}

export const decreaseQuantity = (cartItems, menuItem) => (dispatch) => {
    let cartItemsArr = cartItems.slice();
    let removeMenuId = ""
    cartItemsArr.forEach(item => {
        if (item.menu_id == menuItem.menu_id) {
            if (item.qty > 1) {
                item.qty--
            } else {
                removeMenuId = menuItem.menu_id
            }
        }
        if (removeMenuId !== "") {
            let array = cartItemsArr.filter(elm => elm.menu_id !== removeMenuId)
            cartItemsArr = array
        }
    });
    if (!cartItemsArr.length) {
        localStorage.setItem("dinerTakeawayChoice", false)
    }
    return dispatch({
        type: DECREASE_PRODUCT_QTY,
        payload: {
            cartItems: cartItemsArr
        }
    });
}

export const emptyCart = () => (dispatch) => {
    return dispatch({
        type: EMPTY_CART,
        payload: {
            cartItems: []
        }
    });
}