/*
#############################################################################
# Name : LoadingDiv.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display loading component
#############################################################################
*/
import React, { Component } from 'react'

export default class LoadingDiv extends Component {
    render() {
        return (
            <section className="loader">
                <div className="container">
                    <div className="center">
                        <span>
                            <img src="/assets/images/icons/chefpinlogo.svg" alt="Logo" />
                        </span>
                        <div className="loading_wrap">
                            <div className="loading_wrap">
                                <div className="loading-line-low"> </div>
                                <div className="loading-line-High"> </div>
                            </div>
                            <div className="text_wrap">
                                <div id="loading-text"> Loading
                                    <div className="wrap">
                                        <p className="point-loading1">.</p>
                                        <p className="point-loading2">.</p>
                                        <p className="point-loading3">.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
