import React, { useState } from "react";
import MultiSelectDropdown from "../../components/MultiSelect";
import Select from "react-select";

function PartyOrderForm2() {
    const [formData, setFormData] = useState({
        diner_name: "",
        diner_mobile_number: "",
        diner_email: "",
        location: "",
        locality: "",

        party_date: "",
        party_time: "",
        number_of_guests: "",
        prefer_cuisine: [],
        do_you_require: [],
        live_cooking: false,
        place_tandoor: false,
        permit_kitchen_used: false,
        special_request: "",
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const preffered_cuisine = [
        { value: "North Indian", label: "North Indian" },
        { value: "South Indian", label: "South Indian" },
        { value: "Pan-Asian", label: "Pan-Asian" },
        { value: "Continental", label: "Continental" },
        { value: "Snacks", label: "Snacks" },
        { value: "Desserts", label: "Desserts" },
        { value: "custom", label: "Other-enter details" },
    ];
    const locations = [
        { value: "Delhi", label: "Delhi" },
        { value: "Gurgaon", label: "Gurgaon" },
        { value: "Noida", label: "Noida" },
        { value: "Mumbai", label: "Mumbai" },
        { value: "Bengaluru", label: "Bengaluru" },
    ];
    const requirements = [
        { value: "Serving Staff", label: "Serving Staff" },
        { value: "Crockery/Cultery", label: "Crockery/Cultery" },
        { value: "Chafing Dishes", label: "Chafing Dishes" },
    ];
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(formData);

        const prefer_cuisineValues = formData.prefer_cuisine.map((item) => {
            return item.value;
        });
        // const location =  formData.location.map(item =>{ return item.value})

        var formdata = new FormData();

        formdata.append("diner_name", formData.diner_name);
        formdata.append("diner_email", formData.diner_email);
        formdata.append("diner_mobile_number", formData.diner_mobile_number);
        formdata.append("location", formData.location);

        formdata.append("locality", formData.locality);
        formdata.append("party_date", formData.party_date);
        formdata.append("party_time", formData.party_time);
        formdata.append("number_of_guests", formData.number_of_guests);
        formdata.append("prefer_cuisine", prefer_cuisineValues.toString());
        // formdata.append("prefer_cuisine_other", "fffddd");
        formdata.append("do_you_require", formData.do_you_require.toString());
        formdata.append("live_cooking", formData.live_cooking);
        formdata.append("place_tandoor", formData.place_tandoor);
        formdata.append("permit_kitchen_used", formData.permit_kitchen_used);
        formdata.append("special_request", formData.special_request);

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(
            "https://api-dev.chefp.in/api/v1/store-party-order-enquiry",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                // console.log(formData);
                if (result.message == "Party Enquiry Save Successfully.") {
                    // alert(
                    //     "We have received your Party Enquiry. We will get our selected home chefs to contact you shortly."
                    // );
                    // window.location.reload();
                    window.location.href = "/party-order-thank-you";
                } else {
                    alert("Error" + "", result.error);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const [customValue, setCustomValue] = useState("");
    const handleAddCustomValue = () => {
        if (customValue) {
            const newOption = {
                value: customValue,
                label: customValue,
            };
            setFormData({
                ...formData,
                prefer_cuisine: [...formData.prefer_cuisine, newOption],
            });
            setCustomValue("");
        }
    };
    return (
        <form
            onSubmit={handleSubmit}
            style={{
                maxWidth: "700px",
                margin: "0 auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                backgroundColor: "#f8f8f8",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            <h2 className="text-uppercase color_C07 mb-4">Enquire for Party Orders</h2>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Full Name*:
                    </label>
                    <input
                        type="text"
                        name="diner_name"
                        value={formData.diner_name}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Mobile*:
                    </label>
                    <input
                        type="tel"
                        name="diner_mobile_number"
                        value={formData.diner_mobile_number}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Email*:
                    </label>
                    <input
                        type="email"
                        name="diner_email"
                        value={formData.diner_email}
                        onChange={handleInputChange}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Location*:
                    </label>

                    <Select
                        options={locations}
                        onChange={(text) => {
                            setFormData({ ...formData, location: text.value });
                        }}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Locality*:
                    </label>
                    <input
                        type="text"
                        name="locality"
                        value={formData.locality}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Party Date*:
                    </label>
                    <input
                        type="date"
                        name="party_date"
                        value={formData.party_date}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Party Time*:
                    </label>
                    <input
                        type="time"
                        name="party_time"
                        value={formData.party_time}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Number of Guests*:
                    </label>
                    <input
                        type="number"
                        name="number_of_guests"
                        value={formData.number_of_guests}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Preferred Cuisine:
                    </label>

                    <Select
                        options={preffered_cuisine}
                        isMulti
                        value={formData.prefer_cuisine}
                        onChange={(text) => {
                            setFormData({ ...formData, prefer_cuisine: text });
                        }}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                {formData.prefer_cuisine.some(
                    (option) => option.value === "custom"
                ) && (
                        <div style={{ marginLeft: "9.2em" }}>
                            <input
                                type="text"
                                value={customValue}
                                onChange={(e) => {
                                    setCustomValue(e.target.value);
                                }}
                                style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                            />
                            <button onClick={handleAddCustomValue}>Add</button>
                        </div>
                    )}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Do you require:
                    </label>

                    <Select
                        options={requirements}
                        isMulti
                        onChange={(text) => {
                            const data = text.map((item) => {
                                return item.value;
                            });

                            setFormData({ ...formData, do_you_require: data });
                        }}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Are you looking for Live cooking?
                    </label>
                    <input
                        type="checkbox"
                        name="live_cooking"
                        checked={formData.live_cooking}
                        onChange={handleCheckboxChange}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Do you have place for a Tandoor?
                    </label>
                    <input
                        type="checkbox"
                        name="place_tandoor"
                        checked={formData.place_tandoor}
                        onChange={handleCheckboxChange}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Will you permit your kitchen to be used?
                    </label>
                    <input
                        type="checkbox"
                        name="permit_kitchen_used"
                        checked={formData.permit_kitchen_used}
                        onChange={handleCheckboxChange}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Any special requests:
                    </label>
                    <textarea
                        name="special_request"
                        value={formData.special_request}
                        onChange={handleInputChange}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button type="submit" style={{
                        backgroundColor: "#007BFF",
                        color: "#fff",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                    }}>Submit</button>
                </div>
            </div>
        </form>
    );
}

export default PartyOrderForm2;
