import { ADD_TIME_SLOT, REMOVE_TIME_SLOT } from "../actions/types";

export const addTimeSlot = (day, time) => (dispatch) => {
    let timeSlotArr = {
        slotDay: day,
        slotTime: time
    }
    return dispatch({
        type: ADD_TIME_SLOT,
        payload: {
            timeSlot: timeSlotArr
        }
    });
}

export const removeTimeSlot = () => (dispatch) => {
    return dispatch({
        type: REMOVE_TIME_SLOT,
        payload: {
            timeSlot: []
        }
    });
}