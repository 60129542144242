import { ADD_TIME_SLOT, REMOVE_TIME_SLOT } from "../actions/types";

const initialState = { items: [] }
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TIME_SLOT:
            return {
                items: action.payload.timeSlot
            }
    
            case REMOVE_TIME_SLOT:
            return {
                items: action.payload.timeSlot
            }

        default:
            return state;
    }
}