import { ADD_MOBILE_VIEW, REMOVE_MOBILE_VIEW } from "../actions/types";

const initialState = { isActive: false }
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_MOBILE_VIEW:
            return {
                isActive: action.payload.mobileView
            }

        case REMOVE_MOBILE_VIEW:
            return {
                isActive: action.payload.mobileView
            }

        default:
            return state;
    }
}