import { INCREASE_PRODUCT_QTY, DECREASE_PRODUCT_QTY, EMPTY_CART } from "../actions/types";

const initialState = { items: [] }
export default function (state = initialState, action) {
    switch (action.type) {
        case INCREASE_PRODUCT_QTY:
            return {
                items: action.payload.cartItems
            }

        case DECREASE_PRODUCT_QTY:
            return {
                items: action.payload.cartItems
            }

        case EMPTY_CART:
            return {
                items: action.payload.cartItems
            }

        default:
            return state;
    }
}