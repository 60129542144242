/*
#############################################################################
# Name : dinerSignUpPage.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to save new diner details 
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../components/dinnerHeader";
import WhatsAppButton from "../../../components/whatsappButton";
import ReactFormInputValidation from "react-form-input-validation";
import { dinerSignupAPI } from "../../../core/services/APIservice";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { saveUserData } from "../../../redux/actions/authActions";

ReactFormInputValidation.register(
  "alpha_space",
  function (value, requirement, attribute) {
    return value.match(/^[a-zA-Z ]+$/);
  },
  "Enter valid name"
);

class DinerSignUpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        mobileNumber: "",
      },
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      errors: {},
      isLogined: false,
      loading: false,
      accessToken: "",
      userName: "",
      userEmail: "",
      userImage: "",
      socailProvider: "",
    };

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      firstname: "required|alpha_space",
      lastname: "required|alpha_space",
      email: "required|email",
      password: "required|min:6",
      mobileNumber: "required|numeric|digits_between:10,12",
    });

    /* onformsubmit function starts here */
    this.form.onformsubmit = (fields) => {
      this.recaptcha.execute();
      this.setState({ loading: true });
      return dinerSignupAPI(
        fields.firstname,
        fields.lastname,
        fields.email,
        fields.mobileNumber,
        fields.password
      )
        .then((response) => {
          var tempId,
            userEmail,
            userName,
            userMobileNo = "";
          if (response.data["status"] === 200) {
            tempId = response.data["data"]["user_id"];
            userEmail = response.data["data"]["email"];
            userName = response.data["data"]["first_name"];
            userMobileNo = response.data["data"]["mobile_number"];
            localStorage.setItem("tempId", tempId);
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("userMobile", userMobileNo);
            localStorage.setItem("userName", userName);
            this.setState({ loading: false });
            if (this.props.location.search === "?checkout") {
              this.props.history.push("/diner-signup-verify?checkout");
            } else if(this.props.location.search === "?chef-innerpage"){
              this.props.history.push("/diner-signup-verify?chef-innerpage");
            }else {
              this.props.history.push("/diner-signup-verify");
            }
            NotificationManager.success(
              "Welcome to CHEFPIN " + userName,
              "Successful!",
              3000
            );
          } else if (response.status === 403) {
            this.setState({ loading: false });
            if (response.data.error.first_name) {
              NotificationManager.error(
                response.data.error.first_name,
                "Error!"
              );
            } else if (response.data.error.last_name) {
              NotificationManager.error(
                response.data.error.last_name,
                "Error!"
              );
            } else {
              NotificationManager.error(response.data.message, "Error!");
            }
          } else if (response.status === 500) {
            this.setState({ loading: false });
            NotificationManager.error(response.data.message, "Error!");
          } else {
            this.setState({ loading: false });
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          // you will get error here.
          this.setState({ loading: false });
          NotificationManager.error(
            "Something went wrong while Registration",
            "Error!"
          );
          this.recaptcha.reset();
        });
    };
    /* onformsubmit function ends here */
  }

  componentDidMount() {
    this.myInterval = setInterval(this.triggerFacebookLogin, 2000);
    window.scrollTo({ top: 0, behavior: "smooth" });
    var mobile = localStorage.getItem("userMobile");
    let fields = this.state.fields;
    fields.mobileNumber = mobile;
    this.setState({
      fields,
      mobileNumber: fields.mobileNumber,
    });
  }

  componentWillUnmount() {
    clearInterval(this.myInterval)
  }

  /* responseFacebook function starts here */
  responseFacebook = () => {
    // if (response.accessToken) {
    var firstName = localStorage.getItem("facebook_user_firstname")
    var lastName = localStorage.getItem("facebook_user_lastname")
    var email = localStorage.getItem("facebook_user_email")
    var picture = JSON.parse(localStorage.getItem("facebook_user_picture"))
    var accessToken = localStorage.getItem("facebook_user_token")
    var mobile = localStorage.getItem("userMobile");
    if (email && email !== "" && email !== null) {
      return dinerSignupAPI(
        firstName,
        lastName,
        email,
        mobile,
        "",
        firstName + " " + lastName,
        picture.data.url,
        accessToken,
        "Facebook"
      )
        .then((response) => {
          var tempId,
            userEmail,
            userName,
            userMobileNo = "";
          if (response.data["status"] === 200) {
            tempId = response.data["data"]["user_id"];
            userEmail = response.data["data"]["email"];
            userName = response.data["data"]["first_name"];
            userMobileNo = response.data["data"]["mobile_number"];
            localStorage.setItem("tempId", tempId);
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("userMobile", userMobileNo);
            localStorage.setItem("userName", userName);

            localStorage.removeItem("facebook_logged_in")
            localStorage.removeItem("facebook_user_email")
            localStorage.removeItem("facebook_user_firstname")
            localStorage.removeItem("facebook_user_lastname")
            localStorage.removeItem("facebook_user_picture")
            localStorage.removeItem("facebook_user_token")

            this.setState({ loading: false });
            if (this.props.location.search === "?checkout") {
              this.props.history.push("/diner-signup-verify?checkout");
            } else if(this.props.location.search === "?chef-innerpage"){
              this.props.history.push("/diner-signup-verify?chef-innerpage");
            }else {
              this.props.history.push("/diner-signup-verify");
            }
            NotificationManager.success(
              "Welcome to CHEFPIN " + userName,
              "Successful!",
              3000
            );
          } else if (response.status === 500) {
            NotificationManager.error(response.data.message, "Error!");
          } else if (response.data.status === 405) {
            NotificationManager.error(response.data.message, "Error!");
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          // you will get error here.
          /*  console.log(error); */
          /*  this.setState({ loading: false }); */
          NotificationManager.error(
            "Something went wrong while Registration",
            "Error!"
          );
        });
    } else {
      NotificationManager.error("Use Email Id for Registration", "Error!");
    }
    // }
  };
   /* responseFacebook function ends here */

   /* login function starts here */
  login = async (response) => {
    if (response.accessToken) {
      console.log("in");
      var mobile = localStorage.getItem("userMobile");
      console.log(mobile);
      try {
        const response_1 = await dinerSignupAPI(
          "",
          "",
          response.profileObj["email"],
          mobile,
          "",
          response.profileObj["name"],
          response.profileObj["imageUrl"],
          response.accessToken,
          "Google"
        );
        console.log(response_1.data);
        if (response_1.data["status"] === 200) {
          var tempId,
            userEmail,
            userName,
            userMobileNo = "";
          tempId = response_1.data["data"]["user_id"];
          userEmail = response_1.data["data"]["email"];
          userName = response_1.data["data"]["first_name"];
          userMobileNo = response_1.data["data"]["mobile_number"];
          localStorage.setItem("tempId", tempId);
          localStorage.setItem("userEmail", userEmail);
          localStorage.setItem("userMobile", userMobileNo);
          localStorage.setItem("userName", userName);
          this.setState({ loading: false });
          if (this.props.location.search === "?checkout") {
            this.props.history.push("/diner-signup-verify?checkout");
          } else if(this.props.location.search === "?chef-innerpage"){
            this.props.history.push("/diner-signup-verify?chef-innerpage");
          }else {
            this.props.history.push("/diner-signup-verify");
          }
          NotificationManager.success(
            "Welcome to CHEFPIN " + userName,
            "Successful!",
            3000
          );
        } else if (response_1.status === 500) {
          NotificationManager.error(response_1.data.message, "Error!");
        } else if (response_1.data.status === 405) {
          NotificationManager.error(response_1.data.message, "Error!");
        } else {
          NotificationManager.error(response_1.data["message"], "Error!");
        }
      } catch (error) {
        // you will get error here.
        /* console.log(error); */
        NotificationManager.error(
          "Something went wrong while Registration",
          "Error!"
        );
      }
    }
  };
   /* login function ends here */

 /* handleLoginFailure function starts here */
  handleLoginFailure(response) {
    console.log(response);
  }
 /* handleLoginFailure function ends here */

  /* handleLogoutFailure function starts here */
  handleLogoutFailure(response) {
    console.log(response);
  }
/* handleLogoutFailure function ends here */

/* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved function ends here */

/* triggerFacebookLogin function starts here */
  triggerFacebookLogin = () => {
    if (localStorage.getItem("facebook_logged_in") === "true") {
      this.responseFacebook()
      localStorage.removeItem("facebook_logged_in")
    }
  }
/* triggerFacebookLogin function ends here */

  render() {
    if (!localStorage.getItem("userMobile")) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <div className="css2">
          <DinnerHeader />
          <section className="sign_up_chef diner_sign_up sign_chefdiner">
            <Link to="/diner-signup-mobile" className="back_btn">
              <img
                src="/assets/images/icons/black-arrow.svg"
                alt="Black Arrow"
              />
              Back
            </Link>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xl-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                  <div className="text pt-xl-0">
                    <h3 className="color_C07">REGISTER AS A DINER</h3>
                    <div className="form_wrap">
                      <form onSubmit={this.form.handleSubmit}>
                        <div className="form-group">
                          <div className="mobile_no_wrap">
                            <div className="form-control countrycode">
                              <span className="code">+91</span>
                            </div>
                            <input
                              type="tel"
                              className="form-control tel_number"
                              id="Phone"
                              placeholder="Mobile Number*"
                              name="mobileNumber"
                              onBlur={this.form.handleBlurEvent}
                              onChange={this.form.handleChangeEvent}
                              value={this.state.fields.mobileNumber}
                              maxLength="10"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name*"
                            name="firstname"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.firstname}
                          />
                          <small className="form-text red_000">
                            {this.state.errors.firstname
                              ? this.state.errors.firstname
                              : ""}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name*"
                            name="lastname"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.lastname}
                          />
                          <small className="form-text red_000">
                            {this.state.errors.lastname
                              ? this.state.errors.lastname
                              : ""}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email ID*"
                            name="email"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.email}
                          />
                          <small className="form-text red_000">
                            {this.state.errors.email
                              ? this.state.errors.email
                              : ""}
                          </small>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password*"
                            name="password"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.password}
                          />
                          <small
                            id="PasswordHelp"
                            className="form-text text-muted"
                          >
                            Minimum 6 characters.
                          </small>
                          <small className="form-text red_000">
                            {this.state.errors.password
                              ? this.state.errors.password
                              : ""}
                          </small>
                        </div>
                        <div className="form-group mb-0 social_center">
                          <button
                            type="submit"
                            className="btn"
                            disabled={this.state.loading}
                          >
                            Create Account
                          </button>
                          <span className="or_text">or Continue with</span>
                          <ul className="social_icon">
                            <li>

                              <GoogleLogin
                                clientId={config.CLIENT_ID}
                                buttonText=""
                                onSuccess={this.login}
                                onFailure={this.handleLoginFailure}
                                cookiePolicy={"single_host_origin"}
                                responseType="code,token"
                                className="img-fluid"
                              />
                            </li>
                            <li>

                              {/* <FacebookLogin
                                appId={config.APP_ID} //APP ID NOT CREATED YET
                                fields="name,email,picture"
                                callback={this.responseFacebook}
                                icon="fa-facebook"
                                textButton=""
                                disableMobileRedirect={true}
                              /> */}

                              <button type="button" className="fb-login-custom-button">
                                <i className="fa fa-facebook"></i>
                              </button>
                            </li>
                          </ul>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 d-lg-flex align-items-center align-items-xl-center pt-lg-5 pt-xl-0 d-none">
                  <div className="img_box pt-lg-3 pt-xl-0">
                    <img
                      src="/assets/images/sign-up-as-a-chef/sign-up-img.png"
                      alt="sign up img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="nofooter">
            <WhatsAppButton />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DinerSignUpPage;
