/*
#############################################################################
# Name : chefAccountSidebar.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the menu sidebar
#############################################################################
*/
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addMobileView } from "../../redux/actions/chefMenuActions";
import { changeProfileImage } from "../../core/services/APIservice";
import {
  handleChefAvailabilityToggleAPI,
  handleChefAuditMeToggleAPI,
} from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { saveUserData, removeUserData } from "../../redux/actions/authActions";
import Footer from "../../components/Footer";
import { saveOrderCountData } from "../../redux/actions/orderCountActions";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button,Modal} from 'react-bootstrap'; 
import ModalDialog from 'react-bootstrap/ModalDialog';

class ChefAccountSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAvailable: 0,
      is_available: 0,
      isEnableDate: '',
      auditMe: 0,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      orderCountData:0,
	  timeOption: false,
	  currentDate: new Date(new Date().setDate(new Date().getDate())),
	  show:false
    };
  }
  
  handleModal(){  
    this.setState({show:!this.state.show})  
  } 
  
  componentDidMount() {
    console.log("Order Count : ",this.props.orderCountData);
    this.setState({
      isAvailable: this.props.userData.isAvailable == 0 ? true : false,
      auditMe: this.props.userData.auditMe == 0 ? true : false,
    });
  }

  /* handleChefAvailabilityToggle function starts here */
  handleChefAvailabilityToggle = (e) => {
    let is_available = e.target.checked ? 0 : 1;
	if(is_available == 0){ // on disble call modal popup for ask date
		this.setState({
			is_available: is_available,
		});
		this.handleModal();
	}else{
		return handleChefAvailabilityToggleAPI(
		  parseFloat(this.props.userData.userId),
		  is_available,
		  '',
		  this.props.userData.accessToken
		)
       .then((response) => {
        if (response.data.status === 200) {
          let array = this.props.userData;
          array.isAvailable = is_available;
          array.isEnableDate = null;
          this.props.saveUserData(array);
          this.setState({
            isAvailable: is_available == 0 ? true : false,
          });
          var status = is_available == 0 ? "disabled" : "enabled";
          NotificationManager.success(
            "All orders " + status + " successfully",
            "Success!"
          );
        } else {
          NotificationManager.error(response.data.message, "Error!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* NotificationManager.error(error, "Error!"); */
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
	}
  };
  /* handleChefAvailabilityToggle function ends here */

  /* handleChefAuditMeToggle function starts here */
  handleChefAuditMeToggle = (e) => {
    console.log(e.target.checked);
    let auditMe = e.target.checked ? 0 : 1;
    return handleChefAuditMeToggleAPI(
      parseFloat(this.props.userData.userId),
      auditMe,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          let array = this.props.userData;
          array.auditMe = auditMe;
          this.props.saveUserData(array);
          this.setState({
            auditMe: auditMe == 0 ? true : false,
          });
          /*  var status = is_available == 0 ? "disabled" : "enabled";  */
          if (auditMe == 0) {
            NotificationManager.success("Audit Me deactivated", "Success!");
          } else {
            NotificationManager.success("Audit Me activated", "Success!");
          }
        } else {
          NotificationManager.error(response.data.message, "Error!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* NotificationManager.error(error, "Error!"); */
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  };
  /* handleChefAuditMeToggle function ends here */

  /* uploadProfile function starts here */
  uploadProfile() {
    document.getElementById("selectImage").click();
  }
  /* uploadProfile function ends here */

  /* fileSelectHandler function starts here */
  fileSelectHandler = (event) => {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      this.setState({ errorUserImage: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorUserImage: "" });
    }
    var filename = event.target.files[0]["name"];
    return changeProfileImage(userId, accessToken, file, filename)
      .then((response) => {
        if (response.data["status"] === 200) {
          let data = {
            userType: "Chef",
            userId: localStorage.getItem("userID"),
            userEmail: response.data["data"]["email"],
            userName:
              response.data["data"]["first_name"] +
              " " +
              response.data["data"]["last_name"],
            userMobile: response.data["data"]["mobile_number"],
            image: response.data["data"]["profile_image"],
            accessToken: localStorage.getItem("accessToken"),
            isAvailable: true,
            membership: localStorage.getItem("userMemberShip"),
          };
          this.props.saveUserData(data);
          localStorage.setItem(
            "userImage",
            response.data["data"]["profile_image"]
          );
          setTimeout(() => {}, 1000);
          NotificationManager.success(
            "Profile Image Uploaded",
            "Successful!",
            3000
          );
        }
      })
      .catch((error) => {
        NotificationManager.error("Failed to upload Profile Image", "Error!");
      });
  };
  /* fileSelectHandler function ends here */

  /* logoutHandler function starts here */
  logoutHandler = (e) => {
    NotificationManager.success("You have Logout Successfully", "Successful!");
    this.props.removeUserData();
    localStorage.clear();
    /*  this
             .props
             .history
             .push('/'); */
  };
  /* logoutHandler function ends here */

  render() {
    const { history, mobileView, userData } = this.props;
    return (
      <div
        className={
          mobileView
            ? "col-md-11 col-lg-4 col-xl-3 active"
            : "col-md-11 col-lg-4 col-xl-3"
        }
        id="SidebarMenu"
      >
        <div className="chef diner_sidebar">
          <div className="menu_list_wrap" data-simplebar>
            <div className="top_wrap">
              <div className="top">
                <div className="right">
                  <div className="profile_img">
                    <div className="inner">
                      <img
                        src={
                          this.props.userData.image !== ""
                            ? this.props.userData.image
                            : "assets/images/icons/proflie-img2.png"
                        }
                        alt="profile img"
                        className="img-fluid"
                      />
                    </div>
                    <button
                      className="pro_edit_btn"
                      onClick={this.uploadProfile}
                    >
                      <img
                        src="assets/images/icons/photograph.png"
                        alt="Photograph Icon"
                        className="img-fluid"
                      />
                    </button>
                    <input
                      id="selectImage"
                      hidden
                      type="file"
                      onChange={this.fileSelectHandler}
                    />
                  </div>
                </div>
                <div className="left">
                  <h6>{this.props.userData.userName}</h6>
                  {/* {this.props.userData.membership !== "basic" ? (
                    <div className="icons star">
                      <img src="assets/images/icons/star.svg" alt="" />
                      3.2
                      <span className="grey">/5</span>
                    </div>
                  ) : null} */}
                  <Link to={"/edit-profile"} className="edit_profile_link">
                    Edit Profile
                  </Link>
                </div>
              </div>
              {this.props.userData.membership === "basic" && (
                <p className="membership_basic_text">
                  You are currently on the Basic plan which does not allow you
                  to accept orders. Please upgrade to a paid plan to activate
                  orders on Chef Pin.
                </p>
              )}
              {this.props.userData.membership &&
                this.props.userData.membership !== "basic" && (
                  <div className="radio_wrap chef">
                    <div className="active_deact_toggle veg">
                      <div className="toggle_box">
                        <input
                          type="checkbox"
                          checked={this.state.isAvailable}
                          onChange={this.handleChefAvailabilityToggle}
                        />
                      </div>
                      <span className="act de_active">Disable All Orders</span>
                    </div>
                  </div>
                )}
				{this.props.userData.isEnableDate &&
				this.props.userData.isEnableDate != null && (
					<div style={{marginTop: "-35px",marginBottom: "35px"}}>Menu will be auto-enabled on {this.props.userData.isEnableDate}</div>
				)}
              {this.props.userData.membership &&
                this.props.userData.membership !== "basic" &&
                this.props.userData.membership !== "premium" && (
                  <div className="radio_wrap chef">
                    <div className="active_deact_toggle veg">
                      <div className="toggle_box">
                        <input
                          type="checkbox"
                          checked={this.state.auditMe}
                          onChange={this.handleChefAuditMeToggle}
                        />
                      </div>
                      <span className="act de_active">Audit Me Anytime </span>
                    </div>
                  </div>
                )}
              {this.props.userData.membership &&
                this.props.userData.membership !== "basic" &&
                this.props.userData.membership !== "premium" && (
                  <p className="membership_basic_text">
                    If you enable Audit-Me-Anytime you agree to surprise visits
                    by the Chef Pin team to check the hygiene of your kitchen
                    and quality of your ingredients. Diners will be able to see
                    when you have this pin enabled.
                  </p>
                )}
            </div>
            <ul className="menu">
              {this.props.userData.membership !== "basic" ? (
                <li
                  className={
                    history.location.pathname === "/chef-dashboard"
                      ? "active"
                      : null
                  }
                >
                  <Link
                    to="/chef-dashboard"
                    onClick={() => this.props.addMobileView()}
                    className="icon chef_dash_icon"
                  >
                    Dashboard
                  </Link>
                </li>
              ) : null}
              {this.props.userData.membership !== "basic" ? (
                <li
                  className={
                    history.location.pathname === "/orders-in-progress"
                      ? "active"
                      : null
                  }
                >
                  <Link
                    to="/orders-in-progress"
                    onClick={() => this.props.addMobileView()}
                    className="icon orders_progress_icon"
                  >
                    Orders in Progress {this.props.orderCountData == 0 ? null : <span>{'('+this.props.orderCountData+')'}</span>}
                  </Link>
                </li>
              ) : null}
              {this.props.userData.membership !== "basic" ? (
                <li
                  className={
                    history.location.pathname === "/delivered-orders"
                      ? "active"
                      : null
                  }
                >
                  <Link
                    to="/delivered-orders"
                    onClick={() => this.props.addMobileView()}
                    className="icon order_icon"
                  >
                    Delivered Orders
                  </Link>
                </li>
              ) : null}
              {this.props.userData.membership !== "basic" ? (
                <li
                  className={
                    history.location.pathname === "/chef-cancelled-orders"
                      ? "active"
                      : null
                  }
                >
                  <Link
                    to="/chef-cancelled-orders"
                    onClick={() => this.props.addMobileView()}
                    className="icon rejected_icon"
                  >
                    Cancelled Orders
                  </Link>
                </li>
              ) : null}
              <li
                className={
                  history.location.pathname === "/user-details"
                    ? "active"
                    : null
                }
              >
                <Link
                  to="/user-details"
                  onClick={() => this.props.addMobileView()}
                  className="icon edit_icon"
                >
                  Edit my Listing
                </Link>{" "}
              </li>
			  
			  <li
                className={
                  history.location.pathname === "/chef-subscribed-package-payment-history"
                    ? "active"
                    : null
                }
              >
                <Link
                  to="/chef-subscribed-package-payment-history"
                  onClick={() => this.props.addMobileView()}
                  className="icon profit_icon"
                >
                  Subscription History
                </Link>{" "}
              </li>
			  
              {/*<li
                className={
                  history.location.pathname === "/chef-membership"
                    ? "active"
                    : null
                }
              >
                <Link
                  to="/chef-membership"
                  onClick={() => this.props.addMobileView()}
                  className="icon membership_icon"
                >
                  Membership Plans
                </Link>
              </li>*/}
              {this.props.userData.membership !== "basic" ? (
                <li
                  className={
                    history.location.pathname === "/chef-review"
                      ? "active"
                      : null
                  }
                >
                  <Link
                    to="/chef-review"
                    onClick={() => this.props.addMobileView()}
                    className="icon review_new_icon"
                  >
                    My Review
                  </Link>
                </li>
              ) : null}
              {this.props.userData.membership !== "basic" ? (
                <li
                  className={
                    history.location.pathname === "/chef-blog" ? "active" : null
                  }
                >
                  <Link
                    to="/chef-blog"
                    onClick={() => this.props.addMobileView()}
                    className="icon blog_icon"
                  >
                    My Blogs
                  </Link>
                </li>
              ) : null}
              <li className="d-block d-lg-none">
                <a href="/chef-alert" className="icon alert_icon">
                  Alert
                </a>
              </li>
              <li className="d-block d-lg-none">
                <a
                  href="/"
                  onClick={this.logoutHandler}
                  className="icon log_out_icon"
                >
                  Log Out
                </a>
              </li>
              {/* <li>
                <a href="#" className="icon survey_icon">
                  My Reviews
                </a>
              </li>
              <li>
                <a href="#" className="icon refunds_icon">
                  Refunds
                </a>
              </li>
              <li>
                <a href="#" className="icon writing_icon">
                  Write a Blog
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="icon audit_me_icon"
                >
                  Audit Me Anytime Pin
                </a>
              </li> */}
            </ul>
          </div>
        </div>
		<div>
			<Modal show={this.state.show} onHide={()=>this.handleModal()} size="sm">  
				<Modal.Header closeButton>Please select a date on which your menu will be auto-enabled</Modal.Header>  
				<Modal.Body>
				<h6>Select Date</h6>
				<DatePicker
				  selected={this.state.isEnableDate}
				  onChange={(date) => {
					this.setState({ isEnableDate: date });
					this.setState({timeOption: false});
					if(date.toDateString() === this.state.currentDate.toDateString()){
						this.setState({timeOption: true});
					}
				  }}
				  minDate={
					new Date(new Date().setDate(new Date().getDate()))
				  }
				  maxDate={
					new Date(new Date().setDate(new Date().getDate()+30))
				  }
				/>
			  </Modal.Body>  
			  <Modal.Footer>  
			  {/* <Button onClick={()=>this.handleModal()}>Close</Button> */}
				<Button onClick={async () => {
				  console.log(
					'Notice date: ',
					this.state.isEnableDate
				  );
				  var userId = localStorage.getItem('userID');
				  var token = localStorage.getItem('accessToken');
				  const disableOrderResult =
					await handleChefAvailabilityToggleAPI(
					  parseFloat(this.props.userData.userId),
					  this.state.is_available,
					  `${this.state.isEnableDate.getFullYear()}-${
						this.state.isEnableDate.getMonth() + 1
					  }-${this.state.isEnableDate.getDate()}`,
					  this.props.userData.accessToken
					);
				  console.log(
					'Reschedule order result; ',
					disableOrderResult
				  );
				  if (disableOrderResult.status === 200) {
					  let array = this.props.userData;
					  array.isAvailable = this.state.is_available;
					  array.isEnableDate = `${this.state.isEnableDate.getDate()}-${
						this.state.isEnableDate.getMonth() + 1
					  }-${this.state.isEnableDate.getFullYear()}`;
					  this.props.saveUserData(array);
					  this.setState({
						isAvailable: this.state.is_available == 0 ? true : false,
					  });
					  var status = this.state.is_available == 0 ? "disabled" : "enabled";
					  NotificationManager.success(
						"All orders " + status + " successfully",
						"Success!"
					  );
					  
					  this.setState(
						{
						  isEnableDate: new Date(
							new Date().setDate(new Date().getDate())
						  ),
						},
						() => {}
					  );
					  this.handleModal();
				  } else {
					  NotificationManager.error(disableOrderResult.data.message, "Error!");
					  this.setState({
						isLoading: false,
					  });
				  }
				}}
				>Submit</Button>  
			  </Modal.Footer>  
			</Modal>  
		</div>
        {this.state.hideContent ? (
          <div className="sidebar_footer">
            <Footer />
          </div>
        ) : null}
      </div>
    );
  }
}

 
const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
  orderCountData: state.orderCount.items
});

export default connect(mapStateToProps, {
  addMobileView,
  saveUserData,
  removeUserData,
})(withRouter(ChefAccountSidebar));
