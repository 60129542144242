/*
#############################################################################
# Name : saveMenu.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the saved menu and edit the menu added by the chef
#############################################################################
*/
import React from 'react';
import axios from 'axios';
import {
  getSavedKitchenMenuAPI,
  getMenuItemFormDtaAPI,
  getCategoriesAPI,
  getKitchenMaterialAPI,
  getTagsAPI,
  changeMenuItemStatusAPI,
  deleteMenuItemAPI,
  removeDocumentItemAPI,
  getUserSummaryAPI,
  enableDishAPI
} from '../../core/services/APIservice';
import { withRouter } from 'react-router-dom';
import config from '../../config/config';

// React Notification
import { NotificationManager } from 'react-notifications';
import $ from 'jquery';

const numberReg = /^[0-9\b]+$/;
const decimalReg = /^[0-9\.\b]+$/;

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === 'form-control' && (valid = false);
  });

  return valid;
};

class SaveMenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayForm: null,
      activeMenu: null,
      menuItemArr: [],
      documentItemArr: [],
      excelItemArr: [],
      categoryList: [],
      kitchenMaterialArr: [],
      noneKitchenArray: [],
      menuTagSelected: [],
      tagsArr: [],
      imageNames: [],
      imageUpdated: false,
      menuImageUploaded: false,
      selectNoneCheckbox: false,
      loading: false,
      itemFile: null,
      oldFiles: [],
      itemCategory: '',
      categoryDescription: '',
      itemName: '',
      itemType: '',
      itemDescription: '',
      itemQuantity: '',
      itemPortionSize: '',
      itemPrice: '',
      advanceNotice: '',
      itemContains: [],
      menuTagSelected: [],
      formErrors: {
        itemCategory: '',
        itemName: '',
        itemDescription: '',
        itemQuantity: '',
        itemPrice: '',
        advanceNotice: ''
      },
      itemFileErr: '',
      itemContainsErr: '',
      festiveTagsErr: '',
      formIncludedMaterialIds: [],
      opsMessageFlag: true,
      menuItemFlag: false,
      documentItemFlag: false,
      excelItemFlag: false,
      goToThankYouPage: false,
      chars_left: 200,
      deletePopUP: false,
      isMenuEnabled: false
    };
    this.onChangeInput = this.onChangeInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSavedMenuItem(); // Saved menu item data is fetched
    this.getCategories(); // Categories data is fetched
    this.getKitchenMaterial(); // Kitchen Material data is fetched
    this.getMenuTags(); // Menu Tags data is fetched
  }

  /* getCategories function starts here */
  getCategories = () => {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getCategoriesAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({ categoryList: response.data['data'] });
        }
      })
      .catch((error) => {});
  };
  /* getCategories function ends here */

  /* onChangeInput function starts here */
  onChangeInput = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'itemName':
        formErrors.itemName = value === '' ? 'Item name is required' : '';
        break;

      case 'itemCategory':
        formErrors.itemCategory =
          value === '' ? 'Item category is required' : '';
        break;

      case 'itemDescription':
        formErrors.itemDescription =
          value === '' ? 'Item description is required.' : '';
        break;

      case 'itemQuantity':
        formErrors.itemQuantity =
          value === ''
            ? 'Item quantity is required.'
            : !numberReg.test(e.target.value)
            ? 'Enter valid quantity.'
            : '';
        break;

      case 'itemPrice':
        formErrors.itemPrice =
          value === ''
            ? 'Item price is required.'
            : !decimalReg.test(e.target.value)
            ? 'Enter valid price.'
            : '';
        break;

      case 'advanceNotice':
        formErrors.advanceNotice =
          value === ''
            ? 'Advance notice is required.'
            : !decimalReg.test(e.target.value)
            ? 'Enter valid advance notice.'
            : '';
        break;

      case 'itemPortionSize':
        this.setState({
          itemQuantity: ''
        });
        break;

      default:
        break;
    }

    this.setState({ formErrors, [name]: value });
  };
  /* onChangeInput function ends here */

  /* getKitchenMaterial function starts here */
  getKitchenMaterial() {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getKitchenMaterialAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            kitchenMaterialArr: response.data['data']['menu_material']
          });
        }
      })
      .catch((error) => {});
  }
  /* getKitchenMaterial function ends here */

  /* getMenuTags function starts here */
  getMenuTags() {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getTagsAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({ tagsArr: response.data['data'] });
        }
      })
      .catch((error) => {});
  }
  /* getMenuTags function ends here */

  /* getTagName function starts here */
  getTagName = (tag) => {
    var newItems = [];

    newItems = this.state.menuTagSelected.filter((elm) => elm === tag);

    if (newItems.length) {
      var array = this.state.menuTagSelected.filter((elm) => elm !== tag);
      this.setState({
        menuTagSelected: array
      });
    } else {
      this.state.menuTagSelected.push(tag);
    }
  };
  /* getTagName function ends here */

  /* changeRadioValue function starts here */
  changeRadioValue = (e) => {
    this.setState({
      itemType: e.target.value
    });
    // this.setState({
    //     form: {
    //         itemType: e.target.value
    //     }
    // })
  };
  /* changeRadioValue function ends here */

  /* handleNoneChecked function starts here */
  handleNoneChecked = (event) => {
    if (event.target.checked) {
      this.setState({
        selectNoneCheckbox: true,
        formIncludedMaterialIds: []
      });
      setTimeout(() => {
        this.state.formIncludedMaterialIds.push(1);
      }, 100);
    } else {
      this.setState({
        selectNoneCheckbox: false,
        formIncludedMaterialIds: []
      });
    }
  };
  /* handleNoneChecked function ends here */

  /* handleCheckKitchenMaterial function starts here */
  handleCheckKitchenMaterial = (event) => {
    if (event.target.checked) {
      this.state.formIncludedMaterialIds.push(event.target.value);
    } else {
      let filteredArr = this.state.formIncludedMaterialIds.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({ formIncludedMaterialIds: filteredArr });
    }
    this.setState({
      selectNoneCheckbox: false
    });
    setTimeout(() => {
      if (this.state.formIncludedMaterialIds.length > 0) {
        this.setState({
          itemContainsErr: ''
        });
      } else {
        this.setState({
          itemContainsErr: 'Please select items.'
        });
      }
    });

    // let materials = this.state.kitchenMaterialArr;
    // materials.forEach((sampleMaterial) => {
    //     if (sampleMaterial.id === event.target.value)
    //         sampleMaterial.isChecked = event.target.checked;
    // });
    // this.setState({ kitchenMaterialArr: materials });
  };
  /* handleCheckKitchenMaterial function ends here */

  /* onChangeMenuPhoto function starts here */
  onChangeMenuPhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;
    if (this.state.oldFiles.length) {
      this.setState({
        itemFile: event.target.files[0],
        imageUpdated: true,
        itemFileErr: ''
      });
      var userId = localStorage.getItem('userID');
      var accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      formdata.append('user_id', userId);
      formdata.append('menu_image_id', this.state.oldFiles[0].menu_image_id);
      axios
        .post(config.APIURL + 'v1/remove-menu-image', formdata, {
          headers: {
            Authorization: accessToken
          }
        })
        .then((response) => {
          if (response.data['status'] === 200) {
          }
        })
        .catch((error) => {});
      let filteredKitchenArr = this.state.oldFiles.filter(
        (elm) => elm.menu_image_id !== this.state.oldFiles[0].menu_image_id
      );
      this.setState({ oldFiles: filteredKitchenArr });
    } else {
      this.setState({
        itemFile: event.target.files[0],
        imageUpdated: true,
        itemFileErr: ''
      });
    }

    /* if (this.state.itemFile == null) {
      this.setState({
        itemFileErr: "Item images are required.",
      });
    } else {
      this.setState({
        itemFileErr: "",
      });
    } */
  };
  /* onChangeMenuPhoto function ends here */

  /* changeStatus function starts here */
  changeStatus = (e, menuId) => {
    var newStatus = null;
    if (e.target.value === 0) {
      newStatus = 1;
    } else {
      newStatus = 0;
    }
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');

    return changeMenuItemStatusAPI(userId, menuId, newStatus, accessToken).then(
      (response) => {
        if (response.data.status === 200) {
          this.getSavedMenuItem();
          NotificationManager.success(
            'Menu item status changed',
            'Successful!',
            1000
          );
        } else {
          NotificationManager.error(response.data.error, 'Error!');
        }
      }
    );
  };
  /* changeStatus function ends here */

  /*   deleteMenuItem = (menuId) => {
        const r = window.confirm("Are you sure to delete?");
        if (r === true) {
            var userId = localStorage.getItem("userID");
            var accessToken = localStorage.getItem("accessToken");
            return deleteMenuItemAPI(userId, menuId, accessToken)
                .then(response => {
                    if (response.data.status === 200) {
                        this.getSavedMenuItem();
                        NotificationManager.success("Menu has been deleted", "Successful!", 1000);
                        this.setState({
                            menuItemFlag: false
                        });
                        this.state.menuItemArr.map((dish, k) => {
                            if (dish.menu.length === 0) {
                                this.setState({
                                    goToThankYouPage: false
                                })
                            }
                        })
                    } else {
                        NotificationManager.error(response.data.error, "Error!");
                    }
                })
        }
    } */

  /* checkMenuEnabled function starts here */
  checkMenuEnabled = (e, menuId) => {
    var menuEnable = e.target.checked == true ? 1 : 0;
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return enableDishAPI(userId, accessToken, menuId, menuEnable)
      .then((response) => {
        if (response.data.status === 200) {
          /*    console.log(response); */
          this.getSavedMenuItem();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* checkMenuEnabled function ends here */

  /* showDeletePopup function starts here */
  showDeletePopup = (menuId) => {
    /* console.log(menuId); */
    localStorage.setItem('deleteMenuDish', menuId);
    this.setState({
      deletePopUP: true
    });
  };
  /* showDeletePopup function ends here */

  /* deleteMenuItem function starts here */
  deleteMenuItem = (menuDish) => {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    /* console.log(menuDish); */
    return deleteMenuItemAPI(userId, menuDish, accessToken).then((response) => {
      if (response.data.status === 200) {
        this.getSavedMenuItem();
        NotificationManager.success(
          'Menu has been deleted',
          'Successful!',
          1000
        );
        localStorage.removeItem('deleteMenuDish');
        this.setState({
          menuItemFlag: false,
          deletePopUP: false
        });
        this.state.menuItemArr.map((dish, k) => {
          if (dish.menu.length === 0) {
            this.setState({
              goToThankYouPage: false
            });
          }
        });
      } else {
        NotificationManager.error(response.data.error, 'Error!');
      }
    });
  };
  /* deleteMenuItem function ends here */

  /* submitForm function starts here */
  submitForm(e, menuId, menuEnabled) {
    // e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { formIncludedMaterialIds } = this.state;
    if (this.state.itemCategory === '') {
      formErrors.itemCategory = 'Item category is required.';
    }
    if (this.state.itemName === '') {
      formErrors.itemName = 'Item name is required.';
    }
    if (this.state.itemDescription === '') {
      formErrors.contact = 'Item description is required.';
    }
    if (this.state.itemQuantity === '') {
      formErrors.itemQuantity = 'Item quantity is required.';
    }
    if (this.state.itemPrice === '') {
      formErrors.itemPrice = 'Item price is required.';
    }
    if (this.state.advanceNotice === '') {
      formErrors.advanceNotice = 'Advance notice is required.';
    }
    if (this.state.oldFiles === []) {
      if (!this.state.itemFile && this.state.itemFile == null) {
        this.setState({
          itemFileErr: 'Item image is required.',
          menuImageUploaded: true
        });
      } else {
        this.setState({
          menuImageUploaded: false
        });
      }
    }

    // if (formIncludedMaterialIds.length === 0) {
    //   this.setState({
    //     itemContainsErr: "Item Details Are Required",
    //   });
    // }
    if (this.state.menuImageUploaded === false) {
      this.setState({ formErrors, [name]: value });
      if (formValid(this.state)) {
        this.setState({ loading: true });
        var userId = localStorage.getItem('userID');
        var accessToken = localStorage.getItem('accessToken');
        var formdata = new FormData();
        formdata.append('user_id', userId);
        formdata.append('menu_id', menuId);
        formdata.append('category_id', this.state.itemCategory);
        formdata.append('item_name', this.state.itemName);
        formdata.append('item_description', this.state.itemDescription);
        formdata.append('diet_type', this.state.itemType);
        formdata.append('unit', this.state.itemPortionSize);
        formdata.append(
          'standard_quantity_or_portion_size',
          this.state.itemQuantity
        );
        formdata.append('item_price', this.state.itemPrice);
        formdata.append('advanced_preparation_note', this.state.advanceNotice);
        if (this.state.imageUpdated == true && this.state.itemFile != null) {
          formdata.append(
            'menu_images[]',
            this.state.itemFile,
            this.state.itemFile.name
          );
        }
        if (this.state.selectNoneCheckbox === true) {
          formdata.append('menu_item_none', '1');
          formdata.append('menu_uses_material', []);
        } else {
          formdata.append('menu_item_none', '0');

          formdata.append(
            'menu_uses_material',
            this.state.formIncludedMaterialIds
          );
        }

        formdata.append('tags', this.state.menuTagSelected);
        if (menuEnabled != null && menuEnabled != 0) {
          formdata.append('enabled', 1);
        } else {
          formdata.append('enabled', 0);
        }

        axios
          .post(config.APIURL + 'v1/update-kitchen-menu', formdata, {
            headers: {
              Authorization: accessToken
            }
          })
          .then((response) => {
            if (response.data['status'] === 200) {
              this.setState({
                displayForm: null,
                itemFile: null,
                loading: false,
                itemFileErr: ''
              });
              this.getSavedMenuItem();
              NotificationManager.success(
                'Menu Item Updated',
                'Successful!',
                1000
              );
            } else {
              this.setState({ loading: false });
              NotificationManager.error(response.data.error, 'Error!');
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            NotificationManager.error('Menu Item Failed to Add', 'Error!');
          });
      }
    } else {
      this.setState({ loading: false });
      setTimeout(function () {
        var getfilterRowElement = $('.text-danger');
        $('small.text-danger').each(function () {
          var string = $(this).html();
          if (string != '') {
            $('html, body').animate(
              {
                scrollTop: $(this).parent().offset().top + -100
              },
              500
            );
            return false;
          }
        });
      }, 100);
    }
  }
  /* submitForm function ends here */

  /* getSavedMenuItem function starts here */
  getSavedMenuItem = () => {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getSavedKitchenMenuAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          if (response.data['data']['document'].length === 0) {
            this.setState({
              documentItemFlag: false
            });
          } else {
            this.setState({
              documentItemFlag: true,
              opsMessageFlag: false,
              goToThankYouPage: true
            });
          }
          if (response.data['data']['excel'].length === 0) {
            this.setState({
              excelItemFlag: false
            });
          } else {
            this.setState({
              excelItemFlag: true,
              opsMessageFlag: false,
              goToThankYouPage: true
            });
          }
          response.data['data']['menu_list'].map((dish, k) => {
            if (dish.menu.length > 0) {
              this.setState({
                menuItemFlag: true,
                opsMessageFlag: false
              });
            }
          });
          if (
            response.data['data']['document'].length === 0 &&
            response.data['data']['excel'].length === 0
          ) {
            this.setState({
              opsMessageFlag: true,
              goToThankYouPage: false
            });
          }
          //this.state.menuItemArr.push(...response.data["data"])
          this.setState({
            menuItemArr: response.data['data']['menu_list'],
            documentItemArr: response.data['data']['document'],
            excelItemArr: response.data['data']['excel']
          });
        }
        setTimeout(() => {
          this.state.menuItemArr.map((dish, k) => {
            if (dish.menu.length > 0) {
              this.setState({
                goToThankYouPage: true,
                opsMessageFlag: false
              });
            }
          });
          /* if(this.state.documentItemArr.length !== 0){
                    
                    this.setState({
                        goToThankYouPage: true
                    })
                }else{
                    
                    this.setState({
                        goToThankYouPage: false
                    })
                } */
          /*  if(this.state.excelItemArr.length === 0){
                     this.setState({
                         goToThankYouPage: false
                     })
                 }else{
                     this.setState({
                         goToThankYouPage: true
                     })
                 } */
        }, 1000);
      })
      .catch((error) => {});
  };
  /* getSavedMenuItem function ends here */

  /* getThankYouPage function starts here */
  getThankYouPage = (event) => {
    event.preventDefault();
    if (this.state.goToThankYouPage === true) {
      localStorage.setItem('currentStatus', 6);
    } else {
      NotificationManager.error('Please Add Menu Item', 'Error!');
    }
  };
  /* getThankYouPage function ends here */

  /* getMenuItemFormData function starts here */
  getMenuItemFormData = (menuId, index) => {
    this.setState({
      displayForm: index
    });
    const { formIncludedMaterialIds } = this.state;
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');

    return getMenuItemFormDtaAPI(userId, menuId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            itemName: response.data.data.item_name,
            itemCategory: response.data.data.category_id,
            itemDescription: response.data.data.item_description,
            itemType: response.data.data.diet_type,
            itemQuantity: response.data.data.standard_quantity_or_portion_size,
            itemPortionSize: response.data.data.unit,
            itemPrice: response.data.data.item_price,
            advanceNotice: response.data.data.advanced_preparation_note,
            oldFiles: response.data.data.menu_images,
            itemContains: response.data.data.menu_uses_material,
            menuTagSelected: response.data.data.tags
          });

          if (response.data.data.menu_uses_material.length) {
            let array = [];
            response.data.data.menu_uses_material.forEach((item) => {
              array.push(item.material_id);
            });
            this.setState({
              formIncludedMaterialIds: array
            });
          }
          if (response.data.data.menu_item_none === 1) {
            this.setState({
              selectNoneCheckbox: true
            });
          } else {
            this.setState({
              selectNoneCheckbox: false
            });
          }
        }
      })
      .catch((error) => {});
  };
  /* getMenuItemFormData function ends here */

  /* onClickAdd function starts here */
  onClickAdd = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getUserSummaryAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.props.parentCallback(response.data['data']);
          localStorage.setItem('currentStatus', 5);
        }
      })
      .catch((error) => {});
  };
  /* onClickAdd function ends here */

  /* logoutHandler function starts here */
  logoutHandler = (e) => {
    localStorage.clear();
    NotificationManager.success(
      'You have Logout Successfully',
      'Successful!',
      1000
    );
  };
  /* logoutHandler function ends here */

  /* changeActiveMenu function starts here */
  changeActiveMenu = (index) => {
    this.setState({
      activeMenu: index,
      displayForm: null
    });
  };
  /* changeActiveMenu function ends here */

  /* closePopup function starts here */
  closePopup = (event) => {
    event.preventDefault();
    localStorage.removeItem('deleteMenuDish');
    this.setState({
      deletePopUP: false
    });
  };
  /* closePopup function ends here */

  /* deleteDocumentItem function starts here */
  deleteDocumentItem = (id, event) => {
    event.preventDefault();
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return removeDocumentItemAPI(userId, id, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          NotificationManager.success(
            'Menu File has been deleted',
            'Successful!',
            1000
          );
          this.getSavedMenuItem();
          if (this.state.documentItemArr.length === 0) {
            this.setState({
              goToThankYouPage: false
            });
          }
          if (this.state.excelItemArr.length === 0) {
            this.setState({
              goToThankYouPage: false
            });
          }
        }
      })
      .catch((error) => {});
  };
  /* deleteDocumentItem function ends here */

  /* onPreviousMenuSubmit function starts here */
  onPreviousMenuSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('currentStatus', 3);
  };
  /* onPreviousMenuSubmit function ends here */

  /* onMenuSubmit function starts here */
  onMenuSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('currentStatus', 5);
  };
  /* onMenuSubmit function ends here */

  /* removeMenuPhoto function starts here */
  removeMenuPhoto = (menuName, event) => {
    event.preventDefault();
    /* let filteredArr = this.state.itemFile.filter(
      (elm) => elm.name !== menuName
    );
    this.setState({ itemFile: filteredArr }); */
    this.setState({
      itemFile: null
    });
  };
  /* removeMenuPhoto function ends here */

  /* removeMenuDataPhoto function starts here */
  removeMenuDataPhoto = (menuImageID, event) => {
    event.preventDefault();
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    var formdata = new FormData();
    formdata.append('user_id', userId);
    formdata.append('menu_image_id', menuImageID);
    axios
      .post(config.APIURL + 'v1/remove-menu-image', formdata, {
        headers: {
          Authorization: accessToken
        }
      })
      .then((response) => {
        if (response.data['status'] === 200) {
        }
      })
      .catch((error) => {});
    let filteredKitchenArr = this.state.oldFiles.filter(
      (elm) => elm.menu_image_id !== menuImageID
    );
    this.setState({ oldFiles: filteredKitchenArr });
  };
  /* removeMenuDataPhoto function ends here */

  render() {
    const {
      categoryList,
      formIncludedMaterialIds,
      imageNames,
      formErrors,
      displayForm,
      activeMenu
    } = this.state;
    return (
      <div className='registration bussiness save_menu menu_member1 p-0'>
        <div className='abt_form'>
          <h4 className='d-flex justify-content-between align-items-center'>
            <button
              className='save_btn back_btn'
              onClick={this.onPreviousMenuSubmit}
            >
              <img
                src='assets/images/icons/left-arrow.png'
                alt='Left Arrow Icon'
                className='img-fluid'
              />
              Back
            </button>
            <span className='text-uppercase text-center text-md-left'>
              MENU
            </span>
            <button className='save_btn disabled'>Save</button>
          </h4>
          <p className='sub_title'>
            Note : Your information has been saved!{' '}
            <br className='d-block d-lg-none' /> You can now&nbsp;
            <a
              href='/'
              className='logout_link'
              onClick={(e) => this.logoutHandler(e)}
            >
              logout
            </a>
            &nbsp; and log back in to resume completing the form at your
            convenience.
          </p>
          <div className='inner mb_box_sh_none'>
            <div className='box'>
              <div className='btn-group d-flex justify-content-center save_add_btn_grp'>
                <div>
                  <button
                    className='btn add_menu_btn'
                    onClick={this.onClickAdd}
                  >
                    Add to Menu
                  </button>
                </div>
                <div>
                  <button className='btn save_menu_btn active'>
                    Saved Menu
                  </button>
                </div>
              </div>

              {this.state.opsMessageFlag ? (
                <label className='save_btn_bot_para'>
                  Oops! You have no saved items in your menu yet. Start adding
                  menu items now.
                </label>
              ) : null}
            </div>
          </div>
          {this.state.documentItemFlag ? (
            <div className='inner'>
              <div className='box'>
                <h5>Uploaded menu files</h5>
              </div>
              <div className='box'>
                <ul className='uploa_doc'>
                  {this.state.documentItemArr.map((document, d) => {
                    return (
                      <li key={'ewqqqq' + d}>
                        <a
                          className='doc_box'
                          target='_blank'
                          href={document.document_path}
                        >
                          {document.document_name.slice(-20)}
                        </a>
                        <button
                          className='link'
                          onClick={this.deleteDocumentItem.bind(
                            this,
                            document.id
                          )}
                        >
                          Remove
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
          {this.state.excelItemFlag ? (
            <div className='inner'>
              <div className='box'>
                <h5>Uploaded excel files</h5>
              </div>
              <div className='box'>
                <ul className='uploa_doc'>
                  {this.state.excelItemArr.map((excel, ex) => {
                    return (
                      <li key={'vlsskr' + ex}>
                        <a
                          className='doc_box'
                          target='_blank'
                          href={excel.document_path}
                        >
                          {excel.document_name.slice(-20)}
                        </a>
                        <button
                          className='link'
                          onClick={this.deleteDocumentItem.bind(this, excel.id)}
                        >
                          Remove
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : null}
          {this.state.menuItemFlag ? (
            <div className='inner'>
              <div className='box'>
                <h5>Menu Items</h5>
              </div>
              {this.state.menuItemArr.map((item, index) => {
                return item.menu.length ? (
                  <div className='box' key={'afdsfdsf' + index}>
                    <div id={'headingOne-' + index}>
                      <h5
                        className='color_C07 card_open acco_title collapsed'
                        data-toggle='collapse'
                        data-target={'#collapseOne-' + index}
                        aria-expanded='false'
                        aria-controls={'collapseOne-' + index}
                        onClick={() => this.changeActiveMenu(index)}
                      >
                        <span className='acco_arrow'>
                          {item.type} ({item.menu.length})
                        </span>
                        <small className='form-text text-muted d-block mt-3'>
                          {item.description}
                        </small>
                      </h5>
                    </div>
                    <div
                      id={'collapseOne-' + index}
                      className='collapse'
                      aria-labelledby={'headingOne-' + index}
                      data-parent='#accordion'
                    >
                      {item.menu.map((menuItem, j) => {
                        return (
                          <div className='new_shadow' key={'qpskfj' + j}>
                            <div className='top_edit_wrap'>
                              <div className='img_wrap'>
                                <img
                                  src={menuItem.menu_image}
                                  alt='Snack'
                                  className='img-fluid'
                                />
                              </div>
                              <div className='text_wrap'>
                                <button
                                  type='button'
                                  onClick={() =>
                                    this.getMenuItemFormData(
                                      menuItem.menu_id,
                                      j
                                    )
                                  }
                                  className='edit_orange_btn'
                                >
                                  Edit
                                </button>
                                <h6>{menuItem.item_name}</h6>
                                <h6 className='font-weight-normal'>
                                  INR {menuItem.item_price}
                                </h6>
                                <p>{menuItem.item_description}</p>
                              </div>
                            </div>
                            <div
                              className={
                                displayForm === j && activeMenu === index
                                  ? 'form_wrap'
                                  : 'form_wrap hide-form-div'
                              }
                            >
                              <form
                                method='POST'
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <div className='row'>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group yellow_arrow_icon'>
                                      <select
                                        className='form-control'
                                        name='itemCategory'
                                        onChange={this.onChangeInput}
                                      >
                                        {categoryList.map((category) => {
                                          return (
                                            <option
                                              value={category.id}
                                              key={'asd' + category.id}
                                              selected={
                                                this.state.itemCategory ===
                                                category.id
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {category.category_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {categoryList.map((category, k) => {
                                        if (
                                          category.id ===
                                          this.state.itemCategory
                                        ) {
                                          return (
                                            <small
                                              className='form-text text-muted'
                                              key={'jhgfds' + k}
                                            >
                                              {category.description}
                                            </small>
                                          );
                                        }
                                      })}
                                      {/* <small className="form-text text-muted">Select a category.</small> */}
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group'>
                                      <div className='remove_btn_wrap'>
                                        <input
                                          type='text'
                                          className='form-control'
                                          name='itemName'
                                          defaultValue={this.state.itemName}
                                          onChange={this.onChangeInput}
                                          placeholder='Enter Name'
                                        />
                                        {/* <button className="in_remo_btn"></button> */}
                                        <small className='text-danger'>
                                          {formErrors.itemName}
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group item_textarea'>
                                      <textarea
                                        className='form-control'
                                        name='itemDescription'
                                        onChange={this.onChangeInput}
                                        defaultValue={
                                          this.state.itemDescription
                                        }
                                        placeholder='Enter Description...'
                                      ></textarea>
                                      <small className='form-text text-muted text-right'>
                                        0/500 characters
                                      </small>
                                      <small className='text-danger'>
                                        {formErrors.itemDescription}
                                      </small>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group radio_wrap'>
                                      <label className='d-block'>
                                        This item is:*
                                      </label>
                                      <ul className='radio_list d-block'>
                                        <li>
                                          <label className='custome_check'>
                                            Veg
                                            <input
                                              type='radio'
                                              value='Veg'
                                              name='itemType'
                                              onClick={this.changeRadioValue}
                                              checked={
                                                this.state.itemType == 'Veg'
                                              }
                                              name='Item'
                                            />
                                            <span className='radiobtn'></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className='custome_check'>
                                            Non Veg
                                            <input
                                              type='radio'
                                              value='Non Veg'
                                              name='itemType'
                                              onClick={this.changeRadioValue}
                                              checked={
                                                this.state.itemType == 'Non Veg'
                                              }
                                              name='Item'
                                            />
                                            <span className='radiobtn'></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className='custome_check'>
                                            Contains Egg
                                            <input
                                              type='radio'
                                              value='Contains Egg'
                                              name='itemType'
                                              onClick={this.changeRadioValue}
                                              checked={
                                                this.state.itemType ==
                                                'Contains Egg'
                                              }
                                              name='Item'
                                            />
                                            <span className='radiobtn'></span>
                                          </label>
                                        </li>
                                      </ul>
                                      <small className='text-danger'>
                                        {formErrors.itemType}
                                      </small>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group'>
                                      <label>
                                        Standard Quantity or portion size*
                                      </label>
                                      <div className='select_wrap gms'>
                                        <div className='small_select'>
                                          <select
                                            className='form-control'
                                            name='itemPortionSize'
                                            onChange={this.onChangeInput}
                                          >
                                            <option
                                              value='kg'
                                              selected={
                                                this.state.itemPortionSize ===
                                                'kg'
                                                  ? true
                                                  : false
                                              }
                                            >
                                              kg
                                            </option>
                                            <option
                                              value='gm'
                                              selected={
                                                this.state.itemPortionSize ===
                                                'gm'
                                                  ? true
                                                  : false
                                              }
                                            >
                                              gm
                                            </option>
                                            <option
                                              value='ltr'
                                              selected={
                                                this.state.itemPortionSize ===
                                                'ltr'
                                                  ? true
                                                  : false
                                              }
                                            >
                                              ltr
                                            </option>
                                            <option
                                              value='pc'
                                              selected={
                                                this.state.itemPortionSize ===
                                                'pc'
                                                  ? true
                                                  : false
                                              }
                                            >
                                              pc
                                            </option>
                                            <option
                                              value='portion'
                                              selected={
                                                this.state.itemPortionSize ===
                                                'portion'
                                                  ? true
                                                  : false
                                              }
                                            >
                                              portion
                                            </option>
                                          </select>
                                        </div>
                                        <input
                                          type='tel'
                                          className='form-control tel_number'
                                          name='itemQuantity'
                                          onChange={this.onChangeInput}
                                          value={this.state.itemQuantity}
                                          placeholder={
                                            this.state.itemPortionSize ===
                                            'portion'
                                              ? 'Serves how many people'
                                              : 'Item Quantity*'
                                          }
                                          maxLength='4'
                                        />
                                      </div>
                                      <small className='form-text text-muted'>
                                        Select unit and enter quantity or
                                        portion size.
                                      </small>
                                      <small className='text-danger'>
                                        {formErrors.itemQuantity}
                                      </small>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group'>
                                      <div className='mobile_no_wrap'>
                                        <div className='form-control countrycode'>
                                          <span className='code'>INR</span>
                                        </div>
                                        <input
                                          type='tel'
                                          className='form-control tel_number'
                                          name='itemPrice'
                                          onChange={this.onChangeInput}
                                          defaultValue={this.state.itemPrice}
                                          placeholder='Item Price*'
                                          maxLength='6'
                                        />
                                      </div>
                                      <small className='form-text text-muted'>
                                        Please enter value in INR.
                                      </small>
                                      <small className='text-danger'>
                                        {formErrors.itemPrice}
                                      </small>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group '>
                                      <label>
                                        Advance notice required to prepare the
                                        item*
                                      </label>
                                      <input
                                        type='tel'
                                        className='form-control'
                                        name='advanceNotice'
                                        onChange={this.onChangeInput}
                                        defaultValue={this.state.advanceNotice}
                                        maxLength='2'
                                      />
                                      <small className='form-text text-muted'>
                                        Please enter value in hours.
                                      </small>
                                      <small className='text-danger'>
                                        {formErrors.advanceNotice}
                                      </small>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group'>
                                      <label className='d-block'>
                                        Upload a photo of the item*
                                      </label>
                                      <div className='fileUpload d-flex'>
                                        <button className='fileUploadBtn form-control d-inline-flex'></button>
                                        <div className='d-inline-flex align-items-center form-control'>
                                          <span className='file_text'>
                                            Choose file{' '}
                                            <small>No file selected</small>
                                          </span>
                                          <input
                                            type='file'
                                            className='uploadlogo'
                                            name='menu_photo'
                                            accept='.jpeg, .png, .jpg, .gif .JPEG, .PNG, .JPG, .GIF'
                                            onChange={this.onChangeMenuPhoto}
                                          />
                                        </div>
                                      </div>
                                      <small className='form-text text-muted'>
                                        One file only.
                                        <br className='d-sm-none' />
                                        64 MB limit. Allowed types: png gif jpg
                                        jpeg.
                                      </small>
                                      <small className='text-danger'>
                                        {this.state.itemFileErr}
                                      </small>
                                    </div>
                                    {this.state.itemFile != null ? (
                                      <div className='form-group festive_tags upload_tags'>
                                        <ul>
                                          <li>
                                            {this.state.itemFile.name}
                                            <button
                                              className='close'
                                              onClick={this.removeMenuPhoto.bind(
                                                this,
                                                this.state.itemFile.name
                                              )}
                                            ></button>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : null}

                                    {this.state.oldFiles.length ? (
                                      <div className='form-group festive_tags upload_tags'>
                                        <ul>
                                          {this.state.oldFiles.map(
                                            (item, z) => {
                                              return (
                                                <li key={'uhgvcdert' + z}>
                                                  {item.image_path
                                                    .split('/')
                                                    [
                                                      item.image_path.split('/')
                                                        .length - 1
                                                    ].slice(-20)}
                                                  <button
                                                    className='close'
                                                    onClick={this.removeMenuDataPhoto.bind(
                                                      this,
                                                      item.menu_image_id
                                                    )}
                                                  ></button>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group checklist_wrap'>
                                      <label>Does this item contain:*</label>
                                      <ul className='contn_itm'>
                                        {this.state.kitchenMaterialArr.map(
                                          (materials, i) => {
                                            return (
                                              <li key={'hjytrdcv' + i}>
                                                <label className='custome_check'>
                                                  <input
                                                    key={materials.id}
                                                    onClick={
                                                      this
                                                        .handleCheckKitchenMaterial
                                                    }
                                                    type='checkbox'
                                                    checked={this.state.formIncludedMaterialIds.includes(
                                                      materials.id
                                                    )}
                                                    value={materials.id}
                                                  />
                                                  <span className='checkmark'></span>
                                                  {materials.material_name}
                                                </label>
                                              </li>
                                            );
                                          }
                                        )}
                                        <li>
                                          <label className='custome_check'>
                                            <input
                                              type='checkbox'
                                              onClick={this.handleNoneChecked}
                                              value='checkedall'
                                              checked={
                                                this.state.selectNoneCheckbox
                                              }
                                            />
                                            <span className='checkmark'></span>
                                            None of the above
                                          </label>
                                        </li>
                                      </ul>
                                      <small className='form-text text-muted'>
                                        Select all that apply.
                                      </small>
                                      <small className='text-danger'>
                                        {this.state.itemContainsErr}
                                      </small>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group festive_tags'>
                                      <label>Festive Special Tags</label>
                                      <ul>
                                        {this.state.tagsArr.map((tag, i) => {
                                          return (
                                            <li
                                              key={'poiuyt' + i}
                                              className={
                                                this.state.menuTagSelected.filter(
                                                  (elm) => elm === tag.tag_name
                                                ).length > 0
                                                  ? 'active'
                                                  : 'ddsd'
                                              }
                                              onClick={() =>
                                                this.getTagName(tag.tag_name)
                                              }
                                            >
                                              {tag.tag_name}
                                              <button className='close'></button>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                      <small className='form-text text-muted'>
                                        Please select the festive occasion for
                                        your dish if applicable. <br />
                                        Select all that apply.
                                      </small>
                                      <small className='text-danger'>
                                        {formErrors.festiveTags}
                                      </small>
                                    </div>
                                  </div>
                                  <div className='col-md-12 col-lg-12 col-xl-12'>
                                    <div className='form-group save_btn_next btn-group can_itm_grp d-flex mw-100'>
                                      <div>
                                        <button
                                          type='button'
                                          className='btn previous_btn cancel_btn'
                                          onClick={() => {
                                            this.setState({
                                              displayForm: null
                                            });
                                          }}
                                        >
                                          cancel
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type='button'
                                          className='btn'
                                          disabled={this.state.loading}
                                          onClick={(e) =>
                                            this.submitForm(
                                              e,
                                              menuItem.menu_id,
                                              menuItem.enabled
                                            )
                                          }
                                        >
                                          save item
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className='bottom_wrap'>
                              <ul>
                                <li>
                                  <div className='active_deact_toggle'>
                                    <div className='toggle_box'>
                                      <input
                                        type='checkbox'
                                        value={menuItem.enabled}
                                        checked={
                                          menuItem.enabled == 1 ? true : false
                                        }
                                        onChange={(e) =>
                                          this.checkMenuEnabled(
                                            e,
                                            menuItem.menu_id
                                          )
                                        }
                                      />
                                    </div>
                                    <span
                                      className={
                                        menuItem.enabled == 1
                                          ? 'act active'
                                          : 'act de_active'
                                      }
                                    >
                                      {menuItem.enabled == 1
                                        ? 'Activate'
                                        : 'De-activate'}
                                    </span>
                                  </div>
                                </li>
                                <li className='only_delete'>
                                  <button
                                    type='button'
                                    onClick={() =>
                                      this.showDeletePopup(menuItem.menu_id)
                                    }
                                    className='delete_icon'
                                  >
                                    Delete
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          ) : null}
          {localStorage.getItem('userVerified') == null ||
          localStorage.getItem('userVerified') == 'null' ? (
            <div className='form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100'>
              <button
                type='submit'
                className='btn previous_btn'
                onClick={this.onPreviousMenuSubmit}
              >
                PREVIOUS
              </button>
              <button
                type='submit'
                className='btn'
                onClick={this.getThankYouPage}
              >
                save and next
              </button>
            </div>
          ) : <div className='form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100'>
              <button
                type='submit'
                className='btn previous_btn'
                onClick={this.onPreviousBusinessSubmit}
              >
                PREVIOUS
              </button>
              <button
                type='submit'
                className='btn'
                onClick={this.getThankYouPage}
              >
                save and next
              </button>
            </div>}
        </div>
        {this.state.deletePopUP ? (
          <div
            className={
              this.state.deletePopUP
                ? 'modal fade popup can_del_pop show d-block'
                : 'modal fade popup can_del_pop'
            }
            id='LeaveModal'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='LeaveModalTitle'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content'>
                <div className='modal-body text-center'>
                  <div className='add_another_btn'>
                    <button
                      type='button'
                      className='close plus_icon'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={this.closePopup}
                    ></button>
                  </div>
                  <h5 className='color_C07'>
                    Are you sure you want to delete this item?
                  </h5>
                  <div className='btn-group'>
                    <div>
                      <button
                        className='btn previous_btn'
                        onClick={this.closePopup}
                      >
                        cancel
                      </button>
                    </div>
                    <div>
                      <button
                        className='btn previous_btn'
                        onClick={() =>
                          this.deleteMenuItem(
                            localStorage.getItem('deleteMenuDish')
                          )
                        }
                      >
                        delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.deletePopUP ? (
          <div className='modal-backdrop fade show'></div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(SaveMenuPage);
