/*
#############################################################################
# Name : terms.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the terms
#############################################################################
*/
import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { removeMobileView } from "../../redux/actions/chefMenuActions";

class TermsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  render() {
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        <Helmet>
          <title>Know More About Our Terms of Use</title>
          <meta name="title" content="Know More About Our Terms of Use" />
          <meta
            name="description"
            content="Read About Our Terms of Use for Chef Pin."
          />
        </Helmet>
        {this.state.hideContent ? null : <Header />}
        <section className="sign_up_chef term_use">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-4 col-xl-4 ">
                <div id="sticky-anchor"></div>
                <div
                  id="menu-box"
                  className="terms_menu_list desktop_terms"
                  data-simplebar
                >
                  <h5 className="text-uppercase">contents</h5>
                  <ul>
                    <li className="active">
                      <a href="#TermsConditionsHomeChefs">
                        Terms & Conditions – for Home Chefs
                      </a>
                    </li>
                    <li>
                      <a href="#CodeConductHomeChefs">
                        Code of Conduct – for Home Chefs
                      </a>
                    </li>
                    <li>
                      <a href="#TermsConditionsBuyers">
                        Terms & Conditions – for Buyers
                      </a>
                    </li>
                    {/* <li><a href="#">Disclosure</a></li> */}
                    <li>
                      <a href="#TermsServiceBuyers">
                        Terms of Service – for Buyers
                      </a>
                    </li>
                    <li>
                      <a href="#CancellationPolicy">Cancellation Policy</a>
                    </li>
                    <li>
                      <a href="#RefundPolicy">Refund Policy</a>
                    </li>
                    <li>
                      <a href="#Intellectual">Intellectual property</a>
                    </li>
                    <li>
                      <a href="#Governing">
                        Governing law and dispute resolution
                      </a>
                    </li>
                    <li>
                      <a href="#Disclaimers">Disclaimers</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8 col-xl-8 ">
                <div className="row">
                  <div className="col-12">
                    <h2 className="fw_500 text-uppercase color_C07 res_back_btn_wrap">
                      <a
                        onClick={() => {
                          this.props.removeMobileView();
                          this.props.history.goBack();
                        }}
                        className="res_back_btn mobile_sidebar_back_btn"
                      >
                        <img src="assets/images/icons/left-arrow-black.svg" />
                      </a>
                      terms of use
                    </h2>
                    <div className="para_wrap">
                      <p>
                        These terms of use (the “Terms of Use”) govern use of
                        our website www.chefp.in (“Chef Pin” or the “Website”)
                        and our “Chef Pin” application for mobile and handheld
                        devices (the “App”). The Website and the App are jointly
                        referred to as the “Platform”. Please read these Terms
                        of Use carefully before using the services. By
                        installing, downloading or using the Platform, you shall
                        be contracting with Chef Pin and you signify your
                        acceptance to the Terms of Use and other Chef Pin
                        policies (including but not limited to the Cancellation
                        & Refund Policy, Privacy Policy and Take Down Policy) as
                        posted on the Platform from time to time
                      </p>
                      <p>
                        Chef Pin enables transactions between home chefs (also
                        called merchants) and buyers, dealing in prepared food
                        and drinks and beverages (“Platform Services”). The
                        buyer can choose and place orders (“Orders”) for a
                        variety of products listed and offered for sale by
                        various merchants on the Platform and Chef Pin enables
                        delivery of such orders at select localities of
                        serviceable cities across India (“Delivery Services”)
                        through third party delivery service providers. The
                        Platform Services and Delivery Services are collectively
                        referred to as “Services”.
                      </p>
                      <p>
                        These Terms of Use are subject to modifications at any
                        time. We reserve the right to modify or change these
                        Terms of Use and other Chef Pin policies at any time by
                        posting changes on the Platform, and you shall be liable
                        to update yourself of such changes, if any, by accessing
                        the changes on the Platform.
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div id="back_content"></div>
                    <div id="menu-box" className="terms_menu_list mobile_terms">
                      <h5 className="text-uppercase">contents</h5>
                      <ul>
                        <li className="active">
                          <a href="#TermsConditionsHomeChefs">
                            Terms & Conditions – for Home Chefs
                          </a>
                        </li>
                        <li>
                          <a href="#CodeConductHomeChefs">
                            Code of Conduct – for Home Chefs
                          </a>
                        </li>
                        <li>
                          <a href="#TermsConditionsBuyers">
                            Terms & Conditions – for Buyers
                          </a>
                        </li>
                        <li>
                          <a href="#TermsServiceBuyers">
                            Terms of Service – for Buyers
                          </a>
                        </li>
                        <li>
                          <a href="#CancellationPolicy">Cancellation Policy</a>
                        </li>
                        <li>
                          <a href="#RefundPolicy">Refund Policy</a>
                        </li>
                        <li>
                          <a href="#Intellectual">Intellectual property</a>
                        </li>
                        <li>
                          <a href="#Governing">
                            Governing law and dispute resolution
                          </a>
                        </li>
                        <li>
                          <a href="#Disclaimers">Disclaimers</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 ul_wrap">
                    <div id="TermsConditionsHomeChefs" className="box">
                      <h6 className="text-uppercase color_C07">
                        Terms & Conditions – for Home Chefs
                      </h6>
                      <p>
                        By using the Chef Pin website or app (creating a
                        profile) Home Chefs/Merchants are accepting the below
                        specified Terms of Use and Code of Conduct:{" "}
                      </p>
                      <ul className="terms_text_list">
                        <li>
                          You may access the Platform by registering to create
                          an account (“Chef Pin Account”) and become a member
                          (“Membership”); or you can also register to join by
                          logging into your account with certain third party
                          social networking sites (“SNS”) (including, but not
                          limited to, Facebook); via our Platform, as described
                          below. The Membership is limited for the purpose and
                          are subject to the terms, and strictly not
                          transferable.
                        </li>
                        <li>
                          We reserve the right to suspend or terminate your Chef
                          Pin Account and your access to the Services (i) if any
                          information provided during the registration process
                          or thereafter proves to be inaccurate, not current or
                          incomplete; (ii) if it is believed that your actions
                          may cause legal liability for you, other users or us;
                          and/or (iii) if you are found to be non- compliant
                          with the Terms of Use.
                        </li>
                        <li>
                          You are responsible for safeguarding your password.
                          You agree that you will not disclose your password to
                          any third party and that you will take sole
                          responsibility for any activities or actions under
                          your Chef Pin Account, whether or not you have
                          authorized such activities or actions. You will
                          immediately notify us of any unauthorized use of your
                          Chef Pin Account.
                        </li>
                        <li>
                          Home Chefs are responsible for informing Chef Pin if
                          they are unable to take orders due to prior
                          commitments, sickness, holidays, etc. The Home Chef's
                          inability to inform Chef Pin in a timely fashion can
                          result in penalties as deemed fit by Chef Pin. The
                          Home Chef is required to update their Leave
                          Dates/Black-out Dates on the website through their
                          personal admin system.
                        </li>
                        <li>
                          Home Chefs are responsible for making comprehensive
                          profiles on Chef Pin, providing detailed menus,
                          quantities, pricing and all other specifics like
                          preferred mode of delivery, desired notice period for
                          orders and / or any other special services provided.
                          Chef Pin will not be responsible for any discrepancies
                          that may arise thereafter.
                        </li>
                        <li>
                          In case of any copyright infringement due to
                          text/recipes/photographs uploaded by home chefs on
                          their profile, liability will lie on home chefs and
                          not on Chef Pin. The home chef must use real
                          photographs of his/her dishes only.
                        </li>
                        <li>
                          As a general rule, all food and drinks orders placed
                          on Chef Pin are treated as confirmed. Once a Buyer
                          places an order on Chef Pin, the Home Chef must
                          confirm the order, whereby he/she commits to the
                          details of said placed order. Chef Pin will not be
                          liable for any discrepancies that may arise
                          thereafter.
                        </li>
                        <li>
                          The Home Chefs are solely responsible for preparation
                          and timely delivery of food and drinks (as per terms
                          specified on their profile) as well as for maintaining
                          quality and hygiene. Chef Pin will not be responsible
                          or liable for the quantity or quality of meals
                          delivered.
                        </li>
                        <li>
                          Chef Pin is only providing a platform for
                          communication and it is agreed that the contract for
                          sale of any of the products or services shall be a
                          strictly bipartite contract between the Merchant and
                          the Buyer. In case of complaints from the Buyer
                          pertaining to food and drinks efficacy, quality, or
                          any other such issues, Chef Pin shall notify the same
                          to Merchant and shall also redirect the Buyer to the
                          consumer call center of the Merchant. The Merchant
                          shall be liable for redressing Buyer complaints.
                        </li>
                        <li>
                          Merchants understand that all orders placed through
                          the platform will attract a convenience fee payable to
                          Chef Pin. This convenience fee and any other dues will
                          be deducted before settling any payments to the
                          Merchants. Chef Pin will send regular reminders to
                          Merchants for the said convenience fee and will be in
                          its rights to cancel Merchant registrations upon
                          nonpayment to Chef Pin.
                        </li>
                        <li>
                          Chef Pin would facilitate the use third-party delivery
                          providers for Merchants to deliver the products to
                          Buyers. These services would be charged to Buyers over
                          and above the convenience fee payable to the platform.
                          Chef Pin will not be responsible for any acts of
                          omission and commission by the third-party delivery
                          provider.
                        </li>
                        <li>
                          If there is Cash-On-Delivery (e.g. delivery is being
                          done by Chefs or if the Buyer is picking up the food
                          and drinks from the Chef), then Chef Pin will not be
                          responsible for collecting payments from Buyers and
                          will not be liable therefore for loss that may occur
                          due to non-payment on behalf of Buyer, if any. In
                          either case, if the order is placed through the
                          platform then Chef Pin will charge and recover the
                          contracted convenience fee from the Merchant.
                        </li>
                        <li>
                          Chef Pin will not be responsible for any health
                          damages or sickness caused due to the consumption of
                          food and drinks and drinks prepared by the Merchants.
                        </li>
                        <li>
                          The Home Chef will follow the basic hygiene and safety
                          requirements as laid down by the FSSAI. Chef Pin will
                          monitor Home Chefs closely, through periodic audits,
                          kitchen visits, using Buyer feedback provided in any
                          form to Chef Pin and will remove and block Home Chefs
                          if they are unable to comply with our standards and /
                          or tarnish our reputation. In such a scenario Home
                          Chefs will not be refunded their membership fees for
                          listing on the platform.
                        </li>
                        <li>
                          Any information provided by the Home Chef including
                          photos, menu items, profile descriptions, etc. can be
                          used by Chef Pin for various purposes, including
                          business development, promotions, etc. Chef Pin is not
                          responsible for any liability that may arise from the
                          misuse of the shared information, if any.
                        </li>
                        <li>
                          The Home Chefs cannot, under any circumstances, place
                          orders on their own profiles.
                        </li>
                        <li>
                          Neither parties, be it the Buyer, Chef Pin, the
                          Merchant or the third-party delivery provider shall be
                          responsible to the others for any delay or
                          non-performance of its obligations under this
                          agreement arising from any cause beyond its control
                          including, without limitation, any of the following:
                          act of God, governmental act, war, fire, flood,
                          explosion, terrorism or civil commotion.
                        </li>
                        <li>
                          Home Chefs are legally obliged to add Chef Pin
                          branding to all orders that have been forwarded to
                          them by Chef Pin.
                        </li>
                        <li>
                          For corporate orders, the payments will be made as per
                          the corporate’s payment cycle.
                        </li>
                        <li>
                          All Home Chefs must complete the KYC formalities with
                          Chef Pin and no payments will be made to home chefs
                          who have not completed their KYC formalities.
                        </li>
                        <li>
                          Home Chefs are entitled to request a copy of the
                          personal information we hold on you. Please contact us
                          if you wish to request this information.
                        </li>
                        <li>
                          All information submitted by the home chef, whether in
                          the contract, KYC or for the purpose of FSSAI is
                          assumed to be verified and true. Home Chefs will be
                          liable for any lien, loss, penalties that may arise
                          due to any discrepancies found.
                        </li>
                        <li>
                          We have taken all reasonable steps to prevent Internet
                          fraud and ensure any data collected from you is stored
                          as securely and safely as possible. However, we cannot
                          be held liable in the extremely unlikely event of a
                          breach in our secure computer servers or those of
                          third parties.
                        </li>
                        <li>
                          Merchants understand that the platform allows Buyers
                          to review and rate their experiences of dealing with
                          the Merchant and the quality, quantity and consistency
                          of the food and drinks supplied. Chef Pin is in no way
                          involved in influencing these reviews and ratings and
                          Merchants indemnify the platform from any damages
                          which might arise as a result of any such reviews and
                          ratings.
                        </li>
                        <li>
                          <span>Disclosure</span>
                          <p>
                            We may disclose to third party services certain
                            personally identifiable information listed below:
                          </p>
                          <p>
                            • Information you provide us such as name, email,
                            mobile phone number
                          </p>
                          <p>
                            • Information we collect as you access and use our
                            service, including device information, location and
                            network carrier
                          </p>
                          <p>
                            This information is shared with third party service
                            providers so that we can:
                          </p>
                          <p>• Personalize the website and app for you</p>
                          <p>
                            • Perform data analytics to improve our services
                          </p>
                        </li>
                      </ul>
                      <p>
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="CodeConductHomeChefs" className="box">
                      <h6 className="text-uppercase color_C07">
                        Code of Conduct – for Home Chefs
                      </h6>
                      <ul className="terms_text_list">
                        <li>
                          It is recommended that the chef register with the
                          FSSAI as a Food Business Operator (FBO). The chef can
                          view the process on the following link-{" "}
                          <a
                            href="http://foodlicensing.fssai.gov.in/how_to_apply.html"
                            target="_blank"
                            className="terms_links"
                          >
                            How to Apply
                          </a>
                          .
                        </li>
                        <li>
                          That your dishes will be homemade, and you will
                          personally be involved in their preparation. The home
                          chef is required to maintain proper hygiene to make
                          and package the food and drinks. The home chef must
                          have a clean and sanitized working environment in
                          order to ensure the food and drinks are safe to
                          consume, without the food and drinks causing any
                          health problems.
                        </li>
                        <li>
                          The ingredients used in the food and drinks should be
                          of good quality. Ingredients that are expired, stale,
                          rancid, rotten, spoiled, amongst others, should not be
                          used in the food and drinks prepared by the home
                          chefs.
                        </li>
                        <li>
                          Employment of children in any form by home chefs is
                          strictly prohibited. Home Chefs should only employ
                          those who are at least 18 years of age. All applicable
                          labour laws including employment, wages, working
                          hours, overtime and working conditions shall be
                          complied with.
                        </li>
                        <li>
                          The home chef must adhere to the delivery timelines
                          for each menu item as shared by them on the platform.
                          The food and drinks must be prepared in a timely
                          fashion and be packaged by the time of pickup by the
                          third-party delivery partner so that there is no delay
                          in the food and drinks reaching the Buyer.
                        </li>
                        <li>
                          The home chef is liable to inform Chef Pin and update
                          his/her listing from the admin panel provided to them,
                          regarding any leaves/black-out dates so that the home
                          chef’s profile can be deactivated, and Buyers can’t
                          place orders in the chef’s absence. To ensure a smooth
                          functioning of the marketplace and ease of use for the
                          Buyer, the home chef’s menu must be updated with
                          prices, dishes and availability at all times.
                        </li>
                        <li>
                          You shall undertake to provide adequate directions,
                          information and authorizations to enable delivery. In
                          the event of any failure to pick-up your delivery,
                          failure to deliver within the estimated time due to
                          your failure to provide appropriate instructions, or
                          authorizations, then all risk and responsibility in
                          relation to such goods shall pass to you and you shall
                          not be entitled to any payment for the same. Our
                          decision in relation to this shall be final and
                          binding.
                        </li>
                      </ul>
                      <p>
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="TermsConditionsBuyers" className="box">
                      <h6 className="text-uppercase color_C07">
                        Terms & Conditions – for Buyers
                      </h6>
                      <p>
                        By using the Chef Pin website or app (creating a
                        profile) Buyers are accepting the below specified Terms
                        of Use and Code of Conduct:
                      </p>
                      <ul className="terms_text_list">
                        <li>
                          You may access the Platform by registering to create
                          an account (“Chef Pin Account”) and become a member
                          (“Membership”); or you can also register to join by
                          logging into your account with certain third party
                          social networking sites (“SNS”) (including, but not
                          limited to, Facebook); via our Platform, as described
                          below. The Membership is limited for the purpose and
                          are subject to the terms, and strictly not
                          transferable.
                        </li>
                        <li>
                          We reserve the right to suspend or terminate your Chef
                          Pin Account and your access to the Services (i) if any
                          information provided during the registration process
                          or thereafter proves to be inaccurate, not current or
                          incomplete; (ii) if it is believed that your actions
                          may cause legal liability for you, other users or us;
                          and/or (iii) if you are found to be non- compliant
                          with the Terms of Use.
                        </li>
                        <li>
                          You are responsible for safeguarding your password.
                          You agree that you will not disclose your password to
                          any third party and that you will take sole
                          responsibility for any activities or actions under
                          your Chef Pin Account, whether or not you have
                          authorized such activities or actions. You will
                          immediately notify us of any unauthorized use of your
                          Chef Pin Account.
                        </li>
                        <li>
                          Goods and services purchased from the Platform are
                          intended for your personal use and you represent that
                          the same are not for resale or you are not acting as
                          an agent for other parties.
                        </li>
                        <li>
                          Any contract that arises out of orders for supply of
                          food and drinks through Chef Pin is a contract between
                          Buyers and Home Chefs.
                        </li>
                        <li>
                          Home Chefs are responsible for making comprehensive
                          profiles on Chef Pin, providing detailed menus,
                          quantities, pricing and all other specifics like
                          preferred mode of delivery, desired notice period for
                          orders and / or any other special services provided.
                          Buyers are expected to understand the home chef’s
                          conditions before ordering. Chef Pin will not be
                          responsible for any discrepancies that may arise
                          thereafter.
                        </li>
                        <li>
                          The Home Chefs are solely responsible for preparation
                          and timely delivery of food and drinks (as per terms
                          specified on their profile) as well as for maintaining
                          quality and hygiene. Chef Pin will not be responsible
                          or liable for the quantity or quality of meals
                          delivered.
                        </li>
                        <li>
                          As a general rule, all food and drinks orders placed
                          on the Platform are treated as confirmed. Once a Buyer
                          places an order on Chef Pin, the Chef must confirm the
                          order, whereby he/she commits to the details of said
                          placed order. Chef Pin will not be liable for any
                          discrepancies that may arise thereafter.
                        </li>
                        <li>
                          It is clarified that Chef Pin reserves the right to
                          not process your order in the event the Buyer is
                          unavailable on the phone at the time we call you for
                          confirming the order and in such event the provisions
                          of the cancellation and refund policy below shall be
                          applicable.
                        </li>
                        <li>
                          Chef Pin is only providing a platform for
                          communication and it is agreed that the contract for
                          sale of any of the products or services shall be a
                          strictly bipartite contract between the Merchant and
                          the Buyer. In case of complaints from the Buyer
                          pertaining to food and drinks efficacy, quality, or
                          any other such issues, Chef Pin shall notify the same
                          to Merchant and shall also redirect the Buyer to the
                          consumer call center of the Merchant. The Merchant
                          shall be liable for redressing Buyer complaints.
                        </li>
                        <li>
                          Chef Pin would facilitate the use third-party delivery
                          providers for Merchants to deliver the products to
                          Buyers. These services would be charged to the Buyer.
                          Chef Pin will not be responsible for any acts of
                          omission and commission by the third-party delivery
                          provider.
                        </li>
                        <li>
                          Buyers acknowledge and agree that we act as the
                          Merchant’s payment agent for the limited purpose of
                          accepting payments from users on behalf of the
                          Merchant. Upon Buyer payment of amounts to us, which
                          are due to the Merchant, Buyer payment obligation to
                          the Merchant for such amounts is completed, and we are
                          responsible for remitting such amounts, to the
                          Merchant. Buyers shall not, under any circumstances
                          whatsoever, make any payment directly to the Merchant
                          for Order bookings made using the Platform.
                        </li>
                        <li>
                          The final tax bill will be issued by the Merchant to
                          the Buyer along with the order and Chef Pin is merely
                          collecting the payment on behalf of such Merchant. All
                          applicable taxes and levies, the rates thereof and the
                          manner of applicability of such taxes on the bill are
                          being charged and determined by the Merchant. Chef Pin
                          holds no responsibility for the legal
                          correctness/validity of the levy of such taxes. The
                          sole responsibility for any legal issue arising on the
                          taxes shall reside with the Merchant.
                        </li>
                        <li>
                          If there is Cash-On-Delivery (e.g. delivery is being
                          done by Home Chefs or if the Buyer is picking up the
                          food and drinks from the Home Chef), then Chef Pin
                          will not be responsible for collecting payments from
                          Buyers and will not be liable therefore for loss that
                          may occur due to non-payment on behalf of Buyer, if
                          any. In either case, if the order is placed through
                          the platform then Chef Pin will charge and recover the
                          convenience fee from the Merchant.
                        </li>
                        <li>
                          Prices on any product(s) as is reflected on the
                          Platform may due to some technical issue,
                          typographical error or product information supplied by
                          Merchant be incorrectly reflected and in such an event
                          Merchant may cancel such your order(s).
                        </li>
                        <li>
                          Chef Pin will not be responsible for any health
                          damages or sickness caused due to the food and drinks
                          delivered by the Home Chefs.
                        </li>
                        <li>
                          Chef Pin will not be responsible for any loss incurred
                          by Buyers due to any actions of the Home Chefs
                          including the non-performance and untimely delivery of
                          the orders.
                        </li>
                        <li>
                          We disclaim any and all liability to the Buyer for the
                          supply of the food and drinks to the fullest extent
                          permissible under applicable law. This does not affect
                          the Buyer’s statutory rights as a consumer.
                        </li>
                        <li>
                          When ordering from Chef Pin, Buyers will receive an
                          automated confirmation email specifying the details of
                          the placed order. Buyers are required to check this
                          email carefully and must inform both Chef Pin and the
                          Home Chef immediately in case of any discrepancy. The
                          said automated confirmation email is not the same as
                          the actual confirmation from the Home Chef committing
                          to fulfill the order.
                        </li>
                        <li>
                          All discounts, promo codes, cash backs, loyalty
                          discounts and other such benefits are applicable on
                          online orders (placed on website or mobile app) only.
                        </li>
                        <li>
                          Any discounts or promotions including referral
                          promotions offered by Chef Pin are in good faith. Any
                          user trying to misuse the offers can be banned or the
                          offer can be revoked at any point, the rights to do so
                          solely rests with Chef Pin.
                        </li>
                        <li>
                          The Home Chefs cannot, under any circumstances, place
                          orders on their own profiles.
                        </li>
                        <li>
                          Chef Pin reserves the right to remove or revoke any
                          credit (referral or otherwise) from Buyer's accounts
                          on identification of misuse, expiration of offer etc.
                        </li>
                        <li>
                          Chef Pin reserves the right to limit usage of credit,
                          whether by way of amount, which chefs / dishes it may
                          be applied on, or in any other way.
                        </li>
                        <li>
                          Buyers understand that the platform allows them to
                          review and rate their experiences of dealing with the
                          Merchant and the quality, quantity and consistency of
                          the food and drinks supplied. Chef Pin is in no way
                          involved in influencing these reviews and ratings.
                          Buyers may provide genuine and honest feedback only
                          for the Merchants from whom they have ordered. If any
                          review or rating by a Buyer is deemed to be
                          inappropriate, inaccurate, dishonest and/or malicious
                          then Chef Pin has full rights to remove the said
                          content and block the Buyer from the platform.
                        </li>
                        <li>
                          We have taken all reasonable steps to prevent Internet
                          fraud and ensure any data collected from you is stored
                          as securely and safely as possible. However, we cannot
                          be held liable in the extremely unlikely event of a
                          breach in our secure computer servers or those of
                          third parties.
                        </li>
                        <li>
                          You are entitled to request a copy of the personal
                          information we hold on you. Please contact us if you
                          wish to request this information.
                        </li>
                        <li>
                          Chef Pin strives to ensure that all information on
                          this website is correct and error free. We apologize
                          for any errors or omissions that may have occurred. We
                          cannot warrant that the use of Chef Pin will be error
                          free or fit for purpose, timely, that all errors will
                          be corrected; or that the site or server that makes it
                          available are free of viruses or bugs. We do not make
                          any warranty whatsoever, whether express or implied,
                          relating to the fitness for purpose, accuracy or
                          reliability.
                        </li>
                        <li>
                          {" "}
                          <span>Disclosure</span>
                          <p>
                            We may disclose to third party services certain
                            personally identifiable information listed below:
                          </p>
                          <p>
                            • Information you provide us such as name, email,
                            mobile phone number
                          </p>
                          <p>
                            • Information we collect as you access and use our
                            service, including device information, location and
                            network carrier
                          </p>
                          <p>
                            This information is shared with third party service
                            providers so that we can:
                          </p>
                          <p>• Personalize the app for you</p>
                          <p>• Perform data analytics</p>
                        </li>
                        <li>
                          By accepting our Terms & Conditions, you are giving
                          Chef Pin permission to send promotional communication
                          about, but not limited to, offers, new chefs, dishes
                          or cuisines etc.
                        </li>
                      </ul>
                      <p>
                        {" "}
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="TermsServiceBuyers" className="box">
                      <h6 className="text-uppercase color_C07">
                        Terms of Service – for Buyers
                      </h6>
                      <p>
                        Buyers agree and acknowledge that Chef Pin shall not be
                        responsible for:
                      </p>
                      <ul className="terms_text_list">
                        <li>
                          The services or goods provided by the Merchant
                          including, but not limited, serving of food and drinks
                          orders suiting your requirements and needs;
                        </li>
                        <li>
                          The Merchant’s services or goods not being up to your
                          expectations or leading to any loss, harm or damage to
                          you;
                        </li>
                        <li>
                          The availability or unavailability of certain items on
                          the menu; or
                        </li>
                        <li>The Merchant serving the incorrect orders.</li>
                        <li>
                          The details of the menu and price list available on
                          the Platform are based on the information provided by
                          the Merchants and we shall not be responsible for any
                          change or cancellation or unavailability.
                        </li>
                        <li>
                          You may not be able to avail our Services if your
                          delivery location is outside our current scope of
                          Service. We will keep you informed of the same at the
                          time of confirming your order booking.
                        </li>
                        <li>
                          You understand that delivery periods quoted to you at
                          the time of confirming the order is an approximate
                          estimate and may vary. We will not be responsible for
                          any delay in the delivery of your order.
                        </li>
                        <li>
                          You order will be only delivered to the address
                          designated by you at the time of placing the order on
                          the Platform. We reserve the right to cancel the
                          order, in our sole discretion, in the event of any
                          change to the place of delivery and you shall not be
                          entitled to any refund for the same. Delivery in the
                          event of change of the delivery location shall be at
                          our sole discretion.
                        </li>
                        <li>
                          You shall undertake to provide adequate directions,
                          information and authorizations to accept delivery. In
                          the event of any failure to accept delivery, failure
                          to deliver within the estimated time due to your
                          failure to provide appropriate instructions, or
                          authorizations, then such goods shall be deemed to
                          have been delivered to you and all risk and
                          responsibility in relation to such goods shall pass to
                          you and you shall not be entitled to any refund for
                          the same. Our decision in relation to this shall be
                          final and binding.
                        </li>
                        <li>
                          If you have specific dietary restrictions, allergies
                          or food intolerances that could harm your health,
                          please read the information on the Home Chef’s menu.
                          You may also contact the Home Chef to confirm any
                          doubts. Chef Pin does not take responsibility for any
                          harm to your health.
                        </li>
                        <li>
                          You understand that our liability ends once your order
                          has been delivered to you.
                        </li>
                      </ul>
                      <p>
                        {" "}
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="CancellationPolicy" className="box">
                      <h6 className="text-uppercase color_C07">
                        Cancellation Policy
                      </h6>
                      {/* <p>Buyers agree and acknowledge that Chef Pin shall not be responsible for:</p> */}
                      <ul className="terms_text_list">
                        <li>
                          As a general rule Buyers shall not be entitled to
                          cancel their order once they have received
                          confirmation of the same. If a Buyer cancels his/her
                          order after it has been confirmed, Chef Pin shall have
                          a right to charge a cancellation fee, up to the value
                          of the order, to compensate our Merchant partners.
                        </li>
                        <li>
                          The Buyer must notify Chef Pin within 15 minutes of
                          placing the order if he/she decides to cancel their
                          order. The cancellation request should be raised via
                          our website or app only. No cancellation requests are
                          allowed over phone or email.
                        </li>
                        <li>
                          In the unlikely event of an item on your order being
                          unavailable, the Home Chef may not accept your order
                          or inform you of such unavailability. In such an event
                          you will be entitled to cancel the entire order and
                          shall be entitled to a refund in accordance with our
                          refund policy.
                        </li>
                        <li>
                          We reserve the sole right to cancel your order in the
                          following circumstance:
                        </li>
                      </ul>
                      <p>
                        i) In case the home chef does not accept your order;
                      </p>
                      <p>
                        ii) in the event of the designated address falls outside
                        the delivery zone offered by us;
                      </p>
                      <p>
                        iii) failure to contact you by phone or email at the
                        time of confirming the order booking;
                      </p>
                      <p>
                        iv) failure to deliver your order due to lack of
                        information, direction or authorization from you at the
                        time of delivery; or
                      </p>
                      <p>
                        v) unavailability of all the items ordered by you at the
                        time of booking the order
                      </p>
                      <p>
                        {" "}
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="RefundPolicy" className="box">
                      <h6 className="text-uppercase color_C07">
                        Refund Policy
                      </h6>
                      <p>
                        The Buyer shall be entitled to a full or partial refund
                        only if you pay for your order at the time of placing
                        your order on the Platform and only in the event of any
                        of the following circumstances:
                      </p>
                      <ul className="terms_text_list">
                        <li>
                          the cancellation was made in time on the platform as
                          per the Cancellation Policy and once the chef has
                          accepted your cancellation;
                        </li>
                        <li>
                          your order packaging has been tampered or damaged at
                          the time of delivery;
                        </li>
                        <li>
                          us cancelling your order due to
                          <ul className="terms_text_list">
                            <li>
                              In case the home chef does not accept your order;
                            </li>
                            <li>
                              your delivery location following outside our
                              designated delivery zones;
                            </li>
                            <li>
                              failure to contact you by phone or email at the
                              time of confirming the order booking;
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <p>
                        Due to unavailability of some of the items you ordered
                        at the time of booking, if you decide to modify the
                        order at the time of confirmation, and accept only the
                        items which are available in your original order, then
                        you will be refunded the amount paid for the items which
                        are unavailable.
                      </p>
                      <p>
                        Our decision on refunds shall be at our sole discretion
                        and shall be final and binding.
                      </p>
                      <p>
                        All refund amounts shall be credited to your account
                        within 5-6 business days in accordance with the terms
                        that may be stipulated by the bank which has issued the
                        credit / debit card.
                      </p>
                      <p>
                        In case of payment at the time of delivery, you will not
                        be required to pay for:
                      </p>
                      <ul className="terms_text_list">
                        <li>
                          orders where the packaging has been tampered or
                          damaged by the third-party delivery partner;
                        </li>
                        <li>wrong order being delivered; or</li>
                        <li>
                          items missing from your order at the time of delivery.
                        </li>
                      </ul>
                      <p>
                        {" "}
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="Intellectual" className="box">
                      <h6 className="text-uppercase color_C07">
                        Intellectual property
                      </h6>
                      <p>
                        We are either the owner of intellectual property rights
                        or have the non-exclusive, worldwide, perpetual,
                        irrevocable, royalty free, sub-licensable (through
                        multiple tiers) right to exercise the intellectual
                        property, in the Platform, and in the material published
                        on it.
                      </p>
                      <p>
                        You must not use any part of the materials on the
                        Platform for commercial purposes without obtaining a
                        license to do so from us or our licensors.
                      </p>
                    </div>
                    <div id="Governing" className="box">
                      <h6 className="text-uppercase color_C07">
                        Governing law and dispute resolution
                      </h6>
                      <p>
                        These Terms of Use are governed by the laws of India.
                        Any action, suit, or other legal proceeding, which is
                        commenced to resolve any matter arising under or
                        relating to this Platform, shall be subject to the
                        jurisdiction of the courts at New Delhi, India.
                      </p>
                    </div>
                    <div id="Disclaimers" className="box">
                      <h6 className="text-uppercase color_C07">Disclaimers</h6>
                      <p>
                        IMAGES ON THE CHEF PROFILE PAGES ON THE WEBSITE AND APP
                        ARE NOT PROVIDED BY CHEF PIN AND ARE THE SOLE PROPERTY
                        OF THE HOME CHEFS. ITEMS WHICH ARE DELIVERED MAY NOT
                        LOOK EXACTLY AS SHOWN. IF THERE IS ANY DISPUTE OR
                        LITIGATION THAT ARISES AS A RESULT OF ITEMS PURCHASED
                        BASED ON THESE IMAGES THE HOME CHEFS WILL TAKE FULL
                        RESPONSIBILITY.
                      </p>
                      <p>
                        THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME
                        FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL.
                      </p>
                      <p>
                        DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC
                        DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS
                        INHERENT IN PROVIDING INFORMATION OBTAINED FROM MULTIPLE
                        SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR INACCURACIES
                        IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR
                        ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE
                        DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT
                        IN EVERY CASE.
                      </p>
                      <p>
                        WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS
                        A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT
                        CARDS.
                      </p>
                      <p>
                        YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE
                        ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR
                        ALLIANCES WITH SOME OF THESE THIRD PARTIES FROM TIME TO
                        TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN
                        SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT
                        AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY
                        REGARDING ANY THIRD PARTY’S SERVICES NOR WILL WE BE
                        LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR
                        CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD
                        PARTY INCLUDING, AND NOT LIMITED TO, ANY LIABILITY OR
                        RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT
                        EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY
                        DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU MAY HAVE
                        AGAINST US WITH RESPECT TO THIRD PARTY’S / MERCHANTS
                        SERVICES.
                      </p>
                      <p>
                        CHEF PIN DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE
                        TO ANY VIOLATION OF THE FOOD SAFETY AND STANDARDS ACT,
                        2006 AND APPLICABLE RULES AND REGULATIONS MADE
                        THEREUNDER AND SUCH LIABILITY SHALL BE ATTRIBUTABLE TO
                        THE MERCHANT.
                      </p>
                      <p>
                        WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE
                        PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE
                        SUBJECT DISCUSSED, THE INFORMATION CONTAINED IN THESE
                        MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING
                        THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR
                        WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED,
                        WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE,
                        QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE
                        DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR
                        DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE TO YOU
                        OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN
                        IN RELIANCE ON SUCH INFORMATION.
                      </p>
                      <p>
                        THE INFORMATION PROVIDED HEREUNDER IS PROVIDED “AS IS”.
                        WE AND / OR OUR EMPLOYEES MAKE NO WARRANTY OR
                        REPRESENTATION REGARDING THE TIMELINESS, CONTENT,
                        SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS OF ANY
                        INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE
                        INFORMATION OR DATA PROVIDED HEREUNDER MAY BE RELIED
                        UPON. MULTIPLE RESPONSES MAY USUALLY BE MADE AVAILABLE
                        FROM DIFFERENT SOURCES AND IT IS LEFT TO THE JUDGEMENT
                        OF USERS BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE,
                        ADAPT, MODIFY OR ALTER SUGGESTIONS OR USE THEM IN
                        CONJUNCTION WITH ANY OTHER SOURCES THEY MAY HAVE,
                        THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS,
                        BUSINESS ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND
                        EMPLOYEES FROM ANY KIND OF PROFESSIONAL LIABILITY.
                      </p>
                      <p>
                        WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
                        LOSSES OR INJURY ARISING OUT OF OR RELATING TO THE
                        INFORMATION PROVIDED ON THE PLATFORM. IN NO EVENT WILL
                        WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR AGENTS BE
                        LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE
                        OR ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT
                        CONTAINED HEREIN.
                      </p>
                      <p>
                        IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES
                        (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT,
                        INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                        DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH, AND
                        DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR
                        BUSINESS INTERRUPTION), RESULTING FROM ANY SERVICES
                        PROVIDED BY ANY THIRD PARTY OR MERCHANT ACCESSED THROUGH
                        THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT,
                        OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT WE ARE
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WhatsAppButton />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(TermsPage);
