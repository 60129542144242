/*
#############################################################################
# Name : dinerAlert.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the diner alert data
#############################################################################
*/
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from "../../components/whatsappButton";
import { Link, Redirect } from "react-router-dom";
import config from "../../config/config";
import { getAlertChefDataAPI } from "../../core/services/Phase2APIservice";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { saveChefAlertData } from "../../redux/actions/alertActions";
import { connect } from "react-redux";
class ChefAlertPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      selectedCity: "",
      alertData: [],
      activePage: 1,
      totalRecords: 10,
    };
  }
  componentDidMount() {
    this.getAlertData();
  }

  getAlertData = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getAlertChefDataAPI(userId, token, currentPage)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            alertData: response.data.data,
            totalRecords: response.data.total_alerts_count,
          });
          this.props.saveChefAlertData(response.data.unread_alerts_count);
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getAlertData();
    });
  }

  render() {
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        {this.state.alertData ? (
          <section className="alert_sec chef_flow registration bussiness">
            <div className="container">
              <h6 className="res_back_btn_wrap text-left p-0">
                <Link to={"/chef-dashboard"} className="res_back_btn">
                  <img
                    src="assets/images/icons/left-arrow-black.svg"
                    alt="left-arrow-black"
                  />
                </Link>
                ALERTS
              </h6>
              <ul className="alert_list">
                {this.state.alertData.map((alert, a) => {
                  return (
                    <li key={"alert" + a}>
                      <span
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: alert.message,
                        }}
                      ></span>
                      <ul className="day_time">
                        <li>
                          <span>{Moment(alert.created_at).format("ll")}</span>
                          <span>{Moment(alert.created_at).format("LT")}</span>
                        </li>
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="text-right">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={10}
                totalItemsCount={this.state.totalRecords}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </section>
        ) : (
          <section className="not_found empty_section">
            <div className="container text-center">
              <h6 className="res_back_btn_wrap text-left p-0">
                <Link to={"/chef-dashboard"} className="res_back_btn">
                  <img
                    src="assets/images/icons/left-arrow-black.svg"
                    alt="left-arrow-black"
                  />
                </Link>
                ALERTS
              </h6>
              <img
                src="assets/images/icons/no-notifications.svg"
                alt="Not Found"
                className="img-fluid"
              />
              <h6>NO NOTIFICATIONS</h6>
              <p>
                There are no notifications for you to see
                <br /> at this time. You have been notified!
              </p>
              <Link to={"/chef-dashboard"} className="btn">
                GO BACK TO HOMEpage
              </Link>
            </div>
          </section>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? null : <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  alertChefData: state.chefAlert.items,
});

export default connect(mapStateToProps, {
  saveChefAlertData,
  removeMobileView,
})(ChefAlertPage);
