import React, { useState } from "react";
import MultiSelectDropdown from "../../components/MultiSelect";
import Select from "react-select";

function TiffinServiceForm() {
    const [formData, setFormData] = useState({
        diner_name: "",
        diner_mobile_number: "",
        location: "",
        locality: "",
        number_of_days: "",
        meal_for: "",
        meal_type: "",

    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const meal_types = [
        { value: "Veg", label: "Veg" },
        { value: "Non Veg ", label: "Non Veg" },
    ];

    const meal_fors = [
        { value: "Breakfast", label: "Breakfast" },
        { value: "Lunch", label: "Lunch" },
        { value: "Dinner", label: "Dinner" },
        { value: "All Meals", label: "All Meals" },
    ];

    const locations = [
        { value: "Delhi", label: "Delhi" },
        { value: "Gurgaon", label: "Gurgaon" },
        { value: "Noida", label: "Noida" },
        { value: "Mumbai", label: "Mumbai" },
        { value: "Bengaluru", label: "Bengaluru" },
        { value: "Other", label: "Other" },
    ];
    const requirements = [
        { value: "Serving Staff", label: "Serving Staff" },
        { value: "Crockery/Cultery", label: "Crockery/Cultery" },
        { value: "Chafing Dishes", label: "Chafing Dishes" },
    ];
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };




    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(formData);

        var formdata = new FormData();

        formdata.append("diner_name", formData.diner_name);
        formdata.append("diner_mobile_number", formData.diner_mobile_number);
        formdata.append("location", formData.location);
        formdata.append("meal_for", formData.meal_for);
        formdata.append("meal_type", formData.meal_type);
        formdata.append("locality", formData.locality);
        formdata.append("number_of_days", formData.number_of_days);


        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(
            "https://api.chefp.in/api/v1/store-tiffin-order-enquiry",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                // console.log(formData);
                if (result.message == "Tiffin Enquiry Save Successfully.") {
                    // alert(
                    //     "We have received your Tiffin Service Enquiry. We will get our selected home chefs to contact you shortly."
                    // );
                    // window.location.reload();
                    window.location.href = "/tiffin-service-thank-you";

                } else {
                    alert("Error" + "", result.error);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const [customValue, setCustomValue] = useState("");
    const handleAddCustomValue = () => {
        if (customValue) {
            const newOption = {
                value: customValue,
                label: customValue,
            };
            setFormData({
                ...formData,
                prefer_cuisine: [...formData.prefer_cuisine, newOption],
            });
            setCustomValue("");
        }
    };
    return (
        <form
            onSubmit={handleSubmit}
            style={{
                maxWidth: "700px",
                margin: "0 auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                backgroundColor: "#f8f8f8",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            <h2 className="text-uppercase color_C07 mb-4">Enquire for Tiffin Service</h2>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>



                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Location*:
                    </label>

                    <Select
                        options={locations}
                        onChange={(text) => {
                            setFormData({ ...formData, location: text.value });
                        }}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Locality*:
                    </label>
                    <input
                        type="text"
                        name="locality"
                        value={formData.locality}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Meal Time*:
                    </label>

                    <Select
                        options={meal_fors}
                        onChange={(text) => {
                            setFormData({ ...formData, meal_for: text.value });
                        }}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Meal Preference*:
                    </label>

                    <Select
                        options={meal_types}
                        onChange={(text) => {
                            setFormData({ ...formData, meal_type: text.value });
                        }}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Number of Days*:
                    </label>
                    <input
                        type="number"
                        name="number_of_days"
                        value={formData.number_of_days}
                        onChange={handleInputChange}
                        required
                        min={1}
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Your Name*:
                    </label>
                    <input
                        type="text"
                        name="diner_name"
                        value={formData.diner_name}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Your Phone Number*:
                    </label>
                    <input
                        type="tel"
                        name="diner_mobile_number"
                        value={formData.diner_mobile_number}
                        onChange={handleInputChange}
                        required
                        title="Please enter exactly 10 digits"
                        pattern="[1-9]{1}[0-9]{9}"
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>


                <p> <b> Please Note: Tiffin meals start from INR 120 for veg/ INR 170 for non-veg </b></p>



                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button type="submit" style={{
                        backgroundColor: "#007BFF",
                        color: "#fff",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                    }}>Submit</button>
                </div>
            </div>
        </form>
    );
}

export default TiffinServiceForm;
