/*
#############################################################################
# Name : emptyAccountState.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the empty state
#############################################################################
*/
import React from "react";
import { Link, withRouter } from "react-router-dom";
import DinerFooter from "../../../../components/dinerFooter";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import WhatsAppButton from "../../../../components/whatsappButton";
class EmptyAccountState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }

  /* goBack function starts here */
  goBack = () => {
    this.props.history.goBack();
  };
/* goBack function ends here */

/* storageHandler function starts here */
  storageHandler = (e) => {
    localStorage.clear();
  };
/* storageHandler function ends here */

  render() {
    return (
      <div className="css2 mt-0">
        <section className="not_found empty_section">
          <div className="container text-center">
            <div className="res_back_btn_wrap text-left p-0">
              <a className="res_back_btn">
                <img
                  src="assets/images/icons/left-arrow-black.svg"
                  alt="left-arrow-black"
                  onClick={this.goBack}
                />
              </a>
            </div>
            <img
              src="assets/images/icons/hello-stranger.svg"
              alt="Not Found"
              className="img-fluid"
            />
            <h6>HELLO STRANGER</h6>
            <p>
              Allow us to serve you better by signing
              <br /> up or logging in to Chef Pin.
            </p>
            <Link
              to="/diner-signin-with-otp"
              className="btn"
              onClick={(e) => this.storageHandler(e)}
            >
              LOG IN
            </Link>
            <p className="orange_link">
              New to Chef Pin?{" "}
              <Link
                to="/user-selection"
                className="color_C07"
                onClick={(e) => this.storageHandler(e)}
              >
                Create an Account
              </Link>
            </p>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? (
          <div className="sidebar_footer">
            <DinerFooter />
          </div>
        ) : null}
        
        <DinerMenuTab />
      </div>
    );
  }
}

export default EmptyAccountState;
