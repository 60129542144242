/*
#############################################################################
# Name : index.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used render the React component
#############################################################################
*/
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

// import GA4React from "ga-4-react";
// const ga4react = new GA4React("G-HLP84DFKZN");

(async () => {
  ReactDOM.render(<App />, document.getElementById('root'));
})();

serviceWorker.unregister();
