/*
#############################################################################
# Name : selectedForYou.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the selected for you chef data
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../components/dinnerHeader";
import DinerFooter from "../../../components/dinerFooter";
import WhatsAppButton from "../../../components/whatsappButton";
import LoadingDiv from "../../../components/LoadingDiv";
import { vipHomeChefListingAPI } from "../../../core/services/Phase2APIservice";
import Slider from "react-slick";
import DinerMenuTab from "../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import config from "../../../config/config";
class SelectedForYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
      },
      vipChefArr: [],
      showFilterFlag: false,
      errorStatus: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getVipChef();                //VIP Chef data is fetched
  }

  /* getVipChef function starts here */
  getVipChef = async () => {
    var city = localStorage.getItem("selectedLocationName");
    var pincode = localStorage.getItem("pincode");
    var latitude = localStorage.getItem("latitude");
    var longitude = localStorage.getItem("longitude");
    try {
      const response = await vipHomeChefListingAPI(
        city,
        pincode,
        latitude,
        longitude
      );
      if (response.data.status === 200) {
        this.setState({
          vipChefArr: response.data.data.vip_chef_list,
          isLoading: false,
        });
        if (response.data.data.vip_chef_list.length === 0) {
          this.setState({
            errorStatus: true,
          });
        } else {
          this.setState({
            errorStatus: false,
          });
        }
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      /* console.log(error); */
    }
  };
 /* getVipChef function ends here */

  /* getFilter function starts here */
  getFilter = (e) => {
    this.setState({
      showFilterFlag: true,
    });
  };
  /* getFilter function ends here */

  /* closePopup function starts here */
  closePopup = (event) => {
    event.preventDefault();
    this.setState({
      showFilterFlag: false,
    });
  };
 /* closePopup function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    // var urlParam = config.slugify(kitchenName);
    // if (brandName.length > 0) {
    //  urlParam =  config.slugify(brandName);
    // } 
    // if(chefAvailable === "1"){
      this.props.history.push("/chef-inner-homepage/" + slugUrl); 
    // }
  };
  /* getInerPage function ends here */


  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <div>
            <section className="shoe_all_bg text-white">
              <div className="container">
                <h4>Selected for you</h4>
              </div>
            </section>
            <section className="selected_you selected_for_you see_space">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <div className="row">
                      {this.state.vipChefArr.map((vip, i) => {
                        return (
                          <div
                            className="col-sm-6 col-lg-3 col-xl-3"
                            key={"hgrfvb" + i}
                          >
                            <div
                              className={vip.is_chef_available === "1" ? "card" : "card unavailable-chef-card"}
                              onClick={() =>
                                this.getInerPage(
                                  vip.chef_id,
                                  vip.slug_url,
                                  vip.name,
                                  vip.is_chef_available
                                )
                              }
                            >
                              {vip.promoted === "1" ? (
                                <div className="PromoShape">
                                  <a href="#">Promoted</a>
                                </div>
                              ) : null}
                              <div className="img_wrap">
                                {vip.profile_image !== null ? (
                                  <img
                                    src={vip.profile_image}
                                    alt=" Selected for You "
                                    className=" img-fluid "
                                  />
                                ) : (
                                  <img
                                    src="assets/images/noimage.png "
                                    alt=" Selected for You "
                                    className=" img-fluid "
                                  />
                                )}
                              </div>
                              <div className="text_wrap">
                                <h6>
                                  {vip.verified === "1" ? (
                                    <span className="correct_icn">
                                      {vip.name}
                                    </span>
                                  ) : (
                                    <span>{vip.name}</span>
                                  )}{" "}
                                  <span className="star">
                                    4.5 <span className="grey">/ 5</span>
                                  </span>
                                </h6>
                                {vip.cuisines === "" ? (
                                  "-"
                                ) : (
                                  <p className="sub_title">{vip.cuisines}</p>
                                )}
                                <ul>
                                  <li className="location_icn">
                                    {vip.locality == null &&
                                    vip.locality === "" ? null : (
                                      <span>{vip.locality}, </span>
                                    )}{" "}
                                    {vip.distance == null
                                      ? ""
                                      : parseFloat(vip.distance).toFixed(
                                          1
                                        )}{" "}
                                    kms
                                  </li>
                                  {vip.is_takeaway_available == 1 ? (
                                    <li>Available for Delivery & Takeaway</li>
                                  ) : (
                                    <li>Available for Delivery</li>
                                  )}
                                </ul>
                                {vip.special_dishes !== "" ? (
                                  <p>
                                    {vip.name} is known for cooking the best{" "}
                                    {vip.special_dishes}.
                                  </p>
                                ) : (
                                  <p>
                                    {vip.name} is known for cooking the best
                                    Dosa, Idli's, Vada's etc.
                                  </p>
                                )}
                               {/*  <div className="badge_slide">
                                  <Slider
                                    className="inner_slider"
                                    {...this.state.settings}
                                  >
                                    <div className="box">
                                      <img
                                        src="assets/images/icons/select-you-hand.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>
                                    <div className="box">
                                      <img
                                        src="assets/images/icons/super-chefs.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>
                                    <div className="box">
                                      <img
                                        src="assets/images/icons/select-you-hand.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>
                                    <div className="box">
                                      <img
                                        src="assets/images/icons/super-chefs.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>
                                    <div className="box">
                                      <img
                                        src="assets/images/icons/select-you-hand.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>
                                    <div className="box">
                                      <img
                                        src="assets/images/icons/super-chefs.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>
                                  </Slider>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {this.state.errorStatus ? (
                      <div className="not_found">
                        <div className="container text-center">
                          <img
                            src="assets/images/icons/not-found.svg"
                            alt="Not Found"
                            className="img-fluid"
                          />
                          <h6>SORRY! NO RESULTS FOUND.</h6>
                          <p>
                            We can’t find anything related to your <br />{" "}
                            search. Try a different search.
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        {this.state.showFilterFlag === true ? (
          <div
            className="modal bottom_pop fade map_pop tabs_modal_wrap show"
            id="bottom_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="bottom_modal"
            style={{ paddingRight: "17px", display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <h5>
                    FILTERS
                    <button
                      type="button"
                      className="close_icon"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.closePopup}
                    >
                      <img
                        src="assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                  </h5>
                  <div className="tabs_modal">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="home-tab"
                          data-toggle="tab"
                          href="#one"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Cuisine <span className="d-block">1 selected</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="profile-tab"
                          data-toggle="tab"
                          href="#two"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Rating
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="contact-tab"
                          data-toggle="tab"
                          href="#three"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          Categories
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="contact-tab"
                          data-toggle="tab"
                          href="#four"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          Dietary <br />
                          requirements
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="contact-tab"
                          data-toggle="tab"
                          href="#five"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                        >
                          More filters
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="one"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="cuisine_list_wrap">
                          <div className="search_box">
                            <form>
                              <div className="form-group has-searchh mb-0">
                                <span className="search_icon">
                                  <img
                                    src="assets/images/icons/search-desktop.svg"
                                    alt="Search"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search for cuisines..."
                                />
                              </div>
                            </form>
                          </div>
                          <div className="form-group checklist_wrap">
                            <ul className="contn_itm">
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  American
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="two"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <ul className="rating_sele">
                          <li>
                            <img
                              src="assets/images/icons/star.svg"
                              alt="Star"
                            />
                            <span>Rating selected</span>
                          </li>
                          <li className="rating_bold">4.0+</li>
                        </ul>
                        <div className="cuisine_list_wrap">
                          <div className="form-group radio_wrap">
                            <ul className="radio_list d-block">
                              <li>
                                <label className="custome_check">
                                  4
                                  <img
                                    src="assets/images/icons/star.svg"
                                    alt="Star"
                                  />
                                  <span className="text">and above</span>
                                  <input type="radio" name="Item" />
                                  <span className="radiobtn"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  3
                                  <img
                                    src="assets/images/icons/star.svg"
                                    alt="Star"
                                  />
                                  <span className="text">and above</span>
                                  <input type="radio" name="Item" />
                                  <span className="radiobtn"></span>
                                </label>
                              </li>
                              <li>
                                <label className="custome_check">
                                  2
                                  <img
                                    src="assets/images/icons/star.svg"
                                    alt="Star"
                                  />
                                  <span className="text">and above</span>
                                  <input type="radio" name="Item" />
                                  <span className="radiobtn"></span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="three"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        ...
                      </div>
                      <div
                        className="tab-pane fade"
                        id="four"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        ...
                      </div>
                      <div
                        className="tab-pane fade"
                        id="five"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        ...
                      </div>
                    </div>
                  </div>
                  <div className="bottom_btn">
                    <div className="form-group save_btn_next btn-group can_itm_grp d-flex mw-100">
                      <div>
                        <button
                          type="submit"
                          className="btn previous_btn cancel_btn"
                        >
                          clear all
                        </button>
                      </div>
                      <div>
                        <button type="submit" className="btn">
                          apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showFilterFlag === true ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default SelectedForYou;
