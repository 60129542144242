import React, { useState, useEffect } from "react";
import "./Banner.css";
const Banner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const hideBanner = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 1000) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  };

  const handleClose = () => {
    setShowBanner(false);
    window.addEventListener("scroll", () => {
      setShowBanner(false);
    });
  };

  return (
    <div className={`banner ${showBanner ? "show" : ""}`}>
      <div className="row bannercontent ">
        <div className="col-3">
          <img
            src="/assets/images/bannermobile.png"
            alt=""
            className="img-fluid banner-phone"
          />
        </div>
        <div className="col-3 mt-4 mb-2 d-flex justify-content-center">
          <h6 style={{ color: "#fff" }}>Scan QR to get app</h6>
          <div className="qrsect d-flex justify-content-around">
            <div className="d-flex flex-column align-items-center">
              <img
                src="/assets/images/ChefpinAndroid.png"
                alt=""
                className="img-fluid appqr"
              />
              <span style={{ color: "#fff", marginTop: 5, fontWeight: "bold" }}>
                Android
              </span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <img
                src="/assets/images/ChefPinApple.png"
                alt=""
                className="img-fluid appqr"
              />
              <span style={{ color: "#fff", marginTop: 5, fontWeight: "bold" }}>
                iOS
              </span>
            </div>
          </div>
        </div>
        <div className="col-3 mt-4 mb-2">
          <h6 style={{ color: "#fff" }}>
            Get flat 40%* off on your first order!
          </h6>
          <h6 style={{ color: "#fff" }}>Use code: FIRST40</h6>
          <span
            style={{
              color: "#fff",
              marginTop: "35px",
              display: "block",
              position: "relative",
              left: "350px",
              fontSize: "12px",
            }}
          >
            *upto Rs.250
          </span>
        </div>
        <div className="col-1 mt-4 mb-2">
          <button className="close-button" onClick={handleClose}>
            <h5 style={{ color: "#fff" }}>x</h5>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
