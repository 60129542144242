import React, { useState, useEffect } from "react";
import "../BackendStyle.css";
import { URLs } from "../Url";
import {
  HomeImages,
  HomeImagesUpload,
  HomeImagesUpdate,
  handlePostImage,
  handledeleteImage,
  DeleteHomeImages,
} from "./config";
function HeaderImages() {
  const [data, setdata] = useState([]);
  const [alttext, setalttext] = useState();
  const [image, setimage] = useState();
  const [updateimage, setupdateimage] = useState();
  const [UpdateId, setUpdateId] = useState();
  const [deleteimage, setdeleteimage] = useState();

  const [isLoading, setisLoading] = useState(true);
  const [show, setShow] = useState(true);
  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    const getgteanData = await HomeImages();
    if (getgteanData.success == true) {
      console.log(getgteanData);
      setdata(getgteanData.data);
    }
  };
  const handlerUpdateData = async (Getimage) => {
    const ImageCheck = updateimage == undefined ? image : Getimage;

    const getgteanData = await HomeImagesUpdate(UpdateId, alttext, Getimage);
    if (getgteanData.success == true) {
      handlerData();
      if (updateimage != undefined) {
        ImageDeleteHandler(deleteimage);
        UpdateVar();
      }
      setShow(true);
    }
  };
  const handleImage = async () => {
    if (image != undefined && alttext != undefined) {
      const GetData = await handlePostImage(image);
      if (GetData.success == true) {
        if (show == "update") {
          handlerUpdateData(GetData.image);
        } else {
          handlerUploadData(GetData.image);
        }
      }
    } else {
      alert("empty fields");
    }
  };
  const handlerDelete = async (id, imageId) => {
    const getgteanData = await DeleteHomeImages(id);
    if (getgteanData.success == true) {
      ImageDeleteHandler(imageId);
      handlerData();
    }
  };
  const handlerUploadData = async (Getimage) => {
    const getgteanData = await HomeImagesUpload(alttext, Getimage);
    if (getgteanData.success == true) {
      handlerData();

      setShow(true);
    }
  };
  const ImageDeleteHandler = async (imageDelete) => {
    const getResponse = await handledeleteImage(imageDelete);
    if (getResponse.success == true) {
      UpdateVar();
    }
  };
  const UpdateItem = (item) => {
    setalttext(item.alttext);
    setimage(item.image);
    setdeleteimage(item.image);
    setUpdateId(item._id);
    setShow("update");
  };
  const UpdateVar = () => {
    setalttext();
    setimage();
    setupdateimage();
    setdeleteimage();
    setUpdateId();
  };
  console.log(updateimage);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setupdateimage(URL.createObjectURL(event.target.files[0]));
      setimage(event.target.files[0]);
    }
  };
  return (
    <>
      {show == "update" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Alt Text</label>
              <textarea
                class="form-control"
                rows="7"
                value={alttext}
                onChange={(e) => setalttext(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChange}
              />
            </div>
            <div className="form-group">
              {updateimage == undefined ? (
                <img
                  src={URLs.images + image}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              ) : (
                <img
                  src={updateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              )}
            </div>
            <button
              onClick={() => {
                if (updateimage == undefined) {
                  handlerUpdateData();
                } else {
                  handleImage();
                }
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : show == "add" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Alt Text</label>
              <textarea
                class="form-control"
                rows="7"
                value={alttext}
                onChange={(e) => setalttext(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChange}
              />
            </div>
            {updateimage != undefined ? (
              <div className="form-group">
                <img
                  src={updateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              </div>
            ) : null}
            <button
              onClick={() => {
                handleImage();
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="content">
          <div className="row table-invoice">
            <div className="col-sm-12">
              <div className="table-in-card">
                <div class="d-flex justify-content-between">
                  <h3>Header Images</h3>

                  <button
                    type="button"
                    onClick={() => setShow("add")}
                    class="btn btn-primary btn-xs"
                  >
                    <small>Add</small>
                  </button>
                </div>
                <br />
                {/* <hr /> */}
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Alt Text</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length == 0
                        ? null
                        : data.map((item) => {
                            return (
                              <tr>
                                <td>
                                  <img
                                    src={URLs.images + item.image}
                                    alt=""
                                    className="img-fluid"
                                    style={{ width: 100, height: 100 }}
                                  />
                                </td>
                                <td>{item.alttext}</td>

                                <td>
                                  <div class="d-flex justify-content-evenly">
                                    <button
                                      type="button"
                                      onClick={() => UpdateItem(item)}
                                      class="btnnnnn btn-primary"
                                      style={{ marginRight: 10 }}
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handlerDelete(item._id, item.image)
                                      }
                                      class="btnnnnn btn-danger"
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HeaderImages;
