/*
#############################################################################
# Name : aboutBusiness.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to save and update the chef business detail
#############################################################################
*/
import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  getCateringFacilitiesAPI,
  detailsAboutBusinessAPI,
  getUserSummaryAPI,
} from "../../core/services/APIservice";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import $ from "jquery";

const gstNumberReg =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
const numberReg = /^[0-9\b]+$/;
const decimalReg = /^[0-9\.\b]+$/;

class AboutBusinessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityArr: [],
      venueFacilityArr: [],
      checkedArray: [],
      facilityCheckedArray: [],
      venueFacilityCheckedArray: [],
      responseFacilities: [],
      responseVenueFacilities: [],
      selectAllCheckbox: false,
      loading: false,
      startTimestate: "AM",
      startTime: "",
      endTime: "",
      endTimestate: "PM",
      maximumPeople: "",
      fssaiSelected: "",
      gstSelected: "",
      fssaiPhoto: "",
      fssaiPhotoName: "",
      verifyPhoto: "",
      verifyPhotoName: "",
      offerSelected: "",
      partyOrderSelected: "",
      eventSelected: "",
      busineesYear: "",
      gstNumber: "",
      takeawayInput: "",
      orderAmount: "",
      maximumPeopleCount: "",
      maximumGuestCount: "",
      operationList: [
        {
          id: 1,
          value: "Monday",
          isChecked: false,
        },
        {
          id: 2,
          value: "Tuesday",
          isChecked: false,
        },
        {
          id: 3,
          value: "Wednesday",
          isChecked: false,
        },
        {
          id: 4,
          value: "Thursday",
          isChecked: false,
        },
        {
          id: 5,
          value: "Friday",
          isChecked: false,
        },
        {
          id: 6,
          value: "Saturday",
          isChecked: false,
        },
        {
          id: 7,
          value: "Sunday",
          isChecked: false,
        },
      ],
      timeList: [
        {
          id: 2,
          value: "01:00",
        },
        {
          id: 3,
          value: "02:00",
        },
        {
          id: 4,
          value: "03:00",
        },
        {
          id: 5,
          value: "04:00",
        },
        {
          id: 6,
          value: "05:00",
        },
        {
          id: 7,
          value: "06:00",
        },
        {
          id: 8,
          value: "07:00",
        },
        {
          id: 9,
          value: "08:00",
        },
        {
          id: 10,
          value: "09:00",
        },
        {
          id: 11,
          value: "10:00",
        },
        {
          id: 12,
          value: "11:00",
        },
        {
          id: 13,
          value: "12:00",
        },
      ],
      chars_left: 200,
      errorFssai: "",
      errorFssaiName: "",
      errorGST: "",
      errorGSTNumber: "",
      errorVerfication: "",
      errorDay: "",
      errorStartTime: "",
      errorEndTime: "",
      errorOperationDay: "",
      errorFssaiUpload: "",
      errorVerficationUpload: "",
      errorValidGSTNumber: "",
      formErrors: {
        busineesYear: "",
        orderAmount: "",
        maximumGuestCount: "",
        averageMealPrice: "",
      },
      gstErrorFlag: false,
      fssaiErrorFlag: false,
      averageMealPrice: "",
    };
    this.handleFSSAIChange = this.handleFSSAIChange.bind(this);
    this.handleGSTChange = this.handleGSTChange.bind(this);
    this.handleOfferChange = this.handleOfferChange.bind(this);
    this.handlePartyOrderChange = this.handlePartyOrderChange.bind(this);
    this.handleEventChange = this.handleEventChange.bind(this);
    this.handleStartTimeStateChange =
      this.handleStartTimeStateChange.bind(this);
    this.onAboutBusinessSubmit = this.onAboutBusinessSubmit.bind(this);
    this.handleEndTimeStateChange = this.handleEndTimeStateChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleAboutBusinessChange = this.handleAboutBusinessChange.bind(this);
    this.handleMaximumPeopleChange = this.handleMaximumPeopleChange.bind(this);
    this.handleAveragePersonChange = this.handleAveragePersonChange.bind(this);
    this.handleGSTNumberChange = this.handleGSTNumberChange.bind(this);
    this.handleAboutBusinessTextareaChange =
      this.handleAboutBusinessTextareaChange.bind(this);
  }

  /* handleNumnberChange function starts here */
  handleNumnberChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "busineesYear":
        formErrors.busineesYear = !numberReg.test(e.target.value)
          ? "Enter valid year when business started"
          : "";
        break;

      case "orderAmount":
        formErrors.orderAmount = !decimalReg.test(e.target.value)
          ? "Enter valid Order Amount"
          : "";
        break;
      case "averageMealPrice":
        formErrors.averageMealPrice = !decimalReg.test(e.target.value)
          ? "Enter valid cost for two/average meal price"
          : "";
        break;

      case "maximumGuestCount":
        formErrors.maximumGuestCount = !numberReg.test(e.target.value)
          ? "Enter valid Maximum Guest"
          : "";
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
  /* handleNumnberChange function ends here */

  /* onAboutBusinessSubmit function starts here */
  onAboutBusinessSubmit = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    if (this.state.fssaiSelected === "") {
      this.setState({ errorFssai: "Please select any one option" });
    } else {
      this.setState({
        errorFssai: "",
      });
    }

    if (
      this.state.fssaiSelected === "Yes" ||
      this.state.fssaiSelected === "Applied"
    ) {
      if (this.state.fssaiPhotoName === "") {
        this.setState({ errorFssaiName: "FSSAI Registation File is required" });
      } else {
        this.setState({
          errorFssaiName: "",
        });
      }
    }

    if (this.state.gstSelected === "") {
      this.setState({ errorGST: "Please select any one option" });
    } else {
      this.setState({
        errorGST: "",
      });
    }

    if (this.state.gstSelected === "1") {
      if (this.state.gstNumber === "") {
        this.setState({ errorGSTNumber: "GST Number is required" });
      } else {
        this.setState({
          errorGSTNumber: "",
        });
      }
    }

    if (this.state.verifyPhotoName === "") {
      this.setState({ errorVerfication: "Verification File is required" });
    } else {
      this.setState({
        errorVerfication: "",
      });
    }

    if (this.state.startTime === "") {
      this.setState({
        errorStartTime: "Please select the time you take your first order at",
      });
    } else {
      this.setState({
        errorStartTime: "",
      });
    }

    if (this.state.endTime === "") {
      this.setState({
        errorEndTime: "Please select the time you take your last order at",
      });
    } else {
      this.setState({
        errorEndTime: "",
      });
    }

    if (this.state.checkedArray.length === 0) {
      this.setState({
        errorOperationDay: "Please select atleast one day of operation",
      });
    } else {
      this.setState({
        errorOperationDay: "",
      });
    }
    if (this.state.gstSelected === "1" && this.state.gstNumber === "") {
      this.setState({
        gstErrorFlag: true,
        errorGSTNumber: "GST Number is required",
      });
    } else {
      this.setState({ gstErrorFlag: false, errorGSTNumber: "" });
    }

    if (
      this.state.fssaiSelected === "Yes" ||
      (this.state.fssaiSelected === "Applied" &&
        this.state.fssaiPhotoName === "")
    ) {
      this.setState({ fssaiErrorFlag: true });
    } else {
      this.setState({ fssaiErrorFlag: false });
    }
    var gst = $("input[name=gstNumber]").val();
    if (!gst && this.state.gstSelected === "1") {
      this.setState({
        gstErrorFlag: true,
        errorGSTNumber: "GST Number is required",
      });
    }
    if (
      this.state.startTime !== "" &&
      this.state.endTime !== "" &&
      this.state.verifyPhotoName !== "" &&
      this.state.checkedArray.length !== 0 &&
      this.state.errorFssaiUpload === "" &&
      this.state.errorVerficationUpload === "" &&
      this.state.fssaiSelected !== "" &&
      this.state.gstSelected !== "" &&
      this.state.gstErrorFlag === false &&
      this.state.fssaiErrorFlag === false
    ) {
      this.recaptcha.execute();
      this.setState({ loading: true });
      var formdata = new FormData();
      formdata.append("user_id", userId);
      formdata.append("year_business_started", this.state.busineesYear);
      formdata.append("fssai_registration", this.state.fssaiSelected);
      formdata.append(
        "opening_time",
        this.state.startTime + " " + this.state.startTimestate
      );
      formdata.append(
        "closing_time",
        this.state.endTime + " " + this.state.endTimestate
      );
      if (this.state.verifyPhoto !== "") {
        formdata.append(
          "verification_document",
          this.state.verifyPhoto,
          this.state.verifyPhotoName
        );
      }
      if (this.state.fssaiPhoto !== "") {
        formdata.append(
          "fssai_registration_document",
          this.state.fssaiPhoto,
          this.state.fssaiPhotoName
        );
      }
      formdata.append("gst_registration_number", this.state.gstNumber);
      formdata.append("is_gst_applicable", this.state.gstSelected);
      formdata.append("operational_days", this.state.checkedArray.toString());
      formdata.append("is_takeaway_available", this.state.offerSelected);
      formdata.append("pickup_instructions", this.state.takeawayInput);
      formdata.append("minimum_order_amount", this.state.orderAmount);
      formdata.append("party_orders_accepted", this.state.partyOrderSelected);
      formdata.append("maximum_people_to_cater", this.state.maximumPeople);
      formdata.append(
        "chef_host_event_at_their_home",
        this.state.eventSelected
      );
      formdata.append(
        "maximum_number_of_guest_chef_can_host",
        this.state.maximumGuestCount
      );
      formdata.append(
        "average_price_per_person",
        this.state.maximumPeopleCount
      );
      formdata.append(
        "catering_facilities",
        this.state.facilityCheckedArray.toString()
      );
      formdata.append(
        "venue_facilities",
        this.state.venueFacilityCheckedArray.toString()
      );
      formdata.append("average_meal_price", this.state.averageMealPrice);

      axios
        .post(config.APIURL + "v1/store-chef-business", formdata, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((response) => {
          if (response.data["status"] === 200) {
            var userId = localStorage.getItem("userID");
            var accessToken = localStorage.getItem("accessToken");
            return getUserSummaryAPI(userId, accessToken)
              .then((response) => {
                if (response.data["status"] === 200) {
                  this.props.parentCallback(response.data["data"]);
                  this.setState({ loading: false });
                  localStorage.setItem("currentStatus", 3);
                  NotificationManager.success(
                    "User Business Details Saved",
                    "Successful!",
                    3000
                  );
                }
              })
              .catch((error) => {
                this.setState({ loading: false });
              });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          NotificationManager.error(
            "User Business Details failed to save",
            "Error!"
          );
        });
    } else {
      this.setState({ loading: false });
      setTimeout(function () {
        var getfilterRowElement = $(".text-danger");
        $("small.text-danger").each(function () {
          var string = $(this).html();
          if (string != "") {
            $("html, body").animate(
              {
                scrollTop: $(this).parent().offset().top + -100,
              },
              500
            );
            return false;
          }
        });
      }, 100);

      this.recaptcha.reset();
    }
  };
  /* onAboutBusinessSubmit function ends here */

  componentDidMount() {
    this.getCateringFacilities(); // Catering facilities data is fetched
    this.getAboutBusinessDeatils(); // About Business Details data is fetched
    window.scrollTo(0, 0);
  }

  /* getCateringFacilities function starts here */
  getCateringFacilities() {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getCateringFacilitiesAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            facilityArr: response.data["data"]["catering_facilities"],
            venueFacilityArr: response.data["data"]["venue_facilities"],
          });
        }
        setTimeout(() => {
          this.getFlagInCheckboxData();
        }, 1000);
      })
      .catch((error) => {});
  }
  /* getCateringFacilities function ends here */

  /* getFlagInCheckboxData function starts here */
  getFlagInCheckboxData() {
    let faclilty = [];
    let venue = [];
    this.state.facilityArr.forEach((sample) => {
      faclilty.push({
        ...sample,
        isChecked: false,
      });
    });
    this.state.venueFacilityArr.forEach((item) => {
      venue.push({
        ...item,
        isChecked: false,
      });
    });
    this.setState({ facilityArr: faclilty, venueFacilityArr: venue });
    setTimeout(() => {
      if (this.state.responseFacilities) {
        this.state.facilityArr.forEach((facility) => {
          this.state.responseFacilities.forEach((newFacility) => {
            if (newFacility.facility_id === facility.id) {
              facility.isChecked = true;
              this.state.facilityCheckedArray.push(facility.id);
            }
          });
        });
      }
      if (this.state.responseVenueFacilities) {
        this.state.venueFacilityArr.forEach((venue) => {
          this.state.responseVenueFacilities.forEach((newVenue) => {
            if (newVenue.facility_id === venue.id) {
              venue.isChecked = true;
              this.state.venueFacilityCheckedArray.push(venue.id);
            }
          });
        });
      }
    }, 1000);
  }
  /* getFlagInCheckboxData function ends here */

  /* getAboutBusinessDeatils function starts here */
  getAboutBusinessDeatils() {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return detailsAboutBusinessAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data["data"]["year_business_started"] == null) {
            var year = "";
          } else {
            var year = response.data["data"]["year_business_started"];
          }
          if (response.data["data"]["fssai_registration"] == null) {
            var fssai = "";
          } else {
            var fssai = response.data["data"]["fssai_registration"];
          }
          if (response.data["data"]["is_gst_applicable"] == null) {
            var gst = "";
          } else {
            var gst = response.data["data"]["is_gst_applicable"];
          }

          if (response.data["data"]["gst_registration_number"] == "null") {
            var gstNo = "";
          } else {
            var gstNo = response.data["data"]["gst_registration_number"];
          }

          if (response.data["data"]["pickup_instructions"] == null) {
            var specialTake = "";
          } else {
            var specialTake = response.data["data"]["pickup_instructions"];
          }

          var takeAway = response.data["data"]["is_takeaway_available"];
          if (response.data["data"]["minimum_order_amount"] == "0.00") {
            var orderAmount = "";
          } else {
            var orderAmount = response.data["data"]["minimum_order_amount"];
          }

          var partyOrder = response.data["data"]["party_orders_accepted"];
          var maximumPeopleCarter =
            response.data["data"]["maximum_people_to_cater"];
          var cheHost = response.data["data"]["chef_host_event_at_their_home"];
          if (
            response.data["data"]["maximum_number_of_guest_chef_can_host"] ==
            "0"
          ) {
            var guestHost = "";
          } else {
            var guestHost =
              response.data["data"]["maximum_number_of_guest_chef_can_host"];
          }

          var averagePrice = response.data["data"]["average_price_per_person"];
          if (response.data["data"]["opening_time"] == null) {
            var startTime = "";
            var startTimeState = "AM";
          } else {
            var startTime = response.data["data"]["opening_time"].split(" ")[0];
            var startTimeState =
              response.data["data"]["opening_time"].split(" ")[1];
          }

          if (response.data["data"]["opening_time"] == null) {
            var endTime = "";
            var endTimeState = "PM";
          } else {
            var endTime = response.data["data"]["closing_time"].split(" ")[0];
            var endTimeState =
              response.data["data"]["closing_time"].split(" ")[1];
          }

          var sampleFssaiPhoto = /[^/]*$/.exec(
            response.data["data"]["fssai_registration_document"]
          )[0];
          var sampleVerifyPhoto = /[^/]*$/.exec(
            response.data["data"]["verification_document"]
          )[0];
          let sampleFacility = [];
          let sampleVenueFacility = [];
          let sampleOperation = [];
          sampleFacility = response.data["data"]["catering_facilities"];
          sampleVenueFacility = response.data["data"]["venue_facilities"];
          sampleOperation = response.data["data"]["operational_days"];
          var averagePriceCostForTwo = null;
          if (response.data["data"]["average_meal_price"]) {
            averagePriceCostForTwo =
              response.data["data"]["average_meal_price"];
          }

          var counteroperation = 0;
          var counterDays = 0;
          this.state.operationList.forEach((operation) => {
            counterDays++;
            sampleOperation.forEach((newOperation) => {
              if (newOperation.day_name === operation.value) {
                operation.isChecked = true;
                this.state.checkedArray.push(operation.value);
                counteroperation++;
              }
            });
          });
          if (counteroperation === counterDays) {
            this.setState({ selectAllCheckbox: true });
          } else {
            this.setState({ selectAllCheckbox: false });
          }

          const charCount = specialTake.length;
          const charLeft = 200 - charCount;
          this.setState({ chars_left: charLeft });
          if (fssai === null) {
            this.setState({
              fssaiSelected: "",
            });
          } else {
            this.setState({
              fssaiSelected: fssai,
            });
          }
          if (sampleFssaiPhoto === "null") {
            this.setState({
              fssaiPhotoName: "",
            });
          } else {
            this.setState({
              fssaiPhotoName: sampleFssaiPhoto.slice(-20),
            });
          }

          if (sampleVerifyPhoto === "null") {
            this.setState({
              verifyPhotoName: "",
            });
          } else {
            this.setState({
              verifyPhotoName: sampleVerifyPhoto.slice(-20),
            });
          }
          this.setState({
            responseFacilities: sampleFacility,
            responseVenueFacilities: sampleVenueFacility,
            busineesYear: year,
            gstSelected: gst,
            gstNumber: gstNo,
            takeawayInput: specialTake,
            offerSelected: takeAway,
            orderAmount: orderAmount,
            partyOrderSelected: partyOrder,
            maximumPeople: maximumPeopleCarter,
            eventSelected: cheHost,
            maximumGuestCount: guestHost,
            maximumPeopleCount: averagePrice,
            startTime: startTime,
            startTimestate: startTimeState,
            endTime: endTime,
            endTimestate: endTimeState,
            averageMealPrice: averagePriceCostForTwo,
          });
        }
      })
      .catch((error) => {});
  }
  /* getAboutBusinessDeatils function ends here */

  /* onChangeFSSAIPhoto function starts here */
  onChangeFSSAIPhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (
      !file.name.match(
        /\.(jpg|jpeg|png|gif|txt|doc|docx|pdf|JPG|JPEG|PNG|GIF|TXT|DOC|DOCX|PDF)$/
      )
    ) {
      this.setState({ errorFssaiUpload: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorFssaiUpload: "" });
    }
    if (event.target.files[0]) {
      this.setState({ errorFssaiName: "", fssaiErrorFlag: false });
    } else {
      this.setState({ fssaiErrorFlag: true });
    }
    this.setState({
      fssaiPhoto: event.target.files[0],
      fssaiPhotoName: event.target.files[0]["name"].slice(-20),
    });
  };
  /* onChangeFSSAIPhoto function ends here */

  /* onChangeVerifyPhoto function starts here */
  onChangeVerifyPhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (
      !file.name.match(
        /\.(jpg|jpeg|png|gif|doc|docx|pdf|JPG|JPEG|PNG|GIF|TXT|DOC|DOCX|PDF)$/
      )
    ) {
      this.setState({
        errorVerficationUpload: "Please select valid file type.",
      });
      return false;
    } else {
      this.setState({ errorVerficationUpload: "" });
    }
    if (event.target.files[0]) {
      this.setState({ errorVerfication: "" });
    }
    this.setState({
      verifyPhoto: event.target.files[0],
      verifyPhotoName: event.target.files[0]["name"].slice(-20),
    });
  };
  /* onChangeVerifyPhoto function ends here */

  /* handleAllChecked function starts here */
  handleAllChecked = (event) => {
    if (event.target.checked) {
      this.setState({
        checkedArray: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        selectAllCheckbox: true,
        errorOperationDay: "",
      });
    } else {
      this.setState({ checkedArray: [], selectAllCheckbox: false });
    }
    let operations = this.state.operationList;
    operations.forEach(
      (operation) => (operation.isChecked = event.target.checked)
    );
    this.setState({ operationList: operations });
    setTimeout(() => {}, 1000);
  };
  /* handleAllChecked function ends here */

  /* handleCheckChieldElement function starts here */
  handleCheckChieldElement = (event) => {
    let operations = this.state.operationList;
    var allChecked = 0;
    if (event.target.checked) {
      this.setState({ errorOperationDay: "" });
      this.state.checkedArray.push(event.target.value);
      operations.forEach((operation) => {
        if (operation.value === event.target.value)
          operation.isChecked = event.target.checked;
        if (!operation.isChecked) {
          allChecked++;
        }
      });
      if (allChecked === 0) {
        this.setState({ selectAllCheckbox: true });
      } else {
        this.setState({ selectAllCheckbox: false });
      }
    } else {
      let filteredArr = this.state.checkedArray.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({ checkedArray: filteredArr, selectAllCheckbox: false });
    }
    operations.forEach((operation) => {
      if (operation.value === event.target.value)
        operation.isChecked = event.target.checked;
    });

    setTimeout(() => {}, 1000);
    this.setState({ operationList: operations });
  };
  /* handleCheckChieldElement function ends here */

  /* handleCheckFacilityElement function starts here */
  handleCheckFacilityElement = (event) => {
    if (event.target.checked) {
      this.state.facilityCheckedArray.push(event.target.value);
    } else {
      let filteredArr = this.state.facilityCheckedArray.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({ facilityCheckedArray: filteredArr });
    }
    let facilities = this.state.facilityArr;
    facilities.forEach((facility) => {
      if (facility.id === event.target.value)
        facility.isChecked = event.target.checked;
    });
    this.setState({ facilityArr: facilities });
    setTimeout(() => {}, 1000);
  };
  /* handleCheckFacilityElement function ends here */

  /* handleCheckVenueElement function starts here */
  handleCheckVenueElement = (event) => {
    if (event.target.checked) {
      this.state.venueFacilityCheckedArray.push(event.target.value);
    } else {
      let filteredArr = this.state.venueFacilityCheckedArray.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({ venueFacilityCheckedArray: filteredArr });
    }
    let venues = this.state.venueFacilityArr;
    venues.forEach((venue) => {
      if (venue.id === event.target.value)
        venue.isChecked = event.target.checked;
    });
    this.setState({ venueFacilityArr: venues });
    setTimeout(() => {}, 1000);
  };
  /* handleCheckVenueElement function ends here */

  /* handleStartTimeStateChange function starts here */
  handleStartTimeStateChange(event) {
    if (event.target.value === "AM" && this.state.startTime === "12:00") {
      this.setState({
        startTime: "",
      });
    }
    this.setState({ startTimestate: event.target.value });
  }
  /* handleStartTimeStateChange function ends here */

  /* handleStartTimeChange function starts here */
  handleStartTimeChange(event) {
    if (event.target.value) {
      this.setState({ errorStartTime: "" });
    }
    this.setState({ startTime: event.target.value });
  }
  /* handleStartTimeChange function ends here */

  /* handleEndTimeStateChange function starts here */
  handleEndTimeStateChange(event) {
    if (event.target.value === "AM" && this.state.endTime === "12:00") {
      this.setState({
        endTime: "",
      });
    }
    this.setState({ endTimestate: event.target.value });
  }
  /* handleEndTimeStateChange function ends here */

  /* handleEndTimeChange function starts here */
  handleEndTimeChange(event) {
    if (event.target.value) {
      this.setState({ errorEndTime: "" });
    }
    this.setState({ endTime: event.target.value });
  }
  /* handleEndTimeChange function ends here */

  /* handleMaximumPeopleChange function starts here */
  handleMaximumPeopleChange(event) {
    this.setState({ maximumPeople: event.target.value });
  }
  /* handleMaximumPeopleChange function ends here */

  /* handleAveragePersonChange function starts here */
  handleAveragePersonChange(event) {
    this.setState({ maximumPeopleCount: event.target.value });
  }
  /* handleAveragePersonChange function ends here */

  /* handleFSSAIChange function starts here */
  handleFSSAIChange(event) {
    if (event.target.value) {
      this.setState({ errorFssai: "" });
    }
    if (event.target.value === "Yes" || event.target.value === "Applied") {
      if (this.state.fssaiPhotoName === "") {
        this.setState({ fssaiErrorFlag: true });
      } else {
        this.setState({ fssaiErrorFlag: false });
      }
    } else {
      this.setState({
        fssaiErrorFlag: false,
        errorFssaiName: "",
        fssaiPhoto: "",
        fssaiPhotoName: "",
      });
    }
    this.setState({ fssaiSelected: event.target.value });
    setTimeout(() => {}, 1000);
  }
  /* handleFSSAIChange function ends here */

  /* handleGSTChange function starts here */
  handleGSTChange(event) {
    if (event.target.value) {
      this.setState({ errorGST: "" });
    }
    if (event.target.value === "0") {
      this.setState({
        errorGSTNumber: "",
        errorValidGSTNumber: "",
        gstNumber: "",
        gstErrorFlag: false,
      });
    }
    if (event.target.value === "1") {
      this.setState({ gstErrorFlag: true });
    }

    this.setState({ gstSelected: event.target.value });
  }
  /* handleGSTChange function ends here */

  /* handleOfferChange function starts here */
  handleOfferChange(event) {
    this.setState({ offerSelected: event.target.value });
  }
  /* handleOfferChange function ends here */

  /* handlePartyOrderChange function starts here */
  handlePartyOrderChange(event) {
    this.setState({ partyOrderSelected: event.target.value });
  }
  /* handlePartyOrderChange function ends here */

  /* handleEventChange function starts here */
  handleEventChange(event) {
    this.setState({ eventSelected: event.target.value });
  }
  /* handleEventChange function ends here */

  /* handleAboutBusinessChange function starts here */
  handleAboutBusinessChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }
  /* handleAboutBusinessChange function ends here */

  /* handleGSTNumberChange function starts here */
  handleGSTNumberChange(event) {
    if (event.target.value) {
      this.setState({ errorGSTNumber: "" });
    }
    //this.setState({ gstNumber: event.target.value });
    var validateGST = !gstNumberReg.test(event.target.value)
      ? "Enter valid GST Number"
      : "";
    this.setState({
      errorValidGSTNumber: validateGST,
    });
    if (validateGST === "") {
      this.setState({ gstErrorFlag: false });
    } else {
      this.setState({ gstErrorFlag: true });
    }
    this.setState({ gstNumber: event.target.value });
  }
  /* handleGSTNumberChange function ends here */

  /* onPreviousBusinessSubmit function starts here */
  onPreviousBusinessSubmit = () => {
    localStorage.setItem("currentStatus", 1);
  };
  /* onPreviousBusinessSubmit function ends here */

  /* handleAboutBusinessTextareaChange function starts here */
  handleAboutBusinessTextareaChange(key) {
    const charCount = key.target.value.length;
    const charLeft = 200 - charCount;
    this.setState({ chars_left: charLeft });
    this.setState({
      [key.target.name]: key.target.value,
    });
  }
  /* handleAboutBusinessTextareaChange function ends here */

  /* logoutHandler function starts here */
  logoutHandler = (e) => {
    localStorage.clear();
    NotificationManager.success(
      "You have Logout Successfully",
      "Successful!",
      3000
    );
  };
  /* logoutHandler function ends here */

  /* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    const { formErrors } = this.state;
    return (
      <div className="registration bussiness p-0">
        <div className="abt_form bussiness">
          <h4 className="d-flex justify-content-between align-items-center">
            <button
              className="save_btn back_btn"
              onClick={this.onPreviousBusinessSubmit}
            >
              <img
                src="/assets/images/icons/left-arrow.png"
                alt="Left Arrow Icon"
                className="img-fluid"
              />
              Back
            </button>
            <span className="text-uppercase text-center text-md-left">
              About your business
            </span>
            <button className="save_btn disabled">Save</button>
          </h4>
          <p className="sub_title">
            Note : Your information has been saved!{" "}
            <br className="d-block d-lg-none" /> You can now&nbsp;
            <a
              href="/"
              className="logout_link"
              onClick={(e) => this.logoutHandler(e)}
            >
              logout
            </a>
            &nbsp; and log back in to resume completing the form at your
            convenience.
          </p>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon doc_icon">
                <img
                  src="/assets/images/icons/doc-file.png"
                  alt="Doc Icon"
                  className="img-fluid"
                />
              </span>
              Documents
            </h5>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Year when business started"
                      name="busineesYear"
                      value={this.state.busineesYear}
                      onChange={this.handleNumnberChange}
                      maxLength="4"
                    />
                    <small className="form-text text-muted">
                      Enter in YYYY format.
                    </small>
                    <small className="text-danger">
                      {formErrors.busineesYear}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">
                      Do you have FSSAI registration?*
                    </label>
                    <ul className="radio_list FSSAI">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="fssaiYes"
                            onChange={this.handleFSSAIChange}
                            value="Yes"
                            checked={this.state.fssaiSelected === "Yes"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="fssaiNo"
                            onChange={this.handleFSSAIChange}
                            value="No"
                            checked={this.state.fssaiSelected === "No"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          Applied for
                          <input
                            type="radio"
                            name="fssaiApplied"
                            onChange={this.handleFSSAIChange}
                            value="Applied"
                            checked={this.state.fssaiSelected === "Applied"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                    <small className="text-danger">
                      {this.state.errorFssai ? this.state.errorFssai : ""}
                    </small>
                  </div>
                </div>
                {this.state.fssaiSelected !== "No" ? (
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="form-group">
                      <label className="d-block">
                        Upload a copy of your registration or application*
                      </label>
                      <div className="fileUpload d-flex">
                        <button className="fileUploadBtn form-control d-inline-flex"></button>
                        <div className="d-inline-flex align-items-center form-control">
                          {this.state.fssaiPhotoName === "" ? (
                            <span className="file_text">
                              Choose file
                              <small>No file selected</small>
                            </span>
                          ) : (
                            <span className="file_text">
                              {this.state.fssaiPhotoName}
                            </span>
                          )}
                          <input
                            type="file"
                            className="uploadlogo"
                            name="fssai_registration_document"
                            onChange={this.onChangeFSSAIPhoto}
                          />
                        </div>
                      </div>
                      <small className="form-text text-muted">
                        One file only. 50 MB limit.
                        <br className="d-sm-none" />
                        Allowed types: txt pdf doc docx jpg jpeg gif png.
                      </small>
                      <small className="text-danger">
                        {this.state.errorFssaiName
                          ? this.state.errorFssaiName
                          : ""}
                      </small>
                      <small className="text-danger">
                        {this.state.errorFssaiUpload
                          ? this.state.errorFssaiUpload
                          : ""}
                      </small>
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">
                      Do you have a GST registration?*
                    </label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="gstYes"
                            onChange={this.handleGSTChange}
                            value="1"
                            checked={this.state.gstSelected === "1"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="gstNo"
                            onChange={this.handleGSTChange}
                            value="0"
                            checked={this.state.gstSelected === "0"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                    <small className="text-danger">
                      {this.state.errorGST ? this.state.errorGST : ""}
                    </small>
                  </div>
                </div>
                {this.state.gstSelected !== "0" ? (
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter GST registration Number*"
                        name="gstNumber"
                        value={this.state.gstNumber}
                        onChange={this.handleGSTNumberChange}
                      />
                      {this.state.errorGSTNumber ? (
                        <small className="text-danger">
                          {this.state.errorGSTNumber}
                        </small>
                      ) : null}
                      {this.state.errorValidGSTNumber ? (
                        <small className="text-danger">
                          {this.state.errorValidGSTNumber
                            ? this.state.errorValidGSTNumber
                            : ""}
                        </small>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <span></span>
                )}
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <label className="d-block">Verification*</label>
                    <div className="fileUpload d-flex">
                      <button className="fileUploadBtn form-control d-inline-flex"></button>
                      <div className="d-inline-flex align-items-center form-control">
                        {this.state.verifyPhotoName === "" ? (
                          <span className="file_text">
                            Choose file
                            <small>No file selected</small>
                          </span>
                        ) : (
                          <span className="file_text">
                            {this.state.verifyPhotoName}
                          </span>
                        )}
                        <input
                          type="file"
                          className="uploadlogo"
                          name="verification_document"
                          onChange={this.onChangeVerifyPhoto}
                        />
                      </div>
                    </div>
                    <small className="form-text text-muted mb-3">
                      For identification verification purposes please upload a
                      picture of any government issued ID e.g. Aadhar card etc.
                    </small>
                    <small className="form-text text-muted">
                      One file only. 64 MB limit. Allowed types: png gif jpg
                      jpeg pdf doc docx.
                    </small>
                    <small className="text-danger">
                      {this.state.errorVerficationUpload
                        ? this.state.errorVerficationUpload
                        : ""}
                    </small>
                    <small className="text-danger">
                      {this.state.errorVerfication
                        ? this.state.errorVerfication
                        : ""}
                    </small>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon timetable_icon">
                <img
                  src="/assets/images/icons/timetable.png"
                  alt="Timetable Icon"
                  className="img-fluid"
                />
              </span>
              Operating preferences
            </h5>
            <form>
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group checklist_wrap">
                    <label>Days of Operation*</label>
                    <ul className="days_opt">
                      {this.state.operationList.map((operation, i) => {
                        return (
                          <li key={"kjhgf" + i}>
                            <label className="custome_check">
                              <input
                                key={operation.id}
                                onChange={this.handleCheckChieldElement}
                                type="checkbox"
                                checked={operation.isChecked}
                                value={operation.value}
                              />
                              <span className="checkmark"></span>
                              {operation.value}
                            </label>
                          </li>
                        );
                      })}
                      <li>
                        <label className="custome_check">
                          <input
                            type="checkbox"
                            onClick={this.handleAllChecked}
                            value="checkedall"
                            checked={this.state.selectAllCheckbox}
                          />
                          <span className="checkmark"></span>
                          All days of the week
                        </label>
                      </li>
                    </ul>
                    <small className="text-danger" style={{ marginTop: 0 }}>
                      {this.state.errorOperationDay
                        ? this.state.errorOperationDay
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <label>You take your first order starting?*</label>
                    <div className="select_wrap">
                      <div className="small_select">
                        <select
                          className="form-control"
                          value={this.state.startTimestate}
                          onChange={this.handleStartTimeStateChange}
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                      <select
                        className="form-control tel_number"
                        value={this.state.startTime}
                        onChange={this.handleStartTimeChange}
                      >
                        <option value="">Select time</option>
                        {this.state.timeList.map((time, i) => {
                          return (
                            <option
                              value={time.value}
                              key={"wertyuiknbv" + i}
                              disabled={
                                this.state.startTimestate === "AM" &&
                                time.value === "12:00"
                              }
                            >
                              {time.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <small className="text-danger">
                      {this.state.errorStartTime
                        ? this.state.errorStartTime
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <label>You take your last order at?*</label>
                    <div className="select_wrap">
                      <div className="small_select">
                        <select
                          className="form-control"
                          value={this.state.endTimestate}
                          onChange={this.handleEndTimeStateChange}
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                      <select
                        className="form-control tel_number"
                        value={this.state.endTime}
                        onChange={this.handleEndTimeChange}
                      >
                        <option value="">Select time</option>
                        {this.state.timeList.map((time, i) => {
                          return (
                            <option
                              value={time.value}
                              key={"vbnj" + i}
                              disabled={
                                this.state.endTimestate === "AM" &&
                                time.value === "12:00"
                              }
                            >
                              {time.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <small className="text-danger">
                      {this.state.errorEndTime ? this.state.errorEndTime : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">Do you offer takeaway?</label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="takeawayYes"
                            onChange={this.handleOfferChange}
                            value="1"
                            checked={this.state.offerSelected === "1"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="takeawayNo"
                            onChange={this.handleOfferChange}
                            value="0"
                            checked={this.state.offerSelected === "0"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                    <small className="form-text text-muted">
                      Note: You agree to share your address for takeaway and
                      delivery pick up.
                    </small>
                  </div>
                </div>

                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <label>
                      Special Instructions for takeaway or delivery pick up
                    </label>
                    <textarea
                      className="form-control"
                      name="takeawayInput"
                      value={this.state.takeawayInput}
                      onChange={this.handleAboutBusinessTextareaChange}
                      maxLength="200"
                    ></textarea>
                    <small className="form-text text-muted text-right">
                      {this.state.chars_left} characters
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <div className="mobile_no_wrap">
                      <div className="form-control countrycode">
                        <span className="code">INR</span>
                      </div>
                      <input
                        type="tel"
                        className="form-control tel_number"
                        placeholder="Minimum order amount"
                        maxLength="8"
                        name="orderAmount"
                        value={this.state.orderAmount}
                        onChange={this.handleNumnberChange}
                      />
                    </div>
                    <small className="form-text text-muted">
                      Enter minimum order amount in INR.
                    </small>
                    <small className="text-danger">
                      {formErrors.orderAmount}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <div className="mobile_no_wrap">
                      <div className="form-control countrycode">
                        <span className="code">INR</span>
                      </div>
                      <input
                        type="tel"
                        className="form-control tel_number"
                        placeholder="Minimum order amount"
                        maxLength="8"
                        name="averageMealPrice"
                        value={this.state.averageMealPrice}
                        onChange={this.handleNumnberChange}
                      />
                    </div>
                    <small className="form-text text-muted">
                      Enter cost for two/average meal price.
                    </small>
                    <small className="text-danger">
                      {formErrors.averageMealPrice}
                    </small>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon buffet_icon">
                <img
                  src="/assets/images/icons/buffet.png"
                  alt="Timetable Icon"
                  className="img-fluid"
                />
              </span>
              Other Events
            </h5>
            <form>
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">Do you take party orders?</label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="ordersYes"
                            onChange={this.handlePartyOrderChange}
                            value="1"
                            checked={this.state.partyOrderSelected === "1"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="ordersNo"
                            onChange={this.handlePartyOrderChange}
                            value="0"
                            checked={this.state.partyOrderSelected === "0"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                {this.state.partyOrderSelected !== "0" &&
                this.state.partyOrderSelected !== null ? (
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="form-group yellow_arrow_icon">
                      <select
                        className="form-control"
                        value={this.state.maximumPeople}
                        onChange={this.handleMaximumPeopleChange}
                      >
                        <option value="0">Maximum people you can cater?</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                        <option value="100+">100+</option>
                      </select>
                    </div>
                  </div>
                ) : null}
                {this.state.partyOrderSelected !== "0" &&
                this.state.partyOrderSelected !== null ? (
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="form-group checklist_wrap">
                      <label>Which catering facilities do you provide?</label>
                      <ul className="mw-100 provide">
                        {this.state.facilityArr.map((facility, i) => {
                          return (
                            <li key={"vbnbvc" + i}>
                              <label className="custome_check">
                                {facility.isChecked}
                                <input
                                  key={facility.id}
                                  onClick={this.handleCheckFacilityElement}
                                  type="checkbox"
                                  checked={facility.isChecked}
                                  value={facility.id}
                                />
                                <span className="checkmark"></span>
                                {facility.facility_name}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ) : null}
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">
                      Do you host events at your home?
                    </label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="eventYes"
                            onChange={this.handleEventChange}
                            value="1"
                            checked={this.state.eventSelected === "1"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="eventNo"
                            onChange={this.handleEventChange}
                            value="0"
                            checked={this.state.eventSelected === "0"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                {this.state.eventSelected !== "0" &&
                this.state.eventSelected !== null ? (
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="What is the maximum number of guests you can host?"
                        name="maximumGuestCount"
                        value={this.state.maximumGuestCount}
                        onChange={this.handleNumnberChange}
                        maxLength="5"
                      />
                      <small className="form-text text-muted">
                        Enter a value.
                      </small>
                      <small className="text-danger">
                        {formErrors.maximumGuestCount}
                      </small>
                    </div>
                  </div>
                ) : null}
                {this.state.eventSelected !== "0" &&
                this.state.eventSelected !== null ? (
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="form-group yellow_arrow_icon">
                      <label>
                        {" "}
                        What is the average price per person for your events?
                        (INR)
                      </label>
                      <select
                        className="form-control"
                        value={this.state.maximumPeopleCount}
                        onChange={this.handleAveragePersonChange}
                      >
                        <option value="0">Select</option>
                        <option value="500-1000">500-1000</option>
                        <option value="1000-1500">1000-1500</option>
                        <option value="1500-2000">1500-2000</option>
                        <option value="2000-2500">2000-2500</option>
                        <option value="2500+">2500+</option>
                      </select>
                    </div>
                  </div>
                ) : null}
                {this.state.eventSelected !== "0" &&
                this.state.eventSelected !== null ? (
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="form-group checklist_wrap">
                      <label>Facilities available at your venue</label>
                      <ul className="mw-100 venue">
                        {this.state.venueFacilityArr.map((venue, i) => {
                          return (
                            <li key={"hjytredvb" + i}>
                              <label className="custome_check">
                                <input
                                  key={venue.id}
                                  onClick={this.handleCheckVenueElement}
                                  type="checkbox"
                                  checked={venue.isChecked}
                                  value={venue.id}
                                />
                                <span className="checkmark"></span>
                                {venue.facility_name}
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                      <small className="form-text text-muted">
                        Select all that apply.
                      </small>
                    </div>
                  </div>
                ) : null}
              </div>
            </form>
          </div>
          <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100">
            <button
              type="submit"
              className="btn previous_btn"
              onClick={this.onPreviousBusinessSubmit}
            >
              PREVIOUS
            </button>
            <button
              type="submit"
              className="btn"
              disabled={this.state.loading}
              onClick={this.onAboutBusinessSubmit}
            >
              save and next
            </button>
          </div>
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={config.siteKey}
            onResolved={this.onResolved}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AboutBusinessPage);
