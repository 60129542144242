/*
#############################################################################
# Name : dinerForgotPassword.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to verify the emailid for the forgot password flow
#############################################################################
*/
import React from "react";
import ReactFormInputValidation from "react-form-input-validation";
import { getDinerForgotPaswordAPI } from "../../core/services/APIservice";
import { Link } from "react-router-dom";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import DinnerHeader from "../../components/dinnerHeader";
import WhatsAppButton from "../../components/whatsappButton";

class DinerForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: "",
      },
      errors: {},
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({ email: "required|email" });

    /* onformsubmit function starts here */
    this.form.onformsubmit = (fields) => {
      this.recaptcha.execute();
      return getDinerForgotPaswordAPI(fields.email)
        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success(
              "New Password send to your EmailId",
              "Successful!",
              3000
            );
            let fields = this.state.fields;
            fields.email = "";
            this.setState({ fields });
          } else {
            NotificationManager.error(response.data.message, "Error!");
          }
        })
        .catch((err) => {
          this.recaptcha.reset();
        });
    };
    /* onformsubmit function ends here */
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  /* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    return (
      <React.Fragment>
        <div className="css2 mt-0">
          <DinnerHeader />
          <section className="sign_up_chef verification log_otp sign_chefdiner">
            <a href="/diner-signin-with-password" className="back_btn">
              <img
                src="/assets/images/icons/black-arrow.svg"
                alt="Black Arrow"
              />
              Back
            </a>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                  <div className="text pt-0">
                    <h3 className="color_C07">PASSWORD ASSISTANCE</h3>
                    <div className="profile_img">
                      <div className="inner">
                        <img
                          src="assets/images/icons/password.png"
                          alt="Password Image"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="form_wrap">
                      <form onSubmit={this.form.handleSubmit}>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email ID*"
                            name="email"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.email}
                          />
                          <small
                            id="PasswordHelp"
                            className="form-text text-muted"
                          >
                            Password reset instructions will be sent to your
                            registered email address.
                          </small>
                          <small className="form-text red_000">
                            {this.state.errors.email
                              ? this.state.errors.email
                              : ""}
                          </small>
                          <Link
                            to={"/diner-signin-with-password"}
                            className="form-text forgot_password text-right text-muted"
                          >
                            Login with password
                          </Link>
                        </div>
                        <div className="form-group mb-0">
                          <button type="submit" className="btn">
                            SUBMIT
                          </button>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-7 align-items-center d-none d-md-flex">
                  <div className="img_box">
                    <img
                      src="assets/images/forgot-password/forgot-password.png"
                      alt="sign up img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="nofooter">
          <WhatsAppButton />
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DinerForgotPasswordPage;
