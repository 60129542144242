/*
#############################################################################
# Name : dinerSearch.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the search data on the basis of data passed in the inputbox
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import {
  searchDataAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
} from "../../../../core/services/Phase2APIservice";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../../../config/config";
import PromotedBadge from "../../../../components/PromotedBadge";
import Slider from "react-slick";
import _debounce from 'lodash/debounce';

class DinerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      isLoading: true,
      chefArr: [],
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    };
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.setState({
        searchInput: decodeURIComponent(
          this.props.location.search.split("?q=")[1]
        ),
      });
      let data = {
        query: this.props.location.search.split("?q=")[1],
      };
      this.generateSerchResult(data); // Search data is fetched
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleUrlParam = () => {
    this.props.history.push(
      "/diner-search?q=" + encodeURIComponent(this.state.searchInput)
    );
  };

  /* generateSerchResult function starts here */
  generateSerchResult = _debounce(async (data, flag = false) => {
    if (flag === false) {
      this.setState({ chefArr: [] });
    }
    var city = localStorage.getItem('selectedLocationName');
    var pincode = localStorage.getItem('pincode');
    var latitude = localStorage.getItem('latitude');
    var longitude = localStorage.getItem('longitude');
    var userId = '';
    if (localStorage.getItem('userID')) {
      userId = localStorage.getItem('userID');
    }
    try {
      searchDataAPI(
        decodeURIComponent(data.query),
        city,
        pincode,
        latitude,
        longitude,
        userId
      ).then((response) => {
        if (response.status === 200) {
          this.setState({
            chefArr: response.data.data.chef_list,
            isLoading: false
          });
        }
      });
    } catch (error) {}
  }, 400);
  /* generateSerchResult function ends here */

  /* handleInputChange function starts here */
  handleInputChange = (event) => {
    event.preventDefault();
    const query = event.target.value;
    this.setState(
      {
        chefArr: [],
        searchInput: query,
        isLoading: true,
      },
      () => {
        // this.props.history.push("/diner-search?q=" + this.state.searchInput)
        if (query.length >= 2) {
          let data = {
            query: query,
          };
          this.generateSerchResult(data);
        }
      }
    );
  };
  /* handleInputChange function ends here */

  /* getDataClear function starts here */
  getDataClear = () => {
    this.setState({
      chefArr: [],
      searchInput: "",
    });
    document.getElementById("searchText").focus();
  };
  /* getDataClear function ends here */

  /* getBackHome function starts here */
  getBackHome = () => {
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/diner-homepage");
  };
  /* getBackHome function ends here */

  /* searchFormSubmitHandler function starts here */
  searchFormSubmitHandler = (e) => {
    e.preventDefault();
  };
  /* searchFormSubmitHandler function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          let data = {
            query: this.props.location.search.split("?q=")[1],
          };
          this.generateSerchResult(data, true);
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          let data = {
            query: this.props.location.search.split("?q=")[1],
          };
          this.generateSerchResult(data, true);
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    // var urlParam = config.slugify(kitchenName);
    // if (brandName.length > 0) {
    //  urlParam =  config.slugify(brandName);
    // }
    // if(chefAvailable === "1"){
    var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push(
      "/" + selectedCity + "/chef-inner-homepage/" + slugUrl
    );
    // }
  };
  /* getInerPage function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        <section className="search_box">
          <div className="container">
            <form onSubmit={this.searchFormSubmitHandler}>
              <div className="form-group has-searchh">
                <span className="search_icon">
                  <img
                    src="assets/images/icons/search-desktop.svg"
                    alt="Search"
                  />
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="searchText"
                  placeholder="Search for home chefs, dishes..."
                  value={this.state.searchInput}
                  onChange={this.handleInputChange}
                  onBlur={this.handleUrlParam}
                />
                {this.state.searchInput != "" ? (
                  <button
                    type="button"
                    className="clear_btn"
                    onClick={() => this.getDataClear()}
                  >
                    Clear
                  </button>
                ) : null}
                <button className="close_btn">
                  <img
                    src="assets/images/icons/black-close.svg"
                    alt=""
                    onClick={() => this.getBackHome()}
                  />
                </button>
              </div>
            </form>
          </div>
        </section>
        {this.state.searchInput !== "" && !this.state.isLoading ? (
          this.state.chefArr.length ? (
            <section className="selected_you home_bakers see_space chef_inner crowd_tabs">
              <div className="container">
                <div className="row">
                  {this.state.chefArr.map((chef, i) => {
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-3"
                        key={"ytrf" + i}
                      >
                        <div
                          className={
                            chef.is_chef_available === "1"
                              ? "card"
                              : "card unavailable-chef-card"
                          }
                        >
                          <div
                            className="direction"
                            onClick={() => {
                              localStorage.getItem("userID") !== null
                                ? chef.is_bookmarked == 1
                                  ? this.removeBookmark(chef.chef_id)
                                  : this.addBookmark(chef.chef_id)
                                : this.storageHandler();
                            }}
                          >
                            <img
                              src={
                                chef.is_bookmarked == 1
                                  ? "/assets/images/icons/bookmark.svg"
                                  : "/assets/images/icons/bookmark-empty.svg"
                              }
                              alt=""
                            />
                          </div>
                          <div
                            onClick={() =>
                              this.getInerPage(
                                chef.chef_id,
                                chef.slug_url,
                                chef.name,
                                chef.is_chef_available
                              )
                            }
                          >
                            {chef.promoted == 1 ? <PromotedBadge /> : null}
                            <div className="img_wrap">
                              {chef.is_offer_available === "1" ? (
                                <div className="offer">OFFER</div>
                              ) : null}
                              {chef.profile_image != null ? (
                                <img
                                  src={chef.profile_image}
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              ) : (
                                <img
                                  src="assets/images/noimage.png"
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              )}
                            </div>
                            <div className="text_wrap">
                              <h6>
                                {chef.verified == 1 ? (
                                  <span className="correct_icn">
                                    {" "}
                                    {chef.brand_name !== ""
                                      ? chef.brand_name
                                      : chef.name}
                                  </span>
                                ) : (
                                  <span>
                                    {chef.brand_name !== ""
                                      ? chef.brand_name
                                      : chef.name}
                                  </span>
                                )}{" "}
                                {chef.total_ratings !== 0 ? (
                                  <span className="star">
                                    {chef.total_ratings}{" "}
                                    <span className="grey">/ 5</span>
                                  </span>
                                ) : null}
                              </h6>
                              {chef.cuisines == "" ? (
                                "-"
                              ) : (
                                <p className="sub_title">{chef.cuisines}</p>
                              )}
                              {chef.special_dishes == "" ? (
                                "-"
                              ) : (
                                <p className="sub_title">
                                  {chef.special_dishes}
                                </p>
                              )}
                              <div
                                className={
                                  chef.gamification_data == null
                                    ? "badge_slide border-0"
                                    : "badge_slide"
                                }
                              >
                                <Slider
                                  className="inner_slider"
                                  {...this.state.settings}
                                >
                                  {chef.gamification_data != null ? (
                                    chef.gamification_data.map((item, i) => {
                                      return (
                                        <div
                                          className="box img-slider1"
                                          key={"grkw" + i}
                                        >
                                          <img
                                            src={item.image}
                                            alt={item.title}
                                          />
                                          <span className="text">
                                            {item.desc}
                                          </span>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="box">
                                      <span className="text">&nbsp;&nbsp;</span>
                                    </div>
                                  )}
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          ) : (
            <section className="not_found">
              <div className="container text-center">
                <img
                  src="assets/images/icons/not-found.svg"
                  alt="Not Found"
                  className="img-fluid"
                />
                <h6>SORRY! NO RESULTS FOUND.</h6>
                <p>
                  We can’t find anything related to your <br /> search. Try a
                  different search.
                </p>
              </div>
            </section>
          )
        ) : null}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.searchInput === "" ? (
          <div className="less_content">
            {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
          </div>
        ) : this.state.hideContent ? (
          <DinerMenuTab />
        ) : (
          <DinerFooter />
        )}
      </div>
    );
  }
}

export default DinerSearch;
