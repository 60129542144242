// Auth
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const REMOVE_USER_DETAILS = "REMOVE_USER_DETAILS";

// Cart
export const INCREASE_PRODUCT_QTY = "INCREASE_PRODUCT_QTY";
export const DECREASE_PRODUCT_QTY = "DECREASE_PRODUCT_QTY";
export const EMPTY_CART = "EMPTY_CART";

// Time Slot
export const ADD_TIME_SLOT = "ADD_TIME_SLOT";
export const REMOVE_TIME_SLOT = "REMOVE_TIME_SLOT";

// Chef Data
export const SAVE_CHEF_DATA = "SAVE_CHEF_DATA";
export const REMOVE_CHEF_DATA = "REMOVE_CHEF_DATA";

//ChefOrderData
export const SAVE_CHEF_ORDER_DATA = "SAVE_CHEF_ORDER_DATA";
export const REMOVE_CHEF_ORDER_DATA = "REMOVE_CHEF_ORDER_DATA";


// Chef Menu
export const ADD_MOBILE_VIEW = "ADD_MOBILE_VIEW"
export const REMOVE_MOBILE_VIEW = "REMOVE_MOBILE_VIEW"

// Chef Menu
export const ADD_ORDER_NUMBER = "ADD_ORDER_NUMBER"
export const REMOVE_ORDER_NUMBER = "REMOVE_ORDER_NUMBER"

//OneSignal Data
export const ADD_ONESIGNAL_ID = "ADD_ONESIGNAL_ID"
export const REMOVE_ONESIGNAL_ID = "REMOVE_ONESIGNAL_ID"

//Alert Data
export const ADD_DINER_ALERT_COUNT = "ADD_DINER_ALERT_COUNT"
export const ADD_CHEF_ALERT_COUNT = "ADD_CHEF_ALERT_COUNT"