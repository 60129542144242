/*
#############################################################################
# Name : faq.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the faq details data
#############################################################################
*/
import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { removeMobileView } from "../../redux/actions/chefMenuActions";

class FAQPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  render() {
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        <Helmet>
          <title>
            Frequently Asked Questions from home chefs about Chef Pin
          </title>
          <meta
            name="title"
            content="Frequently Asked Questions from home chefs about Chef Pin"
          />
          <meta
            name="description"
            content="Read About Our Frequently Asked Questions from Home Chefs about Chef Pin."
          />
        </Helmet>
        {this.state.hideContent ? null : <Header />}
        <section className="faq_sec diner_faq">
          {/* <a className="back_btn" href="#">
            <img src="assets/images/icons/black-arrow.svg" alt="Black Arrow" />
            Back
          </a> */}
          <div className="container">
            <h2 className="text-center res_back_btn_wrap ">
              <a
                onClick={() => {
                  this.props.removeMobileView();
                  this.props.history.goBack();
                }}
                className="res_back_btn mobile_sidebar_back_btn"
              >
                <img src="assets/images/icons/left-arrow-black.svg" />
              </a>
              {/*  <a
                onClick={() => {
                  this.props.removeMobileView();
                  this.props.history.goBack();
                }}
                className="res_back_btn desktop_sidebar_back_btn"
              >
                <img
                  src="assets/images/icons/left-arrow-black.svg"
                  alt="left-arrow-black"
                />
              </a> */}
              FREQUENTLY ASKED QUESTIONS
            </h2>
            <h6 className="category">Membership Benefits</h6>
            <div id="accordion" className="accordion">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="title"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Why should I get a paid membership on Chef Pin?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      The biggest reason for any home chef to take a paid plan
                      on Chef Pin is to be able to accept orders on the
                      platform. While the free listing allows diners to discover
                      your menu, but it does not allow them to place any orders.
                      Apart from that, our paid home chefs get the benefits of
                      detailed listing and better positions on our website and
                      app. Plus they can be reviewed and rated by the diners.
                      Our VIP home chefs even get marketing support to promote
                      their business through Chef Pin.
                    </p>
                    <p>
                      <Link
                        to={
                          localStorage.getItem("userID")
                            ? "/chef-membership"
                            : "/signin-with-otp"
                        }
                        className="link"
                      >
                        Membership Plans
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How will you promote my home chef kitchen and food? What
                      kind of marketing support can I expect from Chef Pin?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We have various ways to promote your home chef business.
                      Here are the main platforms:
                      <br />
                      Our app and website have been designed keeping in mind the
                      best practices in the current market scenario. Which means
                      that diners will be recommended the home chefs that our
                      smart algorithm deems to be the best options.
                    </p>
                    <p>
                      We also keep in mind the location of the diner and want to
                      promote the home chefs in that locality so that delivery
                      times can be minimized and your food does not have to sit
                      in a packet for too long.
                    </p>
                    <p>
                      Those home chefs that are rated highly by the diners will
                      be shown prominently on the app and this is another way to
                      promote your business on Chef Pin.
                    </p>
                    <p>
                      We will also provide promotion packages in the future
                      which will allow you to get promoted not only on our app,
                      but also on our social media platforms and groups.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Can you ensure any guarantee of number of orders?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Chef Pin enables transactions between home chefs and
                      diners, dealing in prepared food and drinks and beverages.
                      The buyer can choose and place orders on various home
                      chefs on the platform and Chef Pin enables delivery of
                      such orders in select cities and at select localities.
                    </p>
                    <p>
                      Our job is to get diners to discover the best home chefs
                      and home bakers across India. At the same time we do not
                      guarantee any orders through the platform.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      What if I pay and do not get any order/business?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We understand your apprehension and we are here to help
                      home chefs grow their business. But like any business,
                      there is never a 100% chance of success. What we can say
                      is that listing on Chef Pin will provide you a far larger
                      reach and the opportunity to showcase your offerings to
                      diners who are looking specifically for homemade food.
                    </p>
                    <p>
                      You can also read up on membership plans to see if you
                      would like to upgrade to one that offers Chef Pin
                      marketing support.
                    </p>
                    <p>
                      At the end of the day, your membership on Chef Pin will go
                      a long way, because you also get the benefit of being part
                      of the community and access to our team of F&B experts who
                      can guide you on your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      I want to be featured/promoted on every search within Chef
                      Pin. How much extra do I pay?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We will definitely offer this promotion feature to our
                      home chefs who want to take full advantage of our
                      platform. Please send in your query to{" "}
                      <a href="mailto:info@chefp.in" className="link">
                        info@chefp.in
                      </a>{" "}
                      and we will first like to understand your goals and then
                      recommend a tailor-made package for your marketing needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSix">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      What if I change my menu every day, depending on the
                      produce available, is that possible?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSix"
                  className="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      That is no problem on Chef Pin. When you become a listed
                      home chef with us, we will provide you an online admin
                      panel where you can log in as many times you like (or stay
                      logged an all the time). Through this panel you will have
                      full flexibility to update all your details which you want
                      to show on our platform.
                    </p>
                    <p>
                      If you want, you can update your menu, prices and
                      quantities every day.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSeven">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Are there any advantages if I do great business on Chef
                      Pin? Will I be recognized, endorsed, or featured on Chef
                      Pin social media?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSeven"
                  className="collapse"
                  aria-labelledby="headingSeven"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Of course! We want you to succeed through Chef Pin and our
                      success is only through your success. We have built a
                      recognition system in the app which will allow you earn
                      Pins or badges, the more orders you service through Chef
                      Pin. Over and above this, we will have a program to
                      showcase our ‘star’ home chefs through various digital
                      media channels.
                    </p>
                  </div>
                </div>
              </div>

              <h6 id="Membership_Plans" className="category">
                Membership Plans
              </h6>
              <div className="card">
                <div className="card-header" id="headingEight">
                  <h5 className="mb-0">
                    <button
                      className="title"
                      data-toggle="collapse"
                      data-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      What happens once I pay up? What are the next steps?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseEight"
                  className="collapse"
                  aria-labelledby="headingEight"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      In order to take a paid plan from Chef Pin you will first
                      need to complete your registration form and upload your
                      menu. Once we have checked all your details we will
                      prepare a Basic listing for you on Chef Pin. We will
                      request you to choose one of the paid plans and will send
                      you a payment link to complete the transaction.
                    </p>
                    <p>
                      And that’s it! Once you have made the payment your
                      membership will be updated and you will be able to accept
                      orders on Chef Pin. We will showcase your listing on the
                      app so that more and more diners discover and order from
                      you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingNine">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      Can I pay monthly membership charges instead of paying one
                      annual fee?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseNine"
                  className="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      At the moment we are only accepting annual membership
                      fees, because we truly believe that the services we are
                      providing are really value-for-money (compare it to the
                      other promotion spends you might have to do to grow your
                      business).
                    </p>
                    <p>
                      Over time we might come up with other plans that let
                      members play in installments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTen">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      Will you increase your membership fees in times to come if
                      I receive more orders?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTen"
                  className="collapse"
                  aria-labelledby="headingTen"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Our membership plans are not linked to the number of
                      orders a home chef receives. You will pay a flat fee to
                      become a member on Chef Pin and then we will only charge a
                      pre-determined mark-up/convenience fee on the order value.
                    </p>
                    <p>
                      We promise that our charges will always be transparently
                      explained to you.
                    </p>
                  </div>
                </div>
              </div>

              <h6 className="category">Orders, Pricing and Payments</h6>
              <div className="card">
                <div className="card-header" id="heading11">
                  <h5 className="mb-0">
                    <button
                      className="title"
                      data-toggle="collapse"
                      data-target="#collapse11"
                      aria-expanded="false"
                      aria-controls="collapse11"
                    >
                      What is the commission I will have to pay?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse11"
                  className="collapse"
                  aria-labelledby="heading11"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Unlike other platforms, Chef Pin does not charge a
                      commission from the home chefs. Instead, we will add a
                      mark-up/convenience fee on your menu prices and display
                      only the combined amount to the diner. This means that our
                      home chefs will get full value for the items they list on
                      Chef Pin.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading12">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse12"
                      aria-expanded="false"
                      aria-controls="collapse12"
                    >
                      How will I get paid once my product is sold?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse12"
                  className="collapse"
                  aria-labelledby="heading12"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Home chef payments, after deducting any dues, are expected
                      to be settled weekly.
                    </p>
                    <p>
                      Home chefs should understand that all order placed through
                      the platform will include a contracted mark-up/convenience
                      fee payable to Chef Pin. This amount and any other dues
                      will be deducted before settling any payments to the home
                      chefs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading13">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse13"
                      aria-expanded="false"
                      aria-controls="collapse13"
                    >
                      My current half kg Biryani is priced at INR 350. Will the
                      price be the same on Chef Pin?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse13"
                  className="collapse"
                  aria-labelledby="heading13"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      The way Chef Pin works is that we will add a
                      mark-up/convenience fee to your prices and show that to
                      the diner. The actual amount added will depend on the
                      membership plan you have taken as a Home Chef. VIP members
                      have lower mark-up.
                    </p>
                    <p>
                      The reason we do this is because we want the Home Chef to
                      recover the full price of their dish rather than have to
                      worry about platform commissions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading14">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse14"
                      aria-expanded="false"
                      aria-controls="collapse14"
                    >
                      If I want to cancel an order, how much time do I have to
                      do it?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse14"
                  className="collapse"
                  aria-labelledby="heading14"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Home chefs will have an option to accept or reject every
                      order that is placed through Chef Pin. Usually, home chefs
                      will have up to 6 hours before the delivery slot to cancel
                      the order, in case they have not accepted it already. If
                      any order is not accepted up to 6 hours prior to the
                      delivery slot, then the system will automatically cancel
                      the order, and process a refund. The home chef will be
                      notified in case there are orders which need to be
                      accepted in the system.
                    </p>
                    <p>
                      But please note that our system tracks if you are
                      cancelling too many orders or taking too much time to
                      accept the orders. Your ratings will be impacted because
                      of this.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading15">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse15"
                      aria-expanded="false"
                      aria-controls="collapse15"
                    >
                      What are Chef Pin's cancellation policies for the Home
                      Chefs and customers?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse15"
                  className="collapse"
                  aria-labelledby="heading15"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      As a general rule Buyers shall not be entitled to cancel
                      their order once they have received confirmation of the
                      same. If a Buyer cancels his/her order after it has been
                      confirmed, Chef Pin shall have a right to charge a
                      cancellation fee, up to the value of the order, to
                      compensate our Home Chefs.
                    </p>
                    <p>
                      The Buyer must notify Chef Pin within 15 minutes of
                      placing the order if he/she decides to cancel their order.
                      The cancellation request should be raised via our website
                      or app only. No cancellation requests are allowed over
                      phone or email.
                    </p>
                    <p>
                      In the unlikely event of an item on your order being
                      unavailable, the Home Chef may not accept the order. In
                      such an event, the Buyer shall be entitled to a refund in
                      accordance with our refund policy.
                    </p>
                    <p>
                      For more details please read our{" "}
                      <a href="/terms-of-use" className="link">
                        Cancellation Policy
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading16">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse16"
                      aria-expanded="false"
                      aria-controls="collapse16"
                    >
                      Will you share customer details with home chefs?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse16"
                  className="collapse"
                  aria-labelledby="heading16"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      The customer details needed for you to complete orders
                      successfully will be shared with the home chefs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading17">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse17"
                      aria-expanded="false"
                      aria-controls="collapse17"
                    >
                      Will you share home chefs’ contact details with the
                      customers?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse17"
                  className="collapse"
                  aria-labelledby="heading17"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      As per policy we do not share the personal contact details
                      of home chefs on the platform. If a customer has any
                      special requests or customizations these will be entered
                      at the time of placing the order.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading18">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse18"
                      aria-expanded="false"
                      aria-controls="collapse18"
                    >
                      What will the payment mode be and how will settlements be
                      carried out?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse18"
                  className="collapse"
                  aria-labelledby="heading18"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Payments from diners will be accepted only online through
                      the platform. In turn, the settlement with home chefs will
                      be done electronically directly into their bank account
                      registered on the Chef Pin system.
                    </p>
                  </div>
                </div>
              </div>

              <h6 className="category">DELIVERY</h6>
              <div className="card">
                <div className="card-header" id="heading19">
                  <h5 className="mb-0">
                    <button
                      className="title"
                      data-toggle="collapse"
                      data-target="#collapse19"
                      aria-expanded="false"
                      aria-controls="collapse19"
                    >
                      How will my food be picked up?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse19"
                  className="collapse"
                  aria-labelledby="heading19"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Chef Pin would facilitate the use third-party delivery
                      providers for home chefs to deliver the products to
                      customers. These services would be charged to the
                      customers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading20">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse20"
                      aria-expanded="false"
                      aria-controls="collapse20"
                    >
                      Who pays if the delivery is not done properly?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse20"
                  className="collapse"
                  aria-labelledby="heading20"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We will first ascertain what is the nature of the
                      complaint. If the fault lies with the delivery provider
                      (e.g. food spillage, wrong order delivered) then we will
                      seek redressal from the delivery company. However, if the
                      issue is with the food quality, quantity or other matters
                      related to the home chef, then the responsibility lies
                      with the home chef to address the complaint with the
                      diner.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading21">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse21"
                      aria-expanded="false"
                      aria-controls="collapse21"
                    >
                      If the order does not reach the customer in time due to
                      delay by delivery team what action will Chef Pin take?
                      What if the delivery partner does not turn up?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse21"
                  className="collapse"
                  aria-labelledby="heading21"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We have designed Chef Pin in a way that all orders must be
                      placed at least one day in advance. We know that home
                      chefs do not operate like restaurants because your food is
                      freshly prepared. Because of this advance notice our
                      delivery partners can also plan the deliveries in a
                      systematic way and we do not anticipate for delivery
                      delays and failures.
                    </p>
                    <p>
                      But still there can be unforeseen circumstances, and if
                      the fault lies with the delivery provider (e.g. food
                      spillage, wrong order delivered) then we will seek
                      redressal from the delivery company.
                    </p>
                  </div>
                </div>
              </div>

              <h6 className="category">FSSAI</h6>
              <div className="card">
                <div className="card-header" id="heading22">
                  <h5 className="mb-0">
                    <button
                      className="title"
                      data-toggle="collapse"
                      data-target="#collapse22"
                      aria-expanded="false"
                      aria-controls="collapse22"
                    >
                      Can Chef Pin assist me on the FSSAI license?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse22"
                  className="collapse"
                  aria-labelledby="heading22"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      While we can provide general knowledge about the FSSAI
                      process, we do not undertake these services for home
                      chefs. If there are specific queries around FSSAI we can
                      reach out to our network and provide some guidance. The
                      FSSAI Official site has all the details{" "}
                      <a
                        href="https://fssai.gov.in"
                        target="_blank"
                        className="link"
                      >
                        https://fssai.gov.in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading23">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse23"
                      aria-expanded="false"
                      aria-controls="collapse23"
                    >
                      Is it compulsory to have an FSSAI license? If yes, will
                      Chef Pin help me get it?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse23"
                  className="collapse"
                  aria-labelledby="heading23"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      FSSAI is compulsory for all home chefs, but there is
                      difference between being registered with FSSAI and getting
                      an FSSAI license. This is based on the turnover of your
                      business. While we can provide general knowledge about the
                      FSSAI process, we do not undertake these services for home
                      chefs. If there are specific queries around FSSAI we can
                      reach out to our network and provide some guidance. The
                      FSSAI Official site has all the details{" "}
                      <a
                        href="https://fssai.gov.in"
                        target="_blank"
                        className="link"
                      >
                        https://fssai.gov.in
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <h6 className="category">Discounts</h6>
              <div className="card">
                <div className="card-header" id="heading24">
                  <h5 className="mb-0">
                    <button
                      className="title"
                      data-toggle="collapse"
                      data-target="#collapse24"
                      aria-expanded="false"
                      aria-controls="collapse24"
                    >
                      Can I offer discounts or will I be expected to give any
                      discounts?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse24"
                  className="collapse"
                  aria-labelledby="heading24"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Discounts are great ways to get more people to try your
                      offerings. We believe that discounts should be for limited
                      periods only, and never something that customers should
                      take for granted. Our platform will enable you to provide
                      certain discounts from time to time but that decision is
                      left with the home chef.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading25">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse25"
                      aria-expanded="false"
                      aria-controls="collapse25"
                    >
                      Can I offer a different price on my FB page and a little
                      higher price on Chef Pin as I want to run 10% discount
                      offer through you?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse25"
                  className="collapse"
                  aria-labelledby="heading25"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We would urge you to set your prices competitively on Chef
                      Pin because thousands of diners are discovering home chefs
                      on our platform everyday, and you would not like to miss
                      out because your pricing seems too high.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WhatsAppButton />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(FAQPage);
