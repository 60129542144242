/*
#############################################################################
# Name : dinerHomepage.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the diner landing page 
#############################################################################
*/
import React from "react";
import { Link, Redirect } from "react-router-dom";
import DinnerHeader from "../../components/dinnerHeader";
import WhatsAppButton from "../../components/whatsappButton";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import config from "../../config/config";
import DinerFooter from "../../components/dinerFooter";
import DinerMenuTab from "../../components/dinerMenuTab";
import Banner from "./Banner/Banner";
import InstagramFeed from "./InstagramFeed/InstagramFeed";

import {
  dishDataAPI,
  chefDataAPI,
  articleTagAPI,
} from "../../core/services/Phase2APIservice";
import {
  HomeDescription,
  GetByCatImageSlider,
} from "../../backendUI/Home/config";
import { PostSubsctribe } from "../../backendUI/Subscribers/config";
import { GetPages } from "../../backendUI/Page/config";
import { URLs } from "../../backendUI/Url";
import PartyOrderForm from "./PartyOrderForm";
import { BlogData } from "../../backendUI/Blog/config";
class DinerHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      displayPopup: true,
      selectedCity: "",

      dishTitle: "",
      chefTitle: "",
      dishDataArr: [],
      chefDataArr: [],
      articleTagDataArr: [],
      blogcarodata: [],
      settings: {
        dots: true,
        speed: 1500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        loop: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      dishSettings: {
        dots: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        infinite: true,
        loop: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 374,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      reviewSettings: {
        dots: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        infinite: true,
        loop: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 374,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      blogsSettings: {
        dots: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        infinite: true,
        loop: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 374,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      blogscaroSettings: {
        dots: false,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        infinite: true,
        loop: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 374,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },

      HeadTitle: "",
      PagesData: [],
      ImageSliderData: [],
      ImageSliderData2: [],

      subscribeName: "",
      subscribeEmail: "",
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("closePopup")) {
      this.setState({
        displayPopup: true,
      });
    } else {
      this.setState({
        displayPopup: false,
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: "/" + selectedCity + "/diner-homepage",
      });
    }

    this.getDishData();
    this.getChefData();
    this.getArticleData();
    this.getHeadTitle();
    this.getPagesData();
    this.getImageSlider();
    this.getImageSlider2();
    this.getblogcarData();
  }

  getHeadTitle = () => {
    HomeDescription().then((res) => {
      if (res.success == true) {
        this.setState({
          HeadTitle: res.data[0].description,
        });
      }
    });
  };
  postSubscribe = () => {
    PostSubsctribe(this.state.subscribeName, this.state.subscribeEmail).then(
      (res) => {
        if (res.success == true) {
          this.setState({
            subscribeName: "",
            subscribeEmail: "",
          });
          alert("Submitted Successfully..!!");
        }
      }
    );
  };
  getImageSlider = () => {
    GetByCatImageSlider("FANTASTIC TALENT OF HOME CHEFS").then((res) => {
      if (res.success == true) {
        // console.log("this.state.ImageSliderData", res.data);

        this.setState({
          ImageSliderData: res.data,
        });
      }
    });
  };
  getImageSlider2 = () => {
    GetByCatImageSlider("MEET THE TALENTED HOME CHEFS").then((res) => {
      if (res.success == true) {
        this.setState({
          ImageSliderData2: res.data,
        });
      }
    });
  };
  getPagesData = () => {
    GetPages().then((res) => {
      if (res.success == true) {
        this.setState({
          PagesData: res.data,
        });
      }
    });
  };
  getDishData = () => {
    return dishDataAPI()
      .then((response) => {
        if (response.data.status === 200) {
          /* this.setState({ cityArr: response.data.data }); */
          /*    console.log(response.data.data); */
          this.setState({
            dishTitle: response.data.data.title,
            dishDataArr: response.data.data.slider_items,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };

  getChefData = () => {
    return chefDataAPI()
      .then((response) => {
        if (response.data.status === 200) {
          /* this.setState({ cityArr: response.data.data }); */
          /* console.log(response); */
          this.setState({
            chefTitle: response.data.data.title,
            chefDataArr: response.data.data.slider_items,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };

  getArticleData = () => {
    return articleTagAPI()
      .then((response) => {
        if (response.data.status === 200) {
          /* this.setState({ cityArr: response.data.data }); */
          /*  console.log(response.data.data); */
          this.setState({
            articleTagDataArr: response.data.data,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };

  // getblogcarData = () => {
  //   console.log("hgfhgfhfh");
  // };
  getblogcarData = async () => {
    try {
      const response = await BlogData();
      if (response.success == true) {
        console.log("hgfhgfhfh", response);

        /* this.setState({ cityArr: response.data.data }); */
        console.log("blogdata response", response.data);
        this.setState({
          blogcarodata: response.data,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  getArticleTagData = (slugUrl) => {
    console.log(slugUrl);
    this.props.history.push("/" + slugUrl);
  };

  /* checkCookie function starts here */
  checkCookie(event) {
    event.preventDefault();
    localStorage.setItem("closePopup", true);
    this.setState({
      displayPopup: false,
    });
    // window.location.reload();
  }
  /* checkCookie function ends here */

  /* storageHandler function starts here */
  storageHandler = (e) => {
    /* localStorage.clear(); */
    console.log("hi");
  };
  /* storageHandler function ends here */

  render() {
    if (localStorage.getItem("addressSelected")) {
      //return <Redirect to="/diner-homepage" />
      return <Redirect to={this.state.selectedCity} />;
    }
    if (
      !localStorage.getItem("addressSelected") &&
      this.props.userData.userType === "Chef"
    ) {
      return <Redirect to="/user-details" />;
    }
    console.log(this.state.ImageSliderData2);

    return (
      <div className="css2">
        {/* <Helmet>
          <title>Order Fresh and Healthy Home Cooked food from Chefpin</title>
          <meta
            name="title"
            content="Order Fresh and Healthy Home Cooked food from Chefpin."
          />
          <meta
            name="description"
            content="Enjoy the delicacy of home cooked food from India's finest home chefs. Order your hygienic home cooked food. Join yourself with India's leading home chef portal."
          />
          <meta
            name="keywords"
            content="home made food services, home made foods online, home food service, home chefs near me, order from home chef, home chefs near me, order from home cook, home cooked meal, homemade food"
          />
        </Helmet> */}
        {/* <DinnerHeader />  */}

        <header>
          <div className="container menu_wrapper">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
              </a>
            </div>
            <div id="nav-icon" className=" d-block d-xl-none">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="dinner_menu">
              <li className="active">
                {" "}
                <a href="/" className="login_btn">
                  Home
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/diner-aboutus" className="login_btn">
                  About Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a
                  href="https://wa.me/919899622242"
                  target="_blank"
                  className="login_btn"
                >
                  Contact Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/pick-city" className="login_btn">
                  Pick A City
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/blog" className="login_btn">
                  Blog
                </a>{" "}
              </li>
              <li className="d-block d-xl-none last_btn">
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <ul className="dinner_menu d-none d-xl-block">
              <li>
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <div className="menu_overlay"></div>
          </div>
        </header>
        <section className="home_slider diner_home">
          <div className="container">
            <h3 className="color_036 top_title font-weight-normal">
              Welcome to
            </h3>
            <div className="logo_wrap">
              <img
                src="/assets/images/home/logo.png"
                alt="Chef Pin"
                className="img-fluid"
              />
            </div>
            {/* <p>
                            Are you a talented home chef or home baker whose passion is to cook delicious dishes?
                        </p> */}
            <h1 className="color_036 build_text new">
              {this.state.HeadTitle != "" &&
                this.state.HeadTitle.replace(/(<([^>]+)>)/gi, "")}
              {/* We are India’s leading platform for home chefs and home bakers. If
              you are looking for fresh and hygienic food that tastes just like
              home, then Chef Pin is your go-to solution. The home chefs
              associated with us are professional and they are passionate about
              cooking. Every dish served by Chef Pin is made with the same care
              as you would take in your own home kitchen. Just order a day in
              advance and have your delicious homecooked meal delivered to your
              doorstep. We make ordering from home chefs super easy! */}
            </h1>
            <div id="home_slider" className="slider_big_img">
              <div className="slider_wrapper">
                <div id="slider" className="slider">
                  <div className="slides img_text_wrap active">
                    <div className="img_wrap">
                      <img
                        src="/assets/images/home/slider-1.png"
                        alt="home chef"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="slides img_text_wrap">
                    <div className="img_wrap">
                      <img
                        src="/assets/images/home/slider-2.png"
                        alt="Indian home chef"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="slides img_text_wrap">
                    <div className="img_wrap">
                      <img
                        src="/assets/images/home/slider-3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="slider_nav">
                  <div className="prev btn_icon">
                    <img src="/assets/images/icons/white-left-arrow.png" />
                  </div>
                  <div className="next btn_icon">
                    <img src="/assets/images/icons/white-right-arrow.png" />
                  </div>
                </div>
              </div>
              {/*  <p className="color_036">
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.
                            </p> */}
              <div className="bottom_box d-inline-flex flex-column">
                <Link to={"/pick-city"} className="btn">
                  Pick a city
                </Link>
                <span className="text-center">OR</span>
                <Link to={"/diner-signin-with-otp"} className="btn">
                  Log in
                </Link>
              </div>

              <p className="orange_link">
                New to Chef Pin?{" "}
                <Link to="/user-selection" className="color_C07">
                  Create an Account
                </Link>
              </p>

              <ul className="slider_thumb">
                <li className="active slide-change-btn thumb-img" id="0">
                  <img
                    src="/assets/images/home/slider-1.png"
                    alt="home chef"
                    className="img-fluid"
                  />
                </li>
                <li className="slide-change-btn thumb-img" id="1">
                  <img
                    src="/assets/images/home/slider-2.png"
                    alt="Indian home chef"
                    className="img-fluid"
                  />
                </li>
                <li className="slide-change-btn thumb-img" id="2">
                  <img
                    src="/assets/images/home/slider-3.png"
                    alt="homechefs india"
                    className="img-fluid"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="exploresect mb-4">
          <div className="container">
            <div className="row pt-4 justify-content-center">
              <div className="col-md-5 lg-5 sm-10">
                <h3 className="text-uppercase color_C07">
                  Explore Food Delivery from Home Chefs
                </h3>
                <div className="p-3">
                  <span>
                    <img
                      src="/assets/images/exploreicon1.png"
                      alt=""
                      className="img-fluid p-1"
                    />
                  </span>
                  <span>Try 1000+ Regional Dishes</span>
                </div>
                <div className="p-3">
                  <span>
                    <img
                      src="/assets/images/exploreicon2.png"
                      alt=""
                      className="img-fluid p-1"
                    />
                  </span>
                  <span>Taste Authentic Flavours of India</span>
                </div>

                <div className="p-3">
                  <span>
                    <img
                      src="/assets/images/exploreicon3.png"
                      alt=""
                      className="img-fluid p-1"
                    />
                  </span>
                  <span>Freshly Prepared Always</span>
                </div>

                <div className="p-3">
                  <span>
                    <img
                      src="/assets/images/exploreicon4.png"
                      alt=""
                      className="img-fluid p-1"
                    />
                  </span>
                  <span>Same-day Delivery or Choose Your Slot</span>
                </div>

                <div className="p-3">
                  <span>
                    <img
                      src="/assets/images/exploreicon5.png"
                      alt=""
                      className="img-fluid p-1"
                    />
                  </span>
                  <span>Read Verified Reviews and Ratings</span>
                </div>
              </div>
              <div className="col-md-5 lg-5 sm-10">
                <img
                  src="/assets/images/home/mobile.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div className="modal-content partyorderform p-4 w-50 mx-auto mt-4 d-flex align-items-center">
              <PartyOrderForm />
            </div>

            {/* <div className="modal-content partyorderform p-4 w-50 mx-auto mt-4 d-flex align-items-center">
              <button
                type="button"
                class="close float-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <h2 className="text-uppercase color_C07 mb-4">Party Order</h2>
              <form
              // onSubmit={handleSubmit}
              >
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Diner Name*</label>
                  <input
                    type="text"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Diner Mobile*</label>
                  <input
                    type="text"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Diner Email</label>
                  <input
                    type="text"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Location*</label>
                  <input
                    type="text"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Party Date*</label>
                  <input
                    type="date"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Party Time*</label>
                  <input
                    type="time"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Number of Guests*</label>
                  <input
                    type="text"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>

                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Preferred Cuisine</label>
                  <select className="w-50">
                    <option type="checkbox">North Indian</option>
                    <option type="checkbox">South Indian</option>
                    <option type="checkbox">Pure Veg</option>
                    <option type="checkbox">Pan-Asian</option>
                    <option type="checkbox">Continental</option>
                    <option type="checkbox">Snacks</option>
                    <option type="checkbox">Desserts</option>
                    <option type="checkbox">Other</option>
                  </select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Do you require</label>
                  <select className="w-50">
                    <option type="checkbox">Serving Staff</option>
                    <option type="checkbox">Crockery/Cutlery</option>
                    <option type="checkbox">Chafing Dishes</option>
                  </select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Are you looking for live cooking?</label>
                  <select className="w-50">
                    <option type="checkbox">Yes</option>
                    <option type="checkbox">No</option>
                  </select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Do you have place for tandoor?</label>
                  <select className="w-50">
                    <option type="checkbox">Yes</option>
                    <option type="checkbox">No</option>
                  </select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Will you permit your kitchen to be used?</label>
                  <select className="w-50">
                    <option type="checkbox">Yes</option>
                    <option type="checkbox">No</option>
                  </select>
                </div>
                <div className="form-group d-flex justify-content-between align-items-center">
                  <label>Any Special Requests</label>
                  <input
                    type="text"
                    // value={textInput}
                    // onChange={handleTextChange}
                  />
                </div>
                <button type="submit " className="btn btn-primary">
                  Send Query
                </button>
              </form>
            </div> */}
          </div>
        </div>
        <section className="benifits_slider">
          <div className="container">
            <h2 className="text-uppercase color_C07 text-center">
              BENEFITS OF ORDERING A MEAL FROM CHEF PIN
            </h2>
            <Slider {...this.state.settings}>
              <div className="box">
                <div className="icon">
                  <img
                    src="assets/images/icons/home-red.svg"
                    alt="trust icon"
                  />
                </div>
                <h4>AUTHENTIC TASTE OF HOME</h4>
                <p>
                  Looking for that authentic taste that you only get with
                  homecooked food? Through Chef Pin we can get you just what
                  your heart desires! If you miss your own regional cuisine,
                  then we are sure you can find a home chef on Chef Pin who will
                  use the right ingredients and make your dish to the original
                  recipe. Through Chef Pin you can discover an amazing variety
                  of world cuisines from the comfort of your home.
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img
                    src="assets/images/icons/cursor-red.svg"
                    alt="cursor icon"
                  />
                </div>
                <h4>CONVENIENCE AT THE CLICK OF A BUTTON</h4>
                <p>
                  On Chef Pin you can easily search hundreds of home chefs and
                  thousands of dishes to find exactly what you are craving. You
                  can look up detailed menus and check out home chef reviews and
                  ratings. We have taken out all the hassle of ordering from
                  home chefs and home bakers. What’s best is that you can order
                  up to three days in advance, choose your delivery slot as per
                  your convenience, and get your meal delivered to your
                  doorstep.
                </p>
              </div>
              <div className="box">
                <div className="icon">
                  <img
                    src="assets/images/icons/trust-icon.svg"
                    alt="home icon"
                  />
                </div>
                <h4>A PLATFORM YOU CAN TRUST</h4>
                <p>
                  Chef Pin founders are veterans of the F&amp;B industry and
                  they have put all their experience and know-how to create a
                  platform that can be trusted to provide the best service to
                  diners as well as home chefs. So, whether you are ordering for
                  a family dinner, for a house party or for a corporate team
                  event, we will ensure that we deliver the best quality
                  homecooked food, on time, so that you can focus on what is
                  important to you!
                  <br /> Chef Pin operations are planned for Delhi NCR, Mumbai,
                  Bangalore, Chennai and Kolkata.
                </p>
              </div>
            </Slider>
          </div>
        </section>

        <section className="howitworks">
          <div className="container">
            <h2 className="text-uppercase color_C07 text-center">
              How it works
            </h2>
            <div className="row pt-4 justify-content-center align-items-center">
              <div className="col-md-3 lg-3 sm-10 p-4 d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/howicon1.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-md-3 lg-3 sm-10 p-4 d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/howicon2.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-md-3 lg-3 sm-10 p-4 d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/howicon3.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="row pt-4 mt-4 justify-content-center align-items-center">
              <div className="col-md-3 lg-3 sm-10 p-4 d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/howicon4.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-md-3 lg-3 sm-10 p-4 d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/howicon5.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="container mt-5">
          <h2 class="text-uppercase color_C07 text-center mb-4">
            Our Services
          </h2>
          <div class="row justify-content-evenly mt-4">
            <div class="col-lg-6  d-flex ">
              <div>
                <img
                  src="/assets/images/home/servicesicon.png"
                  alt="Indian home chef"
                  class="mr-3"
                />
              </div>
              <div>
                <h5>Party Orders</h5>
                <p>
                  Now you can get authentic dishes to wow your guests at your
                  next party. Submit a Party Order inquiry to Chef Pin and let
                  us curate the best home chefs who will contact you to help you
                  plan your party menu. How exciting is that!
                </p>
                <a
                  // className="enq-btn"
                  // data-toggle="modal"
                  // data-target="#exampleModal"
                  href="/party-order"
                  target="_blank"
                >
                  <button className="enq-btn" style={{ cursor: "pointer" }}>
                    Enquire
                  </button>
                </a>
                {/* <a
                  style={{ cursor: "pointer", marginTop: 20, color: "#fff" }}
                ></a> */}
              </div>
            </div>
            <div class="col-lg-6  d-flex ">
              <div>
                <img
                  src="/assets/images/home/servicesicon2.png"
                  alt="Indian home chef"
                  class="mr-3"
                />
              </div>
              <div>
                <h5>Sell Food from Home</h5>
                <p>
                  Chef Pin is India's best online platform that allows home chef
                  and home bakers to get discovered by thousands of diners. With
                  Chef Pin, you can turn your kitchen into a profitable business
                  and share your passion for cooking with others.
                </p>
                <a href="https://www.chefp.in/chef-homepage" target="_blank">
                  <button className="enq-btn" style={{ cursor: "pointer" }}>
                    Join us as a home chef
                  </button>
                </a>
              </div>
            </div>
            <div class="col-lg-6  d-flex ">
              <div>
                <img
                  src="/assets/images/home/servicesicon3.png"
                  alt="Indian home chef"
                  class="mr-3"
                />
              </div>
              <div>
                <h5>Online Masterclass</h5>
                <p>
                  The Masterclasses organized by Chef Pin are live online
                  cooking sessions that teach you authentic regional and
                  international recipes to cook in your own kitchen. You will
                  learn from renowned chefs and pick up new skills and
                  techniques to elevate your cooking.
                </p>
                <a href="https://learn.chefp.in/" target="_blank">
                  <button className="enq-btn" style={{ cursor: "pointer" }}>
                    learn.chefp.in
                  </button>
                </a>
              </div>
            </div>
            <div class="col-lg-6  d-flex ">
              <div>
                <img
                  src="/assets/images/home/servicesicon4.png"
                  alt="Indian home chef"
                  class="mr-3"
                />
              </div>
              <div>
                <h5>Chef Pin Pop-ups</h5>
                <p>
                  Look out for the next Chef Pin pop-up in your city! Chef Pin
                  is helping home chefs get discovered by giving them an
                  opportunity to showcase their food at events and 5-star
                  hotels. Sign up here to stay updated on these events.
                </p>
                <a href="https://www.chefp.in/chef-homepage" target="_blank">
                  <button className="enq-btn" style={{ cursor: "pointer" }}>
                    Sign Up
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="faq_sec diner_faq">
          <div class="container">
            <h1 class="text-center res_back_btn_wrap">
              FREQUENTLY ASKED QUESTIONS
            </h1>
            <div id="accordion" class="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      class="title"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Do I have to order in advance?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      That is correct. Since Chef Pin is a platform for home
                      chefs, we should all respect that their dishes are not
                      kept ready in bulk, like in the case of restaurants.
                      Advance ordering allows the home chefs to cook fresh food
                      for you.
                    </p>
                    <p>
                      Our system makes sure that all orders are placed at least
                      1 day prior to delivery. At the time of placing your order
                      you will be able to select your delivery slot for the
                      coming days.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Is there any minimum value to place an order on Chef Pin?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      The minimum value will be displayed for each home chef on
                      their respective pages. You may please refer to that when
                      deciding to order from that home chef.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Are there any delivery charges?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Yes, the delivery charges will be transparently shown to
                      you at the time of placing your order. The delivery amount
                      will be added to your order before payment. Chef Pin uses
                      only the most reputed delivery partners in each city, and
                      the delivery charges will depend on your city and the
                      distance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Can I change the delivery address after the order has been
                      placed?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  class="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Once the order has been placed we will not be able to
                      accept a request to change the delivery address
                      completely. You might be able to make minor changes (e.g.
                      flat/floor number) by speaking to the delivery executive.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFive">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      The package delivered to me has been damaged and/or
                      tampered with. What can I do about this?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFive"
                  class="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We make sure we use only the most reputed delivery
                      companies, but it is possible that mishaps can happen from
                      time to time. In case the package delivered to you has
                      been damaged and/or tampered with you will be entitled to
                      a partial/full refund. Please see our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Refund Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingSix">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      I want to speak to the home chef before placing the order.
                      How can I get their phone number?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSix"
                  class="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      As per policy we do not share the personal contact details
                      of home chefs on the platform. If you have any special
                      requests or customizations you can enter those at the time
                      of placing your order. Otherwise you can write in to{" "}
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>{" "}
                      and we can coordinate with the home chef for you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingSeven">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      I have some special requests. Is it possible to let the
                      home chef know about these?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSeven"
                  class="collapse"
                  aria-labelledby="headingSeven"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Absolutely. One of the main advantages of ordering from
                      home chefs is that you can customize your food. Before you
                      place your order, you will have the option of entering
                      Special Requests which will be communicated to the home
                      chef.
                    </p>
                    <p>
                      But please note that in case your special
                      request/customization cannot be accommodated by the home
                      chef they may have to reject your order.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingEight">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      How do I make sure that the food matches my dietary
                      requirements?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseEight"
                  class="collapse"
                  aria-labelledby="headingEight"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      At Chef Pin we take dietary requirements very seriously.
                      That is why you will see that under each dish we have
                      asked the home chef to mention the major allergens such as
                      eggs, gluten, lactose etc. We have also asked the home
                      chefs to identify if their kitchens are suitable for
                      certain customs such as Jain-vegetarian cooking. We hope
                      that these indicators and filters will help you find your
                      perfect home chef on Chef Pin!
                    </p>
                    <p>
                      In addition to this, you can also leave a Special Request
                      for the home chef at the time of placing your order so
                      that they are aware of any dietary requirements that you
                      have.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div class="card">
                <div class="card-header" id="headingNine">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      We prefer to order Halal non-veg dishes. Will that be
                      possible?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseNine"
                  class="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Yes, you can see which home chefs have marked their meat
                      to be Halal and order from them.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTen">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      How can I change my order?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTen"
                  class="collapse"
                  aria-labelledby="headingTen"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Once an order has been placed, we will consider it
                      confirmed. In case you need to change your order, you
                      would have to cancel the order first and then place a
                      fresh order with the changes you require. Please make sure
                      you read our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading11">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse11"
                      aria-expanded="false"
                      aria-controls="collapse11"
                    >
                      How can I cancel my order?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse11"
                  class="collapse"
                  aria-labelledby="heading11"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We provide a 15-minute window in which a diner can cancel
                      their order with no questions asked and get a full refund.
                      If you would like to cancel your order after this free
                      cancellation window, then please read up our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading12">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse12"
                      aria-expanded="false"
                      aria-controls="collapse12"
                    >
                      I am not happy with the quality and/or quantity of the
                      food delivered to me. What can I do about this?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse12"
                  class="collapse"
                  aria-labelledby="heading12"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      In the unlikely situation that you are not satisfied with
                      your food delivery we request you to first raise the issue
                      with us at{" "}
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>
                      . We will review your complaint and we shall notify the
                      same to the home chef. For effective complaint resolution
                      we could connect you directly with the home chef, who
                      shall be responsible for redressing your complaint.
                    </p>
                    <p>
                      You may also provide a honest but considerate review on
                      our platform to share your experience.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading13">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse13"
                      aria-expanded="false"
                      aria-controls="collapse13"
                    >
                      How do I provide feedback on my order?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse13"
                  class="collapse"
                  aria-labelledby="heading13"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We have an option to Review and Rate your orders. For this
                      you will need to click on your Past Orders and then select
                      the specific order on which you want to provide the
                      feedback. Please note that we only allow reviews from
                      diners who have actually ordered from the home chef – this
                      is to make sure that each review is genuine.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading14">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse14"
                      aria-expanded="false"
                      aria-controls="collapse14"
                    >
                      I want to place a party order with a home chef. How can I
                      do this?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse14"
                  class="collapse"
                  aria-labelledby="heading14"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Please enter a party-order inquiry in the system and we
                      will get the home chef to reply to you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading15">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse15"
                      aria-expanded="false"
                      aria-controls="collapse15"
                    >
                      What is the Chef Pin customer support number?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse15"
                  class="collapse"
                  aria-labelledby="heading15"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      You can reach us at +91 98996 22242 or email us at
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>
                      . We also have a WhatsApp chat option on our site which
                      you can use.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading16">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse16"
                      aria-expanded="false"
                      aria-controls="collapse16"
                    >
                      I am a home chef so how do I register on Chef Pin?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse16"
                  class="collapse"
                  aria-labelledby="heading16"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      You will find a REGISTER AS A HOME CHEF button on our
                      site. Please click on that to register as a home chef on
                      Chef Pin.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <Banner />

        {/* <section class="faq_sec diner_faq">
          <div class="container">
            <h1 class="text-center res_back_btn_wrap">
              FREQUENTLY ASKED QUESTIONS
            </h1>
            <div id="accordion" class="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      class="title"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Do I have to order in advance?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      That is correct. Since Chef Pin is a platform for home
                      chefs, we should all respect that their dishes are not
                      kept ready in bulk, like in the case of restaurants.
                      Advance ordering allows the home chefs to cook fresh food
                      for you.
                    </p>
                    <p>
                      Our system makes sure that all orders are placed at least
                      1 day prior to delivery. At the time of placing your order
                      you will be able to select your delivery slot for the
                      coming days.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Is there any minimum value to place an order on Chef Pin?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      The minimum value will be displayed for each home chef on
                      their respective pages. You may please refer to that when
                      deciding to order from that home chef.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Are there any delivery charges?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Yes, the delivery charges will be transparently shown to
                      you at the time of placing your order. The delivery amount
                      will be added to your order before payment. Chef Pin uses
                      only the most reputed delivery partners in each city, and
                      the delivery charges will depend on your city and the
                      distance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Can I change the delivery address after the order has been
                      placed?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  class="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Once the order has been placed we will not be able to
                      accept a request to change the delivery address
                      completely. You might be able to make minor changes (e.g.
                      flat/floor number) by speaking to the delivery executive.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFive">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      The package delivered to me has been damaged and/or
                      tampered with. What can I do about this?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFive"
                  class="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We make sure we use only the most reputed delivery
                      companies, but it is possible that mishaps can happen from
                      time to time. In case the package delivered to you has
                      been damaged and/or tampered with you will be entitled to
                      a partial/full refund. Please see our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Refund Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingSix">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      I want to speak to the home chef before placing the order.
                      How can I get their phone number?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSix"
                  class="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      As per policy we do not share the personal contact details
                      of home chefs on the platform. If you have any special
                      requests or customizations you can enter those at the time
                      of placing your order. Otherwise you can write in to{" "}
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>{" "}
                      and we can coordinate with the home chef for you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingSeven">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      I have some special requests. Is it possible to let the
                      home chef know about these?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSeven"
                  class="collapse"
                  aria-labelledby="headingSeven"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Absolutely. One of the main advantages of ordering from
                      home chefs is that you can customize your food. Before you
                      place your order, you will have the option of entering
                      Special Requests which will be communicated to the home
                      chef.
                    </p>
                    <p>
                      But please note that in case your special
                      request/customization cannot be accommodated by the home
                      chef they may have to reject your order.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingEight">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      How do I make sure that the food matches my dietary
                      requirements?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseEight"
                  class="collapse"
                  aria-labelledby="headingEight"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      At Chef Pin we take dietary requirements very seriously.
                      That is why you will see that under each dish we have
                      asked the home chef to mention the major allergens such as
                      eggs, gluten, lactose etc. We have also asked the home
                      chefs to identify if their kitchens are suitable for
                      certain customs such as Jain-vegetarian cooking. We hope
                      that these indicators and filters will help you find your
                      perfect home chef on Chef Pin!
                    </p>
                    <p>
                      In addition to this, you can also leave a Special Request
                      for the home chef at the time of placing your order so
                      that they are aware of any dietary requirements that you
                      have.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingNine">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      We prefer to order Halal non-veg dishes. Will that be
                      possible?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseNine"
                  class="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Yes, you can see which home chefs have marked their meat
                      to be Halal and order from them.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTen">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      How can I change my order?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTen"
                  class="collapse"
                  aria-labelledby="headingTen"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Once an order has been placed, we will consider it
                      confirmed. In case you need to change your order, you
                      would have to cancel the order first and then place a
                      fresh order with the changes you require. Please make sure
                      you read our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading11">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse11"
                      aria-expanded="false"
                      aria-controls="collapse11"
                    >
                      How can I cancel my order?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse11"
                  class="collapse"
                  aria-labelledby="heading11"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We provide a 15-minute window in which a diner can cancel
                      their order with no questions asked and get a full refund.
                      If you would like to cancel your order after this free
                      cancellation window, then please read up our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading12">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse12"
                      aria-expanded="false"
                      aria-controls="collapse12"
                    >
                      I am not happy with the quality and/or quantity of the
                      food delivered to me. What can I do about this?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse12"
                  class="collapse"
                  aria-labelledby="heading12"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      In the unlikely situation that you are not satisfied with
                      your food delivery we request you to first raise the issue
                      with us at{" "}
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>
                      . We will review your complaint and we shall notify the
                      same to the home chef. For effective complaint resolution
                      we could connect you directly with the home chef, who
                      shall be responsible for redressing your complaint.
                    </p>
                    <p>
                      You may also provide a honest but considerate review on
                      our platform to share your experience.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading13">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse13"
                      aria-expanded="false"
                      aria-controls="collapse13"
                    >
                      How do I provide feedback on my order?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse13"
                  class="collapse"
                  aria-labelledby="heading13"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We have an option to Review and Rate your orders. For this
                      you will need to click on your Past Orders and then select
                      the specific order on which you want to provide the
                      feedback. Please note that we only allow reviews from
                      diners who have actually ordered from the home chef – this
                      is to make sure that each review is genuine.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading14">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse14"
                      aria-expanded="false"
                      aria-controls="collapse14"
                    >
                      I want to place a party order with a home chef. How can I
                      do this?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse14"
                  class="collapse"
                  aria-labelledby="heading14"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Please enter a party-order inquiry in the system and we
                      will get the home chef to reply to you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading15">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse15"
                      aria-expanded="false"
                      aria-controls="collapse15"
                    >
                      What is the Chef Pin customer support number?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse15"
                  class="collapse"
                  aria-labelledby="heading15"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      You can reach us at +91 98996 22242 or email us at
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>
                      . We also have a WhatsApp chat option on our site which
                      you can use.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading16">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse16"
                      aria-expanded="false"
                      aria-controls="collapse16"
                    >
                      I am a home chef so how do I register on Chef Pin?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse16"
                  class="collapse"
                  aria-labelledby="heading16"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      You will find a REGISTER AS A HOME CHEF button on our
                      site. Please click on that to register as a home chef on
                      Chef Pin.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section
          style={{
            backgroundImage: 'url("assets/images/backgrond.png")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            objectFit: "fill",
            height: "725px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              paddingTop: "50px",
            }}
          >
            <h5
              style={
                {
                  // backgroundColor: "rgba(255, 255, 255, 0.7)",
                }
              }
              class="text-center"
            >
              DOWNLOAD CHEF PIN
            </h5>
            <div
              style={
                {
                  // backgroundColor: "rgba(255, 255, 255, 0.7)",
                }
              }
              class="col-lg-6 mx-auto text-center"
            >
              <p class=" mt-3 " style={{ fontSize: "20px" }}>
                We're all aware that nothing can beat the taste of homemade
                food, but it has never been an effortless task to find it.
                However, here's some exciting news for you! With Chef Pin, you
                can now relish the taste of home-cooked food that's customized
                to your preferences. You have the option to choose from a vast
                selection of home chefs, ranging from food specialists to
                bakers, and get your food freshly prepared just for you.
              </p>
            </div>
          </div>
          <div class=" mt-5 d-flex justify-content-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.chefpin"
              target="_blank"
            >
              <img
                style={{ width: 200 }}
                src="/assets/images/icons/play-store.svg"
                alt="Indian home chef"
                class="img-fluid p-2 "
              />
            </a>
            <a
              href="https://apps.apple.com/in/app/chef-pin/id1579584069"
              target="_blank"
            >
              <img
                style={{ width: 200 }}
                src="/assets/images/icons/apple-store.svg"
                alt="Indian home chef"
                class="img-fluid p-2 "
              />
            </a>
          </div>
        </section>

        <section className="benifits_slider">
          <div className="container">
            <h2 className="text-uppercase color_C07 text-center">
              SEE WHAT SOME OF OUR CUSTOMERS ARE SAYING
            </h2>
            <Slider {...this.state.reviewSettings}>
              <div class="box-review">
                <p style={{ fontSize: "20px", marginTop: "10px" }}>
                  The food from home chef Reena Mukherjee was awesome and
                  delicious. It felt like ‘maa ke haath ka khaana’. Such a
                  delightful meal!
                </p>
                <p>
                  <b>Mun Mun Mukherjee </b>
                </p>
                <p class="color_C07">
                  <b>
                    <i>Verified Diner</i>
                  </b>
                </p>
                <img
                  src="/assets/images/5stars.png"
                  alt="Indian home chef"
                  class="img-fluid p-2 w-50"
                />
              </div>
              <div class="box-review">
                <p style={{ fontSize: "20px", marginTop: "10px" }}>
                  Home chef Suparna Kumar was fantastic as usual! The mutton was
                  heavenly. The Chicken was very tasty. Thank you.
                </p>
                <p>
                  <b>Sabyasachi Bal</b>
                </p>
                <p class="color_C07">
                  <b>
                    <i>Verified Diner</i>
                  </b>
                </p>
                <img
                  src="/assets/images/4stars.png"
                  alt="Indian home chef"
                  class="img-fluid p-2 w-50"
                />
              </div>
              <div class="box-review">
                <p style={{ fontSize: "20px", marginTop: "10px" }}>
                  I am a fan of Maharashtrian food and home chef Radha Kulkarni
                  has surpassed by all standards. I absolutely liked everything,
                  especially Puranpoli!
                </p>
                <p>
                  <b>Atul Sethi</b>
                </p>
                <p class="color_C07">
                  <b>
                    <i>Verified Diner</i>
                  </b>
                </p>
                <img
                  src="/assets/images/5stars.png"
                  alt="Indian home chef"
                  class="img-fluid p-2 w-50"
                />
              </div>
              <div class="box-review">
                <p style={{ fontSize: "20px", marginTop: "10px" }}>
                  Shahi Paneer and Matar Mushroom were both yummy! Dal Makhni
                  was delicious. Brownie was the best by home chef Pragya
                  Chopra.
                </p>
                <p>
                  <b>Samridhi Puri</b>
                </p>
                <p class="color_C07">
                  <b>
                    <i>Verified Diner</i>
                  </b>
                </p>
                <img
                  src="/assets/images/4.5stars.png"
                  alt="Indian home chef"
                  class="img-fluid p-2 w-50"
                />
              </div>
              <div class="box-review">
                <p style={{ fontSize: "20px", marginTop: "10px" }}>
                  Home baker Tushar Toprani bakes beautifully. The pizza was
                  delightful both in taste and in presentation!
                </p>
                <p>
                  <b>Deepali Naair</b>
                </p>
                <p class="color_C07">
                  <b>
                    <i>Verified Diner</i>
                  </b>
                </p>
                <img
                  src="/assets/images/4.5stars.png"
                  alt="Indian home chef"
                  class="img-fluid p-2 w-50"
                />
              </div>
            </Slider>
          </div>
        </section>
        <section className="benifits_slider media-events-caro">
          <div className="container">
            <h2 className="text-uppercase color_C07 text-center">
              MEDIA & EVENTS
            </h2>
            <Slider {...this.state.blogsSettings}>
              <div class="box-review p-2">
                <a
                  href="https://www.newindianexpress.com/lifestyle/food/2023/mar/19/eating-out-of-the-box-pre-prepared-home-cooked-food-gains-popularity-among-working-indians-2556678.html"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/1-Blog.jpg"
                        alt="Block 1 Image"
                      />
                    </div>
                    <p className="media-title">
                      {" "}
                      Eating out of the box: Pre-prepared home-cooked food gains
                      popularity among working Indians{" "}
                    </p>
                    <p className="media-description">
                      Pre-prepared meal boxes are a popular option for those
                      tired of cooking at home or ordering in fancy, often
                      unhealthy, meals.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://hospitality.economictimes.indiatimes.com/news/operations/food-and-beverages/our-mission-to-uncover-the-hidden-talent-of-home-chefs/87664611"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/2-Blog.jpg"
                        alt="Block 2 Image"
                      />
                    </div>
                    <p className="media-title">
                      Our mission to uncover the hidden talent of home chefs
                    </p>
                    <p className="media-description">
                      Siddharth Mohan, founder and CEO of Chef Pin speaks about
                      how his platform is different from others in the home chef
                      food delivery space.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://www.indianewsstream.com/west-asia/chef-pin-aims-to-be-the-countrys-largest-app-for-home-chefs/"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/3-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Chef Pin aims to be the country’s largest app for home
                      chefs
                    </p>
                    <p className="media-description">
                      We recently got the opportunity to taste the scrumptious,
                      rich delicacies of Punjabi-Dilli cuisine at Food Exchange,
                      Novotel Aerocity prepared by Chef pin APP home chef Naveen
                      Sawhney.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="http://fnbnews.com/Hotels-Hospitality/cur8-and-chef-pin-by-rocky-mohan-unite-to-redefine-culinary-excellence-74416"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/4-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      {" "}
                      Chef Pin by Rocky Mohan unite to redefine culinary
                      excellence
                    </p>
                    <p className="media-description">
                      Experience a four-month culinary extravaganza with CUR8,
                      Four Seasons Hotel Bengaluru, and Chef Pin, redefining
                      dining with exceptional homegrown chefs.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://www.vogue.in/content/how-intimate-dining-is-fast-becoming-a-beloved-culinary-trend-on-indias-food-block"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/5-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Chef Pin: Elevating Home Cooks & Dining Experiences in
                      India
                    </p>
                    <p className="media-description">
                      Discover the culinary secrets of India with Chef Pin.
                      Connect with 5,000 home chefs for unique dining
                      experiences in Delhi, Mumbai, and Bangalore.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://bwhotelier.businessworld.in/article/Novotel-Aerocity-New-Delhi-and-Chef-Gul-Ali-brings-authentic-flavours-of-Lucknow-to-life-/09-09-2022-445884/"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/6-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Novotel Aerocity's Lucknow Flavors with Chef Gul Ali
                    </p>
                    <p className="media-description">
                      Experience authentic Lucknow cuisine at Novotel Aerocity,
                      in collaboration with Chef Pin and culinary artist Gul
                      Ali.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://businessnewsthisweek.com/health/discover-kayastha-cuisine-with-a-culinary-workshop-at-ecole-ducasse-india-in-collaboration-with-chef-pin/"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/7-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Kayastha Cuisine Workshop with École Ducasse and Chef Pin
                    </p>
                    <p className="media-description">
                      Discover the rich heritage of Kayastha cuisine at École
                      Ducasse's workshop in collaboration with Chef Pin. Learn
                      traditional dishes and cooking styles.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://www.apnnews.com/jw-marriott-hotel-bengaluru-in-association-with-chef-pin-presents-home-chef-fathima-riyaz/"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/8-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Rediscover Bhatkal Cuisine at JW Marriott Bengaluru
                    </p>
                    <p className="media-description">
                      JW Marriott Bengaluru and Chef Pin present Home Chef
                      Fathima Riyaz, showcasing authentic Bhatkal cuisine from
                      Sept 9-11, 2022.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://lbb.in/delhi/chef-pin-485a6d"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/9-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Hidden Gems of India: Mughlai Cuisine by Home Chef Sana
                      Khan
                    </p>
                    <p className="media-description">
                      Indulge in handpicked Mughlai dishes by home chef Sana
                      Khan at Shangri-La Eros New Delhi in collaboration with
                      Chef Pin, at Tamra. Lunch and dinner buffet available.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://www.vervemagazine.in/people/an-invitation-to-the-community-table-anglo-indian-home-chefs-are-offering-a-taste-of-their-closely-guarded-culinary-heritage"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/10-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Rediscovering Anglo-Indian Cuisine: Home Chefs Share
                      Culinary Heritage
                    </p>
                    <p className="media-description">
                      Explore the unique Anglo-Indian cuisine as home chefs like
                      Ruth Phillips open the doors to their closely guarded
                      culinary heritage.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
              <div class="box-review p-2">
                <a
                  href="https://bwhotelier.businessworld.in/article/Savour-hidden-culinary-gems-of-India-at-Shangri-La-Eros-New-Delhi/07-04-2022-424756/"
                  target="_blank"
                  className="col-md-4 media-block text-center"
                  style={{ marginBottom: 10 }}
                >
                  <div className="media-content">
                    <div className="media-image">
                      <img
                        className="img-fluid rounded"
                        src="/assets/images/media/11-Blog.jpg"
                        alt="Block 3 Image"
                      />
                    </div>
                    <p className="media-title">
                      Shangri-La's Hidden Culinary Gems: Regional Cuisine Series
                      with Chef Pin
                    </p>
                    <p className="media-description">
                      Explore authentic regional cuisines at Shangri-La Eros New
                      Delhi. Home chefs, like Surbhi Bhandari, present Marwadi
                      cuisine at Tamra from April 7-10.
                    </p>
                    <p className="media-title">Read More</p>
                  </div>
                </a>
              </div>
            </Slider>
          </div>
        </section>
        <section className="benifits_slider blogs-caro">
          <div className="container">
            <h2 className="text-uppercase color_C07 text-center">BLOGS</h2>
            <Slider {...this.state.blogscaroSettings}>
              {this.state.blogcarodata.length > 0 &&
                this.state.blogcarodata.map((item) => {
                  return (
                    <div class="box-review p-2">
                      <a
                        href={"/blog/" + item.slug}
                        // to={"/blog/" + item.slug}
                        className="col-md-12 text-center"
                        style={{
                          marginBottom: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="mb-4"
                          style={{
                            width: "100%",
                            height: "70%",
                          }}
                        >
                          <div
                            className="mt-2, mb-2"
                            style={{ height: "17rem" }}
                          >
                            <img
                              className="img-fluid"
                              src={URLs.images + item.feturedimage}
                              alt={item.feturedimage_alt}
                              style={{
                                objectFit: "scale-down",
                                height: "15rem",
                              }}
                            />
                          </div>

                          <p className="color_a05 mt-4">{item.title}</p>
                          <p
                            className="mt-2"
                            style={{ color: "#000", fontSize: 14 }}
                          >
                            {item.description}
                          </p>
                        </div>
                      </a>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </section>
        <InstagramFeed />
        {/* <section className="ourservicessect mt-4">
          <div className="container">
            <h2 className="text-uppercase color_C07 text-center">
              Our Services
            </h2>
            <div className="row justify-content-center">
              <div className="col-lg-4 md-4 sm-10">
                <div>
                  <img
                    src="/asse/assets/images/home/servicesicon.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div>
                  <h4>Party Orders</h4>
                  <p>
                    Now you can get authentic dishes to wow your guests at your
                    next party. Submit a Party Order inquiry to Chef Pin and let
                    us curate the best home chefs who will contact you to help
                    you plan your party menu. How exciting is that! For Party
                    Orders we can link to the Inquiry form as shared earlier
                  </p>
                  <button className="enq-btn">Enquire</button>
                </div>
              </div>
              <div className="col-lg-4 md-4 sm-10">
                <div>
                  <img
                    src="/assets/images/home/servicesicon.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div>
                  <h4>Party Orders</h4>
                  <p>
                    Now you can get authentic dishes to wow your guests at your
                    next party. Submit a Party Order inquiry to Chef Pin and let
                    us curate the best home chefs who will contact you to help
                    you plan your party menu. How exciting is that! For Party
                    Orders we can link to the Inquiry form as shared earlier
                  </p>
                  <button className="enq-btn">Join us as a home chef</button>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 md-4 sm-10">
                <span>
                  <img
                    src="/assets/images/home/servicesicon.png"
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <div>
                  <h4>Party Orders</h4>
                  <p>
                    Now you can get authentic dishes to wow your guests at your
                    next party. Submit a Party Order inquiry to Chef Pin and let
                    us curate the best home chefs who will contact you to help
                    you plan your party menu. How exciting is that! For Party
                    Orders we can link to the Inquiry form as shared earlier
                  </p>
                  <button className="enq-btn">Enquire</button>
                </div>
              </div>
              <div className="col-lg-4 md-4 sm-10">
                <span>
                  <img
                    src="/assets/images/home/servicesicon.png"
                    alt=""
                    className="img-fluid"
                  />
                </span>
                <div>
                  <h4>Party Orders</h4>
                  <p>
                    Now you can get authentic dishes to wow your guests at your
                    next party. Submit a Party Order inquiry to Chef Pin and let
                    us curate the best home chefs who will contact you to help
                    you plan your party menu. How exciting is that! For Party
                    Orders we can link to the Inquiry form as shared earlier
                  </p>
                  <button className="enq-btn">Enquire</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* {this.state.dishDataArr.length >= 6 ? (
          <section className="sample_dish_slider">
            <h2 className="text-uppercase color_C07 text-center">
              {this.state.dishTitle}
            </h2>
            <Slider {...this.state.dishSettings}>
              {this.state.dishDataArr.map((dish) => {
                return (
                  <div className="item" key={"czxc" + dish.id}>
                    <div className="food-img">
                      <img src={dish.image_path} className="img-fluid p-1" />

                      <div className="food-text">
                        <h4>{dish.description}</h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </section>
        ) : null} */}
        {/* //image slide */}

        {this.state.ImageSliderData.length >= 6 ? (
          <section className="sample_dish_slider">
            <h2 className="text-uppercase color_C07 text-center">
              {this.state.dishTitle}
            </h2>
            <Slider {...this.state.dishSettings}>
              {this.state.ImageSliderData.map((dish) => {
                return (
                  <div className="item" key={"czxc" + dish.id}>
                    <div className="food-img">
                      <img
                        // src={dish.image_path}
                        src={URLs.images + dish.image}
                        className="img-fluid"
                        alt={dish.altImage}
                      />

                      <a href={dish.redirectionUrl} className="food-text">
                        <h4>{dish.title}</h4>
                      </a>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </section>
        ) : null}

        <section class="container susbcribe mt-4">
          <div class="col-lg-10 text-center mx-auto">
            <h4 class="text-uppercase color_C07 text-center">
              SUBSCRIBE AND STAY UPDATED
            </h4>
            <div
              style={{
                paddingTop: "50px",
                paddingBottom: "50px",
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <p class=" mb-3 ">
                Be the first to know about Chef Pin offers and event
              </p>
              <div
                style={{
                  width: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <input
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    borderColor: "#808080",
                    marginBottom: "10px",
                  }}
                  type="text"
                  placeholder="Name"
                  value={this.state.subscribeName}
                  onChange={(e) => {
                    this.setState({ subscribeName: e.target.value });
                  }}
                />
                <input
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    borderColor: "#808080",
                    marginBottom: "10px",
                  }}
                  type="text"
                  placeholder="Email Address"
                  value={this.state.subscribeEmail}
                  onChange={(e) => {
                    this.setState({ subscribeEmail: e.target.value });
                  }}
                />
                <button
                  onClick={() => {
                    this.postSubscribe();
                  }}
                  style={{
                    backgroundColor: "#F14D20",
                    border: 0,
                    padding: "10px",
                    borderRadius: "3px",
                    color: "#fff",
                  }}
                >
                  SIGN UP
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* {this.state.articleTagDataArr &&
        this.state.articleTagDataArr.length > 0 ? (
          <div className="article_links">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10 text-center">
                  <ul>
                    {this.state.articleTagDataArr.map((tag) => {
                      return (
                        <li key={"article" + tag.id}>
                          <a
                            href=""
                            onClick={() => this.getArticleTagData(tag.slug_url)}
                          >
                            {tag.tag_title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null} */}

        {/* {this.state.chefDataArr.length >= 6 ? (
          <section className="sample_dish_slider">
            <h2 className="text-uppercase color_C07 text-center">
              {this.state.chefTitle}
            </h2>
            <Slider {...this.state.dishSettings}>
              {this.state.chefDataArr.map((chef) => {
                return (
                  <div className="item" key={"adb" + chef.id}>
                    <div className="food-img">
                      <img src={chef.image_path} className="img-fluid" />

                      <div className="food-text">
                        <h4>{chef.description}</h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </section>
        ) : null} */}

        {/* //image slide */}
        {this.state.ImageSliderData2.length >= 6 ? (
          <section className="sample_dish_slider">
            <h2 className="text-uppercase color_C07 text-center">
              {this.state.chefTitle}
            </h2>
            <Slider {...this.state.dishSettings}>
              {this.state.ImageSliderData2.map((chef) => {
                return (
                  <div className="item" key={"adb" + chef.id}>
                    <div className="food-img">
                      <img
                        //  src={chef.image_path}
                        src={URLs.images + chef.image}
                        className="img-fluid"
                        alt={chef.altImage}
                      />

                      <a href={chef.redirectionUrl} className="food-text">
                        <h4>{chef.title}</h4>
                      </a>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </section>
        ) : null}
        {this.state.hideContent && (
          <div className="mb-3 mb-sm-0 pb-1 pb-sm-0">
            <ul className="store_list mt-0 ">
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.chefpin"
                  target="_blank"
                >
                  <img src="/assets/images/icons/play-store.svg" />
                </a>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/in/app/chef-pin/id1579584069"
                  target="_blank"
                >
                  <img src="/assets/images/icons/apple-store.svg" />
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}

        {this.state.displayPopup ? (
          <div className="cookies_pop">
            <button
              className="close"
              type="button"
              onClick={(e) => this.checkCookie(e)}
            >
              {" "}
              <img src="assets/images/icons/black-close.svg" />
            </button>
            <p>
              We use cookies to personalize your experience. By continuing to
              visit this website you agree to our use of cookies.{" "}
              <a href="/diner-policy" target="_blank">
                Learn More.
              </a>
            </p>
          </div>
        ) : null}
        {this.state.PagesData && this.state.PagesData.length > 0 ? (
          <div className="article_links">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10 text-center">
                  <ul>
                    {this.state.PagesData.map((tag) => {
                      return (
                        <li key={"article" + tag._id}>
                          <a
                            href=""
                            onClick={() => this.getArticleTagData(tag.slug)}
                          >
                            {tag.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
});

export default connect(mapStateToProps)(DinerHomepage);
