import React, { useState, useRef, useEffect } from "react";
import "../BackendStyle.css";
import { URLs } from "../Url";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { getDays, getMonth } from "../Fun";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  GetPages,
  handlePostImage,
  Metatagsupload,
  Metatagsupdate,
  handledeleteImage,
  DeletePages,
} from "./config";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  BlogData,
  BlogDelete,
  BlogImageDelete,
  BlogeditPostdata,
  BlogCategoryData,
  Blogpostimage,
  BlogPostdata,
  AuthorData,
} from "./config";
const AddBlog = () => {
  let history = useHistory();
  let { path, url } = useRouteMatch();

  const editor = useRef(null);

  const [data, setdata] = useState();
  const [authordata, setauthordata] = useState();
  const [categorydata, setcategorydata] = useState();
  const [title, settitle] = useState();
  const [image, setimage] = useState();
  const [imagealt, setimagealt] = useState();
  const [catId, setcatId] = useState();
  const [category, setcategory] = useState();
  const [description, setdescription] = useState();
  const [article, setarticle] = useState();
  const [slug, setslug] = useState();
  const [tags, settags] = useState();
  const [selectedtags, setselectedtags] = useState([]);
  const [author, setauthor] = useState();
  const [authorid, setauthorid] = useState();
  const [status, setstatus] = useState();
  const [updateimage, setupdateimage] = useState();
  const [UpdateId, setUpdateId] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [show, setShow] = useState(true);

  useEffect(() => {
    handlerData();
  }, []);

  const handlerData = async () => {
    const getgteanData = await BlogData();
    const getgteanData2 = await BlogCategoryData();
    const getAuthordata = await AuthorData();
    if (
      getgteanData.success == true &&
      getgteanData2.success == true &&
      getAuthordata.success == true
    ) {
      setdata(getgteanData.data);
      setcategorydata(getgteanData2.data);
      setauthordata(getAuthordata.data);
      setisLoading(false);
    }
  };
  const DeleteHandler = async (id, imageDelete) => {
    const getResponse = await BlogDelete(id);
    if (getResponse.success == true) {
      ImageDeleteHandler(imageDelete);
    }
  };
  const ImageDeleteHandler = async (imageDelete) => {
    const getResponse = await BlogImageDelete(imageDelete);
    if (getResponse.success == true) {
      handlerData();
    }
  };
  const handleImage = async () => {
    if (
      updateimage != undefined &&
      catId != undefined &&
      title != undefined &&
      description != undefined &&
      article != undefined &&
      slug != undefined &&
      author != undefined
    ) {
      const GetDataTestimonials = await Blogpostimage(updateimage);
      if (GetDataTestimonials.success == true) {
        if (show == "update") {
          UpdateHandler(GetDataTestimonials.image);
        } else {
          // console.log(GetDataTestimonials);
          handlePost(GetDataTestimonials.image);
        }
      }
    } else {
      alert("empty fields");
    }
  };
  const handlePost = async (imageName) => {
    const GetDataTestimonials = await BlogPostdata(
      imageName,
      catId,
      title,
      description,
      article,
      authorid,
      slug,
      imagealt,
      selectedtags
    );
    if (GetDataTestimonials.success == true) {
      EmptyState();
      setisLoading(true);
      handlerData();
    }
  };
  const UpdateHandler = async (imageName) => {
    const ImageCheck = updateimage == undefined ? image : imageName;
    const GetDataTestimonials = await BlogeditPostdata(
      UpdateId,
      ImageCheck,
      catId,
      title,
      description,
      article,
      authorid,
      slug,
      imagealt,
      selectedtags
    );
    if (GetDataTestimonials.success == true) {
      EmptyState();
      handlerData();
    }
    if (updateimage != undefined) {
      ImageDeleteHandler(image);
    }
  };
  const EmptyState = () => {
    settitle();
    setimage();
    setdescription();
    setauthorid();
    setcatId();
    setimagealt();
    setarticle();
    seteditorState(EditorState.createEmpty());
    setslug();
    setUpdateId();
    setcategory();
    setauthor();
    setselectedtags([]);
    setShow();
  };
  const UpdateItem = (item) => {
    settitle(item.title);
    setimage(item.image);
    setdescription(item.description);
    setauthorid(item.author);
    setcatId(item.blogcategory_id);
    setimagealt(item.feturedimage_alt);
    setarticle(item.article);
    seteditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(item.article))
      )
    );
    setselectedtags(item.tags);
    setslug(item.slug);
    setUpdateId(item._id);
    let check = categorydata.find((ite) => {
      return ite._id == item.blogcategory_id;
    });
    setcategory(check.categoryname);

    let checkAuthor = authordata.find((items) => {
      return items._id == item.author;
    });
    setauthor(checkAuthor.author);

    // console.log(checkAuthor);
    setShow("update");
  };
  const changeFruit = (newFruit) => {
    setcategory(newFruit);
    let check = categorydata.find((item) => {
      return item.categoryname == newFruit;
    });
    setcatId(check._id);
  };
  const changeAuthor = (newFruit) => {
    setauthor(newFruit);
    let check = authordata.find((item) => {
      return item.author == newFruit;
    });

    setauthorid(check._id);
  };
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    seteditorState(editorState);
    setarticle(editorState);
    setarticle(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  const [Value, setValue] = useState();

  const uploadCallback = (file, callback) => {
    // console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {
        const getImage = await Blogpostimage(file);
        const res = getImage.image;
        setValue("thumbnail", res);
        resolve({ data: { link: URLs.images + res } });
      };
      reader.readAsDataURL(file);
    });
  };

  const config = {
    image: {
      uploadCallback: uploadCallback,
      alt: { present: true, mandatory: true },
    },
  };

  const RemoveTags = (id) => {
    const check = selectedtags.filter((item) => {
      return item != id;
    });
    console.log(check);
    if (check.length == 0) {
      setselectedtags([]);
    } else {
      setselectedtags(check);
    }
  };
  console.log(selectedtags);
  return isLoading == true ? null : (
    <>
      {show == "update" ? (
        <div className="cus-contact">
          <form>
            <div className="form-group">
              <label>Category</label>

              <select
                className="form-control"
                onChange={(event) => changeFruit(event.target.value)}
                // value={category}
                defaultValue={category}
              >
                <option value="">Select Category</option>

                {categorydata.map((item) => {
                  return (
                    <>
                      <option value={item.categoryname}>
                        {item.categoryname}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Article</label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                placeholder="Write your article here"
                toolbar={config}
              />
            </div>
            <div className="form-group">
              <label>Slug</label>
              <input
                type="text"
                className="form-control"
                value={slug}
                onChange={(e) => setslug(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tags</label>
              {selectedtags.length > 0 &&
                selectedtags.map((item, index) => {
                  return (
                    <div className={index == 0 ? "mt-3 mb-3" : "mb-3"}>
                      <span>{index + 1 + "."}</span>
                      &nbsp; <span>{item}</span>
                      <button
                        type="button"
                        onClick={() => RemoveTags(item)}
                        className="btnnnnn btn-danger"
                        style={{ marginLeft: 20 }}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  );
                })}
              <div className="d-flex row align-items-center">
                <input
                  type="text"
                  className="form-control"
                  value={tags}
                  style={{ width: "30%", marginRight: 20 }}
                  onChange={(e) => settags(e.target.value)}
                />
                <button
                  onClick={() => {
                    setselectedtags((curent) => [...curent, tags]);
                    settags();
                  }}
                  type="button"
                  className="btn btn-default"
                >
                  Add Tag
                </button>
              </div>
            </div>
            <div className="form-group">
              <label>Author</label>

              <select
                className="form-control"
                onChange={(event) => changeAuthor(event.target.value)}
                // value={category}
                defaultValue={author}
              >
                <option value="">Select Category</option>

                {authordata.map((item) => {
                  return (
                    <>
                      <option value={item.author}>{item.author}</option>
                    </>
                  );
                })}
              </select>
            </div>
            {/* <div className="form-group">
              <label>Author</label>
              <input
                type="text"
                className="form-control"
                value={author}
                onChange={(e) => setauthor(e.target.value)}
              />
            </div> */}

            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={(e) => setupdateimage(e.target.files[0])}
              />
            </div>
            <div className="form-group">
              <label>Alt Text</label>
              <input
                type="text"
                className="form-control"
                value={imagealt}
                onChange={(e) => setimagealt(e.target.value)}
              />
            </div>
            <button
              onClick={() => {
                if (updateimage == undefined) {
                  UpdateHandler();
                } else {
                  handleImage();
                }
              }}
              type="button"
              className="btn btn-default"
            >
              Submit
            </button>
          </form>
        </div>
      ) : show == "add" ? (
        <div className="cus-contact">
          <form>
            <div className="form-group">
              <label>Category</label>

              <select
                className="form-control"
                onChange={(event) => changeFruit(event.target.value)}
                // value={category}
                defaultValue={category}
              >
                <option value="">Select Category</option>

                {categorydata.map((item) => {
                  return (
                    <>
                      <option value={item.categoryname}>
                        {item.categoryname}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Article</label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                placeholder="Write your article here"
                toolbar={config}
              />
            </div>
            <div className="form-group">
              <label>Slug</label>
              <input
                type="text"
                className="form-control"
                value={slug}
                onChange={(e) => setslug(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Tags</label>
              {selectedtags.length > 0 &&
                selectedtags.map((item, index) => {
                  return (
                    <div className={index == 0 ? "mt-3 mb-3" : "mb-3"}>
                      <span>{index + 1 + "."}</span>
                      &nbsp; <span>{item}</span>
                      <button
                        type="button"
                        onClick={() => RemoveTags(item)}
                        className="btnnnnn btn-danger"
                        style={{ marginLeft: 20 }}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  );
                })}
              <div className="d-flex row align-items-center">
                <input
                  type="text"
                  className="form-control"
                  value={tags}
                  style={{ width: "30%", marginRight: 20 }}
                  onChange={(e) => settags(e.target.value)}
                />
                <button
                  onClick={() => {
                    setselectedtags((curent) => [...curent, tags]);
                    settags();
                  }}
                  type="button"
                  className="btn btn-default"
                >
                  Add Tag
                </button>
              </div>
            </div>
            <div className="form-group">
              <label>Author</label>

              <select
                className="form-control"
                onChange={(event) => changeAuthor(event.target.value)}
                // value={category}
                defaultValue={author}
              >
                <option value="">Select Category</option>

                {authordata.map((item) => {
                  return (
                    <>
                      <option value={item.author}>{item.author}</option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={(e) => setupdateimage(e.target.files[0])}
              />
            </div>
            <div className="form-group">
              <label>Alt Text</label>
              <input
                type="text"
                className="form-control"
                value={imagealt}
                onChange={(e) => setimagealt(e.target.value)}
              />
            </div>
            <button
              onClick={() => handleImage()}
              type="button"
              className="btn btn-default"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="content">
          <div className="row table-invoice">
            <div className="col-sm-12">
              <div className="table-in-card">
                <div className="d-flex justify-content-between">
                  <h3>Blogs List</h3>
                  <button
                    type="button"
                    onClick={() => setShow("add")}
                    className="btn btn-primary btn-xs"
                  >
                    <small>Add Blog</small>
                  </button>
                </div>
                <br />
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Alt Text</th>

                        <th>Category</th>
                        <th>Title</th>
                        <th>Article</th>
                        <th>Description</th>
                        <th>Slug</th>
                        <th>Author</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data == undefined
                        ? null
                        : data.map((item, index) => {
                            var Dates = item.updatedAt.split("T")[0];

                            const editorSta = EditorState.createWithContent(
                              ContentState.createFromBlockArray(
                                convertFromHTML(item.article.slice(0, 1000))
                              )
                            );

                            return (
                              <tr>
                                <td>
                                  <img
                                    src={URLs.images + item.feturedimage}
                                    alt=""
                                    // className="img-fluid"
                                    style={{ width: 100, height: 100 }}
                                  />
                                </td>
                                <td>{item.feturedimage_alt}</td>
                                <td>{item.category[0].categoryname}</td>
                                <td>{item.title}</td>
                                <td>
                                  <Editor
                                    toolbarHidden
                                    editorState={editorSta}
                                    readOnly
                                  />
                                </td>
                                <td>{item.description}</td>
                                <td>
                                  <a
                                    href={
                                      window.location.origin +
                                      "/blog/" +
                                      item.slug
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item.slug}
                                  </a>
                                </td>
                                <td>{item.author_detail[0].author}</td>
                                <td>
                                  {getDays(Dates) +
                                    ", " +
                                    " " +
                                    new Date(Dates).getDate() +
                                    " " +
                                    getMonth(Dates) +
                                    " " +
                                    new Date(Dates).getFullYear()}
                                </td>
                                <td>
                                  {item.status == true ? "Active" : "Deactive"}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <button
                                      className="btnnnnn btn-primary"
                                      type="button"
                                      onClick={() => UpdateItem(item)}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        DeleteHandler(item._id, item.image)
                                      }
                                      className="btnnnnn btn-danger"
                                      style={{ marginLeft: 20 }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddBlog;
