import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from "../../components/whatsappButton";
import { Link, Redirect } from "react-router-dom";
import ChefAccountSidebar from "./chefAccountSidebar";
import StarRatings from "react-star-ratings";
import {
  getChefReviewAPI,
  getChefReplyUpdateAPI,
} from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Moment from "moment";
import LoadingDiv from "../../components/LoadingDiv";
import { removeMobileView } from "../../redux/actions/chefMenuActions";

class ChefReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      isLoading: true,
      deliveredOrders: [],
      orderDetails: [],
      reviewFlag: false,
      btnDisabled: true,
      chars_left: 1000,
    };
  }

  componentDidMount() {
    this.getReviewListData();
  }

  getReviewListData = () => {
    return getChefReviewAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.status === 200) {
          this.setState({
            deliveredOrders: response.data.data.user_reviews,
            isLoading: false,
          });
        } else if (response.data["status"] === 401) {
          NotificationManager.error(
            "Session expired. Please log in.",
            "Error!"
          );
          this.props.history.push("/diner-signin-with-otp");
          localStorage.clear();
        } else {
         /*  NotificationManager.error(response.data.message, "Error!"); */
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };

  handleInputChange = (e) => {
    const charCount = e.target.value.length;
    const charLeft = 1000 - charCount;
    this.setState({ chars_left: charLeft });
    const { name, value } = e.target;
    this.setState({ [name]: value, btnDisabled: false });
  };

  getReviewReply = () => {
    this.setState({
      reviewFlag: true,
    });
  };

  closeRatingReviewReply = () => {
    this.setState({
      reviewFlag: false,
    });
  };

  getRateAndReviewChefReply = (reviewId) => {
    console.log("ReviewID" + reviewId);
    console.log("Comment" + this.state.comment);
    return getChefReplyUpdateAPI(
      this.props.userData.userId,
      this.props.userData.accessToken,
      reviewId,
      this.state.comment
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.status === 200) {
          this.setState({
            reviewFlag: false,
          });
          this.getReviewListData();
          NotificationManager.success(response.data.message, "Success!");
        } else {
          this.setState({
            reviewFlag: false,
          });
          NotificationManager.error(response.data.message, "Error!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (this.props.userData.userType !== "Chef") {
      return (
        <Redirect
          to={
            "/signin-with-otp?redirect=" +
            this.props.location.pathname.split("/")[1]
          }
        />
      );
    }
    const { isLoading, deliveredOrders } = this.state;
    const { history, mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        <section className="chef_flow">
          <div className="container">
            <div className="row justify-content-center">
              <ChefAccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  <div className="chef_right diner_my_acc">
                    <div className="sticky_wrap">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        <Link
                          to="/chef-dashboard"
                          className="res_back_btn desktop_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </Link>
                        My Review
                      </h6>
                    </div>
                    <div className="outer">
                      {isLoading ? (
                        <LoadingDiv />
                      ) : !(deliveredOrders && deliveredOrders.length) ? (
                        <div className="text-center">
                          <img
                            src="assets/images/icons/no-delivered-orders.svg"
                            alt="Not Found"
                            className="img-fluid"
                          />
                          <h6 className="justify-content-center">NO Review</h6>
                          <Link className="btn" to="/chef-dashboard">
                            go back to dashboard
                          </Link>
                        </div>
                      ) : (
                        <div>
                          {deliveredOrders.map((orderDetail, j) => {
                            return (
                              <div
                                key={j}
                                className="inner current_orders_card"
                              >
                                {orderDetail.order_detail.length !== 0 ? (
                                  orderDetail.order_detail.map((order, i) => {
                                    return (
                                      <div
                                        className={
                                          order.is_takeaway_selected == "1"
                                            ? "order_wrap takeaway"
                                            : "order_wrap"
                                        }
                                        key={"ktejx" + i}
                                      >
                                        <div className="order_title">
                                          <h6 className="order_num">
                                            <span>
                                              {order.is_takeaway_selected == "1"
                                                ? "Takeaway"
                                                : "Delivery"}
                                            </span>
                                            Order Number :{" "}
                                            {"#" + order.order_number}
                                          </h6>
                                        </div>
                                        <div className="save_add_wrap">
                                          <div className="card">
                                            <div className="box">
                                              <h6>
                                                {order.first_name +
                                                  " " +
                                                  order.last_name}
                                              </h6>
                                              <p className="add">
                                                {order.drop_location}
                                              </p>
                                            </div>
                                            <hr className="dash_line" />
                                            <div className="box title_price">
                                              {order.item_details.map(
                                                (item, i) => {
                                                  return (
                                                    <h6 key={"wkxjed" + i}>
                                                      <img
                                                        src={
                                                          item.diet_type ===
                                                          "Veg"
                                                            ? "assets/images/icons/veg-icon.svg"
                                                            : item.diet_type ===
                                                              "Non Veg"
                                                            ? "assets/images/icons/non-veg.svg"
                                                            : item.diet_type ===
                                                              "Contains Egg"
                                                            ? "assets/images/icons/egg-icon.svg"
                                                            : null
                                                        }
                                                        alt=""
                                                      />
                                                      {item.item_name} x{" "}
                                                      {item.itemQuantity}{" "}
                                                      {/* <span>
                                                INR{" "}
                                                {parseFloat(item.item_price) *
                                                  parseFloat(item.itemQuantity)}
                                              </span> */}
                                                    </h6>
                                                  );
                                                }
                                              )}
                                              <p className="total_bill">
                                                Total Bill : INR{" "}
                                                {order.amount_paid} (including
                                                taxes and charges)
                                              </p>
                                            </div>
                                            <hr className="dash_line" />
                                            <div className="box time_slot_wrap">
                                              <p className="time_slot delivered">
                                                Delivered on :{" "}
                                                <span>
                                                  {Moment(
                                                    order.delivered_at
                                                  ).format("MMM DD, hh:mm a")}
                                                </span>
                                              </p>
                                            </div>
                                          </div>

                                          <hr className="dash_line" />
                                          <div className="card mb-0 pb-0 pt-0 shadow_none bg_none border-0">
                                            <div className="box px-0 pb-0">
                                              <h6>
                                                Rating Total:
                                                <StarRatings
                                                  rating={parseFloat(
                                                    orderDetail.average_rating
                                                  )}
                                                  starDimension="20px"
                                                  starSpacing="3px"
                                                  starRatedColor="#FFC107"
                                                />{" "}
                                                {(orderDetail.average_rating == "5.00" || orderDetail.average_rating == "4.00" || orderDetail.average_rating == "3.00" || orderDetail.average_rating == "2.00" || orderDetail.average_rating == "1.00") ? parseInt(orderDetail.average_rating) : orderDetail.average_rating}
                                              </h6>
                                              <ul className="rate_star_list">
                                                <li>
                                                  <span className="fw_500">
                                                    Food Quality
                                                  </span>
                                                  <StarRatings
                                                    rating={parseFloat(
                                                      orderDetail.food_quality
                                                    )}
                                                    starDimension="30px"
                                                    starSpacing="3px"
                                                    starRatedColor="#FFC107"
                                                  />
                                                </li>
                                                <li>
                                                  <span className="fw_500">
                                                    Portion Size
                                                  </span>
                                                  <StarRatings
                                                    rating={parseFloat(
                                                      orderDetail.portion_size
                                                    )}
                                                    starDimension="30px"
                                                    starSpacing="3px"
                                                    starRatedColor="#FFC107"
                                                  />
                                                </li>
                                                <li>
                                                  <span className="fw_500">
                                                    Packaging
                                                  </span>
                                                  <StarRatings
                                                    rating={parseFloat(
                                                      orderDetail.packaging
                                                    )}
                                                    starDimension="30px"
                                                    starSpacing="3px"
                                                    starRatedColor="#FFC107"
                                                  />
                                                </li>
                                                <li>
                                                  <span className="fw_500">
                                                    Value for Money
                                                  </span>
                                                  <StarRatings
                                                    rating={parseFloat(
                                                      orderDetail.value_for_money
                                                    )}
                                                    starDimension="30px"
                                                    starSpacing="3px"
                                                    starRatedColor="#FFC107"
                                                  />
                                                </li>
                                              </ul>
                                              <hr class="dash_line my-4"></hr>
                                              <h6>
                                                Review:
                                              </h6>
                                              <p className="review">{orderDetail.review}</p>
                                              {orderDetail.comments.length ? (
                                                <div>
                                                  <hr className="dash_line" />
                                                  <h6 className="mt-3">
                                                    {" "}
                                                    Your Reply:
                                                  </h6>
                                                  <p>
                                                    {
                                                      orderDetail.comments[0]
                                                        .review_comments
                                                    }
                                                  </p>
                                                </div>
                                              ) : (
                                                <div className="d-flex justify-content-end">
                                                  <button
                                                    className="btn add_new_btn"
                                                    onClick={
                                                      this.getReviewReply
                                                    }
                                                  >
                                                    Reply
                                                  </button>
                                                </div>
                                              )}
                                              {orderDetail.comments.length ? null : (
                                                <div className="form-group mt-4">
                                                  <textarea
                                                    className="form-control"
                                                    placeholder="Enter your reply here"
                                                    name="comment"
                                                    onChange={
                                                      this.handleInputChange
                                                    }
                                                    maxLength="1000"
                                                    autoFocus={true}
                                                  ></textarea>
                                                  <span className="characters">
                                                  {this.state.chars_left} characters
                                                  </span>
                                                </div>
                                              )}
                                              {orderDetail.comments.length ? null : (
                                                <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100">
                                                  <button
                                                    className="btn previous_btn"
                                                    type="submit"
                                                    onClick={
                                                      this
                                                        .closeRatingReviewReply
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    disabled={
                                                      this.state.btnDisabled
                                                    }
                                                    className="btn add_new_btn"
                                                    type="submit"
                                                    onClick={() =>
                                                      this.getRateAndReviewChefReply(
                                                        orderDetail.id
                                                      )
                                                    }
                                                  >
                                                    Submit
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="order_wrap">
                                    <div className="save_add_wrap">
                                      <div className="card">
                                        <div className="box">
                                          <h6>{orderDetail.name}</h6>
                                          <hr className="dash_line mb-3" />
                                          <h6>
                                            {" "}
                                            Meal Ordered:
                                            {(orderDetail.order_details == null || orderDetail.order_details == "") ? " -" : orderDetail.order_details}
                                          </h6>
                                        </div>
                                        <hr className="dash_line" />
                                        <div className="box time_slot_wrap">
                                          <p className="time_slot delivered">
                                            Ordered on :{" "}
                                            <span>
                                              {(orderDetail.order_date == "1970-01-01" || orderDetail.order_date == null || orderDetail.order_date == "") ? " -" : Moment(
                                                orderDetail.created_at
                                              ).format(", hhMMM DD:mm a")}
                                            </span>
                                          </p>
                                        </div>
                                      </div>

                                      <hr className="dash_line" />
                                      <div className="card mb-0 pb-0 pt-0 shadow_none bg_none border-0">
                                        <div className="box px-0 pb-0">
                                          <h6>
                                            Rating Total:
                                            <StarRatings
                                              rating={parseFloat(
                                                orderDetail.average_rating
                                              )}
                                              starDimension="20px"
                                              starSpacing="3px"
                                              starRatedColor="#FFC107"
                                            />{" "}
                                            {(orderDetail.average_rating == "5.00" || orderDetail.average_rating == "4.00" || orderDetail.average_rating == "3.00" || orderDetail.average_rating == "2.00" || orderDetail.average_rating == "1.00") ? parseInt(orderDetail.average_rating) : orderDetail.average_rating}
                                          </h6>
                                          <ul className="rate_star_list">
                                            <li>
                                              <span className="fw_500">
                                                Food Quality
                                              </span>
                                              <StarRatings
                                                rating={parseFloat(
                                                  orderDetail.food_quality
                                                )}
                                                starDimension="30px"
                                                starSpacing="3px"
                                                starRatedColor="#FFC107"
                                              />
                                            </li>
                                            <li>
                                              <span className="fw_500">
                                                Portion Size
                                              </span>
                                              <StarRatings
                                                rating={parseFloat(
                                                  orderDetail.portion_size
                                                )}
                                                starDimension="30px"
                                                starSpacing="3px"
                                                starRatedColor="#FFC107"
                                              />
                                            </li>
                                            <li>
                                              <span className="fw_500">
                                                Packaging
                                              </span>
                                              <StarRatings
                                                rating={parseFloat(
                                                  orderDetail.packaging
                                                )}
                                                starDimension="30px"
                                                starSpacing="3px"
                                                starRatedColor="#FFC107"
                                              />
                                            </li>
                                            <li>
                                              <span className="fw_500">
                                                Value for Money
                                              </span>
                                              <StarRatings
                                                rating={parseFloat(
                                                  orderDetail.value_for_money
                                                )}
                                                starDimension="30px"
                                                starSpacing="3px"
                                                starRatedColor="#FFC107"
                                              />
                                            </li>
                                          </ul>
                                          <hr class="dash_line my-4"></hr>
                                          <h6>Review:</h6>
                                          <p className="review">
                                            {orderDetail.review}
                                          </p>
                                          {orderDetail.comments.length ? (
                                            <div>
                                              <hr className="dash_line" />
                                              <h6 className="mt-3">
                                                {" "}
                                                Your Reply:
                                              </h6>
                                              <p>
                                                {
                                                  orderDetail.comments[0]
                                                    .review_comments
                                                }
                                              </p>
                                            </div>
                                          ) : (
                                            <div className="d-flex justify-content-end">
                                              <button
                                                className="btn add_new_btn"
                                                onClick={this.getReviewReply}
                                              >
                                                Reply
                                              </button>
                                            </div>
                                          )}
                                          {orderDetail.comments
                                            .length ? null : (
                                            <div className="form-group mt-4">
                                              <textarea
                                                className="form-control"
                                                name="comment"
                                                placeholder="Enter your reply here"
                                                onChange={
                                                  this.handleInputChange
                                                }
                                                    maxLength="1000"
                                              ></textarea>
                                              <span className="characters">
                                              {this.state.chars_left} characters
                                              </span>
                                            </div>
                                          )}
                                          {orderDetail.comments
                                            .length ? null : (
                                            <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100">
                                              <button
                                                className="btn previous_btn"
                                                type="submit"
                                                onClick={
                                                  this.closeRatingReviewReply
                                                }
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                disabled={
                                                  this.state.btnDisabled
                                                }
                                                className="btn add_new_btn"
                                                type="submit"
                                                onClick={() =>
                                                  this.getRateAndReviewChefReply(
                                                    orderDetail.id
                                                  )
                                                }
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? null : <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(ChefReview);
