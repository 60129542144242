import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import timeSlotReducer from "./timeSlotReducer";
import authReducer from "./authReducer";
import chefDataReducer from "./chefDataReducer";
import chefMenuReducer from "./chefMenuReducer";
import orderNumberReducer from "./orderNumberReducer";
import oneSignalReducer from "./oneSignalReducer";
import alertReducer from "./alertReducer";
import chefAlertReducer from "./chefAlertReducer";
import orderCountReducer from "./orderCountReducer";

const rootReducer = combineReducers({
  cart: cartReducer,
  timeSlot: timeSlotReducer,
  user: authReducer,
  chef: chefDataReducer,
  chefMenu: chefMenuReducer,
  orderNumber: orderNumberReducer,
  onesignal: oneSignalReducer,
  alert: alertReducer,
  chefAlert: chefAlertReducer,
  orderCount: orderCountReducer
});

export default rootReducer;
