/*
#############################################################################
# Name : userSelection.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to select whether the user chef or diner
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import WhatsAppButton from "../../components/whatsappButton";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class UserSelectionPage extends React.Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

 /* getDiner function starts here */ 
  getDiner = (event) => {
    event.preventDefault();
    this.props.history.push("/diner-signup-mobile");
  };
 /* getDiner function ends here */ 

  /* getChef function starts here */ 
  getChef = (event) => {
    event.preventDefault();
    this.props.history.push("/chef-homepage");
  }; 
 /* getChef function ends here */ 
 
  render() {
    return (
      <div className="css2 mt-0">
        <Helmet>
          <title>Register as a Home Chef - Chef Pin</title>
          <meta name="title" content="Register as a Home Chef - Chef Pin" />
          <meta
            name="description"
            content="Register as a home chef. Sell your home cooked meals on Chef Pin. Already have an account?"
          />
        </Helmet>
        <DinnerHeader />
        <section className="sign_chefdiner">
          <Link to="/" className="back_btn">
            <img src="/assets/images/icons/black-arrow.svg" alt="Black Arrow" />
            Back
          </Link>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 text-center custom_border">
                <div className="box one">
                  <h6 className="text-uppercase">are you a diner?</h6>
                  <div className="mediem_circle_loc">
                    <div className="inner">
                      <img
                        src="/assets/images/icons/omelette.svg"
                        alt="Omelette"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p>Order delicious food from home chefs</p>
                  <button className="btn" onClick={this.getDiner}>
                    register as a diner
                  </button>
                </div>
              </div>
              <div className="col-lg-6 text-center">
                <div className="box two">
                  <h6 className="text-uppercase">are you a home chef?</h6>
                  <div className="mediem_circle_loc">
                    <div className="inner">
                      <img
                        src="/assets/images/icons/chef.svg"
                        alt="Chef"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p>Sell your home cooked meals on Chef Pin</p>
                  <button className="btn" onClick={this.getChef}>
                    REGISTER AS A HOME CHEF
                  </button>
                </div>
              </div>
            </div>
            <p className="orange_link">
              Already have an account?{" "}
              <Link to={"/diner-signin-with-otp"}>Log In</Link>
            </p>
          </div>
        </section>
        <div className="nofooter">
          <WhatsAppButton />
        </div>
      </div>
    );
  }
}

export default UserSelectionPage;
