/*
#############################################################################
# Name : dinerSignUpMobile.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to enter the mobile number of diner
#############################################################################
*/
import React from 'react';
import DinnerHeader from '../../../components/dinnerHeader';
import WhatsAppButton from "../../../components/whatsappButton";
// React Notification
import config from "../../../config/config";
import Recaptcha from 'react-google-invisible-recaptcha';
import { connect } from 'react-redux';
import { saveUserData } from '../../../redux/actions/authActions';
import { Link } from 'react-router-dom'

const numberReg = /^[0]?[789]\d{9}$/;
const formValid = ({ formErrors, ...rest }) => {
    let valid = true;

    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

    Object.values(rest).forEach(val => {
        val === "form-control" && (valid = false)
    })

    return valid;
}

class DinerSignUpMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNumber: "",
            hideContent: window.matchMedia("(max-width: 991px)").matches,
            formErrors: {
                mobileNumber: "",
            }
        }
        this.handleMobileChange = this.handleMobileChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    /* handleMobileChange function starts here */
    handleMobileChange = e => {
        const { name, value } = e.target;
        let formErrors = this.state.formErrors;
        switch (name) {
            case 'mobileNumber':
                formErrors.mobileNumber = value === "" ? 'Mobile Number is required.' : !numberReg.test(e.target.value) ? "Enter valid Mobile Number." : "";
                break;
        }
        this.setState({ formErrors, [name]: value });
    }
    /* handleMobileChange function ends here */

    /* submitContactForm function starts here */
    submitContactForm = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let formErrors = this.state.formErrors;
        if (this.state.mobileNumber === "") {
            formErrors.mobileNumber = "Mobile Number is required";
        }
        this.setState({ formErrors, [name]: value });
        if (formValid(this.state)) {
            localStorage.setItem("userMobile", this.state.mobileNumber);
            if (this.props.location.search === "?checkout") {
                this
                    .props
                    .history
                    .push('/diner-signup?checkout');
            } else if(this.props.location.search === "?chef-innerpage"){
                this
                    .props
                    .history
                    .push('/diner-signup?chef-innerpage');
            }else {
                this
                    .props
                    .history
                    .push('/diner-signup');
            }
        } else {
            console.log('error');
        }

    }
    /* submitContactForm function ends here */

    /* onResolved function starts here */
    onResolved() {
        alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
    }
    /* onResolved function ends here */


    render() {
        const { formErrors } = this.state;
        return (
            <React.Fragment>
                <div className="css2">
                    <DinnerHeader />
                    <section className="sign_up_chef verification log_otp sign_chefdiner">
                        <Link to="/user-selection" className="back_btn">
                            <img src="/assets/images/icons/black-arrow.svg" alt="Black Arrow" />Back
                        </Link>
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-lg-6 col-xl-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                                    <div className="text pt-0">
                                        <h3 className="color_C07">REGISTER AS A DINER</h3>
                                        <div className="profile_img">
                                            <div className="inner">
                                                <img
                                                    src="/assets/images/icons/Flat.svg"
                                                    alt="Profile Images"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_wrap">
                                            <form>
                                                <div className="form-group">
                                                    <div className="mobile_no_wrap">
                                                        <div className="form-control countrycode">
                                                            <span className="code">+91</span>
                                                        </div>
                                                        <input
                                                            type="tel"
                                                            className="form-control tel_number"
                                                            placeholder="Mobile Number*"
                                                            name="mobileNumber"
                                                            value={this.state.mobileNumber}
                                                            onChange={this.handleMobileChange}
                                                            maxLength="10" />
                                                    </div>
                                                    <small className="text-danger text-left d-block">{formErrors.mobileNumber}</small>
                                                    <small id="PasswordHelp" className="form-text text-muted">Enter your 10 digit mobile number.</small>
                                                </div>
                                                <div className="form-group mb-0">
                                                    <button type="submit" className="btn" onClick={this.submitContactForm}>PROCEED</button>
                                                </div>
                                                <Recaptcha
                                                    ref={(ref) => (this.recaptcha = ref)}
                                                    sitekey={config.siteKey}
                                                    onResolved={this.onResolved}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 d-lg-flex align-items-center align-items-xl-center pt-lg-5 pt-xl-0 d-none">
                                    <div className="img_box">
                                        <img
                                            src="/assets/images/sign-up-as-a-chef/sign-up-img.png"
                                            alt="sign up img"
                                            className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="nofooter">
          <WhatsAppButton />
        </div>
                </div>
            </React.Fragment>

        );
    }
}

export default DinerSignUpMobile;