import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { isAndroid } from 'react-device-detect';

export default function DeepLinking({ setRedirect }) {
  const myRefname = useRef(null);
  useEffect(() => {
    myRefname.current.click();
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 'auto'
      }}
    >
      <button className='btn' onClick={() => setRedirect(false)}>
        Continue on Browser
      </button>
      <button
        className='btn'
        onClick={() => {
          window.location.href = `chefpin:/${window.location.href.substring(
            window.location.href.split('/', 3).join('/').length
          )}`;
        }}
        ref={myRefname}
        style={{
          marginTop: '2rem'
        }}
      >
        Go To App
      </button>
      <p style={{ textAlign: 'center', marginTop: '2rem', fontWeight: 'bold' }}>
        OR
      </p>
      <img
        onClick={() =>
          (window.location.href = isAndroid
            ? 'https://play.google.com/store/apps/details?id=com.chefpin'
            : 'https://apps.apple.com/us/app/chef-pin-home-food-delivery/id1579584069')
        }
        style={{
          width: isAndroid ? '50%' : '75%',
          objectFit: 'contain',
          alignSelf: 'center'
        }}
        src={`/assets/images/${
          isAndroid ? 'google-play' : 'app-store'
        }-badge.png`}
      />
    </div>
  );
}
