import React from "react";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import LoadingDiv from "../../components/LoadingDiv";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import { GetPageDetail } from "../../backendUI/Page/config";
import { URLs } from "../../backendUI/Url";

class ArticleData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      isLoading: true,
      articleTag: "",
      articleTitle: "",
      articleContent: "",
      titlesWithChefs: [],
    };
  }

  async componentDidMount() {
    await this.getArticleData();
    await this.getArticalDetails();
  }

  getArticalDetails = async () => {
    try {
      const response = await GetPageDetail(this.props.match.params.slug);
      if (response.success) {
        const { description: editorData } = response.data;
        const blocksFromHtml = htmlToDraft(editorData);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);

        this.setState({
          articleTag: response.data.name,
          articleTitle: response.data.title,
          editorState: editorState,
          isLoading: false,
        });
      }
    } catch (error) {
      console.error("Error fetching article details:", error);
      this.setState({ isLoading: false });
    }
  };

  getArticleData = async () => {
    try {
      const response = await articleDetailAPI(this.props.match.params.slug);
      const fetchResponse = await fetch(`https://www.chefp.in/api/page-detail/${this.props.match.params.slug}`);
      const result = await fetchResponse.json();

      const { description: editorData, chefs } = result.data;
      const blocksFromHtml = htmlToDraft(editorData);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);

      this.setState({
        articleTag: result.data.name,
        articleTitle: result.data.title,
        editorState: editorState,
        isLoading: false,
        titlesWithChefs: result.data.titlesWithChefs || [],

      });
    } catch (error) {
      console.error("Error fetching article data:", error);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { editorState, isLoading, articleTag, articleTitle, titlesWithChefs } = this.state;

    return (
      <div className="css2">
        <header>
          <div className="container menu_wrapper">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
              </a>
            </div>
            <div id="nav-icon" className="d-block d-xl-none">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="dinner_menu">
              <li className="active">
                <a href="/" className="login_btn">Home</a>
              </li>
              <li>
                <a href="/diner-aboutus" className="login_btn">About Us</a>
              </li>
              <li>
                <a href="https://wa.me/919899622242" target="_blank" className="login_btn">Contact Us</a>
              </li>
              <li>
                <a href="/pick-city" className="login_btn">Pick A City</a>
              </li>
              <li>
                <a href="https://www.chefp.in/blog/" className="login_btn">Blog</a>
              </li>
              <li className="d-block d-xl-none last_btn">
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <ul className="dinner_menu d-none d-xl-block">
              <li>
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <div className="menu_overlay"></div>
          </div>
        </header>

        {isLoading ? (
          <LoadingDiv />
        ) : (
          <section className="love_cook py-5 article_section">
            <div className="container">
              <span className="text-center">
                <h2 className="text-uppercase color_C07 mb-2 mb-md-3 top_title">{articleTag}</h2>
                <h1 className="color_a05">{articleTitle}</h1>
              </span>
              <Editor toolbarHidden editorState={editorState} readOnly />
            </div>
            <hr />

            {titlesWithChefs && titlesWithChefs.length > 0 && (
              <>


                <section>
                  <div className="container pt-5">
                    {titlesWithChefs.map((titleWithChefs) => (
                      <>
                        <div key={titleWithChefs._id} className="pt-4">
                          {/* <h3 className="text-center" style={{ color: '#f07c07' }}>{titleWithChefs.title}</h3> */}

                          <h2 style={{ color: '#f07c07', textAlign: 'center' }} className="text-center color_a05">
                            {titleWithChefs.title}
                          </h2>
                          <br></br>

                          <div className="row">
                            {titleWithChefs.chefs.map((chef) => (
                              chef.title && (
                                <div key={chef._id} className="col-md-3 media-block text-center" style={{ marginBottom: 10 }}>
                                  <a href={chef.link} target="_blank" rel="noopener noreferrer">
                                    <div className="media-content">
                                      <div className="media-image">
                                        <img className="img-fluid rounded" src={`${URLs.images}${chef.image}`} alt={chef.title} />
                                      </div>
                                      <p className="media-title">{chef.title}</p>
                                      <p className="media-middle-title">{chef.features}</p>
                                      <p className="media-description">{chef.desc}</p>
                                    </div>
                                  </a>
                                </div>
                              )
                            ))}
                          </div>
                        </div>
                        <center>
                          <a href={titleWithChefs.viewAll} target="_blank" rel="noopener noreferrer">
                            <p className="media-title">View All</p>
                          </a>
                        </center>
                      </>
                    ))}

                  </div>
                </section>
              </>
            )}


            <section class="faq_sec diner_faq">
              <div class="container">
                <h1 class="text-center res_back_btn_wrap">
                  FREQUENTLY ASKED QUESTIONS
                </h1>
                <div id="accordion" class="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="title"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Do I have to order in advance?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseOne"
                      class="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          That is correct. Since Chef Pin is a platform for home
                          chefs, we should all respect that their dishes are not
                          kept ready in bulk, like in the case of restaurants.
                          Advance ordering allows the home chefs to cook fresh
                          food for you.
                        </p>
                        <p>
                          Our system makes sure that all orders are placed at
                          least 1 day prior to delivery. At the time of placing
                          your order you will be able to select your delivery
                          slot for the coming days.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Is there any minimum value to place an order on Chef
                          Pin?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          The minimum value will be displayed for each home chef
                          on their respective pages. You may please refer to
                          that when deciding to order from that home chef.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Are there any delivery charges?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Yes, the delivery charges will be transparently shown
                          to you at the time of placing your order. The delivery
                          amount will be added to your order before payment.
                          Chef Pin uses only the most reputed delivery partners
                          in each city, and the delivery charges will depend on
                          your city and the distance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Can I change the delivery address after the order has
                          been placed?<span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Once the order has been placed we will not be able to
                          accept a request to change the delivery address
                          completely. You might be able to make minor changes
                          (e.g. flat/floor number) by speaking to the delivery
                          executive.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFive">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          The package delivered to me has been damaged and/or
                          tampered with. What can I do about this?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          We make sure we use only the most reputed delivery
                          companies, but it is possible that mishaps can happen
                          from time to time. In case the package delivered to
                          you has been damaged and/or tampered with you will be
                          entitled to a partial/full refund. Please see our{" "}
                          <a href="/diner-terms-of-use" class="link">
                            Refund Policy
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSix">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          I want to speak to the home chef before placing the
                          order. How can I get their phone number?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          As per policy we do not share the personal contact
                          details of home chefs on the platform. If you have any
                          special requests or customizations you can enter those
                          at the time of placing your order. Otherwise you can
                          write in to{" "}
                          <a href="mailto:info@chefp.in" class="link">
                            info@chefp.in
                          </a>{" "}
                          and we can coordinate with the home chef for you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          I have some special requests. Is it possible to let
                          the home chef know about these?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Absolutely. One of the main advantages of ordering
                          from home chefs is that you can customize your food.
                          Before you place your order, you will have the option
                          of entering Special Requests which will be
                          communicated to the home chef.
                        </p>
                        <p>
                          But please note that in case your special
                          request/customization cannot be accommodated by the
                          home chef they may have to reject your order.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingEight">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          How do I make sure that the food matches my dietary
                          requirements?<span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseEight"
                      class="collapse"
                      aria-labelledby="headingEight"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          At Chef Pin we take dietary requirements very
                          seriously. That is why you will see that under each
                          dish we have asked the home chef to mention the major
                          allergens such as eggs, gluten, lactose etc. We have
                          also asked the home chefs to identify if their
                          kitchens are suitable for certain customs such as
                          Jain-vegetarian cooking. We hope that these indicators
                          and filters will help you find your perfect home chef
                          on Chef Pin!
                        </p>
                        <p>
                          In addition to this, you can also leave a Special
                          Request for the home chef at the time of placing your
                          order so that they are aware of any dietary
                          requirements that you have.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </section>
        )}
      </div>
    );
  }
}

export default ArticleData;
