import React, { useState, useRef, useEffect } from "react";
import "../BackendStyle.css";
import {
  HomeDescription,
  HomeDescriptionUpload,
  HomeDescriptionUpdate,
} from "./config";
import JoditEditor from "jodit-react";

function Header() {
  const editor = useRef(null);

  const [data, setdata] = useState([]);
  const [title, settitle] = useState();
  const [description, setdescription] = useState();
  const [image, setimage] = useState();
  const [updateimage, setupdateimage] = useState();
  const [UpdateId, setUpdateId] = useState();

  const [isLoading, setisLoading] = useState(true);
  const [show, setShow] = useState(true);
  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    const getgteanData = await HomeDescription();
    if (getgteanData.success == true) {
      setdata(getgteanData.data);
      setisLoading(false);
    }
  };
  const handlerUpdateData = async () => {
    const getgteanData = await HomeDescriptionUpdate(UpdateId, description);
    if (getgteanData.success == true) {
      handlerData();
      setdescription();
      setShow(true);
    }
  };
  const handlerUploadData = async () => {
    const getgteanData = await HomeDescriptionUpload(description);
    if (getgteanData.success == true) {
      handlerData();
      setdescription();
      setShow(true);
    }
  };
  const UpdateItem = (item) => {
    setdescription(item.description);
    setUpdateId(item._id);
    setShow("update");
  };
  console.log(description);
  // var edi = new Jodit("#contentfield", {
  //   textIcons: false,
  //   iframe: false,
  //   height: 300,
  // });
  return (
    <>
      {show == "update" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Description</label>
              <JoditEditor
                ref={editor}
                value={description}
                tabIndex={10}
                config={{
                  readonly: false, // all options from https://xdsoft.net/jodit/doc/,
                  placeholder: "Start typings...",
                  height: 300,
                }}
                // tabIndex={1} // tabIndex of textarea
                // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => setdescription(newContent)}
              />
              {/* <textarea
                class="form-control"
                rows="7"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></textarea> */}
            </div>

            <button
              onClick={() => {
                handlerUpdateData();
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : show == "add" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Description</label>
              <JoditEditor
                ref={editor}
                value={description}
                style={{ height: 500 }}
                config={{
                  readonly: false, // all options from https://xdsoft.net/jodit/doc/,
                  placeholder: "Start typings...",
                  height: 300,
                }}
                // tabIndex={1} // tabIndex of textarea
                // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => setdescription(newContent)}
              />
              {/* <textarea
                class="form-control"
                rows="7"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></textarea> */}
            </div>

            <button
              onClick={() => {
                handlerUploadData();
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className="row table-invoice">
            <div className="col-sm-12">
              <div className="table-in-card">
                <div class="d-flex justify-content-between">
                  <h3>Header Text</h3>
                  {data.length == 0 ? (
                    <button
                      type="button"
                      onClick={() => setShow("add")}
                      class="btn btn-primary btn-xs"
                    >
                      <small>Add</small>
                    </button>
                  ) : null}
                </div>
                <br />
                {/* <hr /> */}
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length == 0
                        ? null
                        : data.map((item) => {
                            return (
                              <tr>
                                <td>{item.description}</td>

                                <td>
                                  <div class="d-flex justify-content-between">
                                    <button
                                      type="button"
                                      onClick={() => UpdateItem(item)}
                                      class="btnnnnn btn-primary"
                                      style={{ marginRight: 10 }}
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>
                                    {/* <button
                                  type="button"
                                  // onClick={() =>
                                  //   DeleteHandler(item._id, item.image)
                                  // }
                                  class="btnnnnn btn-danger"
                                >
                                  <i class="fa fa-trash"></i>
                                </button> */}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
