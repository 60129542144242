/*
#############################################################################
# Name : pickCity.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to edit the chef profile details
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import WhatsAppButton from "../../components/whatsappButton";
import { Link } from "react-router-dom";
import {
  cityAPI,
  getAddressListAPI,
} from "../../core/services/Phase2APIservice";
import { connect } from "react-redux";
import {
  increaseQuantity,
  decreaseQuantity,
  emptyCart,
} from "../../redux/actions/cartActions";
import {Helmet} from "react-helmet";
import config from "../../config/config";

class PickCityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showcontent: false,
      citySelected:"",
      userID: "",
      cityArr: [],
      homeLocationsArr: [],
      workLocationsArr: [],
      otherLocationsArr: [],
      showAddress: false,
      selectedCity: "",
      selectedCityImg: "",
      displayCartEmptyPopup: false,
      removeCartBtnText: "Yes, continue",
      selectedAddress: {
        flatNo: "",
        landmark: "",
        addressSelected: "",
        addressType: "",
        pincode: "",
        latitude: "",
        longitude: "",
        selectedLocation: "",
        otherType: ""
      },
      setType: ""
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var userId = "";
    userId = localStorage.getItem("userID");
    localStorage.setItem("closePopup", true);
    this.getCity();                           // City data is fetched 
    if(localStorage.getItem("userID")){
      this.getLocationList();                 // Location list data is fetched
    }
    if(localStorage.getItem("selectedLocation")){
      var choosenCity = config.slugify(localStorage.getItem("selectedLocation"));
      this.setState({
        citySelected: "/"+choosenCity+"/diner-homepage"
      })
    }
  }

  /* getCity function starts here */
  getCity = () => {
    return cityAPI()
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ cityArr: response.data.data });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* getCity function ends here */

  /* getLocationList function starts here */
  getLocationList = () => {
    var token = localStorage.getItem("accessToken");
    return getAddressListAPI(localStorage.getItem("userID"), token)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            homeLocationsArr: response.data.data.home_location,
            workLocationsArr: response.data.data.work_location,
            otherLocationsArr: response.data.data.other_location,
          });
          if (
            response.data.data.home_location.length === 0 &&
            response.data.data.other_location.length === 0 &&
            response.data.data.work_location.length === 0
          ) {
            this.setState({ showAddress: false });
          } else {
            this.setState({ showAddress: true });
          }
        }
      })
      .catch((error) => {
       /*  console.log(error); */
      });
  };
  /* getLocationList function ends here */

  /* getLocation function starts here */
  getLocation = (name, image) => {
    if (this.props.location.search === "?add-address") {
      localStorage.setItem("selectedLocationImage", image);
      localStorage.setItem("tempSelectedLocation", name);
      localStorage.setItem("tempSelectedLocationName", name);
      this.props.history.push("/get-location?add-address");
    } else if (this.props.location.search === "?chef-innerpage") {
      localStorage.setItem("selectedLocationImage", image);
      localStorage.setItem("tempSelectedLocation", name);
      localStorage.setItem("tempSelectedLocationName", name);
      this.props.history.push("/get-location?chef-innerpage");
    } 
    else {
      if (this.props.cartItems.length && name !== localStorage.getItem('selectedLocationName')) {
        this.setState({
          displayCartEmptyPopup: true,
          selectedCity: name,
          selectedCityImg: image,
          setType: "city"
        })
      } else {
        localStorage.setItem("selectedLocationImage", image);
        localStorage.setItem("tempSelectedLocation", name);
        localStorage.setItem("tempSelectedLocationName", name);
        this.props.history.push("/get-location");
      }
    }
  };
  /* getLocation function ends here */

  /* handleClearCart function starts here */
  handleClearCart = () => {
    if (this.state.setType === "city") {
      localStorage.setItem("selectedLocationImage", this.state.selectedCityImg);
      localStorage.setItem("tempSelectedLocation", this.state.selectedCity);
      localStorage.setItem("tempSelectedLocationName", this.state.selectedCity);
      this.props.history.push("/get-location");
      this.props.emptyCart()
    } else {
      /* console.log(this.state.selectedAddress.fullAddress) */
      localStorage.setItem("flatNo", this.state.selectedAddress.flatNo);
      localStorage.setItem("landmark", this.state.selectedAddress.landmark);
      localStorage.setItem("addressSelected", this.state.selectedAddress.addressSelected);
      localStorage.setItem("addressType", this.state.selectedAddress.addressType);
      localStorage.setItem("pincode", this.state.selectedAddress.pincode);
      localStorage.setItem("latitude", this.state.selectedAddress.latitude);
      localStorage.setItem("longitude", this.state.selectedAddress.longitude);
      localStorage.setItem("selectedLocation", this.state.selectedAddress.selectedLocation);
      localStorage.setItem("selectedLocationName", this.state.selectedAddress.selectedLocation);
      if (this.state.selectedAddress.addressType == "Other") {
        localStorage.setItem("addressDetailType", this.state.selectedAddress.otherType);
      }
      var city = config.slugify(this.state.selectedAddress.selectedLocation);
      this.props.history.push("/"+city+"/diner-homepage");
      this.props.emptyCart()
    }
  }
  /* handleClearCart function ends here */

  /* capitalizeFirstLetter function starts here */
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  /* capitalizeFirstLetter function ends here */

  /* setLocation function starts here */
  setLocation = (
    fullAddress,
    landmark,
    city,
    lat,
    long,
    address,
    otherType,
    pincode,
    type
  ) => {
    /* console.log(fullAddress,
      landmark,
      city,
      lat,
      long,
      address,
      otherType,
      pincode,
      type) */
    if (this.props.cartItems.length && city !== localStorage.getItem('selectedLocationName')) {
      this.setState({
        displayCartEmptyPopup: true,
        selectedAddress: {
          flatNo: fullAddress,
          landmark: landmark,
          addressSelected: address,
          addressType: this.capitalizeFirstLetter(type),
          pincode: pincode,
          latitude: lat,
          longitude: long,
          selectedLocation: city,
          otherType: otherType
        },
        setType: "address"
      }, () => {
        /* console.log(this.state.selectedAddress) */
      })
    }
    else {
      localStorage.setItem("flatNo", fullAddress);
      localStorage.setItem("landmark", landmark);
      localStorage.setItem("addressSelected", address);
      localStorage.setItem("addressType", this.capitalizeFirstLetter(type));
      localStorage.setItem("pincode", pincode);
      localStorage.setItem("latitude", lat);
      localStorage.setItem("longitude", long);
      localStorage.setItem("selectedLocation", city);
      localStorage.setItem("selectedLocationName", city);
      if (otherType) {
        localStorage.setItem("addressDetailType", otherType);
      }else{
        localStorage.removeItem("addressDetailType");
      }
      var citySaved = config.slugify(city);
      this.props.history.push("/"+citySaved+"/diner-homepage");
    }
    // window.location.reload();
  };
   /* setLocation function ends here */


  render() {
    return (
      <div className="css2">
        <Helmet>
          <title>Pick a City</title>
            <meta name="title" content="Pick a City" />
            <meta name="description" content="Select your preferred location for the fresh and faster delivery of your favourite home cooked food."/>
            <meta name="keywords" content = "home made food services delhi ncr, home made food services bengaluru, home made food services mumbai, home made food services chennai, home made food services kolkata" />
        </Helmet>
        <DinnerHeader />
        <section className="homepage">
          {localStorage.getItem("userID") == null &&
            localStorage.getItem("addressSelected") == null ? (
            <Link to={"/"} className="back_btn">
              <img
                src="/assets/images/icons/black-arrow.svg"
                alt="Black Arrow"
              />
              Back
            </Link>
          ) : (
           this.props.location.search === "?chef-innerpage" || localStorage.getItem("userID") != null && localStorage.getItem("addressSelected") == null ? null : <Link to={this.props.location.search === "?checkout" ? "/checkout" : this.state.city} className="back_btn">
              <img
                src="/assets/images/icons/black-arrow.svg"
                alt="Black Arrow"
              />
              Back
            </Link>
          )}
          <div className="container">
            <h2 className="title">PICK A CITY</h2>
            <ul className="pick_city_list">
              {this.state.cityArr
                ? this.state.cityArr.map((city, i) => {
                  return (
                    <li
                      className={
                        city.is_published == "1"
                          ? ""
                          : "pick_city_list_published"
                      }
                      onClick={
                        city.is_published == "1"
                          ? (e) =>
                            this.getLocation(city.name, city.city_image)
                          : null
                      }
                      key={'bvcdrty' + i}
                    >
                      <div className="img">
                        <div className="inner">
                          <img
                            src={city.city_image}
                            alt={city.name}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="text">{city.name}</div>
                    </li>
                  );
                })
                : null}
            </ul>
            {this.state.showAddress ? <hr /> : null}
            {this.state.showAddress ? (
              <div className="container">
                <h6 className="save_add_title">Saved Addresses</h6>
                <div className="add_wrap">
                  {this.state.homeLocationsArr.map((home, h) => {
                    return (
                      <div
                        className=" address_change home_add"
                        key={'hytrdfh' + h}
                        onClick={(e) =>
                          this.setLocation(
                            home.address_line1,
                            home.address_line2,
                            home.city,
                            home.latitude,
                            home.longitude,
                            home.location,
                            home.location_title,
                            home.pincode,
                            home.type
                          )
                        }
                      >
                        <h6>Home</h6>
                        <p>{home.address_line2 ? home.address_line1 + ', ' + home.address_line2 + ', ' + home.location : home.address_line1 ? home.address_line1 + ', ' + home.location : home.location}</p>
                      </div>
                    );
                  })}
                  {this.state.workLocationsArr.map((work, w) => {
                    return (
                      <div
                        className=" address_change portfolio_add"
                        key={'yert' + w}
                        onClick={(e) =>
                          this.setLocation(
                            work.address_line1,
                            work.address_line2,
                            work.city,
                            work.latitude,
                            work.longitude,
                            work.location,
                            work.location_title,
                            work.pincode,
                            work.type
                          )
                        }
                      >
                        <h6>Work</h6>
                        <p>{work.address_line2 ? work.address_line1 + ', ' + work.address_line2 + ', ' + work.location : work.address_line1 ? work.address_line1 + ', ' + work.location : work.location}</p>
                      </div>
                    );
                  })}
                  {this.state.otherLocationsArr.map((other, o) => {
                    return (
                      <div
                        className=" address_change other_add"
                        key={'kuytr' + o}
                        onClick={(e) =>
                          this.setLocation(
                            other.address_line1,
                            other.address_line2,
                            other.city,
                            other.latitude,
                            other.longitude,
                            other.location,
                            other.location_title,
                            other.pincode,
                            other.type
                          )
                        }
                      >
                        {other.location_title == "" ||
                          other.location_title == "null" ? (
                          <h6>Other</h6>
                        ) : (
                          <h6>{other.location_title}</h6>
                        )}
                        <p>{other.address_line2 ? other.address_line1 + ', ' + other.address_line2 + ', ' + other.location : other.address_line1 ? other.address_line1 + ', ' + other.location : other.location}</p>
                      </div>
                    );
                  })}
                  {/* <div className=" address_change place_add">
                                    <h6>Dad's Place</h6>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
                                </div> */}
                </div>
              </div>
            ) : null}
            {localStorage.getItem("userID") == null ? (
              <div className="bottom_box d-flex flex-column justify-content-center align-items-center">
                <span className="d-block">Or</span>
                <Link to={"/diner-signin-with-otp"} className="btn">
                  Log in
                </Link>
                <p className="orange_link">
                  New to Chef Pin?{" "}
                  <Link to={"/user-selection"} className="color_C07">
                    {" "}
                    Create an Account
                  </Link>
                </p>
              </div>
            ) : null}
          </div>
        </section>
        <div className="nofooter">
          <WhatsAppButton />
        </div>

        {this.state.displayCartEmptyPopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          displayCartEmptyPopup: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <h6>Empty cart?</h6>
                    <p>
                      Your cart already contains dishes from a Home Chef.
                      Proceeding further will discard the previously added
                      items.
                    </p>
                    <div className="btn-group">
                      <div>
                        <button
                          id="closeMenuFormButton2"
                          className="btn previous_btn"
                          onClick={() =>
                            this.setState({
                              displayCartEmptyPopup: false,
                              selectedCity: "",
                              selectedCityImg: ""
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button className="btn" onClick={this.handleClearCart}>
                          {this.state.removeCartBtnText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.displayCartEmptyPopup && (
          <div className="modal-backdrop fade show"></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  timeSlot: state.timeSlot.items,
  chefData: state.chef.items,
  userData: state.user.items,
});

export default connect(mapStateToProps, { emptyCart })(
  PickCityPage
);