/*
#############################################################################
# Name : userAgree.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the user agreement data
#############################################################################
*/
import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  getUserSummaryAPI,
  termsAndConditionAPI,
} from "../../core/services/APIservice";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";

class UserAgreePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checboxAgreeValue: localStorage.getItem("mouSubmitted") == 'true' ? true : false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var userId = "";
    userId = localStorage.getItem("userID");
    if (localStorage.getItem("userID") === null) {
      this.props.history.push("/");
    }
  }

  /* handleInputChange function starts here */
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
 /* handleInputChange function ends here */

  /* getThankYou function starts here */
  getThankYou = (event) => {
    event.preventDefault();
    this.recaptcha.execute();
    if (this.state.checboxAgreeValue === false) {
      NotificationManager.error("Please Accept the Agreement", "Error!");
      this.recaptcha.reset();
    } else if(localStorage.getItem("mouSubmitted") == 'true'){
		localStorage.setItem("currentStatus", 7);
	}else {
      //this.props.history.push("/thank-you");
	  //localStorage.setItem("currentStatus", 7);
	  localStorage.setItem("mouSubmitted", true);
	  this.state = {
		  checboxAgreeValue: true,
		};
      this.getTermsAccepted();
	  //window.location.reload();
	  //this.getSummary(); 
    }
  };
/* getThankYou function ends here */

/* getSummary function starts here */  
    getSummary() {
        var userId = localStorage.getItem('userID');
        var accessToken = localStorage.getItem('accessToken');
        return getUserSummaryAPI(userId, accessToken).then((response) => {
            if (response.data['status'] === 200) {
                this.setState({
                    userAboutYou: response.data['data']['about_average'], userBusiness: response.data['data']['business_average'], userKitchen: response.data['data']['kitchen_average'], userMenu: response.data['data']['number_of_uploaded_menu'], userAboutYouFlag: response.data['data']['about_you_req_filled'], userBusinessFlag: response.data['data']['about_business_req_filled'], userKitchenFlag: response.data['data']['about_kitchen_req_filled'], regPackage: response.data['data']['registration_package'], marPackage: response.data['data']['marketing_package'], mouSubmitted: response.data['data']['is_mou_submitted'], isProfileSubmitted: response.data['data']['profile_submitted_at']
                })
            } else {
                if (response.status === 401) {
                    NotificationManager.error("Session expired. Please log in.", "Error!");
                    this.props.history.push('/signin-with-otp');
                    localStorage.clear();
                }
            }
        }).catch((error) => {
        })
    }
/* getSummary function ends here */

/* getTermsAccepted function starts here */
  getTermsAccepted() {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return termsAndConditionAPI(userId, accessToken)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(response.data["message"], "Successfull!");
          var verified = response.data["data"]["profile_verified"];
          localStorage.setItem("userVerified", verified);
		  window.location.reload();
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {});
  }
/* getTermsAccepted function ends here */

/* logoutHandler function starts here */
  logoutHandler = (e) => {
    localStorage.clear();
    NotificationManager.success(
      "You have Logout Successfully",
      "Successful!",
      3000
    );
  };
  /* logoutHandler function ends here */

  /* onPreviousBusinessSubmit function starts here */
  onPreviousBusinessSubmit = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getUserSummaryAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.props.parentCallback(response.data["data"]);
          localStorage.setItem("currentStatus", 5);
        }
      })
      .catch((error) => {});
  };
 /* onPreviousBusinessSubmit function ends here */

  /* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved function ends here */

  render() {
    return (
      <div className="registration bussiness submit_sec">
        <div className="abt_form">
          <h4 className="d-flex justify-content-between align-items-center">
            <button
              className="save_btn back_btn"
              onClick={this.onPreviousBusinessSubmit}
            >
              <img
                src="assets/images/icons/left-arrow.png"
                alt="Left Arrow Icon"
                className="img-fluid"
              />
              Back
            </button>
            <span className="text-uppercase text-center text-md-left">
              CHEF PIN - Memorandum Of Understanding
            </span>
            <button className="save_btn disabled">Save</button>
          </h4>
          <p className="sub_title">
            This Memorandum of Understanding (“MOU”) is binding in nature on both parties and the clauses with the headings “Confidential Information”, “Intellectual Property Rights” and “Dispute Resolution” shall survive its termination.
          </p>
          <div className="inner tandc">
            <div className="upload_text faq_sec diner_faq">
              <div id="accordion" className="accordion">
				  <div className="card">
					<div className="card-header" id="headingOne">
					  <h5 className="mb-0">
						<button
						  className="title"
						  data-toggle="collapse"
						  data-target="#collapseOne"
						  aria-expanded="false"
						  aria-controls="collapseOne"
						>
						  Parties
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseOne"
					  className="collapse"
					  aria-labelledby="headingOne"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
							Chef Pin Private Limited, a company registered under the Companies Act, 2013 and having its registered office at Suite 611, Global Foyer, Gold Course Road, Gurugram, Haryana, India 122002 (“Chef Pin”) and the Home Chef /Merchant.<br/><br/>
							Chef Pin and home chefs, home cooks and home bakers are herein referred to individually as “Party” and collectively as “Parties”.<br/><br/>
							Chef Pin enables transactions between home chefs (also called merchants) and buyers, dealing in prepared food and drinks and beverages (“Platform Services”). The buyer can choose and place orders (“Orders”) for a variety of products listed and offered for sale by various merchants on the Platform and Chef Pin enables delivery of such orders at select localities of serviceable cities across India through third party delivery service providers (“Delivery Services”). The Platform Services and Delivery Services are collectively referred to as “Services”.
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingTwo">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseTwo"
						  aria-expanded="false"
						  aria-controls="collapseTwo"
						>
						  Objective
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseTwo"
					  className="collapse"
					  aria-labelledby="headingTwo"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  This MOU sets out the broad guidelines and parameters upon which the Merchant agrees sell food and beverage items to buyers on the Chef Pin platform. The parties agree to fulfill their obligations as specified in Clause 4 below.
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingThree">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseThree"
						  aria-expanded="false"
						  aria-controls="collapseThree"
						>
						  Term
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseThree"
					  className="collapse"
					  aria-labelledby="headingThree"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
							This MOU shall become effective upon acceptance of the terms by the Merchant, and will remain in effect until terminated by either Party. Chef Pin reserves the right to terminate this MOU by giving 15 days’ notice without assigning any reason. In such a scenario there will be proportionate refund/partial refund of the listing fees paid by the Merchant to Chef Pin.<br/><br/>
							Chef Pin may terminate this Agreement with immediate effect by a notice of termination to the Merchant, if (a) the Merchant fails to perform, has made, or makes any inaccuracy in, or otherwise breaches any of the obligations, covenants, or representations, and/or (b) the failure, inaccuracy, or breach continues for a period of 2 days from the date of notice. In such a scenario there will be no refund of the listing fees paid by the Merchant to Chef Pin.
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingFour">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseFour"
						  aria-expanded="false"
						  aria-controls="collapseFour"
						>
						 Obligations of the Parties
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseFour"
					  className="collapse"
					  aria-labelledby="headingFour"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<h6>Obligations of the Merchant</h6><br/>
						<ul class="list"><li>
						  Merchants are responsible for making comprehensive profiles on Chef Pin, providing detailed menus, quantities, pricing and all other specifics like preferred mode of delivery, desired advance notice period for orders and/or any other special services provided. Chef Pin will not be responsible for any discrepancies that may arise thereafter.
						</li>
						<li>
						  As a general rule, all food and drinks orders placed on the Platform are treated as confirmed. Once a Buyer places an order on Chef Pin, the Merchant must confirm the order, whereby the merchant commits to the details of said placed order. Chef Pin will not be liable for any discrepancies that may arise thereafter.
						</li>
						<li>
						  The Merchants are solely responsible for preparation and timely delivery of food and drinks (as per terms specified on their profile) as well as for maintaining quality and hygiene. Chef Pin will not be responsible or liable for the quantity or quality of meals delivered.
						</li>
						<li>
						  The Merchant will follow all hygiene and safety standards as laid down by the FSSAI. Chef Pin will monitor Merchants closely, through periodic audits, kitchen visits, using Buyer feedback provided in any form to Chef Pin and will remove and block Merchants if they are unable to comply with our standards and/or tarnish our reputation.
						</li>
						<li>
						  Merchants are obliged to add Chef Pin branding, if available, to all orders that have been forwarded to them by Chef Pin.
						</li>
						<li>
						  Merchants will follow the Code of Conduct for Home Chefs as laid down in the Terms of Use available on <a href="www.chefp.in">www.chefp.in</a>
						</li></ul><br/>
						<h6>Obligations of Chef Pin</h6><br/>
						<ul class="list">
						<li>
						  Chef Pin will provide the listing/membership services as per the Listing Plan selected by the Merchant.
						</li>
						<li>
						  Chef Pin is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Merchant and the Buyer. In case of complaints from the Buyer pertaining to food and drinks efficacy, quality, or any other such issues, Chef Pin shall notify the same to the Merchant and shall also redirect the Buyer to the consumer call center of the Merchant. The Merchant shall be liable & obliged to address Buyer complaints.
						</li>
						<li>
						  Chef Pin shall endeavor to promote the Merchants to all its users with no guarantee or assurance of any sales or orders to the Merchant. Merchants will provide materials including photos, menu items, profile descriptions, etc. and allow them to be used by Chef Pin for various purposes, including business development, promotions, etc. Chef Pin is not responsible for any liability that may arise from the misuse of the shared information, if any.
						</li>
						<li>
						  Merchants can request a copy of the personal information Chef Pin holds on them. 
						</li></ul>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingFive">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseFive"
						  aria-expanded="false"
						  aria-controls="collapseFive"
						>
						  Consideration
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseFive"
					  className="collapse"
					  aria-labelledby="headingFive"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<ul class="list">
						<li>
						  Merchants agree to pay a listing/membership fee for the selected Listing Plan as mentioned on the Chef Pin website. The fees plus applicable taxes is payable in advance. 
						</li>
						<li>
						  Merchants understand that all order placed through the platform will attract a contracted mark-up/commission/convenience fee payable to Chef Pin. This amount will be withheld, and any other dues will be deducted before settling any payments to the Merchants.
						</li>
						<li>
						  Merchant payments, after deducting any dues, are expected to be settled weekly.
						</li>
						<li>
							Chef Pin would facilitate the use of third-party delivery providers for Merchants to deliver the products to Buyers. These services would be charged to the Buyer, over and above the mark-up/commission/convenience fee payable to the platform. Chef Pin will not be responsible for any acts of omission and commission by the third-party delivery provider.
						</li>
						<li>
						  Tax liability, if any, arising out of this MOU shall be borne by the respective Parties as applicable.
						</li></ul>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingSix">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseSix"
						  aria-expanded="false"
						  aria-controls="collapseSix"
						>
						  Representations and Warranties
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseSix"
					  className="collapse"
					  aria-labelledby="headingSix"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<ul class="list">
						<li>
						  The Merchant hereby represents and warrants that they are in compliance with all applicable laws and regulations; that they conduct legitimate business activities and have all the necessary governmental approvals.
						</li>
						<li>
						  The Merchant hereby represents and warrants that they have read and agreed to the Terms of Use and Code of Conduct of Chef Pin and that they shall honour the same at all times. Any non-compliance shall be a material breach of this MOU and that Chef Pin reserves the right to terminate or suspend this MOU with immediate effect of the breach.
						</li>
						</ul>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingSeven">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseSeven"
						  aria-expanded="false"
						  aria-controls="collapseSeven"
						>
						 Indemnity
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseSeven"
					  className="collapse"
					  aria-labelledby="headingSeven"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  The Merchant shall defend, indemnify and hold harmless Chef Pin from any and all claims arising out or resulting from: (i) failure to fulfill obligations under the MOU (ii) misconduct, negligence or fraud or (iii) any action by a third party against Chef Pin in relation to any act or omission of the Merchant or employees of the Merchant.
						</p>
					  </div>
					</div>
				  </div>

				  <div className="card">
					<div className="card-header" id="headingEight">
					  <h5 className="mb-0">
						<button
						  className="title"
						  data-toggle="collapse"
						  data-target="#collapseEight"
						  aria-expanded="false"
						  aria-controls="collapseEight"
						>
						  Intellectual Property Rights
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseEight"
					  className="collapse"
					  aria-labelledby="headingEight"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  All intellectual property rights in the logos, brands and trademarks of the Parties used in furtherance of the MOU shall vest with the respective Parties. In the absence of express words to the contrary, nothing in the MOU shall be interpreted as giving either Party any rights in the other Party’s intellectual property rights. Both parties shall ensure that the logos, brands and trademarks are used only for the limited purpose of this MOU. 
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingNine">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseNine"
						  aria-expanded="false"
						  aria-controls="collapseNine"
						>
						  Relationship
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseNine"
					  className="collapse"
					  aria-labelledby="headingNine"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  The relationship between Parties shall be on principal-to-principal basis. Each Party is an independent contractor and is not a legal representative, partner or agent of the other Party. Neither Party shall have any right, power or authority, whether express or implied, to create any obligation on behalf of the other Party. 
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="headingTen">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapseTen"
						  aria-expanded="false"
						  aria-controls="collapseTen"
						>
						  Confidential Information
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapseTen"
					  className="collapse"
					  aria-labelledby="headingTen"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  Except as may be required by appropriate regulatory authorities or by law or any court of competent jurisdiction, no Party will disclose or use for any purpose other than in connection with the transactions contemplated herein any information disclosed or made available by the other Party without the consent of the other Party. 
						</p>
					  </div>
					</div>
				  </div>

				  <div className="card">
					<div className="card-header" id="heading11">
					  <h5 className="mb-0">
						<button
						  className="title"
						  data-toggle="collapse"
						  data-target="#collapse11"
						  aria-expanded="false"
						  aria-controls="collapse11"
						>
						  Limitation of Liability
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapse11"
					  className="collapse"
					  aria-labelledby="heading11"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  In no event shall Chef Pin be liable to the Merchant for any special, exemplary, indirect, incidental, consequential, punitive or for any damages arising out of or in connection with this MOU, whether or not Chef Pin has been advised of the possibility of such damages. Notwithstanding anything to the contrary, in no event shall Chef Pin’s liability exceed INR5,000/- 
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="heading12">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapse12"
						  aria-expanded="false"
						  aria-controls="collapse12"
						>
						  Dispute Resolution
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapse12"
					  className="collapse"
					  aria-labelledby="heading12"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  This MOU shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the Courts at New Delhi alone.
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="heading13">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapse13"
						  aria-expanded="false"
						  aria-controls="collapse13"
						>
						  General Term
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapse13"
					  className="collapse"
					  aria-labelledby="heading13"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						  <b>a. Force Majeure:</b> Chef Pin shall not be liable for any failure to perform its obligations under this MOU for reasons beyond its reasonable control. 
						</p>
						<p>
						  <b>b. Notices:</b> Any notice to be given by either Party may be served by email, personal service or by post to the addresses stated above. 
						</p>
						<p>
						  <b>c. Assignment:</b> The Merchant shall not have the right to assign this Agreement or any part herein without the prior written consent of Chef Pin. However, Chef Pin may assign this agreement or any part thereof, to any of its affiliate, subsidiaries or associate companies. 
						</p>
						<p>
						  <b>d. Modification:</b> No addition or modification of this MOU shall be effective or binding on either of the Parties hereto unless agreed in writing and executed by the respective duly authorized representatives of each of the Parties hereto. 
						</p>
						<p>
						  <b>e. Exclusivity:</b> Both the parties shall be free to enter into similar arrangements with other service providers for the provision of similar services. 
						</p>
						<p>
						  <b>f. Entire Agreement:</b> This MOU sets forth and shall constitute the entire agreement between the Merchant and Chef Pin with respect to the subject matter hereof and shall supersede any and all prior agreements, understandings, promises and representations made by one Party to the Other concerning the subject matter.
						</p>
					  </div>
					</div>
				  </div>
				  <div className="card">
					<div className="card-header" id="heading14">
					  <h5 className="mb-0">
						<button
						  className="title collapsed"
						  data-toggle="collapse"
						  data-target="#collapse14"
						  aria-expanded="false"
						  aria-controls="collapse14"
						>
						  Thank You
						  <span className="plus_icon"></span>
						</button>
					  </h5>
					</div>
					<div
					  id="collapse14"
					  className="collapse"
					  aria-labelledby="heading14"
					  data-parent="#accordion"
					>
					  <div className="card-body">
						<p>
						Thank you for filling out the registration form for CHEF PIN! We
						are preparing your listing for our new and exciting platform.
					  </p>
					  <p>
						By pressing the ‘Confirm and Submit’ button at the bottom of
						this page, you make a solemn promise to us, to your potential
						customers and to your business:{" "}
					  </p>
					  <ul className="list">
						<li>
						  That all the information provided by you is correct and
						  up-to-date.{" "}
						</li>
						<li>
						  That the information you provide about yourself, your kitchen
						  and your food to those who order via the CHEF PIN platform is
						  verifiable.{" "}
						</li>
						<li>
						  That you will maintain very high hygiene standards, use only
						  good quality ingredients and present only freshly prepared
						  food.{" "}
						</li>
						<li>
						  That your dishes will be homemade and you will personally be
						  involved in their preparation.{" "}
						</li>
					  </ul>
					  <p>
						You will be responsible for obtaining a FSSAI license and any
						other licenses or permissions that may be required for you to
						run a commercial venture from your home kitchen. The
						responsibility of coordinating with our delivery partners, to
						make sure that the food reaches your customer on time and in
						good condition, is also yours. In addition, you will allow CHEF
						PIN to organize your data and pictures to present your listing
						on the platform in a user-friendly and inviting manner.
					  </p>
					  <p className="fw_500 para_link">
						If you have any questions, please write to us on{" "}
						<a href="mailto:info@chefp.in">info@chefp.in</a>
					  </p>
					  <p className="fw_500 para_link">
						By clicking here you accept the{" "}
						<a href="/terms-of-use" target="_blank">
						  Terms and Conditions
						</a>{" "}
						of listing on CHEF PIN
					  </p>
					  </div>
					</div>
				  </div>
				</div>
              <label className="custome_check fw_500 mt-md-3 mb-md-3 mt-2 mb-2">
                I ACCEPT
                <input
                  name="checboxAgreeValue"
                  type="checkbox"
                  disabled={this.state.checboxAgreeValue}
                  checked={this.state.checboxAgreeValue}
                  onChange={this.handleInputChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100">
            <button
              type="submit"
              className="btn previous_btn"
              onClick={this.onPreviousBusinessSubmit}
            >
              PREVIOUS
            </button>
			{this.state.checboxAgreeValue == true ? (
				<button type="submit" className="btn" onClick={this.getThankYou}>Next</button>
			) : <button type="submit" className="btn" onClick={this.getThankYou}>Confirm & submit </button>}
            <Recaptcha
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={config.siteKey}
              onResolved={this.onResolved}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserAgreePage);
