/*
#############################################################################
# Name : dinerLoginWithPassword.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to check the entered credentails is valid or not
#############################################################################
*/
import React, { Component } from 'react';
import ReactFormInputValidation from 'react-form-input-validation';
import {
  signinAPI,
  dinerSigninAPI,
  getUserSummaryAPI,
  getUserLocationAPI
} from '../../../core/services/APIservice';
import { Link, Redirect } from 'react-router-dom';
// React Notification
import { NotificationManager } from 'react-notifications';
import config from '../../../config/config';
import Recaptcha from 'react-google-invisible-recaptcha';
import DinnerHeader from '../../../components/dinnerHeader';
import WhatsAppButton from '../../../components/whatsappButton';
import { connect } from 'react-redux';
import { saveUserData } from '../../../redux/actions/authActions';
import { Helmet } from 'react-helmet';

class DinerLoginWithPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCity: '',
      fields: {
        email: '',
        password: ''
      },
      hideContent: window.matchMedia('(max-width: 991px)').matches,
      buttonText: 'Log In',
      buttonDisabled: false,
      errors: {}
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({ email: 'required|email', password: 'required' });

    /* onformsubmit function starts here */
    this.form.onformsubmit = (fields) => {
      this.setState({
        buttonText: 'Logging In...',
        buttonDisabled: true
      });
      this.recaptcha.execute();
      return dinerSigninAPI(fields.email, fields.password)
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              buttonText: 'Log In',
              buttonDisabled: false
            });
            if (
              this.props.location.search === '?checkout' &&
              this.props.location.search === '?chef-innerpage' &&
              (response.data.is_chef || response.data.is_admin)
            ) {
              NotificationManager.error(
                'This Home Chef ID already exists.',
                'Error!'
              );
            } else {
              var token = response.data['data']['api_access_token'];
              var userId = response.data['data']['user']['user_id'];
              var userEmail = response.data['data']['user']['email'];
              var userName = response.data['data']['user']['name'];
              var userMobile = response.data['data']['user']['mobile_number'];
              var image = response.data['data']['user']['profile_image'];
              var verified = response.data['data']['profile_verified'];
			  var mousbmitted = ( response.data.mou_submitted == 0 || 
							response.data.is_profile_submitted == '' || 
							response.data.is_profile_submitted == null || response.data.is_profile_submitted == 0 ) ? false : true;
              localStorage.setItem('userID', userId);
              localStorage.setItem('userEmail', userEmail);
              localStorage.setItem('userName', userName);
              localStorage.setItem('userImage', image);
              localStorage.setItem('accessToken', token);
              localStorage.setItem('userMobile', userMobile);
              localStorage.setItem('userVerified', verified);
              localStorage.setItem('mouSubmitted', mousbmitted);
              let data = {
                userType: response.data.is_chef ? 'Chef' : 'Diner',
                userId: response.data['data']['user']['user_id'],
                userEmail: response.data['data']['user']['email'],
                userName: response.data['data']['user']['name'],
                userMobile: response.data['data']['user']['mobile_number'],
                image: response.data['data']['user']['profile_image'],
                verifid: response.data['data']['user']['profile_verified'],
                accessToken: response.data['data']['api_access_token'],
                isAvailable: response.data.is_available,
				isPublished: response.data.is_published,
				isMarketingPackage: response.data.is_marketing_package,
				isRegistrationPackage: response.data.is_registration_package,
				auditMe: response.data.a_m_a_enabled,
				mouSubmitted: response.data.mou_submitted,
				isProfileSubmitted: response.data.is_profile_submitted,
                membership: response.data.membership
                  ? response.data.membership
                  : ''
              };
              this.props.saveUserData(data);
              /*  if (response.data["data"]["profile_verified"] == "0") {
                this.props.history.push("/user-details");
              } else  */ if (response.data['data']['profile_verified'] == 1 && response.data.is_published == '1') {
                if (response.data.membership == 'basic') {
                  this.props.history.push('/chef-membership');
                } else {
                  this.props.history.push('/chef-dashboard');
                }
              } else {
                if (
                  response.data.is_admin == false &&
                  response.data.is_chef == true
                ) {
                  var token = response.data["data"]["api_access_token"];
				  var userId = response.data["data"]["user"]["user_id"];
				  var userEmail = response.data["data"]["user"]["email"];
				  var userName = response.data["data"]["user"]["name"];
				  var userMobile = response.data["data"]["user"]["mobile_number"];
				  var image = response.data["data"]["user"]["profile_image"];
				  return getUserSummaryAPI(userId, token)
					.then((response) => {
					  if (response.data["status"] === 200) {
						this.setState({
						  userAboutYou: response.data["data"]["about_average"],
						  userBusiness: response.data["data"]["business_average"],
						  userKitchen: response.data["data"]["kitchen_average"],
						  userMenu:
							response.data["data"]["number_of_uploaded_menu"],
						});
						var setStatus = false;
						if (
							response.data['data']['about_average'] > 0 && 
							response.data['data']['business_average'] > 0 && 
							response.data['data']['kitchen_average'] > 0 && 
							response.data['data']['number_of_uploaded_menu'] > 0 && 
							response.data['data']['is_mou_submitted'] > 0 && 
							response.data['data']['profile_submitted_at'] != '' && 
							response.data['data']['profile_submitted_at'] != null && 
							(response.data['data']['registration_package'] == '' || 
							response.data['data']['registration_package'] == null) && 
							setStatus === false
						) {
							localStorage.setItem('currentStatus', 7);
							setStatus = true;
						}if (
							response.data['data']['about_average'] > 0 && 
							response.data['data']['business_average'] > 0 && 
							response.data['data']['kitchen_average'] > 0 && 
							response.data['data']['number_of_uploaded_menu'] > 0 && 
							(response.data['data']['is_mou_submitted'] == 0 || 
							response.data['data']['profile_submitted_at'] == '' || 
							response.data['data']['profile_submitted_at'] == null) && 
							setStatus === false
						) {
							localStorage.setItem('currentStatus', 6);
							setStatus = true;
						}else if (
						  response.data["data"]["about_average"] < 100 &&
						  setStatus === false
						) {
						  localStorage.setItem("currentStatus", 1);
						  setStatus = true;
						} else if (
						  response.data["data"]["business_average"] < 100 &&
						  setStatus === false
						) {
						  localStorage.setItem("currentStatus", 2);
						  setStatus = true;
						} else if (
							response.data['data']['kitchen_average'] < 100 &&
							setStatus === false
						  ) {
							localStorage.setItem('currentStatus', 3);
							setStatus = true;
						} else if(response.data['data']['number_of_uploaded_menu'] < 1) {
							localStorage.setItem('currentStatus', 4);
						}else{
							localStorage.setItem('currentStatus', 7);
						}
						localStorage.setItem("userID", userId);
						localStorage.setItem("userEmail", userEmail);
						localStorage.setItem("userName", userName);
						localStorage.setItem("userImage", image);
						localStorage.setItem("accessToken", token);
						localStorage.setItem("userMobile", userMobile);

						this.props.history.push("/user-details");
					  } else {
						NotificationManager.error(
						  response.data["message"],
						  "Error!"
						);
					  }
					})
					.catch((error) => {
					  console.log(error);
					});
					//this.props.history.push("/user-details");
                } else {
                  if (response.data['data']['location'] == null) {
                    if (this.props.location.search === '?chef-innerpage') {
                      this.props.history.push('/pick-city?chef-innerpage');
                    } else {
                      this.props.history.push('/pick-city');
                    }
                  } else {
                    if (this.props.location.search === '?checkout') {
                      return getUserLocationAPI(
                        localStorage.getItem('userID'),
                        localStorage.getItem('accessToken'),
                        localStorage.getItem('latitude'),
                        localStorage.getItem('longitude'),
                        localStorage.getItem('selectedLocationName'),
                        localStorage.getItem('addressSelected'),
                        localStorage.getItem('pincode'),
                        localStorage.getItem('addressType'),
                        localStorage.getItem('addressDetailType'),
                        localStorage.getItem('flatNo'),
                        localStorage.getItem('landmark')
                      )
                        .then((response) => {
                          if (response.data['status'] === 200) {
                            localStorage.setItem(
                              'prevLocationId',
                              response.data.diner_location_id
                            );
                            NotificationManager.success(
                              response.data['message'],
                              'Successful!',
                              3000
                            );
                            this.props.history.push('/checkout');
                          } else {
                            NotificationManager.error(
                              response.data['message'],
                              'Error!'
                            );
                          }
                        })
                        .catch((error) => {
                          /* console.log(error); */
                        });
                    } else {
                      var address = response.data['data']['location'];
                      var addressType = response.data['data']['type'];
                      var latitude = response.data['data']['latitude'];
                      var longtitude = response.data['data']['longitude'];
                      var city = response.data['data']['city'];
                      var flatNo = response.data['data']['address_line1'];
                      var landmark = response.data['data']['address_line2'];
                      var otherType = response.data['data']['location_title'];
                      localStorage.setItem('flatNo', flatNo);
                      localStorage.setItem('landmark', landmark);
                      localStorage.setItem('addressSelected', address);
                      localStorage.setItem('addressType', addressType);
                      localStorage.setItem('latitude', latitude);
                      localStorage.setItem('longitude', longtitude);
                      localStorage.setItem('selectedLocation', city);
                      localStorage.setItem('selectedLocationName', city);
                      if (otherType) {
                        localStorage.setItem('addressDetailType', otherType);
                      }
                      let selectedCity = config.slugify(city);
                      if (selectedCity == localStorage.getItem('chefCity')) {
                        this.props.history.push(
                          '/' +
                            localStorage.getItem('chefCity') +
                            '/chef-inner-homepage/' +
                            localStorage.getItem('chefSlug')
                        );
                      } else {
                        if (
                          this.props.location.search !== '' &&
                          this.props.location.search.indexOf('redirect=') !== -1
                        ) {
                          this.props.history.push(
                            '/' + this.props.location.search.split('=')[1]
                          );
                        } else {
                          this.props.history.push(
                            '/' + selectedCity + '/diner-homepage'
                          );
                        }
                      }
                    }
                  }
                }
              }
              NotificationManager.success(
                'Welcome back to CHEFPIN ' + userName,
                'Successful!',
                3000
              );
            }
          } else if (response.data.status === 405) {
            this.setState({
              buttonText: 'log in',
              buttonDisabled: false
            });
            localStorage.setItem('forgot-password-email', fields.email);
            if (response.data.reset_password == true) {
              localStorage.setItem('resetPassword', true);
              this.props.history.push('/forgot-password');
            }
          } else {
            this.setState({
              buttonText: 'Log In',
              buttonDisabled: false
            });
            NotificationManager.error(response.data.message, 'Error!');
          }
        })
        .catch((error) => {
          this.setState({
            buttonText: 'Log In',
            buttonDisabled: false
          });
          NotificationManager.error('Invalid User', 'Error!');
          this.recaptcha.reset();
        });
    };
    /* onformsubmit function ends here */
  }

  /* onResolved function starts here */
  onResolved() {
    alert('Recaptcha resolved with response: ' + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  componentDidMount() {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    try {
      OneSignal.getUserId().then(function (userId) {
        localStorage.setItem('OneSignal_playerId', userId);
      });
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log('is subscribed', isSubscribed);
        OneSignal.push(function () {
          console.log('attempt to get id'); // doesn't get this far
          OneSignal.getUserId(function (userId) {
            console.log('user id', userId); // doesn't get this far
            localStorage.setItem('OneSignal_playerId', userId);
          });
        });
      });
    } catch (error) {
      console.log('Error: ', error);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (localStorage.getItem('selectedLocation')) {
      var selectedCity = config.slugify(
        localStorage.getItem('selectedLocation')
      );
      this.setState({
        selectedCity: '/' + selectedCity + '/diner-homepage'
      });
    }
  }

  render() {
    if (localStorage.getItem('accessToken') !== null) {
      return <Redirect to={this.state.selectedCity} />;
    }
    return (
      <React.Fragment>
        <div className='css2'>
          <Helmet>
            <title>Sign In to Chef Pin for delicious home cooked food</title>
            <meta
              name='title'
              content='Sign In to Chef Pin for delicious home cooked food.'
            />
            <meta
              name='description'
              content='Enjoy the fresh and tasty food cooked on demand, delivered to your doorsteps in no time.'
            />
            <meta
              name='keywords'
              content='home made food services, home food service, home made tiffin service, healthy food to order online, meal delivery service near me'
            />
          </Helmet>
          <DinnerHeader />
          <section className='sign_up_chef verification log_otp sign_chefdiner'>
            <Link
              to={
                this.props.location.search !== '' &&
                this.props.location.search.indexOf('redirect=') !== -1
                  ? '/diner-signin-with-otp' + this.props.location.search
                  : '/diner-signin-with-otp'
              }
              className='back_btn'
            >
              <img
                src='/assets/images/icons/black-arrow.svg'
                alt='Black Arrow'
              />
              Back
            </Link>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-xl-5 d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='text pt-0'>
                    <h1 className='color_C07'>LOG IN TO CHEF PIN</h1>
                    <div className='profile_img'>
                      <div className='inner'>
                        <img
                          src='/assets/images/icons/profile-img.png'
                          alt='Profile Images'
                          className='img-fluid'
                        />
                      </div>
                    </div>
                    <div className='form_wrap'>
                      <form onSubmit={this.form.handleSubmit}>
                        <div className='form-group'>
                          <input
                            type='email'
                            className='form-control'
                            id='exampleInputEmail1'
                            aria-describedby='emailHelp'
                            placeholder='Email ID*'
                            name='email'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.email}
                          />
                          <small className='form-text red_000'>
                            {this.state.errors.email
                              ? this.state.errors.email
                              : ''}
                          </small>
                        </div>
                        <div className='form-group'>
                          <input
                            type='password'
                            className='form-control'
                            id='Password'
                            placeholder='Password*'
                            name='password'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.password}
                          />
                          <small className='form-text red_000'>
                            {this.state.errors.password
                              ? 'Password field is required'
                              : ''}
                          </small>
                          <Link
                            to={'/diner-forgot-password'}
                            className='form-text forgot_password text-right text-muted'
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div className='form-group mb-0'>
                          <button
                            disabled={this.state.buttonDisabled}
                            type='submit'
                            className='btn'
                          >
                            {this.state.buttonText}
                          </button>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-xl-7 align-items-center d-none d-lg-flex'>
                  <div className='img_box'>
                    <img
                      src='/assets/images/sign-up-as-a-chef/sign-up-img.png'
                      alt='sign up img'
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='nofooter'>
            <WhatsAppButton />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items
});

export default connect(mapStateToProps, { saveUserData })(
  DinerLoginWithPasswordPage
);
