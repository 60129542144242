import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PartyOrderForm from "./PartyOrderForm";
import DinerFooter from "../../components/dinerFooter";
import Slider from "react-slick";
import { HomeDescription } from "../../backendUI/Home/config";
import config from "../../config/config";


class MediaEventsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            HeadTitle: "",


        };

    }

    componentDidMount() {
        if (!localStorage.getItem("closePopup")) {
            this.setState({
                displayPopup: true,
            });
        } else {
            this.setState({
                displayPopup: false,
            });
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        if (localStorage.getItem("selectedLocation")) {
            var selectedCity = config.slugify(
                localStorage.getItem("selectedLocation")
            );
            this.setState({
                selectedCity: "/" + selectedCity + "/diner-homepage",
            });
        }

        this.getHeadTitle();


    }

    getHeadTitle = () => {
        HomeDescription().then((res) => {
            if (res.success == true) {
                this.setState({
                    HeadTitle: res.data[0].description,
                });
            }
        });
    };

    render() {

        return (
            <div className="css2" >

                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li className="active">
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/diner-aboutus" className="login_btn">
                                    About Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a
                                    href="https://wa.me/919899622242"
                                    target="_blank"
                                    className="login_btn"
                                >
                                    Contact Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/pick-city" className="login_btn">
                                    Pick A City
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/blog" className="login_btn">
                                    Blog
                                </a>{" "}
                            </li>
                            <li className="d-block d-xl-none last_btn">
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="dinner_menu d-none d-xl-block">
                            <li>
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <div className="menu_overlay"></div>
                    </div>
                </header>

                <section className="banner-media-media">
                    <h2>Media & Events</h2>
                </section>


                <section>
                    <div className="container pt-5">
                        <div className="row align-self">
                            <div className="col-md-12 m-0 p-0">
                                <div className="container media-block-container">
                                    <div className="row m-0 p-0">
                                        {/* Block 1 */}
                                        <a
                                            href="https://www.newindianexpress.com/lifestyle/food/2023/mar/19/eating-out-of-the-box-pre-prepared-home-cooked-food-gains-popularity-among-working-indians-2556678.html" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/1-Blog.jpg"
                                                        alt="Block 1 Image"
                                                    />
                                                </div>
                                                <p className="media-title"> Eating out of the box: Pre-prepared home-cooked food gains popularity among working Indians </p>
                                                <p className="media-description">Pre-prepared meal boxes are a popular option for those tired of cooking at home or ordering in fancy, often unhealthy, meals.</p>
                                                <p className="media-title">Read More</p>

                                            </div>
                                        </a>

                                        {/* Block 2 */}
                                        <a
                                            href="https://hospitality.economictimes.indiatimes.com/news/operations/food-and-beverages/our-mission-to-uncover-the-hidden-talent-of-home-chefs/87664611" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/2-Blog.jpg"
                                                        alt="Block 2 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Our mission to uncover the hidden talent of home chefs</p>
                                                <p className="media-description">Siddharth Mohan, founder and CEO of Chef Pin speaks about how his platform is different from others in the home chef food delivery space.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        {/* Block 3 */}
                                        <a
                                            href="https://www.indianewsstream.com/west-asia/chef-pin-aims-to-be-the-countrys-largest-app-for-home-chefs/" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/3-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Chef Pin aims to be the country’s largest app for home chefs</p>
                                                <p className="media-description">We recently got the opportunity to taste the scrumptious, rich delicacies of Punjabi-Dilli cuisine at Food Exchange, Novotel Aerocity prepared by Chef pin APP home chef Naveen Sawhney.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        <a
                                            href="http://fnbnews.com/Hotels-Hospitality/cur8-and-chef-pin-by-rocky-mohan-unite-to-redefine-culinary-excellence-74416" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/4-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title"> Chef Pin by Rocky Mohan unite to redefine culinary excellence</p>
                                                <p className="media-description">Experience a four-month culinary extravaganza with CUR8, Four Seasons Hotel Bengaluru, and Chef Pin, redefining dining with exceptional homegrown chefs.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        <a
                                            href="https://www.vogue.in/content/how-intimate-dining-is-fast-becoming-a-beloved-culinary-trend-on-indias-food-block" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/5-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Chef Pin: Elevating Home Cooks & Dining Experiences in India</p>
                                                <p className="media-description">Discover the culinary secrets of India with Chef Pin. Connect with 5,000 home chefs for unique dining experiences in Delhi, Mumbai, and Bangalore.</p>
                                                <p className="media-title">Read More</p>

                                            </div>
                                        </a>

                                        <a
                                            href="https://bwhotelier.businessworld.in/article/Novotel-Aerocity-New-Delhi-and-Chef-Gul-Ali-brings-authentic-flavours-of-Lucknow-to-life-/09-09-2022-445884/" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/6-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Novotel Aerocity's Lucknow Flavors with Chef Gul Ali</p>
                                                <p className="media-description">Experience authentic Lucknow cuisine at Novotel Aerocity, in collaboration with Chef Pin and culinary artist Gul Ali.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        <a
                                            href="https://businessnewsthisweek.com/health/discover-kayastha-cuisine-with-a-culinary-workshop-at-ecole-ducasse-india-in-collaboration-with-chef-pin/" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/7-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Kayastha Cuisine Workshop with École Ducasse and Chef Pin</p>
                                                <p className="media-description">Discover the rich heritage of Kayastha cuisine at École Ducasse's workshop in collaboration with Chef Pin. Learn traditional dishes and cooking styles.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        <a
                                            href="https://www.apnnews.com/jw-marriott-hotel-bengaluru-in-association-with-chef-pin-presents-home-chef-fathima-riyaz/" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/8-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Rediscover Bhatkal Cuisine at JW Marriott Bengaluru</p>
                                                <p className="media-description">JW Marriott Bengaluru and Chef Pin present Home Chef Fathima Riyaz, showcasing authentic Bhatkal cuisine from Sept 9-11, 2022.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        <a
                                            href="https://lbb.in/delhi/chef-pin-485a6d" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/9-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Hidden Gems of India: Mughlai Cuisine by Home Chef Sana Khan</p>
                                                <p className="media-description">Indulge in handpicked Mughlai dishes by home chef Sana Khan at Shangri-La Eros New Delhi in collaboration with Chef Pin, at Tamra. Lunch and dinner buffet available.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        <a
                                            href="https://www.vervemagazine.in/people/an-invitation-to-the-community-table-anglo-indian-home-chefs-are-offering-a-taste-of-their-closely-guarded-culinary-heritage" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/10-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Rediscovering Anglo-Indian Cuisine: Home Chefs Share Culinary Heritage</p>
                                                <p className="media-description">Explore the unique Anglo-Indian cuisine as home chefs like Ruth Phillips open the doors to their closely guarded culinary heritage.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>

                                        <a
                                            href="https://bwhotelier.businessworld.in/article/Savour-hidden-culinary-gems-of-India-at-Shangri-La-Eros-New-Delhi/07-04-2022-424756/" target="_blank"
                                            className="col-md-4 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/media/11-Blog.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Shangri-La's Hidden Culinary Gems: Regional Cuisine Series with Chef Pin</p>
                                                <p className="media-description">Explore authentic regional cuisines at Shangri-La Eros New Delhi. Home chefs, like Surbhi Bhandari, present Marwadi cuisine at Tamra from April 7-10.</p>
                                                <p className="media-title">Read More</p>
                                            </div>
                                        </a>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





                <DinerFooter />

            </div >
        );
    }
}

export default MediaEventsPage;
