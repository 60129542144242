import { SAVE_USER_DETAILS, REMOVE_USER_DETAILS } from "./types";

export const saveUserData = (userData) => (dispatch) => {
    return dispatch({
        type: SAVE_USER_DETAILS,
        payload: {
            userData: userData
        }
    });
}

export const removeUserData = () => (dispatch) => {
    return dispatch({
        type: REMOVE_USER_DETAILS,
        payload: {
            userData: []
        }
    });
}