import { ADD_ORDER_NUMBER, REMOVE_ORDER_NUMBER } from "../actions/types";

const initialState = { orderNumber: null }
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_ORDER_NUMBER:
            return {
                items: action.payload.orderNumber
            }

        case REMOVE_ORDER_NUMBER:
            return {
                items: action.payload.orderNumber
            }

        default:
            return state;
    }
}