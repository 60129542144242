/*
#############################################################################
# Name : API service.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to fetch the data through API for Chef's
#############################################################################
*/
import axios from "axios";
import config from "../../config/config";

async function signupAPI(firstname, lastname, email, mobileNo, password) {
  var formdata = new FormData();
  formdata.append("first_name", firstname);
  formdata.append("last_name", lastname);
  formdata.append("email", email);
  formdata.append("mobile_number", mobileNo);
  formdata.append("password", password);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef-registration",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function dinerSignupAPI(firstname, lastname, email, mobileNo, password, fullname, provider_image, access_token, provider) {
  var formdata = new FormData();
  formdata.append("first_name", firstname);
  formdata.append("last_name", lastname);
  formdata.append("email", email);
  formdata.append("mobile_number", mobileNo);
  formdata.append("password", password);

  if (provider !== undefined && provider !== "") {
    formdata.append("fullname", fullname);
    formdata.append("provider_image", provider_image);
    formdata.append("access_token", access_token);
    formdata.append("provider", provider);
  }

  try {
    const response = await axios.post(
      config.APIURL + "v1/diner-registration",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function fetchOTPAPI(userID) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef-send-verification-otp",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function dinerFetchOTPAPI(userID) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner-send-verification-otp",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function verfiyOTPAPI(userID, otp) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("otp", otp);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef-otp-verification",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function dinerVerfiyOTPAPI(userID, otp) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("otp", otp);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner-otp-verification",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function signinAPI(email, password) {
  var formdata = new FormData();
  formdata.append("email", email);
  formdata.append("password", password);
  try {
    const response = await axios.post(config.APIURL + "login", formdata);
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function dinerSigninAPI(email, password, provider) {
  var formdata = new FormData();
  formdata.append("email", email);
  formdata.append("password", password);
  if (provider !== undefined && provider !== "") {
    formdata.append("provider", provider);
  }
  try {
    const response = await axios.post(config.APIURL + "login", formdata);
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}


async function detailAboutChefAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-details-about-chef",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getCateringFacilitiesAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-catering-facilities",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function detailsAboutBusinessAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-chef-business",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getAllergiesDetailAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-requirement-and-allergies",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getKitchenMaterialAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-kitchen-material",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function changeProfileImage(userId, token, photo, photoname) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("profile_image", photo, photoname);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-chef-profile-image",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getUserSummaryAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-chef-summary",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getProfileDataAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/show-user-profile-chef",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMobileOTPAPI(userId, mobileNumber, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("mobile_number", mobileNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-chef-mobile-number",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMobileOTPVerfiyAPI(userId, mobileNumber, otp, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("otp", otp);
  formdata.append("mobile_number", mobileNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-chef-mobile-number-verification",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getEmailOTPAPI(userId, emailID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("email", emailID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-chef-email",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getEmailOTPVerfiyAPI(userId, emailID, otp, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("otp", otp);
  formdata.append("email", emailID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-chef-email-verification",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getCusiniesAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-cuisines",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMobileLoginAPI(mobile) {
  var formdata = new FormData();
  formdata.append("mobile_number", mobile);
  try {
    const response = await axios.post(
      config.APIURL + "send-login-otp",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMobileLoginVerfiyAPI(mobile, otp) {
  var formdata = new FormData();
  formdata.append("mobile_number", mobile);
  formdata.append("otp", otp);
  try {
    const response = await axios.post(
      config.APIURL + "login-with-otp",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getCategoriesAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-categories",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getTagsAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(config.APIURL + "v1/get-tags", formdata, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getKitchenMenuAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-kitchen-menu-list",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getSavedKitchenMenuAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-kitchen-menu-document-list",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getForgotPaswordAPI(emailID) {
  var formdata = new FormData();
  formdata.append("email", emailID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/forgot-chef-password",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getDinerForgotPaswordAPI(emailID) {
  var formdata = new FormData();
  formdata.append("email", emailID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/forgot-diner-password",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMenuItemFormDtaAPI(userId, menuId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("menu_id", menuId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-kitchen-menu",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function changeMenuItemStatusAPI(userId, menuId, newStatus, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("menu_id", menuId);
  formdata.append("enabled", newStatus);
  try {
    const response = await axios.post(
      config.APIURL + "v1/enable-disable-menu",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function deleteMenuItemAPI(userId, menuId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("menu_id", menuId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/delete-menu",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function removeDocumentItemAPI(userId, documentId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("document_id", documentId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/remove-menu-document",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function termsAndConditionAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("terms_accepted", '1');
  try {
    const response = await axios.post(
      config.APIURL + "v1/accept-terms-and-conditions",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getSpecialitiyAPI(userId, token, dishName, dishImage, dishImageName) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("special_dish_name", dishName);
  if (dishImage !== "") {
    formdata.append("special_dish_images", dishImage, dishImageName);
  }


  try {
    const response = await axios.post(
      config.APIURL + "v1/store-special-dish",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function removeSpecialDishAPI(userId, token, dishName) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append('special_dish_name', dishName);
  try {
    const response = await axios.post(
      config.APIURL + "v1/remove-special-dish",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function enableDishAPI(userId, token, menuId, enabled) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append('menu_id', menuId);
  formdata.append('enabled', enabled);
  try {
    const response = await axios.post(
      config.APIURL + "v1/enable-disable-menu",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getChangePasswordAPI(userId, token, oldPassword, newPassword, confirmPassword) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append('old_password', oldPassword);
  formdata.append('password', newPassword);
  formdata.append('password_confirm', confirmPassword);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-password",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

  async function getUserLocationAPI(userId, token, latitude, longitude, city, location, pincode, type, location_title, address_line1, address_line2) {
    var formdata = new FormData();
    formdata.append("user_id", userId);
    formdata.append('latitude', latitude);
    formdata.append('longitude', longitude);
    formdata.append('city', city);
    formdata.append('location', location);
    formdata.append('pincode', pincode);
    formdata.append('type', type);
    formdata.append('location_title', location_title);
    formdata.append('address_line1', address_line1);
    formdata.append('address_line2', address_line2);
    try {
      const response = await axios.post(
        config.APIURL + "v1/store-diner-locations",
        formdata,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      return response;
    } catch (error) {
      // you will get error here.
      return error.response;
    }
  }

  export {
    signupAPI,
    fetchOTPAPI,
    verfiyOTPAPI,
    signinAPI,
    detailAboutChefAPI,
    getCateringFacilitiesAPI,
    detailsAboutBusinessAPI,
    getAllergiesDetailAPI,
    getKitchenMaterialAPI,
    changeProfileImage,
    getUserSummaryAPI,
    getProfileDataAPI,
    getMobileOTPAPI,
    getMobileOTPVerfiyAPI,
    getEmailOTPAPI,
    getEmailOTPVerfiyAPI,
    getCusiniesAPI,
    getMobileLoginAPI,
    getMobileLoginVerfiyAPI,
    getCategoriesAPI,
    getTagsAPI,
    getKitchenMenuAPI,
    getSavedKitchenMenuAPI,
    getForgotPaswordAPI,
    getMenuItemFormDtaAPI,
    deleteMenuItemAPI,
    changeMenuItemStatusAPI,
    removeDocumentItemAPI,
    termsAndConditionAPI,
    getSpecialitiyAPI,
    removeSpecialDishAPI,
    getChangePasswordAPI,
    dinerSignupAPI,
    dinerFetchOTPAPI,
    dinerVerfiyOTPAPI,
    dinerSigninAPI,
    getDinerForgotPaswordAPI,
    getUserLocationAPI,
    enableDishAPI
  };
