/*
#############################################################################
# Name : chefAccountSidebar.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to select the memebrship for the chef 
#############################################################################
*/
import React from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from '../../components/whatsappButton';
import LoadingDiv from "../../components/LoadingDiv";
import ChefAccountSidebar from "./chefAccountSidebar";
import { getMemberShipData } from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { connect } from "react-redux";
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};

class ChefMembershipPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberType: "",
      thankYouMessageStatus: false,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      formErrors: {
        memberType: "",
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  /* handleInputChange function starts here */
  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "memberType":
        formErrors.memberType = value === "" ? "Member Type is required" : "";
        break;

      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
/* handleInputChange function ends here */

/* getMembership function starts here */
  getMembership = (event) => {
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.memberType === "") {
      formErrors.memberType = "Member Type is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.setState({ thankYouMessageStatus: true });
      var userId = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
      return getMemberShipData(
        userId,
        localStorage.getItem("userName"),
        localStorage.getItem("userEmail"),
        localStorage.getItem("userMobile"),
        this.state.memberType,
        token
      )
        .then((response) => {
          if (response.data.status == 200) {
            NotificationManager.success(
              response.data["message"],
              "Successful!",
              3000
            );
            /* this.props.history.push("/diner-thank-you"); */
          } else {
            NotificationManager.error(response.data["message"], "Error!");
            this.setState({ thankYouMessageStatus: false });
          }
        })
        .catch(function (error) {
         /*  console.log(error); */
          this.setState({ thankYouMessageStatus: false });
        });
    }
  };
/* getMembership function ends here */


  render() {
    if (this.props.userData.userType !== "Chef") {
      return <Redirect to={"/signin-with-otp?redirect=" + this.props.location.pathname.split("/")[1]} />;
    }
    const { mobileView } = this.props;
    const { formErrors } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        <section className="chef_flow">
          <div className="container">
            <div className="row justify-content-center">
              <ChefAccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="chef_right diner_my_acc">
                  <div className="sticky_wrap">
                    <h6 className="res_back_btn_wrap">
                      <a
                        onClick={() => this.props.removeMobileView()}
                        className="res_back_btn mobile_sidebar_back_btn"
                      >
                        <img
                          src="assets/images/icons/left-arrow-black.svg"
                          alt="left-arrow-black"
                        />
                      </a>
                      <Link
                        to="/chef-dashboard"
                        className="res_back_btn desktop_sidebar_back_btn"
                      >
                        <img
                          src="assets/images/icons/left-arrow-black.svg"
                          alt="left-arrow-black"
                        />
                      </Link>
                      Membership Plans
                    </h6>
                  </div>
                  <div className="table-wrapper">
                    <p className="para">
                      Take a paid membership to enable orders and access other
                      features that will help you grow your business!
                    </p>
                    <table className="desktop">
                      <thead>
                        <tr>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div className="heading vip">
                              <p>
                                <img
                                  src="assets/images/icons/membership-plans.svg"
                                  alt=""
                                />
                                VIP MEMBERSHIP
                              </p>
                              <span>INR 9,999/YR</span>
                            </div>
                          </td>
                          <td>
                            <div className="heading premium">
                              <p>
                                <img
                                  src="assets/images/icons/star-blue.svg"
                                  alt=""
                                />
                                PREMIUM MEMBERSHIP
                              </p>
                              <span>INR 6,999/YR</span>
                            </div>
                          </td>
                          <td>
                            <div className="heading basic">
                              <p>
                                <img
                                  src="assets/images/icons/star-grey.svg"
                                  alt=""
                                />
                                BASIC MEMBERSHIP
                              </p>
                              <span>FREE</span>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Order Taking
                            <span>Accept orders from diners</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Delivery Facility
                            <span>Use of Chef Pin delivery partners</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Priority Listing
                            <span>Get prominent position in the app</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Marketing Support
                            <span>Get featured on Chef Pin channels</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Menu View
                            <span>Diners can see your menu</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Ratings and Reviews
                            <span>Receive reviews from diners</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Admin Panel Access
                            <span>Access and edit your details</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Increased Photo Upload
                            <span>Upload more images to excite diners</span>
                          </td>
                          <td>
                            <img
                              src="assets/images/icons/check-green.svg"
                              alt=""
                            />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                          <td>
                            <img src="assets/images/icons/cross.svg" alt="" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Chef Pin Commission
                            <span>VIP members pay lower commission</span>
                          </td>
                          <td>
                            <span>5% + GST</span>
                          </td>
                          <td>
                            <span>10% + GST</span>
                          </td>
                          <td>
                            <span>Not Applicable</span>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="4"
                            style={{
                              textAlign: "right",
                              width: "100%",
                              maxWidth: "100%",
                            }}
                          >
                            <span>Membership plan pricing includes GST</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="accordion" className="mobile">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h5 className="mb-0">
                            <button
                              className="title"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <p>
                                <img
                                  src="assets/images/icons/membership-plans.svg"
                                  alt=""
                                />
                                VIP MEMBERSHIP
                              </p>
                              <span>INR 9,999/YR</span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    Order Taking
                                    <span>Accept orders from diners</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Delivery Facility
                                    <span>
                                      Use of Chef Pin delivery partners
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Priority Listing
                                    <span>
                                      Get prominent position in the app
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Marketing Support
                                    <span>
                                      Get featured on Chef Pin channels
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Menu View
                                    <span>Diners can see your menu</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Ratings and Reviews
                                    <span>Receive reviews from diners</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Admin Panel Access
                                    <span>Access and edit your details</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Increased Photo Upload
                                    <span>
                                      Upload more images to excite diners
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2" style={{ maxWidth: "100%" }}>
                                    <p>
                                      Chef Pin Commission <span>5% + GST</span>
                                    </p>
                                    <span>
                                      VIP members pay lower commission
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button
                              className="title"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <p>
                                <img
                                  src="assets/images/icons/star-blue.svg"
                                  alt=""
                                />
                                PREMIUM MEMBERSHIP
                              </p>
                              <span>INR 6,999/YR</span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    Order Taking
                                    <span>Accept orders from diners</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Delivery Facility
                                    <span>
                                      Use of Chef Pin delivery partners
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Priority Listing
                                    <span>
                                      Get prominent position in the app
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Marketing Support
                                    <span>
                                      Get featured on Chef Pin channels
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Menu View
                                    <span>Diners can see your menu</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Ratings and Reviews
                                    <span>Receive reviews from diners</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Admin Panel Access
                                    <span>Access and edit your details</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Increased Photo Upload
                                    <span>
                                      Upload more images to excite diners
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2" style={{ maxWidth: "100%" }}>
                                    <p>
                                      Chef Pin Commission <span>10% + GST</span>
                                    </p>
                                    <span>
                                      VIP members pay lower commission
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button
                              className="title"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <p>
                                <img
                                  src="assets/images/icons/star-grey.svg"
                                  alt=""
                                />
                                BASIC MEMBERSHIP
                              </p>
                              <span>FREE</span>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    Order Taking
                                    <span>Accept orders from diners</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Delivery Facility
                                    <span>
                                      Use of Chef Pin delivery partners
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Priority Listing
                                    <span>
                                      Get prominent position in the app
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Marketing Support
                                    <span>
                                      Get featured on Chef Pin channels
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Menu View
                                    <span>Diners can see your menu</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Ratings and Reviews
                                    <span>Receive reviews from diners</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Admin Panel Access
                                    <span>Access and edit your details</span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/check-green.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Increased Photo Upload
                                    <span>
                                      Upload more images to excite diners
                                    </span>
                                  </td>
                                  <td>
                                    <img
                                      src="assets/images/icons/cross.svg"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="2" style={{ maxWidth: "100%" }}>
                                    <p>
                                      Chef Pin Commission{" "}
                                      <span>Not Applicable</span>
                                    </p>
                                    <span>
                                      VIP members pay lower commission
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bottom_wrap">
                      <div className="row">
                        <div className="col-lg-12 col-xl-3">
                          <p>
                            Let us know which plan you are interested in? Our
                            experts will get in touch with you soon.
                          </p>
                        </div>
                        <div className="col-lg-12 col-xl-9">
                          <div className="form-group yellow_arrow_icon">
                            <select
                              className="form-control"
                              name="memberType"
                              onChange={this.handleInputChange}
                              onBlur={this.handleInputChange}
                            >
                              <option value="">Choose your plan</option>
                              <option
                                value="VIP Membership Plan"
                                selected={
                                  this.state.memberType == "VIP Membership Plan"
                                }
                              >
                                VIP Membership Plan
                              </option>
                              <option
                                value="Premium Membership Plan"
                                selected={
                                  this.state.memberType ==
                                  "Premium Membership Plan"
                                }
                              >
                                Premium Membership Plan
                              </option>
                            </select>
                            <small className="text-danger">
                              {formErrors.memberType}
                            </small>
                          </div>
                          <div className="form-group d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.getMembership}
                              disabled={this.state.thankYouMessageStatus}
                            >
                              submit
                            </button>
                          </div>
                        </div>
                        {this.state.thankYouMessageStatus ? (
                          <div className="col-lg-12">
                            <p className="fw_500">
                              Thank you. Your selection has been submitted!
                              Please wait for the Chef Pin Team to contact you.
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? null : <Footer />}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(
  ChefMembershipPage
);
