/*
#############################################################################
# Name : verifyOTP.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to verify the OTP entered 
#############################################################################
*/
import React from "react";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import OtpInput from "react-otp-input";
import { fetchOTPAPI, verfiyOTPAPI } from "../../core/services/APIservice";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import { saveUserData } from "../../redux/actions/authActions";

class VerifyOTPPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      userMobileNo: "",
      userID: "",
      minutes: 1,
      seconds: 0,
    };
  }

  componentDidMount() {
    var mobileNo = localStorage.getItem("userMobile");
    var userId = localStorage.getItem("tempId");
    this.setState({ userID: userId, userMobileNo: mobileNo });
    //this.getOTP();
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  /* async getOTP() {
    var userId = localStorage.getItem("userID");
    this.setState({ userID: userId });
    try {
      const response = await fetchOTPAPI(userId);
    } catch (error) {
    }
  } */

  /* handleChange function starts here */
  handleChange = (otp) => {
    this.setState({ otp });
  };
  /* handleChange function ends here */

/* clearotp function starts here */
  clearotp = (event) => {
    event.preventDefault();
    this.setState({ otp: "" });
  };
  /* clearotp function ends here */

/* resendOtp function starts here */
  resendOtp = () => {
    this.setState({ otp: "" });
    var userId = localStorage.getItem("tempId");
    this.setState({ userID: userId });
    try {
      fetchOTPAPI(userId).then((response) => {
        if (response.data.status) {
          if (response.data.status === 200) {
            this.setState({
              minutes: 1,
              seconds: 0,
            });
            this.myInterval = setInterval(() => {
              const { seconds, minutes } = this.state;
              if (seconds > 0) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              }
              if (seconds === 0) {
                if (minutes === 0) {
                  clearInterval(this.myInterval);
                } else {
                  this.setState(({ minutes }) => ({
                    minutes: minutes - 1,
                    seconds: 59,
                  }));
                }
              }
            }, 1000);
            NotificationManager.success("OTP Sent", "Success!");
          }
        } else {
          NotificationManager.error("Something went wrong", "Error!");
        }
      });
    } catch (error) {}
  };
/* resendOtp function ends here */

/* verfiyOTP function starts here */
  verfiyOTP = (event) => {
    event.preventDefault();
    this.recaptcha.execute();
    return verfiyOTPAPI(this.state.userID, this.state.otp)
      .then((response) => {
        var userToken = "";
        if (response.data["status"] === 200) {
          userToken = response.data["data"]["api_access_token"];
          var image = response.data["data"]["user"]["profile_image"];
          var userId = response.data["data"]["user"]["user_id"];
          var userEmail = response.data["data"]["user"]["email"];
          var userName = response.data["data"]["user"]["name"];
          var userMobileNo = response.data["data"]["user"]["mobile_number"];
          localStorage.setItem("userEmail", userEmail);
          localStorage.setItem("userMobile", userMobileNo);
          localStorage.setItem("userName", userName);
          localStorage.setItem("accessToken", userToken);
          localStorage.setItem("userImage", image);
          localStorage.setItem("userID", userId);
          let data = {
            userType: "Chef",
            userId: response.data["data"]["user"]["user_id"],
            userEmail: response.data["data"]["user"]["email"],
            userName: response.data["data"]["user"]["name"],
            userMobile: response.data["data"]["user"]["mobile_number"],
            image: response.data["data"]["user"]["profile_image"],
            verifid: response.data["data"]["user"]["profile_verified"],
            accessToken: response.data["data"]["api_access_token"],
            isAvailable: response.data.is_available,
            isEnableDate: response.data.is_enable_date,
            membership: response.data.membership
              ? response.data.membership
              : "",
          };
          this.props.saveUserData(data);
          this.props.history.push("/user-details");
          NotificationManager.success(
            "Verification successful",
            "Successful!",
            3000
          );
        } else {
          this.setState({ otp: "" });
          this.recaptcha.reset();
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.recaptcha.reset();
        NotificationManager.error("User Verification Failed", "Error!");
      });
  };
/* verfiyOTP function ends here */

/* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved function ends here */

  render() {
    const { minutes, seconds } = this.state;
    return (
      <div>
        <Header />
        <section className="sign_up_chef verification">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-xl-5 d-flex d-xl-block align-items-center justify-content-center justify-content-lg-start">
                <div className="text">
                  <h3 className="color_C07">verification</h3>
                  <div className="profile_img">
                    <div className="inner">
                      <img
                        src="/assets/images/icons/verification.png"
                        alt="Verification Images"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <h5>Verify with OTP</h5>
                  <p className="opt_text">Sent to {this.state.userMobileNo}</p>
                  <div className="form_wrap">
                    <form>
                      <div className="form-group">
                        <label className="otp_label">
                          Enter the 6 digit OTP
                        </label>
                        <OtpInput
                          containerStyle={"otp_input_wrap"}
                          inputStyle={"form-control"}
                          value={this.state.otp}
                          onChange={this.handleChange}
                          numInputs={6}
                          shouldAutoFocus
                          isInputNum={true}
                          separator={false}
                        />
                      </div>
                    </form>
                  </div>
                  <p className="resend_otp_text color_C07 mb-0">
                    OTP will expire in
                    <span className="color_036 ml-2">
                      {minutes > 9 ? minutes : "0" + minutes}:
                      {seconds > 9 ? seconds : "0" + seconds}
                    </span>
                  </p>

                  <div className="btn-group opt_btn_grp">
                    <button
                      className="btn previous_btn"
                      onClick={this.resendOtp}
                      disabled={minutes > 0 || seconds > 0}
                    >
                      re-send otp
                    </button>
                    <button
                      className="btn"
                      disabled={this.state.otp.toString().length < 6}
                      onClick={this.verfiyOTP}
                    >
                      Verify OTP
                    </button>
                    <Recaptcha
                      ref={(ref) => (this.recaptcha = ref)}
                      sitekey={config.siteKey}
                      onResolved={this.onResolved}
                    />
                  </div>
                  {/* <button
className="btn"
onClick={this.clearotp}
>
clear otp
</button> */}
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-7 align-items-center d-none d-md-flex">
                <div className="img_box">
                  <img
                    src="/assets/images/sign-up-as-a-chef/sign-up-img-verification.png"
                    alt="sign up img verification"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="nofooter">
          <WhatsAppButton />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
});

export default connect(mapStateToProps, { saveUserData })(VerifyOTPPage);
