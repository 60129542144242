/*
#############################################################################
# Name : CurrentOrders.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the current orders details
#############################################################################
*/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import AccountSidebar from "../AccountSidebar";
import DinnerHeader from "../../../../../components/dinnerHeader";
import DinerFooter from "../../../../../components/dinerFooter";
import WhatsAppButton from "../../../../../components/whatsappButton";
import DinerMenuTab from "../../../../../components/dinerMenuTab";
import {
  getDinerCurrentOrdersAPI,
  cancelDinerOrderAPI,
} from "../../../../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import LoadingDiv from "../../../../../components/LoadingDiv";
import Moment from "moment";
import { removeMobileView } from "../../../../../redux/actions/chefMenuActions";
import config from "../../../../../config/config"

class CurrentOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeIntervalSet: false,
      selectedCity:"",
      isLoading: true,
      currentOrders: [],
      cancelOrderId: "",
      cancelBtnText: "Yes, Cancel",
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    if(localStorage.getItem("selectedLocation")){
      var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
      this.setState({
        selectedCity: selectedCity
      })
    }
    this.getDinerCurrentOrdersAPI();      // Diner current order detail is fetched
  }

  setTimeInterval() {
    this.myInterval = setInterval(() => {
      let array = this.state.currentOrders;

      array.forEach((item) => {
        if (item.seconds <= 60) {
          item.seconds = item.seconds + 1;
        }
        if (item.seconds === 60) {
          if (item.minutes === 59) {
            item.hours = item.hours + 1;
            item.minutes = 0;
            item.seconds = 0;
          } else {
            item.minutes = item.minutes + 1;
            item.seconds = 0;
          }
        }
      });

      this.setState({
        currentOrders: array,
      });
    }, 1000);
  }

  /* getDinerCurrentOrdersAPI function starts here */
  getDinerCurrentOrdersAPI = () => {
    return getDinerCurrentOrdersAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          let array = response.data.data.order;
          array.forEach((item) => {
            var createdDate = new Date(item.created_at);
            var nextDate = new Date();
            nextDate = new Date(nextDate);
            var diff = (nextDate - createdDate) / 1000;
            var diff = Math.abs(Math.floor(diff));

            var days = Math.floor(diff / (24 * 60 * 60));
            var leftSec = diff - days * 24 * 60 * 60;

            var hrs = Math.floor(leftSec / (60 * 60));
            var leftSec = leftSec - hrs * 60 * 60;

            var min = Math.floor(leftSec / 60);
            var leftSec = leftSec - min * 60;

            item["hours"] = hrs;
            item["minutes"] = min;
            item["seconds"] = leftSec;
          })

          this.setState({
            currentOrders: array,
            isLoading: false,
          },
            () => {
              if (!this.state.timeIntervalSet) {
                this.setTimeInterval();
                this.setState({
                  timeIntervalSet: true,
                });
              }
            });
        } else {
          /* NotificationManager.error(response.data.message, "Error!"); */
          this.setState({
            isLoading: false,
          });
          if (response.status === 401) {
            NotificationManager.error("Session expired. Please log in.", "Error!");
            this.props.history.push('diner-signin-with-otp');
            localStorage.clear();
        }else{
          NotificationManager.error(response.data.message, "Error!");
        }
        }
      })
      .catch((error) => {
       /*  NotificationManager.error(error, "Error!"); */
        this.setState({
          isLoading: false,
        });
      });
  };
  /* getDinerCurrentOrdersAPI function ends here */
  
  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
  };
  /* getInerPage function ends here */

  /* handleCancelOrder function starts here */
  handleCancelOrder = () => {
    this.setState({
      cancelBtnText: "Cancelling...",
    });
    return cancelDinerOrderAPI(
      this.props.userData.userId,
      this.state.cancelOrderId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          document.getElementById("closeMenuFormButton").click();
          NotificationManager.success(
            "Order has been cancelled successfully.",
            "Success!"
          );
          this.setState({
            cancelOrderId: "",
            cancelBtnText: "Yes, Cancel",
          });
          this.getDinerCurrentOrdersAPI();
        } else {
          document.getElementById("closeMenuFormButton").click();
          NotificationManager.error(response.data.message, "Error!");
          this.setState({
            cancelOrderId: "",
            cancelBtnText: "Yes, Cancel",
          });
        }
      })
      .catch((error) => {
        document.getElementById("closeMenuFormButton").click();
        /* NotificationManager.error(error, "Error!"); */
        this.setState({
          cancelOrderId: "",
          cancelBtnText: "Yes, Cancel",
        });
      });
  };
    /* handleCancelOrder function ends here */

  /* handleTrackOrder function starts here */
  handleTrackOrder = (orderNumber) => {
    localStorage.setItem("trackOrderNumber", orderNumber);
    this.props.history.push("/track-diner-order");
  };
  /* handleTrackOrder function ends here */


  render() {
    if (this.props.userData.userType !== "Diner") {
      return <Redirect to="/" />;
    }
    const { history, mobileView } = this.props;
    const { isLoading, currentOrders, cancelBtnText } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : !currentOrders.length ? (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Current Orders
                      </h6>
                      <div className="inner">
                        <div className="not_found empty_section py-0">
                          <div className="text-center">
                            <img
                              src="assets/images/icons/your-cart-is-empty.svg"
                              alt="Not Found"
                              className="img-fluid"
                            />
                            <h6 className="justify-content-center">
                              NO CURRENT ORDERS
                            </h6>
                            <p>
                              We did not find any orders currently under <br />
                              progress. Please check your cart or past orders.
                            </p>
                            <Link to={"/"+this.state.selectedCity+"/diner-homepage"} className="btn">
                              START ORDERING!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        {/* <button className="res_back_btn d-flex d-lg-none">
                              <img
                                src="assets/images/icons/left-arrow-black.svg"
                                alt="left-arrow-black"
                              />
                            </button> */}
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Current Orders
                      </h6>
                      <div className="inner current_orders_card">
                        {currentOrders.map((order, i) => {
                          return (
                            <div
                              className={
                                order.is_takeaway_selected == "1"
                                  ? "order_wrap takeaway"
                                  : "order_wrap"
                              }
                              key={"tkxmf" + i}
                            >
                              <div className="order_title">
                                <h6 className="order_num">
                                  <span>
                                    {order.is_takeaway_selected == "0"
                                      ? "Delivery"
                                      : "Takeaway"}
                                  </span>
                                  Order Number : {"#" + order.order_number}
                                </h6>
                              </div>
                              <div className="save_add_wrap">
                                <div className="card">
                                  <div className="box title_price">
                                    <h6>
                                      Home Chef{" "}
                                      {order.first_name + " " + order.last_name}{" "}
                                      <span className="mb-0">INR {order.amount_paid} (including taxes and charges)</span>
                                    </h6>
                                    <p className="name_add add">
                                      {order.pickup_location}
                                    </p>
                                    {order.is_takeaway_selected == "1" && (
                                      <a
                                        target="_blank"
                                        href={
                                          "https://www.google.com/maps/dir/" +
                                          order.pickup_location
                                            .split(" ")
                                            .join("+") +
                                          "/" +
                                          order.drop_location
                                            .split(" ")
                                            .join("+")
                                        }
                                        className="link"
                                      >
                                        View on Google Maps
                                      </a>
                                    )}
                                  </div>
                                  <hr className="dash_line" />
                                  <div className="box">
                                    <h6 className="font-weight-normal badge_wrap_div">
                                      {order.order_details.map((item, i) => {
                                        return (
                                          <span
                                            key={"fshyt" + i}
                                            className="badge badge-light mr-2"
                                            style={{
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              backgroundColor: "#f2f2f2",
                                            }}
                                          >
                                            {item.item_name +
                                              " x " +
                                              item.itemQuantity}
                                          </span>
                                        );
                                      })}
                                    </h6>
                                    <p>
                                      Order placed :{" "}
                                      {Moment(order.created_at).format(
                                        "MMM DD, hh:mm a"
                                      )}
                                    </p>
                                    {/* <p>Delivery time slot : Nov 3, 5.00-6.00 pm</p> */}
                                    <p>
                                      Delivery time slot :{" "}
                                      {order.selected_time_slot}
                                    </p>
                                  </div>
                                  {
                                    order.hours === 0 && order.minutes < 15 && (
                                      <div>
                                        <hr className="dash_line" />
                                        <div className="box d-flex justify-content-end">
                                          <p
                                            data-toggle="modal"
                                            data-target="#LeaveModal"
                                            onClick={() =>
                                              this.setState({
                                                cancelOrderId: order.order_number,
                                              })
                                            }
                                            className="cancel_order_link"
                                          >
                                            Cancel Order
                                        </p>
                                        </div>
                                      </div>
                                    )
                                  }
                                </div>
                                <div className="d-flex justify-content-end">
								  <a
                                    target="_blank"
                                    className="btn add_new_btn"
                                    onClick={() =>
                                      this.getInerPage(order.chef_id, order.slug_url)
                                    }
                                  >
                                    Back To Chef Item's
                                  </a>
								  
                                  <a
                                    // href={order.diner_tracking_url ? order.diner_tracking_url : "#"}
                                    target="_blank"
                                    className="btn add_new_btn"
                                    onClick={() =>
                                      this.handleTrackOrder(order.order_number)
                                    }
                                  >
                                    track order
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade popup bottom_pop map_pop cancel_order_pop"
            id="LeaveModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="LeaveModalTitle"
            style={{ display: "none" }}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <button
                    type="button"
                    className="close_icon"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="assets/images/icons/black-close.svg"
                      alt="Close"
                    />
                  </button>
                  <h6>Cancel Order?</h6>
                  <p>Are you sure you want to cancel the order? </p>
                  <div className="btn-group">
                    <div>
                      <button
                        id="closeMenuFormButton"
                        className="btn previous_btn"
                        data-dismiss="modal"
                      >
                        no, don't cancel
                      </button>
                    </div>
                    <div>
                      <button className="btn" onClick={this.handleCancelOrder}>
                        {cancelBtnText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(CurrentOrders);
