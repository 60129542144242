/*
#############################################################################
# Name : dinerHeader.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display Diner header component
#############################################################################
*/
import React from "react";
import { NotificationManager } from "react-notifications";
import { Link, withRouter } from "react-router-dom";
import {
  getLocationListAPI,
  getAlertDinerDataCountAPI,
} from "../core/services/Phase2APIservice";
import { connect } from "react-redux";
import { removeUserData } from "../redux/actions/authActions";
import { emptyCart } from "../redux/actions/cartActions";
import { removeTimeSlot } from "../redux/actions/timeSlotActions";
import { removeChefData } from "../redux/actions/chefDataActions";
import { saveDinerAlertData } from "../redux/actions/alertActions";
import config from "../config/config";

class DinnerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showcontent: false,
      userId: "",
      selectedCity: "",
      alertData: "",
    };
  }

  componentDidMount() {
    if (this.props.alertData) {
      this.setState({
        alertData: this.props.alertData,
      });
    }
    var userId = localStorage.getItem("userID");
    this.setState({
      userId: userId,
    });
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: selectedCity,
      });
    }
    if (localStorage.getItem("userID")) {
      this.getAlertData();
    }
  }

  getAlertData = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getAlertDinerDataCountAPI(userId, token)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            alertData: response.data.unread_alerts_count,
          });
          this.props.saveDinerAlertData(response.data.unread_alerts_count);
        } else {
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.removeUserData();
            this.props.removeChefData();
            this.props.emptyCart();
            this.props.removeTimeSlot();
            localStorage.clear();
            this.props.history.push("/diner-signin-with-otp");
          }
        }
      })
      .catch(() => {
        /*  console.log(error); */
      });
  };

  getLocationList = async () => {
    var userId = localStorage.getItem("userID");
    try {
      const response = await getLocationListAPI(userId);
      if (response.data.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  logoutHandler = () => {
    this.props.removeUserData();
    this.props.removeChefData();
    this.props.emptyCart();
    this.props.removeTimeSlot();
    localStorage.clear();
    NotificationManager.success("You have Logout Successfully", "Successful!");
  };

  storageHandler = () => {
    localStorage.clear();
    this.props.removeUserData();
    this.props.removeChefData();
    this.props.emptyCart();
    this.props.removeTimeSlot();
  };

  getLocation = (event) => {
    event.preventDefault();
    if (this.props.history.location.pathname === "/checkout") {
      this.props.history.push("/pick-city?checkout");
    } else {
      this.props.history.push("/pick-city");
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { formErrors } = this.state;
    const { history, cartItems } = this.props;
    let quantity = 0;
    cartItems.forEach((item) => {
      quantity = quantity + item.qty;
    });
    return (
      <div>
        {localStorage.getItem("addressSelected") === null ? (
          <header>
            <div className="container menu_wrapper">
              <div className="logo">
                <a href="/">
                  <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                </a>
              </div>
              <div id="nav-icon" className=" d-block d-xl-none">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <ul className="dinner_menu">
                <li className="active">
                  {" "}
                  <a href="/" className="login_btn">
                    Home
                  </a>{" "}
                </li>
                <li className="">
                  {" "}
                  <a href="/diner-aboutus" className="login_btn">
                    About Us
                  </a>{" "}
                </li>
                <li className="">
                  {" "}
                  <a
                    href="https://wa.me/919899622242"
                    target="_blank"
                    className="login_btn"
                  >
                    Contact Us
                  </a>{" "}
                </li>
                <li className="">
                  {" "}
                  <a href="/pick-city" className="login_btn">
                    Pick A City
                  </a>{" "}
                </li>
                <li className="">
                  {" "}
                  <a href="/blog" className="login_btn">
                    Blog
                  </a>{" "}
                </li>
                <li className="d-block d-xl-none last_btn">
                  <a className="btn register_btn" href="/chef-homepage">
                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                  </a>
                </li>
              </ul>
              <ul className="dinner_menu d-none d-xl-block">
                <li>
                  <a className="btn register_btn" href="/chef-homepage">
                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                  </a>
                </li>
              </ul>
              <div className="menu_overlay"></div>
            </div>
          </header>
        ) : (
          <header className="header_phase2">
            <div className="container">
              <div className="logo">
                <Link
                  to={"/" + this.state.selectedCity + "/diner-homepage"}
                  className="logo_img"
                >
                  <img
                    src="/assets/images/icons/header-logo.svg"
                    alt="ChefPin"
                  />
                </Link>
                <p className="address dropdown">
                  {history.location.pathname ===
                    "/" + this.state.selectedCity + "/diner-homepage" ? (
                    localStorage.getItem("addressType") == "Home" ? (
                      <img
                        src="/assets/images/icons/house.svg"
                        alt="House Icon"
                      />
                    ) : localStorage.getItem("addressType") == "Work" ? (
                      <img
                        src="/assets/images/icons/portfolio.svg"
                        alt="House Icon"
                      />
                    ) : (
                      <img
                        src="/assets/images/icons/place-dark.svg"
                        alt="House Icon"
                      />
                    )
                  ) : (
                    <img
                      src="/assets/images/icons/left-arrow-black.svg"
                      alt="House Icon"
                      onClick={this.goBack}
                    />
                  )}
                  <span className="home" onClick={this.getLocation}>
                    {localStorage.getItem("addressDetailType")
                      ? localStorage.getItem("addressDetailType")
                      : localStorage.getItem("addressType")}
                  </span>
                  <span onClick={this.getLocation}>
                    {localStorage.getItem("landmark")
                      ? localStorage.getItem("flatNo") +
                      ", " +
                      localStorage.getItem("landmark") +
                      ", " +
                      localStorage.getItem("addressSelected")
                      : localStorage.getItem("flatNo")
                        ? localStorage.getItem("flatNo") +
                        ", " +
                        localStorage.getItem("addressSelected")
                        : localStorage.getItem("addressSelected")}
                  </span>
                </p>
              </div>

              <div className="mobile_search">
                <span className="icon search_icon">
                  <Link to={"/diner-search"}>
                    <img
                      src="/assets/images/icons/search-mobile.svg"
                      alt="Search"
                    />
                    <img
                      src="/assets/images/icons/search-mobile-orange.svg"
                      alt="Search"
                      className="orange_img"
                    />
                  </Link>
                </span>
              </div>
              <ul className="menu">
                {history.location.pathname !== "/checkout" &&
                  history.location.pathname !== "/diner-thank-you" && (
                    <li>
                      <a
                        href="/chef-homepage"
                        onClick={(e) => this.storageHandler(e)}
                      >
                        REGISTER AS A CHEF
                      </a>
                    </li>
                  )}
                <li
                  className={
                    history.location.pathname === "/diner-search"
                      ? "active"
                      : null
                  }
                >
                  <span className="icon search_icon">
                    <img
                      src="/assets/images/icons/search-header.svg"
                      alt="Search"
                    />
                    {/*  <span className="active">1</span> */}
                  </span>
                  <Link to="/diner-search">SEARCH</Link>
                </li>
                {localStorage.getItem("userID") && (
                  <li
                    className={
                      history.location.pathname === "/diner-alert"
                        ? "active"
                        : null
                    }
                  >
                    <span className="icon alerts_icon">
                      <img
                        src="/assets/images/icons/bell-header.svg"
                        alt="Bell"
                      />
                      <span
                        className={
                          this.state.alertData && this.props.alertData
                            ? "active"
                            : null
                        }
                      >
                        {this.props.alertData && this.props.alertData > 0
                          ? this.props.alertData
                          : null}
                      </span>
                    </span>
                    <Link to={"/diner-alert"}>ALERTS</Link>
                  </li>
                )}
                <li
                  className={
                    history.location.pathname === "/checkout" ? "active" : null
                  }
                >
                  <span className="icon alerts_icon">
                    <img
                      src="/assets/images/icons/shopping-bag.svg"
                      alt="Shopping Bag"
                    />
                    <span
                      className={this.props.cartItems.length ? "active" : null}
                    >
                      {quantity > 0 ? (quantity > 9 ? "9+" : quantity) : null}
                    </span>
                  </span>
                  <Link to="/checkout" style={{ position: "relative" }}>
                    CART
                  </Link>
                </li>
                <li className="icon cart_icon dropdown">
                  <span className="icon alerts_icon">
                    <img
                      src="/assets/images/icons/user-header.svg"
                      alt="search"
                    />
                  </span>
                  {localStorage.getItem("userID") === null ? null : (
                    <button
                      href="#"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      ACCOUNT
                    </button>
                  )}
                  {localStorage.getItem("userID") === null ? (
                    <Link
                      to="/user-selection"
                      onClick={(e) => this.storageHandler(e)}
                    >
                      SIGNUP
                    </Link>
                  ) : (
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {/*  <a className="dropdown-item" href="/" onClick={e => this.logoutHandler(e)}>Manage Address</a>
                                        <a className="dropdown-item" href="/" onClick={e => this.logoutHandler(e)}>Order History</a> */}
                      <Link className="dropdown-item" to="/manage-address">
                        manage address
                      </Link>
                      <Link className="dropdown-item" to="/current-orders">
                        order history
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/"
                        onClick={(e) => this.logoutHandler(e)}
                      >
                        log out
                      </Link>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    to="/party-order"
                  // onClick={(e) => this.storageHandler(e)}
                  >
                    PARTY ORDERS
                  </Link>
                </li>
              </ul>
              {/* <div className="form-group has-searchh">
                                <a href="/diner-search"><span> <img src="/assets/images/icons/search-header.svg" alt="Search" /></span>
                                    <input type="text" className="form-control" placeholder="Search" /></a>

                            </div> */}
            </div>
          </header>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  alertData: state.alert.items,
});

export default connect(mapStateToProps, {
  removeUserData,
  emptyCart,
  removeChefData,
  removeTimeSlot,
  saveDinerAlertData,
})(withRouter(DinnerHeader));
