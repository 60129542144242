/*
#############################################################################
# Name : App.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to define routes and all the packages of Chefpin
#############################################################################
*/
// React Backend crm
import DashboardSideBar from '../src/backendUI/DashboardSideBar';
import { URLs } from './backendUI/Url';
import BlogsScreen from './screens/diner/BlogsScreen';
import BlogDetail from './screens/diner/BlogDetails';
import React, { useEffect, useState, useWindowDimensions } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import history from './history';
import LandingPage from './screens/landing/landing';
import SignupPage from './screens/chefSignup/signup';
import VerifyOTPPage from './screens/chefSignup/verifyOTP';
import verfiyMobileOTPPage from '../src/screens/chefSignIn/verifyMobileOTP';
import LoginWithPasswordPage from './screens/chefSignIn/loginWithPassword';
import LoginWithOTPPage from './screens/chefSignIn/loginWithOTP';
import UserDetailsPage from './screens/chefSignup/userDetail';
import SubscribedPackagePaymentHistory from './screens/chefOrder/chefSubscribedPackagePaymentHistory';
import PolicyPage from './screens/landing/policy';
import EditProfilePage from './screens/profile/editProfile';
import ThankYouPage from './screens/landing/thankYou';
import TermsPage from './screens/landing/terms';
import UserAgreePage from './screens/landing/userAgree';
import ForgotPasswordPage from './screens/forgotPassword/forgotPassword';
import DinerHomepage from './screens/diner/dinerHomepage';
import PartyOrderScreen from "./screens/diner/PartyOrderPage";
import TiffinServiceScreen from "./screens/diner/TiffinServices";
import DeletePasswordEnquiry from "./screens/landing/DeletePassword";
import TiffinThankYouPage from "./screens/landing/TiffinThankYouPage";
import PartyThankYouPage from "./screens/landing/PartyThankYouPage";
import PickCityPage from './screens/pickcity/pickCity';
import UserSelectionPage from './screens/diner/userSelection';
import DinerVerifyMobileOTPPage from './screens/diner/signInDiner/dinerVerifyMobileOTP';
import DinerLoginWithPasswordPage from './screens/diner/signInDiner/dinerLoginWithPassword';
import DinerLoginWithOTPPage from './screens/diner/signInDiner/dinerLoginWithOTP';
import DinerSignUpPage from './screens/diner/signUpDiner/dinerSignUpPage';
import DinerSignUpVerifyPage from './screens/diner/signUpDiner/dinerSignUpVerify';
import DinerSignUpMobile from './screens/diner/signUpDiner/dinerSignUpMobile';
import DinerDashboardPage from './screens/diner/dinerDashboard/dinerDashboard';
import DinerForgotPasswordPage from './screens/forgotPassword/dinerForgotPassword';
import FetchLocationPage from './screens/pickcity/fetchLocation';
import DinerInnerPage from './screens/diner/dinerDashboard/dinerInnerPage';
import TrendingHomeChef from './screens/diner/dinerDashboard/trendingHomeChef';
import SelectedForYou from './screens/diner/dinerDashboard/selectedForYou';
import PopularHomeChef from './screens/diner/dinerDashboard/popularHomeChef';
import QuickFilter from './screens/diner/dinerDashboard/quickFilter/quickFilter';
import CrowdFavourite from './screens/diner/dinerDashboard/crowdFavourite/crowdFavourite';
import Collections from './screens/diner/dinerDashboard/collections/collections';
import VipHomeChef from './screens/diner/dinerDashboard/vipHomeChef';
import NewOneChefPin from './screens/diner/dinerDashboard/newOnChefPin';
import MustTryMeals from './screens/diner/dinerDashboard/mustTryMeals';
import DinerSearch from './screens/diner/dinerDashboard/dinerSearch/dinerSearch';
import MarketDetailPage from './screens/diner/dinerDashboard/marketingDetailPage';
import CheckoutPage from './screens/diner/dinerDashboard/checkout/dinerCheckoutPage';
import PaymentThankYouPage from './screens/diner/dinerDashboard/checkout/paymentThankyou';
import ManageAddress from './screens/diner/dinerDashboard/account/ManageAddress';
import PastOrders from './screens/diner/dinerDashboard/account/order-history/PastOrders';
import CurrentOrders from './screens/diner/dinerDashboard/account/order-history/CurrentOrders';
import CancelledOrders from './screens/diner/dinerDashboard/account/order-history/CancelledOrders';
import TrackOrder from './screens/diner/dinerDashboard/account/order-history/TrackOrder';
import PolicyDinerPage from './screens/diner/policyDiner';
import TermsDinerPage from './screens/diner/termsDiner';
import DinerAlertPage from './screens/diner/dinerDashboard/dinerAlert';
import Maintainance from './screens/Maintainance';
import ChefOrderInProgress from './screens/chefOrder/ChefOrderInProgress';
import DeliveredOrders from './screens/chefOrder/DeliveredOrders';
import CancelledOrder from './screens/chefOrder/CancelledOrder';
import ChefDashboard from './screens/chef-dashboard/ChefDashboard';
import DinerProfilePage from './screens/diner/dinerDashboard/account/dinerProfile';
import EmptyAccountState from './screens/diner/dinerDashboard/account/emptyAccountState';
import RefundStatus from './screens/diner/dinerDashboard/account/RefundStatus';
import MyReviews from './screens/diner/dinerDashboard/account/MyReviews';
import FAQDinerPage from './screens/diner/faqDiner';
import ChefMembershipPage from './screens/chefOrder/chefMemberShip';
import FAQPage from './screens/landing/faq';
import AboutUsChefPage from './screens/landing/aboutusChef';
import AboutUsDinerPage from './screens/diner/aboutusDiner';
import MediaEventsPage from "./screens/diner/MediaEventsPage";
import NewBlogPage1 from "./screens/diner/NewBlogPage1";
import NewBlogPage2 from "./screens/diner/NewBlogPage2";
import NewBlogPage3 from "./screens/diner/NewBlogPage3";
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
  useLocation
} from 'react-router-dom';

import ScrollToTop from './ScrollToTop';
// React Notification
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { gapi } from 'gapi-script';
import NotFound from '../src/screens/404';
import ChefReview from '../src/screens/chefOrder/chefReview';
import ChefBlog from './screens/chefOrder/chefBlog';
import DinerBookmark from './screens/diner/dinerDashboard/account/dinerBookMark';
import ArticleData from './screens/diner/articleData';
import ChefAlertPage from './screens/chefOrder/chefAlerts';
import ReactPixel from 'react-facebook-pixel';
import DeepLinking from './screens/deep-linking';
import config from './config/config';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
// import GA4React, { useGA4React } from "ga-4-react";
const persistor = persistStore(store);

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
};

const App = () => {
  const [redirect, setRedirect] = useState(isMobile && (isAndroid || isIOS));

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  useEffect(() => {
    OneSignal.push(() => {
      OneSignal.init({
        // appId: "858ead9d-4613-4828-8e83-ce4e26539af4",     Arvind ID
        appId: '6ca82d47-a584-42fe-b8f5-9549a77cf19b',
        notifyButton: {
          enable: true
        }
      });
      OneSignal.showNativePrompt();
      try {
        OneSignal.getUserId().then(function (userId) {
          console.log('OneSignal User ID:', userId);
          localStorage.setItem('OneSignal_playerId', userId);
        });
        OneSignal.on('subscriptionChange', function (isSubscribed) {
          console.log('is subscribed', isSubscribed);
          OneSignal.push(function () {
            console.log('attempt to get id'); // doesn't get this far
            OneSignal.getUserId(function (userId) {
              console.log('user id', userId); // doesn't get this far
            });
          });
        });
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

	gapi.load('client:auth2', () => {
		gapi.client.init({
			clientId: config.CLIENT_ID,
			plugin_name: 'chat',
		});
	});

  useEffect(() => {
    ReactPixel.init('344072553470177', options);
    ReactPixel.pageView();
  }, []);
  const checkauthentication = localStorage.getItem('authentication');
  console.log(checkauthentication);
  const fakeAuth = {
    isAuthenticated: checkauthentication == 'true' ? true : false,
    authenticate(cb) {
      fakeAuth.isAuthenticated = true;
      setTimeout(cb, 100); // fake async
    },
    unauthenticate(cb) {
      fakeAuth.isAuthenticated = false;
      setTimeout(cb, 100);
    }
  };
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          return fakeAuth.isAuthenticated === true ? (
            children
          ) : (
            <Redirect
              to={{ pathname: '/DashLogin', state: { from: location } }}
            />
          );
        }}
      />
    );
  }
  const DashLogin = () => {
    const [size, setSize] = useState({
      x: window.innerWidth,
      y: window.innerHeight
    });
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const { state } = useLocation();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const handleClick = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
          email: email,
          password: password
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        const response = await fetch(
          URLs.domain + '/api/admin-login',
          requestOptions
        );
        const json = await response.json();
        console.log(json);
        if (json.success == true) {
          console.log(json.data);
          login();
          // navigate("/Dashboard");
        }
      } catch (err) {
        console.log(err);
      }
    };
    const login = () => {
      fakeAuth.authenticate(() => {
        setRedirectToReferrer(true);
        localStorage.setItem('authentication', 'true');
      });
    };
    if (redirectToReferrer === true) {
      return <Redirect to={state?.from || '/'} />;
    }
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            width: 500,
            height: 500,
            alignSelf: 'center'
          }}
        >
          <form>
            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                id='exampleInputEmail1'
                aria-describedby='emailHelp'
                placeholder='Email ID*'
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                id='Password'
                placeholder='Password*'
                name='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type='button'
              className='btn'
              style={{ marginRight: 10 }}
              onClick={handleClick}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  };

  // const ga = useGA4React();
  const maintainanceMode = false;
  if (maintainanceMode) {
    return <Maintainance />;
  }

  return redirect === true ? (
    <DeepLinking redirect={redirect} setRedirect={setRedirect} />
  ) : (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop>
            <Switch history={history}>
              <PrivateRoute path='/Dashboard'>
                <DashboardSideBar />
              </PrivateRoute>
              <Route exact path='/DashLogin' component={DashLogin} />
              {/* <Route exact path="/Dashboard" component={DashboardSideBar} /> */}

              {/* <Route path="header" component={<DashHeader />} /> */}

              <Route exact path='/' component={DinerHomepage} />
			  <Route exact path="/party-order" component={PartyOrderScreen} />
			  <Route exact path="/tiffin-services" component={TiffinServiceScreen} />
			  <Route exact path="/delete-your-acccout" component={DeletePasswordEnquiry} />
			  <Route exact path="/media-events" component={MediaEventsPage} />
              <Route exact path='/chef-homepage' component={LandingPage} />
              <Route exact path='/signup' component={SignupPage} />
              <Route
                exact
                path='/signin-with-otp'
                component={LoginWithOTPPage}
              />
              <Route
                exact
                path='/signin-with-password'
                component={LoginWithPasswordPage}
              />
              <Route exact path='/verfiyotp' component={VerifyOTPPage} />
              <Route
                exact
                path='/verfiyMobileOtp'
                component={verfiyMobileOTPPage}
              />
              <Route exact path='/chef-subscribed-package-payment-history' component={SubscribedPackagePaymentHistory} />
              <Route exact path='/user-details' component={UserDetailsPage} />
              <Route exact path='/chef-policy' component={PolicyPage} />
              <Route exact path='/edit-profile' component={EditProfilePage} />
              <Route exact path='/thank-you' component={ThankYouPage} />
			  <Route path="/tiffin-service-thank-you" component={TiffinThankYouPage} />
              <Route path="/party-order-thank-you" component={PartyThankYouPage} />
              <Route exact path='/user-agreement' component={UserAgreePage} />
              <Route
                exact
                path='/forgot-password'
                component={ForgotPasswordPage}
              />
              <Route exact path='/chef-terms-of-use' component={TermsPage} />
              <Route exact path='/pick-city' component={PickCityPage} />
              <Route exact path='/get-location' component={FetchLocationPage} />
              <Route
                exact
                path='/user-selection'
                component={UserSelectionPage}
              />
              <Route
                exact
                path='/diner-signin-with-otp'
                component={DinerLoginWithOTPPage}
              />
              <Route
                exact
                path='/diner-signin-with-password'
                component={DinerLoginWithPasswordPage}
              />
              <Route
                exact
                path='/diner-verfiyotp'
                component={DinerVerifyMobileOTPPage}
              />
              <Route exact path='/diner-signup' component={DinerSignUpPage} />
              <Route
                exact
                path='/diner-signup-verify'
                component={DinerSignUpVerifyPage}
              />
              <Route
                exact
                path='/diner-signup-mobile'
                component={DinerSignUpMobile}
              />
              <Route
                exact
                path='/:slug/diner-homepage'
                component={DinerDashboardPage}
              />
              <Route
                exact
                path='/:city/chef-inner-homepage/:slug'
                component={DinerInnerPage}
              />
              <Route
                exact
                path='/:city/trending-home-chef'
                component={TrendingHomeChef}
              />
              <Route
                exact
                path='/selected-for-you'
                component={SelectedForYou}
              />
              <Route
                exact
                path='/:city/vip-home-chef'
                component={VipHomeChef}
              />
              <Route
                exact
                path='/:city/new-on-chef-pin'
                component={NewOneChefPin}
              />
              <Route
                exact
                path='/:city/must-try-meals'
                component={MustTryMeals}
              />
              <Route
                exact
                path='/:city/popular-home-chef'
                component={PopularHomeChef}
              />
              <Route
                exact
                path='/diner-forgot-password'
                component={DinerForgotPasswordPage}
              />
              <Route
                exact
                path='/:city/quick-filter/:slug'
                component={QuickFilter}
              />
              <Route
                exact
                path='/:city/crowd-favourite/:slug'
                component={CrowdFavourite}
              />
              <Route
                exact
                path='/:city/diner-collections/:slug'
                component={Collections}
              />
              <Route exact path='/diner-search' component={DinerSearch} />
              <Route
                exact
                path='/:city/marketing-cards/:slug'
                component={MarketDetailPage}
              />
			  {/*<Route
                exact
                path='/:city/marketing-detail/:slug'
                component={MarketDetailPage}
              />*/}
              <Route exact path='/checkout' component={CheckoutPage} />
              <Route
                exact
                path='/diner-thank-you'
                component={PaymentThankYouPage}
              />
              <Route exact path='/manage-address' component={ManageAddress} />
              <Route exact path='/past-orders' component={PastOrders} />
              <Route exact path='/current-orders' component={CurrentOrders} />
              <Route
                exact
                path='/cancelled-orders'
                component={CancelledOrders}
              />
              <Route exact path='/diner-alert' component={DinerAlertPage} />
              <Route
                exact
                path='/orders-in-progress'
                component={ChefOrderInProgress}
              />
              <Route
                exact
                path='/delivered-orders'
                component={DeliveredOrders}
              />
              <Route
                exact
                path='/chef-cancelled-orders'
                component={CancelledOrder}
              />
              <Route exact path='/diner-profile' component={DinerProfilePage} />
              <Route exact path='/track-diner-order' component={TrackOrder} />
              <Route exact path='/chef-dashboard' component={ChefDashboard} />
              <Route exact path='/empty-state' component={EmptyAccountState} />
              <Route exact path='/my-reviews' component={MyReviews} />
              <Route exact path='/refund-status' component={RefundStatus} />
              <Route exact path='/diner-faq' component={FAQDinerPage} />
              <Route exact path='/chef-faq' component={FAQPage} />
              <Route
                exact
                path='/diner-terms-of-use'
                component={TermsDinerPage}
              />
              <Route exact path='/diner-policy' component={PolicyDinerPage} />
              <Route
                exact
                path='/chef-membership'
                component={ChefMembershipPage}
              />
              <Route exact path='/chef-aboutus' component={AboutUsChefPage} />
              <Route exact path='/diner-aboutus' component={AboutUsDinerPage} />
              <Route exact path='/chef-review' component={ChefReview} />
              <Route exact path='/chef-blog' component={ChefBlog} />
              <Route exact path='/chef-alert' component={ChefAlertPage} />
              <Route exact path='/diner-bookmark' component={DinerBookmark} />
              <Route exact path='/blog' component={BlogsScreen} />
              <Route exact path="/15-top-biryani-home-chefs-in-delhi-ncr" component={NewBlogPage1} />
              <Route exact path="/top-home-chefs-in-noida" component={NewBlogPage2} />
              <Route exact path="/top-home-cook-in-bangalore" component={NewBlogPage3} />
			  <Route exact path='/blog/:slug' component={BlogDetail} />
              <Route exact path='/:slug' component={ArticleData} />
              {/* <Route path="*" component={NotFound} /> */}
            </Switch>
            <NotificationContainer />
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
