/*
#############################################################################
# Name : dinerAlert.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the diner alert data
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../components/dinnerHeader";
import DinerFooter from "../../../components/dinerFooter";
import DinerMenuTab from "../../../components/dinerMenuTab";
import WhatsAppButton from "../../../components/whatsappButton";
import { Link, Redirect } from "react-router-dom";
import config from "../../../config/config";
import { getAlertDinerDataAPI } from "../../../core/services/Phase2APIservice";
import Moment from "moment";
import Pagination from "react-js-pagination";
import { removeMobileView } from "../../../redux/actions/chefMenuActions";
import { saveDinerAlertData } from "../../../redux/actions/alertActions";
import { connect } from "react-redux";
class DinerAlertPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCity: "",
      alertData: [],
      activePage: 1,
      totalRecords: 10,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: selectedCity,
      });
    }
    this.getAlertData();
  }

  getAlertData = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var currentPage = this.state.activePage;
    return getAlertDinerDataAPI(userId, token, currentPage)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            alertData: response.data.data,
            totalRecords: response.data.total_alerts_count,
          });
          this.props.saveDinerAlertData(response.data.unread_alerts_count);
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getAlertData();
    });
  }
  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        {this.state.alertData ? (
          <section className="alert_sec chef_flow registration bussiness">
            <div className="container">
              {/* <Link
                to={"/" + this.state.selectedCity + "/diner-homepage"}
                className="res_back_btn"
              >
                <img
                  src="assets/images/icons/left-arrow-black.svg"
                  alt="left-arrow-black"
                />
              </Link> */}
              <h6 className="res_back_btn_wrap">ALERTS</h6>

              <ul className="alert_list">
                {this.state.alertData.map((alert, a) => {
                  return (
                    <li key={"alert" + a}>
                      <span
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: alert.message,
                        }}
                      ></span>
                      <ul className="day_time">
                        <li>
                          <span>{Moment(alert.created_at).format("ll")}</span>
                          <span>{Moment(alert.created_at).format("LT")}</span>
                        </li>
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="text-right">
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={10}
                totalItemsCount={this.state.totalRecords}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </section>
        ) : (
          <section className="not_found empty_section">
            <div className="container text-center">
              <div className="res_back_btn_wrap text-left p-0">
                <Link
                  to={"/" + this.state.selectedCity + "/diner-homepage"}
                  className="res_back_btn"
                >
                  <img
                    src="assets/images/icons/left-arrow-black.svg"
                    alt="left-arrow-black"
                  />
                </Link>
              </div>
              <img
                src="assets/images/icons/no-notifications.svg"
                alt="Not Found"
                className="img-fluid"
              />
              <h6>NO NOTIFICATIONS</h6>
              <p>
                There are no notifications for you to see
                <br /> at this time. You have been notified!
              </p>
              <Link
                to={"/" + this.state.selectedCity + "/diner-homepage"}
                className="btn"
              >
                GO BACK TO HOMEpage
              </Link>
            </div>
          </section>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  alertData: state.alert.items,
});

export default connect(mapStateToProps, {
  saveDinerAlertData,
  removeMobileView,
})(DinerAlertPage);
