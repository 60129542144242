import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import LoadingDiv from "../../components/LoadingDiv";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import {
    EditorState,
    convertFromHTML,
    ContentState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {
    BlogDetailData,
    BlogData,
    BlogCategoryData,
} from "../../backendUI/Blog/config";
import { getDays, getMonth } from "../../backendUI/Fun";
import { URLs } from "../../backendUI/Url";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TumblrShareButton,
    TwitterShareButton,
} from "react-share";
const html = `<p></p>`;

class NewBlogPage3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromText(html)
            ),
        };
        this.state = {
            isLoading: true,
            hideContent: window.matchMedia("(max-width: 991px)").matches,
            articleTag: [],
            articleTitle: "",
            author: "",
            authorImage: "",
            articleContent: "",
            catgory: "",
            time: "",
            categorydata: [],
            data: [],
        };
    }
    // 63ad512db0441bf02241a2c2


    getblogcatdata = () => {
        BlogCategoryData().then((response) => {
            if (response.success == true) {
                this.setState({
                    categorydata: response.data,
                });
            }
        });
    };
    render() {
        // console.log(window.location.href);
        return (
            <div className="css2">
                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li>
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/diner-aboutus" className="login_btn">
                                    About Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a
                                    href="https://wa.me/919899622242"
                                    target="_blank"
                                    className="login_btn"
                                >
                                    Contact Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/pick-city" className="login_btn">
                                    Pick A City
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/blog" className="login_btn">
                                    Blog
                                </a>{" "}
                            </li>
                            <li className="d-block d-xl-none last_btn">
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="dinner_menu d-none d-xl-block">
                            <li>
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <div className="menu_overlay"></div>
                    </div>
                </header>

                <div className="container pt-5">
                    <div className="row align-self">
                        <div className="col-md-12 m-0 p-0">
                            <div className="container">
                                <p className="text-center color_a05">Latest Updates

                                </p>
                                <span className="text-center mt-2">
                                    <h1 className="color_a05">Home Cooks in Bangalore You Must Order from</h1>
                                </span>
                                <div className="d-flex align-items-center justify-content-center row m-0 p-0">
                                    <span>POSTED ON</span>
                                    <span className="text-center color_a05">
                                        &nbsp;
                                        JAN 9, 2023
                                    </span>
                                    &nbsp;
                                    <span>BY</span> &nbsp;
                                    <span className="text-center color_a05">
                                        AMEETA AGNIHOTRI

                                    </span>
                                </div>

                                {/* <Editor
                                        toolbarHidden
                                        editorState={this.state.editorState}
                                        readOnly
                                    /> */}
                                <br></br>

                                {/* <h2> Working as a home cook? Want to increase your business? </h2> */}
                                <p>Bangalore, widely regarded as the Silicon Valley of India, is renowned not only for its dynamic technological community but also for its haute cuisine. And not just cuisine you find at restaurants but the local cuisine offered from home to home in this city. Through the Chef Pin app, foodies are now able to embark on a delightful voyage in search of the <b> <a href="/best-home-chefs-in-bangalore">best home chefs in Bangalore </a> </b>. Below is an insight into some of the city's most accomplished home chefs who are revolutionizing the home dining experience.
                                </p>




                                <hr />
                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Fayona Kitchen: A Culinary Journey with Syeda Tarannum Shariff</h3>
                                                        <h6> by Syeda Tarannum Shariff </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    {/* <h6>Bakery And Confectionery</h6> */}
                                                    <p>
                                                        Chef Syeda Tarannum Shariff blends her B.Com background and Dubai corporate experience into her culinary venture, Fayona Kitchen. Established in 2019, this Bangalore-based kitchen is a  top destination for biryani near me and a haven for non-vegetarian and Arabic cuisine enthusiasts.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 11:00 AM- 06:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/bengaluru/chef-inner-homepage/fayona-kitchen" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/fayona-kitchen.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Lady & Ladle: Fathima Riyaz's Homestyle Delicacies</h3>
                                                        <h6>by Fathima Riyaz</h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Chef Fathima Riyaz transitioned from delighting family and friends searching for <b> <a href="/homemade-foods-online-in-bangalore"> homemade foods online in Bangalore </a> </b> to serving a wider community. Her expertise lies in Bhatkali cuisine, with specialties like Bhatkal Khichdi and Bhatkal Biryani, and a mix of South Indian and Chinese dishes.

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 11:00 AM- 08:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/bengaluru/chef-inner-homepage/lady-ladle" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Lady&Ladle.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Lazeez Khaana: Nausheen Sait's Homely Flavors
                                                        </h3>
                                                        <h6>by Nausheen Sait </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Chef Nausheen Sait, a home chef and catering business owner, brings a familial touch to her cooking, offering a range of dishes from Samosa to Butter Chicken. Lazeez Khaana is a testament to her love for cooking and serving quality food across Bangalore.

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 01:00 AM- 09:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/bengaluru/chef-inner-homepage/lazeez-khaana" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/LazeezKhaana.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Joyful Kitchen: Andrea Cerllo's Artistic Cuisine</h3>
                                                        <h6>by Andrea Cerllo </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Chef Andrea Cerllo finds joy in culinary creativity and likens her kitchen to an artist's studio. Specializing in multi-cuisine dishes like Pork Vindaloo and Chilli Beef, Joyful Kitchen offers unique, creative dishes  that reflect her passion for cooking

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 10:00 AM- 05:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/bengaluru/chef-inner-homepage/joyful-kitchen" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/JoyfulKitchen.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>


                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Savithri's Kitchen: Savithri Ramakrishna's Vegetarian Delights
                                                        </h3>
                                                        <h6>by Savithri Ramakrishna </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        The <b> <a href="/home-chefs-service-in-bangalore"> home chef service in Bangalore </a></b>, Chef Savithri Ramakrishna specializes in authentic South Indian vegetarian cuisine, crafting dishes like Sweet Pongal and Paneer Mixed Veg with homemade masalas. Her kitchen invites patrons to savor the richness of traditional Indian flavors.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 10:00 AM- 08:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/bengaluru/chef-inner-homepage/savithri-s-kitchen" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Savithri'sKitchen.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>The Inked Baker: Reshma D'Souza's Pastry Innovations</h3>
                                                        <h6>by Reshma D'Souza </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Chef Reshma D'Souza, a dessert aficionado, combines her love for science with baking, resulting in constantly evolving menu offerings like Sourdough Breads and Baked Cheesecake. The Inked Baker caters to those seeking innovative and delicious bakes and looking for a cakes shop near me in Bangalore.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 02:00 PM - 03:00 PM (Tue-Sat)
                                                    </p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/bengaluru/chef-inner-homepage/the-inked-baker" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/InkedBaker.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Shilpa Gulati: Baking with Heart and Art
                                                        </h3>
                                                        <h6>by Shilpa Gulati </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Chef Shilpa Gulati pours her passion and warmth into baking, offering a variety of sweet treats like Icecream Cake and Cheese Cakes. With over 20 years of experience, she continues to inspire with her dedicated and affectionate approach to baking and ideal for those searching for bakeries near me in Bangalore.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 11:00 AM- 05:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/bengaluru/chef-inner-homepage/shilpa-gulati" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/GulatiBaking.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>





                            </div>
                        </div>

                    </div>
                </div>

                <div className={this.state.hideContent ? "nofooter" : null}>
                    <WhatsAppButton />
                </div>

                <DinerMenuTab />
                <DinerFooter />
            </div>
        );
    }
}

export default NewBlogPage3;
