/*
#############################################################################
# Name : dinerLoginWithOTP.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to login with the mobile number
#############################################################################
*/
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  getMobileLoginAPI,
  dinerSigninAPI,
  getUserLocationAPI
} from '../../../core/services/APIservice';
import { GoogleLogin } from 'react-google-login';

import FacebookLogin from 'react-facebook-login';

import { NotificationManager } from 'react-notifications';
import config from '../../../config/config';
import Recaptcha from 'react-google-invisible-recaptcha';
import DinnerHeader from '../../../components/dinnerHeader';
import WhatsAppButton from '../../../components/whatsappButton';
import { connect } from 'react-redux';
import { saveUserData } from '../../../redux/actions/authActions';
import { Helmet } from 'react-helmet';

/* Regex code for validation starts here */
const numberReg = /^[0]?[6789]\d{9}$/;
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === 'form-control' && (valid = false);
  });

  return valid;
};
/* Regex code for validation ends here */

class DinerLoginWithOTPPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      hideContent: window.matchMedia('(max-width: 991px)').matches,
      formErrors: {
        mobileNumber: ''
      },
      buttonText: 'log in with otp',
      buttonDisabled: false,
      fbAuth: false,
      selectedCity: ''
    };

    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  componentDidMount() {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    try {
      OneSignal.getUserId().then(function (userId) {
        localStorage.setItem('OneSignal_playerId', userId);
      });
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log('is subscribed', isSubscribed);
        OneSignal.push(function () {
          console.log('attempt to get id'); // doesn't get this far
          OneSignal.getUserId(function (userId) {
            console.log('user id', userId);
            localStorage.setItem('OneSignal_playerId', userId); // doesn't get this far
          });
        });
      });
    } catch (error) {
      console.log('Error: ', error);
    }
    this.myInterval = setInterval(this.triggerFacebookLogin, 2000);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    localStorage.setItem('closePopup', true);
    if (localStorage.getItem('selectedLocation')) {
      var selectedCity = config.slugify(
        localStorage.getItem('selectedLocation')
      );
      this.setState({
        selectedCity: '/' + selectedCity + '/diner-homepage'
      });
    }
  }

  /* handleMobileChange function starts here */
  handleMobileChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'mobileNumber':
        formErrors.mobileNumber =
          value === ''
            ? 'Mobile Number is required.'
            : !numberReg.test(e.target.value)
            ? 'Enter valid Mobile Number.'
            : '';
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
  /* handleMobileChange function ends here */

  /* submitContactForm function starts here */
  submitContactForm = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.mobileNumber === '') {
      formErrors.mobileNumber = 'Mobile Number is required';
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.setState({
        buttonText: 'Sending OTP...',
        buttonDisabled: true
      });
      this.recaptcha.execute();
      localStorage.setItem('userMobile', this.state.mobileNumber);
      return getMobileLoginAPI(this.state.mobileNumber)
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              buttonText: 'log in with otp',
              buttonDisabled: false
            });
            if (this.props.location.search === '?checkout') {
              this.props.history.push('/diner-verfiyotp?checkout');
            } else if (this.props.location.search === '?chef-innerpage') {
              this.props.history.push('/diner-verfiyotp?chef-innerpage');
            } else if (
              this.props.location.search !== '' &&
              this.props.location.search.indexOf('redirect=') !== -1
            ) {
              this.props.history.push(
                '/diner-verfiyotp' + this.props.location.search
              );
            } else {
              this.props.history.push('/diner-verfiyotp');
            }
          } else {
            this.setState({
              buttonText: 'log in with otp',
              buttonDisabled: false
            });
            NotificationManager.error(response.data.message, 'Error!');
          }
        })
        .catch((error) => {
          this.setState({
            buttonText: 'log in with otp',
            buttonDisabled: false
          });
          this.recaptcha.reset();
          NotificationManager.error('Error in Login', 'Error!');
        });
    }
  };
  /* submitContactForm function ends here */

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  /* responseFacebook function starts here */
  responseFacebook = () => {
    let email = localStorage.getItem('facebook_user_email');
    // if (response.email) {
    if (email && email !== '' && email !== null) {
      return dinerSigninAPI(email, '', 'Facebook')
        .then((response) => {
          if (response.data['status'] === 200) {
            var token = response.data['data']['api_access_token'];
            var userId = response.data['data']['user']['user_id'];
            var userEmail = response.data['data']['user']['email'];
            var userName = response.data['data']['user']['name'];
            var userMobile = response.data['data']['user']['mobile_number'];
            var image = response.data['data']['user']['profile_image'];
            localStorage.setItem('userID', userId);
            localStorage.setItem('userEmail', userEmail);
            localStorage.setItem('userName', userName);
            localStorage.setItem('userImage', image);
            localStorage.setItem('accessToken', token);
            localStorage.setItem('userMobile', userMobile);
            let data = {
              userType: response.data.is_chef ? 'Chef' : 'Diner',
              userId: response.data['data']['user']['user_id'],
              userEmail: response.data['data']['user']['email'],
              userName: response.data['data']['user']['name'],
              userMobile: response.data['data']['user']['mobile_number'],
              image: response.data['data']['user']['profile_image'],
              verifid: response.data['data']['user']['profile_verified'],
              accessToken: response.data['data']['api_access_token']
            };
            this.props.saveUserData(data);
            localStorage.removeItem('facebook_logged_in');
            localStorage.removeItem('facebook_user_email');
            localStorage.removeItem('facebook_user_firstname');
            localStorage.removeItem('facebook_user_lastname');
            localStorage.removeItem('facebook_user_picture');
            localStorage.removeItem('facebook_user_token');
            if (
              response.data.is_admin == false &&
              response.data.is_chef == true
            ) {
              if (this.props.location.search === '?checkout') {
                return getUserLocationAPI(
                  localStorage.getItem('userID'),
                  localStorage.getItem('accessToken'),
                  localStorage.getItem('latitude'),
                  localStorage.getItem('longitude'),
                  localStorage.getItem('selectedLocationName'),
                  localStorage.getItem('addressSelected'),
                  localStorage.getItem('pincode'),
                  localStorage.getItem('addressType'),
                  localStorage.getItem('addressDetailType'),
                  localStorage.getItem('flatNo'),
                  localStorage.getItem('landmark')
                )
                  .then((response) => {
                    if (response.data['status'] === 200) {
                      localStorage.setItem(
                        'prevLocationId',
                        response.data.diner_location_id
                      );
                      NotificationManager.success(
                        response.data['message'],
                        'Successful!',
                        3000
                      );
                      this.props.history.push('/checkout');
                    } else {
                      NotificationManager.error(
                        response.data['message'],
                        'Error!'
                      );
                    }
                  })
                  .catch((error) => {
                    /* console.log(error); */
                  });
              } else {
                this.props.history.push('/user-details');
              }
            } else {
              if (response.data['data']['location'] == null) {
                this.props.history.push('/pick-city');
              } else {
                var address = response.data['data']['location'];
                var addressType = response.data['data']['type'];
                var latitude = response.data['data']['latitude'];
                var longtitude = response.data['data']['longitude'];
                var city = response.data['data']['city'];
                var flatNo = response.data['data']['address_line1'];
                var landmark = response.data['data']['address_line2'];
                var otherType = response.data['data']['location_title'];
                localStorage.setItem('flatNo', flatNo);
                localStorage.setItem('landmark', landmark);
                localStorage.setItem('addressSelected', address);
                localStorage.setItem('addressType', addressType);
                localStorage.setItem('latitude', latitude);
                localStorage.setItem('longitude', longtitude);
                localStorage.setItem('selectedLocation', city);
                localStorage.setItem('selectedLocationName', city);
                if (otherType) {
                  localStorage.setItem('addressDetailType', otherType);
                }
                if (this.props.location.search === '?checkout') {
                  return getUserLocationAPI(
                    localStorage.getItem('userID'),
                    localStorage.getItem('accessToken'),
                    localStorage.getItem('latitude'),
                    localStorage.getItem('longitude'),
                    localStorage.getItem('selectedLocationName'),
                    localStorage.getItem('addressSelected'),
                    localStorage.getItem('pincode'),
                    localStorage.getItem('addressType'),
                    localStorage.getItem('addressDetailType'),
                    localStorage.getItem('flatNo'),
                    localStorage.getItem('landmark')
                  )
                    .then((response) => {
                      if (response.data['status'] === 200) {
                        localStorage.setItem(
                          'prevLocationId',
                          response.data.diner_location_id
                        );
                        NotificationManager.success(
                          response.data['message'],
                          'Successful!',
                          3000
                        );
                        this.props.history.push('/checkout');
                      } else {
                        NotificationManager.error(
                          response.data['message'],
                          'Error!'
                        );
                      }
                    })
                    .catch((error) => {
                      /* console.log(error); */
                    });
                } else {
                  let selectedCity = config.slugify(city);
                  if (selectedCity == localStorage.getItem('chefCity')) {
                    this.props.history.push(
                      '/' +
                        localStorage.getItem('chefCity') +
                        '/chef-inner-homepage/' +
                        localStorage.getItem('chefSlug')
                    );
                  } else {
                    this.props.history.push(
                      '/' + selectedCity + '/diner-homepage'
                    );
                  }
                }
              }
            }
            NotificationManager.success(
              'Welcome to CHEFPIN ' + userName,
              'Successful!',
              3000
            );
          } else if (response.status === 500) {
            NotificationManager.error(response.data.message, 'Error!');
          } else if (response.data.status === 405) {
            NotificationManager.error(response.data.message, 'Error!');
          } else {
            NotificationManager.error(response.data['message'], 'Error!');
          }
        })
        .catch((error) => {
          /* console.log(error); */
          NotificationManager.error(
            'Something went wrong while Login',
            'Error!'
          );
        });
    } else {
      NotificationManager.error('Use Email Id for Login', 'Error!');
    }
    // }
  };
  /* responseFacebook function ends here */

  /* login function starts here */
  login = (response) => {
	
    return dinerSigninAPI(response.profileObj['email'], '', 'Google')
      .then((response) => {
        if (response.data['status'] === 200) {
          var token = response.data['data']['api_access_token'];
          var userId = response.data['data']['user']['user_id'];
          var userEmail = response.data['data']['user']['email'];
          var userName = response.data['data']['user']['name'];
          var userMobile = response.data['data']['user']['mobile_number'];
          var image = response.data['data']['user']['profile_image'];
          localStorage.setItem('userID', userId);
          localStorage.setItem('userEmail', userEmail);
          localStorage.setItem('userName', userName);
          localStorage.setItem('userImage', image);
          localStorage.setItem('accessToken', token);
          localStorage.setItem('userMobile', userMobile);
          let data = {
            userType: response.data.is_chef ? 'Chef' : 'Diner',
            userId: response.data['data']['user']['user_id'],
            userEmail: response.data['data']['user']['email'],
            userName: response.data['data']['user']['name'],
            userMobile: response.data['data']['user']['mobile_number'],
            image: response.data['data']['user']['profile_image'],
            verifid: response.data['data']['user']['profile_verified'],
            accessToken: response.data['data']['api_access_token']
          };
          this.props.saveUserData(data);
          if (
            response.data.is_admin == false &&
            response.data.is_chef == true
          ) {
            if (this.props.location.search === '?checkout') {
              return getUserLocationAPI(
                localStorage.getItem('userID'),
                localStorage.getItem('accessToken'),
                localStorage.getItem('latitude'),
                localStorage.getItem('longitude'),
                localStorage.getItem('selectedLocationName'),
                localStorage.getItem('addressSelected'),
                localStorage.getItem('pincode'),
                localStorage.getItem('addressType'),
                localStorage.getItem('addressDetailType'),
                localStorage.getItem('flatNo'),
                localStorage.getItem('landmark')
              )
                .then((response) => {
                  if (response.data['status'] === 200) {
                    localStorage.setItem(
                      'prevLocationId',
                      response.data.diner_location_id
                    );
                    NotificationManager.success(
                      response.data['message'],
                      'Successful!',
                      3000
                    );
                    this.props.history.push('/checkout');
                  } else {
                    NotificationManager.error(
                      response.data['message'],
                      'Error!'
                    );
                  }
                })
                .catch((error) => {
                  /*   console.log(error); */
                });
            } else {
              this.props.history.push('/user-details');
            }
          } else {
            if (response.data['data']['location'] == null) {
              this.props.history.push('/pick-city');
            } else {
              var address = response.data['data']['location'];
              var addressType = response.data['data']['type'];
              var latitude = response.data['data']['latitude'];
              var longtitude = response.data['data']['longitude'];
              var city = response.data['data']['city'];
              var flatNo = response.data['data']['address_line1'];
              var landmark = response.data['data']['address_line2'];
              var otherType = response.data['data']['location_title'];
              localStorage.setItem('flatNo', flatNo);
              localStorage.setItem('landmark', landmark);
              localStorage.setItem('addressSelected', address);
              localStorage.setItem('addressType', addressType);
              localStorage.setItem('latitude', latitude);
              localStorage.setItem('longitude', longtitude);
              localStorage.setItem('selectedLocation', city);
              localStorage.setItem('selectedLocationName', city);
              if (otherType) {
                localStorage.setItem('addressDetailType', otherType);
              }
              if (this.props.location.search === '?checkout') {
                return getUserLocationAPI(
                  localStorage.getItem('userID'),
                  localStorage.getItem('accessToken'),
                  localStorage.getItem('latitude'),
                  localStorage.getItem('longitude'),
                  localStorage.getItem('selectedLocationName'),
                  localStorage.getItem('addressSelected'),
                  localStorage.getItem('pincode'),
                  localStorage.getItem('addressType'),
                  localStorage.getItem('addressDetailType'),
                  localStorage.getItem('flatNo'),
                  localStorage.getItem('landmark')
                )
                  .then((response) => {
                    if (response.data['status'] === 200) {
                      localStorage.setItem(
                        'prevLocationId',
                        response.data.diner_location_id
                      );
                      NotificationManager.success(
                        response.data['message'],
                        'Successful!',
                        3000
                      );
                      this.props.history.push('/checkout');
                    } else {
                      NotificationManager.error(
                        response.data['message'],
                        'Error!'
                      );
                    }
                  })
                  .catch((error) => {
                    /*  console.log(error); */
                  });
              } else {
                let selectedCity = config.slugify(city);
                if (selectedCity == localStorage.getItem('chefCity')) {
                  this.props.history.push(
                    '/' +
                      localStorage.getItem('chefCity') +
                      '/chef-inner-homepage/' +
                      localStorage.getItem('chefSlug')
                  );
                } else {
                  this.props.history.push(
                    '/' + selectedCity + '/diner-homepage'
                  );
                }
              }
            }
          }
          NotificationManager.success(
            'Welcome to CHEFPIN ' + userName,
            'Successful!',
            3000
          );
        } else if (response.status === 500) {
          NotificationManager.error(response.data.message, 'Error!');
        } else if (response.data.status === 405) {
          NotificationManager.error(response.data.message, 'Error!');
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        /* console.log(error); */
        NotificationManager.error('Something went wrong while Login', 'Error!');
      });
  };
  /* login function ends here */

  /* handleLoginFailure function starts here */
  handleLoginFailure(response) {
	  //
  }
  /* handleLoginFailure function ends here */

  /* handleLogoutFailure function starts here */
  handleLogoutFailure(response) {
	  //
  }
  /* handleLogoutFailure function ends here */

  /* onResolved function starts here */
  onResolved() {
    alert('Recaptcha resolved with response: ' + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  /* triggerFacebookLogin function starts here */
  triggerFacebookLogin = () => {
    if (localStorage.getItem('facebook_logged_in') === 'true') {
      this.responseFacebook();
      localStorage.removeItem('facebook_logged_in');
    }
  };
  /* triggerFacebookLogin function ends here */

  render() {
    if (localStorage.getItem('accessToken') !== null) {
      return <Redirect to={this.state.selectedCity} />;
    }
    const { formErrors } = this.state;
    return (
      <React.Fragment>
        <div className='css2'>
          <Helmet>
            <title>Sign In to enjoy your regular meal</title>
            <meta name='title' content='Sign In to enjoy your regular meal.' />
            <meta
              name='description'
              content='Sign In with your phone number to order the fresh and hygienic food straight from the home kitchen.'
            />
            <meta
              name='keywords'
              content='home cooked mea, homemade food, home made chicken, order from home chef, home food service'
            />
          </Helmet>
          <DinnerHeader />
          <section className='sign_up_chef verification log_otp sign_chefdiner diner_sign_up '>
            <Link to='/' className='back_btn'>
              <img
                src='/assets/images/icons/black-arrow.svg'
                alt='Black Arrow'
              />
              Back
            </Link>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-xl-5 d-flex d-xl-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='text pt-0'>
                    <h1 className='color_C07'>LOG IN TO CHEF PIN</h1>
                    <div className='profile_img'>
                      <div className='inner'>
                        <img
                          src='/assets/images/icons/profile-img.png'
                          alt='Profile Images'
                          className='img-fluid'
                        />
                      </div>
                    </div>
                    <div className='form_wrap'>
                      <form>
                        <div className='form-group'>
                          <div className='mobile_no_wrap'>
                            <div className='form-control countrycode'>
                              <span className='code'>+91</span>
                            </div>
                            <input
                              type='tel'
                              className='form-control tel_number'
                              placeholder='Mobile Number*'
                              name='mobileNumber'
                              value={this.state.mobileNumber}
                              onChange={this.handleMobileChange}
                              maxLength='10'
                            />
                          </div>
                          <small className='text-danger text-left d-block'>
                            {formErrors.mobileNumber}
                          </small>
                          <small
                            id='PasswordHelp'
                            className='form-text text-muted'
                          >
                            Enter your 10 digit mobile number.
                          </small>
                        </div>
                        <div className='form-group mb-0'>
                          <button
                            type='submit'
                            className='btn'
                            disabled={this.state.buttonDisabled}
                            onClick={this.submitContactForm}
                          >
                            {this.state.buttonText}
                          </button>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                    <p className='resend_otp_text log_using_psd color_036 text-center text-sm-left mb-0'>
                      or Log in using
                      <Link
                        to={
                          this.props.location.search === '?checkout'
                            ? '/diner-signin-with-password?checkout'
                            : this.props.location.search === '?chef-innerpage'
                            ? '/diner-signin-with-password?chef-innerpage'
                            : this.props.location.search !== '' &&
                              this.props.location.search.indexOf(
                                'redirect='
                              ) !== -1
                            ? '/diner-signin-with-password' +
                              this.props.location.search
                            : '/diner-signin-with-password'
                        }
                        className='color_C07'
                      >
                        {' '}
                        Password
                      </Link>
                    </p>

                    <div className='social_center mt-3'>
                      <span className='or_text ml-0'>or Continue with</span>
                      <ul className='social_icon'>
                        <li>
                          <GoogleLogin
                            clientId={config.CLIENT_ID}
                            buttonText=''
                            onSuccess={this.login}
                            onFailure={this.handleLoginFailure}
                            cookiePolicy={'single_host_origin'}
                            responseType='code,token'
                            className='img-fluid'
                          />
                        </li>
                        <li>
                          {/* <FacebookLogin
                            appId={config.APP_ID}
                            fields="name,email,picture"
                            callback={this.responseFacebook}
                            icon="fa-facebook"
                            textButton=""
                            disableMobileRedirect={true}
                          /> */}

                          {/*<button className='fb-login-custom-button'>
                            <i className='fa fa-facebook'></i>
                          </button>*/}
                        </li>
                      </ul>
                      {/* {this.triggerFacebookLogin()} */}
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-xl-7 align-items-center d-none d-lg-flex'>
                  <div className='img_box'>
                    <img
                      src='/assets/images/sign-up-as-a-chef/sign-up-img.png'
                      alt='sign up img'
                      className='border-rad img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='nofooter'>
            <WhatsAppButton />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items
});

export default connect(mapStateToProps, { saveUserData })(
  DinerLoginWithOTPPage
);
