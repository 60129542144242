/*
#############################################################################
# Name : dinerMenuTab.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display menu tab component for mobile
#############################################################################
*/
import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { removeMobileView } from "../redux/actions/chefMenuActions";
import config from "../config/config";
import { getAlertDinerDataCountAPI } from "../core/services/Phase2APIservice";
class DinerMenuTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCity: "",
      alertData: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: selectedCity,
      });
    }
    if (localStorage.getItem("userID")) {
      this.getAlertData();
    }
  }

  getAlertData = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getAlertDinerDataCountAPI(userId, token)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            alertData: response.data.unread_alerts_count,
          });
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  };
  /* getAccountPage = () => {
    console.log("in");
    console.log(localStorage.getItem("userID"));
    if (localStorage.getItem("userID") == null || localStorage.getItem("userID") == "") {
      console.log('inin');
    /* this.props.history.push("/empty-state"); 
    }
  }; */
  render() {
    const { userData, history } = this.props;
    return (
      <div>
        <section className="tab_stick">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-12 d-lg-none d-md-block p-0">
                <nav>
                  <div
                    className="nav nav-tabs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <Link
                      to={
                        this.state.selectedCity
                          ? "/" + this.state.selectedCity + "/diner-homepage"
                          : "/"
                      }
                      className={
                        history.location.pathname ===
                          "/" + this.state.selectedCity + "/diner-homepage" ||
                        history.location.pathname === "/"
                          ? "nav-item nav-link tab_logo active"
                          : "nav-item nav-link tab_logo"
                      }
                    >
                      <span className="icon alerts_icon">
                        <img
                          src="/assets/images/icons/sticky-logo.svg"
                          alt="ChefPin Logo"
                          className="img-fluid"
                        />
                      </span>
                    </Link>
                    {this.state.selectedCity &&
                    localStorage.getItem("userID") ? (
                      <Link
                        to="/diner-alert"
                        className={
                          history.location.pathname === "/diner-alert"
                            ? "nav-item nav-link tab_logo active"
                            : "nav-item nav-link tab_logo"
                        }
                      >
                        <span className="icon alerts_icon">
                          <img
                            src="/assets/images/icons/bell.svg"
                            alt="Alerts"
                          />
                          <span
                            className={
                              this.state.alertData && this.state.alertData
                                ? "active"
                                : null
                            }
                          >
                            {this.state.alertData && this.state.alertData > 0
                              ? this.state.alertData
                              : null}
                          </span>
                        </span>
                        <span>Alerts</span>
                      </Link>
                    ) : null}
                    {this.state.selectedCity ? (
                      <Link
                        to={"/checkout"}
                        className={
                          history.location.pathname === "/checkout"
                            ? "nav-item nav-link tab_logo active"
                            : "nav-item nav-link tab_logo"
                        }
                      >
                        <span className="icon alerts_icon">
                          <img src="/assets/images/icons/cart.svg" alt="Cart" />
                          <span
                            className={
                              this.props.cartItems.length ? "active" : null
                            }
                          >
                            {this.props.cartItems.length > 0
                              ? this.props.cartItems.length > 9
                                ? "9+"
                                : this.props.cartItems.length
                              : null}
                          </span>
                        </span>
                        <span>Cart</span>
                      </Link>
                    ) : null}
                    <Link
                      className={
                        history.location.pathname === "/manage-address" ||
                        history.location.pathname === "/empty-state"
                          ? "nav-item nav-link tab_logo active"
                          : "nav-item nav-link tab_logo"
                      }
                      onClick={() => this.props.removeMobileView()}
                      to={
                        this.props.userData.userName
                          ? "/manage-address"
                          : "/empty-state"
                      }
                    >
                      <span className="icon alerts_icon">
                        <img
                          src="/assets/images/icons/account.svg"
                          alt="Account"
                        />
                      </span>
                      <span>Account</span>
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div className="footer_padding"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  cartItems: state.cart.items,
});

export default connect(mapStateToProps, { removeMobileView })(
  withRouter(DinerMenuTab)
);
