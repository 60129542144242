import React, { useState, useEffect } from "react";
import "../BackendStyle.css";
import { URLs } from "../Url";
import {
  GetMetatags,
  handlePostImage,
  Metatagsupload,
  Metatagsupdate,
  handledeleteImage,
  DeleteMetas,
} from "./config";
function MetaTags() {
  const [data, setdata] = useState([]);
  const [catdata, setcatdata] = useState([]);
  const [title, settitle] = useState();
  const [description, setdescription] = useState();
  const [keyword, setkeyword] = useState();
  const [ogtitle, setogtitle] = useState();
  const [ogdescription, setogdescription] = useState();
  const [image, setimage] = useState();
  const [updateimage, setupdateimage] = useState();
  const [UpdateId, setUpdateId] = useState();
  const [pagename, setpagename] = useState();
  const [slug, setslug] = useState();
  const [deleteimage, setdeleteimage] = useState();
  const [category, setcategory] = useState();

  const [isLoading, setisLoading] = useState(true);
  const [show, setShow] = useState(true);
  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    const getgteanData = await GetMetatags();

    if (getgteanData.success == true) {
      console.log(getgteanData);
      setdata(getgteanData.data);
      setisLoading(false);
    }
  };
  const handlerUpdateData = async (Getimage) => {
    const ImageCheck = updateimage == undefined ? image : Getimage;

    const getgteanData = await Metatagsupdate(
      UpdateId,
      pagename,
      slug,
      title,
      description,
      keyword,
      ogtitle,
      ogdescription,
      Getimage
    );
    if (getgteanData.success == true) {
      handlerData();
      if (updateimage != undefined) {
        ImageDeleteHandler(deleteimage);
        UpdateVar();
      }
      setShow(true);
    }
  };
  const handleImage = async () => {
    if (image != undefined) {
      const GetData = await handlePostImage(image);
      if (GetData.success == true) {
        if (show == "update") {
          handlerUpdateData(GetData.image);
        } else {
          handlerUploadData(GetData.image);
        }
      }
    } else {
      handlerUploadData();
    }
  };
  const handlerUploadData = async (Getimage) => {
    const getgteanData = await Metatagsupload(
      pagename,
      slug,
      title,
      description,
      keyword,
      ogtitle,
      ogdescription,
      Getimage
    );
    if (getgteanData.success == true) {
      handlerData();

      setShow(true);
    }
  };
  const ImageDeleteHandler = async (imageDelete) => {
    const getResponse = await handledeleteImage(imageDelete);
    if (getResponse.success == true) {
      UpdateVar();
    }
  };
  const UpdateItem = (item) => {
    settitle(item.title);
    setkeyword(item.keywords);
    setdescription(item.description);
    setogdescription(item.ogdescription);
    setogtitle(item.ogtitle);
    setimage(item.ogimage);
    setdeleteimage(item.ogimage);
    setpagename(item.pagename);
    setslug(item.slug);
    setUpdateId(item._id);
    setShow("update");
  };
  const UpdateVar = () => {
    settitle();
    setdescription();
    setimage();
    setupdateimage();
    setdeleteimage();
    setpagename();
    setslug();
    setUpdateId();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setupdateimage(URL.createObjectURL(event.target.files[0]));
      setimage(event.target.files[0]);
    }
  };
  const DeleteHandler = async (id, imageDlt) => {
    const getResponses = await DeleteMetas(id);
    if (getResponses.success == true) {
      const getResponse = await handledeleteImage(imageDlt);
      if (getResponse.success == true) {
        handlerData();
      }
    }
  };
  return isLoading == true ? (
    <h1>Add the category first</h1>
  ) : (
    <>
      {show == "update" ? (
        <div className="cus-contact">
          <form>
            <h3>Update Meta Tag</h3>
            <div className="form-group">
              <label>Page Name</label>
              <input
                class="form-control"
                value={pagename}
                onChange={(e) => setpagename(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Slug</label>
              <input
                class="form-control"
                value={slug}
                onChange={(e) => setslug(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                class="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                class="form-control"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>keywords</label>
              <input
                class="form-control"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Title</label>
              <input
                class="form-control"
                value={ogtitle}
                onChange={(e) => setogtitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Description</label>
              <input
                class="form-control"
                value={ogdescription}
                onChange={(e) => setogdescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChange}
              />
            </div>
            <div className="form-group">
              {updateimage == undefined ? (
                <img
                  src={URLs.images + image}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              ) : (
                <img
                  src={updateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              )}
            </div>
            <button
              onClick={() => {
                if (updateimage == undefined) {
                  handlerUpdateData();
                } else {
                  handleImage();
                }
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : show == "add" ? (
        <div className="cus-contact">
          <form>
            <h3>Create Meta Tag</h3>
            <div className="form-group">
              <label>Page Name</label>
              <input
                class="form-control"
                value={pagename}
                onChange={(e) => setpagename(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Slug</label>
              <input
                class="form-control"
                value={slug}
                onChange={(e) => setslug(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                class="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                class="form-control"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>keywords</label>
              <input
                class="form-control"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Title</label>
              <input
                class="form-control"
                value={ogtitle}
                onChange={(e) => setogtitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Description</label>
              <input
                class="form-control"
                value={ogdescription}
                onChange={(e) => setogdescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChange}
              />
            </div>
            <div className="form-group">
              <img
                src={updateimage}
                alt=""
                className="img-fluid"
                style={{ width: 200, height: 200 }}
              />
            </div>
            <button
              onClick={() => {
                handleImage();
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className="row table-invoice">
            <div className="col-sm-12">
              <div className="table-in-card">
                <div class="d-flex justify-content-between">
                  <h3>Meta Tags</h3>
                  <button
                    type="button"
                    onClick={() => setShow("add")}
                    class="btn btn-primary btn-xs"
                  >
                    <small>Add</small>
                  </button>
                </div>
                {data.length > 0 ? (
                  <>
                    <br />

                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>OG Image</th>
                            <th>Page Name</th>
                            <th>Page Slug</th>
                            <th>Meta Title</th>
                            <th>Meta Description</th>
                            <th>Keywords</th>
                            <th>OG Title</th>
                            <th>OG Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data == undefined
                            ? null
                            : data.map((item) => {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        src={URLs.images + item.ogimage}
                                        alt=""
                                        className="img-fluid"
                                        style={{ width: 100, height: 100 }}
                                      />
                                    </td>
                                    <td>{item.pagename}</td>
                                    <td>{item.slug}</td>
                                    <td>{item.title}</td>
                                    <td>
                                      {item.description == undefined
                                        ? null
                                        : item.description.slice(0, 70)}
                                    </td>
                                    <td>{item.keywords}</td>
                                    <td>{item.ogtitle}</td>
                                    <td>
                                      {item.ogdescription == undefined
                                        ? null
                                        : item.ogdescription.slice(0, 70)}
                                    </td>

                                    <td>
                                      <div class="d-flex justify-content-between">
                                        <button
                                          type="button"
                                          onClick={() => UpdateItem(item)}
                                          class="btnnnnn btn-primary"
                                          style={{ marginRight: 10 }}
                                        >
                                          <i class="fa fa-edit"></i>
                                        </button>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            DeleteHandler(item._id, item.image)
                                          }
                                          class="btnnnnn btn-danger"
                                        >
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MetaTags;
