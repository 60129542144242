/*
#############################################################################
# Name : CancelledOrders.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the cancelled orders details
#############################################################################
*/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import AccountSidebar from "../AccountSidebar";
import DinnerHeader from "../../../../../components/dinnerHeader";
import DinerFooter from "../../../../../components/dinerFooter";
import WhatsAppButton from "../../../../../components/whatsappButton";
import DinerMenuTab from "../../../../../components/dinerMenuTab";
import { getDinerCancelledOrdersAPI } from "../../../../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import LoadingDiv from "../../../../../components/LoadingDiv";
import Moment from "moment";
import { removeMobileView } from "../../../../../redux/actions/chefMenuActions";
import config from "../../../../../config/config";

class CancelledOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedCity:"",
      cancelledOrders: [],
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    if(localStorage.getItem("selectedLocation")){
      var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
      this.setState({
        selectedCity: selectedCity
      })
    }
    this.getDinerCancelledOrdersAPI();      //Diner cancelled order detail is fetched
  }

  /* getDinerCancelledOrdersAPI function starts here */
  getDinerCancelledOrdersAPI = () => {
    return getDinerCancelledOrdersAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            cancelledOrders: response.data.data.order,
            isLoading: false,
          });
        } else {
          /* NotificationManager.error(response.data.message, "Error!"); */
          this.setState({
            isLoading: false,
          });
          if (response.status === 401) {
            NotificationManager.error("Session expired. Please log in.", "Error!");
            this.props.history.push('diner-signin-with-otp');
            localStorage.clear();
        }else{
          NotificationManager.error(response.data.message, "Error!");
        }
        }
      })
      .catch((error) => {
       /*  NotificationManager.error(error, "Error!"); */
        this.setState({
          isLoading: false,
        });
      });
  };
/* getDinerCancelledOrdersAPI function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
  };
  /* getInerPage function ends here */

  render() {
    if (this.props.userData.userType !== "Diner") {
      return <Redirect to="/" />;
    }
    const { history, mobileView } = this.props;
    const { isLoading, cancelledOrders } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : !cancelledOrders.length ? (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Cancelled Orders
                      </h6>
                      <div className="inner">
                        <div className="not_found empty_section py-0">
                          <div className="text-center">
                            <img
                              src="assets/images/icons/no-past-orders.svg"
                              alt="Not Found"
                              className="img-fluid"
                            />
                            <h6 className="justify-content-center">
                              NO CANCELLED ORDERS
                            </h6>
                            <p>
                              You have no cancelled orders. That's a<br /> good
                              thing!
                            </p>
                            <Link to={"/"+this.state.selectedCity+"/diner-homepage"} className="btn">
                              START ORDERING!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Cancelled Orders
                      </h6>
                      <div className="inner current_orders_card">
                        {cancelledOrders.map((order, i) => {
                          return (
                            <div
                              className={
                                order.is_takeaway_selected == "1"
                                  ? "order_wrap takeaway"
                                  : "order_wrap"
                              }
                              key={"dxijmf" + i}
                            >
                              <div className="order_title">
                                <h6 className="order_num">
                                  <span>
                                    {order.is_takeaway_selected == "0"
                                      ? "Delivery"
                                      : "Takeaway"}
                                  </span>
                                  Order Number : {"#" + order.order_number}
                                </h6>
                              </div>
                              <div className="save_add_wrap">
                                <div className="card">
                                  <div className="box title_price">
                                    <h6>
                                      Home Chef{" "}
                                      {order.first_name + " " + order.last_name}{" "}
                                      <span className="mb-0">INR {order.amount_paid} (including taxes and charges)</span>
                                    </h6>
                                    <p className="name_add add">
                                      {order.pickup_location}
                                    </p>
                                    {order.is_takeaway_selected == "1" && (
                                      <a
                                        target="_blank"
                                        href={
                                          "https://www.google.com/maps/dir/" +
                                          order.drop_location
                                            .split(" ")
                                            .join("+") +
                                          "/" +
                                          order.pickup_location
                                            .split(" ")
                                            .join("+")
                                        }
                                        className="link"
                                      >
                                        View on Google Maps
                                      </a>
                                    )}
                                  </div>
                                  <hr className="dash_line" />
                                  <div className="box">
                                    <h6 className="font-weight-normal badge_wrap_div">
                                      {order.order_details.map((item, i) => {
                                        return (
                                          <span
                                            key={"fshyt" + i}
                                            className="badge badge-light mr-2"
                                            style={{
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              backgroundColor: "#f2f2f2",
                                            }}
                                          >
                                            {item.item_name +
                                              " x " +
                                              item.itemQuantity}
                                          </span>
                                        );
                                      })}
                                    </h6>
                                    <p>
                                      Order placed :{" "}
                                      {Moment(order.created_at).format(
                                        "MMM DD, hh:mm a"
                                      )}
                                    </p>
                                    <p>
                                      Delivery time slot :{" "}
                                      {order.selected_time_slot}
                                    </p>
                                  </div>
                                  <hr className="dash_line" />
                                  <div className="box">
                                    <h6 className="font-weight-normal">
                                      {order.status} order on{" "}
                                      {Moment(
                                        order.status === "Cancelled"
                                          ? order.order_cancelled_at
                                          : order.status === "Rejected"
                                          ? order.order_rejected_at
                                          : order.created_at
                                      ).format("MMM DD, hh:mm a")}{" "}
                                    </h6>
                                  </div>
                                  {order.reason_for_chef_rejection ?<hr className="dash_line" /> : null}
                                  { order.reason_for_chef_rejection ?<div className="box">
                                    <p className="font-weight-normal">
                                      Reason :{" "}
                                      <span>
                                        {order.status === "Rejected"
                                          ? order.reason_for_chef_rejection
                                          : null}
                                      </span>
                                    </p>
                                  </div>: null}
                                </div>
                              </div>
							  <div className="d-flex justify-content-end">
								  <a
									target="_blank"
									className="btn add_new_btn"
									onClick={() =>
									  this.getInerPage(order.chef_id, order.slug_url)
									}
								  >
									Back To Chef Item's
								  </a>
							  </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(CancelledOrders);
