import { SAVE_CHEF_ORDER_DATA, REMOVE_CHEF_ORDER_DATA } from "../actions/types";

export const saveOrderCountData = (number) => (dispatch) => {
    return dispatch({
        type: SAVE_CHEF_ORDER_DATA,
        payload: {
            orderCountData: number
        }
    });
}

export const removeOrderCountData = () => (dispatch) => {
    return dispatch({
        type: REMOVE_CHEF_ORDER_DATA,
        payload: {
            orderCountData: null
        }
    });
}