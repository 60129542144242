/*
#############################################################################
# Name : dinerProfile.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the diner profile details
#############################################################################
*/
import React, { Component } from "react";
import OtpInput from "react-otp-input";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import {
  getProfileDataAPI,
  getChangePasswordAPI,
} from "../../../../core/services/APIservice";
import {
  getDinerInfoAPI,
  getEmailOTPAPI,
  getEmailOTPVerfiyAPI,
  getMobileOTPAPI,
  getMobileOTPVerfiyAPI,
} from "../../../../core/services/Phase2APIservice";
import config from "../../../../config/config";
import { connect } from "react-redux";
import { saveUserData } from "../../../../redux/actions/authActions";
import { removeMobileView } from "../../../../redux/actions/chefMenuActions";
import { NotificationManager } from "react-notifications";
const numberReg = /^[0]?[789]\d{9}$/;
const nameValidation = /^[a-zA-Z ]+$/;
const passwordReg = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,}$/;
const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class DinerProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 1,
      seconds: 0,
      firstname: "",
      lastname: "",
      emailId: "",
      mobileNumber: "",
      userEmail: "",
      password: "",
      userMobileNumber: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      profilePhoto: "",
      profilePhotoName: "",
      emailStatus: false,
      mobileStatus: false,
      otpStatus: false,
      otp: "",
      emailOtp: "",
      passwordStatus: false,
      passwordError: "",
      errorProfileValid: "",
      showMobile: false,
      mobileOTP: false,
      showEmail: false,
      emailIDOTP: false,
      mobileButtonDisable: false,
      emailButtonDisable: false,
      formErrors: {
        firstname: "",
        lastname: "",
        emailId: "",
        mobileNumber: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
    this.handleProfileChange = this.handleProfileChange.bind(this);
  }

  componentDidMount = () => {
    this.getProfileData();              // Profile data is fetched
    var userId = "";
    userId = localStorage.getItem("userID");
    if (localStorage.getItem("userID") === null) {
      this.props.history.push("/");
    }
  };

  /* handleNumberChange function starts here */
  handleNumberChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "firstname":
        formErrors.firstname =
          value === ""
            ? "First Name is required"
            : !nameValidation.test(e.target.value)
            ? "Enter valid Name."
            : "";
        break;

      case "lastname":
        formErrors.lastname =
          value === ""
            ? "Last Name is required"
            : !nameValidation.test(e.target.value)
            ? "Enter valid Name."
            : "";
        break;

      case "mobileNumber":
        formErrors.mobileNumber =
          value === ""
            ? "Mobile Number is required."
            : !numberReg.test(e.target.value)
            ? "Enter valid Mobile Number."
            : "";
        break;

      case "emailId":
        formErrors.emailId =
          value === ""
            ? "Email Id is required"
            : !emailReg.test(e.target.value)
            ? "Enter valid Email Id."
            : "";
        break;

      case "oldPassword":
        formErrors.oldPassword =
          value === ""
            ? "Old Password is required"
            : !passwordReg.test(e.target.value)
            ? "Enter minimum 6 character password."
            : "";
        break;

      case "newPassword":
        formErrors.newPassword =
          value === ""
            ? "New Password is required"
            : !passwordReg.test(e.target.value)
            ? "Enter minimum 6 character password."
            : "";
        break;

      case "confirmPassword":
        formErrors.confirmPassword =
          value === ""
            ? "Confirm Password is required"
            : !passwordReg.test(e.target.value)
            ? "Enter minimum 6 character password."
            : "";
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
/* handleNumberChange function ends here */  

/* getProfileData function starts here */
  getProfileData = () => {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getDinerInfoAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            firstname: response.data["data"]["first_name"],
            lastname: response.data["data"]["last_name"],
            userMobileNumber: response.data["data"]["mobile_number"],
            userEmail: response.data["data"]["email"],
            profilePhotoName: /[^/]*$/.exec(
              response.data["data"]["profile_image"]
            )[0],
          });
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {});
  };
/* getProfileData function ends here */  

/* onChangeProfilePhoto function starts here */
  onChangeProfilePhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      this.setState({ errorProfileValid: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorProfileValid: "" });
    }
    this.setState({
      profilePhoto: event.target.files[0],
      profilePhotoName: event.target.files[0]["name"].slice(-20),
    });
  };
/* onChangeProfilePhoto function ends here */  

/* handleProfileChange function starts here */
  handleProfileChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }
/* handleProfileChange function ends here */

/* hideEmailSection function starts here */
  hideEmailSection = (e) => {
    e.preventDefault();
    this.setState({
      emailId: "",
      showEmail: false,
      emailIDOTP: false,
      emailButtonDisable: false,
    });
  };
/* hideEmailSection function ends here */

/* getEmailChange function starts here */
  getEmailChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.emailId === "") {
      formErrors.emailId = "Email ID is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      return getEmailOTPAPI(userId, this.state.emailId, accessToken)
        .then((response) => {
          if (response.data["status"] === 200) {
            if (response.data["message"] === "Same email provided!") {
              NotificationManager.error(response.data["message"], "Error!");
            } else {
              this.setState({
                emailIDOTP: true,
                emailOtp: "",
                emailButtonDisable: true,
              });
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
              this.setState({
                minutes: 1,
                seconds: 0,
              });
              this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state;
                if (seconds > 0) {
                  this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                  }));
                }
                if (seconds === 0) {
                  if (minutes === 0) {
                    clearInterval(this.myInterval);
                  } else {
                    this.setState(({ minutes }) => ({
                      minutes: minutes - 1,
                      seconds: 59,
                    }));
                  }
                }
              }, 1000);
            }
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {});
    } else {
    }
  };
/* getEmailChange function ends here */

/* verfiyEmailOTP function starts here */
  verfiyEmailOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getEmailOTPVerfiyAPI(
      userId,
      this.state.emailId,
      this.state.emailOtp,
      accessToken
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            response.data["message"],
            "Successful!",
            3000
          );
          localStorage.setItem("userEmail", this.state.emailId);
          this.setState({
            emailIDOTP: false,
            showEmail: false,
            userEmail: this.state.emailId,
            emailOtp: "",
            emailId: "",
            emailButtonDisable: false,
          });
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Failed to update the Email ID", "Error!");
      });
  };
/* verfiyEmailOTP function ends here */

/* resendEmailOTP function starts here */
  resendEmailOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getEmailOTPAPI(userId, this.state.emailId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data["message"] === "Same email provided!") {
            NotificationManager.error(response.data["message"], "Error!");
          } else {
            this.setState({ emailIDOTP: true });
            this.setState({ emailOtp: "" });
            NotificationManager.success(
              response.data["message"],
              "Successful!",
              3000
            );
            this.setState({
              minutes: 1,
              seconds: 0,
            });
            this.myInterval = setInterval(() => {
              const { seconds, minutes } = this.state;
              if (seconds > 0) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              }
              if (seconds === 0) {
                if (minutes === 0) {
                  clearInterval(this.myInterval);
                } else {
                  this.setState(({ minutes }) => ({
                    minutes: minutes - 1,
                    seconds: 59,
                  }));
                }
              }
            }, 1000);
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {});
  };
/* resendEmailOTP function ends here */

/* getMobileChange function starts here */
  getMobileChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.mobileNumber === "") {
      formErrors.mobileNumber = "Mobile Number is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      return getMobileOTPAPI(userId, this.state.mobileNumber, accessToken)
        .then((response) => {
          if (response.data["status"] === 200) {
            if (response.data["message"] === "Same number provided!") {
              NotificationManager.error(response.data["message"], "Error!");
            } else {
              this.setState({
                mobileOTP: true,
                otp: "",
                mobileButtonDisable: true,
              });
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
              this.setState({
                minutes: 1,
                seconds: 0,
              });
              this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state;
                if (seconds > 0) {
                  this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                  }));
                }
                if (seconds === 0) {
                  if (minutes === 0) {
                    clearInterval(this.myInterval);
                  } else {
                    this.setState(({ minutes }) => ({
                      minutes: minutes - 1,
                      seconds: 59,
                    }));
                  }
                }
              }, 1000);
            }
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
         /*  console.log(error);
          NotificationManager.error(error, "Error!"); */
        });
    } else {
    }
  };
/* getMobileChange function ends here */

/* hideMobileSection function starts here */
  hideMobileSection = (e) => {
    e.preventDefault();
    this.setState({
      mobileNumber: "",
      showMobile: false,
      mobileOTP: false,
      mobileButtonDisable: false,
    });
  };
/* hideMobileSection function ends here */

/* verfiyMobileOTP function starts here */
  verfiyMobileOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getMobileOTPVerfiyAPI(
      userId,
      this.state.mobileNumber,
      this.state.otp,
      accessToken
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            response.data["message"],
            "Successful!",
            3000
          );
          localStorage.setItem("userMobile", this.state.mobileNumber);
          this.setState({
            mobileOTP: false,
            showMobile: false,
            userMobileNumber: this.state.mobileNumber,
            otp: "",
            mobileNumber: "",
            mobileButtonDisable: false,
          });
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Failed to update the Mobile Number",
          "Error!"
        );
      });
  };
/* verfiyMobileOTP function ends here */

/* resendMobileOTP function starts here */
  resendMobileOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getMobileOTPAPI(userId, this.state.mobileNumber, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data["message"] === "Same number provided!") {
            NotificationManager.error(response.data["message"], "Error!");
          } else {
            this.setState({ otpStatus: true });
            this.setState({ otp: "" });
            NotificationManager.success(
              response.data["message"],
              "Successful!",
              3000
            );
            this.setState({
              minutes: 1,
              seconds: 0,
            });
            this.myInterval = setInterval(() => {
              const { seconds, minutes } = this.state;
              if (seconds > 0) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              }
              if (seconds === 0) {
                if (minutes === 0) {
                  clearInterval(this.myInterval);
                } else {
                  this.setState(({ minutes }) => ({
                    minutes: minutes - 1,
                    seconds: 59,
                  }));
                }
              }
            }, 1000);
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {});
  };
/* resendMobileOTP function ends here */

/* handleChange function starts here */
  handleChange = (otp) => {
    this.setState({ otp });
  };
/* handleChange function ends here */

/* handleEmailChange function starts here */
  handleEmailChange = (emailOtp) => {
    this.setState({ emailOtp });
  };
/* handleEmailChange function ends here */  

/* getPasswordChanged function starts here */
  getPasswordChanged = (event) => {
    event.preventDefault();
    this.setState({ passwordStatus: true });
  };
/* getPasswordChanged function ends here */

/* getDataSubmit function starts here */
  getDataSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.firstname === "") {
      formErrors.firstname = "First Name is required";
    }

    if (this.state.lastname === "") {
      formErrors.lastname = "Last Name is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      /*  this.recaptcha.execute(); */
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      var formdata = new FormData();

      formdata.append("user_id", userId);
      formdata.append("first_name", this.state.firstname);
      formdata.append("last_name", this.state.lastname);
      if (this.state.profilePhoto !== "") {
        formdata.append(
          "profile_image",
          this.state.profilePhoto,
          this.state.profilePhotoName
        );
      }

      axios
        .post(config.APIURL + "v1/update-diner-profile", formdata, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((response) => {
          if (response.data["status"] === 200) {
            if (this.state.profilePhoto !== "") {
              var photo = response.data["data"]["profile_image"];
              localStorage.setItem("userImage", photo);
            }
            localStorage.setItem(
              "userName",
              this.state.firstname + " " + this.state.lastname
            );
            let data = {
              userType: "Diner",
              userId: localStorage.getItem("userID"),
              userEmail: response.data["data"]["email"],
              userName:
                response.data["data"]["first_name"] +
                " " +
                response.data["data"]["last_name"],
              userMobile: response.data["data"]["mobile_number"],
              image: response.data["data"]["profile_image"],
              accessToken: localStorage.getItem("accessToken"),
            };
            this.props.saveUserData(data);
            NotificationManager.success("Profile Updated", "Successful!", 3000);
            this.props.history.push("/current-orders");
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          NotificationManager.error("Failed to Updated Profile", "Error", 3000);
        });
    } else {
      /* this.recaptcha.reset(); */
    }
  };
/* getDataSubmit function ends here */

/* hidePasswordSection function starts here */
  hidePasswordSection = (event) => {
    event.preventDefault();
    this.setState({
      passwordStatus: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
/* hidePasswordSection function ends here */

/* checkPassword function starts here */
  checkPassword = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.oldPassword === "") {
      formErrors.oldPassword = "Old Password is required";
    }

    if (this.state.newPassword === "") {
      formErrors.newPassword = "New Password is required";
    }

    if (this.state.confirmPassword === "") {
      formErrors.confirmPassword = "Confirm Password is required";
    }

    if (this.state.newPassword !== this.state.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      return getChangePasswordAPI(
        userId,
        accessToken,
        this.state.oldPassword,
        this.state.newPassword,
        this.state.confirmPassword
      )
        .then((response) => {
          if (response.data["status"] === 200) {
            localStorage.clear();
            NotificationManager.success("Profile Updated", "Successful!", 3000);
            this.props.history.push("/diner-signin-with-password");
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {});
    } else {
    }
  };
/* checkPassword function ends here */

/* getEditMobileNumber function starts here */
  getEditMobileNumber = (event) => {
    event.preventDefault();
    this.setState({
      showMobile: true,
    });
  };
/* getEditMobileNumber function ends here */

/* getEditEmailID function starts here */
  getEditEmailID = (event) => {
    event.preventDefault();
    this.setState({
      showEmail: true,
    });
  };
/* getEditEmailID function ends here */

/* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved function ends here */  

  render() {
    if (this.props.userData.userType !== "Diner") {
      return <Redirect to="/" />;
    }
    const { formErrors } = this.state;
    const { minutes, seconds } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="registration edit_profile">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12">
                <div className="abt_form heading">
                  <h4 className="d-flex justify-content-center align-items-center text-uppercase">
                    <a
                      onClick={() => {
                        this.props.removeMobileView();
                        this.props.history.goBack();
                      }}
                      className="res_back_btn mobile_sidebar_back_btn"
                    >
                      <img
                        src="assets/images/icons/left-arrow-black.svg"
                        alt="left-arrow-black"
                      />
                    </a>
                    EDIT PROFILE
                    <button className="save_btn">Save</button>
                  </h4>
                </div>
                <div className="abt_form">
                  <div className="inner">
                    <h5 className="color_C07 d-flex align-items-center">
                      <span className="icon user_icon user_icon">
                        <img
                          src="/assets/images/icons/user.png"
                          alt="User Icon"
                          className="img-fluid"
                        />
                      </span>
                      Profile
                    </h5>
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name*"
                                  name="firstname"
                                  value={this.state.firstname}
                                  onChange={this.handleNumberChange}
                                />
                                <small className="text-danger">
                                  {formErrors.firstname}
                                </small>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name*"
                                  name="lastname"
                                  value={this.state.lastname}
                                  onChange={this.handleNumberChange}
                                />
                                <small className="text-danger">
                                  {formErrors.lastname}
                                </small>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <div className="mobile_no_wrap">
                                  <div className="form-control countrycode">
                                    <span className="code">+91</span>
                                  </div>
                                  <input
                                    type="tel"
                                    className="form-control tel_number"
                                    placeholder="Mobile Number*"
                                    name="userMobileNumber"
                                    value={this.state.userMobileNumber}
                                    readOnly
                                  />
                                  <button
                                    className="mob_nub_change"
                                    type="submit"
                                    onClick={this.getEditMobileNumber}
                                  >
                                    Change
                                  </button>
                                </div>
                              </div>
                              {this.state.showMobile ? (
                                <div className="form-group">
                                  <div className="mobile_no_wrap">
                                    <div className="form-control countrycode">
                                      <span className="code">+91</span>
                                    </div>
                                    <input
                                      type="tel"
                                      className="form-control tel_number"
                                      placeholder="Mobile Number*"
                                      name="mobileNumber"
                                      value={this.state.mobileNumber}
                                      onChange={this.handleNumberChange}
                                      maxLength="10"
                                    />
                                  </div>
                                  <small className="form-text text-muted">
                                    Please enter your new 10 digit mobile
                                    number.
                                    <br />
                                    An OTP verification will be sent to your new
                                    number.
                                  </small>
                                  <small className="text-danger">
                                    {formErrors.mobileNumber}
                                  </small>
                                </div>
                              ) : null}
                              {this.state.showMobile ? (
                                <div className="form-group save_btn_next btn-group d-flex justify-content-start align-items-end mw-100">
                                  <button
                                    className="btn previous_btn"
                                    onClick={this.hideMobileSection}
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn"
                                    onClick={this.getMobileChange}
                                    disabled={this.state.mobileButtonDisable}
                                  >
                                    Verify with otp
                                  </button>
                                </div>
                              ) : null}
                              {this.state.mobileOTP ? (
                                <div className="form-group verification">
                                  <label className="otp_label">
                                    Enter the 6 digit OTP
                                  </label>
                                  <OtpInput
                                    containerStyle={"otp_input_wrap"}
                                    inputStyle={"form-control"}
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={6}
                                    shouldAutoFocus
                                    isInputNum={true}
                                    separator={false}
                                  />
                                </div>
                              ) : null}
                              {this.state.mobileOTP ? (
                                <p className="resend_otp_text color_C07 mb-0">
                                  OTP will expire in
                                  <span className="color_036 ml-2">
                                    {minutes > 9 ? minutes : "0" + minutes}:
                                    {seconds > 9 ? seconds : "0" + seconds}
                                  </span>
                                </p>
                              ) : null}
                              {this.state.mobileOTP ? (
                                <div className="form-group btn-group opt_btn_grp">
                                  <button
                                    className="btn previous_btn"
                                    onClick={this.resendMobileOTP}
                                    disabled={minutes > 0 || seconds > 0}
                                  >
                                    re-send otp
                                  </button>
                                  <button
                                    className="btn"
                                    disabled={
                                      this.state.otp.toString().length < 6
                                    }
                                    onClick={this.verfiyMobileOTP}
                                  >
                                    Verify OTP
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <div className="mobile_no_wrap">
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email ID*"
                                    value={this.state.userEmail}
                                    name="userEmail"
                                    readOnly
                                  />
                                  <button
                                    className="mob_nub_change"
                                    type="submit"
                                    onClick={this.getEditEmailID}
                                  >
                                    Change
                                  </button>
                                </div>
                              </div>
                              {this.state.showEmail ? (
                                <div className="form-group">
                                  <div className="mobile_no_wrap">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email ID*"
                                      value={this.state.emailId}
                                      name="emailId"
                                      onChange={this.handleNumberChange}
                                    />
                                  </div>
                                  <small className="form-text text-muted">
                                    Please enter your new Email ID.
                                    <br />
                                    An OTP verification will be sent to your new
                                    email.
                                  </small>
                                  <small className="text-danger">
                                    {formErrors.emailId}
                                  </small>
                                </div>
                              ) : null}
                              {this.state.showEmail ? (
                                <div className="form-group save_btn_next btn-group d-flex justify-content-start align-items-end mw-100">
                                  <button
                                    className="btn previous_btn"
                                    onClick={this.hideEmailSection}
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn"
                                    onClick={this.getEmailChange}
                                    disabled={this.state.emailButtonDisable}
                                  >
                                    Verify with otp
                                  </button>
                                </div>
                              ) : null}
                              {this.state.emailIDOTP ? (
                                <div className="form-group verification">
                                  <label className="otp_label">
                                    Enter the 6 digit OTP
                                  </label>
                                  <OtpInput
                                    containerStyle={"otp_input_wrap"}
                                    inputStyle={"form-control"}
                                    value={this.state.emailOtp}
                                    onChange={this.handleEmailChange}
                                    numInputs={6}
                                    shouldAutoFocus
                                    isInputNum={true}
                                    separator={false}
                                  />
                                </div>
                              ) : null}
                              {this.state.emailIDOTP ? (
                                <p className="resend_otp_text color_C07 mb-0">
                                  OTP will expire in
                                  <span className="color_036 ml-2">
                                    {minutes > 9 ? minutes : "0" + minutes}:
                                    {seconds > 9 ? seconds : "0" + seconds}
                                  </span>
                                </p>
                              ) : null}
                              {this.state.emailIDOTP ? (
                                <div className="form-group btn-group opt_btn_grp">
                                  <button
                                    className="btn previous_btn"
                                    onClick={this.resendEmailOTP}
                                    disabled={minutes > 0 || seconds > 0}
                                  >
                                    re-send otp
                                  </button>
                                  <button
                                    className="btn"
                                    disabled={
                                      this.state.emailOtp.toString().length < 6
                                    }
                                    onClick={this.verfiyEmailOTP}
                                  >
                                    Verify OTP
                                  </button>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label className="d-block">
                                  Upload your profile picture
                                </label>
                                <div className="fileUpload d-flex">
                                  <button className="fileUploadBtn form-control d-inline-flex"></button>
                                  <div className="d-inline-flex align-items-center form-control">
                                    {this.state.profilePhotoName === "" ? (
                                      <span className="file_text">
                                        Choose file
                                        <small>No file selected</small>
                                      </span>
                                    ) : (
                                      <span className="file_text">
                                        {this.state.profilePhotoName.slice(-20)}
                                      </span>
                                    )}
                                    <input
                                      type="file"
                                      className="uploadlogo"
                                      name="profile_image"
                                      onChange={this.onChangeProfilePhoto}
                                    />
                                  </div>
                                </div>
                                <small className="form-text text-muted">
                                  One file only. 64 MB limit. Allowed types: png
                                  gif jpg jpeg.
                                </small>
                                {this.state.errorProfileValid ? (
                                  <small className="form-text red_000">
                                    {this.state.errorProfileValid}
                                  </small>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <label className="d-flex justify-content-between">
                                  Password{" "}
                                  <span
                                    className="mob_nub_change"
                                    onClick={this.getPasswordChanged}
                                  >
                                    {" "}
                                    Change Password
                                  </span>
                                </label>
                                {this.state.passwordStatus ? (
                                  <div className="mobile_no_wrap">
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="Enter Current Password*"
                                      name="oldPassword"
                                      value={this.state.oldPassword}
                                      onChange={this.handleNumberChange}
                                    />
                                    {formErrors.oldPassword ? (
                                      <small className="text-danger">
                                        {formErrors.oldPassword}
                                      </small>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                              {this.state.passwordStatus === true ? (
                                <div className="form-group">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter New password*"
                                    name="newPassword"
                                    value={this.state.newPassword}
                                    onChange={this.handleNumberChange}
                                  />
                                  <small
                                    id="PasswordHelp"
                                    className="form-text text-muted"
                                  >
                                    Minimum 6 characters.
                                  </small>
                                  {formErrors.newPassword ? (
                                    <small className="text-danger">
                                      {formErrors.newPassword}
                                    </small>
                                  ) : null}
                                </div>
                              ) : null}
                              {this.state.passwordStatus === true ? (
                                <div className="form-group">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm New Password*"
                                    name="confirmPassword"
                                    value={this.state.confirmPassword}
                                    onChange={this.handleNumberChange}
                                  />
                                  <small
                                    id="PasswordHelp"
                                    className="form-text text-muted"
                                  >
                                    Minimum 6 characters.
                                  </small>
                                  {formErrors.confirmPassword ? (
                                    <small className="text-danger">
                                      {formErrors.confirmPassword}
                                    </small>
                                  ) : null}
                                  <small className="form-text red_000">
                                    {this.state.passwordError !== ""
                                      ? this.state.passwordError
                                      : ""}
                                  </small>
                                </div>
                              ) : null}

                              {this.state.passwordStatus ? (
                                <div className="form-group save_btn_next btn-group d-flex justify-content-start align-items-end mw-100">
                                  <button
                                    className="btn previous_btn"
                                    onClick={this.hidePasswordSection}
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn pro_save_btn"
                                    onClick={this.checkPassword}
                                  >
                                    save
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className=" d-block text-right save_btn_next w-100 mb-0">
                  <button
                    type="submit"
                    className="btn"
                    onClick={this.getDataSubmit}
                  >
                    save and update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
});

export default connect(mapStateToProps, { saveUserData, removeMobileView })(
  DinerProfilePage
);
