/*
#############################################################################
# Name : dinerDashboard.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display all types of chef data and many more things
#############################################################################
*/
import React from 'react';
import config from '../../../config/config';
import DinnerHeader from '../../../components/dinnerHeader';
import DinerFooter from '../../../components/dinerFooter';
import WhatsAppButton from '../../../components/whatsappButton';
import DinerMenuTab from '../../../components/dinerMenuTab';
import LoadingDiv from '../../../components/LoadingDiv';
import PromotedBadge from '../../../components/PromotedBadge';
import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.min.css';
import Slider from 'react-slick';
import { Redirect, Link } from 'react-router-dom';
import {
  marketingCardsAPI,
  chefListingAPI,
  crowdFavouriteAPI,
  collectionsAPI,
  bannerAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
  getPlayerIDAPI
} from '../../../core/services/Phase2APIservice';
import { connect } from 'react-redux';
import { saveOneSignalData } from '../../../redux/actions/oneSignalAction';
// React Notification
import { NotificationManager } from 'react-notifications';

window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;
class DinerDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedCity: '',
      hideContent: window.matchMedia('(max-width: 991px)').matches,
      settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000
      },
      selectedYou: {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: false,
        loop: true,
        responsive: [
          {
            breakpoint: 1401,
            settings: {
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1199,
            settings: {
              arrows: false,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 575,
            settings: {
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
      },
      params: {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        adaptiveHeight: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1401,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1199,
            settings: {
              arrows: false,
              slidesToShow: 3
            }
          },
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 575,
            settings: {
              centerMode: true,
              arrows: false,
              centerPadding: '10px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 374,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '10px',
              slidesToShow: 1
            }
          }
        ]
      },
      newOnChefPin: {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        autoplaySpeed: 6000,
        infinite: false,
        responsive: [
          {
            breakpoint: 1401,
            settings: {
              centerPadding: '40px',
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1199,
            settings: {
              arrows: false,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '60px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              arrows: false,
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 575,
            settings: {
              centerMode: true,
              arrows: false,
              centerPadding: '10px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 374,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '50px',
              slidesToShow: 1
            }
          }
        ]
      },
      trendingHomeChef: {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        autoplaySpeed: 6000,
        infinite: false,
        responsive: [
          {
            breakpoint: 1401,
            settings: {
              centerPadding: '40px',
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1199,
            settings: {
              arrows: false,
              centerPadding: '40px',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '60px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              arrows: false,
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 575,
            settings: {
              centerMode: true,
              arrows: false,
              centerPadding: '10px',
              slidesToShow: 2
            }
          },
          {
            breakpoint: 374,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '50px',
              slidesToShow: 1
            }
          }
        ]
      },
      quickFilter: {
        slidesToShow: 4,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              centerPadding: '90px',
              centerMode: true
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
              centerPadding: '70px',
              centerMode: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              centerPadding: '70px',
              centerMode: true
            }
          },
          {
            breakpoint: 575,
            settings: {
              centerPadding: '20px',
              centerMode: true,
              slidesToShow: 3
            }
          },
          {
            breakpoint: 374,
            settings: {
              centerPadding: '30px',
              centerMode: true,
              slidesToShow: 2
            }
          }
        ]
      },
      collections: {
        slidesToShow: 6,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              centerPadding: '60px',
              centerMode: true,
              slidesToShow: 5
            }
          },
          {
            breakpoint: 1025,
            settings: {
              centerPadding: '60px',
              centerMode: true,
              slidesToShow: 5
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4,
              centerPadding: '70px',
              centerMode: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              centerPadding: '80px',
              centerMode: true,
              slidesToShow: 3
            }
          },
          {
            breakpoint: 576,
            settings: {
              centerPadding: '30px',
              centerMode: true,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 374,
            settings: {
              centerPadding: '60px',
              centerMode: true,
              slidesToShow: 1
            }
          }
        ]
      },
      imageSlide: {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        adaptiveHeight: true,
        arrows: true,
        dots: true,
        autoplay: true
      },
      marketingCardsArr: [],
      bannerCardArr: [],
      crowdFavouritesArr: [],
      vipChefListingArr: [],
      premiumChefListingArr: [],
      basicChefListingArr: [],
      newChefListingArr: [],
      collectionArr: [],
      matchSelectedForYou: window.matchMedia('(min-width: 768px)').matches,
      matchCollection: window.matchMedia('(max-width: 1200px)').matches,
      errorDashboard: false,
      bookMarkSelected: false,
      mustTryChefArr: []
    };
  }

  componentDidMount() {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios|firefox|fxios|edg|opr/i)) {
      browserName = 'chrome';
      console.log(browserName);
      try {
        OneSignal.getUserId().then(function (userId) {
          localStorage.setItem('OneSignal_playerId', userId);
        });
      } catch (error) {
        console.error(error);
      }
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
      console.log(browserName);
      /*  OneSignal.getUserUserId().then(function (userId) {
        localStorage.setItem("OneSignal_playerId", userId);
      }); */
    } else {
      browserName = 'No browser detection';
    }

    if (localStorage.getItem('OneSignal_playerId')) {
      var playerId = localStorage.getItem('OneSignal_playerId');
      this.props.saveOneSignalData(playerId);
    }
    //window.scrollTo({ top: 0, behavior: "smooth" });
    this.getBannerList(); //Banner data is fetched
    this.getMarketingCards(); //Marketing cards data is fetched
    this.getChefListing(); // Chef data is fetched
    this.getCrowdFavourites(); // Crowd Favourite data is fetched
    this.getCollections(); // Collections data is fetched
    if (localStorage.getItem('selectedLocation')) {
      var selectedCity = config.slugify(
        localStorage.getItem('selectedLocation')
      );
      this.setState({
        selectedCity: selectedCity
      });
    }
    if (
      localStorage.getItem('userID') &&
      localStorage.getItem('OneSignal_playerId')
    ) {
      this.getPlayerID();
    }
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {};
  }

  getPlayerID = () => {
    var userId = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var playerId = this.props.oneSignalData;
    return getPlayerIDAPI(userId, token, playerId)
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  };

  /* getBannerList function starts here */
  getBannerList = () => {
    return bannerAPI()
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ bannerCardArr: response.data.data });
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  };
  /* getBannerList function ends here */

  /* getMarketingCards function starts here */
  getMarketingCards = () => {
    return marketingCardsAPI()
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ marketingCardsArr: response.data.data.data });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* getMarketingCards function ends here */

  /* getCollections function starts here */
  getCollections = () => {
    return collectionsAPI()
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ collectionArr: response.data.data.data });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* getCollections function ends here */

  /* getChefListing function starts here */
  getChefListing = async () => {
    var city = localStorage.getItem('selectedLocationName');
    var pincode = localStorage.getItem('pincode');
    var latitude = localStorage.getItem('latitude');
    var longitude = localStorage.getItem('longitude');
    var userId = '';
    if (localStorage.getItem('userID')) {
      userId = localStorage.getItem('userID');
    }
    try {
      const response = await chefListingAPI(
        city,
        pincode,
        latitude,
        longitude,
        userId
      );
      if (response.data.status === 200) {
        if (
          response.data.data.vip_chef_list.length === 0 &&
          response.data.data.premium_chef_list.length === 0 &&
          response.data.data.basic_chef_list.length === 0
        ) {
          this.setState({
            errorDashboard: true
          });
        } else {
          this.setState({
            errorDashboard: false
          });
        }
        this.setState({
          vipChefListingArr: response.data.data.vip_chef_list,
          premiumChefListingArr: response.data.data.premium_chef_list,
          basicChefListingArr: response.data.data.basic_chef_list,
          newChefListingArr: response.data.data.new_chefpin_member_list,
          mustTryChefArr: response.data.data.must_item_list,
          isLoading: false
        });
      } else {
        this.setState({
          isLoading: false
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false
      });
      /* console.log(error); */
    }
  };
  /* getChefListing function ends here */

  /* getCrowdFavourites function starts here */
  getCrowdFavourites = () => {
    return crowdFavouriteAPI()
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({ crowdFavouritesArr: response.data.data.data });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* getCrowdFavourites function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem('chefID', chefID);
    var city = config.slugify(localStorage.getItem('selectedLocation'));
    this.props.history.push('/' + city + '/chef-inner-homepage/' + slugUrl);
    console.log(this.props.oneSignalData);
  };
  /* getInerPage function ends here */

  /* getQuickFilter function starts here */
  getQuickFilter = (data, filterImage) => {
    localStorage.setItem('selectedFilter', data);
    localStorage.setItem('selectedFilterImage', filterImage);
    var selectedCity = config.slugify(localStorage.getItem('selectedLocation'));
    if (data == 'Near You') {
      this.props.history.push(
        '/' + selectedCity + '/quick-filter/' + 'near-you'
      );
    } else if (data == 'Super Chefs') {
      this.props.history.push(
        '/' + selectedCity + '/quick-filter/' + 'super-chefs'
      );
    } else if (data == 'Highly Rated') {
      this.props.history.push(
        '/' + selectedCity + '/quick-filter/' + 'highly-rated'
      );
    } else if (data == 'Audit Me Anytime') {
      this.props.history.push(
        '/' + selectedCity + '/quick-filter/' + 'audit-me-anytime'
      );
    } else {
      this.props.history.push(
        '/' + selectedCity + '/quick-filter/' + data.toLowerCase()
      );
    }
  };
  /* getQuickFilter function ends here */

  /* getCrowdFavouritesDetails function starts here */
  getCrowdFavouritesDetails = (crowdId, crowdData, crowdImage, slugUrl) => {
    localStorage.setItem('selectedCrowdID', crowdId);
    localStorage.setItem('selectedCrowd', crowdData);
    localStorage.setItem('selectedCrowdImage', crowdImage);
    var selectedCity = config.slugify(localStorage.getItem('selectedLocation'));
    this.props.history.push('/' + selectedCity + '/crowd-favourite/' + slugUrl);
  };
  /* getCrowdFavouritesDetails function ends here */

  /* getCollectionsDetails function starts here */
  getCollectionsDetails = (
    collectionId,
    collectionData,
    collectionImage,
    slugUrl
  ) => {
    localStorage.setItem('selectedCollectionID', collectionId);
    localStorage.setItem('selectedCollection', collectionData);
    localStorage.setItem('selectedCollectionImage', collectionImage);
    var selectedCity = config.slugify(localStorage.getItem('selectedLocation'));
    this.props.history.push(
      '/' + selectedCity + '/diner-collections/' + slugUrl
    );
  };
  /* getCollectionsDetails function ends here */

  /* getMarketingCard function starts here */
  getMarketingCard = (marketingId, marketingName, slug) => {
	var selectedCity = config.slugify(localStorage.getItem('selectedLocation'));
    localStorage.setItem('selectedMarketingID', marketingId);
    localStorage.setItem('selectedMarketingName', marketingName);
    localStorage.setItem('selectedMarketingSlug', slug);
    //this.props.history.push('/marketing-detail');
    this.props.history.push('/'+selectedCity+'/marketing-cards/'+slug);
  };
  /* getMarketingCard function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            'Successful!',
            3000
          );
          this.getChefListing();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            'Successful!',
            3000
          );
          this.getChefListing();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push('/user-selection');
  };

  render() {
    if (!localStorage.getItem('addressSelected')) {
      return <Redirect to='/' />;
    }
    return (
      <div className='css2'>
        <DinnerHeader />
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : !this.state.errorDashboard ? (
          <div>
            <section className='home_banner d-md-flex d-none banner'>
              {this.state.bannerCardArr.length > 1 ? (
                <Slider
                  className='slide carousel-fade'
                  {...this.state.imageSlide}
                >
                  {this.state.bannerCardArr.map((banner, b) => {
                    return (
                      <div className='img_wrap' key={'oiuioi' + b}>
                        {banner.link != null ? (
                          <a href={banner.link} target='_blank'>
                            <img
                              src={banner.image}
                              className='d-block w-100'
                              alt='Slider images-1'
                            />
                          </a>
                        ) : (
                          <img
                            src={banner.image}
                            className='img-fluid'
                            alt='Slider images-1'
                          />
                        )}
                      </div>
                    );
                  })}
                </Slider>
              ) : (
                <div>
                  {this.state.bannerCardArr.map((banner, b) => {
                    return (
                      <div className='box' key={'qwswe' + b}>
                        {banner.link != null ? (
                          <a href={banner.link} target='_blank'>
                            <img
                              src={banner.image}
                              className='d-block w-100'
                              alt='Slider images-1'
                            />
                          </a>
                        ) : (
                          <img
                            src={banner.image}
                            className='img-fluid'
                            alt='Slider images-1'
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </section>
            <section className='marketing_cards'>
              <Slider className='slide carousel-fade' {...this.state.params}>
                {this.state.marketingCardsArr.map((cards, b) => {
                  return (
                    <div className='cards' key={'oiuioi' + b}>
                      {cards.link == null ? (
                        <img
                          src={cards.image}
                          className='img-fluid'
                          onClick={this.getMarketingCard.bind(
                            this,
                            cards.id,
                            cards.name,
                            cards.slug
                          )}
                        />
                      ) : (
                        <a href={cards.link} target='_blank'>
                          <img src={cards.image} className='img-fluid' />
                        </a>
                      )}
                    </div>
                  );
                })}
              </Slider>
            </section>
            {/* <section className="marketing_cards">
              <Swiper {...this.state.params}>
                {this.state.marketingCardsArr
                  ? this.state.marketingCardsArr.map((cards, i) => {
                      return (
                        <div className="cards p-0" key={"vcv" + i}>
                          {cards.link == null ? (
                            <img
                              src={cards.image}
                              className="img-fluid"
                              onClick={this.getMarketingCard.bind(
                                this,
                                cards.id,
                                cards.name
                              )}
                            />
                          ) : (
                            <a href={cards.link} target="_blank">
                              <img src={cards.image} className="img-fluid" />
                            </a>
                          )}
                        </div>
                      );
                    })
                  : null}
              </Swiper>
            </section>  */}
            {/*  <section className="selected_you">
              <div className="container">
                <h4 className="d-flex justify-content-between align-items-center see_all">
                  Selected for You
                  <Link to={"/selected-for-you"}>
                    See all
                    <img
                      src="/assets/images/icons/black-arrow-right.svg"
                      alt="Right Arrow"
                    />
                  </Link>
                </h4>
                {this.state.matchSelectedForYou ? (
                  <Slider
                    className="slider_top row"
                    {...this.state.selectedYou}
                  >
                    {this.state.vipChefListingArr.map((vip, l) => {
                      return (
                        <div className="col-sm-6 col-lg-3 mw-100" key={'cxdfg'+l}>
                          <div
                            className="card"
                            onClick={() => this.getInerPage(vip.chef_id)}
                          >
                            {vip.promoted == 1 ? (
                              <PromotedBadge/>
                            ) : null}
                            <div className="img_wrap">
                              {vip.profile_image != null ? (
                                <img
                                  src={vip.profile_image}
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              ) : (
                                <img
                                  src="/assets/images/noimage.png"
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              )}
                              
                            </div>
                            <div className="text_wrap">
                              <h6>
                                {vip.verified == 1 ? (
                                  <span className="correct_icn">
                                    {vip.brand_name !== ""
                                      ? vip.brand_name
                                      : vip.name}
                                  </span>
                                ) : (
                                  <span>
                                    {vip.brand_name !== ""
                                      ? vip.brand_name
                                      : vip.name}
                                  </span>
                                )}
                                <span className="star">
                                  4.5 <span className="grey">/ 5</span>
                                </span>
                              </h6>
                              {vip.cuisines == "" ? (
                                "-"
                              ) : (
                                <p className="sub_title">{vip.cuisines}</p>
                              )}
                              <ul
                                className={
                                  vip.is_takeaway_available == 1 ? "" : "hide"
                                }
                              >
                                {vip.is_takeaway_available == 1 ? (
                                  <li>Takeaway</li>
                                ) : (
                                  <li>Not for take-away</li>
                                )}
                                <li className="location_icn">
                                  {vip.locality == null &&
                                  vip.locality === "" ? null : (
                                    <span>{vip.locality}, </span>
                                  )}{" "}
                                  {vip.distance == null
                                    ? ""
                                    : parseFloat(vip.distance).toFixed(1)}{" "}
                                  kms
                                </li>
                              </ul>
                              {vip.special_dishes != "" ? (
                                <p>
                                  {vip.name} is waiting for your Chef Pin order
                                  and is best known for making{" "}
                                  {vip.special_dishes}.
                                </p>
                              ) : null}
                              <div className="badge_slide">
                                <Slider
                                  className="inner_slider"
                                  {...this.state.settings}
                                >
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/select-you-hand.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/super-chefs.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/select-you-hand.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/super-chefs.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/select-you-hand.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/super-chefs.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                ) : (
                  <div className="row">
                    {this.state.vipChefListingArr.map((vip, o) => {
                      return (
                        <div className="col-sm-6 col-lg-4 col-xl-4" key={'eds'+o}>
                          <div
                            className="card"
                            onClick={() => this.getInerPage(vip.chef_id)}
                          >
                            {vip.promoted == 1 ? (
                              <PromotedBadge/>
                            ) : null}
                            <div className="img_wrap">
                              {vip.profile_image != null ? (
                                <img
                                  src={vip.profile_image}
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              ) : (
                                <img
                                  src="assets/images/noimage.png "
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              )}
                            </div>
                            <div className="text_wrap">
                              <h6>
                                {vip.verified == 1 ? (
                                  <span className="correct_icn">
                                    {vip.brand_name !== ""
                                      ? vip.brand_name
                                      : vip.name}
                                  </span>
                                ) : (
                                  <span>
                                    {vip.brand_name !== ""
                                      ? vip.brand_name
                                      : vip.name}
                                  </span>
                                )}
                                <span className="star">
                                  4.5 <span className="grey">/ 5</span>
                                </span>
                              </h6>
                              {vip.cuisines == "" ? (
                                "-"
                              ) : (
                                <p className="sub_title">{vip.cuisines}</p>
                              )}
                              <ul
                                className={
                                  vip.is_takeaway_available == 1 ? "" : "hide"
                                }
                              >
                                {vip.is_takeaway_available == 1 ? (
                                  <li>Takeaway</li>
                                ) : (
                                  <li>Not for take-away</li>
                                )}
                                <li className="location_icn">
                                  {vip.locality == null &&
                                  vip.locality === "" ? null : (
                                    <span>{vip.locality}, </span>
                                  )}{" "}
                                  {vip.distance == null
                                    ? ""
                                    : parseFloat(vip.distance).toFixed(1)}{" "}
                                  kms
                                </li>
                              </ul>
                              {vip.special_dishes != "" ? (
                                <p>
                                  {vip.name} is waiting for your Chef Pin order
                                  and is best known for making{" "}
                                  {vip.special_dishes}.
                                </p>
                              ) : null}
                              <div className="badge_slide">
                                <Slider
                                  className="inner_slider"
                                  {...this.state.settings}
                                >
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/select-you-hand.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/super-chefs.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/select-you-hand.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/super-chefs.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/select-you-hand.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                  <div className="box">
                                    <img
                                      src="assets/images/icons/super-chefs.svg"
                                      alt="Hand"
                                    />
                                    <span className="text">
                                      5000 happy diners lorem..
                                    </span>
                                  </div>
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </section>  */}
            <section className='selected_you vip_chefs'>
              {' '}
              <div className='container pr-0 d-block d-xl-none'>
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='d-flex justify-content-between align-items-center see_all'>
                      Quick Filters
                    </h4>
                    <div className='quick_filter'>
                      <Slider
                        className='quick_filters_slider quick_filters_slider_mob'
                        {...this.state.quickFilter}
                      >
                        {/* <div
                          className="box"
                          onClick={this.getQuickFilter.bind(
                            this,
                            "Super Chefs",
                            "super-chefs-big.svg"
                          )}
                        >
                          <div className="mediem_circle_loc">
                            <div className="inner">
                              <img
                                src="/assets/images/icons/super-chefs-big.svg"
                                alt="Super Chefs"
                              />
                            </div>
                          </div>
                          <span>Super Chefs</span>
                        </div>  */}

                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Takeaway',
                            'package.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/package.svg'
                                alt='Takeaway'
                              />
                            </div>
                          </div>
                          <span>Takeaway</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Highly Rated',
                            'highly-rated.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/highly-rated.svg'
                                alt='Highly Rated'
                              />
                            </div>
                          </div>
                          <span>Highly Rated</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Verified',
                            'correct-big.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/correct-big.svg'
                                alt='Verified'
                              />
                            </div>
                          </div>
                          <span>Verified</span>
                        </div>

                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Audit Me Anytime',
                            'audit-me-anytime.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/audit-me-anytime.svg'
                                alt='Audit Me Anytime'
                              />
                            </div>
                          </div>
                          <span>Audit Me Anytime</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Near You',
                            'destination.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/destination.svg'
                                alt='Near You'
                              />
                            </div>
                          </div>
                          <span>Near You</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Vegetarian',
                            'veg-quick-filter.png'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/veg-quick-filter.png'
                                alt='Vegetarian'
                              />
                            </div>
                          </div>
                          <span>Vegetarian</span>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12 col-lg-12 col-xl-3 d-none d-xl-block'>
                    <h4 className='d-flex justify-content-between align-items-center see_all'>
                      Quick Filters
                    </h4>
                    <div className='quick_filter'>
                      <div className='quick_filters_slider quick_filters_slider_mob'>
                        {/* <div
                          className="box"
                          onClick={this.getQuickFilter.bind(
                            this,
                            "Super Chefs",
                            "super-chefs-big.svg"
                          )}
                        >
                          <div className="mediem_circle_loc">
                            <div className="inner">
                              <img
                                src="/assets/images/icons/super-chefs-big.svg"
                                alt="Super Chefs"
                              />
                            </div>
                          </div>
                          <span>Super Chefs</span>
                        </div>  */}
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Near You',
                            'destination.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/destination.svg'
                                alt='Near You'
                              />
                            </div>
                          </div>
                          <span>Near You</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Takeaway',
                            'package.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/package.svg'
                                alt='Takeaway'
                              />
                            </div>
                          </div>
                          <span>Takeaway</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Highly Rated',
                            'highly-rated.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/highly-rated.svg'
                                alt='Highly Rated'
                              />
                            </div>
                          </div>
                          <span>Highly Rated</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Verified',
                            'correct-big.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/correct-big.svg'
                                alt='Verified'
                              />
                            </div>
                          </div>
                          <span>Verified</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Audit Me Anytime',
                            'audit-me-anytime.svg'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/audit-me-anytime.svg'
                                alt='Audit Me Anytime'
                              />
                            </div>
                          </div>
                          <span>Audit Me Anytime</span>
                        </div>
                        <div
                          className='box'
                          onClick={this.getQuickFilter.bind(
                            this,
                            'Vegetarian',
                            'veg-quick-filter.png'
                          )}
                        >
                          <div className='mediem_circle_loc'>
                            <div className='inner'>
                              <img
                                src='/assets/images/icons/veg-quick-filter.png'
                                alt='Vegetarian'
                              />
                            </div>
                          </div>
                          <span>Vegetarian</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 col-lg-12 col-xl-9'>
                    <h4 className='d-flex justify-content-between align-items-center see_all'>
                      Highly Rated Near You
                      <Link
                        to={'/' + this.state.selectedCity + '/must-try-meals'}
                      >
                        See all
                        <img
                          src='/assets/images/icons/black-arrow-right.svg'
                          alt='Right Arrow'
                        />
                      </Link>
                    </h4>
                    <div className='row'>
                      {this.state.mustTryChefArr.map((vip, o) => {
                        return (
                          <div
                            className='col-sm-6 col-lg-4 col-xl-4'
                            key={'vcdfg' + o}
                          >
                            <div
                              className={
                                vip.is_chef_available === '1'
                                  ? 'card'
                                  : 'card unavailable-chef-card'
                              }
                            >
                              <div
                                className='direction'
                                onClick={() => {
                                  localStorage.getItem('userID') !== null
                                    ? vip.is_bookmarked == 1
                                      ? this.removeBookmark(vip.chef_id)
                                      : this.addBookmark(vip.chef_id)
                                    : this.storageHandler();
                                }}
                              >
                                <img
                                  src={
                                    vip.is_bookmarked == 1
                                      ? '/assets/images/icons/bookmark.svg'
                                      : '/assets/images/icons/bookmark-empty.svg'
                                  }
                                  alt=''
                                />
                              </div>
                              <div
                                onClick={() =>
                                  this.getInerPage(
                                    vip.chef_id,
                                    vip.slug_url,
                                    vip.name,
                                    vip.is_chef_available
                                  )
                                }
                              >
                                {vip.promoted == 1 ? <PromotedBadge /> : null}
                                <div className='img_wrap'>
                                  {vip.is_offer_available === '1' ? (
                                    <div className='offer'>OFFER</div>
                                  ) : null}
                                  {vip.profile_image != null ? (
                                    <img
                                      src={vip.profile_image}
                                      alt=' Selected for You '
                                      className=' img-fluid '
                                    />
                                  ) : (
                                    <img
                                      src='/assets/images/noimage.png '
                                      alt=' Selected for You '
                                      className=' img-fluid '
                                    />
                                  )}
                                </div>
                                <div className='text_wrap'>
                                  <h6>
                                    {vip.verified == 1 ? (
                                      <span className='correct_icn'>
                                        {vip.brand_name !== ''
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    ) : (
                                      <span>
                                        {vip.brand_name !== ''
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    )}
                                    {vip.total_ratings !== 0 ? (
                                      <span className='star'>
                                        {vip.total_ratings}
                                        <span className='grey'>/ 5</span>
                                      </span>
                                    ) : null}
                                  </h6>
                                  {vip.cuisines == '' ? (
                                    '-'
                                  ) : (
                                    <p className='sub_title'>{vip.cuisines}</p>
                                  )}
                                  <ul>
                                    <li className='location_icn'>
                                      {vip.locality == null &&
                                      vip.locality === '' ? null : (
                                        <span>{vip.locality}, </span>
                                      )}{' '}
                                      {vip.distance == null
                                        ? ''
                                        : parseFloat(vip.distance).toFixed(
                                            1
                                          )}{' '}
                                      kms
                                    </li>
                                    {vip.is_takeaway_available == 1 ? (
                                      <li>Available for Delivery & Takeaway</li>
                                    ) : (
                                      <li>Available for Delivery</li>
                                    )}
                                  </ul>
                                  {vip.special_dishes != '' ? (
                                    <p>
                                      {vip.name} is waiting for your Chef Pin
                                      order and is best known for making{' '}
                                      {vip.special_dishes}.
                                    </p>
                                  ) : null}
                                  <div
                                    className={
                                      vip.gamification_data == null
                                        ? 'badge_slide border-0'
                                        : 'badge_slide'
                                    }
                                  >
                                    <Slider
                                      className='inner_slider'
                                      {...this.state.settings}
                                    >
                                      {vip.gamification_data != null ? (
                                        vip.gamification_data.map((item, i) => {
                                          return (
                                            <div
                                              className='box img-slider1'
                                              key={'grkw' + i}
                                            >
                                              <img
                                                src={item.image}
                                                alt={item.title}
                                              />
                                              <span className='text'>
                                                {item.desc}
                                              </span>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div className='box'>
                                          <span className='text'>
                                            &nbsp;&nbsp;
                                          </span>
                                        </div>
                                      )}
                                      {/* <div className="box">
                                      <img
                                        src="/assets/images/icons/select-you-hand.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>
                                    <div className="box">
                                      <img
                                        src="/assets/images/icons/super-chefs.svg"
                                        alt="Hand"
                                      />
                                      <span className="text">
                                        5000 happy diners lorem..
                                      </span>
                                    </div>  */}
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className='selected_you new_chef_pin'>
              <div className='container'>
                <h4 className='d-flex justify-content-between align-items-center see_all'>
                  New on Chef Pin
                  <Link to={'/' + this.state.selectedCity + '/new-on-chef-pin'}>
                    See all
                    <img
                      src='/assets/images/icons/black-arrow-right.svg'
                      alt='Right Arrow'
                    />
                  </Link>
                </h4>
                <Slider
                  className='new_chef_pin_slider row'
                  {...this.state.newOnChefPin}
                >
                  {this.state.newChefListingArr.map((basic, r) => {
                    return (
                      <div
                        className='col-sm-6 col-lg-3 mw-100'
                        key={'qasdf' + r}
                      >
                        <div
                          id={'card_' + basic.chef_id}
                          data-tag='basicCard'
                          className={
                            basic.is_chef_available === '1'
                              ? 'card'
                              : 'card unavailable-chef-card'
                          }
                          onClick={(e) => {
                            console.log(e.currentTarget.dataset.tag);
                            if (e.currentTarget.dataset.tag === 'basicCard') {
                              this.getInerPage(
                                basic.chef_id,
                                basic.slug_url,
                                basic.name,
                                basic.is_chef_available
                              );
                            }
                          }}
                        >
                          <div className='img_wrap'>
                            {basic.is_offer_available === '1' ? (
                              <div className='offer'>OFFER</div>
                            ) : null}
                            {basic.profile_image != null ? (
                              <img
                                src={basic.profile_image}
                                alt=' Selected for You '
                                className=' img-fluid '
                              />
                            ) : (
                              <img
                                src='/assets/images/noimage.png '
                                alt=' Selected for You '
                                className=' img-fluid '
                              />
                            )}
                            (
                            <div
                              className='direction'
                              id={'bookmark_' + basic.chef_id}
                              data-tag='basicBookmark'
                              onClick={(e) => {
                                console.log(e.currentTarget.dataset.tag);
                                e.stopPropagation();
                                if (
                                  e.currentTarget.dataset.tag ===
                                  'basicBookmark'
                                ) {
                                  {
                                    localStorage.getItem('userID') !== null
                                      ? basic.is_bookmarked == 1
                                        ? this.removeBookmark(basic.chef_id)
                                        : this.addBookmark(basic.chef_id)
                                      : this.storageHandler();
                                  }
                                }
                              }}
                            >
                              <img
                                id={'bookmark_' + basic.chef_id}
                                src={
                                  basic.is_bookmarked == 1
                                    ? '/assets/images/icons/bookmark.svg'
                                    : '/assets/images/icons/bookmark-empty.svg'
                                }
                                alt=''
                              />
                            </div>
                          </div>
                          <div className='text_wrap'>
                            <h6>
                              <span>
                                {basic.brand_name !== ''
                                  ? basic.brand_name
                                  : basic.name}
                              </span>
                              {basic.total_ratings !== 0 ? (
                                <span className='star'>
                                  {basic.total_ratings}
                                  <span className='grey'>/ 5</span>
                                </span>
                              ) : null}
                            </h6>
                            {basic.cuisines == '' ? (
                              '-'
                            ) : (
                              <p className='sub_title'>{basic.cuisines}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </section>
            <section className='selected_you popular_chefs bg_none'>
              {' '}
              <div className='container pr-0 d-block d-xl-none'>
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='d-flex justify-content-between align-items-center see_all'>
                      Crowd Favourites
                    </h4>
                    <div className='quick_filter crowd_favourites'>
                      <Slider
                        className='quick_filters_slider'
                        {...this.state.quickFilter}
                      >
                        {this.state.crowdFavouritesArr
                          ? this.state.crowdFavouritesArr.map((crowd, j) => {
                              return (
                                <div
                                  className='box'
                                  key={'nmjhgfv' + j}
                                  onClick={() =>
                                    this.getCrowdFavouritesDetails(
                                      crowd.id,
                                      crowd.title,
                                      crowd.image,
                                      crowd.slug_url
                                    )
                                  }
                                >
                                  <div className='mediem_circle_loc'>
                                    <div className='inner'>
                                      <img src={crowd.image} alt='Biryani' />
                                    </div>
                                  </div>
                                  <span>{crowd.title}</span>
                                </div>
                              );
                            })
                          : null}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
              <div className='container'>
                {' '}
                <div className='row'>
                  <div className='col-md-12 col-lg-12 col-xl-3 d-none d-xl-block'>
                    <h4 className='d-flex justify-content-between align-items-center see_all'>
                      Crowd Favourites
                    </h4>
                    <div className='quick_filter crowd_favourites'>
                      <div className='quick_filters_slider'>
                        {this.state.crowdFavouritesArr
                          ? this.state.crowdFavouritesArr.map((crowd, k) => {
                              return (
                                <div
                                  className='box'
                                  key={'nmjhg' + k}
                                  onClick={this.getCrowdFavouritesDetails.bind(
                                    this,
                                    crowd.id,
                                    crowd.title,
                                    crowd.image,
                                    crowd.slug_url
                                  )}
                                >
                                  <div className='mediem_circle_loc'>
                                    <div className='inner'>
                                      <img src={crowd.image} alt='Biryani' />
                                    </div>
                                  </div>
                                  <span>{crowd.title}</span>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-12 col-lg-12 col-xl-9'>
                    <h4 className='d-flex justify-content-between align-items-center see_all'>
                      Explore More
                      <Link
                        to={
                          '/' + this.state.selectedCity + '/popular-home-chef'
                        }
                      >
                        See all
                        <img
                          src='/assets/images/icons/black-arrow-right.svg'
                          alt='Right Arrow'
                        />
                      </Link>
                    </h4>

                    <div className='row popular_home_slider'>
                      {this.state.premiumChefListingArr.map((premium, m) => {
                        return (
                          <div className='col-lg-6' key={'mjytr' + m}>
                            <div className='row'>
                              <div className='col-md-12 col-lg-12 col-xl-12'>
                                <div
                                  id={'card_' + premium.chef_id}
                                  data-tag='card'
                                  className={
                                    premium.is_chef_available === '1'
                                      ? 'card'
                                      : 'card unavailable-chef-card'
                                  }
                                  onClick={(e) => {
                                    console.log(e.currentTarget.dataset.tag);
                                    if (
                                      e.currentTarget.dataset.tag === 'card'
                                    ) {
                                      this.getInerPage(
                                        premium.chef_id,
                                        premium.slug_url,
                                        premium.name,
                                        premium.is_chef_available
                                      );
                                    }
                                  }}
                                >
                                  <div className='img_wrap'>
                                    {premium.is_offer_available === '1' ? (
                                      <div className='offer'>OFFER</div>
                                    ) : null}
                                    {premium.profile_image != null ? (
                                      <img
                                        src={premium.profile_image}
                                        alt=' Selected for You '
                                        className=' img-fluid '
                                      />
                                    ) : (
                                      <img
                                        src='/assets/images/noimage.png '
                                        alt=' Selected for You '
                                        className=' img-fluid '
                                      />
                                    )}

                                    <div
                                      className='direction'
                                      id={'bookmark_' + premium.chef_id}
                                      data-tag='bookmark'
                                      onClick={(e) => {
                                        console.log(
                                          e.currentTarget.dataset.tag
                                        );
                                        e.stopPropagation();
                                        if (
                                          e.currentTarget.dataset.tag ===
                                          'bookmark'
                                        ) {
                                          {
                                            localStorage.getItem('userID') !==
                                            null
                                              ? premium.is_bookmarked == 1
                                                ? this.removeBookmark(
                                                    premium.chef_id
                                                  )
                                                : this.addBookmark(
                                                    premium.chef_id
                                                  )
                                              : this.storageHandler();
                                          }
                                        }
                                      }}
                                    >
                                      <img
                                        id={'bookmark_' + premium.chef_id}
                                        src={
                                          premium.is_bookmarked == 1
                                            ? '/assets/images/icons/bookmark.svg'
                                            : '/assets/images/icons/bookmark-empty.svg'
                                        }
                                        alt=''
                                      />
                                    </div>
                                  </div>
                                  <div className='text_wrap'>
                                    <h6>
                                      {premium.verified == 1 ? (
                                        <span className='correct_icn'>
                                          {premium.brand_name !== ''
                                            ? premium.brand_name
                                            : premium.name}
                                        </span>
                                      ) : (
                                        <span>
                                          {premium.brand_name !== ''
                                            ? premium.brand_name
                                            : premium.name}
                                        </span>
                                      )}
                                    </h6>
                                    {premium.cuisines == '' ? (
                                      '-'
                                    ) : (
                                      <p className='sub_title'>
                                        {premium.cuisines}
                                      </p>
                                    )}
                                    {premium.special_dishes != '' ? (
                                      <p>
                                        Known for cooking the best{' '}
                                        {premium.special_dishes}.
                                      </p>
                                    ) : (
                                      <p>
                                        Known for cooking the best Dosa's,
                                        Idli's, Vada's etc.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {this.state.vipChefListingArr.length == 0 ? null : (
              <section className='selected_you bg_none must_try_meals'>
                <div className='container'>
                  <h4 className='d-flex justify-content-between align-items-center see_all'>
                    Must Try Meals
                    <Link
                      to={'/' + this.state.selectedCity + '/vip-home-chef'}
                    >
                      See all
                      <img
                        src='/assets/images/icons/black-arrow-right.svg'
                        alt='Right Arrow'
                      />
                    </Link>
                  </h4>
                  {this.state.matchSelectedForYou ? (
                    <Slider
                      className='slider_top row'
                      {...this.state.selectedYou}
                    >
                      {this.state.vipChefListingArr.map((vip, p) => {
                        return (
                          <div
                            className='col-sm-6 col-lg-3 mw-100'
                            key={'gfvbhg' + p}
                          >
                            <div
                              className={
                                vip.is_chef_available === '1'
                                  ? 'card'
                                  : 'card unavailable-chef-card'
                              }
                            >
                              <div
                                className='direction'
                                onClick={() => {
                                  localStorage.getItem('userID') !== null
                                    ? vip.is_bookmarked == 1
                                      ? this.removeBookmark(vip.chef_id)
                                      : this.addBookmark(vip.chef_id)
                                    : this.storageHandler();
                                }}
                              >
                                <img
                                  src={
                                    vip.is_bookmarked == 1
                                      ? '/assets/images/icons/bookmark.svg'
                                      : '/assets/images/icons/bookmark-empty.svg'
                                  }
                                  alt=''
                                />
                              </div>
                              <div
                                onClick={() =>
                                  this.getInerPage(
                                    vip.chef_id,
                                    vip.slug_url,
                                    vip.name,
                                    vip.is_chef_available
                                  )
                                }
                              >
                                {vip.promoted == 1 ? <PromotedBadge /> : null}
                                <div className='img_wrap'>
                                  {vip.is_offer_available === '1' ? (
                                    <div className='offer'>OFFER</div>
                                  ) : null}
                                  {vip.profile_image != null ? (
                                    <img
                                      src={vip.profile_image}
                                      alt=' Selected for You '
                                      className=' img-fluid '
                                    />
                                  ) : (
                                    <img
                                      src='/assets/images/noimage.png '
                                      alt=' Selected for You '
                                      className=' img-fluid '
                                    />
                                  )}
                                </div>
                                <div className='text_wrap'>
                                  <h6>
                                    {vip.verified == 1 ? (
                                      <span className='correct_icn'>
                                        {vip.brand_name !== ''
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    ) : (
                                      <span>
                                        {vip.brand_name !== ''
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    )}
                                    {vip.total_ratings !== 0 ? (
                                      <span className='star'>
                                        {vip.total_ratings}
                                        <span className='grey'>/ 5</span>
                                      </span>
                                    ) : null}
                                  </h6>
                                  {vip.cuisines == '' ? (
                                    '-'
                                  ) : (
                                    <p className='sub_title'>{vip.cuisines}</p>
                                  )}
                                  <ul>
                                    <li className='location_icn'>
                                      {vip.locality == null &&
                                      vip.locality === '' ? null : (
                                        <span>{vip.locality}, </span>
                                      )}{' '}
                                      {vip.distance == null
                                        ? ''
                                        : parseFloat(vip.distance).toFixed(
                                            1
                                          )}{' '}
                                      kms
                                    </li>
                                    {vip.is_takeaway_available == 1 ? (
                                      <li>Available for Delivery & Takeaway</li>
                                    ) : (
                                      <li>Available for Delivery</li>
                                    )}
                                  </ul>
                                  {vip.special_dishes != '' ? (
                                    <p>
                                      {vip.name} is waiting for your Chef Pin
                                      order and is best known for making{' '}
                                      {vip.special_dishes}.
                                    </p>
                                  ) : (
                                    <p>
                                      {vip.name} is known for cooking the best
                                      Dosa, Idli's, Vada's etc.
                                    </p>
                                  )}
                                  <div
                                    className={
                                      vip.gamification_data == null
                                        ? 'badge_slide border-0'
                                        : 'badge_slide'
                                    }
                                  >
                                    <Slider
                                      className='inner_slider'
                                      {...this.state.settings}
                                    >
                                      {vip.gamification_data != null ? (
                                        vip.gamification_data.map((item, i) => {
                                          return (
                                            <div
                                              className='box img-slider1'
                                              key={'grkw' + i}
                                            >
                                              <img
                                                src={item.image}
                                                alt={item.title}
                                              />
                                              <span className='text'>
                                                {item.desc}
                                              </span>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div className='box'>
                                          <span className='text'>
                                            &nbsp;&nbsp;
                                          </span>
                                        </div>
                                      )}
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div className='row'>
                      {this.state.vipChefListingArr.map((vip, o) => {
                        return (
                          <div
                            className='col-sm-6 col-lg-4 col-xl-4'
                            key={'mnbht' + o}
                          >
                            <div
                              className={
                                vip.is_chef_available === '1'
                                  ? 'card'
                                  : 'card unavailable-chef-card'
                              }
                            >
                              <div
                                className='direction'
                                onClick={() => {
                                  localStorage.getItem('userID') !== null
                                    ? vip.is_bookmarked == 1
                                      ? this.removeBookmark(vip.chef_id)
                                      : this.addBookmark(vip.chef_id)
                                    : this.storageHandler();
                                }}
                              >
                                <img
                                  src={
                                    vip.is_bookmarked == 1
                                      ? '/assets/images/icons/bookmark.svg'
                                      : '/assets/images/icons/bookmark-empty.svg'
                                  }
                                  alt=''
                                />
                              </div>
                              <div
                                onClick={() =>
                                  this.getInerPage(
                                    vip.chef_id,
                                    vip.slug_url,
                                    vip.name,
                                    vip.is_chef_available
                                  )
                                }
                              >
                                {vip.promoted == 1 ? <PromotedBadge /> : null}
                                <div className='img_wrap'>
                                  {vip.is_offer_available === '1' ? (
                                    <div className='offer'>OFFER</div>
                                  ) : null}
                                  {vip.profile_image != null ? (
                                    <img
                                      src={vip.profile_image}
                                      alt=' Selected for You '
                                      className=' img-fluid '
                                    />
                                  ) : (
                                    <img
                                      src='/assets/images/noimage.png '
                                      alt=' Selected for You '
                                      className=' img-fluid '
                                    />
                                  )}
                                </div>
                                <div className='text_wrap'>
                                  <h6>
                                    {vip.verified == 1 ? (
                                      <span className='correct_icn'>
                                        {vip.brand_name !== ''
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    ) : (
                                      <span>
                                        {vip.brand_name !== ''
                                          ? vip.brand_name
                                          : vip.name}
                                      </span>
                                    )}
                                    {vip.total_ratings !== 0 ? (
                                      <span className='star'>
                                        {vip.total_ratings}
                                        <span className='grey'>/ 5</span>
                                      </span>
                                    ) : null}
                                  </h6>
                                  {vip.cuisines == '' ? (
                                    '-'
                                  ) : (
                                    <p className='sub_title'>{vip.cuisines}</p>
                                  )}
                                  <ul>
                                    <li className='location_icn'>
                                      {vip.locality == null &&
                                      vip.locality === '' ? null : (
                                        <span>{vip.locality}, </span>
                                      )}{' '}
                                      {vip.distance == null
                                        ? ''
                                        : parseFloat(vip.distance).toFixed(
                                            1
                                          )}{' '}
                                      kms
                                    </li>
                                    {vip.is_takeaway_available == 1 ? (
                                      <li>Available for Delivery & Takeaway</li>
                                    ) : (
                                      <li>Available for Delivery</li>
                                    )}
                                  </ul>
                                  {vip.special_dishes != '' ? (
                                    <p>
                                      {vip.name} is waiting for your Chef Pin
                                      order and is best known for making{' '}
                                      {vip.special_dishes}.
                                    </p>
                                  ) : null}
                                  <div
                                    className={
                                      vip.gamification_data == null
                                        ? 'badge_slide border-0'
                                        : 'badge_slide'
                                    }
                                  >
                                    <Slider
                                      className='inner_slider'
                                      {...this.state.settings}
                                    >
                                      {vip.gamification_data != null ? (
                                        vip.gamification_data.map((item, i) => {
                                          return (
                                            <div
                                              className='box img-slider1'
                                              key={'grkw' + i}
                                            >
                                              <img
                                                src={item.image}
                                                alt={item.title}
                                              />
                                              <span className='text'>
                                                {item.desc}
                                              </span>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div className='box'>
                                          <span className='text'>
                                            &nbsp;&nbsp;
                                          </span>
                                        </div>
                                      )}
                                    </Slider>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </section>
            )}
            <section className='homepage2_banner'>
              {' '}
              <img
                src='/assets/images/bottombanner.jpg'
                alt='homepage2_banner'
                className='img-fluid'
              />{' '}
              <div className='text'>
                {' '}
                <h1>Lorem ipsum dolor</h1> <h2> Lorem ipsum chefs </h2>
              </div>{' '}
            </section>{' '}
            <section className='collections selected_you'>
              {' '}
              <div className='container'>
                <h4 className='d-flex justify-content-between align-items-center see_all'>
                  Collections
                </h4>
                {!this.state.matchCollection ? (
                  <div className='collections_slider row'>
                    {this.state.collectionArr.map((collection, q) => {
                      return (
                        <div
                          className='col-sm-6 col-lg-2 col-xl-2 mw-100'
                          key={'uytreer' + q}
                        >
                          <div className='card'>
                            <div className='img_wrap'>
                              <img
                                src={collection.image}
                                alt='Home Bakers'
                                className='img-fluid'
                              />
                            </div>
                            <div
                              className='text'
                              onClick={this.getCollectionsDetails.bind(
                                this,
                                collection.id,
                                collection.title,
                                collection.image,
                                collection.slug_url
                              )}
                            >
                              <p>{collection.title}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Slider
                    className='collections_slider row'
                    {...this.state.collections}
                  >
                    {this.state.collectionArr.map((collection, q) => {
                      return (
                        <div
                          className='col-sm-6 col-lg-2 col-xl-2  mw-100'
                          key={'uuuuuuuuy' + q}
                        >
                          <div className='card'>
                            <div className='img_wrap'>
                              <img
                                src={collection.image}
                                alt='Home Bakers'
                                className='img-fluid'
                              />
                            </div>
                            <div
                              className='text'
                              onClick={this.getCollectionsDetails.bind(
                                this,
                                collection.id,
                                collection.title,
                                collection.image,
                                collection.slug_url
                              )}
                            >
                              <p>{collection.title}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </section>
            {/*<section className='selected_you trending_chefs new_chef_pin'>
              <div className='container'>
                <h4 className='d-flex justify-content-between align-items-center see_all'>
                  Trending Home Chefs
                  <Link
                    to={'/' + this.state.selectedCity + '/trending-home-chef'}
                  >
                    See all{' '}
                    <img
                      src='/assets/images/icons/black-arrow-right.svg '
                      alt=' Right Arrow '
                    />
                  </Link>
                </h4>
                <Slider
                  className='trending_slider row'
                  {...this.state.trendingHomeChef}
                >
                  {this.state.basicChefListingArr.map((basic, n) => {
                    return (
                      <div
                        className='col-sm-6 col-lg-3 mw-100'
                        key={'uhgfdr' + n}
                      >*/}
                        {/* && (localStorage.getItem("selectedLocationName") != "Mumbai" && localStorage.getItem("selectedLocationName") != "Mumbai Suburban" && localStorage.getItem("selectedLocationName") != "Navi Mumbai" && localStorage.getItem("selectedLocationName") != "Thane" && localStorage.getItem("selectedLocationName") != "Greater Mumbai" && localStorage.getItem("selectedLocationName") != "Bombay" && localStorage.getItem("selectedLocationName") != "South Mumbai" && localStorage.getItem("selectedLocationName") != "South Bombay" && localStorage.getItem("selectedLocationName") != "Great Bombay") */}

                       {/* <div
                          data-tag='basicCard'
                          className={
                            basic.is_chef_available === '1'
                              ? 'card'
                              : 'card unavailable-chef-card'
                          }
                          onClick={(e) => {
                            console.log(e.currentTarget.dataset.tag);
                            if (e.currentTarget.dataset.tag === 'basicCard') {
                              this.getInerPage(
                                basic.chef_id,
                                basic.slug_url,
                                basic.name,
                                basic.is_chef_available
                              );
                            }
                          }}
                        >
                          <div className='img_wrap'>
                            {basic.profile_image != null ? (
                              <img
                                src={basic.profile_image}
                                alt=' Selected for You '
                                className=' img-fluid '
                              />
                            ) : (
                              <img
                                src='/assets/images/noimage.png '
                                alt=' Selected for You '
                                className=' img-fluid '
                              />
                            )}
                            <div
                              className='direction'
                              id={'bookmark_' + basic.chef_id}
                              data-tag='basicBookmark'
                              onClick={(e) => {
                                console.log(e.currentTarget.dataset.tag);
                                e.stopPropagation();
                                if (
                                  e.currentTarget.dataset.tag ===
                                  'basicBookmark'
                                ) {
                                  {
                                    localStorage.getItem('userID') !== null
                                      ? basic.is_bookmarked == 1
                                        ? this.removeBookmark(basic.chef_id)
                                        : this.addBookmark(basic.chef_id)
                                      : this.storageHandler();
                                  }
                                }
                              }}
                            >
                              <img
                                id={'bookmark_' + basic.chef_id}
                                src={
                                  basic.is_bookmarked == 1
                                    ? '/assets/images/icons/bookmark.svg'
                                    : '/assets/images/icons/bookmark-empty.svg'
                                }
                                alt=''
                              />
                            </div>
                          </div>
                          <div className='text_wrap'>
                            <h6>
                              <span className='correct_icn'>
                                {basic.brand_name !== ''
                                  ? basic.brand_name
                                  : basic.name}
                              </span>
                            </h6>
                            {basic.cuisines == '' ? (
                              '-'
                            ) : (
                              <p className='sub_title'>{basic.cuisines}</p>
                            )}{' '}
                          </div>{' '}
                        </div>
                      </div>
                    );
                  })}
                </Slider>{' '}
              </div>{' '}
            </section>*/}
            <section className='selected_you disclaimer bg_none'>
              <div className='container'>
                <p>
                  DISCLAIMER: Images on the home chef profile pages on the
                  website and app are not provided by Chef Pin. Items which are
                  delivered may not look exactly as shown. If there is any
                  dispute or litigation that arises as a result of items
                  purchased based on these images, Chef Pin will not take
                  responsibility.
                </p>
              </div>
            </section>
          </div>
        ) : (
          <div className='not_found empty_section'>
            <div className='container'>
              <div className='text-center'>
                <img
                  src='/assets/images/icons/no-saved-addresses.svg'
                  alt='Not Found'
                  className='img-fluid'
                />
                <h6 className='justify-content-center'>OOPS!</h6>
                <p>
                  Sorry but Chef Pin is not active in your <br />
                  location. But we'll be there soon!
                </p>
                <Link to={'/pick-city'} className='btn'>
                  change location
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className={this.state.hideContent ? 'nofooter' : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  timeSlot: state.timeSlot.items,
  chefData: state.chef.items,
  userData: state.user.items,
  oneSignalData: state.onesignal.items
});

export default connect(mapStateToProps, { saveOneSignalData })(
  DinerDashboardPage
);
