/*
#############################################################################
# Name : TrackOrder.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the order tracking details
#############################################################################
*/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import AccountSidebar from "../AccountSidebar";
import DinnerHeader from "../../../../../components/dinnerHeader";
import DinerFooter from "../../../../../components/dinerFooter";
import WhatsAppButton from "../../../../../components/whatsappButton";
import DinerMenuTab from "../../../../../components/dinerMenuTab";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import LoadingDiv from "../../../../../components/LoadingDiv";
import Moment from "moment";
import { getOrderTrackingData } from "../../../../../core/services/Phase2APIservice";

class TrackOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orderTrackData: [],
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    return getOrderTrackingData(
      this.props.userData.userId,
      localStorage.getItem("trackOrderNumber"),
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            orderTrackData: response.data.data.order,
            isLoading: false,
          });
        } else {
          NotificationManager.error(response.data.message, "Error!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* NotificationManager.error(error, "Error!"); */
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    if (localStorage.getItem("accessToken") == null) {
      return <Redirect to="/" />;
    }
    if (
      localStorage.getItem("trackOrderNumber") == null ||
      localStorage.getItem("trackOrderNumber") == ""
    ) {
      return <Redirect to="/current-orders" />;
    }
    const { history, mobileView } = this.props;
    const { isLoading, orderTrackData } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div className="col-md-12 col-lg-8 col-xl-9">
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <Link to="/current-orders" className="res_back_btn">
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </Link>
                        Track Order
                      </h6>
                      <div className="inner current_orders_card">
                        <div className="order_wrap py-0 pb-2">
                          <div className="order_title delivered">
                            <h6 className="order_num">
                              <span>
                                {orderTrackData.is_takeaway_selected == "0"
                                  ? "Delivery"
                                  : "Takeaway"}
                              </span>
                              Order Number : {"#" + orderTrackData.order_number}
                            </h6>
                            {/* <h6 className="order_num chat">CHAT</h6> */}
                          </div>
                        </div>
                        <div className="order_wrap">
                          <div className="order_title delivered">
                            <ol className="ProgressBar">
                              <li
                                className={
                                  orderTrackData.status == "Accepted"
                                    ? "ProgressBar-step is-current"
                                    : orderTrackData.status == "Prepared" ||
                                      orderTrackData.status == "PickedUp"
                                    ? "ProgressBar-step is-complete"
                                    : "ProgressBar-step"
                                }
                              >
                                <span className="ProgressBar-icon"></span>
                                <span className="ProgressBar-stepLabel">
                                  Order accepted by Home Chef{" "}
                                </span>
                              </li>
                              <li
                                className={
                                  orderTrackData.status == "Prepared"
                                    ? "ProgressBar-step is-current"
                                    : orderTrackData.status == "PickedUp"
                                    ? "ProgressBar-step is-complete"
                                    : "ProgressBar-step"
                                }
                              >
                                <span className="ProgressBar-icon"></span>
                                <span className="ProgressBar-stepLabel">
                                  Your order is ready to be picked up
                                </span>
                              </li>
                              {orderTrackData.is_takeaway_selected == "0" && (
                                <li
                                  className={
                                    orderTrackData.status == "PickedUp"
                                      ? "ProgressBar-step is-current"
                                      : "ProgressBar-step"
                                  }
                                >
                                  <span className="ProgressBar-icon"></span>
                                  <span className="ProgressBar-stepLabel">
                                    Order is picked up and is on its way
                                  </span>
                                </li>
                              )}
                            </ol>
                          </div>
                        </div>
                        <div className="order_wrap">
                          <div className="save_add_wrap">
                            <div className="card shadow_none track_location mb-0 py-0">
                              <div className="box title_price pt-0">
                                <h6>
                                  Home Chef{" "}
                                  {orderTrackData.first_name +
                                    " " +
                                    orderTrackData.last_name}
                                </h6>
                                <p className="add">
                                  {orderTrackData.pickup_location}
                                </p>
                              </div>
                              <div className="box pb-0">
                                <h6 className="white_icon">Home</h6>
                                <p>{orderTrackData.drop_location}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="order_wrap">
                          <div className="save_add_wrap">
                            <div className="card shadow_none bill_details mb-0 py-0">
                              <div className="box py-0 px-0">
                                <h6>Bill Details</h6>
                                <div className="bill">
                                  <p>
                                    Item Total{" "}
                                    <span>
                                      INR{" "}
                                      {parseFloat(orderTrackData.amount_paid) -
                                        (parseFloat(orderTrackData.tax_amount) +
                                          parseFloat(
                                            orderTrackData.delivery_charges
                                          ))}
                                    </span>
                                  </p>
                                  <p>
                                    Delivery Partner Fee{" "}
                                    <span>
                                      INR {orderTrackData.delivery_charges}
                                    </span>
                                  </p>
                                  <p>
                                    Taxes and charges{" "}
                                    <span>INR {orderTrackData.tax_amount}</span>
                                  </p>

                                  <hr className="dash_line" />
                                </div>
                                <h6 className="total">
                                  Grand Total{" "}
                                  <span>INR {orderTrackData.amount_paid}</span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps)(TrackOrder);
