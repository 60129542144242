import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import LoadingDiv from "../../components/LoadingDiv";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import {
    EditorState,
    convertFromHTML,
    ContentState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {
    BlogDetailData,
    BlogData,
    BlogCategoryData,
} from "../../backendUI/Blog/config";
import { getDays, getMonth } from "../../backendUI/Fun";
import { URLs } from "../../backendUI/Url";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TumblrShareButton,
    TwitterShareButton,
} from "react-share";
const html = `<p></p>`;

class NewBlogPage1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromText(html)
            ),
        };
        this.state = {
            isLoading: true,
            hideContent: window.matchMedia("(max-width: 991px)").matches,
            articleTag: [],
            articleTitle: "",
            author: "",
            authorImage: "",
            articleContent: "",
            catgory: "",
            time: "",
            categorydata: [],
            data: [],
        };
    }
    // 63ad512db0441bf02241a2c2


    getblogcatdata = () => {
        BlogCategoryData().then((response) => {
            if (response.success == true) {
                this.setState({
                    categorydata: response.data,
                });
            }
        });
    };
    render() {

        return (
            <div className="css2">
                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li>
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/diner-aboutus" className="login_btn">
                                    About Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a
                                    href="https://wa.me/919899622242"
                                    target="_blank"
                                    className="login_btn"
                                >
                                    Contact Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/pick-city" className="login_btn">
                                    Pick A City
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/blog" className="login_btn">
                                    Blog
                                </a>{" "}
                            </li>
                            <li className="d-block d-xl-none last_btn">
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="dinner_menu d-none d-xl-block">
                            <li>
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <div className="menu_overlay"></div>
                    </div>
                </header>

                <div className="container pt-5">
                    <div className="row align-self">
                        <div className="col-md-12 m-0 p-0">
                            <div className="container">
                                <p className="text-center color_a05">Latest Updates

                                </p>
                                <span className="text-center mt-2">
                                    <h1 className="color_a05">15 Top Home Chefs In Delhi-NCR To Taste The Authentic Biryani</h1>
                                </span>
                                <div className="d-flex align-items-center justify-content-center row m-0 p-0">
                                    <span>POSTED ON</span>
                                    <span className="text-center color_a05">
                                        &nbsp;
                                        JAN 9, 2023
                                    </span>
                                    &nbsp;
                                    <span>BY</span> &nbsp;
                                    <span className="text-center color_a05">
                                        AMEETA AGNIHOTRI

                                    </span>
                                </div>

                                {/* <Editor
                                        toolbarHidden
                                        editorState={this.state.editorState}
                                        readOnly
                                    /> */}
                                <br></br>

                                {/* <h2> Working as a home cook? Want to increase your business? </h2> */}
                                <p>Delhi-NCR, the melting pot of diverse cultures and cuisines, offers a plethora of options for food enthusiasts. In the middle of all the posh restaurants and busy streets, you'll find hidden gems of home-cooked delicacies made by renowned chefs. These chefs are master storytellers in the kitchen; their dishes tell tales through the flavours and spices they use. Especially if you’re looking for authentic birayani recipes with vivid flavours. </p>
                                <br></br>

                                <p> <b>Chef Pin: Your Gateway to Gourmet Home-Cooked Delights </b></p>

                                <p>Chef Pin is the ultimate platform connecting you with these amazing home chefs. Whether you're craving Chicken Biryani, Mutton Biryani, or even the unique Kathal Biryani, Chef Pin's got you covered. It's all about delivering homemade Biryani to your door, ensuring you get that authentic, homely touch with every bite. These top Home Chefs In Delhi-NCR service is available for both delivery and takeaway, and they operate around the week from 09:00 AM to 09:00 PM. Remember, a 24-hour notice is needed for all orders – planning is key!</p>



                                <hr />
                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3> Magic Mom: The Biryani Fiesta </h3>
                                                        <h6> by Anju Narang </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    {/* <h6>Bakery And Confectionery</h6> */}
                                                    <p>
                                                        The magic of North Indian cuisine is brought to you by Anju Narang of Magic Mom. Every meal Anju makes, especially her Chola Bhatura, showcases her immense enthusiasm for cooking.  So, order the day before and enjoy her <b> <a href="/homemade-food-service-near-me"> home food service </a> </b> and delicious treats.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours -- 09:00 AM- 08:00 PM
                                                        <br></br>
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/magic-mom" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_1.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>The gastronomic adventures of KEF Deli</h3>
                                                        <h6>by Aarti Malhotra</h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        You may have the greatest Cream Chicken and Keema Kheesa at KEF Deli, run by Aarti Malhotra. Any serious eater looking to <b> <a href="/home-chefs-near-me"> order from a home chef </a> </b> should make it a point to sample some of Aarti's nostalgically spiced dishes.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours -- 10:00 AM- 07:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/kef-deli" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_2.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Gourmet Delights at Your Doorstep</h3>
                                                        <h6> by Team Garniche </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        The cuisines offered by Team Garniche include a medley of Chinese, Italian, Mughlai, Thai, and Indian influences. These Top Home Chefs In Delhi-NCR tantalizing dishes include Covid Meals, Mutton Galouti Kebabs, and Chef Special Mushroom.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours -- 09:00 AM- 09:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/garniche-chef-at-your-doorstep" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_3.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Biryani Veere:  It’s More Than Just Biryani!</h3>
                                                        <h6> by Vibhor Guha </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Biryani lovers must dine at Vibhor Guha's Biryani Veere. With his background in hospitality management, Vibhor crafts biryani that represents unity and community, with a specialization in chicken. So don't crave it and look for biryani near me.

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours--10:00 AM- 06:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/biryani-veere" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_4.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>


                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>The Fusion Fantasy of Desi Beads</h3>
                                                        <h6>by Apoorva Singh </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Fusion is everything to DESIBEADS' Apoorva Singh. Her dishes, which range from Kaju Paneer to Uttarakhandi Puri Combo, showcase her love and skill for cooking a variety of cuisines and always gears up for home food service.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours--11:00 AM- 11:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/desibeads" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_5.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Savoury and Sage - The Homely Touch</h3>
                                                        <h6>by Aatika Kapoor </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Savoury and Sage's Aatika Kapoor is well-known for her Asian-inspired dishes, including tea cakes, quiches, and more. These Best home chefs in Delhi showcase her dedication to providing high-quality, sanitary meals and her culinary adventure.



                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---11:00 AM- 08:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/savoury-and-sage" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_6.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Tank's Kitchen: A Culinary Duo</h3>
                                                        <h6>by Shwetank Shekhar </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        The culinary passions of Shwetank Shekhar and his wife, both employed in the hotel industry, are brought to Tank's Kitchen. Butter Chicken is only one of several meals this restaurant offers that focuses on North Indian and Chinese cuisines.

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---11:00 AM- 10:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/tank-s-kitchen" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_7.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>


                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>The Mughlai Mysteries of Arba's Kitchenette</h3>
                                                        <h6> by Mohammed Shahid </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Mughlai delicacies, including Chicken/Mutton Shammi kebab and the Chef's Special Dessert—' Ande ka Halwa'—are available at Arba's Kitchenette, which Chef Mohammed Shahid creates. Get some Mughlai magic with this Top Home chef in Delhi-NCR.

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---11:00 AM- 06:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/arba-s-kitchenette" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_8.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>A Delicious Dish: Dastarkhwan-e-Sana Begum</h3>
                                                        <h6> by Sana Khan </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        The delectable Chicken Nahari and Biryani result from Sana's positive energy and the power of prayer in the kitchen. For all biryani lovers, search 'biryani near me' to discover authentic biryani, crafted with love and passion, right in your vicinity."


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---02:00 AM- 08:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/dastarkhwan-e-sana-begum" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_9.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>



                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Chef at Your Service— Rajeev's Rendezvous with Flavors</h3>
                                                        <h6>by Rajeev Lal </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        For Rajeev, it's all about following one's heart and making people happy. People looking for homemade Biryani online in Delhi can connect with him as his specialities include Paneer Lababdar and Lucknowi Biryani.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---11:00 AM- 10:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/chef-at-your-service" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_10.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>



                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Unleashing the Flavours of Nuz Kitchen</h3>
                                                        <h6> by Nuzhat Khan </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Mutton Bihari Kabab and White Sauce Pasta are just two of the many dishes offered by Nuzhat Khan of Nuz Kitchen. Her creative and enthusiastic cooking approach is what sets her meals apart.



                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---01:00 AM- 10:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/nuz-kitchen" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_11.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>



                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Hungry Tummy: Naveen's Nostalgic Nibbles</h3>
                                                        <h6>by Naveen Sawhney </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Naveen Sawhney, the man behind Hungry Tummy, is a chef with experience in the merchant navy. Naveen, the best home chefs in Delhi, is dedicated to improving his craft and is an expert in Makhni Murg and Tikkas.



                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---02:00 AM- 08:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/hungry-tummy" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_12.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>



                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>The Mathematical Biryani from Alka's Kitchen</h3>
                                                        <h6> by Alka Singhal </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Mutton and chicken biryani are two of Alka Singhal's most famous dishes from her restaurant, Alka's Kitchen. If you're on a quest for homemade Biryani online in Delhi, Alka's Kitchen is your perfect destination where she brings solace to others through her cuisine.



                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---09:00 AM- 04:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/alka-s-kitchen" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_13.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Bengal Biryani Palace: A Bengali Bliss</h3>
                                                        <h6> by Krishnendu Kar </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        If you are looking for real Bengali Thali, Awadhi Biriyani, or biryani near me, then Krishnendu Kar's Bengal Biryani Palace is the place to go. His one-of-a-kind take on classic Bengali and Awadhi dishes is sure to please.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---02:00 AM- 08:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/bengal-biryani-palace" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_14.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Bageechi: Old Delhi Delights </h3>
                                                        <h6>by Venu Mathur </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Vegetarian delicacies like mutton and chicken dishes are Venu Mathur's forte at Bageechi. His recipes originate from Old Delhi and are passed down through generations. Discover deliciousness with this homemade Biryani online in Delhi, where her Biryani and Khade Masala Gosht are a testament to culinary excellence.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        Operating hours---10:00 AM- 07:00 PM
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/bageechi" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Biryani_15.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>

                <div className={this.state.hideContent ? "nofooter" : null}>
                    <WhatsAppButton />
                </div>

                <DinerMenuTab />
                <DinerFooter />
            </div>
        );
    }
}

export default NewBlogPage1;
