/*
#############################################################################
# Name : policy.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the policy
#############################################################################
*/
import React, { Component } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { Helmet } from "react-helmet";

class PolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  render() {
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        <Helmet>
          <title>Know More About Our Chef Policy</title>
          <meta name="title" content="Know More About Our Chef Policy" />
          <meta name="description" content="Read About Chef Pin Home Chef Policy" />
        </Helmet>
        {this.state.hideContent ? null : <Header />}
        <section className="sign_up_chef term_use">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-md-4 col-xl-4 ">
                <div id="sticky-anchor"></div>
                <div
                  id="menu-box"
                  className="terms_menu_list desktop_terms"
                  data-simplebar
                >
                  <h5 className="text-uppercase">contents</h5>
                  <ul>
                    <li className="active">
                      <a href="#YourConsent">Your consent</a>
                    </li>
                    <li>
                      <a href="#PolicyChanges">Policy Changes</a>
                    </li>
                    <li>
                      <a href="#LinksOtherWebsites">Links to other websites</a>
                    </li>
                    <li>
                      <a href="#InformationCollect">
                        Information we collect from you
                      </a>
                    </li>
                    <li>
                      <a href="#Cookies">Cookies</a>
                    </li>
                    <li>
                      <a href="#UsesInformation">Uses of your information</a>
                    </li>
                    <li>
                      <a href="#DisclosureInformation">
                        Disclosure and Distribution of your information
                      </a>
                    </li>
                    <li>
                      <a href="#DataSecurity">Data security precautions</a>
                    </li>
                    <li>
                      <a href="#OptOut">Opt - out</a>
                    </li>
                    <li>
                      <a href="#GrievanceOfficer">
                        Grievance officer and Chef Pin platform security
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-8 col-xl-8 ">
                <div className="row">
                  <div className="col-12">
                    <h2 className="fw_500 text-uppercase color_C07 res_back_btn_wrap">
                      <a
                        onClick={() => {
                          this.props.removeMobileView();
                          this.props.history.goBack();
                        }}
                        className="res_back_btn mobile_sidebar_back_btn"
                      >
                        <img
                          src="assets/images/icons/left-arrow-black.svg"
                        />
                      </a>
                      privacy policy
                    </h2>
                    <div className="para_wrap">
                      <p>
                        This Privacy Policy (“Policy”) describes the policies
                        and procedures on the collection, use, disclosure and
                        protection of your information when you use our website
                        located at chefp.in, or the Chef Pin mobile application
                        (collectively, “Chef Pin Platform”) made available by
                        Chef Pin Private Limited (“Chef Pin ”, “Company”, “we”,
                        “us” and “our”), a private company established under the
                        Companies Act 2013 having its registered office at Suite
                        611, 6th Floor, Global Foyer, Golf Course Road,
                        Gurugram, Haryana 122002
                      </p>
                      <p>
                        The terms “you” and “your” refer to the user of the Chef
                        Pin Platform. The term “Services” refers to any services
                        offered by Chef Pin whether on the Chef Pin Platform or
                        otherwise.
                      </p>
                      <p>
                        Please read this Policy before using the Chef Pin
                        Platform or submitting any personal information to Chef
                        Pin. This Policy is a part of and incorporated within,
                        and is to be read along with, the Terms of Use.
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div id="back_content"></div>
                    <div id="menu-box" className="terms_menu_list mobile_terms">
                      <h5 className="text-uppercase">contents</h5>
                      <ul>
                        <li className="active">
                          <a href="#YourConsent">Your consent</a>
                        </li>
                        <li>
                          <a href="#PolicyChanges">Policy Changes</a>
                        </li>
                        <li>
                          <a href="#LinksOtherWebsites">
                            Links to other websites
                          </a>
                        </li>
                        <li>
                          <a href="#InformationCollect">
                            Information we collect from you
                          </a>
                        </li>
                        <li>
                          <a href="#Cookies">Cookies</a>
                        </li>
                        <li>
                          <a href="#UsesInformation">
                            Uses of your information
                          </a>
                        </li>
                        <li>
                          <a href="#DisclosureInformation">
                            Disclosure and Distribution of your information
                          </a>
                        </li>
                        <li>
                          <a href="#DataSecurity">Data security precautions</a>
                        </li>
                        <li>
                          <a href="#OptOut">Opt - out</a>
                        </li>
                        <li>
                          <a href="#GrievanceOfficer">
                            Grievance officer and Chef Pin platform security
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 ul_wrap">
                    <div id="YourConsent" className="box">
                      <div className="para_wrap">
                        <h6 className="text-uppercase color_C07">
                          your consent
                        </h6>
                        <p>
                          By using the Chef Pin Platform and the Services, you
                          agree and consent to the collection, transfer, use,
                          storage, disclosure and sharing of your information as
                          described and collected by us in accordance with this
                          Policy. If you do not agree with the Policy, please do
                          not use or access the Chef Pin Platform.
                        </p>
                        <p>
                          <a href="#back_content" className="back_con_link">
                            Back to Contents
                          </a>
                        </p>
                      </div>
                    </div>
                    <div id="PolicyChanges" className="box">
                      <div className="para_wrap">
                        <h6 className="text-uppercase color_C07">
                          POLICY CHANGES
                        </h6>
                        <p>
                          We may occasionally update this Policy and such
                          changes will be posted on this page. If we make any
                          significant changes to this Policy we will endeavour
                          to provide you with reasonable notice of such changes,
                          such as via prominent notice on the Chef Pin Platform
                          or to your email address on record and where required
                          by applicable law, we will obtain your consent. To the
                          extent permitted under the applicable law, your
                          continued use of our Services after we publish or send
                          a notice about our changes to this Policy shall
                          constitute your consent to the updated Policy.
                        </p>
                        <p>
                          <a href="#back_content" className="back_con_link">
                            Back to Contents
                          </a>
                        </p>
                      </div>
                    </div>
                    <div id="LinksOtherWebsites" className="box">
                      <div className="para_wrap">
                        <h6 className="text-uppercase color_C07">
                          LINKS TO OTHER WEBSITES
                        </h6>
                        <p>
                          The Chef Pin Platform may contain links to other
                          websites. Any personal information about you collected
                          whilst visiting such websites is not governed by this
                          Policy. Chef Pin shall not be responsible for and has
                          no control over the practices and content of any
                          website accessed using the links contained on the Chef
                          Pin Platform. This Policy shall not apply to any
                          information you may disclose to any of our service
                          providers/service personnel which we do not require
                          you to disclose to us or any of our service providers
                          under this Policy.
                        </p>
                        <p>
                          <a href="#back_content" className="back_con_link">
                            Back to Contents
                          </a>
                        </p>
                      </div>
                    </div>
                    <div id="InformationCollect" className="box">
                      <div className="para_wrap">
                        <h6 className="text-uppercase color_C07">
                          INFORMATION WE COLLECT FROM YOU
                        </h6>
                        <p>
                          We will collect and process the following information
                          about you:
                        </p>
                        <p className="fw_500 mb-2">
                          • Information you give us -
                        </p>
                        <p className="mb-2">
                          This includes information submitted when you:
                        </p>
                        <ul className="terms_text_list">
                          <li>
                            Create or update your Chef Pin account, which may
                            include your name, email, phone number, login name
                            and password, address, payment or banking
                            information, date of birth and profile picture. If
                            you sign in to the Chef Pin Platform through
                            third-party sign-in services such as Facebook,
                            Google or Gmail or any other social networking or
                            similar site (collectively, “SNS”), an option of
                            which may be provided to you by Chef Pin at its sole
                            discretion, you will be allowing us to pass through
                            and receive from the SNS your log-in information and
                            other user data; or
                          </li>
                          <li>
                            Provide content to us, which may include reviews,
                            ordering details and history, favorite vendors,
                            special merchant requests, contact information of
                            people you refer to us and other information you
                            provide on the Chef Pin Platform (“Your Content”).
                          </li>
                          <li>
                            Use our Services, we may collect and store
                            information about you to process your requests and
                            automatically complete forms for future
                            transactions, including (but not limited to) your
                            phone number, address, email, billing information
                            and credit or payment card information.
                          </li>
                          <li>
                            Correspond with Chef Pin for customer support;
                          </li>
                          <li>
                            Participate in the interactive services offered by
                            the Chef Pin Platform such as discussion boards,
                            competitions, promotions or surveys, other social
                            media functions or make payments etc., or
                          </li>
                          <li>
                            Enable features that require Chef Pin 's access to
                            your address book, calendar or location
                          </li>
                          <li>Report problems for troubleshooting.</li>
                          <li>
                            If you sign up to use our Services as a merchant or
                            a delivery partner, we may collect location details,
                            copies of government identification documents and
                            other details (KYC), call and SMS details.
                          </li>
                        </ul>
                        <p className="fw_500 mb-2">
                          • Information we collect about you -
                        </p>
                        <p>
                          With regard to each of your visits to the Chef Pin
                          Platform, we will automatically collect and analyse
                          the following demographic and other information:
                        </p>
                        <ul className="terms_text_list">
                          <li>
                            When you communicate with us (via email, phone,
                            through the Chef Pin Platform or otherwise), we may
                            maintain a record of your communication;
                          </li>
                          <li>
                            <b className="fw_500">Location information:</b>{" "}
                            Depending on the Services that you use, and your app
                            settings or device permissions, we may collect your
                            real time information, or approximate location
                            information as determined through data such as GPS,
                            IP address;
                          </li>
                          <li>
                            <b className="fw_500">
                              Usage and Preference Information:
                            </b>{" "}
                            We collect information as to how you interact with
                            our Services, preferences expressed and settings
                            chosen. Chef Pin Platform includes the Chef Pin
                            advertising services (“Ad Services”), which may
                            collect user activity and browsing history within
                            the Chef Pin Platform and across third-party sites
                            and online services, including those sites and
                            services that include our ad pixels (“Pixels”),
                            widgets, plug-ins, buttons, or related services or
                            through the use of cookies. Our Ad Services collect
                            browsing information including without limitation
                            your Internet protocol (IP) address and location,
                            your login information, browser type and version,
                            date and time stamp, user agent, Chef Pin cookie ID
                            (if applicable), time zone setting, browser plug-in
                            types and versions, operating system and platform,
                            and other information about user activities on the
                            Chef Pin Platform, as well as on third party sites
                            and services that have embedded our Pixels, widgets,
                            plug-ins, buttons, or related services;
                          </li>
                          <li>
                            <b className="fw_500">Transaction Information:</b>{" "}
                            We collect transaction details related to your use
                            of our Services, and information about your activity
                            on the Services, including the full Uniform Resource
                            Locators (URL), the type of Services you requested
                            or provided, comments, domain names, search results
                            selected, number of clicks, information and pages
                            viewed and searched for, the order of those pages,
                            length of your visit to our Services, the date and
                            time you used the Services, amount charged, details
                            regarding application of promotional code, methods
                            used to browse away from the page and any phone
                            number used to call our customer service number and
                            other related transaction details;
                          </li>
                          <li>
                            <b className="fw_500">Device Information:</b> We may
                            collect information about the devices you use to
                            access our Services, including the hardware models,
                            operating systems and versions, software, file names
                            and versions, preferred languages, unique device
                            identifiers, advertising identifiers, serial
                            numbers, device motion information and mobile
                            network information. Analytics companies may use
                            mobile device IDs to track your usage of the Chef
                            Pin Platform;
                          </li>
                          <li>
                            <b className="fw_500">
                              Stored information and files:
                            </b>{" "}
                            Chef Pin mobile application (Chef Pin app) may also
                            access metadata and other information associated
                            with other files stored on your mobile device. This
                            may include, for example, photographs, audio and
                            video clips, personal contacts and address book
                            information. If you permit the Chef Pin app to
                            access the address book on your device, we may
                            collect names and contact information from your
                            address book to facilitate social interactions
                            through our services and for other purposes
                            described in this Policy or at the time of consent
                            or collection. If you permit the Chef Pin app to
                            access the calendar on your device, we collect
                            calendar information such as event title and
                            description, your response (Yes, No, Maybe), date
                            and time, location and number of attendees.
                          </li>
                          <li>
                            If you are a partner supplier, merchant or a
                            delivery partner, we will, additionally, record your
                            calls with us made from the device used to provide
                            Services, related call details, SMS details location
                            and address details.
                          </li>
                        </ul>
                        <p className="fw_500 mb-2">
                          • Information we receive from other sources -
                        </p>
                        <ul className="terms_text_list">
                          <li>
                            We may receive information about you from third
                            parties, such as other users, partners (including ad
                            partners, analytics providers, search information
                            providers), or our affiliated companies or if you
                            use any of the other websites/apps we operate or the
                            other Services we provide. Users of our Ad Services
                            and other third-parties may share information with
                            us such as the cookie ID, device ID, or demographic
                            or interest data, and information about content
                            viewed or actions taken on a third-party website,
                            online services or apps. For example, users of our
                            Ad Services may also be able to share customer list
                            information (e.g., email or phone number) with us to
                            create customized audience segments for their ad
                            campaigns.
                          </li>
                          <li>
                            When you sign in to Chef Pin Platform with your SNS
                            account, or otherwise connect to your SNS account
                            with the Services, you consent to our collection,
                            storage, and use, in accordance with this Policy, of
                            the information that you make available to us
                            through the social media interface. This could
                            include, without limitation, any information that
                            you have made public through your social media
                            account, information that the social media service
                            shares with us, or information that is disclosed
                            during the sign-in process. Please see your social
                            media provider’s privacy policy and help center for
                            more information about how they share information
                            when you choose to connect your account.
                          </li>
                          <li>
                            If you are a partner supplier, merchant or a
                            delivery partner, we may, additionally, receive
                            feedback and rating from other users.
                          </li>
                        </ul>
                        <p>
                          <a href="#back_content" className="back_con_link">
                            Back to Contents
                          </a>
                        </p>
                      </div>
                    </div>
                    <div id="Cookies" className="box">
                      <h6 className="text-uppercase color_C07">COOKIES</h6>
                      <p>
                        Our Chef Pin Platform and third parties with whom we
                        partner, may use cookies, pixel tags, web beacons,
                        mobile device IDs, “flash cookies” and similar files or
                        technologies to collect and store information with
                        respect to your use of the Services and third-party
                        websites.
                      </p>
                      <p>
                        Cookies are small files that are stored on your browser
                        or device by websites, apps, online media and
                        advertisements. We use cookies and similar technologies
                        for purposes such as:
                      </p>
                      <p>
                        i) Authenticating users; <br />
                        ii) Remembering user preferences and settings; <br />
                        iii) Determining the popularity of content; <br />
                        iv) Delivering and measuring the effectiveness of
                        advertising v) campaigns; <br />
                        v) Analysing site traffic and trends, and generally
                        understanding the online behaviours and interests of
                        people who interact with our services.
                      </p>
                      <p>
                        A pixel tag (also called a web beacon or clear GIF) is a
                        tiny graphic with a unique identifier, embedded
                        invisibly on a webpage (or an online ad or email), and
                        is used to count or track things like activity on a
                        webpage or ad impressions or clicks, as well as to
                        access cookies stored on users’ computers. We use pixel
                        tags to measure the popularity of our various pages,
                        features and services. We also may include web beacons
                        in e-mail messages or newsletters to determine whether
                        the message has been opened and for other analytics.
                      </p>
                      <p>
                        To modify your cookie settings, please visit your
                        browser’s settings. By using our Services with your
                        browser settings to accept cookies, you are consenting
                        to our use of cookies in the manner described in this
                        section.
                      </p>
                      <p>
                        We may also allow third parties to provide audience
                        measurement and analytics services for us, to serve
                        advertisements on our behalf across the Internet, and to
                        track and report on the performance of those
                        advertisements. These entities may use cookies, web
                        beacons, SDKs and other technologies to identify your
                        device when you visit the Chef Pin Platform and use our
                        Services, as well as when you visit other online sites
                        and services.
                      </p>
                      <p>
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="UsesInformation" className="box">
                      <div className="para_wrap">
                        <h6 className="text-uppercase color_C07">
                          USES OF YOUR INFORMATION
                        </h6>
                        <p>
                          We use the information we collect for following
                          purposes, including:
                        </p>
                        <ul className="terms_text_list">
                          <li>
                            To provide, personalise, maintain and improve our
                            products and services, such as to enable deliveries
                            and other services, enable features to personalise
                            your Chef Pin account;
                          </li>
                          <li>
                            To carry out our obligations arising from any
                            contracts entered into between you and us and to
                            provide you with the relevant information and
                            services;
                          </li>
                          <li>
                            To administer and enhance the security of our Chef
                            Pin Platform and for internal operations, including
                            troubleshooting, data analysis, testing, research,
                            statistical and survey purposes;
                          </li>
                          <li>
                            To provide you with information about services we
                            consider similar to those that you are already
                            using, or have enquired about, or may interest you.
                            If you are a registered user, we will contact you by
                            electronic means (e-mail or SMS or telephone or
                            WhatsApp) with information about these services;
                          </li>
                          <li>
                            To understand our users (what they do on our
                            Services, what features they like, how they use
                            them, etc.), improve the content and features of our
                            Services (such as by personalizing content to your
                            interests), process and complete your transactions,
                            make special offers, provide customer support,
                            process and respond to your queries;
                          </li>
                          <li>
                            To generate and review reports and data about, and
                            to conduct research on, our user base and Service
                            usage patterns;
                          </li>
                          <li>
                            To allow you to participate in interactive features
                            of our Services, if any; or
                          </li>
                          <li>
                            To measure or understand the effectiveness of
                            advertising we serve to you and others, and to
                            deliver relevant advertising to you.
                          </li>
                          <li>
                            If you are a partner supplier or merchant or
                            delivery partner, to track the progress of delivery
                            or status of the order placed by our customers.
                          </li>
                        </ul>
                        <p>
                          We may combine the information that we receive from
                          third parties with the information you give to us and
                          information we collect about you for the purposes set
                          out above. Further, we may anonymize and/or
                          de-identify information collected from you through the
                          Services or via other means, including via the use of
                          third-party web analytic tools. As a result, our use
                          and disclosure of aggregated and/or de-identified
                          information is not restricted by this Policy, and it
                          may be used and disclosed to others without
                          limitation.
                        </p>
                        <p>
                          We analyse the log files of our Chef Pin Platform that
                          may contain Internet Protocol (IP) addresses, browser
                          type and language, Internet service provider (ISP),
                          referring, app crashes, page viewed and exit websites
                          and applications, operating system, date/time stamp,
                          and clickstream data. This helps us to administer the
                          website, to learn about user behavior on the site, to
                          improve our product and services, and to gather
                          demographic information about our user base as a
                          whole.
                        </p>
                        <p>
                          <a href="#back_content" className="back_con_link">
                            Back to Contents
                          </a>
                        </p>
                      </div>
                    </div>
                    <div id="DisclosureInformation" className="box">
                      <div className="para_wrap">
                        <h6 className="text-uppercase color_C07">
                          DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION
                        </h6>
                        <p>
                          We may share your information that we collect for
                          following purposes:
                        </p>
                        <ul className="terms_text_list">
                          <li>
                            <b className="fw_500">With Service Providers:</b> We
                            may share your information with our vendors,
                            consultants, marketing partners, research firms and
                            other service providers or business partners, such
                            as Payment processing companies, to support our
                            business. For example, your information may be
                            shared with outside vendors to send you emails and
                            messages or push notifications to your devices in
                            relation to our Services, to help us analyze and
                            improve the use of our Services, to process and
                            collect payments. We also may use vendors for other
                            projects, such as conducting surveys or organizing
                            contests for us.
                          </li>
                          <li>
                            <b className="fw_500">
                              With Partner suppliers/Merchant:
                            </b>{" "}
                            While you place a request to order food through the
                            Chef Pin Platform, your information is provided to
                            us and to the suppliers/merchants with whom you may
                            choose to order. In order to facilitate your online
                            food order processing, we provide your information
                            to that supplier/merchant in a similar manner as if
                            you had made a food order directly with the
                            supplier. If you provide a mobile phone number, Chef
                            Pin may send you text messages regarding the order’s
                            delivery status.
                          </li>
                          <li>
                            <b className="fw_500">With Other Users:</b> If you
                            are a delivery partner, we may share your name,
                            phone number and/or profile picture (if applicable),
                            tracking details with other users to provide them
                            the Services.
                          </li>
                          <li>
                            <b className="fw_500">
                              For Crime Prevention or Investigation:
                            </b>{" "}
                            We may share this information with governmental
                            agencies or other companies assisting us, when we
                            are:
                            <ul className="terms_text_list">
                              <li>
                                Obligated under the applicable laws or in good
                                faith to respond to court orders and processes;
                                or
                              </li>
                              <li>
                                Detecting and preventing against actual or
                                potential occurrence of identity theft, fraud,
                                abuse of Services and other illegal acts;
                              </li>
                              <li>
                                Responding to claims that an advertisement,
                                posting or other content violates the
                                intellectual property rights of a third party;
                              </li>
                            </ul>
                            or to protect the rights, property, or safety of the
                            Company, our customers, or others, or in the event
                            of a claim or dispute relating to your use of our
                            Services. This includes exchanging information with
                            other companies and organisations for the purposes
                            of fraud detection and credit risk reduction.
                          </li>
                          <li>
                            <b className="fw_500">For Internal Use:</b> We may
                            share your information with any present or future
                            member of our “Group” (as defined below) or
                            affiliates for our internal business purposes. The
                            term “Group” means, with respect to any person, any
                            entity that is controlled by such person, or any
                            entity that controls such person, or any entity that
                            is under common control with such person, whether
                            directly or indirectly, or, in the case of a natural
                            person, any Relative (as such term is defined in the
                            Companies Act, 1956 and Companies Act, 2013 to the
                            extent applicable) of such person.
                          </li>
                          <li>
                            <b className="fw_500">
                              With Advertisers and advertising networks:
                            </b>{" "}
                            We may work with third parties such as network
                            advertisers to serve advertisements on the Chef Pin
                            Platform and on third-party websites or other media
                            (e.g., social networking platforms). These third
                            parties may use cookies, JavaScript, web beacons
                            (including clear GIFs), Flash LSOs and other
                            tracking technologies to measure the effectiveness
                            of their ads and to personalize advertising content
                            to you. While you cannot opt out of advertising on
                            the Chef Pin Platform, you may opt out of much
                            interest-based advertising on third party sites and
                            through third party ad networks (including
                            DoubleClick Ad Exchange, Facebook Audience Network
                            and Google AdSense). For more information, visit
                            www.aboutads.info/choices. Opting out means that you
                            will no longer receive personalized ads by third
                            parties ad networks from which you have opted out,
                            which is based on your browsing information across
                            multiple sites and online services. If you delete
                            cookies or change devices, your opt out may no
                            longer be effective.
                          </li>
                        </ul>
                        <p className="fw_500 mb-2">
                          To fulfill the purpose for which you provide it.
                        </p>
                        <p>
                          We may share your information other than as described
                          in this Policy if we notify you and you consent to the
                          sharing.
                        </p>
                        <p>
                          <a href="#back_content" className="back_con_link">
                            Back to Contents
                          </a>
                        </p>
                      </div>
                    </div>
                    <div id="DataSecurity" className="box">
                      <h6 className="text-uppercase color_C07">
                        DATA SECURITY PRECAUTIONS
                      </h6>
                      <p>
                        We have in place appropriate technical and security
                        measures to secure the information collected by us.
                      </p>
                      <p>
                        You are advised not to send your full credit/debit card
                        details through unencrypted electronic platforms. Where
                        we have given you (or where you have chosen) a username
                        and password which enables you to access certain parts
                        of the Chef Pin Platform, you are responsible for
                        keeping these details confidential. We ask you not to
                        share your password with anyone.
                      </p>
                      <p>
                        Please we aware that the transmission of information via
                        the internet is not completely secure. Although we will
                        do our best to protect your personal data, we cannot
                        guarantee the security of your data transmitted through
                        the Chef Pin Platform. Once we have received your
                        information, we will use strict physical, electronic,
                        and procedural safeguards to try to prevent unauthorised
                        access.
                      </p>
                      <p>
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="OptOut" className="box">
                      <h6 className="text-uppercase color_C07">OPT-OUT</h6>
                      <p>
                        When you sign up for an account, you are opting in to
                        receive alerts, emails, notifications, messages etc.
                        from Chef Pin. You can follow the “unsubscribe”
                        instructions in commercial email messages, but note that
                        you cannot opt out of receiving certain administrative
                        notices, service notices, or legal notices from Chef
                        Pin.
                      </p>
                      <p>
                        If you wish to withdraw your consent for the use and
                        disclosure of your personal information in the manner
                        provided in this Policy, please write to us. Please note
                        that we may take time to process such requests, after
                        which we will not use your personal data for any
                        processing unless required by us to comply with our
                        legal obligations. We may not be able offer you any or
                        all Services upon such withdrawal of your consent.
                      </p>
                      <p>
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                    <div id="GrievanceOfficer" className="box">
                      <h6 className="text-uppercase color_C07">
                        GRIEVANCE OFFICER AND CHEF PIN PLATFORM SECURITY
                      </h6>
                      <p>
                        If you have any queries relating to the processing or
                        usage of information provided by you in connection with
                        this Policy, please email us{" "}
                        <a href="mailto:info@chefp.in" className="terms_links">
                          info@chefp.in
                        </a>{" "}
                        or write to our Grievance Officer at the following
                        address:
                      </p>
                      <p className="fw_500 mb-2">Chef Pin Grievance Officer</p>
                      <p>
                        Chef Pin Private Limited,
                        <br />
                        Suite 611, 6th Floor,
                        <br />
                        Global Foyer,
                        <br />
                        Golf Course Road,
                        <br />
                        Gurugram, Haryana 122002
                      </p>
                      <p>
                        If you come across any abuse or violation of the Policy,
                        please report it to info@chefp.in
                      </p>
                      <p>
                        Further, please note that the Chef Pin Platform may
                        store this data on their servers located outside of
                        India. Chef Pin has security measures in place to
                        protect the loss, misuse and alteration of the
                        information. The privacy policy adopted by Chef Pin are
                        detailed in www.chefp.in. In the event you have
                        questions or concerns about the security measures
                        adopted by Chef Pin, you can contact their data
                        protection / privacy team / legal team or designated
                        grievance officer.
                      </p>
                      <p>
                        <a href="#back_content" className="back_con_link">
                          Back to Contents
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <WhatsAppButton />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(PolicyPage);
