/*
#############################################################################
# Name : editProfile.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to edit the chef profile details
#############################################################################
*/
import React, { Component } from "react";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import OtpInput from "react-otp-input";
import axios from "axios";
import {
  getProfileDataAPI,
  getMobileOTPAPI,
  getMobileOTPVerfiyAPI,
  getEmailOTPVerfiyAPI,
  getEmailOTPAPI,
  getChangePasswordAPI
} from "../../core/services/APIservice";

import config from "../../config/config";
import Recaptcha from 'react-google-invisible-recaptcha';

// React Notification
import { NotificationManager } from "react-notifications";

import { connect } from "react-redux";
import { saveUserData } from "../../redux/actions/authActions";
const numberReg = /^[0]?[789]\d{9}$/;
const nameValidation = /^[a-zA-Z ]+$/;
const passwordReg = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,}$/;
const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class EditProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 1,
      seconds: 0,
      firstname: "",
      lastname: "",
      emailId: "",
      mobileNumber: "",
      userEmail: "",
      password: "",
      userMobileNumber: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      profilePhoto: "",
      profilePhotoName: "",
      emailStatus: false,
      mobileStatus: false,
      otpStatus: false,
      otp: "",
      emailOtp: "",
      passwordStatus: false,
      passwordError: "",
      errorProfileValid: "",
      showMobile: false,
      mobileOTP: false,
      showEmail: false,
      emailIDOTP: false,
      mobileButtonDisable: false,
      emailButtonDisable: false,
      formErrors: {
        firstname: "",
        lastname: "",
        emailId: "",
        mobileNumber: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      }
    };
    this.handleProfileChange = this.handleProfileChange.bind(this);
  }

  componentDidMount = () => {
    this.getProfileData();          // Profile detail data is fetched
    var userId = '';
    userId = localStorage.getItem('userID');
    if (localStorage.getItem('userID') === null) {
      this
        .props
        .history
        .push('/');
    }
  };

  /* handleNumberChange functions starts here */
  handleNumberChange = e => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'firstname':
        formErrors.firstname = value === "" ? 'First Name is required' : !nameValidation.test(e.target.value) ? "Enter valid Name.": "";
        break;

      case 'lastname':
        formErrors.lastname = value === "" ? 'Last Name is required' : !nameValidation.test(e.target.value) ? "Enter valid Name.": "";
        break;

      case 'mobileNumber':
        formErrors.mobileNumber = value === "" ? 'Mobile Number is required.' : !numberReg.test(e.target.value) ? "Enter valid Mobile Number." : "";
        break;

      case 'emailId':
        formErrors.emailId = value === "" ? 'Email Id is required' : !emailReg.test(e.target.value) ? "Enter valid Email Id." : "";
        break;

      case 'oldPassword':
        formErrors.oldPassword = value === "" ? 'Old Password is required' : !passwordReg.test(e.target.value) ? "Enter minimum 6 character password." : "";
        break;

      case 'newPassword':
        formErrors.newPassword = value === "" ? 'New Password is required' : !passwordReg.test(e.target.value) ? "Enter minimum 6 character password." : "";
        break;

      case 'confirmPassword':
        formErrors.confirmPassword = value === "" ? 'Confirm Password is required' : !passwordReg.test(e.target.value) ? "Enter minimum 6 character password." : "";
        break;
    }
    this.setState({ formErrors, [name]: value })
  }
  /* handleNumberChange functions ends here */

  /* getProfileData functions starts here */
  getProfileData = () => {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getProfileDataAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            firstname: response.data["data"]["first_name"],
            lastname: response.data["data"]["last_name"],
            userMobileNumber: response.data["data"]["mobile_number"],
            userEmail: response.data["data"]["email"],
            profilePhotoName: /[^/]*$/.exec(
              response.data["data"]["profile_image"]
            )[0],
          });
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
      });
  };
  /* getProfileData functions ends here */

  /* onChangeProfilePhoto functions starts here */
  onChangeProfilePhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      this.setState({ errorProfileValid: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorProfileValid: "" });
    }
    this.setState({
      profilePhoto: event.target.files[0],
      profilePhotoName: event.target.files[0]["name"].slice(-20),
    });
  };
/* onChangeProfilePhoto functions ends here */

/* handleProfileChange functions starts here */
  handleProfileChange(key) {
    this.setState({
      [key.target.name]: key.target.value,
    });
  }
/* handleProfileChange functions ends here */

/* hideEmailSection functions starts here */
  hideEmailSection = (e) => {
    e.preventDefault();
    this.setState({
      emailId: "", showEmail: false, emailIDOTP: false, emailButtonDisable: false
    })
  }
/* hideEmailSection functions ends here */

/* getEmailChange functions starts here */
  getEmailChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.emailId === "") {
      formErrors.emailId = "Email ID is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      return getEmailOTPAPI(userId, this.state.emailId, accessToken)
        .then((response) => {
          if (response.data["status"] === 200) {
            if (response.data["message"] === "Same email provided!") {
              NotificationManager.error(response.data["message"], "Error!");
            } else {
              this.setState({ emailIDOTP: true, emailOtp: "", emailButtonDisable: true});
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
              this.setState({
                minutes: 1,
                seconds: 0,
              });
              this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state;
                if (seconds > 0) {
                  this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                  }));
                }
                if (seconds === 0) {
                  if (minutes === 0) {
                    clearInterval(this.myInterval);
                  } else {
                    this.setState(({ minutes }) => ({
                      minutes: minutes - 1,
                      seconds: 59,
                    }));
                  }
                }
              }, 1000);
            }
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
        });
    } else {
    }
  };
  /* getEmailChange functions ends here */

  /* verfiyEmailOTP functions starts here */
  verfiyEmailOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getEmailOTPVerfiyAPI(
      userId,
      this.state.emailId,
      this.state.emailOtp,
      accessToken
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            response.data["message"],
            "Successful!",
            3000
          );
          localStorage.setItem("userEmail", this.state.emailId);
          this.setState({
            emailIDOTP: false, showEmail: false, userEmail: this.state.emailId, emailOtp: "", emailButtonDisable: false
          })
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error("Failed to update the Email ID", "Error!");
      });
  }
  /* verfiyEmailOTP functions ends here */

  /* resendEmailOTP functions starts here */
  resendEmailOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getEmailOTPAPI(userId, this.state.emailId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data["message"] === "Same email provided!") {
            NotificationManager.error(response.data["message"], "Error!");
          } else {
            this.setState({ emailIDOTP: true });
            this.setState({ emailOtp: "" });
            NotificationManager.success(
              response.data["message"],
              "Successful!",
              3000
            );
            this.setState({
              minutes: 1,
              seconds: 0,
            });
            this.myInterval = setInterval(() => {
              const { seconds, minutes } = this.state;
              if (seconds > 0) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              }
              if (seconds === 0) {
                if (minutes === 0) {
                  clearInterval(this.myInterval);
                } else {
                  this.setState(({ minutes }) => ({
                    minutes: minutes - 1,
                    seconds: 59,
                  }));
                }
              }
            }, 1000);
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
      });
  }
 /* resendEmailOTP functions ends here */

 /* getMobileChange functions starts here */
  getMobileChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.mobileNumber === "") {
      formErrors.mobileNumber = "Mobile Number is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      return getMobileOTPAPI(userId, this.state.mobileNumber, accessToken)
        .then((response) => {
          if (response.data["status"] === 200) {
            if (response.data["message"] === "Same number provided!") {
              NotificationManager.error(response.data["message"], "Error!");
            } else {
              this.setState({ mobileOTP: true, otp: "", mobileButtonDisable: true});
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
              this.setState({
                minutes: 1,
                seconds: 0,
              });
              this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state;
                if (seconds > 0) {
                  this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                  }));
                }
                if (seconds === 0) {
                  if (minutes === 0) {
                    clearInterval(this.myInterval);
                  } else {
                    this.setState(({ minutes }) => ({
                      minutes: minutes - 1,
                      seconds: 59,
                    }));
                  }
                }
              }, 1000);
            }
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          console.log(error);
          /* NotificationManager.error(error, "Error!"); */
        });
    } else {
    }
  };
  /* getMobileChange functions ends here */

  /* hideMobileSection functions starts here */
  hideMobileSection = (e) => {
    e.preventDefault();
    this.setState({
      mobileNumber: "", showMobile: false, mobileOTP: false, mobileButtonDisable: false
    })
  }
  /* hideMobileSection functions ends here */

  /* verfiyMobileOTP functions starts here */
  verfiyMobileOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getMobileOTPVerfiyAPI(
      userId,
      this.state.mobileNumber,
      this.state.otp,
      accessToken
    )
      .then((response) => {
        if (response.data["status"] === 200) {
          NotificationManager.success(
            response.data["message"],
            "Successful!",
            3000
          );
          localStorage.setItem("userMobile", this.state.mobileNumber);
          this.setState({
            mobileOTP: false, showMobile: false, userMobileNumber: this.state.mobileNumber, otp: "", mobileButtonDisable: false
          })
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Failed to update the Mobile Number",
          "Error!"
        );
      });
  }
  /* verfiyMobileOTP functions ends here */

  /* resendMobileOTP functions starts here */
  resendMobileOTP = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getMobileOTPAPI(userId, this.state.mobileNumber, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          if (response.data["message"] === "Same number provided!") {
            NotificationManager.error(response.data["message"], "Error!");
          } else {
            this.setState({ otpStatus: true });
            this.setState({ otp: "" });
            NotificationManager.success(
              response.data["message"],
              "Successful!",
              3000
            );
            this.setState({
              minutes: 1,
              seconds: 0,
            });
            this.myInterval = setInterval(() => {
              const { seconds, minutes } = this.state;
              if (seconds > 0) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              }
              if (seconds === 0) {
                if (minutes === 0) {
                  clearInterval(this.myInterval);
                } else {
                  this.setState(({ minutes }) => ({
                    minutes: minutes - 1,
                    seconds: 59,
                  }));
                }
              }
            }, 1000);
          }
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
      });
  }
/* resendMobileOTP functions ends here */

  /* handleChange functions starts here */
  handleChange = (otp) => {
    this.setState({ otp });
  };
  /* handleChange functions ends here */

  /* handleEmailChange functions starts here */
  handleEmailChange = (emailOtp) => {
    this.setState({ emailOtp });
  }
  /* handleEmailChange functions ends here */

  /* getPasswordChanged functions starts here */
  getPasswordChanged = (event) => {
    event.preventDefault();
    this.setState({ passwordStatus: true });
  };
  /* getPasswordChanged functions ends here */

  /* getDataSubmit functions starts here */ 
  getDataSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.firstname === "") {
      formErrors.firstname = "First Name is required";
    }

    if (this.state.lastname === "") {
      formErrors.lastname = "Last Name is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.recaptcha.execute();
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      var formdata = new FormData();

      formdata.append("user_id", userId);
      formdata.append("first_name", this.state.firstname);
      formdata.append("last_name", this.state.lastname);
      if (this.state.profilePhoto !== "") {
        formdata.append(
          "profile_image",
          this.state.profilePhoto,
          this.state.profilePhotoName
        );
      }

      axios
        .post(
          config.APIURL+"v1/save-user-profile-chef",
          formdata,
          {
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then((response) => {
          if (response.data["status"] === 200) {
            if (this.state.profilePhoto !== "") {
            var photo = response.data["data"]["profile_image"];
            localStorage.setItem("userImage", photo);
            }
            localStorage.setItem("userName", this.state.firstname + ' ' + this.state.lastname);
            let data = {
              userType: "Chef",
              userId: localStorage.getItem("userID"),
              userEmail: response.data["data"]["email"],
              userName:
                response.data["data"]["first_name"] +
                " " +
                response.data["data"]["last_name"],
              userMobile: response.data["data"]["mobile_number"],
              image: response.data["data"]["profile_image"],
              accessToken: localStorage.getItem("accessToken"),
            };
            this.props.saveUserData(data);
           // 
            if (localStorage.getItem("userVerified") == "1") {
              this.props.history.push("/chef-dashboard");
            } else {
              this.props.history.push("/user-details");
            }
            NotificationManager.success("Profile Updated", "Successful!", 3000);
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          NotificationManager.error("Failed to Updated Profile", "Error", 3000);
        });
    } else {
      this.recaptcha.reset();
    }
  };
  /* getDataSubmit functions ends here */ 

  /* hidePasswordSection functions starts here */ 
  hidePasswordSection = (event) => {
    event.preventDefault();
    this.setState({
      passwordStatus: false, oldPassword: "", newPassword: "", confirmPassword: ""
    })
  }
/* hidePasswordSection functions ends here */ 

  /* checkPassword functions starts here */ 
  checkPassword = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.oldPassword === "") {
      formErrors.oldPassword = "Old Password is required";
    }

    if (this.state.newPassword === "") {
      formErrors.newPassword = "New Password is required";
    }

    if (this.state.confirmPassword === "") {
      formErrors.confirmPassword = "Confirm Password is required";
    }

    if (this.state.newPassword !== this.state.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      return getChangePasswordAPI(userId, accessToken, this.state.oldPassword, this.state.newPassword, this.state.confirmPassword).then((response) => {
        if (response.data["status"] === 200) {
          localStorage.clear();
          this.props.history.push("/signin-with-password");
          window.location.reload();
          NotificationManager.success(
            "Profile Updated",
            "Successful!",
            3000
          );
        } else {
          NotificationManager.error(response.data["message"], "Error!");
        }
      }).catch((error) => {
      })
    } else {
    }

  }
/* checkPassword functions ends here */

/* getEditMobileNumber functions starts here */ 
  getEditMobileNumber = (event) => {
    event.preventDefault();
    this.setState({
      showMobile: true
    })
  }
/* getEditMobileNumber functions ends here */ 

/* getEditEmailID functions starts here */ 
  getEditEmailID = (event) => {
    event.preventDefault();
    this.setState({
      showEmail: true
    })
  }
/* getEditEmailID functions ends here */ 

/* onResolved functions starts here */ 
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved functions ends here */ 

  render() {
    const { formErrors } = this.state
    const { minutes, seconds } = this.state;
    return (
      <div>
        <Header />
        <section className="registration edit_profile">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12">
                <div className="abt_form heading">
                  <h4 className="d-flex justify-content-between align-items-center text-uppercase">
                    <button className="save_btn back_btn">
                      <img
                        src="/assets/images/icons/left-arrow.png"
                        alt="Left Arrow Icon"
                        className="img-fluid"
                      />
                      Back
                    </button>
                    EDIT PROFILE
                    <button className="save_btn">Save</button>
                  </h4>
                </div>
                <div className="abt_form">
                  <div className="inner">
                    <h5 className="color_C07 d-flex align-items-center">
                      <span className="icon user_icon user_icon">
                        <img
                          src="/assets/images/icons/user.png"
                          alt="User Icon"
                          className="img-fluid"
                        />
                      </span>
                      Profile
                    </h5>
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name*"
                                  name="firstname"
                                  value={this.state.firstname}
                                  onChange={this.handleNumberChange}
                                />
                                <small className="text-danger">{formErrors.firstname}</small>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name*"
                                  name="lastname"
                                  value={this.state.lastname}
                                  onChange={this.handleNumberChange}
                                />
                                <small className="text-danger">{formErrors.lastname}</small>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <div className="mobile_no_wrap">
                                  <div className="form-control countrycode">
                                    <span className="code">+91</span>
                                  </div>
                                  <input
                                    type="tel"
                                    className="form-control tel_number"
                                    placeholder="Mobile Number*"
                                    name="userMobileNumber"
                                    value={this.state.userMobileNumber}
                                    readOnly
                                  />
                                  <button
                                    className="mob_nub_change"
                                    type="submit"
                                    onClick={this.getEditMobileNumber}
                                  >
                                    Change
                                  </button>
                                </div>
                              </div>
                              {this.state.showMobile ? <div className="form-group">
                                <div className="mobile_no_wrap">
                                  <div className="form-control countrycode">
                                    <span className="code">+91</span>
                                  </div>
                                  <input
                                    type="tel"
                                    className="form-control tel_number"
                                    placeholder="Mobile Number*"
                                    name="mobileNumber"
                                    value={this.state.mobileNumber}
                                    onChange={this.handleNumberChange}
                                    maxLength="10"
                                  />
                                </div>
                                <small className="form-text text-muted">
                                  Please enter your new 10 digit mobile number.<br />
                                  An OTP verification will be sent to your new number.
                                </small>
                                <small className="text-danger">{formErrors.mobileNumber}</small>
                              </div> : null}
                              {this.state.showMobile ? <div className="form-group save_btn_next btn-group d-flex justify-content-start align-items-end mw-100">
                                <button className="btn previous_btn" onClick={this.hideMobileSection}>
                                  CANCEL
            </button>
                                <button
                                  type="submit"
                                  className="btn"
                                  onClick={this.getMobileChange}
                                  disabled={this.state.mobileButtonDisable}
                                >
                                  Verify with otp
            </button>
                              </div> : null}
                              {this.state.mobileOTP ?
                                <div className="form-group verification">
                                  <label className="otp_label">
                                    Enter the 6 digit OTP
                                  </label>
                                  <OtpInput
                                    containerStyle={"otp_input_wrap"}
                                    inputStyle={"form-control"}
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={6}
                                    shouldAutoFocus
                                    isInputNum={true}
                                    separator={false}
                                  />
                                </div> : null}
                              {this.state.mobileOTP ? <p className="resend_otp_text color_C07 mb-0">
                                OTP will expire in
                    <span className="color_036 ml-2">
                                  {minutes > 9 ? minutes : "0" + minutes}:
                      {seconds > 9 ? seconds : "0" + seconds}
                                </span>
                              </p> : null}
                              {this.state.mobileOTP ? <div className="form-group btn-group opt_btn_grp">
                                <button
                                  className="btn previous_btn"
                                  onClick={this.resendMobileOTP}
                                  disabled={minutes > 0 || seconds > 0}
                                >
                                  re-send otp
                  </button>
                                <button
                                  className="btn"
                                  disabled={this.state.otp.toString().length < 6}
                                  onClick={this.verfiyMobileOTP}
                                >
                                  Verify OTP
                  </button>
                              </div> : null}
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <div className="mobile_no_wrap">
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email ID*"
                                    value={this.state.userEmail}
                                    name="userEmail"
                                    readOnly
                                  />
                                  <button
                                    className="mob_nub_change"
                                    type="submit"
                                    onClick={this.getEditEmailID}
                                  >
                                    Change
                                  </button>
                                </div>
                              </div>
                              {this.state.showEmail ? <div className="form-group">
                                <div className="mobile_no_wrap">
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email ID*"
                                    value={this.state.emailId}
                                    name="emailId"
                                    onChange={this.handleNumberChange}
                                  />

                                </div>
                                <small className="form-text text-muted">
                                  Please enter your new Email ID.
                                  <br />An OTP verification will be sent to your new email.
                                </small>
                                <small className="text-danger">{formErrors.emailId}</small>
                              </div> : null}
                              {this.state.showEmail ? <div className="form-group save_btn_next btn-group d-flex justify-content-start align-items-end mw-100">
                                <button className="btn previous_btn" onClick={this.hideEmailSection}>
                                  CANCEL
            </button>
                                <button
                                  type="submit"
                                  className="btn"
                                  onClick={this.getEmailChange}
                                  disabled={this.state.emailButtonDisable}
                                >
                                  Verify with otp
            </button>
                              </div> : null}
                              {this.state.emailIDOTP ?
                                <div className="form-group verification">
                                  <label className="otp_label">
                                    Enter the 6 digit OTP
                                  </label>
                                  <OtpInput
                                    containerStyle={"otp_input_wrap"}
                                    inputStyle={"form-control"}
                                    value={this.state.emailOtp}
                                    onChange={this.handleEmailChange}
                                    numInputs={6}
                                    shouldAutoFocus
                                    isInputNum={true}
                                    separator={false}
                                  />
                                </div> : null}
                              {this.state.emailIDOTP ?
                                <p className="resend_otp_text color_C07 mb-0">
                                  OTP will expire in
                    <span className="color_036 ml-2">
                                    {minutes > 9 ? minutes : "0" + minutes}:
                      {seconds > 9 ? seconds : "0" + seconds}
                                  </span>
                                </p> : null}
                              {this.state.emailIDOTP ? <div className="form-group btn-group opt_btn_grp">
                                <button
                                  className="btn previous_btn"
                                  onClick={this.resendEmailOTP}
                                  disabled={minutes > 0 || seconds > 0}
                                >
                                  re-send otp
                  </button>
                                <button
                                  className="btn"
                                  disabled={this.state.emailOtp.toString().length < 6}
                                  onClick={this.verfiyEmailOTP}
                                >
                                  Verify OTP
                  </button>
                              </div> : null}

                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-6">
                              <div className="form-group">
                                <label className="d-block">
                                  Upload your profile picture
                                </label>
                                <div className="fileUpload d-flex">
                                  <button className="fileUploadBtn form-control d-inline-flex"></button>
                                  <div className="d-inline-flex align-items-center form-control">
                                    {this.state.profilePhotoName === "" ? (
                                      <span className="file_text">
                                        Choose file
                                        <small>No file selected</small>
                                      </span>
                                    ) : (
                                        <span className="file_text">
                                          {this.state.profilePhotoName.slice(-20)}
                                        </span>
                                      )}
                                    <input
                                      type="file"
                                      className="uploadlogo"
                                      name="profile_image"
                                      onChange={this.onChangeProfilePhoto}
                                    />
                                  </div>
                                </div>
                                <small className="form-text text-muted">
                                  One file only. 64 MB limit. Allowed types: png
                                  gif jpg jpeg.
                                </small>
                                {this.state.errorProfileValid
                                  ? <small className="form-text red_000">
                                    {this.state.errorProfileValid}

                                  </small> : null}
                              </div>
                              <div className="form-group">
                                <label className="d-flex justify-content-between">
                                  Password <span className="mob_nub_change"
                                    onClick={this.getPasswordChanged}> Change Password</span>
                                </label>
                                {this.state.passwordStatus ? <div className="mobile_no_wrap">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter Current Password*"
                                    name="oldPassword"
                                    value={this.state.oldPassword}
                                    onChange={this.handleNumberChange}
                                  />
                                  {formErrors.oldPassword ? <small className="text-danger">
                                    {formErrors.oldPassword}
                                  </small> : null}
                                </div> : null}
                              </div>
                              {this.state.passwordStatus === true ? (
                                <div className="form-group">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter New password*"
                                    name="newPassword"
                                    value={this.state.newPassword}
                                    onChange={this.handleNumberChange}
                                  />
                                  <small id="PasswordHelp" className="form-text text-muted">Minimum 6 characters.</small>
                                  {formErrors.newPassword ? <small className="text-danger">
                                    {formErrors.newPassword}
                                  </small> : null}
                                </div>
                              ) : null}
                              {this.state.passwordStatus === true ? (
                                <div className="form-group">
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Confirm New Password*"
                                    name="confirmPassword"
                                    value={this.state.confirmPassword}
                                    onChange={this.handleNumberChange}
                                  />
                                  <small id="PasswordHelp" className="form-text text-muted">Minimum 6 characters.</small>
                                  {formErrors.confirmPassword ? <small className="text-danger">
                                    {formErrors.confirmPassword}
                                  </small> : null}
                                  <small className="form-text red_000">
                                {this.state.passwordError !== ""
                                  ? this.state.passwordError
                                  : ""}
                              </small>
                                </div>
                              ) : null}
                              
                              {this.state.passwordStatus ? <div className="form-group save_btn_next btn-group d-flex justify-content-start align-items-end mw-100">
                                <button className="btn previous_btn" onClick={this.hidePasswordSection}>
                                  CANCEL
            </button>
                                <button
                                  type="submit"
                                  className="btn pro_save_btn" onClick={this.checkPassword}
                                >
                                  save
                        </button>
                              </div> : null}

                            </div>

                          </div>
                        </div>

                      </div>

                    </form>
                  </div>
                </div>
                <div className=" d-block text-right save_btn_next w-100 mb-0">
                  <button type="submit" className="btn" onClick={this.getDataSubmit}>
                    save and update
                  </button>
                </div>
                <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
              </div>
            </div>
          </div>
        </section>
        <div className="nofooter">
          <WhatsAppButton />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
});


export default connect(mapStateToProps, { saveUserData })(EditProfilePage);
