/*
#############################################################################
# Name : crowdFavourite.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the crowd favourite data on the basis of filter selection
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import LoadingDiv from "../../../../components/LoadingDiv";
import {
  crowdFavaoutireAPI,
  timeSlotAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
} from "../../../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import Slider from "react-slick";
import { connect } from "react-redux";
import {
  increaseQuantity,
  decreaseQuantity,
  emptyCart,
} from "../../../../redux/actions/cartActions";
import {
  addTimeSlot,
  removeTimeSlot,
} from "../../../../redux/actions/timeSlotActions";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import config from "../../../../config/config";

class CrowdFavourite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      removeChefBtnText: "Replace",
      data: localStorage.getItem("selectedCrowd"),
      images: localStorage.getItem("selectedCrowdImage"),
      chefArr: [],
      chefMenuArr: [],
      errorStatus: false,
      showPopup: false,
      showTimeSlot: false,
      chefMemberShip: "",
      timeSlot: [],
      timeSlotSelected: "",
      selectedDaySlot: "",
      selectedTimeSlot: "",
      isScheduled: false,
      selectedDish: "",
      selectedMenuId: "",
      showChangeChefAlert: false,
      chefOpeningTime: "",
      chefClosingTime: "",
      selectedChefId: "",
      selectedChefUserId: "",
      displayOopsPopup: false,
      displayUnavailablePopup: false,
      slugUrl: "",
      isChefAvailable: 1,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    };
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getCrowdFavouriteList(); // Crowd Favourites data is fetched
    /* if (
      localStorage.getItem("selectedLocationName") == "Bengaluru" ||
      localStorage.getItem("selectedLocationName") == "Bangalore" ||
      localStorage.getItem("selectedLocationName") == "Bangalore urban"
    ) {
      this.setState({ isChefAvailable: "0" });
    } */
  }

  /* getCrowdFavouriteList function starts here */
  getCrowdFavouriteList = () => {
    var city = localStorage.getItem("selectedLocationName");
    var pincode = localStorage.getItem("pincode");
    var latitude = localStorage.getItem("latitude");
    var longitude = localStorage.getItem("longitude");
    var id = this.props.match.params.slug;
    var userId = "";
    if (localStorage.getItem("userID")) {
      userId = localStorage.getItem("userID");
    }
    return crowdFavaoutireAPI(id, city, pincode, latitude, longitude, userId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            chefArr: response.data.data.crowd_favourite_list,
            chefMenuArr: response.data.data.chef_menu_list,
            isLoading: false,
          });
          setTimeout(() => {
            let array = this.state.chefMenuArr;
            let arr = [];
            array.forEach((menuItem) => {
              if (this.props.cartItems.length) {
                var included = false;
                var itemQty = "";
                this.props.cartItems.forEach((item) => {
                  if (menuItem.menu_id == item.menu_id) {
                    included = true;
                    itemQty = item.qty;
                  }
                });
                if (included == true) {
                  arr.push({ ...menuItem, qty: itemQty });
                } else {
                  arr.push({ ...menuItem, qty: "BOOK" });
                }
              } else {
                arr.push({ ...menuItem, qty: "BOOK" });
              }
            });
            array = arr;
            this.setState({
              chefMenuArr: array,
            });
          }, 100);
          if (
            response.data.data.crowd_favourite_list.length === 0 &&
            response.data.data.chef_menu_list.length === 0
          ) {
            this.setState({
              errorStatus: true,
            });
          } else {
            this.setState({
              errorStatus: false,
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        /*  console.log(error); */
      });
  };
  /* getCrowdFavouriteList function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getCrowdFavouriteList();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getCrowdFavouriteList();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push(
      "/" + selectedCity + "/chef-inner-homepage/" + slugUrl
    );
    // var urlParam = config.slugify(kitchenName);
    // if (brandName.length > 0) {
    //  urlParam =  config.slugify(brandName);
    // }
    // if (chefAvailable === "1") {
    /* console.log("2", slugUrl)
    this.props.history.push("/chef-inner-homepage/" + slugUrl); */
    // }
  };
  /* getInerPage function ends here */

  /* handleRadioChange function starts here */
  handleRadioChange(event) {
    this.setState({
      timeSlotSelected: event.target.value,
    });
    localStorage.setItem("timeSlotSelected", event.target.value);
  }
  /* handleRadioChange function ends here */

  /* getTimeSlots function starts here */
  getTimeSlots(startTime, endTime) {
    return timeSlotAPI(startTime, endTime).then((response) => {
      if (response.status === 200) {
        let timeArray = [];
        for (var key in response.data.data) {
          timeArray.push({ ...response.data.data[key], selected: false });
        }
        let tempArr = this.state.chefMenuArr;
        tempArr.forEach((item) => {
          item.time_slot.forEach((time) => {
            time["date_time_slot"] = timeArray;
          });
        });
        this.setState({
          chefMenuArr: tempArr,
        });
      }
    });
  }
  /* getTimeSlots function ends here */

  /* randomString function starts here */
  randomString = () => {
    let length = 32;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  /* randomString function ends here */

  /* addCartItem function starts here */
  addCartItem = (
    itemMenu,
    quantity,
    menuId,
    startTime,
    endTime,
    chefId,
    chefUserId,
    membership_assignment,
    is_chef_available,
    slugUrl
  ) => {
    this.setState({
      slugUrl: slugUrl,
    });
    if (membership_assignment === "basic") {
      this.setState({
        displayOopsPopup: true,
      });
    } else {
      if (
        is_chef_available == "" ||
        is_chef_available == "1" ||
        is_chef_available == 1
      ) {
        if (this.props.cartItems.length) {
          if (chefId !== this.props.cartItems[0].chef_id) {
            this.setState({
              selectedDish: itemMenu,
              selectedMenuId: menuId,
              showChangeChefAlert: true,
              chefOpeningTime: startTime,
              chefClosingTime: endTime,
              selectedChefId: chefId,
              selectedChefUserId: chefUserId,
            });
          } else {
            let arrCategoryMenu = this.state.chefMenuArr;
            if (quantity == "BOOK" && !this.state.displayedTimeSlot) {
              arrCategoryMenu[itemMenu].qty = 1;
              this.setState({
                chefMenuArr: arrCategoryMenu,
              });
            } else {
              if (quantity == "BOOK") {
                arrCategoryMenu[itemMenu].qty = 1;
                NotificationManager.success(
                  arrCategoryMenu[itemMenu].item_name + " is added to cart",
                  "Success"
                );
              } else {
                arrCategoryMenu[itemMenu].qty++;
              }
              this.setState({
                chefMenuArr: arrCategoryMenu,
              });
            }
            setTimeout(() => {
              let menuItem = {
                chef_id: this.state.selectedChefId,
                menu_id: arrCategoryMenu[itemMenu].menu_id,
                category_name: arrCategoryMenu[itemMenu].category_name,
                item_name: arrCategoryMenu[itemMenu].item_name,
                diet_type: arrCategoryMenu[itemMenu].diet_type,
                menu_image: arrCategoryMenu[itemMenu].menu_image,
                actual_price: arrCategoryMenu[itemMenu].actual_price,
                item_price: arrCategoryMenu[itemMenu].item_price,
                qty: arrCategoryMenu[itemMenu].qty,
              };
              const cartItems = this.props.cartItems;

              this.props.increaseQuantity(cartItems, menuItem);
            }, 200);
          }
        } else {
          this.setState({
            selectedMenuId: menuId,
            selectedChefId: chefId,
          });
          if (!this.state.isScheduled) {
            this.getTimeSlots(startTime, endTime);
            this.setState({
              selectedDish: itemMenu,
              showTimeSlot: true,
            });
          } else {
            let arrCategoryMenu = this.state.chefMenuArr;
            if (quantity == "BOOK" && !this.state.displayedTimeSlot) {
              arrCategoryMenu[itemMenu].qty = 1;
              this.setState({
                chefMenuArr: arrCategoryMenu,
              });
            } else {
              if (quantity == "BOOK") {
                arrCategoryMenu[itemMenu].qty = 1;
              } else {
                arrCategoryMenu[itemMenu].qty++;
              }
              this.setState({
                chefMenuArr: arrCategoryMenu,
              });
            }
            setTimeout(() => {
              let menuItem = {
                chef_id: this.state.selectedChefId,
                menu_id: arrCategoryMenu[itemMenu].menu_id,
                category_name: arrCategoryMenu[itemMenu].category_name,
                item_name: arrCategoryMenu[itemMenu].item_name,
                diet_type: arrCategoryMenu[itemMenu].diet_type,
                menu_image: arrCategoryMenu[itemMenu].menu_image,
                actual_price: arrCategoryMenu[itemMenu].actual_price,
                item_price: arrCategoryMenu[itemMenu].item_price,
                qty: arrCategoryMenu[itemMenu].qty,
              };
              const cartItems = this.props.cartItems;

              let randomId = this.randomString();
              localStorage.setItem("cartRandomStringId", randomId);

              this.props.increaseQuantity(cartItems, menuItem);
            }, 200);
          }
        }
      } else {
        this.setState({
          displayUnavailablePopup: true,
        });
      }
    }
  };
  /* addCartItem function ends here */

  /* removeCartItem function starts here */
  removeCartItem = (itemMenu, quantity) => {
    let arrCategoryMenu = this.state.chefMenuArr;
    let menuItem = arrCategoryMenu[itemMenu];
    if (quantity > 1) {
      arrCategoryMenu[itemMenu].qty--;
    } else {
      arrCategoryMenu[itemMenu].qty = "BOOK";
      NotificationManager.success(
        arrCategoryMenu[itemMenu].item_name + " is removed from cart",
        "Success"
      );
    }

    const cartItems = this.props.cartItems;

    this.setState({
      chefMenuArr: arrCategoryMenu,
      showCartPopup: false,
      displayTimePopup: false,
    });

    this.props.decreaseQuantity(cartItems, menuItem);
    setTimeout(() => {
      if (!this.props.cartItems.length) {
        localStorage.removeItem("cartRandomStringId");
      }
    }, 100);
  };
  /* removeCartItem function ends here */

  /* saveTimeData function starts here */
  saveTimeData = (event) => {
    event.preventDefault();
    this.setState({
      showTimeSlot: false,
    });
    console.log("3", this.state.slugUrl);
    /* this.props.history.push("/chef-inner-homepage/" + this.state.slugUrl); */
    var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push(
      "/" + selectedCity + "/chef-inner-homepage/" + this.state.slugUrl
    );
  };
  /* saveTimeData function ends here */

  /* closeTimePopup function starts here */
  closeTimePopup = (event) => {
    event.preventDefault();
    this.setState({
      showTimeSlot: false,
    });
  };
  /* closeTimePopup function ends here */

  /* handleTimeRadioChange function starts here */
  handleTimeRadioChange = (e, m, t, ti) => {
    let array = this.state.chefMenuArr;
    array[m].time_slot[t].date_time_slot[ti].selected = true;

    array[m].time_slot.map((slot, s) => {
      if (t == s) {
        this.setState({ selectedDaySlot: slot.date });
        slot.date_time_slot.map((item, i) => {
          if (i == ti) {
            item.selected = true;
            this.setState({
              selectedTimeSlot: item.start + "-" + item.end,
            });
          } else {
            item.selected = false;
          }
        });
      }
    });
    this.setState({
      chefMenuArr: array,
    });
  };
  /* handleTimeRadioChange function ends here */

  /* handleScheduleClick function starts here */
  handleScheduleClick = () => {
    this.props.emptyCart();
    localStorage.setItem("chefID", this.state.selectedChefId);
    this.props.addTimeSlot(
      this.state.selectedDaySlot,
      this.state.selectedTimeSlot
    );
    let arrCategoryMenu = this.state.chefMenuArr;
    let itemMenu = this.state.selectedDish;
    arrCategoryMenu[itemMenu].qty = 1;
    var chefName = arrCategoryMenu[itemMenu].name;
    this.setState({
      chefMenuArr: arrCategoryMenu,
    });
    setTimeout(() => {
      let menuItem = {
        chef_id: this.state.selectedChefId,
        menu_id: arrCategoryMenu[itemMenu].menu_id,
        category_name: arrCategoryMenu[itemMenu].category_name,
        item_name: arrCategoryMenu[itemMenu].item_name,
        diet_type: arrCategoryMenu[itemMenu].diet_type,
        menu_image: arrCategoryMenu[itemMenu].menu_image,
        actual_price: arrCategoryMenu[itemMenu].actual_price,
        item_price: arrCategoryMenu[itemMenu].item_price,
        qty: arrCategoryMenu[itemMenu].qty,
      };
      const cartItems = this.props.cartItems;

      if (!cartItems.length) {
        let randomId = this.randomString();
        localStorage.setItem("cartRandomStringId", randomId);
      }

      this.props.increaseQuantity(cartItems, menuItem);

      this.setState({
        showTimeSlot: false,
      });
      console.log("4", this.state.slugUrl);
      /* this.props.history.push(
        "/chef-inner-homepage/" + this.state.slugUrl
      ); */
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.props.history.push(
        "/" + selectedCity + "/chef-inner-homepage/" + this.state.slugUrl
      );
    }, 200);
  };
  /* handleScheduleClick function ends here */

  /* handleClearChef function starts here */
  handleClearChef = () => {
    this.getTimeSlots(this.state.chefOpeningTime, this.state.chefClosingTime);
    document.getElementById("closeMenuFormButton2").click();
    this.setState({
      showTimeSlot: true,
    });
  };
  /* handleClearChef function ends here */

  /* handleBrandnameClick function starts here */
  handleBrandnameClick = (chefId, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefId);
    var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push(
      "/" + selectedCity + "/chef-inner-homepage/" + slugUrl
    );
    // var urlParam = config.slugify(kitchenName);
    // if (brandName.length > 0) {
    //   urlParam = config.slugify(brandName);
    // }
    // if (chefAvailable === "1") {
    /* console.log("1", slugUrl) */
    /* this.props.history.push("/chef-inner-homepage/" + slugUrl); */
    // }
  };
  /* handleBrandnameClick function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    var ind = 0;
    return (
      <div className="css2">
        <DinnerHeader />
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <section className="selected_you home_bakers see_space chef_inner crowd_tabs">
            <div className="shoe_all_bg text-white mb-3 mb-sm-5">
              <div className="right_wrap container">
                <h4 className="mb-0">{this.state.data}</h4>
                <div className="img">
                  <img
                    src={this.state.images}
                    alt="Paneer Biryani"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {this.state.chefArr.length
                  ? this.state.chefArr.map((vip, i) => {
                      return (
                        <div
                          className="col-sm-6 col-md-6 col-lg-4 col-xl-3"
                          key={i}
                        >
                          <div
                            className={
                              vip.is_chef_available === "1"
                                ? "card"
                                : "card unavailable-chef-card"
                            }
                          >
                            <div
                              className="direction"
                              onClick={() => {
                                localStorage.getItem("userID") !== null
                                  ? vip.is_bookmarked == 1
                                    ? this.removeBookmark(vip.chef_id)
                                    : this.addBookmark(vip.chef_id)
                                  : this.storageHandler();
                              }}
                            >
                              <img
                                src={
                                  vip.is_bookmarked == 1
                                    ? "/assets/images/icons/bookmark.svg"
                                    : "/assets/images/icons/bookmark-empty.svg"
                                }
                                alt=""
                              />
                            </div>
                            <div
                              onClick={() =>
                                this.getInerPage(
                                  vip.chef_id,
                                  vip.slug_url,
                                  vip.name,
                                  vip.is_chef_available
                                )
                              }
                            >
                              {vip.promoted === "1" ? (
                                <div className="PromoShape">
                                  <a href="#">Promoted</a>
                                </div>
                              ) : null}
                              <div className="img_wrap">
                                {vip.is_offer_available === "1" ? (
                                  <div className="offer">OFFER</div>
                                ) : null}
                                {vip.profile_image != null ? (
                                  <img
                                    src={vip.profile_image}
                                    alt=" Selected for You "
                                    className=" img-fluid "
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/noimage.png"
                                    alt=" Selected for You "
                                    className=" img-fluid "
                                  />
                                )}
                              </div>
                              <div className="text_wrap">
                                <h6>
                                  {vip.verified === "1" ? (
                                    <span className="correct_icn">
                                      {vip.brand_name !== ""
                                        ? vip.brand_name
                                        : vip.name}
                                    </span>
                                  ) : (
                                    <span>
                                      {vip.brand_name !== ""
                                        ? vip.brand_name
                                        : vip.name}
                                    </span>
                                  )}{" "}
                                  {vip.total_ratings !== 0 ? (
                                    <span className="star">
                                      {vip.total_ratings}
                                      <span className="grey">/ 5</span>
                                    </span>
                                  ) : null}
                                </h6>
                                {vip.cuisines === "" ? (
                                  "-"
                                ) : (
                                  <p className="sub_title">{vip.cuisines}</p>
                                )}
                                <ul>
                                  <li className="location_icn">
                                    {vip.locality == null &&
                                    vip.locality === "" ? null : (
                                      <span>{vip.locality}, </span>
                                    )}{" "}
                                    {vip.distance == null
                                      ? ""
                                      : parseFloat(vip.distance).toFixed(
                                          1
                                        )}{" "}
                                    kms
                                  </li>
                                  {vip.is_takeaway_available == 1 ? (
                                    <li>Available for Delivery & Takeaway</li>
                                  ) : (
                                    <li>Available for Delivery</li>
                                  )}
                                </ul>
                                <div
                                  className={
                                    vip.gamification_data == null
                                      ? "badge_slide border-0"
                                      : "badge_slide"
                                  }
                                >
                                  <Slider
                                    className="inner_slider"
                                    {...this.state.settings}
                                  >
                                    {vip.gamification_data != null ? (
                                      vip.gamification_data.map((item, i) => {
                                        return (
                                          <div
                                            className="box img-slider1"
                                            key={"grkw" + i}
                                          >
                                            <img
                                              src={item.image}
                                              alt={item.title}
                                            />
                                            <span className="text">
                                              {item.desc}
                                            </span>
                                          </div>
                                        );
                                      })
                                    ) : (
                                      <div className="box">
                                        <span className="text">
                                          &nbsp;&nbsp;
                                        </span>
                                      </div>
                                    )}
                                  </Slider>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {this.state.chefMenuArr.length
                  ? this.state.chefMenuArr.map((menu, m) => {
                      return (
                        <div className="col-lg-11 col-xl-9" key={m}>
                          <div
                            className={
                              m === 0
                                ? "page_section crowd hero pt-0"
                                : "page_section crowd hero"
                            }
                          >
                            <div className="wrapper pt-0">
                              <div className="wrap">
                                <div
                                  className={
                                    menu.is_chef_available === "1" &&
                                    menu.enabled === "1" &&
                                    menu.is_available_now === "1" &&
                                    this.state.isChefAvailable == "1"
                                      ? "img_wrap collection_wrapper"
                                      : "img_wrap not_avail_active collection_wrapper"
                                  }
                                >
                                  {menu.menu_image === null ? (
                                    <img
                                      src="/assets/images/noimage.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={menu.menu_image}
                                      alt="Paneer Biryani"
                                    />
                                  )}

                                  {menu.diet_type === "Non Veg" ? (
                                    <div className="icon_logo">
                                      <img
                                        src="/assets/images/icons/non-veg.svg"
                                        alt=""
                                      />
                                    </div>
                                  ) : null}
                                  {menu.diet_type === "Veg" ? (
                                    <div className="icon_logo">
                                      <img
                                        src="/assets/images/icons/veg-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  ) : null}
                                  {menu.diet_type === "Contains Egg" ? (
                                    <div className="icon_logo">
                                      <img
                                        src="/assets/images/icons/egg-icon.svg"
                                        alt=""
                                      />
                                    </div>
                                  ) : null}
                                  {/* {localStorage.getItem("userID") !== null ? (
                                    <div
                                      className="direction"
                                      onClick={() =>
                                        menu.is_bookmark == 1
                                          ? this.removeBookmark(menu.chef_id)
                                          : this.addBookmark(menu.chef_id)
                                      }
                                    >
                                      <img
                                        src={
                                          menu.is_bookmark == 1
                                            ? "/assets/images/icons/bookmark.svg"
                                            : "/assets/images/icons/bookmark-empty.svg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  ) : null} */}
                                  {(menu.is_chef_available === "0" ||
                                    menu.enabled === "0" ||
                                    menu.is_available_now === "0" ||
                                    this.state.isChefAvailable == "0") && (
                                    <div className="not_available">
                                      Not Available
                                    </div>
                                  )}
                                </div>
                                <div className="text">
                                  <h6>
                                    {menu.item_name}
                                    <div
                                      className={
                                        menu.qty !== "BOOK"
                                          ? "button_add color_fill"
                                          : "button_add"
                                      }
                                    >
                                      {menu.is_chef_available === "1" &&
                                        menu.enabled === "1" &&
                                        menu.is_available_now === "1" &&
                                        this.state.isChefAvailable !== "0" && (
                                          <form
                                            id="myform"
                                            method="POST"
                                            action="#"
                                          >
                                            <input
                                              type="button"
                                              value="-"
                                              className={
                                                this.state.isChefAvailable ===
                                                  "0" || menu.enabled === "0"
                                                  ? "disabled-add-cart-btn qtyminus"
                                                  : "qtyminus"
                                              }
                                              field="quantity"
                                              onClick={this.removeCartItem.bind(
                                                this,
                                                m,
                                                menu.qty
                                              )}
                                              disabled={menu.qty == "BOOK"}
                                            />
                                            <input
                                              type="text"
                                              name="quantity"
                                              defaultValue={menu.qty}
                                              className={
                                                menu.qty !== "BOOK"
                                                  ? "qty "
                                                  : "qty cursor-qty"
                                              }
                                              disabled={menu.qty !== "BOOK"}
                                              onClick={() =>
                                                this.addCartItem(
                                                  m,
                                                  menu.qty,
                                                  menu.menu_id,
                                                  menu.opening_time,
                                                  menu.closing_time,
                                                  menu.chef_id,
                                                  menu.chef_user_id,
                                                  menu.membership_assignment,
                                                  menu.is_chef_available,
                                                  menu.slug_url
                                                )
                                              }
                                              readonly
                                            />
                                            <input
                                              type="button"
                                              value="+"
                                              className={
                                                this.state.isChefAvailable ===
                                                  "0" || menu.enabled === "0"
                                                  ? "disabled-add-cart-btn qtyplus"
                                                  : "qtyplus"
                                              }
                                              field="quantity"
                                              onClick={this.addCartItem.bind(
                                                this,
                                                m,
                                                menu.qty,
                                                menu.menu_id,
                                                menu.opening_time,
                                                menu.closing_time,
                                                menu.chef_id,
                                                menu.chef_user_id,
                                                menu.membership_assignment,
                                                menu.is_chef_available,
                                                menu.slug_url
                                              )}
                                            />
                                          </form>
                                        )}
                                    </div>
                                  </h6>
                                  <ul>
                                    <li>
										INR{" "}{menu.discount_flag == true ? 
										( 
											<span>
												<del class="strikeprice">
													{ menu.actual_price }
												</del>{" "}
												{menu.item_price} {" "}				
												<span class="strikepricediscount">{"("}{ menu.discount }{"% Off)"}</span>
											</span>
										   )  : (
											menu.item_price
										)}
									</li>
                                    {menu.unit === "portion" ? (
                                      <li>
                                        {"Serves " +
                                          menu.standard_quantity_or_portion_size}
                                      </li>
                                    ) : (
                                      <li>
                                        {menu.standard_quantity_or_portion_size}{" "}
                                        {menu.unit}
                                      </li>
                                    )}
                                  </ul>
                                  <div className="border_crowd"></div>
                                  <div className="text_wrap">
                                    <h6>
                                      {" "}
                                      <a
                                        onClick={() =>
                                          this.handleBrandnameClick(
                                            menu.chef_id,
                                            menu.slug_url,
                                            menu.name,
                                            menu.is_chef_available
                                          )
                                        }
                                        className={
                                          menu.is_chef_available === "1" &&
                                          menu.enabled === "1" &&
                                          menu.is_available_now === "1"
                                            ? "correct_icn"
                                            : "correct_icn disabled-brand-name"
                                        }
                                      >
                                        {menu.brand_name !== ""
                                          ? menu.brand_name
                                          : menu.name}
                                      </a>{" "}
                                      {menu.total_ratings !== 0 ? (
                                        <span className="star">
                                          {menu.total_ratings}{" "}
                                          <span className="grey">/ 5</span>
                                        </span>
                                      ) : null}
                                    </h6>
                                    <ul>
                                      <li className="location_icn">
                                        {menu.locality == null &&
                                        menu.locality === "" ? null : (
                                          <span>{menu.locality}, </span>
                                        )}{" "}
                                        {menu.distance == null
                                          ? ""
                                          : parseFloat(menu.distance).toFixed(
                                              1
                                            )}{" "}
                                        kms
                                      </li>
                                      {menu.is_takeaway_available == 1 ? (
                                        <li>
                                          Available for Delivery & Takeaway
                                        </li>
                                      ) : (
                                        <li>Available for Delivery</li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <p className="para">
                                {menu.item_description}
                                <span className="more">More</span>
                              </p>
                              {menu.menu_uses_material.length ? (
                                <ul className="bottom_list">
                                  {menu.menu_uses_material.map(
                                    (material, mat) => {
                                      return (
                                        <li key={mat}>
                                          {material.material_name}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              {this.state.errorStatus ? (
                <div className="not_found">
                  <div className="container text-center">
                    <img
                      src="/assets/images/icons/not-found.svg"
                      alt="Not Found"
                      className="img-fluid"
                    />
                    <h6>SORRY! NO RESULTS FOUND.</h6>
                    <p>
                      We can’t find anything related to your <br /> search. Try
                      a different search.
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </section>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
        {this.state.showTimeSlot ? (
          <div
            className={
              this.state.showTimeSlot
                ? "modal bottom_pop fade map_pop modal2 time_slot show d-block"
                : "modal bottom_pop fade map_pop modal2 time_slot"
            }
            id="Time_Modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="Time_Modal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="more-details">
                    <div className="form_wrap edit-delivery-address">
                      <div className="address-header">
                        <div className="top_wrap">
                          <h5 className="text-uppercase color_a05">
                            WHEN WOULD YOU LIKE THIS ORDER TO BE READY?
                            <button
                              type="button"
                              className="close_icon"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={this.closeTimePopup}
                            >
                              <img
                                src="/assets/images/icons/black-close.svg"
                                alt="Close"
                              />
                            </button>
                          </h5>
                          <p>
                            This is the time slot when your order will be picked
                            up for delivery or take-away.
                          </p>
                        </div>
                        <div id="accordion" className="mt-0">
                          {this.state.chefMenuArr.map((menu, m) => {
                            return (
                              <div>
                                {this.state.selectedMenuId == menu.menu_id
                                  ? menu.time_slot.map((timeSlot, t) => {
                                      return (
                                        <div className="card" key={t}>
                                          <div
                                            className="slot_title"
                                            id={"heading" + t}
                                          >
                                            <h6>{timeSlot.date}</h6>

                                            {timeSlot.is_available == true ? (
                                              <p
                                                data-toggle="collapse"
                                                data-target={"#collapse" + t}
                                                aria-expanded={
                                                  t == 0 ? "true" : "false"
                                                }
                                                aria-controls={"#collapse" + t}
                                              >
                                                Available slots
                                              </p>
                                            ) : (
                                              <p>
                                                This dish is not available on
                                                this date.
                                              </p>
                                            )}
                                          </div>
                                          <div
                                            id={"collapse" + t}
                                            className={
                                              t == 0
                                                ? "collapse show"
                                                : "collapse"
                                            }
                                            aria-labelledby={"heading" + t}
                                            aria-expanded="false"
                                            data-parent="#accordion"
                                          >
                                            <div className="card-body">
                                              {timeSlot.is_available == true ? (
                                                <div className="radio-box">
                                                  <ul className="radio_list d-block">
                                                    {timeSlot.date_time_slot
                                                      ? timeSlot.date_time_slot.map(
                                                          (time, ti) => {
                                                            return (
                                                              <li key={ti}>
                                                                <div
                                                                  className={
                                                                    ti == 0
                                                                      ? "custome_check my-2"
                                                                      : "custome_check"
                                                                  }
                                                                >
                                                                  <label
                                                                    for={
                                                                      timeSlot.date.replace(
                                                                        /\s+/g,
                                                                        ""
                                                                      ) + ti
                                                                    }
                                                                  >
                                                                    {time.start}{" "}
                                                                    - {time.end}
                                                                    <input
                                                                      type="radio"
                                                                      id={
                                                                        timeSlot.date.replace(
                                                                          /\s+/g,
                                                                          ""
                                                                        ) + ti
                                                                      }
                                                                      value={
                                                                        time.start +
                                                                        "-" +
                                                                        time.end
                                                                      }
                                                                      name={timeSlot.date.replace(
                                                                        /\s+/g,
                                                                        ""
                                                                      )}
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        this.handleTimeRadioChange(
                                                                          e,
                                                                          m,
                                                                          t,
                                                                          ti
                                                                        )
                                                                      }
                                                                      checked={
                                                                        time.selected &&
                                                                        this
                                                                          .state
                                                                          .selectedDaySlot ==
                                                                          timeSlot.date
                                                                      }
                                                                    />
                                                                    <span className="radiobtn"></span>
                                                                  </label>
                                                                </div>
                                                              </li>
                                                            );
                                                          }
                                                        )
                                                      : null}
                                                  </ul>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="form-group save-btn mb-0">
                        <button
                          type="submit"
                          className="btn"
                          onClick={this.handleScheduleClick}
                        >
                          schedule
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showTimeSlot ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}

        {this.state.showChangeChefAlert ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          showChangeChefAlert: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <h6>Replace Cart Items?</h6>
                    <p>
                      Your cart already contains dishes from a Home Chef.
                      Proceeding further will discard the previously added
                      items.
                    </p>
                    <div className="btn-group">
                      <div>
                        <button
                          id="closeMenuFormButton2"
                          className="btn previous_btn"
                          onClick={() =>
                            this.setState({
                              showChangeChefAlert: false,
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button className="btn" onClick={this.handleClearChef}>
                          {this.state.removeChefBtnText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.displayOopsPopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          displayOopsPopup: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <p className="mb-0">
                      Oops! This home chef has not enabled online orders, but we
                      will let them know that diners are interested in ordering
                      from them.
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.displayUnavailablePopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          displayUnavailablePopup: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <p className="mb-0">
                      Oops! This home chef is not taking orders at this time.
                      Please select another home chef.
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.showChangeChefAlert ||
        this.state.displayOopsPopup ||
        this.state.displayUnavailablePopup ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
});

export default connect(mapStateToProps, {
  increaseQuantity,
  decreaseQuantity,
  emptyCart,
  addTimeSlot,
  removeTimeSlot,
})(CrowdFavourite);
