/*
#############################################################################
# Name : forgotPassword.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to verify the emailid for the forgot password flow
#############################################################################
*/
import React from "react";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import ReactFormInputValidation from "react-form-input-validation";
import { getForgotPaswordAPI } from "../../core/services/APIservice";
import { Link } from "react-router-dom";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: "",
      },
      errors: {},
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({ email: "required|email" });

    /* onformsubmit function starts here */
    this.form.onformsubmit = (fields) => {
      this.recaptcha.execute();
      return getForgotPaswordAPI(fields.email)
        .then((response) => {
          localStorage.removeItem("resetPassword");
          if (response.data.status === 200) {
            NotificationManager.success(
              "New Password send to your EmailId",
              "Successful!",
              3000
            );
            localStorage.removeItem("forgot-password-email");
            let fields = this.state.fields;
            fields.email = "";
            this.setState({ fields });
          } else {
            NotificationManager.error(response.data.message, "Error!");
          }
        })
        .catch((err) => {
          this.recaptcha.reset();
        });
    };
     /* onformsubmit function ends here */
  }

  componentDidMount(){
    if(localStorage.getItem('forgot-password-email')){
      this.setState({
        fields: {
          email: localStorage.getItem('forgot-password-email')
        },
      })
    }
  }

   /* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
   /* onResolved function ends here */

  render() {
    return (
      <React.Fragment>
        <div>
          <Header />
          <section className="sign_up_chef verification log_otp">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                  <div className="text pt-0">
                    <h3 className="color_C07">PASSWORD ASSISTANCE</h3>
                    <div className="profile_img">
                      <div className="inner">
                        <img
                          src="assets/images/icons/password.png"
                          alt="Password Image"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="form_wrap">
                      <form onSubmit={this.form.handleSubmit}>
                        <div className="form-group">
                          {localStorage.getItem('forgot-password-email')?<input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email ID*"
                            name="email"
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.email}
                            readOnly
                          />:<input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email ID*"
                          name="email"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.email}
                        />}
                          <small
                            id="PasswordHelp"
                            className="form-text text-muted"
                          >
                            Password reset instructions will be sent to your
                            registered email address.
                          </small>
                          <small className="form-text red_000">
                            {this.state.errors.email
                              ? this.state.errors.email
                              : ""}
                          </small>
                          {localStorage.getItem("resetPassword") === null ||
                          localStorage.getItem("resetPassword") === false ? (
                            <Link
                              to={"/signin-with-password"}
                              className="form-text forgot_password text-right text-muted"
                            >
                              Login with password
                            </Link>
                          ) : null}
                        </div>
                        <div className="form-group mb-0">
                          <button type="submit" className="btn">
                            SUBMIT
                          </button>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-7 align-items-center d-none d-md-flex">
                  <div className="img_box">
                    <img
                      src="assets/images/forgot-password/forgot-password.png"
                      alt="sign up img"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="nofooter">
            <WhatsAppButton />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPasswordPage;
