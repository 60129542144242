import React, { Component } from "react";
import AccountSidebar from "./AccountSidebar";
import { connect } from "react-redux";
import {
  getBookMarkAPI,
  getBookmarkListAPI,
  deleteBookMarkAPI,
} from "../../../../core/services/Phase2APIservice";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import LoadingDiv from "../../../../components/LoadingDiv";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import { removeMobileView } from "../../../../redux/actions/chefMenuActions";
import config from "../../../../config/config";
import PromotedBadge from "../../../../components/PromotedBadge"
class DinerBookmark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      bookMarkArr: [],
      selectedCity: "",
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }

  componentDidMount() {
    this.getBookmarkList();
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: selectedCity,
      });
    }
  }

  getBookmarkList = () => {
    return getBookmarkListAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response);
          this.setState({
            bookMarkArr: response.data.data,
            isLoading: false,
          });
        } else {
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.history.push("diner-signin-with-otp");
            localStorage.clear();
          } else {
            NotificationManager.error(response.data.message, "Error!");
          }
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
  };
  /* getInerPage function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getBookmarkList();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getBookmarkList();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  render() {
    if (localStorage.getItem("accessToken") == null) {
      return <Redirect to="/" />;
    }
    const { isLoading, bookMarkArr } = this.state;
    const { mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : !bookMarkArr.length ? (
                    <div class="outer">
                      <h6 class="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="/assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Bookmarks
                      </h6>
                      <div class="inner">
                        <div class="not_found empty_section py-0">
                          <div class="text-center">
                            <img
                              src="/assets/images/icons/no-bookmarks-yet.svg"
                              alt="Not Found"
                              class="img-fluid"
                            />
                            <h6 class="justify-content-center">
                              NO BOOKMARKS YET
                            </h6>
                            <p>
                              Use bookmarks to select your favourite
                              <br /> home chefs and find them here
                            </p>
                            <Link
                              to={
                                "/" +
                                this.state.selectedCity +
                                "/diner-homepage"
                              }
                              className="btn"
                            >
                              Go back to homepage
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="/assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Bookmarks
                      </h6>
                      <div className="inner selected_you ">
                        <div className="row">
                          {bookMarkArr.length
                            ? bookMarkArr.map((bookmark, b) => {
                                return (
                                  <div
                                    className="col-md-6 justify-content-lg-end pl-xl-5"
                                    key={b}
                                  >
                                    <div
                                      className={
                                        bookmark.is_chef_available === "1"
                                          ? "card"
                                          : "card unavailable-chef-card"
                                      }
                                    >
                                      <div
                                        className="direction"
                                        onClick={() =>
                                          bookmark.is_bookmarked == 1
                                            ? this.removeBookmark(
                                                bookmark.chef_id
                                              )
                                            : this.addBookmark(bookmark.chef_id)
                                        }
                                      >
                                        <img
                                          src={
                                            bookmark.is_bookmarked == 1
                                              ? "/assets/images/icons/bookmark.svg"
                                              : "/assets/images/icons/bookmark-empty.svg"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        onClick={() =>
                                          this.getInerPage(
                                            bookmark.chef_id,
                                            bookmark.slug_url,
                                            bookmark.name,
                                            bookmark.is_chef_available
                                          )
                                        }
                                      >
                                        {bookmark.promoted == 1 ? (
                                          <PromotedBadge/>
                                        ) : null}
                                        <div className="img_wrap">
                                          {bookmark.is_offer_available ===
                                          "1" ? (
                                            <div className="offer">OFFER</div>
                                          ) : null}
                                          {bookmark.profile_image != null ? (
                                            <img
                                              src={bookmark.profile_image}
                                              alt=" Selected for You "
                                              className=" img-fluid "
                                            />
                                          ) : (
                                            <img
                                              src="/assets/images/noimage.png "
                                              alt=" Selected for You "
                                              className=" img-fluid "
                                            />
                                          )}
                                        </div>
                                        <div className="text_wrap">
                                          <h6>
                                            {bookmark.verified == 1 ? (
                                              <span className="correct_icn">
                                                {bookmark.brand_name !== ""
                                                  ? bookmark.brand_name
                                                  : bookmark.name}
                                              </span>
                                            ) : (
                                              <span>
                                                {bookmark.brand_name !== ""
                                                  ? bookmark.brand_name
                                                  : bookmark.name}
                                              </span>
                                            )}
                                            {bookmark.total_ratings !== 0 ? (
                                              <span className="star">
                                                {bookmark.total_ratings}{" "}
                                                <span className="grey">
                                                  / 5
                                                </span>
                                              </span>
                                            ) : null}
                                          </h6>
                                          {bookmark.cuisines == "" ? (
                                            "-"
                                          ) : (
                                            <p className="sub_title">
                                              {bookmark.cuisines}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                          {/* <div className="col-md-6 pl-xl-5">
                            <div className="card">
                              <div className="img_wrap">
                                <img
                                  src="https://api.techtesting.in/uploads/profile-/assets/images/1612521048_4b2010fe9ea3b1f96f97.jpg"
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              </div>
                              <div className="text_wrap">
                                <h6>
                                  <span className="correct_icn">
                                    Test Kitchen
                                  </span>
                                  <span className="star">
                                    4.5<span className="grey">/ 5</span>
                                  </span>
                                </h6>
                                <p className="sub_title">
                                  Cuisine For Chef Kitchen Test
                                </p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(DinerBookmark);
