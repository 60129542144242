import { ADD_DINER_ALERT_COUNT, ADD_CHEF_ALERT_COUNT } from "./types";

export const saveDinerAlertData = (alertData) => (dispatch) => {
  return dispatch({
    type: ADD_DINER_ALERT_COUNT,
    payload: {
      alertData: alertData,
    },
  });
};

export const saveChefAlertData = (alertChefData) => (dispatch) => {
  return dispatch({
    type: ADD_CHEF_ALERT_COUNT,
    payload: {
      alertChefData: alertChefData,
    },
  });
};
