import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import DinnerHeader from "../../components/dinnerHeader";
import WhatsAppButton from "../../components/whatsappButton";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import config from "../../config/config";
import DinerFooter from "../../components/dinerFooter";
import DinerMenuTab from "../../components/dinerMenuTab";
import {
  dishDataAPI,
  chefDataAPI,
  articleTagAPI,
} from "../../core/services/Phase2APIservice";
import {
  HomeDescription,
  GetByCatImageSlider,
} from "../../backendUI/Home/config";
import { getDays, getMonth } from "../../backendUI/Fun";
import { URLs } from "../../backendUI/Url";
import { BlogData, BlogCategoryData } from "../../backendUI/Blog/config";
import { useParams } from "react-router-dom";

function BlogsScreen() {
  const params = useParams();

  const [data, setdata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [categorydata, setcategorydata] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    const getgteanData = await BlogData();
    const getgteanData2 = await BlogCategoryData();
    if (getgteanData.success == true && getgteanData2.success == true) {
      setdata(getgteanData.data);
      setfilterdata(getgteanData.data);
      setcategorydata(getgteanData2.data);
      setisLoading(false);
    }
  };
  const CatFilter = (id) => {
    const Check = categorydata.find((item) => {
      return item._id === id;
    });
    // console.log(Check.blogs, id);
    setfilterdata(Check.blogs);
  };
  return (
    <div className="css2">
      <header>
        <div className="container menu_wrapper">
          <div className="logo">
            <a href="/">
              <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
            </a>
          </div>
          <div id="nav-icon" className=" d-block d-xl-none">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className="dinner_menu">
            <li>
              {" "}
              <a href="/" className="login_btn">
                Home
              </a>{" "}
            </li>
            <li className="">
              {" "}
              <a href="/diner-aboutus" className="login_btn">
                About Us
              </a>{" "}
            </li>
            <li className="">
              {" "}
              <a
                href="https://wa.me/919899622242"
                target="_blank"
                className="login_btn"
              >
                Contact Us
              </a>{" "}
            </li>
            <li className="">
              {" "}
              <a href="/pick-city" className="login_btn">
                Pick A City
              </a>{" "}
            </li>
            <li className="active">
              {" "}
              <a href="/blog" className="login_btn">
                Blog
              </a>{" "}
            </li>
            <li className="d-block d-xl-none last_btn">
              <a className="btn register_btn" href="/chef-homepage">
                <span className="my_acc_text">Join Us AS a Home Chef</span>
              </a>
            </li>
          </ul>
          <ul className="dinner_menu d-none d-xl-block">
            <li>
              <a className="btn register_btn" href="/chef-homepage">
                <span className="my_acc_text">Join Us AS a Home Chef</span>
              </a>
            </li>
          </ul>
          <div className="menu_overlay"></div>
        </div>
      </header>
      <section>
        <div className="container pt-5">
          <div className="row align-self">
            <div className="col-md-8 m-0 p-0">
              <div className="container">
                <div className="row m-0 p-0">

                  <a
                    href="/top-home-chefs-in-noida"
                    className="col-md-6 text-center"
                    style={{ marginBottom: 10 }}
                  >
                    <div
                      className="mb-4"
                      style={{
                        width: "100%",
                        height: "70%",
                      }}
                    >
                      <div className="mt-2, mb-2" style={{ height: "17rem" }}>
                        <img
                          className="img-fluid"
                          src={"/assets/images/Blog/Savoring.webp"}
                          alt={"hgfhgfh"}
                          style={{
                            objectFit: "scale-down",
                            height: "15rem",
                          }}
                        />
                      </div>

                      <p className="color_a05 mt-4">Savoring Excellence: Noida’s Top Home Chefs and Their Delectable Creations</p>
                      <p
                        className="mt-2"
                        style={{ color: "#000", fontSize: 14 }}
                      >
                        Hey, foodies of Noida! Get ready to have your taste senses piqued! Let's go on a culinary adventure to the best home chefs in Noida, the trendiest kitchen where the top home cooks are creating delectable dishes.
                      </p>
                    </div>
                  </a>

                  <a
                    href="/15-top-biryani-home-chefs-in-delhi-ncr"
                    className="col-md-6 text-center"
                    style={{ marginBottom: 10 }}
                  >
                    <div
                      className="mb-4"
                      style={{
                        width: "100%",
                        height: "70%",
                      }}
                    >
                      <div className="mt-2, mb-2" style={{ height: "17rem" }}>
                        <img
                          className="img-fluid"
                          src={"/assets/images/Blog/HomeCheffoBriyani.webp"}
                          alt={"hgfhgfh"}
                          style={{
                            objectFit: "scale-down",
                            height: "15rem",
                          }}
                        />
                      </div>

                      <p className="color_a05 mt-4">15 Top Home Chefs In Delhi-NCR To Taste The Authentic Biryani
                      </p>
                      <p
                        className="mt-2"
                        style={{ color: "#000", fontSize: 14 }}
                      >
                        Delhi-NCR, the melting pot of diverse cultures and cuisines, offers a plethora of options for food enthusiasts. In the middle of all the posh restaurants and busy streets, you'll find hidden gems of home-cooked delicacies made by renowned chefs.
                      </p>
                    </div>
                  </a>

                  <a
                    href="/top-home-cook-in-bangalore"
                    className="col-md-6 text-center"
                    style={{ marginBottom: 10 }}
                  >
                    <div
                      className="mb-4"
                      style={{
                        width: "100%",
                        height: "70%",
                      }}
                    >
                      <div className="mt-2, mb-2" style={{ height: "17rem" }}>
                        <img
                          className="img-fluid"
                          src={"/assets/images/Blog/home_cook.webp"}
                          alt={"hgfhgfh"}
                          style={{
                            objectFit: "scale-down",
                            height: "15rem",
                          }}
                        />
                      </div>

                      <p className="color_a05 mt-4">Home Cooks in Bangalore You Must Order from</p>
                      <p
                        className="mt-2"
                        style={{ color: "#000", fontSize: 14 }}
                      >
                        Bangalore, widely regarded as the Silicon Valley of India, is renowned not only for its dynamic technological community but also for its haute cuisine. And not just cuisine you find at restaurants but the local cuisine offered from home to home in this city.
                      </p>
                    </div>
                  </a>

                  {filterdata.length > 0 &&
                    filterdata.map((item) => {
                      return (
                        <a
                          href={"/blog/" + item.slug}
                          // to={"/blog/" + item.slug}
                          className="col-md-6 text-center"
                          style={{ marginBottom: 10 }}
                        >
                          <div
                            className="mb-4"
                            style={{
                              width: "100%",
                              height: "70%",
                            }}
                          >
                            <div
                              className="mt-2, mb-2"
                              style={{ height: "17rem" }}
                            >
                              <img
                                className="img-fluid"
                                src={URLs.images + item.feturedimage}
                                alt={item.feturedimage_alt}
                                style={{
                                  objectFit: "scale-down",
                                  height: "15rem",
                                }}
                              />
                            </div>

                            <p className="color_a05 mt-4">{item.title}</p>
                            <p
                              className="mt-2"
                              style={{ color: "#000", fontSize: 14 }}
                            >
                              {item.description}
                            </p>
                          </div>
                        </a>
                      );
                    })}


                  <a
                    href="/blog-static"
                    className="col-md-6 text-center"
                    style={{ marginBottom: 10 }}
                  >
                    <div
                      className="mb-4"
                      style={{
                        width: "100%",
                        height: "70%",
                      }}
                    >
                      <div className="mt-2, mb-2" style={{ height: "17rem" }}>
                        <img
                          className="img-fluid"
                          src={"/assets/images/homepage2_banner3.jpg"}
                          alt={"hgfhgfh"}
                          style={{
                            objectFit: "scale-down",
                            height: "15rem",
                          }}
                        />
                      </div>

                      <p className="color_a05 mt-4">Top Chefs of Noida</p>
                      <p
                        className="mt-2"
                        style={{ color: "#000", fontSize: 14 }}
                      >
                        Here's the list of top chef's from Noida
                      </p>
                    </div>
                  </a>

                </div>
              </div>
            </div>
            <div className="col-md-2">
              <p>Latest Post</p>

              {data.length > 0 &&
                data.map((item, index) => {
                  var Dates = item.updatedAt.split("T")[0];

                  return (
                    <>
                      <div className="row mt-2">
                        <div
                          className="d-flex col-3 align-items-center"
                          style={{
                            backgroundColor: "#e53a05",
                          }}
                        >
                          <p
                            className="mt-2 mb-2"
                            style={{
                              color: "#fff",
                              fontSize: 12,
                              textAlign: "center",
                            }}
                          >
                            {new Date(Dates).getDate() + " " + getMonth(Dates)}
                          </p>
                        </div>
                        <div
                          className="col-9 color_a05"
                          style={{ fontSize: 16 }}
                        >
                          <a href={"/blog/" + item.slug}>
                            <p className="color_a05">{item.title}</p>
                          </a>
                        </div>
                      </div>
                      {index == data.length - 1 ? null : <hr />}
                    </>
                  );
                })}

              <p className="mt-3">Categories</p>
              {categorydata.length > 0 &&
                categorydata.map((item, index) => {
                  return (
                    <>
                      <a
                        type="button"
                        onClick={() => CatFilter(item._id)}
                        className="row m-0 p-0 mt-1"
                      >
                        <div className="color_a05  m-0 p-0">
                          {item.categoryname}
                        </div>
                        <div className="m-0 p-0">
                          &nbsp;{"("}
                          {item.blogs.length}
                          {")"}
                        </div>
                      </a>
                      {index == categorydata.length - 1 ? null : <hr />}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogsScreen;
