/*
#############################################################################
# Name : AccountSidebar.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the sidebar details
#############################################################################
*/
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import {
  changeProfileImage,
  dinerPinsAchievedAPI,
} from "../../../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import {
  saveUserData,
  removeUserData,
} from "../../../../redux/actions/authActions";
import { emptyCart } from "../../../../redux/actions/cartActions";
import { addMobileView } from "../../../../redux/actions/chefMenuActions";
import DinerFooter from "../../../../components/dinerFooter";
import config from "../../../../config/config";

class AccountSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHistoryOpen: false,
      selectedCity: "",
      oldSlide: 0,
      currentSlide: 0,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      slideItems: [],
      /*  slideItems: [
        {
          image: "assets/images/icons/order-1st.svg",
          title: "1st Order Pin",
          desc: "150 Happy customers lorem ipsumOrder Pin",
        },
        {
          image: "assets/images/icons/order-10th.svg",
          title: "10th Order Pin",
          desc: "100 Happy customers lorem ipsum Order Pin",
        },
        {
          image: "assets/images/icons/months-3.svg",
          title: "3 months",
          desc: "200 Happy customers lorem ipsum",
        },
        {
          image: "assets/images/icons/anniversary.svg",
          title: "Anniversary",
          desc: "250 Happy customers lorem ipsum",
        },
        {
          image: "assets/images/icons/super-chef.svg",
          title: "Super Chef",
          desc: "300 Happy customers lorem ipsum",
        },
        {
          image: "assets/images/icons/ama-pin.svg",
          title: "AMA pin",
          desc: "350 Happy customers lorem ipsum",
        },
        {
          image: "assets/images/icons/happy-customers.svg",
          title: "Happy Customers",
          desc: "400 Happy customers lorem ipsum",
        },
      ], */
    };
  }
  componentDidMount() {
    if (
      this.props.history.location.pathname === "/past-orders" ||
      this.props.history.location.pathname === "/current-orders" ||
      this.props.history.location.pathname === "/cancelled-orders" ||
      this.props.history.location.pathname === "/track-diner-order"
    ) {
      this.setState({
        isHistoryOpen: true,
      });
    }
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: selectedCity,
      });
    }
    this.getPinsAchieved();
  }

  getPinsAchieved = () => {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return dinerPinsAchievedAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          console.log(response.data.data);
          this.setState({
            slideItems: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /* logoutHandler function starts here */
  logoutHandler = (e) => {
    NotificationManager.success("You have Logout Successfully", "Successful!");
    this.props.removeUserData(); // removeUserData function
    this.props.emptyCart(); // emptyCart function
    localStorage.clear();
  };
  /* logoutHandler function ends here */

  /* nextClick function starts here */
  nextClick = (e) => {
    const { slideItems } = this.state;
    var c1 = slideItems.length - (slideItems.length % 2);
    var c2 = slideItems.length + 2 - (slideItems.length % 2);

    if (e >= c1) {
      if ((slideItems.length - 1) % 2 == 1) {
        this.setState({
          currentSlide: slideItems.length - 1,
        });
      } else {
        this.setState({
          currentSlide: slideItems.length - 1,
        });
      }
    } else {
      this.setState({
        currentSlide: e,
      });
    }
  };
  /* nextClick function ends here */

  /* uploadProfile function starts here */
  uploadProfile() {
    document.getElementById("selectImage").click();
  }
  /* uploadProfile function ends here */

  /* fileSelectHandler function starts here */
  fileSelectHandler = (event) => {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      this.setState({ errorUserImage: "Please select valid file type." });
      return false;
    } else {
      this.setState({ errorUserImage: "" });
    }
    var filename = event.target.files[0]["name"];
    return changeProfileImage(userId, accessToken, file, filename)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            profilePhoto: response.data["data"]["profile_image"],
          });
          let data = {
            userType: "Diner",
            userId: localStorage.getItem("userID"),
            userEmail: response.data["data"]["email"],
            userName:
              response.data["data"]["first_name"] +
              " " +
              response.data["data"]["last_name"],
            userMobile: response.data["data"]["mobile_number"],
            image: response.data["data"]["profile_image"],
            accessToken: localStorage.getItem("accessToken"),
          };
          this.props.saveUserData(data);
          localStorage.setItem(
            "userImage",
            response.data["data"]["profile_image"]
          );
          setTimeout(() => {}, 1000);
          NotificationManager.success(
            "Profile Image Uploaded",
            "Successful!",
            3000
          );
        }
      })
      .catch((error) => {
        NotificationManager.error("Failed to upload Profile Image", "Error!");
      });
  };
  /* fileSelectHandler function ends here */

  render() {
    const { history, userData, mobileView } = this.props;
    const { currentSlide, slideItems } = this.state;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slideItems && slideItems.length > 2 ? 3 : 1,
      slidesToScroll: 1,
      afterChange: this.nextClick,
    };
    return (
      <div
        className={
          mobileView
            ? "col-md-11 col-lg-4 col-xl-3 active"
            : "col-md-11 col-lg-4 col-xl-3"
        }
        id="SidebarMenu"
      >
        <div className="diner_sidebar">
          <Link
            to={"/" + this.state.selectedCity + "/diner-homepage"}
            className="res_back_btn mobile_sidebar_back_btn"
          >
            <img
              src="assets/images/icons/left-arrow-black.svg"
              alt="left-arrow-black"
            />
          </Link>
          <div className="top">
            <div className="left">
              <h6>
                {userData.userName
                  ? userData.userName.length > 10
                    ? userData.userName.substr(0, 10) + "..."
                    : userData.userName
                  : null}
              </h6>
              <Link to={"/diner-profile"}>Edit Profile</Link>
              <div className="wrap">
                <p className="mobile">{userData.userMobile}</p>
                <p>{userData.userEmail}</p>
              </div>
              {/* <a href="#">Edit Profile</a> */}
            </div>
            <div className="right">
              <div className="profile_img mx-auto">
                <div className="inner">
                  {this.props.userData.image ? (
                    <img
                      src={this.props.userData.image}
                      alt="profile img"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src="assets/images/icons/proflie-img2.png"
                      alt="profile img"
                      className="img-fluid"
                    />
                  )}
                </div>
                <button className="pro_edit_btn" onClick={this.uploadProfile}>
                  <img
                    src="assets/images/icons/photograph.png"
                    alt="Photograph Icon"
                    className="img-fluid"
                  />
                </button>
                <input
                  id="selectImage"
                  type="file"
                  hidden
                  onChange={this.fileSelectHandler}
                />
              </div>
            </div>
          </div>
          <hr className="dash_line" />
          <ul className="icon_list">
            {slideItems && (
              <li
                className="icons pins_achieved"
                data-toggle="modal"
                data-target="#pins_achieved"
              >
                <img src="assets/images/icons/pins-achieved.svg" alt="" />
                Pins achieved
                <img
                  src="assets/images/icons/pins-achieved-right-arrow.svg"
                  alt=""
                  className="right_icon"
                />
              </li>
            )}
            <li className="icons bookmark">
              <Link
                to={"/diner-bookmark"}
                onClick={() => this.props.addMobileView()}
              >
                <img src="assets/images/icons/bookmark.svg" alt="" />
                Bookmarks
              </Link>
            </li>
          </ul>
          <hr className="dash_line" />
          <div className="menu_list_wrap">
            <ul className="menu">
              <li
                className={
                  history.location.pathname === "/manage-address"
                    ? "active"
                    : null
                }
              >
                <Link
                  to="/manage-address"
                  className="icon add_icon"
                  onClick={() => this.props.addMobileView()}
                >
                  Manage Address
                </Link>
              </li>
			   <li
                className={
                  history.location.pathname === "/my-reviews"
                    ? "active"
                    : null
                }
              >
                <Link
                  to="/my-reviews"
                  className="icon review_new_icon"
                  onClick={() => this.props.addMobileView()}
                >
                  My Reviews
                </Link>
              </li>
              <li
                className={
                  history.location.pathname === "/refund-status"
                    ? "active"
                    : null
                }
              >
                <Link
                  to="/refund-status"
                  className="icon refund_icon"
                  onClick={() => this.props.addMobileView()}
                >
                  Refund Status
                </Link>
              </li>
              <li className="sub_menu">
                <a
                  data-toggle="collapse"
                  role="button"
                  onClick={() => {
                    this.setState({
                      isHistoryOpen: !this.state.isHistoryOpen,
                    });
                  }}
                  aria-expanded={this.state.isHistoryOpen ? "true" : "false"}
                  aria-controls="Order-History"
                  className="icon order_icon"
                >
                  Order History
                </a>
                <div
                  className={
                    this.state.isHistoryOpen ? "collapse show" : "collapse"
                  }
                  id="Order-History"
                >
                  <hr className="dash_line" />
                  <ul className="inner_menu">
                    <li
                      className={
                        this.props.history.location.pathname ===
                        "/current-orders"
                          ? "active"
                          : null
                      }
                    >
                      <Link
                        to="/current-orders"
                        onClick={() => this.props.addMobileView()}
                      >
                        Current Orders
                      </Link>
                    </li>
                    <li
                      className={
                        this.props.history.location.pathname === "/past-orders"
                          ? "active"
                          : null
                      }
                    >
                      <Link
                        to="/past-orders"
                        onClick={() => this.props.addMobileView()}
                      >
                        Past Orders
                      </Link>
                    </li>
                    <li
                      className={
                        this.props.history.location.pathname ===
                        "/cancelled-orders"
                          ? "active"
                          : null
                      }
                    >
                      <Link
                        to="/cancelled-orders"
                        onClick={() => this.props.addMobileView()}
                      >
                        Cancelled Orders
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="menu d-block d-lg-none">
                <a
                  href="/"
                  onClick={this.logoutHandler}
                  className="icon log_out_icon"
                >
                  Log Out
                </a>
              </li>
            </ul>
          </div>
        </div>
        {this.state.hideContent ? (
          <div className="sidebar_footer">
            <DinerFooter />
          </div>
        ) : null}
        {slideItems && (
          <div
            className="pins_achieved_modal modal fade "
            id="pins_achieved"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="pins_achievedTitle"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="pins_achievedTitle">
                    Pins achieved
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="assets/images/icons/black-close.svg"
                      alt="Close"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="wrap-modal-slider">
                    <Slider {...settings} className="pin_slider slider-nav">
                      {slideItems.map((item, i) => {
                        return (
                          <div className="icons" key={"grkw" + i}>
                            <div onClick={() => this.setState({ currentSlide: i })}>
                              <img src={item.image} alt="1st Order Pin" />
                            </div>
                            <span>{item.title}</span>
                          </div>
                        );
                      })}
                    </Slider>
                    <Slider className="pin_slider slider-for">
                      {slideItems.map((item, i) => {
                        return (
                          currentSlide == i && (
                            <div className="bottom_text" key={"sdfs" + i}>
                              <img src={item.image} alt="1st Order Pin" />
                              <span>{item.desc}</span>
                            </div>
                          )
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, {
  saveUserData,
  removeUserData,
  emptyCart,
  addMobileView,
})(withRouter(AccountSidebar));
