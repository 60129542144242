import { URLs } from "../Url";
export const handlePostImage = async (updateimage) => {
  try {
    var formdata = new FormData();
    formdata.append("image", updateimage);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home-imageupload",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const handledeleteImage = async (name) => {
  try {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home-deleteimage/" + name,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
//Benefits
export const GetBenefitmeals = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/Herobenefits",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const BenefitmealUpload = async (title, description, Getimage) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: title,
      description: description,
      image: Getimage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/Herobenefits/",
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const DeleteBenefitmeal = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/benefits-delete/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const BenefitmealUpdate = async (id, title, description, Getimage) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: title,
      description: description,
      image: Getimage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/benefits-update/" + id,
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
//Metatags
export const GetMetatags = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/HomeMeta",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const Metatagsupload = async (
  title,
  description,
  keyword,
  ogtitle,
  ogdescription,
  Getimage
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: title,
      description: description,
      keywords: keyword,
      ogtitle: ogtitle,
      ogdescription: ogdescription,
      ogimage: Getimage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/HomeMeta-upload",
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const Metatagsupdate = async (
  id,
  title,
  description,
  keyword,
  ogtitle,
  ogdescription,
  Getimage
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: title,
      description: description,
      keywords: keyword,
      ogtitle: ogtitle,
      ogdescription: ogdescription,
      ogimage: Getimage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/meta-update/" + id,
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
// Features
export const HomeDescription = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/description",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const HomeDescriptionUpload = async (description) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      description: description,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/herodescription",
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const HomeDescriptionUpdate = async (UpdateId, description) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      description: description,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/description-update/" + UpdateId,
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
// Images
export const HomeImages = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/HeroImage",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const DeleteHomeImages = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/image-delete/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const HomeImagesUpload = async (alttext, image) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      image: image,
      alttext: alttext,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/HeroImage",
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const HomeImagesUpdate = async (UpdateId, alttext, image) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      image: image,
      alttext: alttext,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/image-update/" + UpdateId,
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};

//image slider
export const GetByCatImageSlider = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + `/api/home/Imagebycatergoryslider?category=${id}`,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const GetImageSlider = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/Imageslider",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const DeleteImageSlider = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/Imageslider-delete/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const ImageSliderUpload = async (
  title,
  description,
  Getimage,
  category,
  redirectionUrl
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    //   {
    //   title: title,
    //   description: description,
    //   category: category,
    //   redirectionUrl: redirectionUrl,
    //   image: Getimage,
    // }
    var raw = JSON.stringify({
      title: title,
      altImage: description,
      category: category,
      redirectionUrl: redirectionUrl,
      image: Getimage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/Imageslider",
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const ImageSliderUpdate = async (
  id,
  title,
  description,
  Getimage,
  category,
  redirectionUrl
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: title,
      altImage: description,
      category: category,
      redirectionUrl: redirectionUrl,
      image: Getimage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/Imageslider-update/" + id,
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
