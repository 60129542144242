/*
#############################################################################
# Name : dinerCheckoutPage.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the cart details and to handle the payment flow 
#############################################################################
*/
import React from "react";
import { Link, Redirect } from "react-router-dom";
import DinnerHeader from "../../../../components/dinnerHeader";
import WhatsAppButton from "../../../../components/whatsappButton";
import ProcessingDiv from "../../../../components/ProcessingDiv";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import { connect } from "react-redux";
import {
  increaseQuantity,
  decreaseQuantity,
  emptyCart,
} from "../../../../redux/actions/cartActions";
import {
  addOrderNumber,
  removeOrderNumber,
} from "../../../../redux/actions/orderNumberActions";
import { removeUserData } from "../../../../redux/actions/authActions";
import { removeTimeSlot } from "../../../../redux/actions/timeSlotActions";
import { removeChefData } from "../../../../redux/actions/chefDataActions";
import {
  getRazorPayOrderIDAPI,
  deliveryChargesAPI,
  taxCalculationAPI,
  orderDetailAPI,
  getChefDetailAPI,
  createOrderNumber,
  updateOrderOnCartChange,
  removeCouponCodeAPI,
  getCouponCodeAPI,
  getCouponCodeAppliedAPI,
} from "../../../../core/services/Phase2APIservice";
import { getUserLocationAPI } from "../../../../core/services/APIservice";
import { updateDinerLocationAPI } from "../../../../core/services/Phase2APIservice";
import Geocode from "react-geocode";
import config from "../../../../config/config";
import LoadingDiv from "../../../../components/LoadingDiv";
import { NotificationManager } from "react-notifications";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

const couponCodePattern = /^[a-zA-Z\d-_]+$/;

class CheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      isProcessing: false,
      selectedCity: "",
      isLoading: true,
      showAddress: false,
      modifyChef: "",
      modifyDiner: "",
      chars_left: 100,
      requestDesc: "",
      showRequestPopupFlag: false,
      deliveryCharges: "",
      actualDeliveryCharges: "",
      paymentID: "",
      orderID: "",
      orderAmount: "",
      razorPayDetail: [],
      taxAmount: "",
      latitude: "",
      longitude: "",
      chefLatitude: "",
      chefLongitude: "",
      deliveryPartner: "",
      deliveryPartnerType: "",
      chefName: "",
      chefAddress: "",
      chefFullAddress: "",
      chefProfileImage: "",
      chefId: "",
      payButtonDisabled: true,
      paymentStatus: "Failed",
      isTakeawayChoice: false,
      chefDiscountFlag: this.props.chefData.chefDiscountFlag ? this.props.chefData.chefDiscountFlag : false,
      slugUrl: "",
      discountCharges: "",
      dueAmount: 0,
      mapPopupFlag: true,
      address: "",
      location: "",
      locationName: "",
      locationImage: "",
      latitude: "",
      longitude: "",
      mapLatitude: "",
      mapLongitude: "",
      showMap: false,
      markers: [
        {
          name: "",
          position: {
            lat: "",
            lng: "",
          },
        },
      ],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      fullAddress: "",
      landMark: "",
      addressSaveAs: "Other",
      otherText: "",
      pincode: "",
      dragLat: "",
      dragLng: "",
      userId: "",
      formErrors: {
        fullAddress: "",
        inputCouponCode: "",
      },
      showFilterFlag: false,
      couponListArr: [],
      couponDesc: "",
      couponDescFlag: false,
      selectedCoupon: "",
      selectedDiscountCoupon: "",
      inputCouponCode: "",
      removeCouponFlag: false,
    };
    this.openCheckout = this.openCheckout.bind(this);
    this.handleMapPopupChange = this.handleMapPopupChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  async componentDidMount() {
    if (
      localStorage.getItem("flatNo") == null ||
      localStorage.getItem("flatNo") == ""
    ) {
      this.setState({
        mapPopupFlag: false,
      });
    }
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: selectedCity,
      });
    }
    if (
      localStorage.getItem("dinerTakeawayChoice") &&
      (localStorage.getItem("dinerTakeawayChoice") === true ||
        localStorage.getItem("dinerTakeawayChoice") === "true")
    ) {
      this.setState({
        showAddress: true,
        isTakeawayChoice: true,
        deliveryCharges: 0,
        actualDeliveryCharges: 0,
        payButtonDisabled: false,
        mapPopupFlag: true,
      });
    }
    if (this.props.cartItems.length) {
      this.getChefData(); // Chef data is fetched
      // this.getDinerLocation();
    } else {
      this.setState({
        isLoading: false,
      });
    }
    this.setState({
      userId: localStorage.getItem("userID"),
    });
  }

  /* handleMapPopupChange function starts here */
  handleMapPopupChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "fullAddress":
        formErrors.fullAddress = value === "" ? "Input is required" : "";
        break;

      case "inputCouponCode":
        formErrors.inputCouponCode = !couponCodePattern.test(e.target.value)
          ? "Enter valid Coupon Code."
          : "";
        break;

      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
  /* handleMapPopupChange function ends here */

  /* handleRadioChange function starts here */
  handleRadioChange(event) {
    this.setState({ addressSaveAs: event.target.value });
  }
  /* handleRadioChange function ends here */

  /* handleChange function starts here */
  handleChange = (address) => {
    this.setState({ address });
  };
  /* handleChange function ends here */

  /* openMapPopup function starts here */
  openMapPopup = (event) => {
    event.preventDefault();
    this.setState({
      address: localStorage.getItem("addressSelected"),
      markers: [
        {
          name: "",
          position: {
            lat: localStorage.getItem("latitude"),
            lng: localStorage.getItem("longitude"),
          },
        },
      ],
    });
    setTimeout(() => {
      this.setState({ showMap: true });
    }, 1000);
  };
  /* openMapPopup function ends here */

  /* closeMapPopup function starts here */
  closeMapPopup = (event) => {
    event.preventDefault();
    this.setState({
      showMap: false,
      address: "",
    });
  };
  /* closeMapPopup function ends here */

  /* moveMarker function starts here */
  moveMarker = (props, marker, e, index) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    this.setState({
      latitude: lat,
      longitude: lng,
    });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.setState({
          selectedPlace: address,
          activeMarker: marker,
          showingInfoWindow: true,
          address: address,
        });
        response.results[0].address_components.forEach((item) => {
          item.types.forEach((type) => {
            if (type === "postal_code") {
              this.setState({
                pincode: item.long_name,
              });
            }
          });
        });
      },
      (error) => {
        /*    console.error(error); */
      }
    );
  };
  /* moveMarker function ends here */

  /* onClose function starts here */
  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  /* onClose function ends here */

  getCouponCodeList = () => {
    var token = localStorage.getItem("accessToken");
    var amount = this.props.cartItems.reduce(
      (a, c) => a + c.item_price * c.qty,
      0
    );

    var dinerCity = localStorage.getItem("selectedLocation");
    let cartId = localStorage.getItem("cartRandomStringId");
    return getCouponCodeAPI(
      this.state.userId,
      this.state.chefId,
      dinerCity,
      amount,
      cartId,
      token
    )
      .then((response) => {
        if (response.data.status === 200) {
          /* console.log(response.data.data); */
          this.setState({
            couponListArr: response.data.data,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };

  /* saveData function starts here */
  saveData = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.fullAddress === "") {
      formErrors.fullAddress = "Input is required";
    }
    var citySeleted = "";
    citySeleted = localStorage.getItem("selectedLocation");
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var token = localStorage.getItem("accessToken");
      /* if (this.state.landMark != "") {
      var addressSelected =
        this.state.fullAddress +
        ", " +
        this.state.landMark +
        ", " +
        this.state.address;
    } else {
      var addressSelected =
        this.state.fullAddress + ", " + this.state.address;
    } */
      if (localStorage.getItem("prevLocationId") != null) {
        var locationId = localStorage.getItem("prevLocationId");
        let locationData = {
          user_id: this.state.userId,
          location_id: locationId,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          city: citySeleted,
          location: this.state.address,
          pincode: this.state.pincode,
          type: this.state.addressSaveAs,
          location_title: this.state.otherText,
          address_line1: this.state.fullAddress,
          address_line2: this.state.landMark,
        };
        return updateDinerLocationAPI(locationData, token)
          .then((response) => {
            if (response.data["status"] === 200) {
              localStorage.setItem("selectedLocation", citySeleted);
              localStorage.setItem("flatNo", this.state.fullAddress);
              localStorage.setItem("landmark", this.state.landMark);
              localStorage.setItem("addressSelected", this.state.address);
              localStorage.setItem("addressType", this.state.addressSaveAs);
              localStorage.setItem("pincode", this.state.pincode);
              localStorage.setItem("latitude", this.state.latitude);
              localStorage.setItem("longitude", this.state.longitude);
              if (
                this.state.addressSaveAs == "Other" ||
                this.state.addressSaveAs == "other"
              ) {
                localStorage.setItem("addressDetailType", this.state.otherText);
              }
              this.setState({
                showMap: false,
                mapPopupFlag: true,
              });
              this.props.history.push("/checkout");
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
            } else {
              if (response.status === 401) {
                NotificationManager.error(
                  "Session expired. Please log in.",
                  "Error!"
                );
                this.props.removeUserData();
                this.props.removeChefData();
                this.props.emptyCart();
                this.props.removeTimeSlot();
                localStorage.clear();
                this.props.history.push("/diner-signin-with-otp");
              }
            }
          })
          .catch((error) => {
            /* console.log(error); */
          });
      } else {
        return getUserLocationAPI(
          this.state.userId,
          token,
          this.state.latitude,
          this.state.longitude,
          citySeleted,
          this.state.address,
          this.state.pincode,
          this.state.addressSaveAs,
          this.state.otherText,
          this.state.fullAddress,
          this.state.landMark
        )
          .then((response) => {
            if (response.data["status"] === 200) {
              localStorage.setItem(
                "prevLocationId",
                response.data.diner_location_id
              );
              localStorage.setItem("selectedLocation", citySeleted);
              localStorage.setItem("flatNo", this.state.fullAddress);
              localStorage.setItem("landmark", this.state.landMark);
              localStorage.setItem("addressSelected", this.state.address);
              localStorage.setItem("addressType", this.state.addressSaveAs);
              localStorage.setItem("pincode", this.state.pincode);
              localStorage.setItem("latitude", this.state.latitude);
              localStorage.setItem("longitude", this.state.longitude);
              if (
                this.state.addressSaveAs == "Other" ||
                this.state.addressSaveAs == "other"
              ) {
                localStorage.setItem("addressDetailType", this.state.otherText);
              }
              this.setState({
                showMap: false,
                mapPopupFlag: true,
              });
              this.props.history.push("/checkout");
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
            } else {
              if (response.status === 401) {
                NotificationManager.error(
                  "Session expired. Please log in.",
                  "Error!"
                );
                this.props.removeUserData();
                this.props.removeChefData();
                this.props.emptyCart();
                this.props.removeTimeSlot();
                localStorage.clear();
                this.props.history.push("/diner-signin-with-otp");
              }
            }
          })
          .catch((error) => {
            /* console.log(error); */
          });
      }
    } else {
      /* console.log("error"); */
    }
  };
  /* saveData function ends here */

  changeAddress = () => {
    this.props.history.push("/pick-city?checkout");
  };

  /* getOrderNumber function starts here */
  getOrderNumber = () => {
    var takeAway = "";
    if (this.state.showAddress === true) {
      takeAway = 1;
    } else {
      takeAway = 0;
    }
    let itemArray = [];
    this.props.cartItems.forEach((item) => {
      itemArray.push({
        menu_id: item.menu_id,
        item_name: item.item_name,
        item_price: item.item_price,
        actual_price: item.actual_price,
        itemQuantity: item.qty,
      });
    });
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    let orderData = {
      order_number: this.props.orderNumber,
      chef_id: this.state.chefId,
	  chef_discount: this.props.chefData.chefDiscount ? this.props.chefData.chefDiscount : '',
      chef_discount_start_date: this.props.chefData.chefDiscountStartDate ? this.props.chefData.chefDiscountStartDate : '',
      chef_discount_end_date: this.props.chefData.chefDiscountEndDate ? this.props.chefData.chefDiscountEndDate : '',
      chef_discount_flag: this.props.chefData.chefDiscountFlag ? this.props.chefData.chefDiscountFlag : '',
      chef_discount_type: this.props.chefData.chefDiscountType ? this.props.chefData.chefDiscountType : '',
      chef_enable_discount: this.props.chefData.chefEnableDiscount ? this.props.chefData.chefEnableDiscount : '',
      diner_id: this.props.userData.userId,
      amount_due:
        this.props.cartItems.reduce((a, c) => a + c.item_price * c.qty, 0) +
        parseFloat(
          this.state.deliveryCharges !== "" ? this.state.deliveryCharges : 0
        ) +
        parseFloat(this.state.taxAmount !== "" ? this.state.taxAmount : 0),
      tax_amount: this.state.taxAmount,
      delivery_charges: this.state.deliveryCharges,
      drop_location: localStorage.getItem("addressSelected"),
      drop_latitude: this.state.latitude,
      drop_longitude: this.state.longitude,
      status: "created",
      special_instructions: this.state.requestDesc,
      is_takeaway_selected: takeAway,
      order_details: itemArray,
      delivery_partner: this.state.deliveryPartner,
      delivery_partner_type: this.state.deliveryPartnerType,
      selected_time_slot:
        this.props.timeSlot.slotDay + " - " + this.props.timeSlot.slotTime,
      selected_time_slot_from_datetime:
        this.props.timeSlot.slotDay +
        ", " +
        this.props.timeSlot.slotTime.split("-")[0],
      selected_time_slot_to_datetime:
        this.props.timeSlot.slotDay +
        ", " +
        this.props.timeSlot.slotTime.split("-")[1],
    };

    return createOrderNumber(userId, token, orderData)
      .then((response) => {
        if (response.data.status === 200) {
          this.props.addOrderNumber(response.data.order_number);
        }
      })
      .catch((error) => {
        /* console.log(error) */
      });
  };
  /* getOrderNumber function ends here */

  /* randomString function starts here */
  randomString = () => {
    let length = 32;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };
  /* randomString function ends here */

  /* getChefData function starts here */
  getChefData = () => {
    return getChefDetailAPI(this.props.cartItems[0].chef_id, "")
      .then((response) => {
        if (response.data.status === 200) {
          localStorage.setItem("chefName", response.data.data.name);
          this.setState(
            {
              chefId: response.data.data.chef_id,
              chefName: response.data.data.name,
              chefAddress: response.data.data.address,
              chefFullAddress: response.data.data.full_address,
              chefProfileImage: response.data.data.profile_image,
              chefTakeaway: response.data.data.is_takeaway_available,
              chefLatitude: response.data.data.latitude,
              chefLongitude: response.data.data.longitude,
              slugUrl: response.data.data.slug_url,
              isLoading: false,
            },
            () => {
              this.getDinerLocation();
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.removeUserData();
            this.props.removeChefData();
            this.props.emptyCart();
            this.props.removeTimeSlot();
            localStorage.clear();
            this.props.history.push("/diner-signin-with-otp");
          }
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        /* console.log(error); */
      });
  };
  /* getChefData function ends here */

  /* getDinerLocation function starts here */
  getDinerLocation = () => {
    this.setState({
      payButtonDisabled: true,
    });
    Geocode.setApiKey(config.placesKey);
    var address = localStorage.getItem("addressSelected");
    Geocode.fromAddress(this.state.chefFullAddress).then((response) => {
      const { lat, lng } = response.results[0].geometry.location;
      this.setState({
        modifyChef: lat + "," + lng,
      });
    });
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({
          latitude: lat,
          longitude: lng,
        });
        var chefAddress = this.props.chefData.chefAddress;
        var strChefAddress = this.state.chefFullAddress;
        /* var modifyChefAddress = strChefAddress
          ? strChefAddress.split(" ").join("+")
          : ""; */
        var strDinerAddress = localStorage.getItem("addressSelected");
        var modifyDinerAddress = strDinerAddress
          ? strDinerAddress.split(" ").join("+")
          : "";
        this.setState({
          modifyDiner: modifyDinerAddress,
        });
        let data = {
          strChefAddress: strChefAddress,
          strDinerAddress: strDinerAddress,
          city: localStorage.getItem("selectedLocation"),
          pickup_lat: this.state.chefLatitude,
          pickup_lng: this.state.chefLongitude,
          drop_lat: this.state.latitude,
          drop_lng: this.state.longitude,
          selected_time_slot:
            this.props.timeSlot.slotDay + " - " + this.props.timeSlot.slotTime,
          selected_time_slot_from_datetime:
            this.props.timeSlot.slotDay +
            ", " +
            this.props.timeSlot.slotTime.split("-")[0],
          selected_time_slot_to_datetime:
            this.props.timeSlot.slotDay +
            ", " +
            this.props.timeSlot.slotTime.split("-")[1],
        };
        try {
          return deliveryChargesAPI(data)
            .then((response) => {
              if (response.data.status === 200) {
                this.setState(
                  {
                    deliveryCharges: this.state.isTakeawayChoice
                      ? 0
                      : response.data.data.delivery_charges,
					actualDeliveryCharges: this.state.isTakeawayChoice
                      ? 0
                      : response.data.data.actual_delivery_charges,
                    deliveryPartner: response.data.data.delivery_partner,
                    deliveryPartnerType: response.data.data.type,
                  },
                  () => {
                    this.getTaxDetail();
                  }
                );
              } else {
                NotificationManager.error(response.data.message, "Error!");
              }
            })
            .catch((error) => {
              /* NotificationManager.error(error, "Error!"); */
            });
        } catch (error) {
          /* NotificationManager.error(error, "Error!"); */
        }
      },
      (error) => {
        /* console.error(error); */
      }
    );
  };
  /* getDinerLocation function ends here */

  /* handleRequestTextareaChange function starts here */
  handleRequestTextareaChange = (key) => {
    const charCount = key.target.value.length;
    const charLeft = 100 - charCount;
    this.setState({ chars_left: charLeft });
    this.setState({
      [key.target.name]: key.target.value,
    });
  };
  /* handleRequestTextareaChange function ends here */

  /* handleCheckAddress function starts here */
  handleCheckAddress = (event) => {
    if (event.target.checked) {
      this.setState(
        {
          deliveryCharges: 0,
          actualDeliveryCharges: 0,
          showAddress: true,
          isTakeawayChoice: true,
          payButtonDisabled: false,
          mapPopupFlag: true,
        },
        () => {
          this.getTaxDetail(localStorage.getItem("couponCode"));
        }
      );

      localStorage.setItem("dinerTakeawayChoice", true);
    } else {
      localStorage.setItem("dinerTakeawayChoice", false);
      this.getDinerLocation();
      this.setState({
        showAddress: false,
        isTakeawayChoice: false,
      });
      if (
        localStorage.getItem("flatNo") == null ||
        localStorage.getItem("flatNo") == ""
      ) {
        this.setState({
          mapPopupFlag: false,
        });
      }
    }
  };
  /* handleCheckAddress function ends here */

  /* getTaxDetail function starts here */
  getTaxDetail(flag = "") {
    // var timeStamp = new Date().getUTCMilliseconds();
    /* if(this.props.userData.userId){
     var timeStamp = (new Date().getTime()).toString(36) + this.state.chefId + this.props.userData.userId;
    } else {
     var timeStamp = (new Date().getTime()).toString(36) + this.state.chefId;
    }
     console.log(timeStamp);  */
    let itemArray = [];
    this.props.cartItems.forEach((item) => {
      itemArray.push({
        menu_id: item.menu_id,
        item_name: item.item_name,
        item_price: item.item_price,
        actual_price: item.actual_price,
        itemQuantity: item.qty,
      });
    });

    let orderData = {
      order_details: itemArray,
      chef_id: this.state.chefId,
	  chef_discount: this.props.chefData.chefDiscount ? this.props.chefData.chefDiscount : '',
      chef_discount_end_date: this.props.chefData.chefDiscountEndDate ? this.props.chefData.chefDiscountEndDate : '',
      chef_discount_start_date: this.props.chefData.chefDiscountStartDate ? this.props.chefData.chefDiscountStartDate : '',
      chef_discount_flag: this.props.chefData.chefDiscountFlag ? this.props.chefData.chefDiscountFlag : '',
      chef_discount_type: this.props.chefData.chefDiscountType ? this.props.chefData.chefDiscountType : '',
      chef_enable_discount: this.props.chefData.chefEnableDiscount ? this.props.chefData.chefEnableDiscount : '',
    };
    return taxCalculationAPI(
      this.props.cartItems.reduce((a, c) => a + c.item_price * c.qty, 0),
      this.state.deliveryCharges,
      orderData
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            taxAmount: Math.round(response.data.data),
          });
          /* this.getOrderNumber() */
          this.updateOrderOnCartChange();
          /* this.getCouponCodeList(); */
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(function (error) {
        this.setState({
          isLoading: false,
        });
        /* console.log(error); */
      });
  }
  /* getTaxDetail function ends here */

  /* updateOrderOnCartChange function starts here */
  updateOrderOnCartChange = () => {
    var takeAway = "";
    if (this.state.showAddress === true) {
      takeAway = 1;
    } else {
      takeAway = 0;
    }
    let itemArray = [];
    this.props.cartItems.forEach((item) => {
      itemArray.push({
        menu_id: item.menu_id,
        item_name: item.item_name,
        item_price: item.item_price,
        actual_price: item.actual_price,
        itemQuantity: item.qty,
      });
    });
    let dueAmt =
      this.props.cartItems.reduce((a, c) => a + c.item_price * c.qty, 0) +
      parseFloat(
        this.state.deliveryCharges !== "" ? this.state.deliveryCharges : 0
      ) +
      parseFloat(this.state.taxAmount !== "" ? this.state.taxAmount : 0);
	  
    let orderData = {
      cart_id: localStorage.getItem("cartRandomStringId"),
      chef_discount: this.props.chefData.chefDiscount ? this.props.chefData.chefDiscount : '',
      chef_discount_start_date: this.props.chefData.chefDiscountStartDate ? this.props.chefData.chefDiscountStartDate : '',
      chef_discount_end_date: this.props.chefData.chefDiscountEndDate ? this.props.chefData.chefDiscountEndDate : '',
      chef_discount_flag: this.props.chefData.chefDiscountFlag ? this.props.chefData.chefDiscountFlag : '',
      chef_discount_type: this.props.chefData.chefDiscountType ? this.props.chefData.chefDiscountType : '',
      chef_enable_discount: this.props.chefData.chefEnableDiscount ? this.props.chefData.chefEnableDiscount : '',
      chef_id: this.state.chefId,
      diner_id: this.props.userData.userId ? this.props.userData.userId : 0,
      amount_due: dueAmt,
      amount_paid: this.state.orderAmount / 100,
      tax_amount: this.state.taxAmount,
      delivery_charges: this.state.deliveryCharges,
      drop_location: localStorage.getItem("addressSelected"),
      drop_latitude: this.state.latitude,
      drop_longitude: this.state.longitude,
      status: "created",
      special_instructions: this.state.requestDesc,
      is_takeaway_selected: takeAway,
      order_details: itemArray,
      delivery_partner: this.state.deliveryPartner,
      delivery_partner_type: this.state.deliveryPartnerType,
      selected_time_slot:
        this.props.timeSlot.slotDay + " - " + this.props.timeSlot.slotTime,
      selected_time_slot_from_datetime:
        this.props.timeSlot.slotDay +
        ", " +
        this.props.timeSlot.slotTime.split("-")[0],
      /*   selected_time_slot_from_datetime:"Sun, 27th Jun 2021, 11:00 PM", */
      selected_time_slot_to_datetime:
        this.props.timeSlot.slotDay +
        ", " +
        this.props.timeSlot.slotTime.split("-")[1],
    };
    return updateOrderOnCartChange(orderData)
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            discountCharges: response.data.data.discount_amount,
            dueAmount: response.data.data.due_amount,
            isLoading: false,
            payButtonDisabled: false,
          });
          if (localStorage.getItem("couponCode")) {
            this.applyTakeawayCouponCode();
          }
          if (localStorage.getItem("couponCode")) {
            this.setState({
              selectedCoupon: localStorage.getItem("couponCode"),
              selectedDiscountCoupon: localStorage.getItem("couponAmount"),
            });
          }
          this.getCouponCodeList();
        } else {
          /* NotificationManager.error(response.data.message, "Error!"); */
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.removeUserData();
            this.props.removeChefData();
            this.props.emptyCart();
            this.props.removeTimeSlot();
            localStorage.clear();
            this.props.history.push("diner-signin-with-otp");
          }
        }
        if (response.data.error) {
          NotificationManager.error(response.data.error, "Error!");
          this.setState({
            payButtonDisabled: true,
          });
        }
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };
  /* updateOrderOnCartChange function ends here */

  /* addCartItem function starts here */
  addCartItem = (e, item) => {
    this.setState({
      payButtonDisabled: true,
    });
    const cartItems = this.props.cartItems;
    this.props.increaseQuantity(cartItems, item);
    setTimeout(() => {
      this.getTaxDetail();
    }, 500);
  };
  /* addCartItem function ends here */

  /* removeCartItem function starts here */
  removeCartItem = (e, item) => {
    this.setState({
      payButtonDisabled: true,
    });
    if (item.qty == 1) {
      NotificationManager.success(
        item.item_name + " is removed from cart",
        "Success"
      );
    }
    const cartItems = this.props.cartItems;
    this.props.decreaseQuantity(cartItems, item);
    setTimeout(() => {
      this.getTaxDetail();
      if (!this.props.cartItems.length) {
        localStorage.removeItem("cartRandomStringId");
      }
    }, 500);
  };
  /* removeCartItem function ends here */

  /* getOrderPlaced function starts here */
  getOrderPlaced = () => {
    if (localStorage.getItem("couponCode")) {
      let couponApplied = localStorage.getItem("couponCode");
      console.log("in");
      var token = localStorage.getItem("accessToken");
      var amount = this.props.cartItems.reduce(
        (a, c) => a + c.item_price * c.qty,
        0
      );

      var dinerCity = localStorage.getItem("selectedLocation");
      let cartId = localStorage.getItem("cartRandomStringId");

      return getCouponCodeAppliedAPI(
        this.state.userId,
        this.state.chefId,
        dinerCity,
        amount,
        couponApplied,
        cartId,
        token
      )
        .then((response) => {
          if (response.data.status === 200) {
           /*  this.setState({
              selectedCoupon: couponApplied,
              selectedDiscountCoupon: response.data.discount_amount,
              showFilterFlag: false,
              inputCouponCode: "",
            });
            localStorage.setItem("couponCode", couponApplied);
            localStorage.setItem("couponAmount", response.data.discount_amount); */
            var userId = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
			var cart_id= localStorage.getItem("cartRandomStringId");
			var chef_id  = this.state.chefId;
			var city  = localStorage.getItem("selectedLocation");
			var deliveryCharges  = this.state.deliveryCharges;
			var actualDeliveryCharges  = this.state.actualDeliveryCharges;
			var coupon_code  = '';
			var cart_amount  = '';
			var receipt_number  = '';
			var taxes = this.state.taxAmount;
            let amount = !this.state.selectedCoupon
              ? parseFloat(this.state.dueAmount) * 100
              : parseFloat(
                  this.state.dueAmount - this.state.selectedDiscountCoupon
                ) * 100;
            return getRazorPayOrderIDAPI(userId, token, amount, cart_id, chef_id, city, deliveryCharges, coupon_code, cart_amount, taxes)
              .then((response) => {
                if (response.data.data.status == "created") {
                  this.setState({
                    orderAmount: response.data.data.amount,
                    orderID: response.data.data.id,
                  });
                  this.openCheckout();
                } else {
                  if (response.status === 401) {
                    NotificationManager.error(
                      "Session expired. Please log in.",
                      "Error!"
                    );
                    this.props.removeUserData();
                    this.props.removeChefData();
                    this.props.emptyCart();
                    this.props.removeTimeSlot();
                    localStorage.clear();
                    this.props.history.push("diner-signin-with-otp");
                  }
                }
              })
              .catch(function (error) {});
            /* this.updateOrderOnCartChange(); */
          } else {
            if (response.status === 401) {
              NotificationManager.error(
                "Session expired. Please log in.",
                "Error!"
              );
              this.props.removeUserData();
              this.props.removeChefData();
              this.props.emptyCart();
              this.props.removeTimeSlot();
              localStorage.clear();
              this.props.history.push("/diner-signin-with-otp");
            } else {
              NotificationManager.error(response.data.message, "Error!");
              this.removeCouponApplied();
              this.setState({
                showFilterFlag: false,
                inputCouponCode: "",
              });
              /*  this.updateOrderOnCartChange(); */
            }
          }
        })
        .catch((error) => {
          /* console.log(error); */
        });
    } else {
      console.log("out");
      var userId = localStorage.getItem("userID");
      var token = localStorage.getItem("accessToken");
	  var cart_id= localStorage.getItem("cartRandomStringId");
	  var chef_id  = this.state.chefId;
	  var city  = localStorage.getItem("selectedLocation");
	  var deliveryCharges  = this.state.deliveryCharges;
	  var actualDeliveryCharges  = this.state.actualDeliveryCharges;
	  var coupon_code  = '';
	  var cart_amount  = '';
	  var receipt_number  = '';
	  var taxes = this.state.taxAmount;
			
      let amount = !this.state.selectedCoupon
        ? parseFloat(this.state.dueAmount) * 100
        : parseFloat(this.state.dueAmount - this.state.selectedDiscountCoupon) *
          100;
      return getRazorPayOrderIDAPI(userId, token, amount, cart_id, chef_id, city, deliveryCharges, coupon_code, cart_amount, taxes)
        .then((response) => {
          if (response.data.data.status == "created") {
            this.setState({
              orderAmount: response.data.data.amount,
              orderID: response.data.data.id,
            });
            this.openCheckout();
          } else {
            if (response.status === 401) {
              NotificationManager.error(
                "Session expired. Please log in.",
                "Error!"
              );
              this.props.removeUserData();
              this.props.removeChefData();
              this.props.emptyCart();
              this.props.removeTimeSlot();
              localStorage.clear();
              this.props.history.push("diner-signin-with-otp");
            }
          }
        })
        .catch(function (error) {});
    }
    /*  */
  };
  /* getOrderPlaced function ends here */

  /* getDinerOrderPlaced function starts here */
  getDinerOrderPlaced = (paymentId, orderId, signature) => {
    var takeAway = "";
    if (this.state.showAddress === true) {
      takeAway = 1;
    } else {
      takeAway = 0;
    }
    let itemArray = [];
    this.props.cartItems.forEach((item) => {
      itemArray.push({
        menu_id: item.menu_id,
        item_name: item.item_name,
        item_price: item.item_price,
        actual_price: item.actual_price,
        itemQuantity: item.qty,
      });
    });
    let couponCode = "";
    if (localStorage.getItem("couponCode")) {
      couponCode = localStorage.getItem("couponCode");
    }

    let orderData = {
      //order_number: this.props.orderNumber,
      cart_id: localStorage.getItem("cartRandomStringId"),
      transaction_id: paymentId,
      chef_id: this.state.chefId,
	  chef_discount: this.props.chefData.chefDiscount ? this.props.chefData.chefDiscount : '',
      chef_discount_start_date: this.props.chefData.chefDiscountStartDate ? this.props.chefData.chefDiscountStartDate : '',
      chef_discount_end_date: this.props.chefData.chefDiscountEndDate ? this.props.chefData.chefDiscountEndDate : '',
      chef_discount_flag: this.props.chefData.chefDiscountFlag ? this.props.chefData.chefDiscountFlag : '',
      chef_discount_type: this.props.chefData.chefDiscountType ? this.props.chefData.chefDiscountType : '',
      chef_enable_discount: this.props.chefData.chefEnableDiscount ? this.props.chefData.chefEnableDiscount : '',
      diner_id: this.props.userData.userId,
      amount_due: this.state.orderAmount / 100,
      amount_paid: this.state.orderAmount / 100,
      applied_coupon_code: couponCode,
      order_id: this.state.orderID,
      tax_amount: this.state.taxAmount,
      delivery_charges: this.state.deliveryCharges,
      drop_location: localStorage.getItem("addressSelected"),
      drop_latitude: this.state.latitude,
      drop_longitude: this.state.longitude,
      status: "created",
      special_instructions: this.state.requestDesc,
      is_takeaway_selected: takeAway,
      api_response: JSON.stringify(this.state.razorPayDetail),
      signature: signature,
      order_details: itemArray,
      delivery_partner: this.state.deliveryPartner,
      delivery_partner_type: this.state.deliveryPartnerType,
      selected_time_slot:
        this.props.timeSlot.slotDay + " - " + this.props.timeSlot.slotTime,
      selected_time_slot_from_datetime:
        this.props.timeSlot.slotDay +
        ", " +
        this.props.timeSlot.slotTime.split("-")[0],
      selected_time_slot_to_datetime:
        this.props.timeSlot.slotDay +
        ", " +
        this.props.timeSlot.slotTime.split("-")[1],
      payment_api_status: this.state.paymentStatus,
    };

    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    this.setState({
      isProcessing: true,
    });
    return orderDetailAPI(userId, token, orderData)
      .then((response) => {
        if (response.data.status == 200) {
          NotificationManager.success(
            response.data["message"],
            "Successful!",
            3000
          );
          localStorage.removeItem("cartRandomStringId");
          localStorage.removeItem("couponCode");
          localStorage.removeItem("couponAmount");
          this.props.history.push("/diner-thank-you");
        } else {
          this.setState({
            isProcessing: false,
          });
          NotificationManager.error(response.data["message"], "Error!");
          window.location.reload();
        }
      })
      .catch(function (error) {
        this.setState({
          isProcessing: false,
        });
        /*  console.log(error); */
      });
  };
  /* getDinerOrderPlaced function ends here */

  /* openCheckout function starts here */
  openCheckout = () => {
    let paymentOptions = {
      order_id: this.state.orderID,
      // key: "rzp_test_BoGaOPskqkL01M",
      key: config.razorKey, // 2000 paise = INR 20, amount in paisa
      name: "Chef Pin",
      description: "Purchase Description",
      image: "assets/images/icons/header-logo.svg",

      handler: (response) => {
        this.setState({
          razorPayDetail: response,
        });
        var redirect_url;
        if (
          typeof response.razorpay_payment_id == "undefined" ||
          response.razorpay_payment_id < 1 ||
          !response.razorpay_payment_id
        ) {
          /* redirect_url = "/checkout"; */
        } else {
          this.setState(
            {
              paymentStatus: "Success",
            },
            () =>
              this.getDinerOrderPlaced(
                response.razorpay_payment_id,
                response.razorpay_order_id,
                response.razorpay_signature
              )
          );
        }
      },
      prefill: {
        name: localStorage.getItem("userName"),
        email: localStorage.getItem("userEmail"),
        mobile: localStorage.getItem("userMobile"),
      },
      notes: {
        address: "Hello World",
        cart_id: localStorage.getItem("cartRandomStringId"),
      },
      theme: {
        color: "#F37254",
      },
    };

    let rzp = new window.Razorpay({ ...paymentOptions });
    rzp.on("payment.failed", (response) => {
      this.setState(
        {
          paymentStatus: "Failed",
          razorPayDetail: response,
        },
        () => {
          if (response.error) {
            var takeAway = "";
            if (this.state.showAddress === true) {
              takeAway = 1;
            } else {
              takeAway = 0;
            }
            let itemArray = [];
            this.props.cartItems.forEach((item) => {
              itemArray.push({
                menu_id: item.menu_id,
                item_name: item.item_name,
                item_price: item.item_price,
                actual_price: item.actual_price,
                itemQuantity: item.qty,
              });
            });
            var userId = localStorage.getItem("userID");
            var token = localStorage.getItem("accessToken");
            let orderData = {
              //order_number: this.props.orderNumber,
              cart_id: localStorage.getItem("cartRandomStringId"),
              transaction_id: response.error.metadata
                ? response.error.metadata.payment_id
                : "",
              chef_id: this.state.chefId,
			  chef_discount: this.props.chefData.chefDiscount ? this.props.chefData.chefDiscount : '',
			  chef_discount_start_date: this.props.chefData.chefDiscountStartDate ? this.props.chefData.chefDiscountStartDate : '',
			  chef_discount_end_date: this.props.chefData.chefDiscountEndDate ? this.props.chefData.chefDiscountEndDate : '',
			  chef_discount_flag: this.props.chefData.chefDiscountFlag ? this.props.chefData.chefDiscountFlag : '',
			  chef_discount_type: this.props.chefData.chefDiscountType ? this.props.chefData.chefDiscountType : '',
			  chef_enable_discount: this.props.chefData.chefEnableDiscount ? this.props.chefData.chefEnableDiscount : '',
              diner_id: this.props.userData.userId,
              amount_due: this.state.orderAmount / 100,
              amount_paid: this.state.orderAmount / 100,
              order_id: this.state.orderID,
              tax_amount: this.state.taxAmount,
              delivery_charges: this.state.deliveryCharges,
              drop_location: localStorage.getItem("addressSelected"),
              drop_latitude: this.state.latitude,
              drop_longitude: this.state.longitude,
              status: "created",
              special_instructions: this.state.requestDesc,
              is_takeaway_selected: takeAway,
              api_response: JSON.stringify(this.state.razorPayDetail),
              // signature: signature,
              order_details: itemArray,
              delivery_partner: this.state.deliveryPartner,
              delivery_partner_type: this.state.deliveryPartnerType,
              selected_time_slot:
                this.props.timeSlot.slotDay +
                " - " +
                this.props.timeSlot.slotTime,
              selected_time_slot_from_datetime:
                this.props.timeSlot.slotDay +
                ", " +
                this.props.timeSlot.slotTime.split("-")[0],
              selected_time_slot_to_datetime:
                this.props.timeSlot.slotDay +
                ", " +
                this.props.timeSlot.slotTime.split("-")[1],
              payment_api_status: this.state.paymentStatus,
              payment_api_response: response,
            };
            NotificationManager.error(response.error.description, "Error!");
            /* setTimeout(() => {
              window.location.reload();
            }, 100) */
            return orderDetailAPI(userId, token, orderData).then((response) => {
              let randomId = this.randomString();
              localStorage.setItem("cartRandomStringId", randomId);
              setTimeout(() => {
                window.location.reload();
              }, 100);
            });
          } else {
            this.getDinerOrderPlaced(
              response.error.metadata.payment_id,
              response.error.metadata.order_id,
              ""
            );
          }
          rzp.close();
        }
      );
    });
    rzp.open();
  };
  /* openCheckout function ends here */

  /* showRequestPopup function starts here */
  showRequestPopup = () => {
    this.setState({
      showRequestPopupFlag: true,
    });
  };
  /* showRequestPopup function ends here */

  /* getRequestPopupClose function starts here */
  getRequestPopupClose = () => {
    this.setState({
      showRequestPopupFlag: false,
    });
  };
  /* getRequestPopupClose function ends here */

  /* addRequest function starts here */
  addRequest = (event) => {
    event.preventDefault();
    this.setState({
      showRequestPopupFlag: false,
    });
  };
  /* addRequest function ends here */

  /* getDataClear function starts here */
  getDataClear = (e) => {
    e.preventDefault();
    this.setState({
      requestDesc: "",
    });
  };
  /* getDataClear function ends here */

  /* getInerPage function starts here */
  getInerPage = () => {
    localStorage.setItem("chefID", this.state.chefId);
    var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push(
      "/" + selectedCity + "/chef-inner-homepage/" + this.state.slugUrl
    );
  };
  /* getInerPage function ends here */

  applyCouponCode = (code) => {
    if (this.state.userId) {
      var token = localStorage.getItem("accessToken");
      var amount = this.props.cartItems.reduce(
        (a, c) => a + c.item_price * c.qty,
        0
      );

      var dinerCity = localStorage.getItem("selectedLocation");
      let cartId = localStorage.getItem("cartRandomStringId");

      return getCouponCodeAppliedAPI(
        this.state.userId,
        this.state.chefId,
        dinerCity,
        amount,
        code,
        cartId,
        token
      )
        .then((response) => {
          if (response.data.status === 200) {
            console.log(response.data.data);
            this.setState({
              selectedCoupon: code,
              selectedDiscountCoupon: response.data.discount_amount,
              showFilterFlag: false,
              inputCouponCode: "",
            });
            localStorage.setItem("couponCode", code);
            localStorage.setItem("couponAmount", response.data.discount_amount);
            NotificationManager.success(
              "Coupon Code Applied",
              "Successful!",
              3000
            );
            /* this.updateOrderOnCartChange(); */
          } else {
            if (response.status === 401) {
              NotificationManager.error(
                "Session expired. Please log in.",
                "Error!"
              );
              this.props.removeUserData();
              this.props.removeChefData();
              this.props.emptyCart();
              this.props.removeTimeSlot();
              localStorage.clear();
              this.props.history.push("/diner-signin-with-otp");
            } else {
              NotificationManager.error(response.data.message, "Error!");
              this.setState({
                showFilterFlag: false,
                inputCouponCode: "",
              });
              /*  this.updateOrderOnCartChange(); */
            }
          }
        })
        .catch((error) => {
          /* console.log(error); */
        });
    } else {
      NotificationManager.error(
        "Please login before availing coupon.",
        "Error!"
      );
      this.setState({
        showFilterFlag: false,
      });
    }
  };

  applyTakeawayCouponCode = () => {
    let code = localStorage.getItem("couponCode");
    var token = localStorage.getItem("accessToken");
    var amount = this.props.cartItems.reduce(
      (a, c) => a + c.item_price * c.qty,
      0
    );

    var dinerCity = localStorage.getItem("selectedLocation");
    let cartId = localStorage.getItem("cartRandomStringId");

    return getCouponCodeAppliedAPI(
      this.state.userId,
      this.state.chefId,
      dinerCity,
      amount,
      code,
      cartId,
      token
    )
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response.data.data);
          this.setState({
            selectedCoupon: code,
            selectedDiscountCoupon: response.data.discount_amount,
            showFilterFlag: false,
            inputCouponCode: "",
          });
          localStorage.setItem("couponCode", code);
          localStorage.setItem("couponAmount", response.data.discount_amount);
        } else {
          NotificationManager.error(response.data.message, "Error!");
          this.removeCouponApplied();
          this.setState({
            showFilterFlag: false,
            inputCouponCode: "",
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };

  getCouponData = () => {
    this.getCouponCodeList();
    this.setState({
      showFilterFlag: true,
    });
  };

  expandedCouponText = (code) => {
    let couponData = this.state.couponListArr.filter(function (coupon) {
      return coupon.coupon_code === code;
    });
    if (couponData[0]["coupon_code"] === code) {
      this.setState({
        couponDescFlag: true,
        couponDesc: code,
      });
    }
  };

  removeCouponApplied = () => {
    localStorage.removeItem("couponCode");
    localStorage.removeItem("couponAmount");
    this.setState({
      selectedCoupon: "",
      selectedDiscountCoupon: "",
      removeCouponFlag: true,
    });
    let token = localStorage.getItem("accessToken");
    let cartId = localStorage.getItem("cartRandomStringId");

    return removeCouponCodeAPI(this.state.userId, token, cartId)
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response.data.data);
        } else {
          NotificationManager.error(response.data.message, "Error!");
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    const { formErrors } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : this.state.isProcessing ? (
          <ProcessingDiv />
        ) : this.props.cartItems.length ? (
          <section className="checkout">
            <div className="container">
              <h5 className="d-none d-md-block">CHECKOUT</h5>
              <h6>Ordering from</h6>
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <div className="card_wrap">
                    <div className="img_wrap">
                      <img
                        src={
                          this.state.chefProfileImage !== ""
                            ? this.state.chefProfileImage
                            : "assets/images/noimage.png"
                        }
                        alt="Checkout Image"
                      />
                    </div>
                    <div className="text">
                      <h6 onClick={() => this.getInerPage()}>
                        {this.state.chefName}
                      </h6>
                      <p>{this.state.chefAddress}</p>
                      <div className="time_slot">
                        <p className="time_slot_icon">
                          <img
                            src="assets/images/icons/chronometer.svg"
                            alt="Chronometer Icons"
                            className="time_icon"
                          />
                          Time Slot
                        </p>
                        <p className="fw_500">
                          {this.props.timeSlot.slotDay} -
                          {this.props.timeSlot.slotTime}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="dash_line" />
                  <ul className="order_list">
                    {this.props.cartItems.length
                      ? this.props.cartItems.map((item, it) => {
                          return (
                            <li key={"vbts" + it}>
                              <h6>
                                {/* <img src="assets/images/icons/veg-icon.svg" alt="Veg Icon" /> */}
                                {item.diet_type === "Non Veg" ? (
                                  <div className="icon_logo">
                                    <img
                                      src="assets/images/icons/non-veg.svg"
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                {item.diet_type === "Veg" ? (
                                  <div className="icon_logo">
                                    <img
                                      src="assets/images/icons/veg-icon.svg"
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                {item.diet_type === "Contains Egg" ? (
                                  <div className="icon_logo">
                                    <img
                                      src="assets/images/icons/egg-icon.svg"
                                      alt=""
                                    />
                                  </div>
                                ) : null}
                                {item.item_name}  
								{this.state.chefDiscountFlag == true ? 
								  ( 
									<span> INR{" "} 
										<del class="strikeprice">
											{ item.actual_price }
										</del>{" "}
										{item.item_price}{" "}				
										<span class="strikepricediscount">{"("}{ this.props.chefData.chefDiscount }{"% Off)"}</span>
									</span>
								   )  : (
									<span> INR{" "} {item.item_price} </span>
								   )}
                              </h6>
                              <div className="button_add color_fill">
                                <form id="myform" method="POST" action="#">
                                  <input
                                    type="button"
                                    className="qtyminus"
                                    field="quantity"
                                    value="-"
                                    onClick={(e) =>
                                      this.removeCartItem(e, item)
                                    }
                                  />
                                  <input
                                    type="text"
                                    name="quantity"
                                    className="qty"
                                    value={item.qty}
                                    disabled={true}
                                  />
                                  <input
                                    type="button"
                                    className="qtyplus"
                                    field="quantity"
                                    data-toggle="modal"
                                    value="+"
                                    onClick={(e) => this.addCartItem(e, item)}
                                  />
                                </form>
                              </div>
                            </li>
                          );
                        })
                      : null}
                  </ul>
                  <hr className="dash_line" />
                  {this.state.requestDesc ? (
                    <div className="request_cust_btn justify-content-start">
                      <p>{this.state.requestDesc}</p>
                      <button
                        className="clear_btn"
                        onClick={(e) => this.getDataClear(e)}
                      >
                        Remove
                      </button>
                    </div>
                  ) : (
                    <button
                      className="request_cust_btn"
                      data-toggle="modal"
                      data-target="#Enquire_Party_Orders"
                      onClick={this.showRequestPopup}
                    >
                      <img
                        src="assets/images/icons/post-it.svg"
                        alt="Post It"
                      />
                      Request Customization
                    </button>
                  )}
                  {this.props.chefData.chefTakeaway &&
                  this.props.chefData.chefTakeaway == "1" ? (
                    <hr className="dash_line" />
                  ) : null}
                  {this.props.chefData.chefTakeaway &&
                  this.props.chefData.chefTakeaway == "1" ? (
                    <div className="add_wrap">
                      <h6>
                        Do you want to do a Take-away?
                        <label className="custome_check">
                          <input
                            type="checkbox"
                            onClick={this.handleCheckAddress}
                            checked={this.state.isTakeawayChoice}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </h6>
                      {this.state.showAddress ? (
                        <p>You have to reach the chef at the below address :</p>
                      ) : null}
                      {this.state.showAddress ? (
                        <p className="add">{this.state.chefFullAddress}</p>
                      ) : null}
                      {this.state.showAddress ? (
                        <a
                          href={
                            "https://www.google.com/maps/dir/" +
                            this.state.modifyDiner +
                            "/" +
                            this.state.modifyChef
                          }
                          target="_blank"
                          className="view_map_link"
                        >
                          View on Google Maps
                        </a>
                      ) : null}
                    </div>
                  ) : null}
                  <hr className="dash_line mt-sm-4 d-block d-lg-none" />
                </div>
                <div className="col-lg-6">
                  <div className="bill_wrap pt-0">
                    <div className="request_cust_btn view_coupons justify-content-start">
                      <p>
                        <img src="/assets/images/icons/offer-icon.svg" />
                        {this.state.selectedCoupon
                          ? this.state.selectedCoupon
                          : "offers"}
                        {this.state.selectedCoupon ? (
                          <span className="applyText">Applied</span>
                        ) : null}
                      </p>
                      {this.state.selectedCoupon ? (
                        <button
                          className="clear_btn"
                          onClick={this.removeCouponApplied}
                        >
                          Remove
                        </button>
                      ) : (
                        <button
                          className="clear_btn"
                          onClick={this.getCouponData}
                        >
                          View coupons
                        </button>
                      )}
                    </div>

                    <hr className="dash_line mb-2"></hr>
                    <h6>Bill Details</h6>
                    <ul className="list_price">
                      <li>
                        Item Total
                        <span className="price">
                          INR{" "}
                          {this.props.cartItems.reduce(
                            (a, c) => a + c.item_price * c.qty,
                            0
                          )}
                        </span>
                      </li>
                      <li>
                        Taxes and charges
                        <span className="price">
                          INR{" "}
                          {this.state.taxAmount !== ""
                            ? this.state.taxAmount
                            : 0}
                        </span>
                      </li>
                      {this.state.mapPopupFlag ? <>
						  {this.state.deliveryCharges < this.state.actualDeliveryCharges
							? (
								<li>
								  Delivery Partner Fee
								  <span className="price">
									<del>INR{" "}
									{this.state.actualDeliveryCharges !== ""
									  ? Math.round(this.state.actualDeliveryCharges)
									  : 0}</del> INR{" "}
									{this.state.deliveryCharges !== ""
									  ? Math.round(this.state.deliveryCharges)
									  : 0}
								  </span>
								</li>
							  )
							: (
								<li>
								  Delivery Partner Fee
								  <span className="price">
									INR{" "}
									{this.state.deliveryCharges !== ""
									  ? Math.round(this.state.deliveryCharges)
									  : 0}
								  </span>
								</li>
							  )
						  }
						</> : (
                        <li>Delivery Charges (To be calculated)</li>
                      )}
                      {this.state.discountCharges > 0 && (
                        <li>
                          Discount
                          <span className="price">
                            -INR {Math.round(this.state.discountCharges)}
                          </span>
                        </li>
                      )}
                      {this.state.selectedCoupon && (
                        <li>
                          Coupon Discount
                          <span className="price">
                            -INR {Math.round(this.state.selectedDiscountCoupon)}
                          </span>
                        </li>
                      )}
                      {this.state.removeCouponFlag &&
                      !this.state.selectedCoupon ? (
                        <li className="grand_total">
                          Grand Total
                          <span className="price">
                            <span>INR</span>{" "}
                            {this.state.mapPopupFlag
                              ? parseFloat(this.state.dueAmount)
                              : this.props.cartItems.reduce(
                                  (a, c) => a + c.item_price * c.qty,
                                  0
                                ) + this.state.taxAmount}
                          </span>
                        </li>
                      ) : (
                        <li className="grand_total">
                          Grand Total
                          <span className="price">
                            <span>INR</span>{" "}
                            {this.state.mapPopupFlag
                              ? parseFloat(
                                  this.state.dueAmount -
                                    this.state.selectedDiscountCoupon
                                )
                              : this.props.cartItems.reduce(
                                  (a, c) => a + c.item_price * c.qty,
                                  0
                                ) +
                                this.state.taxAmount -
                                this.state.selectedDiscountCoupon}
                          </span>
                        </li>
                      )}
                    </ul>
                    <hr className="dash_line" />
                    {this.state.showAddress ? null : this.state.mapPopupFlag ? (
                      <div className="delivery">
                        <p className="d-flex justify-content-between">
                          Delivery at :
                          <span
                            className="fw_500 cursor-qty"
                            onClick={this.changeAddress}
                          >
                            CHANGE
                          </span>
                        </p>
                        <p className="address dropdown">
                          <img
                            src="assets/images/icons/house-orange.svg"
                            alt="House Icon"
                          />
                          <span className="home">
                            {localStorage.getItem("addressDetailType")
                              ? localStorage.getItem("addressDetailType")
                              : localStorage.getItem("addressType")}
                          </span>
                          <span>
                            {localStorage.getItem("landmark")
                              ? localStorage.getItem("flatNo") +
                                ", " +
                                localStorage.getItem("landmark") +
                                ", " +
                                localStorage.getItem("addressSelected")
                              : localStorage.getItem("flatNo")
                              ? localStorage.getItem("flatNo") +
                                ", " +
                                localStorage.getItem("addressSelected")
                              : localStorage.getItem("addressSelected")}
                          </span>
                        </p>
                      </div>
                    ) : null}
                    {localStorage.getItem("userID") == null ? (
                      <p className="to_place_text">
                        To place your order now, log in to your existing account
                        or sign up.
                      </p>
                    ) : (
                      <p className="to_place_text">
                        You are logged in from{" "}
                        {localStorage.getItem("userEmail")}
                      </p>
                    )}
                    {localStorage.getItem("userID") == null ? (
                      <div className="btn-group sign_log_btn">
                        <div>
                          <Link
                            className="btn"
                            to={"/diner-signup-mobile?checkout"}
                          >
                            Sign Up
                            <img
                              src="assets/images/icons/white-full-fill-right-arrow.svg"
                              alt="Icon"
                            />
                          </Link>
                        </div>
                        <div>
                          <Link
                            className="btn"
                            to={"/diner-signin-with-otp?checkout"}
                          >
                            Log In
                            <img
                              src="assets/images/icons/white-full-fill-right-arrow.svg"
                              alt="Icon"
                            />
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="btn-group sign_log_btn single">
                        <div>
                          {this.state.mapPopupFlag ? (
                            <button
                              type="button"
                              disabled={
                                !this.props.cartItems.length ||
                                this.state.payButtonDisabled
                              }
                              className="btn"
                              onClick={this.getOrderPlaced}
                            >
                              Proceed to Pay
                              <img
                                src="assets/images/icons/white-full-fill-right-arrow.svg"
                                alt="Icon"
                              />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn"
                              onClick={this.openMapPopup}
                            >
                              Add location details
                              <img
                                src="assets/images/icons/white-full-fill-right-arrow.svg"
                                alt="Icon"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="note_checkout">
                      <p>
                        <span className="fw_500 d-block">NOTE :</span>
                        You will receive a confirmation from the Home Chef for
                        the order you have placed. If the Home Chef is not
                        available/cannot deliver the order and the order is
                        cancelled then you will receive a full refund.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="not_found empty_section">
            <div className="container text-center">
              <div className="res_back_btn_wrap text-left p-0">
                <Link
                  to={"/" + this.state.selectedCity + "/diner-homepage"}
                  className="res_back_btn"
                >
                  <img
                    src="assets/images/icons/left-arrow-black.svg"
                    alt="left-arrow-black"
                  />
                </Link>
              </div>
              <img
                src="assets/images/icons/your-cart-is-empty.svg"
                alt="Not Found"
                className="img-fluid"
              />
              <h6>YOUR CART IS EMPTY</h6>
              <p>
                Go on and add some delicious dishes <br />
                from the best home chefs
              </p>
              <Link
                to={"/" + this.state.selectedCity + "/diner-homepage"}
                className="btn"
              >
                GO BACK TO HOMEpage
              </Link>
            </div>
          </section>
        )}

        <div className="nofooter">
          <WhatsAppButton />
        </div>

        <DinerMenuTab />
        {this.state.showRequestPopupFlag ? (
          <div
            className={
              this.state.showRequestPopupFlag
                ? "pins_achieved_modal modal fade enquire_orders request_custom bottom_pop show d-block"
                : "pins_achieved_modal modal fade enquire_orders request_custom bottom_pop"
            }
            id="Enquire_Party_Orders"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="pins_achievedTitle"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header justify-content-start flex-column">
                  <h5 className="modal-title ml-0" id="pins_achievedTitle">
                    REQUEST CUSTOMIZATION
                  </h5>
                  <p>Add special requests if any</p>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.getRequestPopupClose}
                  >
                    <img
                      src="assets/images/icons/black-close.svg"
                      alt="Close"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="wrap-modal-slider">
                    <form>
                      <div className="form-group mb-2">
                        <textarea
                          className="form-control"
                          id="Typeyourenquiry"
                          rows="3"
                          name="requestDesc"
                          onChange={this.handleRequestTextareaChange}
                          value={this.state.requestDesc}
                          maxLength="100"
                        ></textarea>
                        <label className="bottom_label text-right d-block">
                          {this.state.chars_left} characters
                        </label>
                      </div>

                      <div className="note_checkout form-group mb-4">
                        <p>
                          <span className="fw_500 d-block">NOTE :</span>
                          You will receive a confirmation from the Home Chef for
                          the order you have placed. If the Home Chef is not
                          available/cannot deliver the order and the order is
                          cancelled then you will receive a full refund.
                        </p>
                      </div>
                      <div className="form-group text-center mb-0">
                        <button
                          className="btn"
                          type="button"
                          onClick={this.addRequest}
                        >
                          add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showRequestPopupFlag || this.state.showFilterFlag ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
        {this.state.showMap == true ? (
          <div
            className="modal bottom_pop fade map_pop show"
            id="bottom_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="bottom_modal"
            style={{ paddingRight: "17px", display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {/* <div className="add_another_btn close_view">
                    <button
                        type="button"
                        className="close plus_icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeMapPopup}>

                        </button>
                </div> */}
                  <button
                    type="button"
                    className="close close_view"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closeMapPopup}
                  >
                    <img src="/assets/images/icons/x-button.svg" alt="Close" />
                  </button>
                  <div className="map-img">
                    <Map
                      google={this.props.google}
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "300px",
                      }}
                      zoom={15}
                      initialCenter={{
                        lat: this.state.markers[0].position.lat,
                        lng: this.state.markers[0].position.lng,
                      }}
                    >
                      {this.state.markers.map((marker, index) => (
                        <Marker
                          key={"bvcdert" + index}
                          position={marker.position}
                          draggable={true}
                          onDragend={this.moveMarker.bind(marker)}
                          name={marker.name}
                          icon={"/map-marker.png"}
                        />
                      ))}
                    </Map>
                  </div>
                  <div className="more-details">
                    <div className="form_wrap edit-delivery-address">
                      <form>
                        <div className="address-header">
                          <p className="small-text">YOUR LOCATION</p>
                          <p className="text-med address-change fw_500">
                            {this.state.address}
                            <button
                              type="submit"
                              data-dismiss="modal"
                              aria-label="Close"
                              className="change-text"
                              onClick={this.changeAddress}
                            >
                              Change
                            </button>
                          </p>
                          <h2>Add More Details</h2>
                        </div>
                        <div className="form-group">
                          <label>Enter Building/House/Flat/Block No.*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="fullAddress"
                            value={this.state.fullAddress}
                            onChange={this.handleMapPopupChange}
                            maxLength="40"
                          />
                          <small className="text-danger">
                            {formErrors.fullAddress}
                          </small>
                        </div>
                        <div className="form-group">
                          <label>Enter Landmark</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="landMark"
                            value={this.state.landMark}
                            onChange={this.handleMapPopupChange}
                            maxLength="40"
                          />
                        </div>
                        <div className="form-group">
                          <label>Save as</label>
                          <div className="radio-box">
                            <ul className="radio_list">
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Home
                                    <input
                                      type="radio"
                                      name="Home"
                                      onChange={this.handleRadioChange}
                                      value="Home"
                                      checked={
                                        this.state.addressSaveAs === "Home" ||
                                        this.state.addressSaveAs === "home"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Work
                                    <input
                                      type="radio"
                                      name="Work"
                                      onChange={this.handleRadioChange}
                                      value="Work"
                                      checked={
                                        this.state.addressSaveAs === "Work" ||
                                        this.state.addressSaveAs === "work"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Other
                                    <input
                                      type="radio"
                                      name="Other"
                                      onChange={this.handleRadioChange}
                                      value="Other"
                                      checked={
                                        this.state.addressSaveAs === "Other" ||
                                        this.state.addressSaveAs === "other"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {this.state.addressSaveAs == "Other" ||
                          this.state.addressSaveAs === "other" ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="eg. Dad's Place"
                              name="otherText"
                              value={this.state.otherText}
                              onChange={this.handleMapPopupChange}
                              maxLength="20"
                            />
                          ) : null}
                        </div>
                        <div className="form-group save-btn mb-0">
                          <button
                            type="submit"
                            className="btn"
                            onClick={this.saveData}
                          >
                            Save and Proceed
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showMap == true ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
        {this.state.showFilterFlag === true ? (
          <div
            className="modal bottom_pop fade map_pop discount_pop tabs_modal_wrap show"
            id="bottom_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="bottom_modal"
            style={{ paddingRight: "17px", display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <h5 className="text-uppercase">
                    Apply Coupon
                    <button
                      type="button"
                      className="close_icon"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        this.setState({
                          showFilterFlag: false,
                        })
                      }
                    >
                      <img
                        src="assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                  </h5>
                  <div className="form_group_wrap mb-4">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        name="inputCouponCode"
                        value={this.state.inputCouponCode}
                        onChange={this.handleMapPopupChange}
                        maxLength="40"
                        style={{ textTransform: "uppercase" }}
                      />
                      <button
                        type="submit"
                        className={
                          !this.state.inputCouponCode
                            ? "apply disableApply"
                            : "apply"
                        }
                        onClick={() =>
                          this.applyCouponCode(this.state.inputCouponCode)
                        }
                      >
                        Apply
                      </button>
                    </div>
                    <small className="text-danger">
                      {formErrors.inputCouponCode}
                    </small>
                    <small className="form-text text-left text-muted">
                      Enter coupon code
                    </small>
                  </div>
                  <div className="tabs_modal pt-4 border-0">
                    {this.state.couponListArr.length ? (
                      <div className="discount_list">
                        <h6 className="color_C07">Available offers</h6>
                        <ul data-simplebar>
                          {this.state.couponListArr.map((coupon, c) => {
                            return (
                              <li key={coupon.coupon_code}>
                                <h6>{coupon.title}</h6>
                                {coupon.coupon_code !==
                                this.state.couponDesc ? (
                                  <p>
                                    {coupon.description.substr(0, 100)}
                                    {coupon.description.length > 100 ? (
                                      <span
                                        onClick={() =>
                                          this.expandedCouponText(
                                            coupon.coupon_code
                                          )
                                        }
                                      >
                                        &nbsp;&nbsp;View Details.
                                      </span>
                                    ) : null}
                                  </p>
                                ) : null}
                                {coupon.coupon_code ===
                                this.state.couponDesc ? (
                                  <p>{coupon.description}</p>
                                ) : null}
                                <div className="discount_code_wrap">
                                  <span className="discount_code">
                                    {coupon.coupon_code}
                                  </span>
									{coupon.active ? (
									   <button
										type="submit"
										className="apply"
										onClick={() =>
										  this.applyCouponCode(coupon.coupon_code)
										}
									  >
										Apply
									  </button>
									) : null}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      <div className="discount_list">
                        <h6 className="color_C07">No offers available</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  timeSlot: state.timeSlot.items,
  chefData: state.chef.items,
  userData: state.user.items,
  orderNumber: state.orderNumber.items,
});

const googleApiWrapper = GoogleApiWrapper({
  apiKey: config.placesKey,
})(CheckoutPage);

export default connect(mapStateToProps, {
  increaseQuantity,
  decreaseQuantity,
  addOrderNumber,
  removeOrderNumber,
  removeUserData,
  emptyCart,
  removeChefData,
  removeTimeSlot,
})(googleApiWrapper);
