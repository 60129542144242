/*
#############################################################################
# Name : dinerVerifyMobileOTP.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to verify the entered mobile number
#############################################################################
*/
import React, { Component } from "react";
import OtpInput from "react-otp-input";
import {
  getMobileLoginAPI,
  getMobileLoginVerfiyAPI,
  getUserSummaryAPI,
  getUserLocationAPI,
} from "../../../core/services/APIservice";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import DinnerHeader from "../../../components/dinnerHeader";
import WhatsAppButton from "../../../components/whatsappButton";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { saveUserData } from "../../../redux/actions/authActions";

class DinerVerifyMobileOTPPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      userMobileNo: "",
      minutes: 1,
      seconds: 0,
      buttonText: "verify otp",
      buttonDisabled: false,
      resendText: "re-send OTP",
      resendDisabled: false,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var mobileNo = localStorage.getItem("userMobile");
    this.setState({ userMobileNo: mobileNo });
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  /* resendOTP function starts here */
  resendOTP = () => {
    this.setState({
      otp: "",
      resendText: "re-sending OTP...",
      resendDisabled: true,
    });
    return getMobileLoginAPI(this.state.userMobileNo)
      .then((response) => {
        this.setState({
          resendText: "re-send OTP",
          resendDisabled: false,
        });
        this.setState({
          minutes: 1,
          seconds: 0,
        });
        this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state;
          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.myInterval);
            } else {
              this.setState(({ minutes }) => ({
                minutes: minutes - 1,
                seconds: 59,
              }));
            }
          }
        }, 1000);
        NotificationManager.success("OTP Sent", "Success!");
      })
      .catch((error) => {
        this.setState({
          resendText: "re-send OTP",
          resendDisabled: false,
        });
      });
  };
  /* resendOTP function ends here */

  /* clearotp function starts here */
  clearotp = (event) => {
    event.preventDefault();
    this.setState({ otp: "" });
  };
  /* clearotp function ends here */

  /* handleChange function starts here */
  handleChange = (otp) => {
    this.setState({ otp });
  };
  /* handleChange function ends here */

  /* verfiyOTP function starts here */
  verfiyOTP = (event) => {
    event.preventDefault();
    this.recaptcha.execute();
    this.setState({
      buttonText: "Verifying OTP...",
      buttonDisabled: true,
    });
    return getMobileLoginVerfiyAPI(this.state.userMobileNo, this.state.otp)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            buttonText: "verify otp",
            buttonDisabled: false,
          });
          if (
            this.props.location.search === "?checkout" && this.props.location.search === "?chef-innerpage" && (response.data.is_chef || response.data.is_admin)
          ) {
            NotificationManager.error(
              "This Home Chef ID already exists.",
              "Error!"
            );
          } else {
            var token = response.data["data"]["api_access_token"];
            var userId = response.data["data"]["user"]["user_id"];
            var userEmail = response.data["data"]["user"]["email"];
            var userName = response.data["data"]["user"]["name"];
            var userMobile = response.data["data"]["user"]["mobile_number"];
            var image = response.data["data"]["user"]["profile_image"];
            var verified = response.data["data"]["profile_verified"];
            var membership = response.data.membership;
			var mousbmitted = ( response.data.mou_submitted == 0 || 
							response.data.is_profile_submitted == '' || 
							response.data.is_profile_submitted == null || response.data.is_profile_submitted == 0 ) ? false : true;
            localStorage.setItem("userID", userId);
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("userName", userName);
            localStorage.setItem("userImage", image);
            localStorage.setItem("accessToken", token);
            localStorage.setItem("userMobile", userMobile);
            localStorage.setItem("userVerified", verified);
            localStorage.setItem("userMemberShip", membership);
			localStorage.setItem('mouSubmitted', mousbmitted);
            let data = {
              userType: response.data.is_chef ? "Chef" : "Diner",
              userId: response.data["data"]["user"]["user_id"],
              userEmail: response.data["data"]["user"]["email"],
              userName: response.data["data"]["user"]["name"],
              userMobile: response.data["data"]["user"]["mobile_number"],
              image: response.data["data"]["user"]["profile_image"],
              verifid: response.data["data"]["user"]["profile_verified"],
              accessToken: response.data["data"]["api_access_token"],
              isAvailable: response.data.is_available,
			  isPublished: response.data.is_published,
              isMarketingPackage: response.data.is_marketing_package,
              isRegistrationPackage: response.data.is_registration_package,
			  auditMe: response.data.a_m_a_enabled,
			  mouSubmitted: response.data.mou_submitted,
              isProfileSubmitted: response.data.is_profile_submitted,
              membership: response.data.membership
                ? response.data.membership
                : "",
            };
            this.props.saveUserData(data);
            /*  if (response.data["data"]["profile_verified"] == "0") {
              this.props.history.push("/user-details");
            } else */ if (
              response.data["data"]["profile_verified"] == 1 && response.data.is_published == '1'
            ) {
              if (response.data.membership == "basic") {
                this.props.history.push("/chef-membership");
              } else {
                this.props.history.push("/chef-dashboard");
              }
            } else {
              if (
                response.data.is_admin == false &&
                response.data.is_chef == true
              ) {
				var token = response.data["data"]["api_access_token"];
				var userId = response.data["data"]["user"]["user_id"];
				var userEmail = response.data["data"]["user"]["email"];
				var userName = response.data["data"]["user"]["name"];
				var userMobile = response.data["data"]["user"]["mobile_number"];
				var image = response.data["data"]["user"]["profile_image"];
				return getUserSummaryAPI(userId, token)
				.then((response) => {
				  if (response.data["status"] === 200) {
					this.setState({
					  userAboutYou: response.data["data"]["about_average"],
					  userBusiness: response.data["data"]["business_average"],
					  userKitchen: response.data["data"]["kitchen_average"],
					  userMenu:
						response.data["data"]["number_of_uploaded_menu"],
					});
					var setStatus = false;

					if (
						response.data['data']['about_average'] > 0 && 
						response.data['data']['business_average'] > 0 && 
						response.data['data']['kitchen_average'] > 0 && 
						response.data['data']['number_of_uploaded_menu'] > 0 && 
						response.data['data']['is_mou_submitted'] > 0 && 
						response.data['data']['profile_submitted_at'] != '' && 
						response.data['data']['profile_submitted_at'] != null && 
						(response.data['data']['registration_package'] == '' || 
						response.data['data']['registration_package'] == null) && 
						setStatus === false
					) {
						localStorage.setItem('currentStatus', 7);
						setStatus = true;
					}if (
						response.data['data']['about_average'] > 0 && 
						response.data['data']['business_average'] > 0 && 
						response.data['data']['kitchen_average'] > 0 && 
						response.data['data']['number_of_uploaded_menu'] > 0 && 
						(response.data['data']['is_mou_submitted'] == 0 || 
						response.data['data']['profile_submitted_at'] == '' || 
						response.data['data']['profile_submitted_at'] == null) && 
						setStatus === false
					) {
						localStorage.setItem('currentStatus', 6);
						setStatus = true;
					}else if (
					  response.data["data"]["about_average"] < 100 &&
					  setStatus === false
					) {
					  localStorage.setItem("currentStatus", 1);
					  setStatus = true;
					} else if (
					  response.data["data"]["business_average"] < 100 &&
					  setStatus === false
					) {
					  localStorage.setItem("currentStatus", 2);
					  setStatus = true;
					} else if (
						response.data['data']['kitchen_average'] < 100 &&
						setStatus === false
					  ) {
						localStorage.setItem('currentStatus', 3);
						setStatus = true;
					} else if(response.data['data']['number_of_uploaded_menu'] < 1) {
						localStorage.setItem('currentStatus', 4);
					}else{
						localStorage.setItem('currentStatus', 7);
					}
					localStorage.setItem("userID", userId);
					localStorage.setItem("userEmail", userEmail);
					localStorage.setItem("userName", userName);
					localStorage.setItem("userImage", image);
					localStorage.setItem("accessToken", token);
					localStorage.setItem("userMobile", userMobile);
					
					this.props.history.push("/user-details");
				  } else {
					NotificationManager.error(
					  response.data["message"],
					  "Error!"
					);
				  }
				})
				.catch((error) => {
				  console.log(error);
				});
                //this.props.history.push("/user-details");
              } else {
                if (response.data["data"]["location"] == null) {
                  this.props.history.push("/pick-city");
                } else {
                  if (this.props.location.search === "?checkout") {
                    return getUserLocationAPI(
                      localStorage.getItem("userID"),
                      localStorage.getItem("accessToken"),
                      localStorage.getItem("latitude"),
                      localStorage.getItem("longitude"),
                      localStorage.getItem("selectedLocationName"),
                      localStorage.getItem("addressSelected"),
                      localStorage.getItem("pincode"),
                      localStorage.getItem("addressType"),
                      localStorage.getItem("addressDetailType"),
                      localStorage.getItem("flatNo"),
                      localStorage.getItem("landmark")
                    )
                      .then((response) => {
                        if (response.data["status"] === 200) {
                          localStorage.setItem("prevLocationId", response.data.diner_location_id);
                          NotificationManager.success(
                            response.data["message"],
                            "Successful!",
                            3000
                          );
                          this.props.history.push("/checkout");
                        } else {
                          NotificationManager.error(
                            response.data["message"],
                            "Error!"
                          );
                        }
                      })
                      .catch((error) => {
                        /* console.log(error); */
                      });
                  } else {
                    var address = response.data["data"]["location"];
                    var addressType = response.data["data"]["type"];
                    var latitude = response.data["data"]["latitude"];
                    var longtitude = response.data["data"]["longitude"];
                    var city = response.data["data"]["city"];
                    var flatNo = response.data["data"]["address_line1"];
                    var landmark = response.data["data"]["address_line2"];
                    var otherType = response.data["data"]["location_title"];
                    localStorage.setItem("flatNo", flatNo);
                    localStorage.setItem('landmark', landmark);
                    localStorage.setItem("addressSelected", address);
                    localStorage.setItem("addressType", addressType);
                    localStorage.setItem("latitude", latitude);
                    localStorage.setItem("longitude", longtitude);
                    localStorage.setItem("selectedLocation", city);
                    localStorage.setItem("selectedLocationName", city);
                    if (otherType) {
                      localStorage.setItem("addressDetailType", otherType);
                    }
                    var selectedCity = config.slugify(city);
                    if(selectedCity == localStorage.getItem('chefCity')){
                      this.props.history.push("/"+localStorage.getItem('chefCity') +'/chef-inner-homepage/' + localStorage.getItem('chefSlug'));
                    } else {
                      if (this.props.location.search !== "" && this.props.location.search.indexOf("redirect=") !== -1) {
                        this
                          .props
                          .history
                          .push('/' + this.props.location.search.split("=")[1]);
                      } else {
                        this.props.history.push("/" + selectedCity + "/diner-homepage");
                      }
                    }
                  }
                }
              }
            }
            NotificationManager.success(
              "Welcome back to CHEFPIN " + userName,
              "Successful!",
              3000
            );
          }
        } else {
          this.setState({
            buttonText: "verify otp",
            buttonDisabled: false,
          });
          this.setState({ otp: "" });
          this.recaptcha.reset();
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          buttonText: "verify otp",
          buttonDisabled: false,
        });
        this.recaptcha.reset();
        NotificationManager.error("Wrong OTP", "Error!");
      });
  };
   /* verfiyOTP function ends here */

   /* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    const { minutes, seconds } = this.state;
    if (!localStorage.getItem("userMobile")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        <section className="sign_up_chef verification">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-5 d-flex d-xl-block align-items-center justify-content-center justify-content-lg-start">
                <div className="text">
                  <h3 className="color_C07">verification</h3>
                  <div className="profile_img">
                    <div className="inner">
                      <img
                        src="assets/images/icons/verification.png"
                        alt="Verification Images"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <h5>Verify with OTP</h5>
                  <p className="opt_text">Sent to {this.state.userMobileNo}</p>
                  <div className="form_wrap">
                    <form>
                      <div className="form-group">
                        <label className="otp_label">
                          Enter the 6 digit OTP
                        </label>
                        <OtpInput
                          containerStyle={"otp_input_wrap"}
                          inputStyle={"form-control"}
                          value={this.state.otp}
                          onChange={this.handleChange}
                          numInputs={6}
                          shouldAutoFocus
                          isInputNum={true}
                          separator={false}
                        />
                      </div>
                    </form>
                  </div>
                  <p className="resend_otp_text color_C07 mb-0">
                    OTP will expire in
                    <span className="color_036 ml-2">
                      {minutes > 9 ? minutes : "0" + minutes}:
                      {seconds > 9 ? seconds : "0" + seconds}
                    </span>
                  </p>
                  <div className="btn-group opt_btn_grp">
                    <button
                      className="btn"
                      disabled={
                        this.state.otp.toString().length < 6 ||
                        this.state.buttonDisabled
                      }
                      onClick={this.verfiyOTP}
                    >
                      {this.state.buttonText}
                    </button>
                    <button
                      className="btn previous_btn"
                      onClick={this.resendOTP}
                      disabled={
                        minutes > 0 || seconds > 0 || this.state.resendDisabled
                      }
                    >
                      {this.state.resendText}
                    </button>
                  </div>
                  <Recaptcha
                    ref={(ref) => (this.recaptcha = ref)}
                    sitekey={config.siteKey}
                    onResolved={this.onResolved}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-7 align-items-center d-none d-md-flex">
                <div className="img_box">
                  <img
                    src="assets/images/sign-up-as-a-chef/sign-up-img-verification.png"
                    alt="sign up img verification"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="nofooter">
          <WhatsAppButton />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.user.items,
});

export default connect(mapStateToProps, { saveUserData })(
  DinerVerifyMobileOTPPage
);
