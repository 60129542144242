/*
#############################################################################
# Name : collections.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the collections data 
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import LoadingDiv from "../../../../components/LoadingDiv";
import {
  collectionDetialAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
} from "../../../../core/services/Phase2APIservice";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../../../config/config";
import Slider from "react-slick";
class Collections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      data: localStorage.getItem("selectedCollection"),
      images: localStorage.getItem("selectedCollectionImage"),
      chefArr: [],
      errorStatus: false,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getData(); // Collection data is fetched
  }

  /* getData function starts here */
  getData = () => {
    var city = localStorage.getItem("selectedLocationName");
    var pincode = localStorage.getItem("pincode");
    var latitude = localStorage.getItem("latitude");
    var longitude = localStorage.getItem("longitude");
    var id = this.props.match.params.slug;
    var userId = "";
    if (localStorage.getItem("userID")) {
      userId = localStorage.getItem("userID");
    }
    return collectionDetialAPI(id, city, pincode, latitude, longitude, userId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            chefArr: response.data.data.collection_list,
            isLoading: false,
          });
          if (response.data.data.collection_list.length === 0) {
            this.setState({
              errorStatus: true,
            });
          } else {
            this.setState({
              errorStatus: false,
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        /* console.log(error); */
      });
  };
  /* getData function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getData();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getData();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push(
      "/" + selectedCity + "/chef-inner-homepage/" + slugUrl
    );
    // var urlParam = config.slugify(kitchenName);
    // if (brandName.length > 0) {
    //  urlParam =  config.slugify(brandName);
    // }
    // if(chefAvailable === "1"){
    /*  this.props.history.push("/chef-inner-homepage/" + slugUrl);  */
    // }
  };
  /* getInerPage function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <section className="selected_you home_bakers see_space chef_inner crowd_tabs">
            <div className="shoe_all_bg text-white mb-3 mb-sm-5">
              <div className="right_wrap container">
                <h4 className="mb-0">{this.state.data}</h4>
                <div className="img">
                  <img
                    src={this.state.images}
                    alt="Paneer Biryani"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {this.state.chefArr.map((chef, c) => {
                  return (
                    <div
                      className="col-sm-6 col-md-6 col-lg-4 col-xl-3"
                      key={"jytrdf" + c}
                    >
                      <div
                        className={
                          chef.is_chef_available === "1"
                            ? "card"
                            : "card unavailable-chef-card"
                        }
                      >
                        <div
                          className="direction"
                          onClick={() => {
                            localStorage.getItem("userID") !== null
                              ? chef.is_bookmarked == 1
                                ? this.removeBookmark(chef.chef_id)
                                : this.addBookmark(chef.chef_id)
                              : this.storageHandler();
                          }}
                        >
                          <img
                            src={
                              chef.is_bookmarked == 1
                                ? "/assets/images/icons/bookmark.svg"
                                : "/assets/images/icons/bookmark-empty.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div
                          onClick={() =>
                            this.getInerPage(
                              chef.chef_id,
                              chef.slug_url,
                              chef.name,
                              chef.is_chef_available
                            )
                          }
                        >
                          {chef.promoted == 1 ? (
                            <div className="PromoShape">
                              <a href="#">Promoted</a>
                            </div>
                          ) : null}
                          <div className="img_wrap">
                            {chef.is_offer_available === "1" ? (
                              <div className="offer">OFFER</div>
                            ) : null}
                            {chef.profile_image != null ? (
                              <img
                                src={chef.profile_image}
                                alt=" Selected for You "
                                className=" img-fluid "
                              />
                            ) : (
                              <img
                                src="/assets/images/noimage.png"
                                alt=" Selected for You "
                                className=" img-fluid "
                              />
                            )}
                          </div>
                          <div className="text_wrap">
                            <h6>
                              {chef.verified == 1 ? (
                                <span className="correct_icn">
                                  {chef.brand_name !== ""
                                    ? chef.brand_name
                                    : chef.name}
                                </span>
                              ) : (
                                <span>
                                  {chef.brand_name !== ""
                                    ? chef.brand_name
                                    : chef.name}
                                </span>
                              )}{" "}
                              {chef.total_ratings !== 0 ? (
                                <span className="star">
                                  {chef.total_ratings}{" "}
                                  <span className="grey">/ 5</span>
                                </span>
                              ) : null}
                            </h6>
                            {chef.cuisines == "" ? (
                              "-"
                            ) : (
                              <p className="sub_title">{chef.cuisines}</p>
                            )}
                            <ul>
                              <li className="location_icn">
                                {chef.locality == null &&
                                chef.locality === "" ? null : (
                                  <span>{chef.locality}, </span>
                                )}{" "}
                                {chef.distance == null
                                  ? ""
                                  : parseFloat(chef.distance).toFixed(1)}{" "}
                                kms
                              </li>
                              {chef.is_takeaway_available == 1 ? (
                                <li>Available for Delivery & Takeaway</li>
                              ) : (
                                <li>Available for Delivery</li>
                              )}
                            </ul>
                            <div className={
                                      chef.gamification_data == null
                                        ? "badge_slide border-0"
                                        : "badge_slide"
                                    }>
                              <Slider
                                className="inner_slider"
                                {...this.state.settings}
                              >
                                {chef.gamification_data != null ? (
                                  chef.gamification_data.map((item, i) => {
                                    return (
                                      <div
                                        className="box img-slider1"
                                        key={"grkw" + i}
                                      >
                                        <img
                                          src={item.image}
                                          alt={item.title}
                                        />
                                        <span className="text">
                                          {item.desc}
                                        </span>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div className="box">
                                    <span className="text">
                                      &nbsp;&nbsp;
                                    </span>
                                  </div>
                                )}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {this.state.errorStatus ? (
                <div className="not_found">
                  <div className="container text-center">
                    <img
                      src="/assets/images/icons/not-found.svg"
                      alt="Not Found"
                      className="img-fluid"
                    />
                    <h6>SORRY! NO RESULTS FOUND.</h6>
                    <p>
                      We can’t find anything related to your <br /> search. Try
                      a different search.
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </section>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default Collections;
