/*
#############################################################################
# Name : signup.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to save the new chef details
#############################################################################
*/
import React from 'react';
import Header from '../../components/Header';
import WhatsAppButton from '../../components/whatsappButton';
import ReactFormInputValidation from 'react-form-input-validation';
import { signupAPI } from '../../core/services/APIservice';
// React Notification
import { NotificationManager } from 'react-notifications';
import config from '../../config/config';
import Recaptcha from 'react-google-invisible-recaptcha';
import { Helmet } from 'react-helmet';

ReactFormInputValidation.register(
  'alpha_space',
  function (value, requirement, attribute) {
    return value.match(/^[a-zA-Z ]+$/);
  },
  'Enter valid name'
);

export default class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        mobileNumber: ''
      },
      errors: {},
      loading: false
    };

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      firstname: 'required|alpha_space',
      lastname: 'required|alpha_space',
      email: 'required|email',
      password: 'required|min:6',
      mobileNumber: 'required|numeric|digits_between:10,12'
    });

    /* onformsubmit function starts here */
    this.form.onformsubmit = (fields) => {
      this.recaptcha.execute();
      this.setState({ loading: true });
      return signupAPI(
        fields.firstname,
        fields.lastname,
        fields.email,
        fields.mobileNumber,
        fields.password
      )
        .then((response) => {
          var tempId,
            userEmail,
            userName,
            userMobileNo = '';
          if (response.data['status'] === 200) {
            tempId = response.data['data']['user_id'];
            userEmail = response.data['data']['email'];
            userName = response.data['data']['first_name'];
            userMobileNo = response.data['data']['mobile_number'];
            localStorage.setItem('tempId', tempId);
            localStorage.setItem('userEmail', userEmail);
            localStorage.setItem('userMobile', userMobileNo);
            localStorage.setItem('userName', userName);
            this.setState({ loading: false });
            NotificationManager.success(
              'Welcome to CHEFPIN ' + userName,
              'Successful!',
              3000
            );
            this.props.history.push('/verfiyotp');
          } else if (response.status === 403) {
            this.setState({ loading: false });
            if (response.data.error.first_name) {
              NotificationManager.error(
                response.data.error.first_name,
                'Error!'
              );
            } else if (response.data.error.last_name) {
              NotificationManager.error(
                response.data.error.last_name,
                'Error!'
              );
            } else {
              NotificationManager.error(response.data.message, 'Error!');
            }
          } else if (response.status === 500) {
            this.setState({ loading: false });
            NotificationManager.error(response.data.message, 'Error!');
          } else {
            this.setState({ loading: false });
            NotificationManager.error(response.data['message'], 'Error!');
          }
        })
        .catch((error) => {
          // you will get error here.
          this.setState({ loading: false });
          NotificationManager.error(
            'Something went wrong while Registration',
            'Error!'
          );
          this.recaptcha.reset();
        });
    };
    /* onformsubmit function ends here */
  }

  componentDidMount() {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    try {
      OneSignal.getUserId().then(function (userId) {
        localStorage.setItem('OneSignal_playerId', userId);
      });
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log('is subscribed', isSubscribed);
        OneSignal.push(function () {
          console.log('attempt to get id'); // doesn't get this far
          OneSignal.getUserId(function (userId) {
            console.log('user id', userId); // doesn't get this far
            localStorage.setItem('OneSignal_playerId', userId);
          });
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  /* onResolved function starts here */
  onResolved() {
    alert('Recaptcha resolved with response: ' + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    return (
      <React.Fragment>
        <div>
          <Helmet>
            <title>
              Register with Chef Pin and start earning from your home cook food
            </title>
            <meta
              name='title'
              content='Register with Chef Pin and start earning from your home cook food.'
            />
            <meta
              name='description'
              content='Enjoy cooking? Why not share amazing food with the world and earn money? Sign Up to register.'
            />
            <meta
              name='keywords'
              content='home cooked meal, food home delivery,  homemade food, homemade food near me, cooked food delivered to home, home made chicken'
            />
          </Helmet>
          <Header />
          <section className='sign_up_chef'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-xl-5 d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='text pt-xl-0'>
                    <h1 className='color_C07'>REGISTER AS A HOME CHEF</h1>
                    <div className='profile_img'>
                      <div className='inner'>
                        <img
                          src='/assets/images/icons/profile-img.png'
                          alt='Profile Images'
                          className='img-fluid'
                        />
                      </div>
                    </div>
                    <div className='form_wrap'>
                      <form onSubmit={this.form.handleSubmit}>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='First Name*'
                            name='firstname'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.firstname}
                          />
                          <small className='form-text red_000'>
                            {this.state.errors.firstname
                              ? this.state.errors.firstname
                              : ''}
                          </small>
                        </div>
                        <div className='form-group'>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Last Name*'
                            name='lastname'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.lastname}
                          />
                          <small className='form-text red_000'>
                            {this.state.errors.lastname
                              ? this.state.errors.lastname
                              : ''}
                          </small>
                        </div>
                        <div className='form-group'>
                          <input
                            type='email'
                            className='form-control'
                            placeholder='Email ID*'
                            name='email'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.email}
                          />
                          <small className='form-text red_000'>
                            {this.state.errors.email
                              ? this.state.errors.email
                              : ''}
                          </small>
                        </div>
                        <div className='form-group'>
                          <input
                            type='password'
                            className='form-control'
                            placeholder='Password*'
                            name='password'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.password}
                          />
                          <small
                            id='PasswordHelp'
                            className='form-text text-muted'
                          >
                            Minimum 6 characters.
                          </small>
                          <small className='form-text red_000'>
                            {this.state.errors.password
                              ? this.state.errors.password
                              : ''}
                          </small>
                        </div>
                        <div className='form-group'>
                          <div className='mobile_no_wrap'>
                            <div className='form-control countrycode'>
                              <span className='code'>+91</span>
                            </div>
                            <input
                              type='tel'
                              className='form-control tel_number'
                              id='Phone'
                              placeholder='Mobile Number*'
                              name='mobileNumber'
                              onBlur={this.form.handleBlurEvent}
                              onChange={this.form.handleChangeEvent}
                              value={this.state.fields.mobileNumber}
                              maxLength='10'
                            />
                          </div>
                          <small
                            id='PasswordHelp'
                            className='form-text text-muted'
                          >
                            Enter your 10 digit mobile number.
                          </small>
                          <small className='form-text red_000'>
                            {this.state.errors.mobileNumber
                              ? '10 digit mobile number required'
                              : ''}
                          </small>
                        </div>
                        <div className='form-group mb-0'>
                          <button
                            type='submit'
                            className='btn'
                            disabled={this.state.loading}
                          >
                            sAVE AND SEND OTP
                          </button>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-xl-7 d-lg-flex align-items-center align-items-xl-center pt-lg-5 pt-xl-0 d-none'>
                  <div className='img_box pt-lg-3 pt-xl-0'>
                    <img
                      src='/assets/images/sign-up-as-a-chef/sign-up-img.png'
                      alt='sign up img'
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='nofooter'>
            <WhatsAppButton />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
