/*
#############################################################################
# Name : aboutKitchen.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to add and display the kitchen details of the chef
#############################################################################
*/
import React, { Component } from "react";
import axios from "axios";
import {
  getAllergiesDetailAPI,
  getKitchenMaterialAPI,
  getCusiniesAPI,
  getUserSummaryAPI,
  getSpecialitiyAPI,
  removeSpecialDishAPI,
} from "../../core/services/APIservice";
import { withRouter } from "react-router-dom";
import config from "../../config/config";
// React Notification
import { NotificationManager } from "react-notifications";
import Recaptcha from "react-google-invisible-recaptcha";
import $ from "jquery";

class AboutKitchenPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yourselfDescribe: "",
      cookingQualification: "",
      cuisinesServe: [""],
      currentEditCuisineIndex: "",
      specialDishName: "",
      specialDishPhoto: "",
      specialDishPhotoName: "",
      specialDishArr: [],
      kitchenPhoto: [],
      kitchenPhotoName: [],
      organicCertifiedSelected: "",
      recyclePackageSelected: "",
      disposableSelected: "",
      kitchenTypeSelected: "",
      kitchenSuitableSelected: false,
      suitable_for_jain_veg: "0",
      suitable_for_pure_veg: "0",
      suitable_for_veg_with_egg: "0",
      suitable_for_non_veg: "0",
      jainSelected: false,
      pureVegSelected: false,
      vegSelected: false,
      nonVegSelected: false,
      jainDisabled: false,
      pureVegDisabled: false,
      vegDisabled: false,
      nonVegDisabled: false,
      halalSelected: "",
      allergiesArr: [],
      kitchenMaterialArr: [],
      kitchenItemArr: [],
      allergiesCheckedArray: [],
      kitchenMaterialCheckedArray: [],
      kitchenItemCheckedArray: [],
      responseKitchenMaterial: [],
      responseKitchenItem: [],
      responseAllergies: [],
      /* chars_left: 350, */
      cusinieSuggestion: [],
      kitchenSuitableArr: [],
      displaySuggestions: false,
      cusineEnteredSuggestions: [],
      selectKitchenAllCheckbox: false,
      selectCookingAllCheckbox: false,
      loading: false,
      loadingDish: false,
      errorDescribe: "",
      errorCuisine: "",
      errorKitchenUpload: "",
      errorSpecialUpload: "",
      errorKitchenSuitable: "",
      errorNonVegHalal: "",
      errorSpecialDish: "",
      errorSpecialDishName: '',
	  enable_discount: "0",
      discount_type: "In Percentage",
      discounterror: "",
      discountstartdateerror: "",
      discountenddateerror: "",
      discountdates: "",
      discount: "0",
	  discount_start_date: new Date(new Date().setDate(new Date().getDate())),
	  discount_end_date: new Date(new Date().setDate(new Date().getDate())),
      currentDate: new Date(new Date().setDate(new Date().getDate())),
    };
    this.handleOrganicCertifiedChange = this.handleOrganicCertifiedChange.bind(
      this
    );
    this.handleRecyclePackageChange = this.handleRecyclePackageChange.bind(
      this
    );
    this.handleDisposableChange = this.handleDisposableChange.bind(this);
    this.handleKitchenTypeChange = this.handleKitchenTypeChange.bind(this);
    this.handleHalalChange = this.handleHalalChange.bind(this);
    this.handleAboutKitchenChange = this.handleAboutKitchenChange.bind(this);
    this.handleAboutKitchenTextareaChange = this.handleAboutKitchenTextareaChange.bind(
      this
    );
    this.handleInputKitchenCheckboxChange = this.handleInputKitchenCheckboxChange.bind(
      this
    );
  }

  componentDidMount() {
    this.getKitchenDetails();       // Kithcen Details data is fetched
    this.getRequirementAllergies(); // Requirement Allergies data is fetched
    this.getKitchenMaterial();      // Kitchen Material data is fetched
    this.getCusiniesData();         // Cusinies data is fetched
    window.scrollTo(0, 0);
  }

  /*  getCusiniesData function starts here */
  // Cusinie data is fetched from the database by passing userID and Token
  getCusiniesData() {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getCusiniesAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({ cusinieSuggestion: response.data["data"] });
        }
      })
      .catch((error) => {
      });
  }
  /*  getCusiniesData function ends here */

  /*  getRequirementAllergies function starts here */
  // Requirement and Allergies data is fetched from the database by passing userID and Token
  getRequirementAllergies() {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getAllergiesDetailAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({ allergiesArr: response.data["data"] });
        }
      })
      .catch((error) => {
      });
  }
  /*  getRequirementAllergies function ends here */

  /* getKitchenMaterial function starts here */
  // Kitchen Material data is fetched from the database by passing userID and Token
  getKitchenMaterial() {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return getKitchenMaterialAPI(userId, accessToken)
      .then((response) => {
        if (response.data["status"] === 200) {
          this.setState({
            kitchenMaterialArr: response.data["data"]["kitchen_material"],
            kitchenItemArr: response.data["data"]["kitchen_cooking_Material"],
          });
          setTimeout(() => {
            this.getFlagInCheckboxData();
          }, 1000);
        }
      })
      .catch((error) => {
      });
  }
  /* getKitchenMaterial function ends here */

  /* getFlagInCheckboxData function starts here */
  getFlagInCheckboxData() {
    let allergies = [];
    let materials = [];
    let items = [];
    this.state.allergiesArr.forEach((sample) => {
      allergies.push({
        ...sample,
        isChecked: false,
      });
    });
    this.state.kitchenMaterialArr.forEach((sample) => {
      materials.push({
        ...sample,
        isChecked: false,
      });
    });
    this.state.kitchenItemArr.forEach((sample) => {
      items.push({
        ...sample,
        isChecked: false,
      });
    });
    this.setState({
      allergiesArr: allergies,
      kitchenMaterialArr: materials,
      kitchenItemArr: items,
    });
    setTimeout(() => {
      if (this.state.responseAllergies) {
        this.state.allergiesArr.forEach((allergies) => {
          this.state.responseAllergies.forEach((newAllergies) => {
            if (newAllergies.req_id === allergies.id) {
              allergies.isChecked = true;
              this.state.allergiesCheckedArray.push(allergies.id);
            }
          });
        });
      }
      if (this.state.responseKitchenItem) {
        var counterItem = 0;
        var counterCookingItem = 0;
        this.state.kitchenItemArr.forEach((items) => {
          counterCookingItem++;
          this.state.responseKitchenItem.forEach((newItems) => {
            if (newItems.material_id === items.id) {
              items.isChecked = true;
              this.state.kitchenItemCheckedArray.push(items.id);
              counterItem++;
            }
          });
        });
        if (counterItem === counterCookingItem) {
          this.setState({ selectCookingAllCheckbox: true });
        } else {
          this.setState({ selectCookingAllCheckbox: false });
        }
      }
      if (this.state.responseKitchenMaterial) {
        var counterKitchen = 0;
        var counterKitchenMaterial = 0;
        this.state.kitchenMaterialArr.forEach((materials) => {
          counterKitchenMaterial++;
          this.state.responseKitchenMaterial.forEach((newMaterial) => {
            if (newMaterial.material_id === materials.id) {
              materials.isChecked = true;
              this.state.kitchenMaterialCheckedArray.push(materials.id);
              counterKitchen++;
            }
          });
        });
        if (counterKitchen === counterKitchenMaterial) {
          this.setState({ selectKitchenAllCheckbox: true });
        } else {
          this.setState({ selectKitchenAllCheckbox: false });
        }
      }
    }, 1000);
  }
  /* getFlagInCheckboxData function ends here */

/* onChangeSpecialDishPhoto function starts here */
  onChangeSpecailDishPhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.state.specialDishName === "") {
      this.setState({
        errorSpecialDishName: "Speciality Dish Name is required",
      });
    } else {
      this.setState({
        errorSpecialDishName: "",
      });
      var file = event.target.files;
      for (var x = 0; x < file.length; x++) {
        if (!file[x].name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
          this.setState({
            errorSpecialUpload:
              "Please select valid file type. " +
              file[x].name +
              " is invalid type",
          });
          return false;
        } else {
          this.setState({ errorSpecialUpload: "" });
        }
      }

      this.setState({
        specialDishPhoto: event.target.files[0],
        specialDishPhotoName: event.target.files[0]["name"].slice(-20),
      });
      setTimeout(() => {
      }, 1000);
    }
  };
  /* onChangeSpecialDishPhoto function ends here */
  
  handleBusinessInputChange = (e) => {
    const { name, value } = e.target;
    let businessFormErrors = this.state.businessFormErrors;
    switch (name) {
	  case "enable_discount":
        if (
          !isNaN(this.state.enable_discount) &&
          Number(this.state.enable_discount) > 0
        ) {
          this.setState({ enable_discount: "0" });
        }
      default:
        break;
    }

    this.setState({ businessFormErrors, [name]: value });
  };

/* onChangeKitchenPhoto function starts here */
  onChangeKitchenPhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;
    for (var x = 0; x < file.length; x++) {
      if (!file[x].name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        this.setState({
          errorKitchenUpload:
            "Please select valid file type. " +
            file[x].name +
            " is invalid type",
        });
        return false;
      } else {
        this.setState({ errorKitchenUpload: "" });
      }
    }
    this.state.kitchenPhoto.push(...event.target.files);
    setTimeout(() => {
    }, 1000);
  };
  /* onChangeKitchenPhoto function ends here */

/* handleOrganicCertifiedChange function starts here */
  handleOrganicCertifiedChange(event) {
    this.setState({ organicCertifiedSelected: event.target.value });
  }
/* handleOrganicCertifiedChange function ends here */

/* handleRecyclePackageChange function starts here */
  handleRecyclePackageChange(event) {
    this.setState({ recyclePackageSelected: event.target.value });
  }
/* handleRecyclePackageChange function ends here */

/* handleKitchenTypeChange function starts here */
  handleKitchenTypeChange(event) {
    this.setState({ kitchenTypeSelected: event.target.value });
  }
/* handleKitchenTypeChange function ends here */

/* handleHalalChange function starts here */
  handleHalalChange(event) {
    if (event.target.value) {
      this.setState({ errorNonVegHalal: "" });
    }
    this.setState({ halalSelected: event.target.value });
  }
/* handleHalalChange function ends here */

/* handleDisposableChange function starts here */
  handleDisposableChange(event) {
    this.setState({ disposableSelected: event.target.value });
  }
/* handleDisposableChange function ends here */

/* handleCheckAllergies function starts here */
  handleCheckAllergies = (event) => {
    if (event.target.checked) {
      this.state.allergiesCheckedArray.push(event.target.value);
    } else {
      let filteredArr = this.state.allergiesCheckedArray.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({ allergiesCheckedArray: filteredArr });
    }
    let allergies = this.state.allergiesArr;
    allergies.forEach((sampleAllergies) => {
      if (sampleAllergies.id === event.target.value)
        sampleAllergies.isChecked = event.target.checked;
    });
    this.setState({ allergiesArr: allergies });
    setTimeout(() => {
    }, 1000);
  };
/* handleCheckAllergies function ends here */

/* handleCheckKitchenMaterial function starts here */
  handleCheckKitchenMaterial = (event) => {
    let materials = this.state.kitchenMaterialArr;
    var allChecked = 0;
    if (event.target.checked) {
      this.state.kitchenMaterialCheckedArray.push(event.target.value);

      materials.forEach((sampleMaterial) => {
        if (sampleMaterial.id === event.target.value)
          sampleMaterial.isChecked = event.target.checked;
        if (!sampleMaterial.isChecked) {
          allChecked++;
        }
      });
      if (allChecked === 0) {
        this.setState({ selectKitchenAllCheckbox: true });
      } else {
        this.setState({ selectKitchenAllCheckbox: false });
      }
    } else {
      let filteredArr = this.state.kitchenMaterialCheckedArray.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({
        kitchenMaterialCheckedArray: filteredArr,
        selectKitchenAllCheckbox: false,
      });
    }
    materials.forEach((sampleMaterial) => {
      if (sampleMaterial.id === event.target.value)
        sampleMaterial.isChecked = event.target.checked;
    });
    this.setState({ kitchenMaterialArr: materials });
    setTimeout(() => {
    }, 1000);
  };
/* handleCheckKitchenMaterial function ends here */

/* handleKitchenAllChecked function starts here */
  handleKitchenAllChecked = (event) => {
    this.setState({ kitchenMaterialCheckedArray: [] })
    let selectedArr = []
    if (event.target.checked) {
      let materials = this.state.kitchenMaterialArr;
      materials.forEach((sampleMaterial) => {
        sampleMaterial.isChecked = event.target.checked;
        selectedArr.push(sampleMaterial.id);
      });
      this.setState({
        selectKitchenAllCheckbox: true,
        kitchenMaterialArr: materials,
        kitchenMaterialCheckedArray: selectedArr
      });
    } else {
      let materials = this.state.kitchenMaterialArr;
      materials.forEach((sampleMaterial) => {
        sampleMaterial.isChecked = event.target.checked;
      });
      this.setState({
        selectKitchenAllCheckbox: false,
        kitchenMaterialCheckedArray: [],
        kitchenMaterialArr: materials,
      });
    }
  };
  /* handleKitchenAllChecked function endss here */

/* handleCheckKitchenItem function starts here */
  handleCheckKitchenItem = (event) => {
    let items = this.state.kitchenItemArr;
    var allChecked = 0;
    if (event.target.checked) {
      this.state.kitchenItemCheckedArray.push(event.target.value);
      items.forEach((sampleItem) => {
        if (sampleItem.id === event.target.value)
          sampleItem.isChecked = event.target.checked;
        if (!sampleItem.isChecked) {
          allChecked++;
        }
      });
      if (allChecked === 0) {
        this.setState({ selectCookingAllCheckbox: true });
      } else {
        this.setState({ selectCookingAllCheckbox: false });
      }
    } else {
      let filteredArr = this.state.kitchenItemCheckedArray.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({
        kitchenItemCheckedArray: filteredArr,
        selectCookingAllCheckbox: false,
      });
    }
    items.forEach((sampleItem) => {
      if (sampleItem.id === event.target.value)
        sampleItem.isChecked = event.target.checked;
    });
    this.setState({ kitchenItemArr: items });
    setTimeout(() => {
    }, 1000);
  };
  /* handleCheckKitchenItem function ends here */

/* handleCookingAllChecked function starts here */
  handleCookingAllChecked = (event) => {
    this.setState({ kitchenItemCheckedArray: [] })
    let selectedItemArr = []
    if (event.target.checked) {
      let items = this.state.kitchenItemArr;
      items.forEach((sampleItem) => {
        sampleItem.isChecked = event.target.checked;
        selectedItemArr.push(sampleItem.id);
      });
      this.setState({
        selectCookingAllCheckbox: true,
        kitchenItemArr: items,
        kitchenItemCheckedArray: selectedItemArr
      });
    } else {
      let items = this.state.kitchenItemArr;
      items.forEach((sampleItem) => {
        sampleItem.isChecked = event.target.checked;
      });
      this.setState({
        selectCookingAllCheckbox: false,
        kitchenItemCheckedArray: [],
        kitchenItemArr: items,
      });
    }
  };
  /* handleCookingAllChecked function ends here */

  /* handleChange function starts here */
  handleChange(i, event) {
    this.setState({
      currentEditCuisineIndex: i,
    });
    if (event.target.value !== "" || event.target.value !== null) {
      let array = this.state.cusinieSuggestion.filter(
        (elm) =>
          elm.cuisine_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) &&
          event.target.value !== ""
      );
      if (array) {
        this.setState({
          cusineEnteredSuggestions: array,
          displaySuggestions: true,
        });
      }
    } else {
      this.setState({
        cusineEnteredSuggestions: [],
        displaySuggestions: false,
      });
    }
    let cuisinesServe = [...this.state.cuisinesServe];
    cuisinesServe[i] = event.target.value;
    this.setState({ cuisinesServe });
    setTimeout(() => {
    }, 1000);
  }
   /* handleChange function ends here */

  /* assignSuggestion function starts here */
  assignSuggestion = (i, value) => {
    this.setState({
      errorCuisine: "",
    });
    let cuisinesServe = [...this.state.cuisinesServe];
    cuisinesServe[this.state.currentEditCuisineIndex] = value;
    this.setState({ cuisinesServe });
    setTimeout(() => {
      this.setState({
        displaySuggestions: false,
      });
    }, 100);
  };
   /* assignSuggestion function ends here */

 /* addClick function starts here */
  addClick() {
    var lastCousine = this.state.cuisinesServe[
      this.state.cuisinesServe.length - 1
    ];
    if (lastCousine !== "") {
      var count = -1;
      this.setState({
        errorCuisine: "",
      });
      this.state.cuisinesServe.map((item) => {
        if (item == lastCousine) {
          count++;
        }
      });
      var counter = 0;
      this.state.cusinieSuggestion.map((item) => {
        if (item.cuisine_name == lastCousine) {
          counter++;
        }
      });
      if (counter > 0 && count == 0) {
        this.setState((prevState) => ({
          cuisinesServe: [...prevState.cuisinesServe, ""],
        }));
      } else {
        if (count > 0) {
          this.setState({
            errorCuisine: "Cuisine already added",
          });
        } else {
          this.setState({
            errorCuisine: "Please enter valid cousine",
          });
        }
      }
    } else if (lastCousine == "") {
      this.setState({
        errorCuisine: "Please enter cousine",
      });
    } else {
      this.setState({
        errorCuisine: "",
      });
    }
  }
/* addClick function ends here */

/* removeClick function starts here */
  removeClick(i) {
    this.setState({
      errorCuisine: "",
    });
    let cuisinesServe = [...this.state.cuisinesServe];
    cuisinesServe.splice(i, 1);
    this.setState({ cuisinesServe });
    setTimeout(() => {
    }, 1000);
  }
/* removeClick function ends here */  

/* removeDish function starts here */
  removeDish = (dish, event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    return removeSpecialDishAPI(userId, accessToken, dish)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            specialDishArr: response.data["data"]["special_dishes"],
          });
        }
      })
      .catch((error) => {
      });
  };
/* removeDish function ends here */

/* addSpecialMenuDish function starts here */
  addSpecialMenuDish = (event) => {
    event.preventDefault();
    if (this.state.specialDishName === "") {
      this.setState({
        errorSpecialDish: "Speciality Dish Name is required",
      });
    } else {
      this.setState({
        errorSpecialDish: "",
      });
    }
    if (this.state.specialDishName != "") {
      this.setState({ loadingDish: true });
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      return getSpecialitiyAPI(
        userId,
        accessToken,
        this.state.specialDishName,
        this.state.specialDishPhoto,
        this.state.specialDishPhotoName
      )
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              specialDishArr: response.data["data"]["special_dishes"],
              specialDishName: "",
              specialDishPhoto: "",
              loadingDish: false
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingDish: false });
        });
    } else {
      this.setState({ loadingDish: false });
    }

  };
/* addSpecialMenuDish function ends here */

  /*  addAnotherSpecialMenuDish = (event) => {
     event.preventDefault();
     if(this.state.specialDishArr.length === 0){
       this.setState({
         errorSpecialDish: "Speciality Dish not added"
       })
     }else{
       this.setState({
         errorSpecialDish:"", specialDishName: '', specialDishPhoto: []
       })
     }
   } */
/* removeSpecialDishClick function starts here */
  removeSpecialDishClick = (i, event) => {
    event.preventDefault();
    let specialDish = [...this.state.specialDish];
    specialDish.splice(i, 1);
    this.setState({ specialDish });
    setTimeout(() => {
    }, 1000);
  };
/* removeSpecialDishClick function ends here */

/* handleAboutKitchenChange function starts here */
  handleAboutKitchenChange = (key) => {
    if (key.target.name === "specialDishName") {
      this.setState({ errorSpecialDish: "", errorSpecialDishName: "" });
    }
    this.setState({
      [key.target.name]: key.target.value,
    });
  };
/* handleAboutKitchenChange function ends here */

/* handleAboutKitchenTextareaChange function starts here */
  handleAboutKitchenTextareaChange(key) {
    /* const charCount = key.target.value.length;
    const charLeft = 350 - charCount;
    this.setState({ chars_left: charLeft }); */
    if (key.target.value) {
      this.setState({ errorDescribe: "" });
    }
    this.setState({
      [key.target.name]: key.target.value,
    });
  }
/* handleAboutKitchenTextareaChange function ends here */

/* getKitchenDetails function starts here */
  getKitchenDetails() {
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    var formdata = new FormData();
    formdata.append("user_id", userId);
    axios
      .post(config.APIURL + "v1/get-chef-kitchen-detail", formdata, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((response) => {
        if (response.data["status"] === 200) {
          var describe = response.data["data"]["kitchen_description"];
          var qualification = response.data["data"]["cooking_qualifications"];
          var kitchentype = response.data["data"]["kitchen_type"];
          let kitchenImages = [];
          kitchenImages = response.data["data"]["kitchen_images"];
          let sampleKitchenMaterial = [];
          let sampleKitchenItem = [];
          let sampleAllergies = [];
          let sampleSpecialDish = [];
          sampleSpecialDish = response.data["data"]["special_dishes"];
          sampleKitchenMaterial = response.data["data"]["kitchen_material"];
          sampleKitchenItem = response.data["data"]["kitchen_cooking_medium"];
          sampleAllergies =
            response.data["data"]["kitchen_requirements_and_allergies"];
          var veg = response.data["data"]["suitable_for_veg_with_egg"];
          var pureveg = response.data["data"]["suitable_for_pure_veg"];
          var jainveg = response.data["data"]["suitable_for_jain_veg"];
          var nonveg = response.data["data"]["suitable_for_non_veg"];
          var discount_start_date = response.data["data"]["discount_start_date"];
          var discount_end_date = response.data["data"]["discount_end_date"];
          var discount = response.data["data"]["discount"];
          var discount_type = response.data["data"]["discount_type"];
          var enable_discount = response.data["data"]["enable_discount"];
		  
		  if (
            enable_discount == "0"
          ) {
			this.setState({
              discount: '0',
              discount_start_date: '',
              discount_end_date: '',
              discount_type: '',
              enable_discount: '0',
            });  
		  }else{
			this.setState({
              discount: discount,
              discount_start_date: discount_start_date,
              discount_end_date: discount_end_date,
              discount_type: discount_type,
              enable_discount: enable_discount,
            });  
		  }
			
          if (veg === "1") {
            this.setState({ vegSelected: true, kitchenSuitableSelected: true });
            this.setState({
              jainDisabled: true,
              pureVegDisabled: true,
              jainSelected: false,
              pureVegSelected: false,
            });
          } else {
            this.setState({ vegSelected: false });
          }
          if (pureveg === "1") {
            this.setState({
              pureVegSelected: true,
              kitchenSuitableSelected: true,
            });
            this.setState({
              nonVegDisabled: true,
              vegDisabled: true,
              vegSelected: false,
              nonVegSelected: false,
            });
          } else {
            this.setState({ pureVegSelected: false });
          }
          if (jainveg === "1") {
            this.setState({
              jainSelected: true,
              kitchenSuitableSelected: true,
            });
            this.setState({
              nonVegDisabled: true,
              vegDisabled: true,
              vegSelected: false,
              nonVegSelected: false,
            });
          } else {
            this.setState({ jainSelected: false });
          }
          if (nonveg === "1") {
            this.setState({
              nonVegSelected: true,
              kitchenSuitableSelected: true,
            });
            this.setState({
              jainDisabled: true,
              pureVegDisabled: true,
              jainSelected: false,
              pureVegSelected: false,
            });
          } else {
            this.setState({ nonVegSelected: false });
          }
          var halal = response.data["data"]["non_veg_is_halal"];
          var organic = response.data["data"]["certified_organic_use"];
          var recycle = response.data["data"]["packaging_recylable"];
          var disposable = response.data["data"]["provide_dispoable_cutlery"];
          let cuisine = [];
          cuisine = response.data["data"]["cuisines"];
          if (cuisine.length) {
            this.setState({ cuisinesServe: [] });
          } else {
            this.setState({ cuisinesServe: [""] });
          }
          cuisine.forEach((sampleItem) => {
            this.state.cuisinesServe.push(sampleItem.cuisine_name);
          });

          /*  const charCount = describe.length;
           const charLeft = 350 - charCount;
           this.setState({ chars_left: charLeft }); */
          if (kitchentype === null) {
            this.setState({
              kitchenTypeSelected: "",
            });
          } else {
            this.setState({
              kitchenTypeSelected: kitchentype,
            });
          }

          if (organic === null) {
            this.setState({
              organicCertifiedSelected: "",
            });
          } else {
            this.setState({
              organicCertifiedSelected: organic,
            });
          }

          if (recycle === null) {
            this.setState({
              recyclePackageSelected: "",
            });
          } else {
            this.setState({
              recyclePackageSelected: recycle,
            });
          }

          if (halal === null) {
            this.setState({
              halalSelected: "",
            });
          } else {
            this.setState({
              halalSelected: halal,
            });
          }

          this.setState({
            yourselfDescribe: describe,
            cookingQualification: qualification,
            suitable_for_jain_veg: jainveg,
            suitable_for_pure_veg: pureveg,
            suitable_for_veg_with_egg: veg,
            kitchenPhotoName: kitchenImages,
            responseAllergies: sampleAllergies,
            responseKitchenItem: sampleKitchenItem,
            responseKitchenMaterial: sampleKitchenMaterial,
            disposableSelected: disposable,
            specialDishArr: sampleSpecialDish,
          });
          setTimeout(() => {
          }, 1000);
        }
      })
      .catch((error) => {
      });
  }
/* getKitchenDetails function ends here */

/* onAboutKitchenSubmit function starts here */
  onAboutKitchenSubmit = (event) => {
    if (this.state.yourselfDescribe === "") {
      this.setState({
        errorDescribe: "Describe yourself is required",
      });
    } else {
      this.setState({
        errorDescribe: "",
      });
    }
    var cuisineCounter = 0;
    this.state.cuisinesServe.map((item) => {
      if (item === "") {
        cuisineCounter++;
      }
    });
    if (cuisineCounter > 0) {
      if (this.state.cuisinesServe.length > 1) {
        this.setState({
          errorCuisine: "Please fill all the cuisine",
        });
      } else {
        this.setState({
          errorCuisine: "Cuisine is required",
        });
      }
    }

    var isMatchCounter = 0;
    this.state.cuisinesServe.map((item) => {
      var isMatch = false;
      this.state.cusinieSuggestion.map((cuisine) => {
        if (item === cuisine.cuisine_name) {
          isMatch = true;
        }
      });
      if (isMatch === true) {
        isMatchCounter++;
      }
    });
    if (this.state.cuisinesServe.length !== isMatchCounter) {
      this.setState({
        errorCuisine: "Please select all valid Cuisine",
      });
    } else {
      this.setState({
        errorCuisine: "",
      });
    }

    if (
      this.state.jainSelected === false &&
      this.state.nonVegSelected === false &&
      this.state.vegSelected === false &&
      this.state.pureVegSelected === false
    ) {
      this.setState({
        errorKitchenSuitable: "Kitchen Suitable is required",
        kitchenSuitableSelected: false,
      });
    } else {
      this.setState({
        kitchenSuitableSelected: true,
        errorKitchenSuitable: "",
      });
    }

    if (this.state.halalSelected === "") {
      this.setState({
        errorNonVegHalal: "Halal is required",
      });
    } else {
      this.setState({
        errorNonVegHalal: "",
      });
    }

    if (this.state.specialDishName !== "") {
      this.setState({
        errorSpecialDish: "Special Dish Added but not saved",
      });
    } else {
      this.setState({
        errorSpecialDish: "",
      });
    }

    if (this.state.specialDishArr.length === 0) {
      this.setState({
        errorSpecialDish: "To proceed add dish and click below to save",
      });
    } else {
      this.setState({
        errorSpecialDish: "",
      });
    }
	
	this.setState({
		discountdates : ''
	});
	this.setState({
		 discounterror: "",
		 discountstartdateerror: ""
	});
	
	if(this.state.enable_discount == '1'){
		if(this.state.discount == '' || parseFloat(this.state.discount) <= 0){
			this.setState({
				 discounterror: "This is required and enter valid discount."
			});
			return false;
		}
		
		//if(this.state.discount_type == 'In Percentage'){
			if(parseFloat(this.state.discount) > 100){
				this.setState({
					 discounterror: "The discount should be less then 100%."
				});
				return false;
			}
		//}
		
		if(this.state.discount_end_date == ''){
			this.setState({
				 discountenddateerror: "This is required."
			});
			return false;
		}else{
			var disdate = this.state.discount_end_date;
			/* this.setState({
				discountdates : `${disdate.getFullYear()}-${disdate.getMonth() + 1}-${disdate.getDate()}`
			}); */
		}
		
		if(this.state.discount_end_date < this.state.discount_start_date){
			this.setState({
				 discountenddateerror: "The discount end date should be greater than start date."
			});
			return false;
		}
	}



    /* !this.state.cuisinesServe.length */
    if (
      this.state.yourselfDescribe !== "" &&
      this.state.halalSelected !== "" &&
      this.state.kitchenSuitableSelected === true &&
      this.state.specialDishArr.length !== 0 &&
      cuisineCounter === 0 &&
      this.state.errorCuisine === "" &&
      this.state.cuisinesServe.length &&
      this.state.cuisinesServe.length === isMatchCounter && this.state.errorSpecialDishName === "" && this.state.specialDishName === ""
    ) {
      event.preventDefault();
      this.recaptcha.execute();
      this.setState({ loading: true });
      var userId = localStorage.getItem("userID");
      var accessToken = localStorage.getItem("accessToken");
      var formdata = new FormData();
      formdata.append("user_id", userId);
      formdata.append("cuisines", this.state.cuisinesServe.toString());
      formdata.append("kitchen_type", this.state.kitchenTypeSelected);
      formdata.append("non_veg_is_halal", this.state.halalSelected);
      formdata.append("special_dish", this.state.specialDish);
      if (this.state.kitchenPhoto !== "") {
        for (const file of this.state.kitchenPhoto) {
          formdata.append("kitchen_images[]", file, file.name);
        }
      }
      formdata.append("kitchen_description", this.state.yourselfDescribe);
      formdata.append(
        "cooking_qualifications",
        this.state.cookingQualification
      );
      formdata.append(
        "suitable_for_jain_veg",
        this.state.suitable_for_jain_veg
      );
      formdata.append(
        "suitable_for_pure_veg",
        this.state.suitable_for_pure_veg
      );
      formdata.append(
        "suitable_for_veg_with_egg",
        this.state.suitable_for_veg_with_egg
      );
      formdata.append("suitable_for_non_veg", this.state.suitable_for_non_veg);
      formdata.append(
        "certified_organic_use",
        this.state.organicCertifiedSelected
      );
      formdata.append("packaging_recylable", this.state.recyclePackageSelected);
      formdata.append(
        "provide_dispoable_cutlery",
        this.state.disposableSelected
      );
      formdata.append(
        "kitchen_requirements_and_allergies",
        this.state.allergiesCheckedArray.toString()
      );
      formdata.append(
        "kitchen_cooking_medium",
        this.state.kitchenItemCheckedArray.toString()
      );
      formdata.append(
        "kitchen_use_material",
        this.state.kitchenMaterialCheckedArray.toString()
      );
	  formdata.append("enable_discount", this.state.enable_discount);
      formdata.append("discount_start_date", this.state.discount_start_date);
      formdata.append("discount_end_date", this.state.discount_end_date);
      formdata.append("discount", this.state.discount);
      formdata.append("discount_type", this.state.discount_type);
      //localStorage.setItem('currentStatus', 1);
      axios
        .post(config.APIURL + "v1/store-chef-kitchen-detail", formdata, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((response) => {
          if (response.data["status"] === 200) {
            var userId = localStorage.getItem("userID");
            var accessToken = localStorage.getItem("accessToken");
            return getUserSummaryAPI(userId, accessToken)
              .then((response) => {
                if (response.data["status"] === 200) {
                  this.props.parentCallback(response.data["data"]);
                  this.setState({ loading: false });
                  localStorage.setItem("currentStatus", 5);
                  NotificationManager.success(
                    "User Kitchen Details Saved",
                    "Successful!",
                    3000
                  );
                }
              })
              .catch((error) => {
                this.setState({ loading: false });
              });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          NotificationManager.error(
            "User Kitchen Details failed to save",
            "Error!"
          );
        });
    } else {
      this.setState({ loading: false });
      setTimeout(function () {
        var getfilterRowElement = $(".text-danger");
        $("small.text-danger").each(function () {
          var string = $(this).html();
          if (string != "") {
            $("html, body").animate(
              {
                scrollTop: $(this).parent().offset().top + -100,
              },
              500
            );
            return false;
          }
        });
      }, 100);
      this.recaptcha.reset();
    }
  };
/* onAboutKitchenSubmit function ends here */

/* onPreviousKitchenSubmit function starts here */
  onPreviousKitchenSubmit = () => {
    localStorage.setItem("currentStatus", 2);
  };
/* onPreviousKitchenSubmit function ends here */

/* specialMenuDish function starts here */
  specialMenuDish = (event) => {
    event.preventDefault();
  };
/* specialMenuDish function ends here */

/* logoutHandler function starts here */
  logoutHandler = (e) => {
    localStorage.clear();
    NotificationManager.success(
      "You have Logout Successfully",
      "Successful!",
      3000
    );
  };
/* logoutHandler function ends here */

  handleKitchenInputChange = (e) => {
    const { name, value } = e.target;
    let kitchenFormErrors = this.state.kitchenFormErrors;

    switch (
      name
      // case 'kitchenDescription':
      //     kitchenFormErrors.kitchenDescription = value.length <= 0 ? 'This is required' : "";
      //     break;

      // case 'isHalala':
      //     kitchenFormErrors.isHalala = value.length <= 0 ? 'This is required' : "";
      //     break;
    ) {
    }

    this.setState({ kitchenFormErrors, [name]: value });
  };
  
  handleMenuInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    const { menuModalTitle } = this.state;

    this.setState({ formErrors, [name]: value });
  };

/* handleInputKitchenCheckboxChange function starts here */
  handleInputKitchenCheckboxChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const checked = event.target.checked;
    if (event.target.name === "jainSelected") {
      if (checked) {
        this.setState({
          nonVegDisabled: true,
          vegDisabled: true,
          vegSelected: false,
          nonVegSelected: false,
          errorKitchenSuitable: "",
        });
        this.setState({
          suitable_for_jain_veg: "1",
          kitchenSuitableSelected: true,
        });
      } else {
        if (this.state.pureVegSelected === false) {
          this.setState({
            nonVegDisabled: false,
            vegDisabled: false,
          });
        }
        this.setState({
          suitable_for_jain_veg: "0",
          kitchenSuitableSelected: false,
        });
      }
    }

    if (event.target.name === "pureVegSelected") {
      if (checked) {
        this.setState({
          nonVegDisabled: true,
          vegDisabled: true,
          vegSelected: false,
          nonVegSelected: false,
          errorKitchenSuitable: "",
        });
        this.setState({
          suitable_for_pure_veg: "1",
          kitchenSuitableSelected: true,
        });
      } else {
        if (this.state.jainSelected === false) {
          this.setState({
            nonVegDisabled: false,
            vegDisabled: false,
          });
        }
        this.setState({
          suitable_for_pure_veg: "0",
          kitchenSuitableSelected: false,
        });
      }
    }

    if (event.target.name === "vegSelected") {
      if (checked) {
        this.setState({
          jainDisabled: true,
          pureVegDisabled: true,
          jainSelected: false,
          pureVegSelected: false,
          errorKitchenSuitable: "",
        });
        this.setState({
          suitable_for_veg_with_egg: "1",
          kitchenSuitableSelected: true,
        });
      } else {
        if (this.state.nonVegSelected === false) {
          this.setState({
            jainDisabled: false,
            pureVegDisabled: false,
          });
        }
        this.setState({
          suitable_for_veg_with_egg: "0",
          kitchenSuitableSelected: false,
        });
      }
    }

    if (event.target.name === "nonVegSelected") {
      if (checked) {
        this.setState({
          jainDisabled: true,
          pureVegDisabled: true,
          jainSelected: false,
          pureVegSelected: false,
          errorKitchenSuitable: "",
        });
        this.setState({
          suitable_for_non_veg: "1",
          kitchenSuitableSelected: true,
        });
      } else {
        if (this.state.vegSelected === false) {
          this.setState({
            jainDisabled: false,
            pureVegDisabled: false,
          });
        }
        this.setState({
          suitable_for_non_veg: "0",
          kitchenSuitableSelected: false,
        });
      }
    }

    this.setState({
      [name]: value,
    });
  }
/* handleInputKitchenCheckboxChange function ends here */

/* removeKitchenPhoto function starts here */
  removeKitchenPhoto = (kitchenName, event) => {
    event.preventDefault();
    let filteredArr = this.state.kitchenPhoto.filter(
      (elm) => elm.name !== kitchenName
    );
    this.setState({ kitchenPhoto: filteredArr });


    setTimeout(() => {
    }, 1000);
  };
/* removeKitchenPhoto function ends here */

/* removeKitchenDataPhoto function starts here */
  removeKitchenDataPhoto = (kitchenID, event) => {
    event.preventDefault();
    var userId = localStorage.getItem("userID");
    var accessToken = localStorage.getItem("accessToken");
    var formdata = new FormData();
    formdata.append("user_id", userId);
    formdata.append("image_id", kitchenID);
    axios
      .post(config.APIURL + "v1/remove-kitchen-image", formdata, {
        headers: {
          Authorization: accessToken
        },
      })
      .then((response) => {
        if (response.data["status"] === 200) {
        }
      }).catch((error) => {
      })
    let filteredKitchenArr = this.state.kitchenPhotoName.filter(
      (elm) => elm.image_id !== kitchenID
    );

    this.setState({ kitchenPhotoName: filteredKitchenArr });
  }
/* removeKitchenDataPhoto function ends here */

/* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved function ends here */

  render() {
    return (
      <div className="registration bussiness p-0">
        <div className="abt_form">
          <h4 className="d-flex justify-content-between align-items-center">
            <button
              className="save_btn back_btn"
              onClick={this.onPreviousKitchenSubmit}
            >
              <img
                src="/assets/images/icons/left-arrow.png"
                alt="Left Arrow Icon"
                className="img-fluid"
              />
              Back
            </button>
            <span className="text-uppercase text-center text-md-left">
              About your kitchen
            </span>
            <button className="save_btn disabled">Save</button>
          </h4>
          <p className="sub_title">
            Note : Your information has been saved!{" "}
            <br className="d-block d-lg-none" /> You can now&nbsp;
            <a
              href="/"
              className="logout_link"
              onClick={(e) => this.logoutHandler(e)}
            >
              logout
            </a>
            &nbsp; and log back in to resume completing the form at your
            convenience.
          </p>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon cuisines_icon">
                <img
                  src="/assets/images/icons/chef.png"
                  alt="Chef Icon"
                  className="img-fluid"
                />
              </span>
              Cuisines you serve
            </h5>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <label>
                      Describe yourself, your cooking, what inspires you and
                      your motivation*
                    </label>
                    <textarea
                      className="form-control"
                      name="yourselfDescribe"
                      value={this.state.yourselfDescribe}
                      onChange={this.handleAboutKitchenTextareaChange}
                    //maxLength="350"
                    ></textarea>
                    {/* <small className="form-text text-muted text-right">
                      {this.state.chars_left} characters
                    </small> */}
                    <small className="text-danger">
                      {this.state.errorDescribe ? this.state.errorDescribe : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Any cooking-related qualifications?"
                      name="cookingQualification"
                      value={this.state.cookingQualification}
                      onChange={this.handleAboutKitchenChange}
                    />
                    <small className="form-text text-muted">
                      Please enter any degrees, certifications etc.
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <label className="d-block">
                      Which cuisines do you serve?*
                    </label>
                    {this.state.cuisinesServe.map((sampleCuisine, i) => {
                      return (
                        <div
                          key={'xkkjhg' + i}
                          className="py-2 custom-autosuggest remove_btn_wrap"
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Cuisine"
                            value={sampleCuisine || ""}
                            onChange={this.handleChange.bind(this, i)}
                            onBlur={() =>
                              setTimeout(() => {
                                this.setState({
                                  displaySuggestions: false,
                                });
                              }, 300)
                            }
                          // onFocus={this.handleChange.bind(this, i)}
                          />
                          {this.state.displaySuggestions === true &&
                            this.state.cuisinesServe.length - 1 === i ? (
                            <ul>
                              {this.state.cusineEnteredSuggestions.map(
                                (item) => {
                                  return (
                                    <li
                                      key={'bnhfc' + item.id}
                                      onClick={() =>
                                        this.assignSuggestion(
                                          i,
                                          item.cuisine_name
                                        )
                                      }
                                    >
                                      {item.cuisine_name}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          ) : null}
                          {i > 0 ? (
                            <button
                              type="button"
                              className="in_remo_btn"
                              onClick={this.removeClick.bind(this, i)}
                              key={i}
                            ></button>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      );
                    })}
                    <small className="text-danger">
                      {this.state.errorCuisine ? this.state.errorCuisine : ""}
                    </small>
                    <button
                      type="button"
                      className="add_another_btn d-flex align-items-center"
                      onClick={this.addClick.bind(this)}
                    >
                      <span className="plus_icon"></span>
                      <span>Add another cuisine</span>
                    </button>
                  </div>
                </div>

                <div className="col-md-12 col-lg-12 col-xl-10">
                  {this.state.specialDishArr.length !== 0 ? (
                    <label className="d-block">Speciality dishes</label>
                  ) : null}
                  {this.state.specialDishArr.map((dish, d) => {
                    return (
                      <div className="form-group" key={'wsz' + d}>
                        <div className="py-2 custom-autosuggest remove_btn_wrap">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Cuisine"
                            value={dish.special_dish_name}
                            maxLength="48"
                          />

                          <button
                            type="button"
                            className="in_remo_btn"
                            onClick={this.removeDish.bind(
                              this,
                              dish.special_dish_name
                            )}
                            key={d}
                          ></button>
                        </div>
                      </div>
                    );
                  })}
                  <div className="form-group">
                    <label className="d-block">
                      What are your speciality dishes?*
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Speciality Dishes"
                      name="specialDishName"
                      value={this.state.specialDishName}
                      onChange={this.handleAboutKitchenChange}
                      maxLength="25"
                    />
                    <small className="form-text text-muted">
                      For eg. Butter Chicken.
                    </small>
                    <small className="text-danger">
                      {this.state.errorSpecialDish
                        ? this.state.errorSpecialDish
                        : ""}
                    </small>
                    {this.state.errorSpecialDish ? null : <small className="text-danger">
                      {this.state.errorSpecialDishName
                        ? this.state.errorSpecialDishName
                        : ""}
                    </small>}
                  </div>

                  <div className="form-group">
                    <label className="d-block">
                      Upload picture of speciality dish
                    </label>
                    <div className="fileUpload d-flex">
                      <button className="fileUploadBtn form-control d-inline-flex"></button>
                      <div className="d-inline-flex align-items-center form-control">
                        {this.state.specialDishPhoto === "" ? (
                          <span className="file_text">
                            Choose file
                            <small>No file selected</small>
                          </span>
                        ) : (
                          <span className="file_text">
                            {this.state.specialDishPhotoName}
                          </span>
                        )}

                        <input
                          type="file"
                          className="uploadlogo"
                          name="special_dish"
                          onChange={this.onChangeSpecailDishPhoto}
                        />
                      </div>
                    </div>
                    <small className="form-text text-muted">
                      One file only. 64 MB limit. Allowed types: png gif jpg
                      jpeg.
                    </small>
                    <small className="text-danger">
                      {this.state.errorSpecialUpload
                        ? this.state.errorSpecialUpload
                        : ""}
                    </small>
                    {/*  <small className="form-text text-muted">
                      {this.state.specialDishPhoto.map((item, j) => {
                        return <p key={'kjhg'+j}>{item.name}</p>;
                      })}
                    </small> */}
                  </div>
                  {this.state.loadingDish ? <small className="form-text red_000">Speciality Dish uploading ...</small> : null}
                  <div className="form-group">
                    <button
                      className="add_another_btn d-flex align-items-center"
                      onClick={this.addSpecialMenuDish}
                      disabled={this.state.loadingDish}
                    >
                      <span className="plus_icon"></span>
                      <span>Save and add another speciality dish</span>
                    </button>
                  </div>
                  {/* <div className="form-group text-right">
                    <button
                      type="submit"
                      className="btn" onClick={this.addSpecialMenuDish}>
                      add
            </button>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
          <div className="inner">
            <h5 className="color_C07 d-flex align-items-center">
              <span className="icon stove_icon">
                <img
                  src="/assets/images/icons/stove.png"
                  alt="Ttove Icon"
                  className="img-fluid"
                />
              </span>
              Kitchen Details
            </h5>
            <form>
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">Type of Kitchen</label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Home
                          <input
                            type="radio"
                            name="kitchenHome"
                            onChange={this.handleKitchenTypeChange}
                            value="Home"
                            checked={this.state.kitchenTypeSelected === "Home"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          Commercial
                          <input
                            type="radio"
                            name="kitchenCommerical"
                            onChange={this.handleKitchenTypeChange}
                            value="Commercial"
                            checked={
                              this.state.kitchenTypeSelected === "Commercial"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          Offsite
                          <input
                            type="radio"
                            name="kitchenOffSite"
                            onChange={this.handleKitchenTypeChange}
                            value="Off-Site"
                            checked={
                              this.state.kitchenTypeSelected === "Off-Site"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group">
                    <label className="d-block">Kitchen/Venue pictures</label>
                    <div className="fileUpload d-flex">
                      <button className="fileUploadBtn form-control d-inline-flex"></button>
                      <div className="d-inline-flex align-items-center form-control">
                        <span className="file_text">
                          Choose file<small>No file selected</small>
                        </span>
                        <input
                          type="file"
                          className="uploadlogo"
                          name="kitchen_photo"
                          onChange={this.onChangeKitchenPhoto}
                          multiple
                        />
                      </div>
                    </div>
                    <small className="form-text text-muted">
                      Unlimited number of files can be uploaded to this field.
                      <br />
                      64 MB limit. Allowed types: png gif jpg jpeg.
                    </small>
                    <small className="text-danger">
                      {this.state.errorKitchenUpload
                        ? this.state.errorKitchenUpload
                        : ""}
                    </small>
                    <div className="festive_tags upload_tags">
                      <ul>
                        {this.state.kitchenPhoto.map((item, i) => {
                          return (
                            <li key={'okjhgty' + i}>
                              {item.name.slice(-20)}
                              <button
                                className="close"
                                onClick={this.removeKitchenPhoto.bind(
                                  this,
                                  item.name
                                )}
                              ></button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    {this.state.kitchenPhotoName.length !== 0 ? (
                      <div className="festive_tags upload_tags">
                        <ul>
                          {this.state.kitchenPhotoName.map((photo, i) => {
                            return (
                              <li key={'ghg' + i}>
                                {photo.image.split('/')[photo.image.split('/').length - 1]}
                                <button
                                  className="close"
                                  onClick={this.removeKitchenDataPhoto.bind(
                                    this,
                                    photo.image_id
                                  )}
                                ></button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                    {/* {this.state.kitchenPhotoName.length !== 0
                      ? this.state.kitchenPhotoName.map((photo, i) => (
                        <p key={'iuy'+i}>
                          {photo.image.split("kitchen-images/")[1].slice(-20)}
                        </p>
                      ))
                      : null} */}
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group checklist_wrap">
                    <label>Does your kitchen use any of the following?</label>
                    <ul className="kit_foll">
                      {this.state.kitchenMaterialArr.map((materials, i) => {
                        return (
                          <li key={'werfd' + i}>
                            <label className="custome_check">
                              <input
                                key={materials.id}
                                onClick={this.handleCheckKitchenMaterial}
                                type="checkbox"
                                checked={materials.isChecked}
                                value={materials.id}
                              />
                              <span className="checkmark"></span>
                              {materials.material_name}
                            </label>
                          </li>
                        );
                      })}
                      <li>
                        <label className="custome_check">
                          <input
                            type="checkbox"
                            onClick={this.handleKitchenAllChecked}
                            value="checkedall"
                            checked={this.state.selectKitchenAllCheckbox}
                          />
                          <span className="checkmark"></span>
                          All of the above
                        </label>
                      </li>
                    </ul>
                    <small className="form-text text-muted">
                      Select all that apply.
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group checklist_wrap">
                    <label>Is your kitchen suitable for the following?*</label>
                    <ul className="kit_suitable">
                      <li>
                        <label
                          className={
                            this.state.jainDisabled
                              ? "custome_check disable_checks"
                              : "custome_check"
                          }
                        >
                          <input
                            name="jainSelected"
                            type="checkbox"
                            checked={this.state.jainSelected}
                            onChange={this.handleInputKitchenCheckboxChange}
                            disabled={this.state.jainDisabled}
                          />
                          <span className="checkmark"></span>Jain Veg (no <br />{" "}
                          onion and <br /> garlic)
                        </label>
                      </li>
                      <li>
                        <label
                          className={
                            this.state.pureVegDisabled
                              ? "custome_check disable_checks"
                              : "custome_check"
                          }
                        >
                          <input
                            name="pureVegSelected"
                            type="checkbox"
                            checked={this.state.pureVegSelected}
                            onChange={this.handleInputKitchenCheckboxChange}
                            disabled={this.state.pureVegDisabled}
                          />
                          <span className="checkmark"></span>Pure Veg <br /> (no
                          eggs)
                        </label>
                      </li>
                      <li>
                        <label
                          className={
                            this.state.vegDisabled
                              ? "custome_check disable_checks"
                              : "custome_check"
                          }
                        >
                          <input
                            name="vegSelected"
                            type="checkbox"
                            checked={this.state.vegSelected}
                            onChange={this.handleInputKitchenCheckboxChange}
                            disabled={this.state.vegDisabled}
                          />
                          <span className="checkmark"></span>Veg (with eggs)
                        </label>
                      </li>
                      <li>
                        <label
                          className={
                            this.state.nonVegDisabled
                              ? "custome_check disable_checks"
                              : "custome_check"
                          }
                        >
                          <input
                            name="nonVegSelected"
                            type="checkbox"
                            checked={this.state.nonVegSelected}
                            onChange={this.handleInputKitchenCheckboxChange}
                            disabled={this.state.nonVegDisabled}
                          />
                          <span className="checkmark"></span>Non-Veg
                        </label>
                      </li>
                    </ul>

                    <small className="form-text text-muted">
                      Select all that apply.
                    </small>
                    <small className="text-danger mt-0">
                      {this.state.errorKitchenSuitable
                        ? this.state.errorKitchenSuitable
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">Is your non veg Halal?*</label>
                    <ul className="radio_list halal">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="halalYes"
                            onChange={this.handleHalalChange}
                            value="Yes"
                            checked={this.state.halalSelected === "Yes"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="halalNo"
                            onChange={this.handleHalalChange}
                            value="No"
                            checked={this.state.halalSelected === "No"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          Not sure
                          <input
                            type="radio"
                            name="halalNotSure"
                            onChange={this.handleHalalChange}
                            value="Not Sure"
                            checked={this.state.halalSelected === "Not Sure"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          Only serve <br /> vegetarian
                          <input
                            type="radio"
                            name="halalOnly"
                            onChange={this.handleHalalChange}
                            value="Only Serve Vegetarian"
                            checked={
                              this.state.halalSelected ===
                              "Only Serve Vegetarian"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                    <small className="form-text text-muted">
                      Select a value.
                    </small>
                    <small className="text-danger">
                      {this.state.errorNonVegHalal
                        ? this.state.errorNonVegHalal
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group checklist_wrap">
                    <label>
                      Do you cater to the following dietary requirements and
                      allergies?
                    </label>
                    <ul className="reuirement">
                      {this.state.allergiesArr.map((allergies, i) => {
                        return (
                          <li key={'qasd' + i}>
                            <label className="custome_check">
                              <input
                                key={allergies.id}
                                onClick={this.handleCheckAllergies}
                                type="checkbox"
                                checked={allergies.isChecked}
                                value={allergies.id}
                              />
                              <span className="checkmark"></span>
                              {allergies.requirement_and_allergies_name}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                    <small className="form-text text-muted">
                      Select all that apply.
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group checklist_wrap">
                    <label>What is your cooking medium?</label>
                    <ul className="cooking">
                      {this.state.kitchenItemArr.map((items, i) => {
                        return (
                          <li key={'ijh' + i}>
                            <label className="custome_check">
                              <input
                                key={items.id}
                                onClick={this.handleCheckKitchenItem}
                                type="checkbox"
                                checked={items.isChecked}
                                value={items.id}
                              />
                              <span className="checkmark"></span>
                              {items.material_name}
                            </label>
                          </li>
                        );
                      })}
                      <li>
                        <label className="custome_check">
                          <input
                            type="checkbox"
                            onClick={this.handleCookingAllChecked}
                            value="checkedall"
                            checked={this.state.selectCookingAllCheckbox}
                          />
                          <span className="checkmark"></span>
                          All of the above
                        </label>
                      </li>
                    </ul>
                    <small className="form-text text-muted">
                      Select all that apply.
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">
                      Is the produce you use certified Organic?
                    </label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="organicYes"
                            onChange={this.handleOrganicCertifiedChange}
                            value="Yes"
                            checked={
                              this.state.organicCertifiedSelected === "Yes"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="organicNo"
                            onChange={this.handleOrganicCertifiedChange}
                            value="No"
                            checked={
                              this.state.organicCertifiedSelected === "No"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          Not sure
                          <input
                            type="radio"
                            name="organicNotSure"
                            onChange={this.handleOrganicCertifiedChange}
                            value="Not Sure"
                            checked={
                              this.state.organicCertifiedSelected === "Not Sure"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                    <small className="form-text text-muted">
                      Select a value.
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">
                      Is all your packaging recyclable?
                    </label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="recycleYes"
                            onChange={this.handleRecyclePackageChange}
                            value="Yes"
                            checked={
                              this.state.recyclePackageSelected === "Yes"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="recycleNo"
                            onChange={this.handleRecyclePackageChange}
                            value="No"
                            checked={this.state.recyclePackageSelected === "No"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          Not sure
                          <input
                            type="radio"
                            name="recycleNotSure"
                            onChange={this.handleRecyclePackageChange}
                            value="Not Sure"
                            checked={
                              this.state.recyclePackageSelected === "Not Sure"
                            }
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                    <small className="form-text text-muted">
                      Select a value.
                    </small>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xl-10">
                  <div className="form-group radio_wrap">
                    <label className="d-block">
                      Do you provide disposable cutlery with orders?
                    </label>
                    <ul className="radio_list">
                      <li>
                        <label className="custome_check">
                          Yes
                          <input
                            type="radio"
                            name="disposableYes"
                            onChange={this.handleDisposableChange}
                            value="1"
                            checked={this.state.disposableSelected === "1"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                      <li>
                        <label className="custome_check">
                          No
                          <input
                            type="radio"
                            name="disposableNo"
                            onChange={this.handleDisposableChange}
                            value="0"
                            checked={this.state.disposableSelected === "0"}
                          />
                          <span className="radiobtn"></span>
                        </label>
                      </li>
                    </ul>
                    <small className="form-text text-muted">
                      Select a value.
                    </small>
                  </div>
                </div>
				<div className="col-md-12 col-lg-12 col-xl-10">
					 <div className="form-group radio_wrap">
						<label className="d-block">
						Do you enable discount for all the menu items?
						</label>
						 <ul className="radio_list">
						  <li>
							<label className="custome_check">
							  Yes
							  <input
								type="radio"
								name="enable_discount"
								onChange={this.handleBusinessInputChange}
								value="1"
								checked={this.state.enable_discount === "1"}
							  />
							  <span className="radiobtn"></span>
							</label>
						  </li>
						  <li>
							<label className="custome_check">
							  No
							  <input
								type="radio"
								name="enable_discount"
								onChange={this.handleBusinessInputChange}
								value="0"
								checked={this.state.enable_discount === "0"}
							  />
							  <span className="radiobtn"></span>
							</label>
						  </li>
						</ul>
						<small className="form-text text-muted">
						  Select a value.
						</small>
					</div>
					{this.state.enable_discount === "1" && (
						<div className="col-md-12 col-lg-12 col-xl-10">
						{/*<div className="form-group">
								<label className="d-block">
									Discount type
								</label>
								<div className="form-line">
									<select
									className="form-control"
									name="discount_type"
									onChange={
									  this.handleBusinessInputChange
									}
									>
									<option
									  value="In Amount"
									  selected={
										this.state.discount_type ===
										"In Amount"
									  }
									>
										  In Amount
									</option>
									<option
									  value="In Percentage"
									  selected={
										this.state.discount_type ===
										"In Percentage"
									  }
									>
									  In Percentage
									</option>					
									</select>
								</div>
						</div>*/}
							<div className="form-group">
								<label className="d-block">
									Discount(%)
								</label>
								<div className="form-line">
									<input
									  type="number"
									  step="any"
									  placeholder=""
									  defaultValue={this.state.discount}
									  onChange={this.handleKitchenInputChange}
									  name="discount"
									  className="form-control"
									/>
								</div>
								{this.state.discounterror != '' && (
								  <small className="text-danger">
									{this.state.discounterror}
								  </small>
								)}
							</div>
							<div className="form-group">
								<label className="d-block">
									Discount start date
								</label>
								<div className="form-line">
									<input
										type='date'
										defaultValue={this.state.discount_start_date}
										className='form-control'
										name='discount_start_date'
										onChange={this.handleMenuInputChange}
										onBlur={this.handleMenuInputChange}
									/>
								</div>
								{this.state.discountstartdateerror != '' && (
								  <small className="text-danger">
									{this.state.discountstartdateerror}
								  </small>
								)}
							</div>
							
							<div className="form-group">
								<label className="d-block">
									Discount end date
								</label>
								<div className="form-line">
									<input
										type='date'
										defaultValue={this.state.discount_end_date}
										className='form-control'
										name='discount_end_date'
										onChange={this.handleMenuInputChange}
										onBlur={this.handleMenuInputChange}
									/>
								</div>
								{this.state.discountenddateerror != '' && (
								  <small className="text-danger">
									{this.state.discountenddateerror}
								  </small>
								)}
							</div>
						</div>
					)}	
				</div>
				
              </div>
            </form>
          </div>
          <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100">
            <button
              type="submit"
              className="btn previous_btn"
              onClick={this.onPreviousKitchenSubmit}
            >
              PREVIOUS
            </button>
            <button
              type="submit"
              className="btn"
              disabled={this.state.loading}
              onClick={this.onAboutKitchenSubmit}
            >
              save and next
            </button>
          </div>
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={config.siteKey}
            onResolved={this.onResolved}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AboutKitchenPage);
