/*
#############################################################################
# Name : ChefOrderInProgress.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to fetch the chef order in progress data and displayed 
#############################################################################
*/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from "../../components/whatsappButton";
import ChefAccountSidebar from "./chefAccountSidebar";
import {
  chefOrderInProgressAPI,
  chefAcceptOrderAPI,
  chefRejectOrderAPI,
  chefOrderSetPreparedAPI,
  chefOrderSetPickedUpAPI,
} from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import ChefOrderToAccept from "./ChefOrderToAccept";
import ChefOrderToPrepared from "./ChefOrderToPrepared";
import ChefOrderToReady from "./ChefOrderToReady";
import Moment from "moment";
import { contains } from "jquery";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { saveOrderCountData } from "../../redux/actions/orderCountActions";

class ChefOrderInProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeIntervalSet: false,
      currentTab: 1,
      isLoading: true,
      toAcceptOrders: [],
      toPreparedOrders: [],
      toReadyOrders: [],
      acceptBtnDisabled: false,
      reasonSubmitBtnDisabled: false,
      reasonSubmitBtnText: "Submit",
      preparedBtnDisabled: false,
      toPrepareOrderCount: 0,
      toDeliverOrderCount: 0,
      showRejectReasonAlert: false,
      rejectReasonRadioInput: "Ingredients not available",
      otherRejectReason: "",
      reasonTextDisabled: true,
      reasonFormErr: "",
      selectedOrderNumber: "",
      pickedUpBtnDisabled: false,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    if (this.props.userData.userId) {
      this.chefOrderInProgressAPI(); // Chef Order in progress data is fetched
      if (localStorage.getItem("currentTab") != null) {
        this.setState({
          currentTab: parseInt(localStorage.getItem("currentTab")),
        });
        localStorage.removeItem("currentTab");
      }
    }
  }

  /* setTimeInterval function starts here */
  setTimeInterval() {
    this.myInterval = setInterval(() => {
      let array = this.state.toAcceptOrders;

      array.forEach((item) => {
        if (item.seconds > 0) {
          item.seconds = item.seconds - 1;
        }
        if (item.hours > 0 || item.minutes > 0 || item.seconds > 0) {
          if (item.seconds === 0) {
            if (item.minutes === 0) {
              if (item.hour === 0) {
                clearInterval(this.myInterval);
              } else {
                item.hours = item.hours - 1;
                item.minutes = 59;
                item.seconds = 59;
              }
            } else {
              item.minutes = item.minutes - 1;
              item.seconds = 59;
            }
          }
        }
      });

      let array2 = this.state.toPreparedOrders;

      array2.forEach((item) => {
        if (item.seconds > 0) {
          item.seconds = item.seconds - 1;
        }
        if (item.hours > 0 || item.minutes > 0 || item.seconds > 0) {
          if (item.seconds === 0) {
            if (item.minutes === 0) {
              if (item.hours === 0) {
                clearInterval(this.myInterval);
              } else {
                item.hour = item.hour - 1;
                item.minutes = 59;
                item.seconds = 59;
              }
            } else {
              item.minutes = item.minutes - 1;
              item.seconds = 59;
            }
          }
        }
      });
      this.setState({
        toAcceptOrders: array,
        toPreparedOrders: array2,
      });
    }, 1000);
  }
  /* setTimeInterval function ends here */

  /* chefOrderInProgressAPI function starts here */
  chefOrderInProgressAPI = () => {
    return chefOrderInProgressAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          let array = response.data.data.to_process_orders;
          array.forEach((item) => {
            var createdDate = new Date();
            var nextDate = new Date(item.expire_at.replace(/\s/, "T"));
            //nextDate.setHours(nextDate.getHours() + 12);
            nextDate = new Date(nextDate);
            var diff = (nextDate - createdDate) / 1000;
            var diff = Math.abs(Math.floor(diff));

            var days = Math.floor(diff / (24 * 60 * 60));
            var leftSec = diff - days * 24 * 60 * 60;

            var hrs = Math.floor(leftSec / (60 * 60));
            var leftSec = leftSec - hrs * 60 * 60;

            var min = Math.floor(leftSec / 60);
            var leftSec = leftSec - min * 60;

            if (Date.parse(createdDate) < Date.parse(nextDate)) {
              item["hours"] = hrs;
              item["minutes"] = min;
              item["seconds"] = leftSec;
            } else {
              item["hours"] = 0;
              item["minutes"] = 0;
              item["seconds"] = 0;
            }
          });
          let array2 = response.data.data.to_prepare_orders;
          array2.forEach((item) => {
            var createdDate = new Date();
            var date = item.selected_time_slot.split(",")[1].includes("st")
              ? item.selected_time_slot.split(",")[1].split("st")[0]
              : item.selected_time_slot.split(",")[1].includes("nd")
              ? item.selected_time_slot.split(",")[1].split("nd")[0]
              : item.selected_time_slot.split(",")[1].includes("rd")
              ? item.selected_time_slot.split(",")[1].split("rd")[0]
              : item.selected_time_slot.split(",")[1].split("th")[0];
            var month = item.selected_time_slot.split(" - ")[0].split(" ")[2];
            var year = item.selected_time_slot.split(" - ")[0].split(" ")[3];
            var time = item.selected_time_slot.split(" - ")[1].split("-")[1];
            var nextDate = new Date(
              date + " " + month + " " + year + ", " + time
            );
            var diff = (nextDate - createdDate) / 1000;
            var diff = Math.abs(Math.floor(diff));

            var days = Math.floor(diff / (24 * 60 * 60));

            var leftSec = diff - days * 24 * 60 * 60;

            var hrs = Math.floor(leftSec / (60 * 60));
            var leftSec = leftSec - hrs * 60 * 60;

            var min = Math.floor(leftSec / 60);
            var leftSec = leftSec - min * 60;

            item["hours"] = hrs + days * 24;
            item["minutes"] = min;
            item["seconds"] = leftSec;
          });

          this.setState(
            {
              toAcceptOrders: array,
              toPreparedOrders: array2,
              toReadyOrders: response.data.data.to_deliver_orders,
              isLoading: false,
            },
            () => {
              var orderDataCount =
                parseInt(this.state.toAcceptOrders.length) +
                parseInt(this.state.toPreparedOrders.length) +
                parseInt(this.state.toReadyOrders.length);
              console.log(orderDataCount);
              this.props.saveOrderCountData(orderDataCount);
              if (!this.state.timeIntervalSet) {
                this.setTimeInterval();
                this.setState({
                  timeIntervalSet: true,
                });
              }
            }
          );
        } else if (response.data["status"] === 401) {
          NotificationManager.error(
            "Session expired. Please log in.",
            "Error!"
          );
          this.props.history.push("/diner-signin-with-otp");
          localStorage.clear();
        } else {
          NotificationManager.error(response.data.message, "Error!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
        this.setState({
          isLoading: false,
        });
      });
  };
  /* chefOrderInProgressAPI function ends here */

  /* handleAcceptOrder function starts here */
  handleAcceptOrder = (orderNumber) => {
    this.setState({
      acceptBtnDisabled: true,
    });
    return chefAcceptOrderAPI(
      this.props.userData.userId,
      orderNumber,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.chefOrderInProgressAPI();
          this.setState({
            acceptBtnDisabled: false,
          });
          NotificationManager.success(
            "The order has been accepted successfully.",
            "Success!"
          );
        } else if (response.data["status"] === 401) {
          NotificationManager.error(
            "Session expired. Please log in.",
            "Error!"
          );
          this.props.history.push("/diner-signin-with-otp");
          localStorage.clear();
        } else {
          this.setState({
            acceptBtnDisabled: false,
          });
          NotificationManager.error(response.data.message, "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          acceptBtnDisabled: false,
        });
        /* NotificationManager.error(error, "Error!"); */
        /* console.log(error); */
      });
  };
  /* handleAcceptOrder function ends here */

  /* handleRejectOrder function starts here */
  handleRejectOrder = (orderNumber) => {
    this.setState({
      // reasonSubmitBtnDisabled: true,
      showRejectReasonAlert: true,
      selectedOrderNumber: orderNumber,
    });
  };
  /* handleRejectOrder function ends here */

  /* handleSetPrepared function starts here */
  handleSetPrepared = (orderNumber) => {
    this.setState({
      preparedBtnDisabled: true,
    });
    return chefOrderSetPreparedAPI(
      this.props.userData.userId,
      orderNumber,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.chefOrderInProgressAPI();
          this.setState({
            preparedBtnDisabled: false,
          });
          NotificationManager.success(
            "The order has been prepared successfully.",
            "Success!"
          );
        } else if (response.data["status"] === 401) {
          NotificationManager.error(
            "Session expired. Please log in.",
            "Error!"
          );
          this.props.history.push("/diner-signin-with-otp");
          localStorage.clear();
        } else {
          this.setState({
            preparedBtnDisabled: false,
          });
          NotificationManager.error(response.data.message, "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          preparedBtnDisabled: false,
        });
        /*  NotificationManager.error(error, "Error!"); */
        /* console.log(error); */
      });
  };
  /* handleSetPrepared function ends here */

  /* handleSetPickedUp function starts here */
  handleSetPickedUp = (orderNumber) => {
    this.setState({
      pickedUpBtnDisabled: true,
    });
    return chefOrderSetPickedUpAPI(
      this.props.userData.userId,
      orderNumber,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.chefOrderInProgressAPI();
          this.setState({
            pickedUpBtnDisabled: false,
          });
          NotificationManager.success(
            "The order has been set as picked up successfully.",
            "Success!"
          );
        } else if (response.data["status"] === 401) {
          NotificationManager.error(
            "Session expired. Please log in.",
            "Error!"
          );
          this.props.history.push("/diner-signin-with-otp");
          localStorage.clear();
        } else {
          this.setState({
            pickedUpBtnDisabled: false,
          });
          NotificationManager.error(response.data.message, "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          pickedUpBtnDisabled: false,
        });
        /* NotificationManager.error(error, "Error!"); */
        /* console.log(error); */
      });
  };
  /* handleSetPickedUp function ends here */

  /* handleRadioInput function starts here */
  handleRadioInput = (e) => {
    if (e.target.value === "Other") {
      this.setState({
        reasonTextDisabled: false,
      });
    } else {
      this.setState({
        reasonTextDisabled: true,
        otherRejectReason: "",
        reasonFormErr: "",
      });
      document.getElementById("reason-textarea").value = "";
    }
    this.setState({
      rejectReasonRadioInput: e.target.value,
    });
  };
  /* handleRadioInput function ends here */

  /* reasonTextareaChange function starts here */
  reasonTextareaChange = (e) => {
    if (e.target.value !== "") {
      this.setState({
        reasonFormErr: "",
        otherRejectReason: e.target.value,
      });
    } else {
      this.setState({
        reasonFormErr: "Please enter the reason.",
        otherRejectReason: e.target.value,
      });
    }
  };
  /* reasonTextareaChange function ends here */

  /* handleReasonSubmit function starts here */
  handleReasonSubmit = () => {
    const { rejectReasonRadioInput, otherRejectReason, selectedOrderNumber } =
      this.state;
    if (rejectReasonRadioInput === "Other" && otherRejectReason === "") {
      this.setState({
        reasonFormErr: "Please enter the reason.",
      });
    }
    setTimeout(() => {
      if (this.state.reasonFormErr === "") {
        this.setState({
          reasonSubmitBtnText: "Submitting...",
          reasonSubmitBtnDisabled: true,
        });
        let data = {
          order_number: selectedOrderNumber,
          reason_for_rejection:
            rejectReasonRadioInput === "Other"
              ? otherRejectReason
              : rejectReasonRadioInput,
        };
        return chefRejectOrderAPI(
          this.props.userData.userId,
          data,
          this.props.userData.accessToken
        )
          .then((response) => {
            if (response.data.status === 200) {
              this.chefOrderInProgressAPI();
              this.setState({
                reasonSubmitBtnText: "Submit",
                reasonSubmitBtnDisabled: false,
                showRejectReasonAlert: false,
                rejectReasonRadioInput: "Ingredients not available",
                otherRejectReason: "",
              });
              NotificationManager.success(
                "The order has been rejected successfully.",
                "Success!"
              );
            } else if (response.data["status"] === 401) {
              NotificationManager.error(
                "Session expired. Please log in.",
                "Error!"
              );
              this.props.history.push("/diner-signin-with-otp");
              localStorage.clear();
            } else {
              this.setState({
                reasonSubmitBtnText: "Submit",
                reasonSubmitBtnDisabled: false,
                showRejectReasonAlert: false,
                rejectReasonRadioInput: "Ingredients not available",
                otherRejectReason: "",
              });
              NotificationManager.error(response.data.message, "Error!");
            }
          })
          .catch((error) => {
            this.setState({
              reasonSubmitBtnText: "Submit",
              reasonSubmitBtnDisabled: false,
              showRejectReasonAlert: false,
              rejectReasonRadioInput: "Ingredients not available",
              otherRejectReason: "",
            });
            /* console.log(error); */
          });
      }
    }, 200);
  };
  /* handleReasonSubmit function ends here */

  render() {
    if (this.props.userData.userType !== "Chef") {
      return (
        <Redirect
          to={
            "/signin-with-otp?redirect=" +
            this.props.location.pathname.split("/")[1]
          }
        />
      );
    }
    const { history, mobileView } = this.props;
    const {
      currentTab,
      isLoading,
      toAcceptOrders,
      toPreparedOrders,
      toReadyOrders,
      acceptBtnDisabled,
      reasonSubmitBtnDisabled,
      reasonSubmitBtnText,
      preparedBtnDisabled,
      showRejectReasonAlert,
      rejectReasonRadioInput,
      otherRejectReason,
      reasonTextDisabled,
      reasonFormErr,
      pickedUpBtnDisabled,
    } = this.state;
    return (
      /*   ; */
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        <section className="chef_flow">
          <div className="container">
            <div className="row justify-content-center">
              <ChefAccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  <div className="chef_right diner_my_acc">
                    <div className="sticky_wrap">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        <Link
                          to="/chef-dashboard"
                          className="res_back_btn desktop_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </Link>
                        Orders in Progress
                      </h6>
                      <div className="chef_tabs">
                        <div className="menu_sticky">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <a
                                className={
                                  currentTab === 1
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                onClick={() =>
                                  this.setState(
                                    {
                                      currentTab: 1,
                                    },
                                    () => {
                                      this.chefOrderInProgressAPI();
                                      window.scroll({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }
                                  )
                                }
                              >
                                To Accept ({toAcceptOrders.length})
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  currentTab === 2
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                onClick={() =>
                                  this.setState(
                                    {
                                      currentTab: 2,
                                    },
                                    () => {
                                      this.chefOrderInProgressAPI();
                                      window.scroll({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }
                                  )
                                }
                              >
                                To Prepare ({toPreparedOrders.length})
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={
                                  currentTab === 3
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                onClick={() =>
                                  this.setState(
                                    {
                                      currentTab: 3,
                                    },
                                    () => {
                                      this.chefOrderInProgressAPI();
                                      window.scroll({
                                        top: 0,
                                        behavior: "smooth",
                                      });
                                    }
                                  )
                                }
                              >
                                Ready ({toReadyOrders.length})
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="Accept"
                        role="tabpanel"
                        aria-labelledby="Accept-tab"
                      >
                        {currentTab === 1 && (
                          <ChefOrderToAccept
                            isLoading={isLoading}
                            toAcceptOrders={toAcceptOrders}
                            setTimeInterval={this.setTimeInterval}
                            handleAcceptOrder={this.handleAcceptOrder}
                            handleRejectOrder={this.handleRejectOrder}
                            acceptBtnDisabled={acceptBtnDisabled}
                          />
                        )}
                        {currentTab === 2 && (
                          <ChefOrderToPrepared
                            isLoading={isLoading}
                            toPreparedOrders={toPreparedOrders}
                            handleRejectOrder={this.handleRejectOrder}
                            handleSetPrepared={this.handleSetPrepared}
                            preparedBtnDisabled={preparedBtnDisabled}
                          />
                        )}
                        {currentTab === 3 && (
                          <ChefOrderToReady
                            isLoading={isLoading}
                            toReadyOrders={toReadyOrders}
                            handleSetPickedUp={this.handleSetPickedUp}
                            pickedUpBtnDisabled={pickedUpBtnDisabled}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showRejectReasonAlert && (
            <div
              className="pins_achieved_modal modal fade enquire_orders request_custom bottom_pop cancel_pop show"
              id="Cancel_pop"
              tabIndex="-1"
              role="dialog"
              style={{ display: "block" }}
              aria-labelledby="pins_achievedTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header justify-content-start flex-column">
                    <h5
                      className="modal-title ml-0 text-uppercase"
                      id="pins_achievedTitle"
                    >
                      select reason
                      <button
                        type="button"
                        className="close"
                        onClick={() =>
                          this.setState({
                            showRejectReasonAlert: false,
                          })
                        }
                        aria-label="Close"
                      >
                        <img
                          src="assets/images/icons/black-close.svg"
                          alt="Close"
                        />
                      </button>
                    </h5>
                    <p>
                      Please select a reason for rejecting/cancelling the order.
                    </p>
                  </div>
                  <div className="modal-body">
                    <hr className="dash_line" />
                    <div className="radio-box">
                      <ul className="radio_list d-block">
                        <li>
                          <div className="custome_check mt-0">
                            <label for="reason_1">
                              Ingredients not available
                              <input
                                id="reason_1"
                                onChange={this.handleRadioInput}
                                type="radio"
                                name="rejectReason"
                                value="Ingredients not available"
                                checked={
                                  rejectReasonRadioInput ===
                                  "Ingredients not available"
                                }
                              />
                              <span className="radiobtn" checked="true"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custome_check">
                            <label for="reason_2">
                              Not adequate advance notice
                              <input
                                id="reason_2"
                                onChange={this.handleRadioInput}
                                type="radio"
                                name="rejectReason"
                                value="Not adequate advance notice"
                                checked={
                                  rejectReasonRadioInput ===
                                  "Not adequate advance notice"
                                }
                              />
                              <span className="radiobtn"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custome_check">
                            <label for="radio_3">
                              Special instructions not possible
                              <input
                                id="radio_3"
                                onChange={this.handleRadioInput}
                                type="radio"
                                name="rejectReason"
                                value="Special instructions not possible"
                                checked={
                                  rejectReasonRadioInput ===
                                  "Special instructions not possible"
                                }
                              />
                              <span className="radiobtn"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custome_check">
                            <label for="radio_4">
                              Order quantity too large
                              <input
                                id="radio_4"
                                onChange={this.handleRadioInput}
                                type="radio"
                                name="rejectReason"
                                value="Order quantity too large"
                                checked={
                                  rejectReasonRadioInput ===
                                  "Order quantity too large"
                                }
                              />
                              <span className="radiobtn"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custome_check">
                            <label for="radio_5">
                              Sold Out
                              <input
                                id="radio_5"
                                onChange={this.handleRadioInput}
                                type="radio"
                                name="rejectReason"
                                value="Sold Out"
                                checked={rejectReasonRadioInput === "Sold Out"}
                              />
                              <span className="radiobtn"></span>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custome_check">
                            <label for="radio_6">
                              Other
                              <input
                                id="radio_6"
                                onChange={this.handleRadioInput}
                                type="radio"
                                name="rejectReason"
                                value="Other"
                                checked={rejectReasonRadioInput === "Other"}
                              />
                              <span className="radiobtn"></span>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="wrap-modal-slider">
                      <form>
                        <div className="form-group">
                          <textarea
                            id="reason-textarea"
                            className="form-control"
                            placeholder="Enter reason here"
                            defaultValue={otherRejectReason}
                            disabled={reasonTextDisabled}
                            onChange={this.reasonTextareaChange}
                          ></textarea>
                          <label className="bottom_label text-right d-block">
                            0/100 characters
                          </label>
                          <span className="text-danger">{reasonFormErr}</span>
                        </div>
                        <div className="form-group text-center mb-0">
                          <button
                            type="button"
                            onClick={this.handleReasonSubmit}
                            disabled={reasonSubmitBtnDisabled}
                            className="btn"
                          >
                            {reasonSubmitBtnText}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showRejectReasonAlert && (
            <div className="modal-backdrop fade show"></div>
          )}
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? null : <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
  orderCountData: state.orderCount.items,
});

export default connect(mapStateToProps, {
  removeMobileView,
  saveOrderCountData,
})(ChefOrderInProgress);
