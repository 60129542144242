import { ADD_ONESIGNAL_ID } from "../actions/types";

const initialState = { items: [] };
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ONESIGNAL_ID:
      return {
        items: action.payload.oneSignalData,
      };

    default:
      return state;
  }
}
