/*
#############################################################################
# Name : Header.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display Chef header component
#############################################################################
*/
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// React Notification
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { removeUserData } from "../redux/actions/authActions";
import { saveChefAlertData } from "../redux/actions/alertActions";
import { getAlertChefDataCountAPI } from "../core/services/Phase2APIservice";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showcontent: false,
      userID: "",
      alertData: "",
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }

  componentDidMount() {
    var userId = "";
    userId = localStorage.getItem("userID");
    if (localStorage.getItem("userID")) {
      this.getAlertData();
    }
    if (this.props.alertChefData) {
      this.setState({
        alertData: this.props.alertChefData,
      });
    }
  }

  logoutHandler = (e) => {
    NotificationManager.success("You have Logout Successfully", "Successful!");
    this.props.removeUserData();
    localStorage.clear();
  };

  storageHandler = (e) => {
    localStorage.clear();
    this.props.removeUserData();
  };

  getAlertData = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getAlertChefDataCountAPI(userId, token)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            alertData: response.data.unread_alerts_count,
          });
          this.props.saveChefAlertData(response.data.unread_alerts_count);
        } else {
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.removeUserData();
            localStorage.clear();
          }
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  };

  getAlert = () => {
    this.props.history.push("/chef-alert");
  };

  render() {
    const { history } = this.props;
    return (
      <header className="header_phase2 normal_logo_header">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="logo">
            <a
              href={
                localStorage.getItem("userVerified") != "1"
                  ? "/chef-homepage"
                  : "/chef-dashboard"
              }
            >
              <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
            </a>
          </div>
          <div className="right d-flex justify-content-end align-items-center">
            {localStorage.getItem("userID") === null ? (
              <Link
                to={"/signup"}
                className="btn register_btn"
                onClick={(e) => this.storageHandler(e)}
              >
                <span className="my_acc_text">REGISTER AS A CHEF</span>
              </Link>
            ) : localStorage.getItem("userVerified") != null ? (
              <Link
                to={"/user-details"}
                className="login_btn my_acc_btn BackBtn"
              >
                <span className="account_img">
                  <img
                    src="/assets/images/icons/account@2x.png"
                    alt="My Account Image"
                    className="img-fluid"
                  />
                </span>
                <span className="my_acc_text">MY ACCOUNT</span>
              </Link>
            ) : null}
            {localStorage.getItem("userVerified") != null ? (
              <ul className="menu">
                <li
                  className={
                    history.location.pathname === "/chef-alert"
                      ? "active"
                      : null
                  }
                >
                  <Link to={"/chef-alert"}>
                    <span className="icon alerts_icon">
                      <img
                        src="/assets/images/icons/bell-header.svg"
                        alt="Bell"
                      />
                      <span
                        className={
                          this.state.alertData && this.props.alertChefData
                            ? "active"
                            : null
                        }
                      >
                        {this.state.alertData && this.props.alertChefData > 0
                          ? this.props.alertChefData
                          : null}
                      </span>
                    </span>
                  </Link>
                  <Link to={"/chef-alert"}>ALERTS</Link>
                </li>
              </ul>
            ) : null}
            {localStorage.getItem("userID") === null ? (
              <Link to={"/signup"} className="login_btn regist_mobile">
                REGISTER
              </Link>
            ) : null}
            {localStorage.getItem("userID") === null ? (
              <Link
                to={"/signin-with-otp"}
                className="login_btn"
                onClick={(e) => this.storageHandler(e)}
              >
                LOG IN
              </Link>
            ) : (
              <Link
                to="/"
                className="login_btn"
                onClick={(e) => this.logoutHandler(e)}
              >
                LOG OUT
              </Link>
            )}
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  alertChefData: state.chefAlert.items,
});

export default connect(mapStateToProps, { saveChefAlertData, removeUserData })(
  withRouter(Header)
);
