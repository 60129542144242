import { SAVE_CHEF_DATA, REMOVE_CHEF_DATA } from "../actions/types";

const initialState = { items: [] };
export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CHEF_DATA:
      return {
        items: action.payload.chefData,
      };

    case REMOVE_CHEF_DATA:
      return {
        items: action.payload.userData,
      };

    default:
      return state;
  }
}
