import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import LoadingDiv from "../../components/LoadingDiv";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {
  BlogDetailData,
  BlogData,
  BlogCategoryData,
} from "../../backendUI/Blog/config";
import { getDays, getMonth } from "../../backendUI/Fun";
import { URLs } from "../../backendUI/Url";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton,
} from "react-share";
const html = `<p></p>`;

class BlogDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromText(html)
      ),
    };
    this.state = {
      isLoading: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      articleTag: [],
      articleTitle: "",
      author: "",
      authorImage: "",
      articleContent: "",
      catgory: "",
      time: "",
      categorydata: [],
      data: [],
    };
  }
  // 63ad512db0441bf02241a2c2
  componentDidMount = () => {
    this.getArticalDetails(this.props.match.params.slug);
    this.getblogcatdata();
    this.getblogdata();
  };
  getArticalDetails = (id) => {
    BlogDetailData(id).then((response) => {
      if (response.success == true) {
        var editorData = response.data.article;
        const blocksFromHtml = htmlToDraft(editorData);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          articleTag: response.data.tags,
          articleTitle: response.data.title,
          editorState: editorState,
          author: response.data.author_detail[0].author,
          authorImage: response.data.author_detail[0].image,
          catgory: response.data.category[0].categoryname,
          time: response.data.updatedAt.split("T")[0],
          isLoading: false,
        });
        console.log(response.data);
      }
    });
  };
  getblogdata = () => {
    BlogData().then((response) => {
      if (response.success == true) {
        this.setState({
          data: response.data,
        });
      }
    });
  };
  getblogcatdata = () => {
    BlogCategoryData().then((response) => {
      if (response.success == true) {
        this.setState({
          categorydata: response.data,
        });
      }
    });
  };
  render() {
    // console.log(window.location.href);
    return (
      <div className="css2">
        <header>
          <div className="container menu_wrapper">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
              </a>
            </div>
            <div id="nav-icon" className=" d-block d-xl-none">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="dinner_menu">
              <li>
                {" "}
                <a href="/" className="login_btn">
                  Home
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/diner-aboutus" className="login_btn">
                  About Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a
                  href="https://wa.me/919899622242"
                  target="_blank"
                  className="login_btn"
                >
                  Contact Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/pick-city" className="login_btn">
                  Pick A City
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/blog" className="login_btn">
                  Blog
                </a>{" "}
              </li>
              <li className="d-block d-xl-none last_btn">
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <ul className="dinner_menu d-none d-xl-block">
              <li>
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <div className="menu_overlay"></div>
          </div>
        </header>
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <div className="container pt-5">
            <div className="row align-self">
              <div className="col-md-10 m-0 p-0">
                <div className="container">
                  <p className="text-center color_a05">{this.state.catgory}</p>
                  <span className="text-center mt-2">
                    <h1 className="color_a05">{this.state.articleTitle}</h1>
                  </span>
                  <div className="d-flex align-items-center justify-content-center row m-0 p-0">
                    <span>POSTED ON</span>
                    <span className="text-center color_a05">
                      &nbsp;
                      {getMonth(this.state.time).toUpperCase() +
                        " " +
                        new Date(this.state.time).getDate() +
                        ", " +
                        new Date(this.state.time).getFullYear()}
                    </span>
                    &nbsp;
                    <span>BY</span> &nbsp;
                    <span className="text-center color_a05">
                      {this.state.author}
                    </span>
                  </div>

                  <Editor
                    toolbarHidden
                    editorState={this.state.editorState}
                    readOnly
                  />
                  <hr />
                  <div className="d-flex align-items-center justify-content-center row m-0 p-0">
                    <span>This entry was posted in </span>&nbsp;
                    <span className="text-center color_a05">
                      {this.state.author}
                    </span>
                    &nbsp; <span>and tagged </span>&nbsp;
                    {this.state.articleTag.length > 0 &&
                      this.state.articleTag.map((item, index) => {
                        return (
                          <>
                            <span className="text-center color_a05">
                              {item}
                              {index == this.state.articleTag.length - 1
                                ? "."
                                : ","}
                              &nbsp;
                            </span>
                          </>
                        );
                      })}
                  </div>

                  <hr />
                  <div className="d-flex align-items-center justify-content-center row m-0 p-0">
                    <FacebookShareButton url={window.location.href}>
                      <img
                        src={URLs.images + "facebook.png"}
                        alt=""
                        // className="img-fluid"
                        style={{ width: 50, height: 50 }}
                      />
                    </FacebookShareButton>
                    &nbsp;
                    <TwitterShareButton url={window.location.href}>
                      <img
                        src={URLs.images + "twiter.png"}
                        alt=""
                        // className="img-fluid"
                        style={{ width: 50, height: 50 }}
                      />
                    </TwitterShareButton>
                    &nbsp;
                    <EmailShareButton url={window.location.href}>
                      <img
                        src={URLs.images + "mail.png"}
                        alt=""
                        // className="img-fluid"
                        style={{ width: 50, height: 50 }}
                      />
                    </EmailShareButton>
                    &nbsp;
                    <PinterestShareButton url={window.location.href}>
                      <img
                        src={URLs.images + "pintrest.png"}
                        alt=""
                        // className="img-fluid"
                        style={{ width: 50, height: 50 }}
                      />
                    </PinterestShareButton>
                    &nbsp;
                    <LinkedinShareButton url={window.location.href}>
                      <img
                        src={URLs.images + "linkdin.png"}
                        alt=""
                        // className="img-fluid"
                        style={{ width: 50, height: 50 }}
                      />
                    </LinkedinShareButton>
                    &nbsp;
                    <TumblrShareButton url={window.location.href}>
                      <img
                        src={URLs.images + "tumbler.png"}
                        alt=""
                        // className="img-fluid"
                        style={{ width: 50, height: 50 }}
                      />
                    </TumblrShareButton>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <p>Latest Post</p>
                {this.state.data.length > 0 &&
                  this.state.data.map((item, index) => {
                    var Dates = item.updatedAt.split("T")[0];

                    return (
                      <>
                        <div className="row mt-2">
                          <div
                            className="d-flex col-3 align-items-center"
                            style={{
                              backgroundColor: "#e53a05",
                            }}
                          >
                            <p
                              className="mt-2 mb-2"
                              style={{
                                color: "#fff",
                                fontSize: 12,
                                textAlign: "center",
                              }}
                            >
                              {new Date(Dates).getDate() +
                                " " +
                                getMonth(Dates)}
                            </p>
                          </div>

                          <div className="col-9" style={{ fontSize: 16 }}>
                            <a href={"/blog/" + item.slug}>
                              <p className="color_a05">{item.title}</p>
                            </a>
                          </div>
                        </div>
                        {index == this.state.data.length - 1 ? null : <hr />}
                      </>
                    );
                  })}

                <p className="mt-3">Categories</p>
                {this.state.categorydata.length > 0 &&
                  this.state.categorydata.map((item, index) => {
                    return (
                      <>
                        <div className="row m-0 p-0 mt-1">
                          <div className="color_a05  m-0 p-0">
                            {item.categoryname}
                          </div>
                          <div className="m-0 p-0">
                            &nbsp;{"("}
                            {item.blogs.length}
                            {")"}
                          </div>
                        </div>
                        {index == this.state.categorydata.length - 1 ? null : (
                          <hr />
                        )}
                      </>
                    );
                  })}
                <hr />
              </div>
            </div>
          </div>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default BlogDetails;
