/*
#############################################################################
# Name : addMenu.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to add new menu item
#############################################################################
*/
import React from 'react';
import {
  getCategoriesAPI,
  getKitchenMaterialAPI,
  getTagsAPI,
  getKitchenMenuAPI,
  getUserSummaryAPI,
  getSavedKitchenMenuAPI
} from '../../core/services/APIservice';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
// React Notification
import { NotificationManager } from 'react-notifications';
import config from '../../config/config';
import Recaptcha from 'react-google-invisible-recaptcha';
import $ from 'jquery';

const numberReg = /^[0-9\b]+$/;
const decimalReg = /^[0-9\.\b]+$/;

class AddMenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSelected: '',
      categoryList: [],
      categorySelected: '',
      categoryDescription: '',
      menuItemName: '',
      menuItemDescription: '',
      menuItemSelected: '',
      menuItemPortionSelected: 'gm',
      menuItemQuantity: '',
      menuItemPrice: '',
      advanceNotice: '',
      menuItemPhoto: null,
      menuItemPhotoName: '',
      menuItemContain: '',
      kitchenMaterialCheckedArray: [],
      chars_left: 200,
      kitchenMaterialArr: [],
      tagsArr: [],
      menuManualSelected: false,
      selectNoneCheckbox: false,
      loading: false,
      menuItemExist: false,
      menuItemDocument: [],
      menuItemDocumentName: [],
      menuItemExcel: '',
      menuItemExcelName: '',
      menuTagSelected: [],
      menuCardData: [],
      portionList: [
        {
          value: 'kg'
        },
        {
          value: 'gm'
        },
        {
          value: 'ltr'
        },
        {
          value: 'pc'
        },
        {
          value: 'portion'
        }
      ],
      showAddMore: false,
      showCard: false,
      errorCategoryMessage: '',
      errorMenuName: '',
      errorMenuDescription: '',
      errorMenuQuantity: '',
      errorMenuPrice: '',
      errorMenuAdvance: '',
      errorMenuKitchen: '',
      errorMenuPhoto: '',
      errorMenuItem: '',
      errorMenuDocumentUpload: '',
      errorMenuExcelUpload: '',
      errorMenuItemPhotoUpload: '',
      goToThankYouPage: false,
      active: false,
      deletePopup: false,
      uploadingDocumentText: false,
      uploadingExcelText: false,
      formErrors: {
        menuItemQuantity: '',
        menuItemPrice: '',
        advanceNotice: ''
      },
      errorDocumentFile: '',
      errorExcelFile: '',
      previousSelectedRadio: '',
      documentUploadFlag: false,
      excelUploadFlag: false
    };
    this.handleMenuItemChange = this.handleMenuItemChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleMenuTextareaChange = this.handleMenuTextareaChange.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.handlePortionChange = this.handlePortionChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  componentDidMount() {
    this.getCategories(); // Categories data is fetched
    this.getKitchenMaterial(); // Kitchen Material data is fetched
    this.getMenuTags(); // Menu Tags data is fetched
    this.getKitchenMenuItemList(); // Kitchen Menu Item List data is fetched
    this.getSavedMenuItem(); // Saved Menu Item data is fetched
    window.scrollTo(0, 0);
  }

  /* getSavedMenuItem function starts here */
  getSavedMenuItem() {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getSavedKitchenMenuAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            menuItemExist: response.data['data']['menu_item_exist']
          });

          if (response.data['data']['document'].length !== 0) {
            this.setState({
              formSelected: 'Document',
              goToThankYouPage: true,
              documentUploadFlag: true
            });
          } else {
            this.setState({ documentUploadFlag: false });
          }
          if (response.data['data']['excel'].length !== 0) {
            response.data['data']['excel'].map((excel, e) => {
              this.setState({
                menuItemExcelName: excel.document_name
              });
            });
            this.setState({
              formSelected: 'Excel',
              goToThankYouPage: true,
              excelUploadFlag: true
            });
          } else {
            this.setState({ excelUploadFlag: false });
          }

          response.data['data']['menu_list'].map((dish, k) => {
            if (dish.menu.length > 0) {
              this.setState({
                formSelected: 'Manually',
                showCard: true,
                goToThankYouPage: true
              });
            }
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  }
  /* getSavedMenuItem function ends here */

  /* onChangeInput function starts here */
  onChangeInput = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case 'menuItemQuantity':
        formErrors.menuItemQuantity = !numberReg.test(e.target.value)
          ? 'Enter valid quantity.'
          : '';
        break;

      case 'menuItemPrice':
        formErrors.menuItemPrice = !decimalReg.test(e.target.value)
          ? 'Enter valid price.'
          : '';
        break;

      case 'advanceNotice':
        formErrors.advanceNotice = !decimalReg.test(e.target.value)
          ? 'Enter valid advance notice.'
          : '';
        break;

      default:
        break;
    }
    if (name === 'menuItemQuantity') {
      this.setState({ errorMenuQuantity: '' });
    }
    if (name === 'menuItemPrice') {
      this.setState({ errorMenuPrice: '' });
    }
    if (name === 'advanceNotice') {
      this.setState({ errorMenuAdvance: '' });
    }
    this.setState({ formErrors, [name]: value });
  };
  /* onChangeInput function ends here */

  /* getCategories function starts here */
  getCategories() {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getCategoriesAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({ categoryList: response.data['data'] });
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  }
  /* getCategories function ends here */

  /* getKitchenMenuItemList function starts here */
  getKitchenMenuItemList() {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getKitchenMenuAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          if (response.data['data']['kitchen_menu_list'].length !== 0) {
            this.setState({
              menuCardData: response.data['data']['kitchen_menu_list'],
              showCard: true,
              showAddMore: true,
              goToThankYouPage: true
            });
          }
        }
      })
      .catch((error) => {
        /*   console.log(error); */
      });
  }
  /* getKitchenMenuItemList function ends here */

  /* getKitchenMaterial function starts here */
  getKitchenMaterial() {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getKitchenMaterialAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({
            kitchenMaterialArr: response.data['data']['menu_material']
          });
          setTimeout(() => {
            this.getFlagInCheckboxData();
          }, 1000);
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  }
  /* getKitchenMaterial function ends here */

  /* getMenuTags function starts here */
  getMenuTags() {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getTagsAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.setState({ tagsArr: response.data['data'] });
        }
      })
      .catch((error) => {
        /*  console.log(error); */
      });
  }
  /* getMenuTags function ends here */

  /* getFlagInCheckboxData function starts here */
  getFlagInCheckboxData() {
    let materials = [];
    this.state.kitchenMaterialArr.forEach((sample) => {
      materials.push({
        ...sample,
        isChecked: false
      });
    });
    this.setState({ kitchenMaterialArr: materials });
  }
  /* getFlagInCheckboxData function ends here */

  /* handleNoneChecked function starts here */
  handleNoneChecked = (event) => {
    console.log(event);
    if (event.target.checked) {
      this.setState({ errorMenuKitchen: '' });
      let materials = this.state.kitchenMaterialArr;
      materials.forEach((sampleMaterial) => {
        sampleMaterial.isChecked = false;
      });
      this.state.kitchenMaterialCheckedArray.push(1);
      this.setState({
        selectNoneCheckbox: true,
        kitchenMaterialArr: materials
      });
    } else {
      this.setState({
        selectNoneCheckbox: false,
        kitchenMaterialCheckedArray: []
      });
    }
  };
  /* handleNoneChecked function ends here */

  /* handleCheckKitchenMaterial function starts here */
  handleCheckKitchenMaterial = (event) => {
    if (event.target.checked) {
      this.setState({ errorMenuKitchen: '' });
      this.state.kitchenMaterialCheckedArray.push(event.target.value);
    } else {
      let filteredArr = this.state.kitchenMaterialCheckedArray.filter(
        (elm) => elm !== event.target.value
      );
      this.setState({ kitchenMaterialCheckedArray: filteredArr });
    }
    let materials = this.state.kitchenMaterialArr;
    materials.forEach((sampleMaterial) => {
      if (sampleMaterial.id === event.target.value)
        sampleMaterial.isChecked = event.target.checked;
    });
    this.setState({ kitchenMaterialArr: materials, selectNoneCheckbox: false });
  };
  /* handleCheckKitchenMaterial function ends here */

  /* handleCategoryChange function starts here */
  handleCategoryChange(event) {
    if (event.target.value) {
      this.setState({ errorCategoryMessage: '' });
    }
    this.setState({ categorySelected: event.target.value });
    this.state.categoryList.map((category) => {
      if (category.id === event.target.value) {
        this.setState({
          categoryDescription: category.description
        });
      }
    });
  }
  /* handleCategoryChange function ends here */

  /* handlePortionChange function starts here */
  handlePortionChange(event) {
    this.setState({
      menuItemPortionSelected: event.target.value,
      menuItemQuantity: ''
    });
  }
  /* handlePortionChange function ends here */

  /* handleMenuTextareaChange function starts here */
  handleMenuTextareaChange(key) {
    const charCount = key.target.value.length;
    const charLeft = 200 - charCount;
    this.setState({ chars_left: charLeft });
    if (key.target.value) {
      this.setState({ errorMenuDescription: '' });
    }
    this.setState({
      [key.target.name]: key.target.value
    });
  }
  /* handleMenuTextareaChange function ends here */

  /* handleItemChange function starts here */
  handleItemChange(event) {
    if (event.target.value) {
      this.setState({ errorMenuItem: '' });
    }
    this.setState({ menuItemSelected: event.target.value });
  }
  /* handleItemChange function ends here */

  /* onChangeMenuPhoto function starts here */
  onChangeMenuPhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;
    for (var x = 0; x < file.length; x++) {
      if (!file[x].name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        this.setState({
          errorMenuItemPhotoUpload:
            'Please select valid file type. ' +
            file[x].name +
            ' is invalid type'
        });
        return false;
      } else {
        this.setState({ errorMenuItemPhotoUpload: '' });
      }
    }
    if (event.target.files) {
      this.setState({ errorMenuPhoto: '' });
    }
    this.setState({
      menuItemPhoto: event.target.files[0]
    });
  };
  /* onChangeMenuPhoto function ends here */

  /* onChangeDocumentPhoto function starts here */
  onChangeDocumentPhoto = (event) => {
    event.preventDefault();
    var file = event.target.files;
    for (var x = 0; x < file.length; x++) {
      if (
        !file[x].name.match(
          /\.(jpg|jpeg|png|txt|doc|docx|pdf|JPG|JPEG|PNG|TXT|DOC|DOCX|PDF)$/
        )
      ) {
        this.setState({
          errorMenuDocumentUpload:
            'Please select valid file type. ' +
            file[x].name +
            ' is invalid type'
        });
        return false;
      } else {
        this.setState({ errorMenuDocumentUpload: '' });
      }
    }
    this.state.menuItemDocument.push(...event.target.files);
  };
  /* onChangeDocumentPhoto function ends here */

  /* removeDocument function starts here */
  removeDocument = (docName) => {
    let filteredArr = this.state.menuItemDocument.filter(
      (elm) => elm.name !== docName
    );
    this.setState({ menuItemDocument: filteredArr });
  };
  /* removeDocument function ends here */

  /* removePhoto function starts here */
  removePhoto = (photoName, event) => {
    event.preventDefault();
    if (photoName == this.state.menuItemPhoto.name) {
      this.setState({
        menuItemPhoto: null
      });
    }
    /*  let filteredArr = this.state.menuItemPhoto.filter(
       (elm) => elm.name !== photoName
     ); */
    //this.setState({ menuItemPhoto: filteredArr });
  };
  /* removePhoto function ends here */

  /* getDocumentUploaded function starts here */
  getDocumentUploaded = () => {
    if (this.state.menuItemDocument.length !== 0) {
      this.setState({
        uploadingDocumentText: true
      });
      var userId = localStorage.getItem('userID');
      var accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      formdata.append('user_id', userId);
      for (const file of this.state.menuItemDocument) {
        formdata.append('menu_documents[]', file, file.name);
      }
      axios
        .post(config.APIURL + 'v1/store-menu-document', formdata, {
          headers: {
            Authorization: accessToken
          }
        })
        .then((response) => {
          if (response.data['status'] === 200) {
            this.setState({
              menuManualSelected: false,
              goToThankYouPage: true,
              errorMenuDocumentUpload: '',
              uploadingDocumentText: false,
              menuItemDocument: []
            });
            NotificationManager.success(
              response.data['message'],
              'Successful!',
              3000
            );
            this.getSavedMenuItem();
          } else {
            NotificationManager.error(response.data['message'], 'Error!');
          }
        })
        .catch((error) => {
          NotificationManager.error('Document Upload Failed', 'Error!');
          this.setState({
            uploadingDocumentText: false
          });
        });
    } else {
      this.setState({
        errorMenuDocumentUpload: 'Document File not selected'
      });
    }
  };
  /* getDocumentUploaded function ends here */

  /* onChangeExcelPhoto function starts here */
  onChangeExcelPhoto = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;
    for (var x = 0; x < file.length; x++) {
      if (!file[x].name.match(/\.(xls|xlsx)$/)) {
        this.setState({
          errorMenuExcelUpload:
            'Please select valid file type. ' +
            file[x].name +
            ' is invalid type'
        });
        return false;
      } else {
        this.setState({ errorMenuExcelUpload: '' });
      }
    }
    this.setState({
      menuItemExcel: event.target.files[0],
      menuItemExcelName: event.target.files[0]['name']
    });
    setTimeout(() => {
      this.getExcelUploaded();
    }, 1000);
  };
  /* onChangeExcelPhoto function ends here */

  /* removeExcelFile function starts here */
  removeExcelFile = () => {
    if (this.state.menuItemExcel !== '') {
      this.setState({
        menuItemExcel: '',
        menuItemExcelName: ''
      });
    }
  };
  /* removeExcelFile function ends here */

  /* getExcelUploaded function starts here */
  getExcelUploaded() {
    if (this.state.menuItemExcel !== '') {
      this.setState({
        uploadingExcelText: true
      });
      var userId = localStorage.getItem('userID');
      var accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      formdata.append('user_id', userId);
      formdata.append(
        'menu_excel',
        this.state.menuItemExcel,
        this.state.menuItemExcelName
      );

      axios
        .post(config.APIURL + 'v1/store-menu-excel', formdata, {
          headers: {
            Authorization: accessToken
          }
        })
        .then((response) => {
          if (response.data['status'] === 200) {
            this.setState({
              menuManualSelected: false,
              goToThankYouPage: true,
              uploadingExcelText: false,
              menuItemExcel: '',
              menuItemExcelName: ''
            });
            NotificationManager.success(
              response.data['message'],
              'Successful!',
              3000
            );
            this.getSavedMenuItem();
          } else {
            NotificationManager.error(response.data['message'], 'Error!');
          }
        })
        .catch((error) => {
          NotificationManager.error('Excel Upload Failed', 'Error!');
          this.setState({
            uploadingExcelText: false
          });
        });
    }
  }
  /* getExcelUploaded function ends here */

  /* handleMenuItemChange function starts here */
  handleMenuItemChange(key) {
    if (key.target.name === 'menuItemName') {
      this.setState({ errorMenuName: '' });
    }
    this.setState({
      [key.target.name]: key.target.value
    });
  }
  /* handleMenuItemChange function ends here */

  /* getTagName function starts here */
  getTagName = (tag) => {
    var newItems = [];

    newItems = this.state.menuTagSelected.filter((elm) => elm === tag);

    if (newItems.length) {
      var array = this.state.menuTagSelected.filter((elm) => elm !== tag);
      this.setState({
        menuTagSelected: array
      });
    } else {
      this.state.menuTagSelected.push(tag);
    }
  };
  /* getTagName function ends here */

  /* showManualYes function starts here */
  showManualYes = () => {
    window.scrollTo(0, 0);
    this.setState({ menuManualSelected: true });
  };
  /* showManualYes function ends here */

  /* showManualNo function starts here */
  showManualNo = () => {
    window.scrollTo(0, 0);
    this.setState({ menuManualSelected: false });
  };
  /* showManualNo function ends here */

  /* onCancel function starts here */
  onCancel = (event) => {
    this.setState({ menuManualSelected: false, showAddMore: true });
  };
  /* onCancel function ends here */

  /* onSubmitForm function starts here */
  onSubmitForm = (event) => {
    event.preventDefault();
    if (this.state.categorySelected === '') {
      this.setState({ errorCategoryMessage: 'Item Category not selected' });
    } else {
      this.setState({
        errorCategoryMessage: ''
      });
    }

    if (this.state.menuItemName === '') {
      this.setState({ errorMenuName: 'Item Name is Required' });
    } else {
      this.setState({
        errorMenuName: ''
      });
    }

    if (this.state.menuItemDescription === '') {
      this.setState({ errorMenuDescription: 'Item Description is Required' });
    } else {
      this.setState({
        errorMenuDescription: ''
      });
    }

    if (this.state.menuItemSelected === '') {
      this.setState({ errorMenuItem: 'Item Menu is Required' });
    } else {
      this.setState({
        errorMenuItem: ''
      });
    }

    if (this.state.menuItemQuantity === '') {
      this.setState({ errorMenuQuantity: 'Item Quantity is Required' });
    } else {
      this.setState({
        errorMenuQuantity: ''
      });
    }

    if (this.state.menuItemPrice === '') {
      this.setState({ errorMenuPrice: 'Item Price is Required' });
    } else {
      this.setState({
        errorMenuPrice: ''
      });
    }

    if (this.state.advanceNotice === '') {
      this.setState({ errorMenuAdvance: 'Advance Notice is Required' });
    } else {
      this.setState({
        errorMenuAdvance: ''
      });
    }

    if (this.state.kitchenMaterialCheckedArray.length === 0) {
      // this.setState({ errorMenuKitchen: "Item Details Are Required" });
    } else {
      this.setState({
        errorMenuKitchen: ''
      });
    }

    if (this.state.menuItemPhoto == null) {
      this.setState({ errorMenuPhoto: 'Menu Photo is Required' });
    } else {
      this.setState({
        errorMenuPhoto: ''
      });
    }

    if (
      this.state.categorySelected !== '' &&
      this.state.menuItemName !== '' &&
      this.state.menuItemDescription !== '' &&
      this.state.menuItemQuantity !== '' &&
      this.state.menuItemPrice !== '' &&
      this.state.advanceNotice !== '' &&
      this.state.menuItemSelected !== '' &&
      this.state.kitchenMaterialCheckedArray.length !== 0 &&
      this.state.menuItemPhoto !== 0
    ) {
      this.recaptcha.execute();
      this.setState({ loading: true });
      var userId = localStorage.getItem('userID');
      var accessToken = localStorage.getItem('accessToken');
      var formdata = new FormData();
      formdata.append('user_id', userId);
      formdata.append('category_id', this.state.categorySelected);
      formdata.append('item_name', this.state.menuItemName);
      formdata.append('item_description', this.state.menuItemDescription);
      formdata.append('diet_type', this.state.menuItemSelected);
      formdata.append('unit', this.state.menuItemPortionSelected);
      formdata.append(
        'standard_quantity_or_portion_size',
        this.state.menuItemQuantity
      );
      formdata.append('item_price', this.state.menuItemPrice);
      formdata.append('advanced_preparation_note', this.state.advanceNotice);
      if (this.state.menuItemPhoto !== '') {
        formdata.append(
          'menu_images[]',
          this.state.menuItemPhoto,
          this.state.menuItemPhoto.name
        );
      }

      if (this.state.selectNoneCheckbox === true) {
        formdata.append('menu_item_none', '1');
        formdata.append('menu_uses_material', []);
      } else {
        formdata.append('menu_item_none', '0');
        formdata.append(
          'menu_uses_material',
          this.state.kitchenMaterialCheckedArray.toString()
        );
      }
      formdata.append('tags', this.state.menuTagSelected.toString());
      axios
        .post(config.APIURL + 'v1/store-kitchen-menu', formdata, {
          headers: {
            Authorization: accessToken
          }
        })
        .then((response) => {
          if (response.data['status'] === 200) {
            this.setState({
              menuManualSelected: false,
              showAddMore: true,
              goToThankYouPage: true,
              loading: false
            });
            this.getKitchenMenuItemList();
            NotificationManager.success('Menu Item Added', 'Successful!', 3000);
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          NotificationManager.error('Menu Item Failed to Add', 'Error!');
        });
    } else {
      this.setState({ loading: false });
      setTimeout(function () {
        var getfilterRowElement = $('.text-danger');
        $('small.text-danger').each(function () {
          var string = $(this).html();
          if (string != '') {
            $('html, body').animate(
              {
                scrollTop: $(this).parent().offset().top + -100
              },
              500
            );
            return false;
          }
        });
      }, 100);
      this.recaptcha.reset();
    }
  };
  /* onSubmitForm function ends here */

  /* showForm function starts here */
  showForm = () => {
    this.setState({
      showAddMore: false,
      menuManualSelected: true,
      selectNoneCheckbox: false,
      categorySelected: '',
      categoryDescription: '',
      menuItemName: '',
      menuItemDescription: '',
      menuItemSelected: '',
      menuItemPortionSelected: 'gm',
      menuItemQuantity: '',
      menuItemPrice: '',
      advanceNotice: '',
      menuItemPhoto: null,
      menuItemPhotoName: '',
      menuItemContain: '',
      kitchenMaterialCheckedArray: [],
      menuTagSelected: []
    });
    this.getKitchenMaterial();
  };
  /* showForm function ends here */

  /* getTagUnselested function starts here */
  getTagUnselested = (event) => {
    event.preventDefault();
  };
  /* getTagUnselested function ends here */

  /* getThankYouPage function starts here */
  getThankYouPage = (event) => {
    event.preventDefault();
    if (this.state.goToThankYouPage === true) {
      var userId = localStorage.getItem('userID');
      var accessToken = localStorage.getItem('accessToken');
      return getUserSummaryAPI(userId, accessToken)
        .then((response) => {
          if (response.data['status'] === 200) {
            this.props.parentCallback(response.data['data']);
            localStorage.setItem('currentStatus', 6);
          }
        })
        .catch((error) => {
          /* console.log(error); */
        });
    } else {
      NotificationManager.error('Please Add Menu Item', 'Error!');
    }
  };
  /* getThankYouPage function ends here */

  /* logoutHandler function starts here */
  logoutHandler = (e) => {
    localStorage.clear();
    this.props.history.push('/');
    NotificationManager.success(
      'You have Logout Successfully',
      'Successful!',
      3000
    );
  };
  /* logoutHandler function ends here */

  /* handleRadioChange function starts here */
  handleRadioChange(event) {
    this.setState({ previousSelectedRadio: this.state.formSelected });
    if (event.target.value === 'Manually') {
      if (this.state.showCard === true) {
        this.setState({
          menuManualSelected: false
        });
      } else {
        this.setState({
          menuManualSelected: true
        });
      }
    } else {
      this.setState({
        menuManualSelected: false
      });
    }

    if (this.state.menuItemExist === true) {
      this.setState({
        deletePopup: true
      });
    } else {
      this.setState({
        deletePopup: false
      });
    }

    if (
      this.state.documentUploadFlag === true ||
      this.state.excelUploadFlag === true ||
      this.state.showCard === true
    ) {
      this.setState({
        deletePopup: true
      });
    }
    this.setState({
      formSelected: event.target.value
    });
  }
  /* handleRadioChange function ends here */

  /* onPreviousBusinessSubmit function starts here */
  onPreviousBusinessSubmit = (event) => {
    event.preventDefault();
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getUserSummaryAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.props.parentCallback(response.data['data']);
          localStorage.setItem('currentStatus', 3);
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* onPreviousBusinessSubmit function ends here */

  /* logoutHandler function starts here */
  logoutHandler = (e) => {
    NotificationManager.success('You have Logout Successfully', 'Successful!');
    localStorage.clear();
  };
  /* logoutHandler function ends here */

  /* onClickSaveSection function starts here */
  onClickSaveSection = () => {
    var userId = localStorage.getItem('userID');
    var accessToken = localStorage.getItem('accessToken');
    return getUserSummaryAPI(userId, accessToken)
      .then((response) => {
        if (response.data['status'] === 200) {
          this.props.parentCallback(response.data['data']);
          localStorage.setItem('currentStatus', 4);
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* onClickSaveSection function ends here */

  /* hidePopup function starts here */
  hidePopup = (event) => {
    event.preventDefault();
    this.setState({
      deletePopup: false,
      previousSelectedRadio: this.state.formSelected
    });
  };
  /* hidePopup function ends here */

  /* hidePopCancelPopup function starts here */
  hidePopCancelPopup = (event) => {
    event.preventDefault();
    this.setState({
      deletePopup: false,
      formSelected: this.state.previousSelectedRadio
    });
    setTimeout(function () {
      var elm = $('input:radio.radio_acco:checked');
      $('html, body').animate(
        {
          scrollTop: $(elm).parent().offset().top + -100
        },
        500
      );
    }, 100);
  };
  /* hidePopCancelPopup function ends here */

  /* onResolved function starts here */
  onResolved() {
    alert('Recaptcha resolved with response: ' + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    const { formErrors } = this.state;
    return (
      <div className='registration bussiness save_menu new_menu menu_member1 p-0'>
        <div className='abt_form'>
          <h4 className='d-flex justify-content-between align-items-center'>
            <button
              className='save_btn back_btn'
              onClick={this.onPreviousBusinessSubmit}
            >
              <img
                src='assets/images/icons/left-arrow.png'
                alt='Left Arrow Icon'
                className='img-fluid'
              />
              Back
            </button>
            <span className='text-uppercase text-center text-md-left'>
              MENU
            </span>
            <button className='save_btn disabled'>Save</button>
          </h4>
          <p className='sub_title'>
            Note : Your information has been saved!{' '}
            <br className='d-block d-lg-none' /> You can now{' '}
            <a
              href='/'
              className='logout_link'
              onClick={(e) => this.logoutHandler(e)}
            >
              logout
            </a>{' '}
            and log back in to resume completing the form at your convenience.
          </p>
          <div className='inner'>
            <div className='box border-0'>
              <div className='btn-group d-flex justify-content-center save_add_btn_grp'>
                <div>
                  <button className='btn add_menu_btn active'>
                    Add to Menu
                  </button>
                </div>
                <div>
                  <button
                    className='btn save_menu_btn'
                    onClick={this.onClickSaveSection}
                  >
                    Saved Menu
                  </button>
                </div>
              </div>

              <p className='save_btn_bot_para'>
                Please choose one of the options below.{' '}
              </p>
            </div>
            <ul id='accordion'>
              <li>
                <input
                  type='radio'
                  id='first'
                  className='radio_acco'
                  name='radioPopUp'
                  onChange={this.handleRadioChange}
                  value='Manually'
                  checked={this.state.formSelected === 'Manually'}
                />
                <label className='custome_check label_title' htmlFor='first'>
                  <span className='title color_C07 d-flex align-items-center'>
                    Enter menu items
                  </span>
                  <span className='radiobtn'></span>
                  <span className='label_para'>
                    Fastest way to get listed! Directly enter each menu item.
                  </span>
                </label>
                <div className='content'>
                  <div className='inner'>
                    {this.state.showCard === true ? (
                      <div className='box show-card'>
                        {this.state.menuCardData.map((card, i) => {
                          return (
                            <div className='new_shadow' key={'gdffdg' + i}>
                              <div className='top_edit_wrap'>
                                <div className='img_wrap'>
                                  <img
                                    src={card.menu_image}
                                    alt='Snack'
                                    className='img-fluid'
                                  />
                                </div>
                                <div className='text_wrap'>
                                  <h6>{card.item_name}</h6>
                                  <h6 className='font-weight-normal'>
                                    INR {card.item_price}
                                  </h6>
                                  <p>{card.item_description}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {this.state.menuManualSelected === true ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                        className='mt-4'
                      >
                        <div className='row'>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group yellow_arrow_icon'>
                              <select
                                className='form-control'
                                value={this.state.categorySelected}
                                onChange={this.handleCategoryChange}
                              >
                                <option value=''>Item Category*</option>
                                {this.state.categoryList.map((category, i) => {
                                  return (
                                    <option
                                      value={category.id}
                                      key={'sdfxw' + i}
                                    >
                                      {category.category_name}
                                    </option>
                                  );
                                })}
                              </select>
                              {this.state.categoryDescription === '' ? (
                                <small className='form-text text-muted'>
                                  Select a category.
                                </small>
                              ) : (
                                <small className='form-text text-muted'>
                                  {this.state.categoryDescription}
                                </small>
                              )}
                              <small className='form-text text-muted d-none'>
                                Includes starters, snacks and small bites.
                              </small>
                              <small className='text-danger'>
                                {this.state.errorCategoryMessage
                                  ? this.state.errorCategoryMessage
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Item Name*'
                                name='menuItemName'
                                value={this.state.menuItemName}
                                onChange={this.handleMenuItemChange}
                              />
                              <small className='text-danger'>
                                {this.state.errorMenuName
                                  ? this.state.errorMenuName
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group item_textarea'>
                              <textarea
                                className='form-control'
                                placeholder='Item description*'
                                name='menuItemDescription'
                                value={this.state.menuItemDescription}
                                onChange={this.handleMenuTextareaChange}
                                maxLength='200'
                              ></textarea>
                              <small className='form-text text-muted text-right'>
                                {this.state.chars_left} characters
                              </small>
                              <small className='text-danger'>
                                {this.state.errorMenuDescription
                                  ? this.state.errorMenuDescription
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group radio_wrap'>
                              <label className='d-block'>This item is:*</label>
                              <ul className='radio_list'>
                                <li>
                                  <label className='custome_check'>
                                    Veg
                                    <input
                                      type='radio'
                                      name='itemVeg'
                                      onChange={this.handleItemChange}
                                      value='Veg'
                                      checked={
                                        this.state.menuItemSelected === 'Veg'
                                      }
                                    />
                                    <span className='radiobtn'></span>
                                  </label>
                                </li>
                                <li>
                                  <label className='custome_check'>
                                    Non Veg
                                    <input
                                      type='radio'
                                      name='itemNonVeg'
                                      onChange={this.handleItemChange}
                                      value='Non Veg'
                                      checked={
                                        this.state.menuItemSelected ===
                                        'Non Veg'
                                      }
                                    />
                                    <span className='radiobtn'></span>
                                  </label>
                                </li>
                                <li>
                                  <label className='custome_check'>
                                    Contains Egg
                                    <input
                                      type='radio'
                                      name='itemEgg'
                                      onChange={this.handleItemChange}
                                      value='Contains Egg'
                                      checked={
                                        this.state.menuItemSelected ===
                                        'Contains Egg'
                                      }
                                    />
                                    <span className='radiobtn'></span>
                                  </label>
                                </li>
                              </ul>
                              <small className='text-danger'>
                                {this.state.errorMenuItem
                                  ? this.state.errorMenuItem
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group'>
                              <label>Standard Quantity or portion size*</label>
                              <div className='select_wrap gms'>
                                <div className='small_select'>
                                  <select
                                    className='form-control'
                                    value={this.state.menuItemPortionSelected}
                                    onChange={this.handlePortionChange}
                                  >
                                    {this.state.portionList.map(
                                      (portion, i) => {
                                        return (
                                          <option
                                            value={portion.value}
                                            key={'ewtu' + i}
                                          >
                                            {portion.value}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                                <input
                                  type='tel'
                                  className='form-control tel_number'
                                  placeholder={
                                    this.state.menuItemPortionSelected ===
                                    'portion'
                                      ? 'Serves how many people'
                                      : 'Item Quantity*'
                                  }
                                  name='menuItemQuantity'
                                  value={this.state.menuItemQuantity}
                                  onChange={this.onChangeInput}
                                  maxLength='4'
                                />
                              </div>
                              <small className='form-text text-muted'>
                                Select unit and enter quantity or portion size.
                              </small>
                              <small className='text-danger'>
                                {formErrors.menuItemQuantity}
                              </small>
                              <small className='text-danger'>
                                {this.state.errorMenuQuantity
                                  ? this.state.errorMenuQuantity
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group'>
                              <div className='mobile_no_wrap'>
                                <div className='form-control countrycode'>
                                  <span className='code'>INR</span>
                                </div>
                                <input
                                  type='tel'
                                  className='form-control tel_number'
                                  placeholder='Item Price*'
                                  name='menuItemPrice'
                                  value={this.state.menuItemPrice}
                                  onChange={this.onChangeInput}
                                  maxLength='6'
                                />
                              </div>
                              <small className='form-text text-muted'>
                                Please enter value in INR.
                              </small>
                              <small className='text-danger'>
                                {formErrors.menuItemPrice}
                              </small>
                              <small className='text-danger'>
                                {this.state.errorMenuPrice
                                  ? this.state.errorMenuPrice
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group '>
                              <label>
                                Advance notice required to prepare the item*
                              </label>
                              <input
                                type='tel'
                                className='form-control'
                                name='advanceNotice'
                                value={this.state.advanceNotice}
                                onChange={this.onChangeInput}
                                maxLength='2'
                              />
                              <small className='form-text text-muted'>
                                Please enter value in hours.
                              </small>
                              <small className='text-danger'>
                                {formErrors.advanceNotice}
                              </small>
                              <small className='text-danger'>
                                {this.state.errorMenuAdvance
                                  ? this.state.errorMenuAdvance
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group'>
                              <label className='d-block'>
                                Upload a photo of the item*
                              </label>
                              <div className='fileUpload d-flex'>
                                <button className='fileUploadBtn form-control d-inline-flex'></button>
                                <div className='d-inline-flex align-items-center form-control'>
                                  <span className='file_text'>
                                    Choose file
                                    <small>No file selected</small>
                                  </span>
                                  <input
                                    type='file'
                                    className='uploadlogo'
                                    name='menu_photo'
                                    onChange={this.onChangeMenuPhoto}
                                  />
                                </div>
                              </div>
                              <small className='form-text text-muted'>
                                One file only.
                                <br className='d-sm-none' /> 64 MB limit.
                                Allowed types: png gif jpg jpeg.
                              </small>
                              <small className='text-danger'>
                                {this.state.errorMenuItemPhotoUpload
                                  ? this.state.errorMenuItemPhotoUpload
                                  : ''}
                              </small>
                              <small className='text-danger'>
                                {this.state.errorMenuPhoto
                                  ? this.state.errorMenuPhoto
                                  : ''}
                              </small>

                              <div className='form-group festive_tags upload_tags'>
                                {/* <ul>
                                  {this.state.menuItemPhoto.map((item, i) => {
                                    return <li key={'cxdgkkh' + i}>{item.name.slice(-20)}<button className="close" onClick={this.removePhoto.bind(this, item.name)}></button></li>;

                                  })}
                                </ul> */}
                                <ul>
                                  {this.state.menuItemPhoto ? (
                                    <li>
                                      {this.state.menuItemPhoto.name.slice(-20)}
                                      <button
                                        className='close'
                                        onClick={this.removePhoto.bind(
                                          this,
                                          this.state.menuItemPhoto.name
                                        )}
                                      ></button>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>

                              {/* <small className="form-text text-muted">
                                {this.state.menuItemPhoto.map((item, i) => {
                                  return <p key={''ylrke' + i}>{item.name.slice(-20)}</p>;
                                })}{" "}
                              </small> */}
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group checklist_wrap'>
                              <label>Does this item contain:*</label>
                              <ul className='contn_itm'>
                                {this.state.kitchenMaterialArr.map(
                                  (materials, i) => {
                                    return (
                                      <li key={'blfk' + i}>
                                        <label className='custome_check'>
                                          <input
                                            key={materials.id}
                                            onClick={
                                              this.handleCheckKitchenMaterial
                                            }
                                            type='checkbox'
                                            checked={materials.isChecked}
                                            value={materials.id}
                                          />
                                          <span className='checkmark'></span>
                                          {materials.material_name}
                                        </label>
                                      </li>
                                    );
                                  }
                                )}
                                <li>
                                  <label className='custome_check'>
                                    <input
                                      type='checkbox'
                                      onClick={this.handleNoneChecked}
                                      value='checkedall'
                                      checked={this.state.selectNoneCheckbox}
                                    />
                                    <span className='checkmark'></span>
                                    None of the above
                                  </label>
                                </li>
                              </ul>
                              <small className='form-text text-muted'>
                                Select all that apply.
                              </small>
                              <small className='text-danger mt-0'>
                                {this.state.errorMenuKitchen
                                  ? this.state.errorMenuKitchen
                                  : ''}
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group festive_tags'>
                              <label>Festive Special Tags</label>
                              <ul>
                                {this.state.tagsArr.map((tag, i) => {
                                  return (
                                    <li
                                      key={'cllq' + i}
                                      className={
                                        this.state.menuTagSelected.filter(
                                          (elm) => elm === tag.tag_name
                                        ).length > 0
                                          ? 'active'
                                          : 'ddsd'
                                      }
                                      onClick={() =>
                                        this.getTagName(tag.tag_name)
                                      }
                                    >
                                      {tag.tag_name}
                                      <button
                                        className='close'
                                        onClick={this.getTagUnselested}
                                      ></button>
                                    </li>
                                  );
                                })}
                              </ul>
                              <small className='form-text text-muted'>
                                Please select the festive occasion for your dish
                                if applicable.
                                <br />
                                Select all that apply.
                              </small>
                            </div>
                          </div>
                          <div className='col-md-12 col-lg-12 col-xl-10'>
                            <div className='form-group save_btn_next btn-group can_itm_grp d-flex mw-100 mb-0'>
                              <button
                                type='submit'
                                className='btn previous_btn cancel_btn'
                                onClick={this.onCancel}
                              >
                                cancel
                              </button>
                              <button
                                type='submit'
                                className='btn'
                                disabled={this.state.loading}
                                onClick={this.onSubmitForm}
                              >
                                save item
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : null}
                    {this.state.showAddMore === true ? (
                      <div className='form-group mb-0'>
                        <button
                          className='add_another_btn d-flex align-items-center'
                          onClick={this.showForm}
                        >
                          <span className='plus_icon'></span>
                          <span>Add another item</span>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </li>
              {localStorage.getItem('userVerified') == null ||
              localStorage.getItem('userVerified') == 'null' ? (
                <li>
                  <input
                    type='radio'
                    id='two'
                    className='radio_acco'
                    name='radioPopUp'
                    onChange={this.handleRadioChange}
                    value='Document'
                    checked={this.state.formSelected === 'Document'}
                  />
                  <label className='custome_check label_title' htmlFor='two'>
                    <span className='title color_C07 d-flex align-items-center'>
                      Upload menu
                    </span>
                    <span className='radiobtn'></span>
                    <span className='label_para'>
                      Upload a digital/soft copy of your menu.
                    </span>
                  </label>
                  <div className='content'>
                    <div className='inner'>
                      <div className='upload_text'>
                        <p>
                          To ensure that you are listed with Chef Pin in the
                          shortest time possible, please try to include the
                          following details in your menu:
                        </p>
                        <ul className='list'>
                          <li>Item name</li>
                          <li>Item description</li>
                          <li>Standard quantity or portion size</li>
                          <li>Item price</li>
                          <li>Advance notice required to prepare the item</li>
                        </ul>
                        <div className='form-group mb-0'>
                          <label className='d-block'>Upload your menu</label>
                          <div className='fileUpload d-flex'>
                            <button className='fileUploadBtn form-control d-inline-flex'></button>
                            <div className='d-inline-flex align-items-center form-control'>
                              <span className='file_text'>
                                Choose file <small>No file selected</small>
                              </span>
                              <input
                                type='file'
                                className='uploadlogo'
                                name='document_photo'
                                onChange={this.onChangeDocumentPhoto}
                                multiple
                              />
                            </div>
                          </div>
                          <small className='form-text text-muted'>
                            Unlimited number of files can be uploaded to this
                            field. <br />
                            64 MB limit. Allowed types: txt pdf doc docx png jpg
                            jpeg.
                          </small>
                          {this.state.uploadingDocumentText ? (
                            <small className='form-text red_000'>
                              Document getting uploading ...
                            </small>
                          ) : null}
                          <small className='text-danger'>
                            {this.state.errorMenuDocumentUpload
                              ? this.state.errorMenuDocumentUpload
                              : ''}
                          </small>
                          <div className='form-group festive_tags upload_tags'>
                            <ul>
                              {this.state.menuItemDocument.map((item, i) => {
                                return (
                                  <li key={'cxjsi' + i}>
                                    {item.name.slice(-20)}
                                    <button
                                      className='close'
                                      onClick={() =>
                                        this.removeDocument(item.name)
                                      }
                                    ></button>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                          {/* <div className="form-group festive_tags upload_tags">
                          <ul>
                          {this.state.menuItemDocumentName.map((item, i) => {
                            return <li key={'afafac' + i}>{item.name}<button className="close" onClick={() => this.removeDocument(item.name)}></button></li>;

                          })}
                          
                          </ul>
                        </div> */}
                        </div>
                        <div className='form-group text-right'>
                          <button
                            type='submit'
                            className='btn'
                            onClick={this.getDocumentUploaded}
                          >
                            upload document
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : null}
              {localStorage.getItem('userVerified') == null ||
              localStorage.getItem('userVerified') == 'null' ? (
                <li>
                  <input
                    type='radio'
                    name='accordion'
                    id='third'
                    className='radio_acco'
                    name='radioPopUp'
                    onChange={this.handleRadioChange}
                    value='Excel'
                    checked={this.state.formSelected === 'Excel'}
                  />
                  <label className='custome_check label_title' htmlFor='third'>
                    <span className='title color_C07 d-flex align-items-center'>
                      Fill an excel sheet
                    </span>
                    <span className='radiobtn'></span>
                    <span className='label_para'>
                      Download a sample excel sheet and fill in your menu
                      details. When completed, re-upload the file.
                    </span>
                  </label>
                  <div className='content'>
                    <div className='inner'>
                      <div className='upload_text'>
                        <a
                          href='assets/doc/MenuTemplate.xlsx'
                          className='download_sample_link'
                        >
                          <img
                            src='assets/images/icons/document.png'
                            alt='Document Icon'
                            className='img-fluid'
                          />
                          Download sample
                        </a>
                        <div className='form-group mb-0'>
                          <div className='fileUpload d-flex'>
                            <button className='fileUploadBtn form-control d-inline-flex'></button>
                            <div className='d-inline-flex align-items-center form-control'>
                              {this.state.menuItemExcelName === '' ? (
                                <span className='file_text'>
                                  Choose file
                                  <small>No file selected</small>
                                </span>
                              ) : (
                                <div className='festive_tags upload_tags'>
                                  <ul>
                                    <li className='file_text'>
                                      {this.state.menuItemExcelName.slice(-20)}
                                    </li>
                                  </ul>
                                </div>
                              )}
                              <input
                                type='file'
                                className='uploadlogo'
                                name='excel_photo'
                                onChange={this.onChangeExcelPhoto}
                              />
                            </div>
                          </div>
                          <small className='form-text text-muted'>
                            Only one file can be uploaded to this field. <br />
                            64 MB limit. Allowed types: xls xlsx.
                          </small>
                          {this.state.uploadingExcelText ? (
                            <small className='form-text red_000'>
                              Excel getting uploading ...
                            </small>
                          ) : null}
                          <small className='text-danger'>
                            {this.state.errorMenuExcelUpload
                              ? this.state.errorMenuExcelUpload
                              : ''}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ) : null}
            </ul>
          </div>
          {localStorage.getItem('userVerified') == null ||
          localStorage.getItem('userVerified') == 'null' ? (
            <div className='form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100'>
              <button
                type='submit'
                className='btn previous_btn'
                onClick={this.onPreviousBusinessSubmit}
              >
                PREVIOUS
              </button>
              <button
                type='submit'
                className='btn'
                onClick={this.getThankYouPage}
              >
                save and next
              </button>
            </div>
          ) : <div className='form-group save_btn_next btn-group d-flex justify-content-center justify-content-md-end align-items-end mw-100'>
              <button
                type='submit'
                className='btn previous_btn'
                onClick={this.onPreviousBusinessSubmit}
              >
                PREVIOUS
              </button>
              <button
                type='submit'
                className='btn'
                onClick={this.getThankYouPage}
              >
                save and next
              </button>
            </div>
		}
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={config.siteKey}
            onResolved={this.onResolved}
          />
        </div>
        {this.state.deletePopup ? (
          <div
            className='modal fade popup can_del_pop show'
            id='LeaveModal'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='LeaveModalTitle'
            style={{ display: 'block', paddingRight: '17px' }}
          >
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content'>
                <div className='modal-body text-center'>
                  <h5 className='color_C07'>
                    You have already added your menu. Proceeding further will
                    delete your previously saved menu.
                  </h5>
                  <div className='btn-group'>
                    <div>
                      <button
                        className='btn previous_btn'
                        onClick={this.hidePopup}
                      >
                        proceed
                      </button>
                    </div>
                    <div>
                      <button className='btn' onClick={this.hidePopCancelPopup}>
                        cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.deletePopup ? (
          <div className='modal-backdrop fade show'></div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(AddMenuPage);
