/*
#############################################################################
# Name : ManageAddress.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display and edit the address
#############################################################################
*/
import React, { Component } from "react";
import AccountSidebar from "./AccountSidebar";
import {
  getDinerAddressAPI,
  deleteDinerAddressAPI,
  updateDinerLocationAPI
} from "../../../../core/services/Phase2APIservice";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import LoadingDiv from "../../../../components/LoadingDiv";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import { removeMobileView } from "../../../../redux/actions/chefMenuActions";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import Geocode from "react-geocode";
import config from "../../../../config/config";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class ManageAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      homeAddress: [],
      workAddress: [],
      otherAddress: [],
      deleteAddressAlert: false,
      deleteBtnText: "Yes, delete",
      deleteItemId: "",
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      address: "",
      location: "",
      locationName: "",
      locationImage: "",
      latitude: "",
      longitude: "",
      mapLatitude: "",
      mapLongitude: "",
      showMap: false,
      markers: [
        {
          name: "",
          position: {
            lat: "",
            lng: "",
          },
        },
      ],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      fullAddress: "",
      landMark: "",
      addressSaveAs: "",
      otherText: "",
      pincode: "",
      dragLat: "",
      dragLng: "",
      userId: "",
      formErrors: {
        fullAddress: "",
      },
    };
    this.handleMapPopupChange = this.handleMapPopupChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }
  componentDidMount() {
    Geocode.setApiKey(config.placesKey);        //Geocode setAPIKey used to pass google places key
    this.getDinerAddressAPI();        // Diner Address data isfetched
  }

  /* handleMapPopupChange function starts here */
  handleMapPopupChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "fullAddress":
        formErrors.fullAddress = value === "" ? "Input is required" : "";
        break;

      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
/* handleMapPopupChange function ends here */

/* handleRadioChange function starts here */
handleRadioChange(event) {
  this.setState({ addressSaveAs: event.target.value });
}
/* handleRadioChange function ends here */

/* handleChange function starts here */
handleChange = (address) => {
  this.setState({ address });
};
/* handleChange function ends here */

/* closeMapPopup function starts here */
closeMapPopup = (event) => {
  event.preventDefault();
  this.setState({
    showMap: false,
    address: "",
  });
};
/* closeMapPopup function ends here */

/* moveMarker function starts here */
moveMarker = (props, marker, e, index) => {
  const lat = e.latLng.lat();
  const lng = e.latLng.lng();
  this.setState({
    latitude: lat,
    longitude: lng,
  });
  Geocode.fromLatLng(lat, lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      this.setState({
        selectedPlace: address,
        activeMarker: marker,
        showingInfoWindow: true,
        address: address,
      });
      response.results[0].address_components.forEach((item) => {
        item.types.forEach((type) => {
          if (type === "postal_code") {
            this.setState({
              pincode: item.long_name,
            });
          }
        });
      });
    },
    (error) => {
   /*    console.error(error); */
    }
  );
};
/* moveMarker function ends here */

/* onClose function starts here */
onClose = (props) => {
  if (this.state.showingInfoWindow) {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null,
    });
  }
};
/* onClose function ends here */

/* saveData function starts here */
saveData = (event) => {
  event.preventDefault();
  const { name, value } = event.target;
  let formErrors = this.state.formErrors;
  if (this.state.fullAddress === "") {
    formErrors.fullAddress = "Input is required";
  }
  this.setState({ formErrors, [name]: value });
  if (formValid(this.state)) {
    var token = localStorage.getItem("accessToken");
   /*  if (this.state.landMark != "") {
      var addressSelected =
        this.state.fullAddress +
        ", " +
        this.state.landMark +
        ", " +
        this.state.address;
    } else {
      var addressSelected =
        this.state.fullAddress + ", " + this.state.address;
    } */
      let editAddressArr = JSON.parse(localStorage.getItem("editAddressArray"));
      let locationData = {
        user_id: editAddressArr.user_id,
        location_id: editAddressArr.location_id,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        city: editAddressArr.city,
        location: this.state.address,
        pincode: this.state.pincode,
        type: this.state.addressSaveAs,
        location_title: this.state.otherText,
        address_line1: this.state.fullAddress,
        address_line2: this.state.landMark,
      };
      return updateDinerLocationAPI(locationData, token)
        .then((response) => {
          if (response.data["status"] === 200) {
            if (this.state.otherText) {
              localStorage.setItem("addressDetailType",this.state.otherText);
            }else{
              localStorage.removeItem("addressDetailType");
            }
            localStorage.setItem("selectedLocation", editAddressArr.city);
            localStorage.setItem("flatNo", this.state.fullAddress);
            localStorage.setItem("landmark", this.state.landMark);
            localStorage.setItem("addressSelected", this.state.address);
            localStorage.setItem("addressType", this.state.addressSaveAs);
            localStorage.setItem("pincode", this.state.pincode);
            localStorage.setItem("latitude", this.state.latitude);
            localStorage.setItem("longitude", this.state.longitude);
            this.setState({
              showMap: false
            });
            this.getDinerAddressAPI();  
            this.props.history.push('/manage-address');
            /* window.location.reload(); */
            NotificationManager.success(
              response.data["message"],
              "Successful!",
              3000
            );
          }  else {
            if (response.status === 401) {
              NotificationManager.error(
                "Session expired. Please log in.",
                "Error!"
              );
              this.props.history.push("/diner-signin-with-otp");
              localStorage.clear();
            }
          }
        })
        .catch((error) => {
          /* console.log(error); */
        });
  } else {
    /* console.log("error"); */
  }
};
/* saveData function ends here */

  /* getDinerAddressAPI function starts here */
  getDinerAddressAPI() {
    return getDinerAddressAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            homeAddress: response.data.data.home_location,
            workAddress: response.data.data.work_location,
            otherAddress: response.data.data.other_location,
            isLoading: false,
          });
        } else {
          /* NotificationManager.error(response.data.message, "Error!"); */
          if (response.status === 401) {
            NotificationManager.error("Session expired. Please log in.", "Error!");
            this.props.history.push('diner-signin-with-otp');
            localStorage.clear();
        }else{
          NotificationManager.error(response.data.message, "Error!");
        }
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* NotificationManager.error(error, "Error!"); */
        this.setState({
          isLoading: false,
        });
      });
  }
 /* getDinerAddressAPI function ends here */

  /* handleDeleteAddress function starts here */
  handleDeleteAddress = () => {
    this.setState({
      deleteBtnText: "Deletting...",
    });
    var userId = this.props.userData.userId;
    var token = this.props.userData.accessToken;
    return deleteDinerAddressAPI(userId, this.state.deleteItemId, token)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            deleteAddressAlert: false,
            deleteBtnText: "Yes, Delete it",
          });
          document.getElementById("closeMenuFormButton").click();
          NotificationManager.success(
            "Address has been deleted successfully.",
            "Successful!"
          );
          this.getDinerAddressAPI();
        } else {
          document.getElementById("closeMenuFormButton").click();
          this.setState({
            deleteAddressAlert: false,
            deleteBtnText: "Yes, Delete it",
          });
          NotificationManager.error(response.data.message, "Error!");
        }
      })
      .catch((error) => {
        document.getElementById("closeMenuFormButton").click();
        this.setState({
          deleteAddressAlert: false,
          deleteBtnText: "Yes, Delete it",
        });
        /* NotificationManager.error(error, "Error!"); */
      });
  };
  /* handleDeleteAddress function ends here */

  /* handleEditAddress function starts here */
  handleEditAddress = (address) => {
    localStorage.setItem("editAddressArray", JSON.stringify(address));
    this.setState({
      address:address.location,
      fullAddress: address.address_line1,
      landMark: address.address_line2,
      otherText: address.location_title,
      latitude: address.latitude,
      longitude: address.longitude,
      markers: [
        {
          name: "",
          position: {
            lat: address.latitude,
            lng: address.longitude
          },
        },
      ],
    });
    if(address.type == "home"){
      this.setState({
        addressSaveAs: "Home"
      })
    }else if(address.type == "work"){
      this.setState({
        addressSaveAs: "Work"
      })
    }else{
      this.setState({
        addressSaveAs: "Other"
      })
    }
    setTimeout(() => {
      this.setState({ showMap: true });
    }, 1000);
   /*  setTimeout(() => {
      this.props.history.push("/get-location?edit-address");
    }, 100); */
  };
  /* handleEditAddress function ends here */

  
  render() {
    if (localStorage.getItem("accessToken") == null) {
      return <Redirect to="/" />;
    }
    const {
      homeAddress,
      workAddress,
      otherAddress,
      deleteBtnText,
      isLoading,
      formErrors
    } = this.state;
    const { mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : !homeAddress.length &&
                    !workAddress.length &&
                    !otherAddress.length ? (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="/assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Manage Address
                      </h6>
                      <div className="inner">
                        <div className="not_found empty_section py-0">
                          <div className="text-center">
                            <img
                              src="/assets/images/icons/no-saved-addresses.svg"
                              alt="Not Found"
                              className="img-fluid"
                            />
                            <h6 className="justify-content-center">
                              NO SAVED ADDRESSES
                            </h6>
                            <p>
                              Tell us where you would like your meal
                              <br /> delivered
                            </p>
                            <Link to="/pick-city?add-address" className="btn">
                              ADD AN ADDRESS
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="/assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Manage Address
                      </h6>
                      <div className="inner">
                        <p>Saved Address</p>
                        {
                          <div className="save_add_wrap">
                            <div className="row">
                              {homeAddress.length
                                ? homeAddress.map((address, i) => {
                                    return (
                                      <div
                                        className="col-lg-12 col-xl-6"
                                        key={"gkdjr" + i}
                                      >
                                        <div className="card">
                                          <div className="top">
                                            <h6>
                                              {address.type === "home" ? (
                                                <img
                                                  src="/assets/images/icons/house-orange.svg"
                                                  alt=""
                                                />
                                              ) : address.type === "work" ? (
                                                <img
                                                  src="/assets/images/icons/portfolio-orange.svg"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src="/assets/images/icons/place-orange.svg"
                                                  alt=""
                                                />
                                              )}
                                              Home
                                            </h6>
                                            {/* if (this.state.landMark != "") {
                                                var addressSelected =
                                                  this.state.fullAddress +
                                                  ", " +
                                                  this.state.landMark +
                                                  ", " +
                                                  this.state.address;
                                              } else {
                                                var addressSelected =
                                                  this.state.fullAddress + ", " + this.state.address;
                                              } */}
                                            <p>{address.address_line2 ? address.address_line1 + ', ' + address.address_line2 + ', ' + address.location : address.address_line1 ? address.address_line1 + ', ' + address.location : address.location}</p>
                                          </div>
                                          <div className="bottom">
                                            <ul>
                                              <li>
                                                <button
                                                  data-toggle="modal"
                                                  data-target="#LeaveModal"
                                                  onClick={() =>
                                                    this.setState({
                                                      deleteAddressAlert: true,
                                                      deleteItemId:
                                                        address.location_id,
                                                    })
                                                  }
                                                  className="delete_icon icon"
                                                >
                                                  Delete
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  onClick={() =>
                                                    this.handleEditAddress(
                                                      address
                                                    )
                                                  }
                                                  className="edit_icon icon"
                                                >
                                                  Edit
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}

                              {workAddress.length
                                ? workAddress.map((address, i) => {
                                    return (
                                      <div
                                        className="col-lg-12 col-xl-6"
                                        key={"gkdjr" + i}
                                      >
                                        <div className="card">
                                          <div className="top">
                                            <h6>
                                              {address.type === "home" ? (
                                                <img
                                                  src="/assets/images/icons/house-orange.svg"
                                                  alt=""
                                                />
                                              ) : address.type === "work" ? (
                                                <img
                                                  src="/assets/images/icons/portfolio-orange.svg"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src="/assets/images/icons/place-orange.svg"
                                                  alt=""
                                                />
                                              )}
                                              Work
                                            </h6>
                                            <p>{address.address_line2 ? address.address_line1 + ', ' + address.address_line2 + ', ' + address.location : address.address_line1 ? address.address_line1 + ', ' + address.location : address.location}</p>
                                          </div>
                                          <div className="bottom">
                                            <ul>
                                              <li>
                                                <button
                                                  data-toggle="modal"
                                                  data-target="#LeaveModal"
                                                  onClick={() =>
                                                    this.setState({
                                                      deleteItemId:
                                                        address.location_id,
                                                    })
                                                  }
                                                  className="delete_icon icon"
                                                >
                                                  Delete
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  onClick={() =>
                                                    this.handleEditAddress(
                                                      address
                                                    )
                                                  }
                                                  className="edit_icon icon"
                                                >
                                                  Edit
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}

                              {otherAddress.length
                                ? otherAddress.map((address, i) => {
                                    return (
                                      <div
                                        className="col-lg-12 col-xl-6"
                                        key={"gkdjr" + i}
                                      >
                                        <div className="card">
                                          <div className="top">
                                            <h6>
                                              {address.type === "home" ? (
                                                <img
                                                  src="/assets/images/icons/house-orange.svg"
                                                  alt=""
                                                />
                                              ) : address.type === "work" ? (
                                                <img
                                                  src="/assets/images/icons/portfolio-orange.svg"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src="/assets/images/icons/place-orange.svg"
                                                  alt=""
                                                />
                                              )}
                                             {address.location_title ? address.location_title : "Other"}
                                            </h6>
                                            <p>{address.address_line2 ? address.address_line1 + ', ' + address.address_line2 + ', ' + address.location : address.address_line1 ? address.address_line1 + ', ' + address.location : address.location}</p>
                                          </div>
                                          <div className="bottom">
                                            <ul>
                                              <li>
                                                <button
                                                  data-toggle="modal"
                                                  data-target="#LeaveModal"
                                                  onClick={() =>
                                                    this.setState({
                                                      deleteItemId:
                                                        address.location_id,
                                                    })
                                                  }
                                                  className="delete_icon icon"
                                                >
                                                  Delete
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  onClick={() =>
                                                    this.handleEditAddress(
                                                      address
                                                    )
                                                  }
                                                  className="edit_icon icon"
                                                >
                                                  Edit
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                            <div className="d-flex justify-content-end">
                              <Link
                                to="/pick-city?add-address"
                                className="btn add_new_btn"
                              >
                                ADD new ADDRESS
                              </Link>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade popup bottom_pop map_pop cancel_order_pop"
            id="LeaveModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="LeaveModalTitle"
            style={{ display: "none" }}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <button
                    type="button"
                    className="close_icon"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="/assets/images/icons/black-close.svg"
                      alt="Close"
                    />
                  </button>
                  <h6>Delete Address?</h6>
                  <p>Are you sure you want to delete this address? </p>
                  <div className="btn-group">
                    <div>
                      <button
                        id="closeMenuFormButton"
                        className="btn previous_btn"
                        data-dismiss="modal"
                      >
                        no, don't delete
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn"
                        onClick={this.handleDeleteAddress}
                      >
                        {deleteBtnText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
        {this.state.showMap == true ? (
          <div
            className="modal bottom_pop fade map_pop show"
            id="bottom_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="bottom_modal"
            style={{ paddingRight: "17px", display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                {/* <div className="add_another_btn">
                    <button
                        type="button"
                        className="close plus_icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeMapPopup}></button>
                </div> */}
                <button
                    type="button"
                    className="close close_view"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closeMapPopup}
                  >
                    <img
                      src="//assets/images/icons/x-button.svg"
                      alt="Close"
                    />
                  </button>
                  <div className="map-img">
                    <Map
                      google={this.props.google}
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "300px",
                      }}
                      zoom={15}
                      initialCenter={{
                        lat: this.state.markers[0].position.lat,
                        lng: this.state.markers[0].position.lng,
                      }}
                    >
                      {this.state.markers.map((marker, index) => (
                        <Marker
                          key={"bvcdert" + index}
                          position={marker.position}
                          draggable={true}
                          onDragend={this.moveMarker.bind(marker)}
                          name={marker.name}
                          icon={"/map-marker.png"}
                        />
                      ))}
                    </Map>
                  </div>
                  <div className="more-details">
                    <div className="form_wrap edit-delivery-address">
                      <form>
                        <div className="address-header">
                          <p className="small-text">YOUR LOCATION</p>
                          <p className="text-med address-change fw_500">
                            {this.state.address}
                            {/* <button
                              type="submit"
                              data-dismiss="modal"
                              aria-label="Close"
                              className="change-text"
                              onClick={this.closeMapPopup}
                            >
                              Change
                            </button> */}
                          </p>
                          <h2>Add More Details</h2>
                        </div>
                        <div className="form-group">
                          <label>Enter Building/House/Flat/Block No.*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="fullAddress"
                            value={this.state.fullAddress}
                            onChange={this.handleMapPopupChange}
                            maxLength="40"
                          />
                          <small className="text-danger">
                            {formErrors.fullAddress}
                          </small>
                        </div>
                        <div className="form-group">
                          <label>Enter Landmark</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="landMark"
                            value={this.state.landMark}
                            onChange={this.handleMapPopupChange}
                            maxLength="40"
                          />
                        </div>
                        <div className="form-group">
                          <label>Save as</label>
                          <div className="radio-box">
                            <ul className="radio_list">
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Home
                                    <input
                                      type="radio"
                                      name="Home"
                                      onChange={this.handleRadioChange}
                                      value="Home"
                                      checked={
                                        this.state.addressSaveAs === "Home" ||
                                        this.state.addressSaveAs === "home"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Work
                                    <input
                                      type="radio"
                                      name="Work"
                                      onChange={this.handleRadioChange}
                                      value="Work"
                                      checked={
                                        this.state.addressSaveAs === "Work" ||
                                        this.state.addressSaveAs === "work"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Other
                                    <input
                                      type="radio"
                                      name="Other"
                                      onChange={this.handleRadioChange}
                                      value="Other"
                                      checked={
                                        this.state.addressSaveAs === "Other" ||
                                        this.state.addressSaveAs === "other"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {this.state.addressSaveAs == "Other" ||
                          this.state.addressSaveAs === "other" ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="eg. Dad's Place"
                              name="otherText"
                              value={this.state.otherText}
                              onChange={this.handleMapPopupChange}
                              maxLength="20"
                            />
                          ) : null}
                        </div>
                        <div className="form-group save-btn mb-0">
                          <button
                            type="submit"
                            className="btn"
                            onClick={this.saveData}
                          >
                            Save and Proceed
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showMap ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

const googleApiWrapper = GoogleApiWrapper({
  apiKey: config.placesKey,
})(ManageAddress);

export default connect(mapStateToProps, { removeMobileView })(googleApiWrapper);
