/*
#############################################################################
# Name : dinerFooter.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display footer thorugh out the side
#############################################################################
*/
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addMobileView } from "../redux/actions/chefMenuActions";
import { removeUserData } from "../redux/actions/authActions";
class DinerFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHistoryOpen: false,
    };
  }

  componentDidMount() {
    if (
      this.props.history.location.pathname === "/diner-faq" ||
      this.props.history.location.pathname === "/diner-policy" ||
      this.props.history.location.pathname === "/diner-terms-of-use"
    ) {
      this.setState({
        isHistoryOpen: true,
      });
    }
  }
  storageHandler = (e) => {
    localStorage.clear();
    this.props.removeUserData();
  };
  render() {
    const { history } = this.props;
    return (
      <div>
        <footer className="desk_footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-2 d-flex align-items-center justify-content-center justify-content-xl-start">
                <div className="logo">
                  <a href="#">
                    <img
                      src="/assets/images/footer-logo.svg"
                      alt="ChefPin Logo"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-12 col-lg-9 col-xl-7">
                <ul className="footer_links">
                  <li>
                    <span className="head">HELP</span>
                    <ul>
                      <li>
                        <Link to="/diner-faq">FAQ's</Link>
                      </li>
                      <li>
                        <a href="/diner-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/diner-terms-of-use">Terms of Use</a>
                      </li>
                      <li>
                        <a href="/party-order">Party Order </a>
                      </li>
                      <li>
                        <a href="/tiffin-services">Tiffin Services </a>
                      </li>
					  <li>
                        <a href="/delete-your-acccout">Delete Your Acccout </a>
                      </li>	
                    </ul>
                  </li>
                  <li>
                    <span className="head">COMPANY</span>
                    <ul>
                      <li>
                        <a href="/diner-aboutus">About Us</a>
                      </li>
                      <li>
                        <a href="/media-events">Media & Events</a>
                      </li>
                      <li>
                        <a
                          href="/chef-homepage"
                          onClick={(e) => this.storageHandler(e)}
                        >
                          Register as Home Chef
                        </a>
                      </li>
                      <li>
                        <a href="https://chefp.in/blog">Chef Pin Blogs</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="head">Contact Us</span>
                    <ul>
                      <li className="whats_icon icon">
                        WhatsApp :{" "}
                        <a href="https://wa.me/919899622242" target="_blank">
                          +91 98996 22242
                        </a>
                      </li>
                      <li className="email_icon icon">
                        Email : <a href="mailto:info@chefp.in">info@chefp.in</a>
                      </li>
                      {/*  <li className="chat_icon icon">
                        <a href="#">Chef Pin Chat</a>
                      </li> */}
                    </ul>
                    <ul className="store_list">
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.chefpin"
                          target="_blank"
                        >
                          <img src="/assets/images/icons/play-store.svg" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://apps.apple.com/in/app/chef-pin/id1579584069"
                          target="_blank"
                        >
                          <img src="/assets/images/icons/apple-store.svg" />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-md-12 col-lg-3 col-xl-3 d-flex justify-content-end">
                <div className="social_icon">
                  <span className="head">fOLLOW US ON</span>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/Chef-Pin-103131911364130"
                        target="_blank"
                      >
                        <img
                          src="/assets/images/icons/facebook-icon.png"
                          alt="Facebook"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/chefp.in/"
                        target="_blank"
                      >
                        <img
                          src="/assets/images/icons/instagram-icon.png"
                          alt="Instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ChefPin1" target="_blank">
                        <img
                          src="/assets/images/icons/twitter-icon.png"
                          alt="Twitter"
                        />
                      </a>
                    </li>
                  </ul>
                  <p>
                    © ChefPin 2023. All rights <br />
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="mobile_footer diner_sidebar">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11 col-lg-4 col-xl-3">
                <footer>
                  <ul className="menu mobile_menu_show d-block d-lg-none">
                    <li className="sub_menu">
                      <a
                        data-toggle="collapse"
                        role="button"
                        onClick={() => {
                          this.setState({
                            isHistoryOpen: !this.state.isHistoryOpen,
                          });
                        }}
                        aria-expanded={
                          this.state.isHistoryOpen ? "true" : "false"
                        }
                        aria-controls="Help"
                        className="icon help_icon"
                      >
                        Help
                      </a>
                      <div
                        className={
                          this.state.isHistoryOpen
                            ? "collapse show"
                            : "collapse"
                        }
                        id="Help"
                      >
                        <hr className="dash_line" />
                        <ul className="inner_menu">
                          <li
                            className={
                              history.location.pathname === "/diner-faq"
                                ? "active"
                                : null
                            }
                          >
                            <Link
                              to="/diner-faq"
                              onClick={() => this.props.addMobileView()}
                            >
                              FAQ's
                            </Link>
                          </li>
                          <li>
                            <a
                              href="/diner-policy"
                              onClick={() => this.props.addMobileView()}
                            >
                              Privacy Policy
                            </a>
                          </li>
                          <li>
                            <a
                              href="/diner-terms-of-use"
                              onClick={() => this.props.addMobileView()}
                            >
                              Terms of Use
                            </a>
                          </li>
						  <li>
							<a href="/party-order" onClick={() => this.props.addMobileView()}>Party Order </a>
						  </li>
						  <li>
							<a href="/tiffin-services" onClick={() => this.props.addMobileView()}>Tiffin Services </a>
						  </li>
						  <li>
							<a href="/delete-your-acccout" onClick={() => this.props.addMobileView()}>Delete Your Acccout </a>
						  </li>	
                        </ul>
                      </div>
                    </li>
                    <li className="sub_menu">
                      <a
                        data-toggle="collapse"
                        href="#Company"
                        role="button"
                        aria-expanded="false"
                        aria-controls="Company"
                        className="icon company_icon"
                      >
                        Company
                      </a>
                      <div className="collapse" id="Company">
                        <hr className="dash_line" />
                        <ul className="inner_menu">
                          <li>
                            <a
                              href="/chef-homepage"
                              onClick={(e) => this.storageHandler(e)}
                            >
                              Register as Home Chef
                            </a>
                          </li>
                          <li>
                            <Link
                              to="/diner-aboutus"
                            //onClick={() => this.props.addMobileView()}
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <a href="https://chefp.in/blog">Chef Pin Blogs</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="sub_menu">
                      <a
                        data-toggle="collapse"
                        href="#Get-Touch"
                        role="button"
                        aria-expanded="false"
                        aria-controls="Get-Touch"
                        className="icon get_in_icon"
                      >
                        Contact Us
                      </a>
                      <div className="collapse" id="Get-Touch">
                        <hr className="dash_line" />
                        <ul className="inner_menu">
                          <li>
                            <a
                              href="https://wa.me/919899622242"
                              target="_blank"
                            >
                              WhatsApp : +91 98996 22242{" "}
                            </a>
                          </li>
                          <li>
                            <a href="mailto:info@chefp.in">
                              Email : info@chefp.in
                            </a>
                          </li>
                          {/* <li>
                            <a href="#">Chef Pin Chat</a>
                          </li> */}
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <ul className="store_list">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.chefpin"
                        target="_blank"
                      >
                        <img src="/assets/images/icons/play-store.svg" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://apps.apple.com/in/app/chef-pin/id1579584069"
                        target="_blank"
                      >
                        <img src="/assets/images/icons/apple-store.svg" />
                      </a>
                    </li>
                  </ul>
                  <div className="social_icon">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/Chef-Pin-103131911364130"
                          target="_blank"
                        >
                          <img
                            src="/assets/images/icons/facebook-icon.png"
                            alt="Facebook"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/chefp.in/"
                          target="_blank"
                        >
                          <img
                            src="/assets/images/icons/instagram-icon.png"
                            alt="Instagram"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/ChefPin1" target="_blank">
                          <img
                            src="/assets/images/icons/twitter-icon.png"
                            alt="Twitter"
                          />
                        </a>
                      </li>
                    </ul>
                    <p>© ChefPin 2023. All rights reserved.</p>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
});

export default connect(mapStateToProps, { addMobileView, removeUserData })(
  withRouter(DinerFooter)
);
