import { ADD_MOBILE_VIEW, REMOVE_MOBILE_VIEW } from "./types";

export const addMobileView = () => (dispatch) => {
    return dispatch({
        type: ADD_MOBILE_VIEW,
        payload: {
            mobileView: true
        }
    });
}

export const removeMobileView = () => (dispatch) => {
    return dispatch({
        type: REMOVE_MOBILE_VIEW,
        payload: {
            mobileView: false
        }
    });
}