/*
#############################################################################
# Name : chefAccountSidebar.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to select the memebrship for the chef 
#############################################################################
*/
import React from "react";
import Pagination from 'react-js-pagination';
import { Link, Redirect } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from '../../components/whatsappButton';
import LoadingDiv from "../../components/LoadingDiv";
import ChefAccountSidebar from "./chefAccountSidebar";
import { getChefSubscriptionPackageAPI } from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { connect } from "react-redux";
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === null && (valid = false);
  });

  return valid;
};

class chefSubscribedPackagePaymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberType: "",
	  orderData: [],
	  orderExcelData: [],
	  isLoading: true,
      activePage: 1,
      totalRecords: 10,
	  userName: '',
      userToken: '',
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }

  componentDidMount() {
    var name = localStorage.getItem('userName');
    var token = localStorage.getItem('accessToken');

    this.getChefSubscriptionListing();
  }
  
  getChefSubscriptionListing = () => {
    var id = localStorage.getItem('userID');
    var token = localStorage.getItem('accessToken');
    var currentPage = this.state.activePage;
    return getChefSubscriptionPackageAPI(
      id,
      currentPage,
      token
    )
      .then((response) => {
        if (response.data['status'] == 200) {
          /*  console.log(response); */
          this.setState({
            orderData: response.data.data,
            //orderExcelData: (response.data['data']['records_found']),
            //totalRecords: response.data.data.pager_count,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false
        });
      });
  };

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.getChefSubscriptionListing();
    });
  }


  render() {
    if (this.props.userData.userType !== "Chef") {
      return <Redirect to={"/signin-with-otp?redirect=" + this.props.location.pathname.split("/")[1]} />;
    }
    const { mobileView } = this.props;
    const { formErrors } = this.state;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        <section className="chef_flow">
          <div className="container">
            <div className="row justify-content-center">
              <ChefAccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="chef_right diner_my_acc">
                  <div className="sticky_wrap">
                    <h6 className="res_back_btn_wrap">
                      <a
                        onClick={() => this.props.removeMobileView()}
                        className="res_back_btn mobile_sidebar_back_btn"
                      >
                        <img
                          src="assets/images/icons/left-arrow-black.svg"
                          alt="left-arrow-black"
                        />
                      </a>
                      <Link
                        to="/chef-dashboard"
                        className="res_back_btn desktop_sidebar_back_btn"
                      >
                        <img
                          src="assets/images/icons/left-arrow-black.svg"
                          alt="left-arrow-black"
                        />
                      </Link>
                      Subscribed Package Payment History
                    </h6>
                  </div>
				  <div className=''>
                      <table className='table table-bordered table-striped table-hover tbl_responsive packagev-table'>
                        <thead>
                          <tr>
							<th>Sr. No.</th>
                            <th>Package</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Paid Amount</th>
                          </tr>
                        </thead>
                        {this.state.orderData.length ? (
                          <tbody>
                            {this.state.orderData.map((packagev, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className='align-middle text-center'
                                  >
                                    {this.state.activePage > 1 && i + 1 != 10
                                      ? this.state.activePage - 1 + '' + (i + 1)
                                      : this.state.activePage > 1 && i + 1 == 10
                                      ? this.state.activePage * (i + 1)
                                      : i + 1}
                                  </td>
                                  <td>{packagev.package}</td>
                                  <td>{packagev.payment_date}</td>
                                  <td>{packagev.end_date}</td>
                                  <td>₹ {Math.round(packagev.paid_amount)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan='5'>No record found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      {/*<div className='text-right'>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalRecords}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>*/}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? null : <Footer />}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(
  chefSubscribedPackagePaymentHistory
);
