/*
#############################################################################
# Name : RefundStatus.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display refund details
#############################################################################
*/
import React, { Component } from "react";
import AccountSidebar from "./AccountSidebar";
import {
  getDinerRefundStatus,
  deleteDinerAddressAPI,
} from "../../../../core/services/Phase2APIservice";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import LoadingDiv from "../../../../components/LoadingDiv";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import Moment from "moment";
import config from "../../../../config/config";
import { removeMobileView } from "../../../../redux/actions/chefMenuActions";

class RefundStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedCity: "",
      refundStatus: [],
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    if(localStorage.getItem("selectedLocation")){
      var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
      this.setState({
        selectedCity: selectedCity
      })
    }
    this.getDinerRefundStatus();        //Refund detail data is fetched
  }

  /* getDinerRefundStatus function starts here */
  getDinerRefundStatus() {
    return getDinerRefundStatus(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            refundStatus: response.data.data.orders,
            isLoading: false,
          });
        } else {
          /*   NotificationManager.error(response.data.message, "Error!") */ 
          this.setState(
            {
              isLoading: false,
            }
          );
          if (response.status === 401) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.history.push("diner-signin-with-otp");
            localStorage.clear();
          } else {
            NotificationManager.error(response.data.message, "Error!");
          }
        }
      })
      .catch((error) => {
        /*   NotificationManager.error(error, "Error!"); */
        this.setState({
          isLoading: false,
        });
      });
  }
 /* getDinerRefundStatus function ends here */

  render() {
    if (localStorage.getItem("accessToken") == null) {
      return <Redirect to="/" />;
    }
    const { refundStatus, isLoading } = this.state;
    const { mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="diner_my_acc">
          <div className="container">
            <div className="row justify-content-center">
              <AccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  {isLoading ? (
                    <LoadingDiv />
                  ) : !refundStatus.length ? (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Refund Status
                      </h6>
                      <div className="inner">
                        <div className="not_found empty_section py-0">
                          <div className="text-center">
                            <img
                              src="assets/images/icons/dolor.svg"
                              alt="Not Found"
                              className="img-fluid"
                            />
                            <h6 className="justify-content-center">
                              NO REFUND STATUS
                            </h6>
                            <p>
                              We did not find any refund currently under <br />
                              progress.
                            </p>
                            <Link to={"/"+this.state.selectedCity+"/diner-homepage"} className="btn">
                              Go back to homepage
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="outer">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        Refund Status
                      </h6>
                      <div className="inner current_orders_card">
                        {refundStatus.map((order, i) => {
                          return (
                            <div className="order_wrap">
                              <div className="order_title">
                                <h6 className="order_num">
                                  <span>
                                    {order.is_takeaway_selected == "0"
                                      ? "Delivery"
                                      : "Takeaway"}
                                  </span>
                                  Order Number : {"#" + order.order_number}
                                </h6>
                              </div>
                              <div className="save_add_wrap">
                                <div className="card">
                                  <div className="box title_price">
                                    <h6>
                                      Home Chef{" "}
                                      {order.first_name + " " + order.last_name}{" "}
                                      <span className="mb-0">INR {order.amount_paid} (including taxes and charges)</span>
                                    </h6>
                                    <p className="name_add add">
                                      {order.drop_location}
                                    </p>
                                    {order.is_takeaway_selected == "1" && (
                                      <a href="#" className="link">
                                        View on Google Maps
                                      </a>
                                    )}
                                  </div>
                                  <hr className="dash_line" />
                                  <div className="box">
                                    <h6 className="font-weight-normal badge_wrap_div">
                                      {order.order_details.map((item, i) => {
                                        return (
                                          <span
                                            className="badge badge-light mr-2"
                                            style={{
                                              fontWeight: 400,
                                              fontSize: "15px",
                                              backgroundColor: "#f2f2f2",
                                            }}
                                            key={"fshyt" + i}
                                          >
                                            {item.item_name +
                                              " x " +
                                              item.itemQuantity}
                                          </span>
                                        );
                                      })}
                                    </h6>
                                    <p>
                                      Order placed :{" "}
                                      {Moment(order.created_at).format(
                                        "MMM DD, hh:mm a"
                                      )}
                                    </p>
                                    <p>
                                      Delivery time slot :{" "}
                                      {order.selected_time_slot}
                                    </p>
                                  </div>
                                  <hr className="dash_line" />
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="box">
                                        <h6 className="font-weight-normal">
                                          {order.status} order on{" "}
                                          {Moment(
                                            order.status === "Cancelled"
                                              ? order.order_cancelled_at
                                              : order.status === "Rejected"
                                              ? order.order_rejected_at
                                              : order.created_at
                                          ).format("MMM DD, hh:mm a")}{" "}
                                        </h6>
                                      </div>
                                    </div>
                                    {order.reason_for_chef_rejection || order.reason_for_diner_cancellation ?<div className="col-md-6">
                                      <div className="box">
                                        <p className="font-weight-normal">
                                          Reason :{" "}
                                          <span>
                                            {order.status === "Cancelled"
                                              ? order.reason_for_diner_cancellation
                                              : order.status === "Rejected"
                                              ? order.reason_for_chef_rejection
                                              : "N/A"}
                                          </span>
                                        </p>
                                      </div>
                                    </div>: null}
                                  </div>
                                  <hr className="dash_line" />
                                  <div className="box">
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr className="text-center">
                                            <th>Refund ID</th>
                                            <th>Payment ID</th>
                                            <th>Refund Amount</th>
                                            <th>Refund Date</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <td>{order.refund_id}</td>
                                          <td>{order.payment_id}</td>
                                          <td>INR {order.refund_amount}</td>
                                          <td>
                                            {order.status === "Cancelled"
                                              ? order.order_cancelled_at
                                              : order.status === "Rejected"
                                              ? order.order_rejected_at
                                              : order.created_at}
                                          </td>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(RefundStatus);
