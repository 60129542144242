/*
#############################################################################
# Name : whatsappButton.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display badges throughout the 
project
#############################################################################
*/

import React from "react";

class PromotedBadge extends React.Component {
  render() {
    return (
      <div className="PromoShape">
        <a href="#">Promoted</a>
      </div>
    );
  }
}

export default PromotedBadge
