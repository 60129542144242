import React, { useState, useRef, useEffect } from "react";
import "../BackendStyle.css";
import { URLs } from "../Url";
import { getDays, getMonth } from "../Fun";

// import JoditEditor from "jodit-react";

import {
  CategoryDelete,
  BlogallImageDelete,
  categoryeditPostdata,
  CategoryPostdata,
  BlogCategoryData,
  BlogData,
  BlogDelete,
  BlogImageDelete,
  BlogeditPostdata,
  Blogpostimage,
  BlogPostdata,
} from "./config";
const Category = () => {
  const editor = useRef(null);

  const [data, setdata] = useState();
  const [blogdata, setblogdata] = useState([]);
  const [title, settitle] = useState();
  const [image, setimage] = useState();
  const [catId, setcatId] = useState();
  const [category, setcategory] = useState();
  const [description, setdescription] = useState();
  const [author, setauthor] = useState();
  const [status, setstatus] = useState();
  const [updateimage, setupdateimage] = useState();
  const [UpdateId, setUpdateId] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [show, setShow] = useState(true);

  useEffect(() => {
    handlerData();
  }, []);

  const handlerData = async () => {
    const getgteanData2 = await BlogData();
    const getgteanData = await BlogCategoryData();
    if (getgteanData.success == true) {
      setdata(getgteanData.data);
      setblogdata(getgteanData2.data);
      setisLoading(false);
    }
  };
  const DeleteHandler = async (id) => {
    const filesName = [];

    const getResponse = await CategoryDelete(id);
    if (getResponse.success == true) {
      console.log("filesName");
      if (blogdata.length > 0) {
        blogdata.map((item) => {
          if (item.blogcategory_id == id) {
            filesName.push(item.image);
          }
        });
        ImageDeleteHandler(filesName);
      }
    }
  };
  const ImageDeleteHandler = async (imageDelete) => {
    const getResponse = await BlogallImageDelete(imageDelete);
    if (getResponse.success == true) {
      handlerData();
    }
  };

  const handlePost = async () => {
    const GetDataTestimonials = await CategoryPostdata(title);
    if (GetDataTestimonials.success == true) {
      setShow();
      setisLoading(true);
      handlerData();
      settitle();
    }
  };
  const UpdateHandler = async () => {
    const GetDataTestimonials = await categoryeditPostdata(UpdateId, title);
    if (GetDataTestimonials.success == true) {
      setShow();
      handlerData();
    }
  };
  const UpdateItem = (item) => {
    settitle(item.categoryname);
    setUpdateId(item._id);
    setShow("update");
  };

  return isLoading == true ? null : (
    <>
      {show == "update" ? (
        <div className="cus-contact">
          <form>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>

            <button
              onClick={() => {
                UpdateHandler();
              }}
              type="button"
              className="btn btn-default"
            >
              Submit
            </button>
          </form>
        </div>
      ) : show == "add" ? (
        <div className="cus-contact">
          <form>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>

            <button
              onClick={() => handlePost()}
              type="button"
              className="btn btn-default"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="content">
          <div className="row table-invoice">
            <div className="col-sm-12">
              <div className="table-in-card">
                <div class="d-flex justify-content-between">
                  <h3>Categorys</h3>
                  <button
                    type="button"
                    onClick={() => setShow("add")}
                    class="btn btn-primary btn-xs"
                  >
                    <small>Add category</small>
                  </button>
                </div>
                <hr />
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Sr No</th>
                        <th>Title</th>
                        <th>Created Date</th>
                        {/* <th>Status</th> */}
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => {
                        var Dates = item.updatedAt.split("T")[0];
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.categoryname}</td>
                            {/* <td>{item.title}</td>
                            <td>{item.description}</td>
                            <td>{item.author}</td> */}
                            <td>
                              {getDays(Dates) +
                                ", " +
                                " " +
                                new Date(Dates).getDate() +
                                " " +
                                getMonth(Dates) +
                                " " +
                                new Date(Dates).getFullYear()}
                            </td>
                            {/* <td>
                              <button class="btn btn-warning btn-xs">
                                <small>Status</small>
                              </button>
                            </td> */}
                            <td>
                              <div class="d-flex ">
                                <button
                                  className="btnnnnn btn-primary"
                                  type="button"
                                  onClick={() => UpdateItem(item)}
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                {/* <button
                                  className="btnnnnn btn-danger"
                                  style={{ marginLeft: 20 }}
                                  type="button"
                                  onClick={() => DeleteHandler(item._id)}
                                >
                                  <i class="fa fa-trash"></i>
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Category;
