import { ADD_ORDER_NUMBER, REMOVE_ORDER_NUMBER } from "./types";

export const addOrderNumber = (number) => (dispatch) => {
    return dispatch({
        type: ADD_ORDER_NUMBER,
        payload: {
            orderNumber: number
        }
    });
}

export const removeOrderNumber = () => (dispatch) => {
    return dispatch({
        type: REMOVE_ORDER_NUMBER,
        payload: {
            orderNumber: null
        }
    });
}