import { URLs } from "../Url";
export const handlePostImage = async (updateimage) => {
  try {
    var formdata = new FormData();
    formdata.append("image", updateimage);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home-imageupload",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const handledeleteImage = async (name) => {
  try {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home-deleteimage/" + name,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

//Metatags
export const GetMetatags = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home/HomeMeta",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

export const Metatagsupload = async (
  pagename,
  slug,
  title,
  description,
  keyword,
  ogtitle,
  ogdescription,
  Getimage
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pagename: pagename,
      slug: slug,

      title: title,
      description: description,
      keywords: keyword,
      ogtitle: ogtitle,
      ogdescription: ogdescription,
      ogimage: Getimage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/HomeMeta-upload",
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const Metatagsupdate = async (
  id,
  pagename,
  slug,
  title,
  description,
  keyword,
  ogtitle,
  ogdescription,
  Getimage
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      pagename: pagename,
      slug: slug,

      title: title,
      description: description,
      keywords: keyword,
      ogtitle: ogtitle,
      ogdescription: ogdescription,
      ogimage: Getimage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home/meta-update/" + id,
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const DeleteMetas = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/delete-meta/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
