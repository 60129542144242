/*
#############################################################################
# Name : ChefDashboard.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used render the Chef revenue and chef dashboard data
#############################################################################
*/
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from "../../components/whatsappButton";
import LoadingDiv from "../../components/LoadingDiv";
import ChefAccountSidebar from "../chefOrder/chefAccountSidebar";
import {
  chefCanceledOrderAPI,
  getPlayerIDAPI,
} from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Moment from "moment";
import Slider from "react-slick";
import { getChefRevenueAPI } from "../../core/services/Phase2APIservice";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { saveOneSignalData } from "../../redux/actions/oneSignalAction";
import StarRatings from "react-star-ratings";
import { saveOrderCountData } from "../../redux/actions/orderCountActions";
import config from "../../config/config";

class ChefDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      revenue: [],
      rejectionOrderWarning: "",
      totalProfileView: "",
      lastWeekProfileView: "",
      currentMonthPeriod: "",
      currentWeekPeriod: "",
      todaysAccepts: "",
      todaysPrepares: "",
      slideItems: [],
      averageRating: "",
      foodQuality: "",
	  regPackage: '',
	  marPackage: '',
      foodPacking: "",
      foodPortionSize: "",
      foodValue: "",
      /* slideItems: [
          {
          image: "/assets/images/icons/order-1st.svg",
          title: "1st Order Pin",
          desc: "150 Happy customers lorem ipsumOrder Pin",
        }, 
        {
          image: "/assets/images/icons/order-10th.svg",
          title: "10th Order Pin",
          desc: "100 Happy customers lorem ipsum Order Pin",
          id: "chef_pin_10th_order_enabled",
        },
        {
          image: "/assets/images/icons/months-3.svg",
          title: "3 months",
          desc: "200 Happy customers lorem ipsum",
          id: "chef_pin_3rd_month_enabled",
        },
        {
          image: "/assets/images/icons/anniversary.svg",
          title: "Anniversary",
          desc: "250 Happy customers lorem ipsum",
          id: "chef_pin_12_month_enabled",
        },
        {
          image: "/assets/images/icons/super-chef.svg",
          title: "Super Chef",
          desc: "300 Happy customers lorem ipsum",
          id: "chef_pin_super_enabled",
        },
        {
          image: "/assets/images/icons/ama-pin.svg",
          title: "Audit-Me-Anytime",
          desc: "350 Happy customers lorem ipsum",
        }, 
        {
          image: "/assets/images/icons/happy-customers.svg",
          title: "Happy Customers",
          desc: "400 Happy customers lorem ipsum",
          id: "chef_pin_150th_order_enabled",
        },
      ],  */
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
     this.getChefRevenueAPI(); //Revenue Data is fetched
    if (localStorage.getItem("OneSignal_playerId")) {
      var playerId = localStorage.getItem("OneSignal_playerId");
      this.props.saveOneSignalData(playerId);
    }
    this.getPlayerID();
  }

  getPlayerID = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var playerId = this.props.oneSignalData;
    return getPlayerIDAPI(userId, token, playerId)
      .then((response) => {
        if (response.data.status === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
  
      });
  };

  /* getChefRevenueAPI function starts here */
  getChefRevenueAPI() {
    return getChefRevenueAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          var orderCount = parseInt(response.data.data.no_of_orders_to_accept) + parseInt(response.data.data.no_of_orders_to_prepare) + parseInt(response.data.data.no_of_orders_to_ready);
          this.props.saveOrderCountData(orderCount);
          this.setState({
            revenue: response.data.data.revenue,
            rejectionOrderWarning: response.data.data.rejection_order_warning,
            currentMonthPeriod: response.data.data.current_month_period,
            totalProfileView: response.data.data.total_profile_viewed,
            lastWeekProfileView: response.data.data.last_week_profile_viewed,
            currentWeekPeriod: response.data.data.current_week_period,
            todaysAccepts: response.data.data.no_of_orders_to_accept,
            todaysPrepares: response.data.data.no_of_orders_to_prepare,
            averageRating:
              response.data.data.ratings_data != null
                ? response.data.data.ratings_data.average_rating
                : null,
            foodQuality:
              response.data.data.ratings_data != null
                ? response.data.data.ratings_data.food_quality
                : null,
            foodPacking:
              response.data.data.ratings_data != null
                ? response.data.data.ratings_data.packaging
                : null,
            foodPortionSize:
              response.data.data.ratings_data != null
                ? response.data.data.ratings_data.portion_size
                : null,
            foodValue:
              response.data.data.ratings_data != null
                ? response.data.data.ratings_data.value_for_money
                : null,
            slideItems:
              response.data.data.gamification_data != null
                ? response.data.data.gamification_data
                : null,
			regPackage: response.data.data.registration_package,
			marPackage: response.data.data.marketing_package,
            isLoading: false,
          });
        } else {
          NotificationManager.error(response.data.message, "Error!");
          this.setState({
            isLoading: false,
          });
          if (response.status === 500) {
            NotificationManager.error(
              "Session expired. Please log in.",
              "Error!"
            );
            this.props.history.push("/signin-with-otp");
            localStorage.clear();
          }
        }
      })
      .catch((error) => {
        /* NotificationManager.error(error, "Error!"); */
        /* console.log(error); */
        this.setState({
          isLoading: false,
        });
      });
  }
  /* getChefRevenueAPI function ends here */

  /* getAcceptOrder function starts here */
  getAcceptOrder = () => {
    this.props.history.push("/orders-in-progress");
  };
  /* getAcceptOrder function ends here */

  /* getPrepareOrder function starts here */
  getPrepareOrder = () => {
    localStorage.setItem("currentTab", 2);
    this.props.history.push("/orders-in-progress");
  };
  /* getPrepareOrder function ends here */

  render() {
    if (this.props.userData.userType !== "Chef") {
      return <Redirect to="/" />;
    }
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 3,
    };
    const {
      isLoading,
      revenue,
      rejectionOrderWarning,
      currentMonthPeriod,
      currentWeekPeriod,
	  totalProfileView,
	  lastWeekProfileView,
      todaysAccepts,
      todaysPrepares,
      slideItems,
    } = this.state;
    const { history, mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        <section className="chef_flow">
          <div className="container">
            <div className="row justify-content-center">
              <ChefAccountSidebar
              />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  <div className="chef_right diner_my_acc">
                    <h4 className="name res_back_btn_wrap">
                      <a
                        onClick={() => this.props.removeMobileView()}
                        className="res_back_btn mobile_sidebar_back_btn"
                      >
                        <img
                          src="assets/images/icons/left-arrow-black.svg"
                          alt="left-arrow-black"
                        />
                      </a>
                      {/* <a href="#" className="res_back_btn desktop_sidebar_back_btn">
                                                <img src="assets/images/icons/left-arrow-black.svg" alt="left-arrow-black" />
                                            </a> */}
                      Hi {this.props.userData.userName.split(" ")[0]}!
                    </h4>
					
					{this.state.rejectionOrderWarning != null ? (
						<div class="alert alert-danger alert-dismissible">
							<a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
							{this.state.rejectionOrderWarning}
						</div>
					) : null}

                    {isLoading ? (
                      <LoadingDiv />
                    ) : (
                      <div>
						<div className="top_list">
							<div className="row">
								<div className="col-12 col-xl-12">
								
									{ this.state.regPackage == '' && this.state.marPackage == '' ? 
										<p><b class="text-danger">Your Chef Pin subscription is pending. <a href="/user-details">Click here</a> to see subscription options.</b></p> :
										null
									}
									
									{ this.state.regPackage != '' && this.state.marPackage == '' ? 
										<p>Your registration package enabled till {this.state.regPackage}.<br/><b class="text-danger"><a href="/user-details">Click here</a> to upgrade to our Marketing Booster Pack</b></p> :
										null
									}
									
									{ this.state.regPackage == '' && this.state.marPackage != '' ? 
										<p><b class="text-danger">Your registration package has expired So, <a href="/user-details">click here</a> to subscribe now.</b></p> :
										null
									}
									
									{ this.state.regPackage != '' && this.state.marPackage != '' ? 
										<p>Your registration package enabled till {this.state.regPackage}.<br/>Your marketing package enabled till {this.state.marPackage}.</p> :
										null
									}
								
								
									{/*{this.state.regPackage != '' ? <p>Your registration package enabled till {this.state.regPackage}.</p> : <p><b class="text-danger">Your registration package has expired So, <a href="/user-details">click here</a> to subscribe now.</b></p>}
									{this.state.marPackage != '' ? <p>Your marketing package enabled till {this.state.marPackage}.</p> : <p><b class="text-danger">Your marketing package has expired So, <a href="/user-details">click here</a> to subscribe now.</b></p>}*/}
								</div>
							</div>
						</div>
                        <div className="top_list">
                          <div className="row">
                            <div className="col-6 col-xl-4 d-flex one">
                              <div className="box">
                                <h6>Today's Revenue</h6>
                                <h6 className="bottom">
                                  INR{" "}
                                  {revenue.current_date_revenue
                                    ? revenue.current_date_revenue
                                    : "0.00"}{" "}
                                  <span>
                                    {revenue.current_date_total_orders
                                      ? revenue.current_date_total_orders == "1"
                                        ? revenue.current_date_total_orders +
                                          " order"
                                        : revenue.current_date_total_orders +
                                          " orders"
                                      : "0 orders"}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="col-6 col-xl-4 two">
                              <div className="box">
                                <h6>This week : {currentWeekPeriod}</h6>
                                <h6 className="bottom">
                                  INR{" "}
                                  {revenue.current_week_revenue
                                    ? revenue.current_week_revenue
                                    : "0.00"}{" "}
                                  <span>
                                    {revenue.current_week_total_orders
                                      ? revenue.current_week_total_orders == "1"
                                        ? revenue.current_week_total_orders +
                                          " order"
                                        : revenue.current_week_total_orders +
                                          " orders"
                                      : "0 orders"}
                                  </span>
                                </h6>
                              </div>
                            </div>
                            <div className="col-12 col-xl-4 three">
                              <div className="box">
                                <h6>This month : {currentMonthPeriod}</h6>
                                <h6 className="bottom">
                                  INR{" "}
                                  {revenue.current_month_revenue
                                    ? revenue.current_month_revenue
                                    : "0.00"}{" "}
                                  <span>
                                    {revenue.current_month_total_orders
                                      ? revenue.current_month_total_orders ==
                                        "1"
                                        ? revenue.current_month_total_orders +
                                          " order"
                                        : revenue.current_month_total_orders +
                                          " orders"
                                      : "0 orders"}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border_grey"></div>
                        <div className="row">
                          <div className="col-lg-12 col-xl-6">
                            <ul className="order_list">
                              <li onClick={this.getAcceptOrder}>
                                <h6>Orders to accept today :</h6>
                                <h4>{todaysAccepts ? todaysAccepts : "0"}</h4>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-12 col-xl-6">
                            <ul className="order_list mb-0">
                              <li onClick={this.getPrepareOrder}>
                                <h6>Orders to prepare :</h6>
                                <h4>{todaysPrepares ? todaysPrepares : "0"}</h4>
                              </li>
                            </ul>
                          </div>
						  <div className="border_grey"></div>
						  {this.state.totalProfileView != null && this.state.totalProfileView != '' && (
						  <div className="col-lg-12 col-xl-6">
                            <ul className="order_list mb-0">
                              <li>
                                <h6>Total Profile Viewed :</h6>
                                <h4>{this.state.totalProfileView}</h4>
                              </li>
                            </ul>
                          </div>
						  )}
						  {this.state.lastWeekProfileView != null && this.state.lastWeekProfileView != '' && (
						  <div className="col-lg-12 col-xl-6">
                            <ul className="order_list mb-0">
                              <li>
                                <h6>Last Week Profile Viewed :</h6>
                                <h4>{this.state.lastWeekProfileView}</h4>
                              </li>
                            </ul>
                          </div>
						   )}
                          <div className="border_grey"></div>
                          {/* <div className="col-lg-12 col-xl-8">
                            <div className="most_ordered">
                              <h6>Most Ordered Dishes</h6>
                              <div className="dish">
                                <div className="dish_img">
                                  <img
                                    src="assets/images/paneer-biryani.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="text">
                                  <p className="title">
                                    Seasoned Eggplant Raita <span>INR 500</span>
                                  </p>
                                  <p>
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed dia cone.
                                  </p>
                                </div>
                              </div>
                              <div className="dish">
                                <div className="dish_img">
                                  <img
                                    src="assets/images/paneer-biryani.jpg"
                                    alt=""
                                  />
                                </div>
                                <div className="text">
                                  <p className="title">
                                    Seasoned Eggplant Raita <span>INR 500</span>
                                  </p>
                                  <p>
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed dia cone.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div> */}

                          {this.state.averageRating && (
                            <div className="col-md-12 col-lg-12 col-xl-6 d-flex">
                              <div className="rating_chef_dash_wrap">
                                <h6>
                                  Ratings :{" "}
                                  <small>
                                    {this.state.averageRating}/<span>5</span>{" "}
                                  </small>
                                </h6>
                                <ul className="rate_star_list d-block">
                                  <li className="mr-0">
                                    <span className="fw_500">Food Quality</span>
                                    <StarRatings
                                      rating={parseFloat(
                                        this.state.foodQuality
                                      )}
                                      starDimension="30px"
                                      starSpacing="3px"
                                      starRatedColor="#FFC107"
                                    />
                                  </li>
                                  <li className="mr-0">
                                    <span className="fw_500">Portion Size</span>
                                    <StarRatings
                                      rating={parseFloat(
                                        this.state.foodPortionSize
                                      )}
                                      starDimension="30px"
                                      starSpacing="3px"
                                      starRatedColor="#FFC107"
                                    />
                                  </li>
                                  <li className="mr-0">
                                    <span className="fw_500">Packaging </span>
                                    <StarRatings
                                      rating={parseFloat(
                                        this.state.foodPacking
                                      )}
                                      starDimension="30px"
                                      starSpacing="3px"
                                      starRatedColor="#FFC107"
                                    />
                                  </li>
                                  <li className="mr-0">
                                    <span className="fw_500">
                                      Value for Money
                                    </span>
                                    <StarRatings
                                      rating={parseFloat(this.state.foodValue)}
                                      starDimension="30px"
                                      starSpacing="3px"
                                      starRatedColor="#FFC107"
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                          {slideItems && (
                            <div className="col-lg-12 col-xl-6 d-block d-xl-none">
                              <div className="border_grey"></div>
                            </div>
                          )}
                          {slideItems == null ||
                          slideItems.length == 0 ? null : (
                            <div className="col-md-12 col-lg-12 col-xl-6 d-flex">
                              <div className="pins_chef_dash_wrap">
                                <h6>Pins Achieved </h6>
                                <Slider
                                  {...settings}
                                  className="pin_slider slider-nav"
                                >
                                  {slideItems &&
                                    slideItems.map((item, i) => {
                                      return (
                                        <div className="wrap" key={"grkw" + i}>
                                          <div className="icons">
                                            <div>
                                              <img
                                                src={item.image}
                                                alt={item.title}
                                              />
                                            </div>
                                            <span>{item.title}</span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Slider>
                              </div>
                            </div>
                          )}
						 <div className="col-md-12 col-lg-12 col-xl-6">
							<div className="pins_chef_dash_wrap">
                                <a class="edit_profile_link" href={config.APIURL+"v1/chef/payment-ledger-pdf/"+localStorage.getItem("userID")} download><h6><center>Click Here To Download Last 30 Days Ledger Report</center></h6></a>
							</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? null : <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
  oneSignalData: state.onesignal.items,
  orderCountData: state.orderCount.items
});

export default connect(mapStateToProps, {
  removeMobileView,
  saveOneSignalData,
  saveOrderCountData
})(ChefDashboard);
