import { SAVE_CHEF_DATA, REMOVE_CHEF_DATA } from "../actions/types";

export const saveChefData = (chefDataArr) => (dispatch) => {
    return dispatch({
        type: SAVE_CHEF_DATA,
        payload: {
            chefData: chefDataArr
        }
    });
}

export const removeChefData = () => (dispatch) => {
    return dispatch({
        type: REMOVE_CHEF_DATA,
        payload: {
            chefData: []
        }
    });
}