/*
#############################################################################
# Name : quickFilter.js
# Created by : Arvind Yadav
# Date       : 15th Dec 2020
# Purpose    : This file is used to display the quick filter data on the basis of filter selection
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../../components/dinnerHeader";
import DinerFooter from "../../../../components/dinerFooter";
import WhatsAppButton from "../../../../components/whatsappButton";
import LoadingDiv from "../../../../components/LoadingDiv";
import {
  quickFilterAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
} from "../../../../core/services/Phase2APIservice";
import Slider from "react-slick";
import DinerMenuTab from "../../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../../../config/config";
import { Helmet } from "react-helmet";
class QuickFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
      },
      vipChefArr: [],
      premiumChefArr: [],
      basicChefArr: [],
      data: localStorage.getItem("selectedFilter"),
      images: localStorage.getItem("selectedFilterImage"),
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getFilterData(); // Filter data is fetched
  }

  /* getFilterData function starts here */
  getFilterData = () => {
    var userId = "";
    if (localStorage.getItem("userID")) {
      userId = localStorage.getItem("userID");
    }
    var city = localStorage.getItem("selectedLocationName");
    var pincode = localStorage.getItem("pincode");
    var latitude = localStorage.getItem("latitude");
    var longitude = localStorage.getItem("longitude");
    if (localStorage.getItem("selectedFilter") == "Verified") {
      var filter = "verified";
      return quickFilterAPI(city, pincode, latitude, longitude, filter, userId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              vipChefArr: response.data.data.vip_chef_list,
              isLoading: false,
              /*  premiumChefArr: response.data.data.premium_chef_list,
                                                     basicChefArr: response.data.data.basic_chef_list */
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          /* console.log(error); */
        });
    } else if (localStorage.getItem("selectedFilter") == "Takeaway") {
      var filter = "takeaway";
      return quickFilterAPI(city, pincode, latitude, longitude, filter, userId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              vipChefArr: response.data.data.vip_chef_list,
              isLoading: false,
              /*  premiumChefArr: response.data.data.premium_chef_list,
                                                     basicChefArr: response.data.data.basic_chef_list */
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          /* console.log(error); */
        });
    } else if (localStorage.getItem("selectedFilter") == "Audit Me Anytime") {
      var filter = "a_m_a_enabled";
      return quickFilterAPI(city, pincode, latitude, longitude, filter, userId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              vipChefArr: response.data.data.vip_chef_list,
              isLoading: false,
              /*  premiumChefArr: response.data.data.premium_chef_list,
                                                     basicChefArr: response.data.data.basic_chef_list */
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          /* console.log(error); */
        });
    } else if (localStorage.getItem("selectedFilter") == "Highly Rated") {
      var filter = "high_rated";
      return quickFilterAPI(city, pincode, latitude, longitude, filter, userId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              vipChefArr: response.data.data.vip_chef_list,
              isLoading: false,
              /*  premiumChefArr: response.data.data.premium_chef_list,
                                                     basicChefArr: response.data.data.basic_chef_list */
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          /* console.log(error); */
        });
    } else if (localStorage.getItem("selectedFilter") == "Super Chefs") {
      var filter = "super_chef";
      return quickFilterAPI(city, pincode, latitude, longitude, filter, userId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              vipChefArr: response.data.data.vip_chef_list,
              isLoading: false,
              /*  premiumChefArr: response.data.data.premium_chef_list,
                                                     basicChefArr: response.data.data.basic_chef_list */
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          /* console.log(error); */
        });
    } else if (localStorage.getItem("selectedFilter") == "Vegetarian") {
      var filter = "vegetarian";
      return quickFilterAPI(city, pincode, latitude, longitude, filter, userId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              vipChefArr: response.data.data.vip_chef_list,
              isLoading: false,
              /*  premiumChefArr: response.data.data.premium_chef_list,
                                                     basicChefArr: response.data.data.basic_chef_list */
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          /* console.log(error); */
        });
    } else {
      /*  (localStorage.getItem('selectedFilter') == "Verified"){ */
      var filter = "";
      return quickFilterAPI(city, pincode, latitude, longitude, filter, userId)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              vipChefArr: response.data.data.vip_chef_list,
              isLoading: false,
              /*   premiumChefArr: response.data.data.premium_chef_list,
                                                      basicChefArr: response.data.data.basic_chef_list */
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          /* console.log(error); */
        });
      /* } */
    }
  };
  /* getFilterData function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getFilterData();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getFilterData();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
    // }
  };
  /* getInerPage function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    var Background = `/assets/images/icons/${this.state.images}`;
    return (
      <div className="css2">
        {localStorage.getItem("selectedFilter") == "Near You" ? (
          <Helmet>
            <title> Homemade Food Near You - Chef Pin </title>
            <meta name="title" content="Homemade Food Near You - Chef Pin" />
            <meta
              name="description"
              content="Every dish served by Chef Pin is made with the same care as you would take in your own home kitchen. We make ordering from home chefs super easy!"
            />
          </Helmet>
        ) : (
          <Helmet>
            <title> ChefPin </title> <meta name="title" content="ChefPin" />
            <meta name="description" content="ChefPin" />
          </Helmet>
        )}
        <DinnerHeader /> {/* <div className="menu_fixed_pad"></div> */}
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <section className="selected_you home_bakers chef_inner crowd_tabs ">
            <div className="shoe_all_bg text-white mb-3 mb-sm-5">
              <div className="right_wrap container">
                <h4 className="mb-0"> {this.state.data} </h4>
                <div className="img">
                  <img
                    src={Background}
                    alt="Paneer Biryani"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="container">
              {this.state.vipChefArr.length ? (
                <div className="row">
                  {this.state.vipChefArr.map((vip, i) => {
                    return (
                      <div
                        className="col-sm-6 col-md-6 col-lg-4 col-xl-3"
                        key={"mnb" + i}
                      >
                        <div
                          className={
                            vip.is_chef_available === "1"
                              ? "card"
                              : "card unavailable-chef-card"
                          }
                        >
                          <div
                            className="direction"
                            onClick={() => {
                              localStorage.getItem("userID") !== null
                                ? vip.is_bookmarked == 1
                                  ? this.removeBookmark(vip.chef_id)
                                  : this.addBookmark(vip.chef_id)
                                : this.storageHandler();
                            }}
                          >
                            <img
                              src={
                                vip.is_bookmarked == 1
                                  ? "/assets/images/icons/bookmark.svg"
                                  : "/assets/images/icons/bookmark-empty.svg"
                              }
                              alt=""
                            />
                          </div>
                          <div
                            onClick={() =>
                              this.getInerPage(
                                vip.chef_id,
                                vip.slug_url,
                                vip.name,
                                vip.is_chef_available
                              )
                            }
                          >
                            {vip.promoted == 1 ? (
                              <div className="PromoShape">
                                <a href="#"> Promoted </a>
                              </div>
                            ) : null}
                            <div className="img_wrap">
                              {vip.is_offer_available === "1" ? (
                                <div className="offer"> OFFER </div>
                              ) : null}
                              {vip.profile_image != null ? (
                                <img
                                  src={vip.profile_image}
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              ) : (
                                <img
                                  src="assets/images/noimage.png "
                                  alt=" Selected for You "
                                  className=" img-fluid "
                                />
                              )}
                            </div>
                            <div className="text_wrap">
                              <h6>
                                {vip.verified == 1 ? (
                                  <span className="correct_icn">
                                    {vip.brand_name !== ""
                                      ? vip.brand_name
                                      : vip.name}
                                  </span>
                                ) : (
                                  <span>
                                    {vip.brand_name !== ""
                                      ? vip.brand_name
                                      : vip.name}
                                  </span>
                                )}
                                {vip.total_ratings !== 0 ? (
                                  <span className="star">
                                    {vip.total_ratings}
                                    <span className="grey"> / 5</span>
                                  </span>
                                ) : null}
                              </h6>
                              {vip.cuisines == "" ? (
                                "-"
                              ) : (
                                <p className="sub_title"> {vip.cuisines} </p>
                              )}
                              <ul>
                                <li className="location_icn">
                                  {vip.locality == null &&
                                  vip.locality === "" ? null : (
                                    <span> {vip.locality}, </span>
                                  )}
                                  {vip.distance == null
                                    ? ""
                                    : parseFloat(vip.distance).toFixed(1)}
                                  kms
                                </li>
                                {vip.is_takeaway_available == 1 ? (
                                  <li> Available for Delivery & Takeaway </li>
                                ) : (
                                  <li> Available for Delivery </li>
                                )}
                              </ul>
                              <div
                                className={
                                  vip.gamification_data == null
                                    ? "badge_slide border-0"
                                    : "badge_slide"
                                }
                              >
                                <Slider
                                  className="inner_slider"
                                  {...this.state.settings}
                                >
                                  {vip.gamification_data != null ? (
                                    vip.gamification_data.map((item, i) => {
                                      return (
                                        <div
                                          className="box img-slider1"
                                          key={"grkw" + i}
                                        >
                                          <img
                                            src={item.image}
                                            alt={item.title}
                                          />
                                          <span className="text">
                                            {item.desc}
                                          </span>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="box">
                                      <span className="text">&nbsp;&nbsp;</span>
                                    </div>
                                  )}
                                </Slider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </section>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default QuickFilter;
