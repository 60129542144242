import React from 'react';

class LeaveModal extends React.Component {
    render() {
        return (
            <div
                className="modal fade popup leave_pop"
                id="LeaveModal"
                role="dialog"
                aria-labelledby="LeaveModalTitle"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <span className="benifit_leaf_icon"></span>
                            <div className="add_another_btn">
                                <button
                                    type="button"
                                    className="close plus_icon"
                                    data-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div className="row justify-content-center justify-content-lg-start">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                    <h2>BEFORE YOU LEAVE!</h2>
                                    <p>
                                        Please share your contact details before you leave so that{" "}
                                        <br />
                                        we can keep you updated on all the upcoming offers on{" "}
                                        <br />
                                        Chef Pin!
                                    </p>
                                    <div className="form_wrap">
                                        <form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Name*" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="email" className="form-control" placeholder="Email Id*" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="mobile_no_wrap">
                                                            <div className="form-control countrycode">
                                                                <span className="code">+91</span>
                                                            </div>
                                                            <input
                                                                type="tel"
                                                                className="form-control tel_number"
                                                                placeholder="Mobile Number*" />
                                                        </div>
                                                        <small className="form-text text-muted text-left">
                                                            Enter your 10 digit mobile number{" "}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12 col-xl-12">
                                                    <div className="form-group mb-0">
                                                        <button className="btn d-none d-lg-block">
                                                            send
                                                        </button>
                                                        <button className="btn d-block d-lg-none mx-auto">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex align-items-center justify-content-end">
                                    <div className="img_wrap">
                                        <img
                                            src="/assets/images/home/hello-dinner-pop-img.png"
                                            alt="Hello Dinner Image"
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeaveModal;