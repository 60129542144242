import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ["cart"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default createStore(persistedReducer, applyMiddleware(thunk));