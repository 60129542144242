import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import LoadingDiv from "../../components/LoadingDiv";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import {
    EditorState,
    convertFromHTML,
    ContentState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {
    BlogDetailData,
    BlogData,
    BlogCategoryData,
} from "../../backendUI/Blog/config";
import { getDays, getMonth } from "../../backendUI/Fun";
import { URLs } from "../../backendUI/Url";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TumblrShareButton,
    TwitterShareButton,
} from "react-share";
const html = `<p></p>`;

class NewBlogPage2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromText(html)
            ),
        };
        this.state = {
            isLoading: true,
            hideContent: window.matchMedia("(max-width: 991px)").matches,
            articleTag: [],
            articleTitle: "",
            author: "",
            authorImage: "",
            articleContent: "",
            catgory: "",
            time: "",
            categorydata: [],
            data: [],
        };
    }
    // 63ad512db0441bf02241a2c2


    getblogcatdata = () => {
        BlogCategoryData().then((response) => {
            if (response.success == true) {
                this.setState({
                    categorydata: response.data,
                });
            }
        });
    };
    render() {

        return (
            <div className="css2">
                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li>
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/diner-aboutus" className="login_btn">
                                    About Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a
                                    href="https://wa.me/919899622242"
                                    target="_blank"
                                    className="login_btn"
                                >
                                    Contact Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/pick-city" className="login_btn">
                                    Pick A City
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/blog" className="login_btn">
                                    Blog
                                </a>{" "}
                            </li>
                            <li className="d-block d-xl-none last_btn">
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="dinner_menu d-none d-xl-block">
                            <li>
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <div className="menu_overlay"></div>
                    </div>
                </header>

                <div className="container pt-5">
                    <div className="row align-self">
                        <div className="col-md-12 m-0 p-0">
                            <div className="container">
                                <p className="text-center color_a05">Latest Updates

                                </p>
                                <span className="text-center mt-2">
                                    <h1 className="color_a05">Savoring Excellence: Noida’s Top Home Chefs and Their Delectable Creations</h1>
                                </span>
                                <div className="d-flex align-items-center justify-content-center row m-0 p-0">
                                    <span>POSTED ON</span>
                                    <span className="text-center color_a05">
                                        &nbsp;
                                        JAN 9, 2024
                                    </span>
                                    &nbsp;
                                    <span>BY</span> &nbsp;
                                    <span className="text-center color_a05">
                                        AMEETA AGNIHOTRI

                                    </span>
                                </div>

                                {/* <Editor
                                        toolbarHidden
                                        editorState={this.state.editorState}
                                        readOnly
                                    /> */}
                                <br></br>

                                {/* <h2> Working as a home cook? Want to increase your business? </h2> */}
                                <p>Hey, foodies of Noida! Get ready to have your taste senses piqued! Let's go on a culinary adventure to the <a href="/best-home-chefs-in-noida"> best home chefs in Noida </a>, the trendiest kitchen where the top home cooks are creating delectable dishes. Plus, instead of drooling over it, you can have these delicious treats sent straight to your door using the Chef Pin app. Meet the maestros—you're almost there! </p>
                                <br></br>



                                <hr />
                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>A Haven for Bread Enthusiasts: Sourdough Central</h3>
                                                        <h6> by Sourdough Central </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    {/* <h6>Bakery And Confectionery</h6> */}
                                                    <p>
                                                        Are you someone looking for Bakeries near me? Find what you're looking for at Sourdough Central. Olive Italian Herbs, Cheese and Truffle, and Seeds and Beet are just a few of their artisan sourdough bread varieties. They stand out because they refuse to use commercial yeast, additives, or preservatives in their breads. Indulge in the delectable aroma and flavor of freshly baked bread.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 10:00 AM- 09:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/sourdough-central" target="_blank">  <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/sourdough-central" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/AHavenforBread.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Okhli: Dive into the Richness of Kayastha Cuisine</h3>
                                                        <h6> by Sugandha Saxena</h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        The enchantment of Kayastha food is brought to your table by Chef Sugandha Saxena, a true master of the kitchen. Every dish on the menu at Okhli has a story to tell. The Gurdey Kapoorey and Mutton E Dadima are more than just recipes; they are tales told via food in Sugandha's family cookbook. Check out the best home-cooked food delivery Noida– Okhli if you're in the market for something very special.

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 01:00 PM- 08:00 PM Daily
                                                    </p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/okhli" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Okhli_dive.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Mummy Da Dhaba: Comfort Food at Its Best</h3>
                                                        <h6>by Anju Bhasin </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Need a comforting dish of Chinese or North Indian cuisine? You should place your order at Mummy Da Dhaba, created by Anju Bhasin, the best home chef in Noida. Everything is prepared with the utmost care and devotion, from the Paneer Butter Masala to the Rajma Chawal. It's as if your mom is hugging you through your meal!

                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 11:00 AM- 09:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/mummy-da-dhaba" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/MummyDaDhaba.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>ChefbyChoice Seema: A Blend of Tradition and Innovation</h3>
                                                        <h6>by Seema Arora </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Seema Arora is not just a home chef; she’s an artist. Her culinary expertise spans the gamut from delectable North Indian fare to delectable baked goods, including Turkish bagels and brownies.  If you are looking for online homemade food delivery in Noida, consider ChefbyChoice Seema for your next celebration or as a personal treat.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 12:00 AM- 08:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/chefbychoice-seema" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/ChefbyChoice.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>


                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Parul's Cooking Hub: A Delectable Fusion of Flavors</h3>
                                                        <h6>by Parul Sachdeva </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        The cuisine at Parul Sachdeva is as varied as the city of Noida itself. She prepares a plethora of international dishes, from South American delicacies to Mutton Rogan Josh. If you like to eat out and try new things, check out the best online homemade food delivery Noida– Parul's Cooking Hub is going to blow your mind.



                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 09:00 AM- 08:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/parul-s-cooking-hub" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Parul.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Earthly Feast: A Flavor Symphony by Arjita Kalra</h3>
                                                        <h6> by Arjita Kalra
                                                        </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Acakes shop near me, Earthly Feast, hosted by Chef Arjita Kalra, where you can taste the fruits of her love for cooking in every bite. Among other delicious things, Earthly Feast's Chocolate Millet Cake distinguishes out because it combines traditional wisdom with modern twists. Here, she uses only the finest, most locally obtained ingredients to create delectable dishes that will bring joy to your taste buds and the people you share them with.



                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 11:00 AM- 09:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/delhi-ncr/chef-inner-homepage/earthly-feast" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/Earthly.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Home To Plate: Seema Verma's Culinary Creations</h3>
                                                        <h6> by Seema Verma </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        The 30-year culinary expertise of Chef Seema Verma, a former housewife turned culinary artist, is brought to Home To Plate. The best home-cooked food delivery in Noida, Seema takes great pride in her dishes, which include the Dal Makhni, Marble Cake, and Chicken Biryani, and she gives each one her all while making sure it's clean and presents itself well.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 12:00 AM- 07:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/delhi-ncr/chef-inner-homepage/home-to-plate" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/HomeToPlate.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>


                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Healthy Desserts: Veena Rai's Artisanal Bakes</h3>
                                                        <h6>by Veena Rai </h6>
                                                    </span>

                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>

                                                    </ul>
                                                    <p>
                                                        Healthy Desserts is home to the delectable creations of Noida-based chocolatier and baker Veena Rai. Indulge your sweet tooth with her healthier takes on classic cakes, baklawa, and other treats inspired by Continental and Middle Eastern flavors.


                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p>Availability: Delivery & Takeaway</p>
                                                    <p>Operating Hours: 11:00 AM- 09:00 PM Daily</p>
                                                    <p style={{ display: "block" }}>
                                                        Advance Notice: 24 hours
                                                        <br></br>
                                                        <a href="https://chefp.in/delhi-ncr/chef-inner-homepage/healthy-desserts" target="_blank">  <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            View Chef
                                                        </span> </a>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="/assets/images/Blog/HealthyDesserts.webp"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>





                            </div>
                        </div>

                    </div>
                </div>

                <div className={this.state.hideContent ? "nofooter" : null}>
                    <WhatsAppButton />
                </div>

                <DinerMenuTab />
                <DinerFooter />
            </div>
        );
    }
}

export default NewBlogPage2;
