/*
#############################################################################
# Name : Maintainance.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the maintainance data while making changes on the Chefpin
#############################################################################
*/
import React, { Component } from 'react'

export default class Maintainance extends Component {
    render() {
        return (
            <div style={{
                height: "100%",
                margin: 0
            }}>
                <div className="bgimg">
                    <div className="topleft" style={{
                        position: "absolute",
                        top: "10px",
                        left: "16px",
                    }}>
                        <img src="assets/images/icons/header-logo.svg" alt="ChefPin" />
                    </div>
                    <div className="middle" style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                    }}>
                        <h1>UNDER MAINTENANCE</h1>
                        <hr style={{
                            margin: "auto",
                            width: "40%"
                        }} className="my-2" />
                        <div>
                            <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.</p>
                            <p>We&rsquo;ll be back online shortly!</p>
                        </div>
                    </div>
                    <div className="bottomleft" style={{
                        position: "absolute",
                        bottom: "10px",
                        left: "16px"
                    }}>
                        <p>Chefpin @2021</p>
                    </div>
                </div>
            </div>
        )
    }
}
