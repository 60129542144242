/*
#############################################################################
# Name : whatsappButton.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display whatsapp button component
#############################################################################
*/
import React, {Component} from 'react';
import { whatsAppAPI } from "../core/services/Phase2APIservice";

class WhatsAppButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  phone: "",
		  msg: "",
		  url: "",
		};
	}
	componentDidMount() {
		this.getWhatsAPPData();
	}
	getWhatsAPPData = () => {
		var userId = localStorage.getItem("userID");
		var token = localStorage.getItem("accessToken");
		return whatsAppAPI(userId, token)
		  .then((response) => {
			if (response.data.status === 200) {
			  this.setState({
				phone: response.data.data.phone,
				msg: response.data.data.text,
				url: response.data.data.url,
			  });
			}
		  })
		  .catch((error) => {
			/*  console.log(error); */
		  });
	};
    render() {
        return (
            <a className="whatsapp_btn" id="whatsappButton" href={this.state.url} target="_blank">
                <img
                    src="/assets/images/icons/whatsapp.png"
                    alt="WhatsApp Button"
                    className="img-fluid"/>
            </a>
        )
    }
}

export default WhatsAppButton;