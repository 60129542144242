import { SAVE_CHEF_ORDER_DATA, REMOVE_CHEF_ORDER_DATA } from "../actions/types";

const initialState = { orderCountData: null };
export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CHEF_ORDER_DATA:
      return {
        items: action.payload.orderCountData,
      };

    case REMOVE_CHEF_ORDER_DATA:
      return {
        items: action.payload.orderCountData,
      };

    default:
      return state;
  }
}
