import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Feed.css";

const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type&access_token=IGQVJYX040U0ZAtLTNtZAkk5Vm5BOS1jVkFSTU1LbGQxRTE0RTkwMkNHSXBJeUdtaW1mOXlNVkh5dm1wYmJXQ0ZAPNGtRckNCeTA0c2VHcVN2Mm05MnEzUDBvYTZA0ZAVFVcUMwbTFwZAEpQckZANVWdUSDlwQwZDZD`
        );
        const imagePosts = response.data.data.filter(
          (post) => post.media_type != "VIDEO"
        );

        setPosts(imagePosts);
        console.log("dvcsdvsd", response.data.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // const imagePosts = posts.filter((post) => post.media_type === "IMAGE");

  return (
    <div className="container">
      <h2 className="text-uppercase color_C07 text-center mb-2">
        WE ARE ALWAYS UP TO EXCITING THINGS
      </h2>
      <div className="grid-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          posts
            .slice(0, Math.min(8, posts.length))
            .reverse()
            .map((post) => (
              <div className="grid-item" key={post.id}>
                <img
                  className="img-fluid postsimage"
                  src={post.media_url}
                  alt="Image"
                />
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default InstagramFeed;
