import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DinerFooter from "../../components/dinerFooter";
import Slider from "react-slick";
import { HomeDescription } from "../../backendUI/Home/config";
import config from "../../config/config";
import WhatsAppButton from "../../components/whatsappButton";
import DeletePasswordForm from "./DeletePasswordForm";


class DeletePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HeadTitle: "",
        };
    }
    componentDidMount() {
        if (!localStorage.getItem("closePopup")) {
            this.setState({
                displayPopup: true,
            });
        } else {
            this.setState({
                displayPopup: false,
            });
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        if (localStorage.getItem("selectedLocation")) {
            var selectedCity = config.slugify(
                localStorage.getItem("selectedLocation")
            );
            this.setState({
                selectedCity: "/" + selectedCity + "/diner-homepage",
            });
        }

        this.getHeadTitle();
    }

    getHeadTitle = () => {
        HomeDescription().then((res) => {
            if (res.success == true) {
                this.setState({
                    HeadTitle: res.data[0].description,
                });
            }
        });
    };

    render() {

        return (
            <div className="css2" >
                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li className="active">
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>

                        </ul>

                        <div className="menu_overlay"></div>
                    </div>
                </header>
                <section style={{ padding: "80px 0px 80px 0px"}}>
                    <div className="container">
						<h2 className="text-uppercase color_C07 mb-4 text-center" style={{ fontSize: "34px" }} >REQUEST FOR ACCOUNT DELETION</h2>
						<DeletePasswordForm />
                    </div>
                </section>

               

                <div className={this.state.hideContent ? "nofooter" : null}>
                    <WhatsAppButton />
                </div>


                <DinerFooter />

            </div>
        );
    }
}

export default DeletePassword;
