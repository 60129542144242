/*
#############################################################################
# Name : faqDiner.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to select whether the user chef or diner
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import { getFAQData } from "../../core/services/Phase2APIservice";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
class FAQDinerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqData: [],
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    // this.getFAQData();
  }

  /* getFAQData function starts here */
  getFAQData = () => {
    return getFAQData("Diner")
      .then((response) => {
        if (response.data.status === 200) {
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* getFAQData function ends here */

  render() {
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        <Helmet>
          <title>Frequently Asked Questions from Diners about Chef Pin</title>
              <meta name="title" content="Frequently Asked Questions from Diners about Chef Pin" />
              <meta name="description" content="Read About Our Frequently Asked Questions from Diners about Chef Pin."/>
              <meta name="keywords" content = "home made food services delhi ncr, home made food services bengaluru, home made food services mumbai, home made food services chennai, home made food services kolkata" />
          </Helmet>
        {this.state.hideContent ? null : <DinnerHeader />}
        <section className="faq_sec diner_faq">
          <div className="container">
            <h1 className="text-center res_back_btn_wrap">
              <a
                onClick={() => {
                  this.props.removeMobileView();
                  this.props.history.goBack();
                }}
                className="res_back_btn mobile_sidebar_back_btn"
              >
                <img
                  src="assets/images/icons/left-arrow-black.svg"
                />
              </a>
              {/* <a
                onClick={() => {
                  this.props.removeMobileView();
                  this.props.history.goBack();
                }}
                className="res_back_btn desktop_sidebar_back_btn"
              >
                <img
                  src="assets/images/icons/left-arrow-black.svg"
                  alt="left-arrow-black"
                />
              </a> */}
              FREQUENTLY ASKED QUESTIONS
            </h1>
            <div id="accordion" className="accordion">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="title"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Do I have to order in advance?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      In most cases yes, but some chefs also offer same day delivery. You can find these chefs in the same day delivery collection on our home page. The dishes that are available for same day delivery also have a tag that mentions the same on the right.
                    </p>
                    <p>
                      For other chefs our system makes sure that all orders are placed at least 1 day prior to delivery. At the time of placing the order you will be able to select the pick up spot for the coming days.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Is there any minimum value to place an order on Chef Pin?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      The minimum value will be displayed for each home chef on
                      their respective pages. You may please refer to that when
                      deciding to order from that home chef.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Are there any delivery charges?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Yes, the delivery charges will be transparently shown to
                      you at the time of placing your order. The delivery amount
                      will be added to your order before payment. Chef Pin uses
                      only the most reputed delivery partners in each city, and
                      the delivery charges will depend on your city and the
                      distance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Can I change the delivery address after the order has been
                      placed?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Once the order has been placed we will not be able to
                      accept a request to change the delivery address
                      completely. You might be able to make minor changes (e.g.
                      flat/floor number) by speaking to the delivery executive.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      The package delivered to me has been damaged and/or
                      tampered with. What can I do about this?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We make sure we use only the most reputed delivery
                      companies, but it is possible that mishaps can happen from
                      time to time. In case the package delivered to you has
                      been damaged and/or tampered with you will be entitled to
                      a partial/full refund. Please see our{" "}
                      <a href="/diner-terms-of-use" className="link">
                        Refund Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSix">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      I want to speak to the home chef before placing the order.
                      How can I get their phone number?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSix"
                  className="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      As per policy we do not share the personal contact details
                      of home chefs on the platform. If you have any special
                      requests or customizations you can enter those at the time
                      of placing your order. Otherwise you can write in to{" "}
                      <a href="mailto:info@chefp.in" className="link">
                        info@chefp.in
                      </a>{" "}
                      and we can coordinate with the home chef for you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingSeven">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      I have some special requests. Is it possible to let the
                      home chef know about these?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseSeven"
                  className="collapse"
                  aria-labelledby="headingSeven"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Absolutely. One of the main advantages of ordering from
                      home chefs is that you can customize your food. Before you
                      place your order, you will have the option of entering
                      Special Requests which will be communicated to the home
                      chef.
                    </p>
                    <p>
                      But please note that in case your special
                      request/customization cannot be accommodated by the home
                      chef they may have to reject your order.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingEight">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      How do I make sure that the food matches my dietary
                      requirements?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseEight"
                  className="collapse"
                  aria-labelledby="headingEight"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      At Chef Pin we take dietary requirements very seriously.
                      That is why you will see that under each dish we have
                      asked the home chef to mention the major allergens such as
                      eggs, gluten, lactose etc. We have also asked the home
                      chefs to identify if their kitchens are suitable for
                      certain customs such as Jain-vegetarian cooking. We hope
                      that these indicators and filters will help you find your
                      perfect home chef on Chef Pin!
                    </p>
                    <p>
                      In addition to this, you can also leave a Special Request
                      for the home chef at the time of placing your order so
                      that they are aware of any dietary requirements that you
                      have.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingNine">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      We prefer to order Halal non-veg dishes. Will that be
                      possible?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseNine"
                  className="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Yes, you can see which home chefs have marked their meat
                      to be Halal and order from them.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTen">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      How can I change my order?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTen"
                  className="collapse"
                  aria-labelledby="headingTen"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Once an order has been placed, we will consider it
                      confirmed. In case you need to change your order, you
                      would have to cancel the order first and then place a
                      fresh order with the changes you require. Please make sure
                      you read our{" "}
                      <a href="/diner-terms-of-use" className="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading11">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse11"
                      aria-expanded="false"
                      aria-controls="collapse11"
                    >
                      How can I cancel my order?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse11"
                  className="collapse"
                  aria-labelledby="heading11"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We provide a 15-minute window in which a diner can cancel
                      their order with no questions asked and get a full refund.
                      If you would like to cancel your order after this free
                      cancellation window, then please read up our{" "}
                      <a href="/diner-terms-of-use" className="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading12">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse12"
                      aria-expanded="false"
                      aria-controls="collapse12"
                    >
                      I am not happy with the quality and/or quantity of the
                      food delivered to me. What can I do about this?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse12"
                  className="collapse"
                  aria-labelledby="heading12"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      In the unlikely situation that you are not satisfied with
                      your food delivery we request you to first raise the issue
                      with us at{" "}
                      <a href="mailto:info@chefp.in" className="link">
                        info@chefp.in
                      </a>
                      . We will review your complaint and we shall notify the
                      same to the home chef. For effective complaint resolution
                      we could connect you directly with the home chef, who
                      shall be responsible for redressing your complaint.
                    </p>
                    <p>
                      You may also provide a honest but considerate review on
                      our platform to share your experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading13">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse13"
                      aria-expanded="false"
                      aria-controls="collapse13"
                    >
                      How do I provide feedback on my order?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse13"
                  className="collapse"
                  aria-labelledby="heading13"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      We have an option to Review and Rate your orders. For this
                      you will need to click on your Past Orders and then select
                      the specific order on which you want to provide the
                      feedback. Please note that we only allow reviews from
                      diners who have actually ordered from the home chef – this
                      is to make sure that each review is genuine.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading14">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse14"
                      aria-expanded="false"
                      aria-controls="collapse14"
                    >
                      I want to place a party order with a home chef. How can I
                      do this?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse14"
                  className="collapse"
                  aria-labelledby="heading14"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      Please enter a party-order inquiry in the system and we
                      will get the home chef to reply to you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading15">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse15"
                      aria-expanded="false"
                      aria-controls="collapse15"
                    >
                      What is the Chef Pin customer support number?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse15"
                  className="collapse"
                  aria-labelledby="heading15"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      You can reach us at +91 98996 22242 or email us at{" "}
                      <a href="mailto:info@chefp.in" className="link">
                        info@chefp.in
                      </a>
                      . We also have a WhatsApp chat option on our site which
                      you can use.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="heading16">
                  <h5 className="mb-0">
                    <button
                      className="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse16"
                      aria-expanded="false"
                      aria-controls="collapse16"
                    >
                      I am a home chef so how do I register on Chef Pin?
                      <span className="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse16"
                  className="collapse"
                  aria-labelledby="heading16"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <p>
                      You will find a REGISTER AS A HOME CHEF button on our
                      site. Please click on that to register as a home chef on
                      Chef Pin.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(FAQDinerPage);
