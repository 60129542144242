import React, { useState } from "react";
import DinnerHeader from "../../../components/dinnerHeader";
import DinerFooter from "../../../components/dinerFooter";
import WhatsAppButton from "../../../components/whatsappButton";
import LoadingDiv from "../../../components/LoadingDiv";
import { StickyContainer, Sticky } from "react-sticky";
import Slider from "react-slick";
import { Redirect, Link } from "react-router-dom";
import {
  getChefDetailAPI,
  getChefDataBySlug,
  enquireMessageAPI,
  timeSlotAPI,
  weFastAPI,
  getReviewListAPI,
  submitDinerReviewAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
} from "../../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import Moment from "moment";
import { connect } from "react-redux";
import {
  increaseQuantity,
  decreaseQuantity,
  emptyCart,
} from "../../../redux/actions/cartActions";
import {
  addTimeSlot,
  removeTimeSlot,
} from "../../../redux/actions/timeSlotActions";
import { saveChefData } from "../../../redux/actions/chefDataActions";
import StarRatings from "react-star-ratings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TumblrShareButton } from "react-share";
import { RWebShare } from "react-web-share";
import { Helmet } from "react-helmet";
const numberReg = /^[0]?[789]\d{9}$/;
const emailReg =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class DinerInnerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showChangeChefAlert: false,
      removeChefBtnText: "Replace",
      newChefId: "",
      currentSlide: 0,
      imageSlide: {
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
      },
      chefName: "",
      brandName: "",
      chefVerified: "",
      chefCuisines: "",
      chefSpecialityDish: "",
      chefDietary: [],
      chefHalal: "",
      chefTakesPartyOrder: "",
      chefOrganicProd: "",
      chefMinimumAmt: "",
      chefDiscount: "",
      chefDiscountStartDate: "",
      chefDiscountEndDate: "",
      chefDiscountFlag: false,
      chefDiscountType: "",
      chefEnableDiscount: "",
      chefBrandLogo: "",
      chefStartTime: "",
      chefEndTime: "",
      chefAddress: "",
      chefMenu: [],
      chefMemberShip: "",
      chefOperatingDay: "",
      chefFeatureImage: [],
      chefProfileImage: "",
      chefNewAddress: "",
      dinerAddress: "",
      FASSINumber: "",
      vegChecked: false,
      eggChecked: false,
      showEnquire: false,
      showTimeSlot: false,
      displayedTimeSlot: false,
      selectedTab: 0,
      cartQuantity: "BOOK",
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      enquiryName: "",
      enquiryEmailId: "",
      enquiryMobileNo: "",
      enquiryMessage: "",
      formErrors: {
        enquiryEmailId: "",
        enquiryMobileNo: "",
        enquiryMessage: "",
      },
      isScheduled: false,
      selectedMenuId: "",
      selectedCatId: "",
      selectedMenuIndex: "",
      openingTime: "",
      closingTime: "",
      resetTimeSlot: false,
      timeSlots: [],
      chefTakeaway: "",
      chefLocality: "",
      dietType: "",
      removeTimeSlotBtnText: "Change Slot",
      kitchenDesc: "",
      kitchenFlag: false,
	  isWhatsAppVisible: false,
      whatsAppMobileNumber: '',
      whatsAppText: '',
      whatsAppLabel: '',
      flagFSSAI: "",
      slideItems: [],
      /* slideItems: [
          {
          image: "/assets/images/icons/order-1st.svg",
          title: "1st Order Pin",
          desc: "150 Happy customers lorem ipsumOrder Pin",
        }, 
        {
          image: "/assets/images/icons/order-10th.svg",
          title: "10th Order Pin",
          desc: "100 Happy customers lorem ipsum Order Pin",
          id: "chef_pin_10th_order_enabled",
        },
        {
          image: "/assets/images/icons/months-3.svg",
          title: "3 months",
          desc: "200 Happy customers lorem ipsum",
          id: "chef_pin_3rd_month_enabled",
        },
        {
          image: "/assets/images/icons/anniversary.svg",
          title: "Anniversary",
          desc: "250 Happy customers lorem ipsum",
          id: "chef_pin_12_month_enabled",
        },
        {
          image: "/assets/images/icons/super-chef.svg",
          title: "Super Chef",
          desc: "300 Happy customers lorem ipsum",
          id: "chef_pin_super_enabled",
        },
        {
          image: "/assets/images/icons/ama-pin.svg",
          title: "AMA pin",
          desc: "350 Happy customers lorem ipsum",
        },
        {
          image: "/assets/images/icons/happy-customers.svg",
          title: "Happy Customers",
          desc: "400 Happy customers lorem ipsum",
          id: "chef_pin_150th_order_enabled",
        },
      ], */
      newChefStartTime: "",
      newChefEndTime: "",
      selectedTimeSlot: "",
      displayOopsPopup: false,
      displayLocationPopup: false,
      isItemListEmpty: false,
      isChefAvailable: 0,
      displayUnavailablePopup: false,
      reviewListArr: [],
      reviewTotalRating: "",
      foodQuality: "",
      portionSize: "",
      packaging: "",
      valueForMoney: "",
      comment: "",
      formErrors: {
        foodQuality: "",
        portionSize: "",
        packaging: "",
        valueForMoney: "",
        comment: "",
      },
      ratingError: "",
      submitBtnText: "Submit",
      btnDisabled: false,
      reviewFlag: false,
      displayReviewPopup: false,
      mealOrder: "",
      mealOrderDate: "",
      mealOrderFormatDate: "",
      chefReplyFlag: false,
      chefBlogUrl: "",
      bookMarkFlag: "",
      offerLabel: "",
    };
    this.handleMapPopupChange = this.handleMapPopupChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitRatings = this.submitRatings.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.accordionContent = [];
    //const [startDate, setStartDate] = useState(new Date());
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getChefData();
    this.getReviewListData();
    if (localStorage.getItem("userName")) {
      this.setState({
        enquiryName: localStorage.getItem("userName"),
        enquiryMobileNo: localStorage.getItem("userMobile"),
        enquiryEmailId: localStorage.getItem("userEmail"),
      });
    }

    if (!this.props.cartItems.length) {
      this.props.removeTimeSlot();
    } else {
      if (this.props.timeSlot.slotDay) {
        this.setState({
          isScheduled: true,
        });
      }
    }

    if (localStorage.getItem("openReview")) {
      this.setState(
        {
          reviewFlag: true,
        },
        () => {
          console.log(this.state.reviewFlag);
        }
      );
    }
  }

  getRatingReview = () => {
    if (localStorage.getItem("userID")) {
      this.setState({
        reviewFlag: true,
      });
    } else {
      this.setState({
        displayReviewPopup: true,
      });
      localStorage.setItem("openReview", true);
    }
  };

  closeRatingReview = () => {
    this.setState({
      reviewFlag: false,
    });
  };

  getReviewListData = () => {
    let chefId = localStorage.getItem("chefID");
    return getReviewListAPI(chefId)
      .then((response) => {
        console.log(response.data.data);
        if (response.data.status === 200) {
          this.setState({
            reviewTotalRating: response.data.data.total_ratings,
            reviewListArr: response.data.data.user_reviews,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* handleInputChange function starts here */
  handleInputChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    let ratingError = this.state.ratingError;

    switch (name) {
      case "foodQuality":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "portionSize":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "packaging":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "valueForMoney":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        for (var i = 1; i <= 5; i++) {
          if (i <= e.target.value) {
            document.querySelector("#" + name + "-rating-" + i).checked = true;
          } else {
            document.querySelector("#" + name + "-rating-" + i).checked = false;
          }
        }
        break;

      case "comment":
        ratingError =
          value.length > 0
            ? ""
            : "Please select the rating for atleast one section.";
        break;

      default:
        break;
    }

    this.setState({ ratingError, [name]: value });
  };
  /* handleInputChange function ends here */

  handleStartDateChange(event) {
    this.setState({
      mealOrderDate: event,
      mealOrderFormatDate: Moment(event).format("YYYY-MM-DD"),
    });
  }

  submitRatings = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    let ratingError = this.state.ratingError;
    const {
      foodQuality,
      portionSize,
      packaging,
      valueForMoney,
      comment,
      mealOrder,
      mealOrderFormatDate,
    } = this.state;

    if (!foodQuality && !portionSize && !packaging && !valueForMoney) {
      ratingError = "Please select the rating for atleast one section.";
    }
    this.setState({ ratingError, [name]: value });

    setTimeout(() => {
      if (this.state.ratingError === "") {
        this.setState({
          submitBtnText: "Submitting...",
          btnDisabled: true,
        });
        var chefID = localStorage.getItem("chefID");
        let data = {
          user_id: this.props.userData.userId,
          email: this.props.userData.userEmail,
          diner_id: this.props.userData.userId,
          chef_id: chefID,
          food_quality: foodQuality,
          portion_size: portionSize,
          packaging: packaging,
          value_for_money: valueForMoney,
          review: comment,
          order_details: mealOrder,
          order_date: mealOrderFormatDate,
        };
        return submitDinerReviewAPI(data, this.props.userData.accessToken)
          .then((response) => {
            if (response.data.status === 200) {
              this.setState({
                submitBtnText: "Submit",
                btnDisabled: false,
                foodQuality: 0,
                portionSize: 0,
                packaging: 0,
                valueForMoney: 0,
                comment: "",
                foodQuality: "",
                portionSize: "",
                packaging: "",
                valueForMoney: "",
                comment: "",
                mealOrder: "",
                mealOrderDate: "",
                mealOrderFormatDate: "",
                reviewFlag: false,
              });
              localStorage.removeItem("openReview");
              NotificationManager.success(response.data.message, "Success!");
            } else {
              this.setState({
                submitBtnText: "Submit",
                btnDisabled: false,
                foodQuality: 0,
                portionSize: 0,
                packaging: 0,
                valueForMoney: 0,
                comment: "",
                foodQuality: "",
                portionSize: "",
                packaging: "",
                valueForMoney: "",
                comment: "",
                mealOrder: "",
                mealOrderDate: "",
                mealOrderFormatDate: "",
                ratingID: "",
              });
              NotificationManager.error(response.data.message, "Error!");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 200);
  };

  handleMapPopupChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "enquiryEmailId":
        formErrors.enquiryEmailId =
          value === ""
            ? "Email Id is required"
            : !emailReg.test(e.target.value)
            ? "Enter valid Email Id."
            : "";
        break;

      case "enquiryMobileNo":
        formErrors.enquiryMobileNo =
          value === ""
            ? "Mobile Number is required."
            : !numberReg.test(e.target.value)
            ? "Enter valid Mobile Number."
            : "";
        break;

      case "enquiryMessage":
        formErrors.enquiryMessage =
          value === "" ? "Enquire Message is required" : "";
        break;

      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  getChefData = () => {
    var type = "";
    var userId = "";
    if (localStorage.getItem("userID")) {
      userId = localStorage.getItem("userID");
    }
    /* var chefId = localStorage.getItem("chefID"); */
    return getChefDataBySlug(
      this.props.match.params.slug,
      this.state.dietType,
      userId
    )
      .then((response) => {
        if (response.data.status === 200) {
          var strChefAddress = response.data.data.address;
          var modifyChefAddress = strChefAddress.split(" ").join("+");
          if (localStorage.getItem("addressSelected")) {
            var strDinerAddress = localStorage.getItem("addressSelected");
            var modifyDinerAddress = strDinerAddress.split(" ").join("+");
          } else {
            var modifyDinerAddress = "";
          }
          let data = {
            chefId: response.data.data.chef_id,
            chefName: response.data.data.name,
            chefAddress: response.data.data.address,
            chefTakeaway: response.data.data.is_takeaway_available,
            profile_image: response.data.data.profile_image,
			chefDiscount: response.data.data.discount,
			chefDiscountStartDate: response.data.data.discount_start_date,
			chefDiscountEndDate: response.data.data.discount_end_date,
			chefDiscountType: response.data.data.discount_type,
			chefEnableDiscount: response.data.data.enable_discount,
			chefDiscountFlag: response.data.data.discount_flag,
          };
          this.props.saveChefData(data);
          localStorage.setItem("chefID", response.data.data.chef_id);
          this.setState(
            {
              chefName: response.data.data.name,
              brandName: response.data.data.brand_name,
              chefVerified: response.data.data.verified,
              chefCuisines: response.data.data.cuisines,
              chefSpecialityDish: response.data.data.special_dishes,
              chefKitchenDesc: response.data.data.kitchen_description,
              chefDietary: response.data.data.dietary_requirements,
              chefHalal: response.data.data.non_veg_is_halal,
              chefTakesPartyOrder: response.data.data.party_orders_accepted,
              chefRecycle: response.data.data.packaging_recylable,
              chefOrganicProd: response.data.data.certified_organic_use,
              chefMinimumAmt: response.data.data.minimum_order_amount,
              chefDiscount: response.data.data.discount,
              chefDiscountStartDate: response.data.data.discount_start_date,
              chefDiscountEndDate: response.data.data.discount_end_date,
              chefDiscountType: response.data.data.discount_type,
              chefEnableDiscount: response.data.data.enable_discount,
              chefDiscountFlag: response.data.data.discount_flag,
              chefBrandLogo: response.data.data.brand_logo,
              chefStartTime: response.data.data.opening_time,
              chefEndTime: response.data.data.closing_time,
              chefAddress: response.data.data.address,
              chefMenu: response.data.data.chef_menu,
              chefMemberShip: response.data.data.membership_assignment,
              chefOperatingDay: response.data.data.operational_days,
              chefFeatureImage: response.data.data.featured_images,
              chefProfileImage: response.data.data.profile_image,
              chefNewAddress: modifyChefAddress,
              dinerAddress: modifyDinerAddress,
              openingTime: response.data.data.opening_time,
              closingTime: response.data.data.closing_time,
              chefTakeaway: response.data.data.is_takeaway_available,
              chefLocality: response.data.data.locality,
              isLoading: false,
              FASSINumber: response.data.data.fssai_registration_number,
              isChefAvailable: response.data.data.is_chef_available,
              chefBlogUrl: response.data.data.chefpin_blog_url,
              bookMarkFlag: response.data.data.is_bookmarked,
              offerLabel: response.data.data.offer_label,
              flagFSSAI: response.data.data.fssai_registration,
              slideItems: response.data.data.gamification_data,
			  isWhatsAppVisible: response.data.data.whatsapp_visibility,
              whatsAppMobileNumber: encodeURIComponent(response.data.data.whatsapp_mobile_number),
              whatsAppText: encodeURIComponent(response.data.data.whatsapp_text),
              whatsAppLabel: response.data.data.whatsapp_label,
            },
            () => {
              console.log(this.state.slideItems);
              console.log(this.state.isChefAvailable);
            },
            500
          );
          var isItem = 0;
          response.data.data.chef_menu.forEach((item) => {
            if (item.menu.length > 0) {
              isItem++;
            }
          });

          if (isItem > 0) {
            this.setState({
              isItemListEmpty: false,
            });
          } else {
            this.setState({
              isItemListEmpty: true,
            });
          }

          setTimeout(() => {
           /*  if (
              localStorage.getItem("selectedLocationName") === "Bengaluru" ||
              localStorage.getItem("selectedLocationName") === "Bangalore" ||
              localStorage.getItem("selectedLocationName") === "Bangalore urban"
            ) {
              this.setState({ isChefAvailable: "0" });
            } */
            let array = response.data.data.chef_menu;
            array.forEach((menuItem) => {
              let arr = [];
              menuItem.menu.forEach((itemMenu) => {
                if (this.props.cartItems.length) {
                  var included = false;
                  var itemQty = "";
                  this.props.cartItems.forEach((item) => {
                    if (itemMenu.menu_id == item.menu_id) {
                      included = true;
                      itemQty = item.qty;
                    }
                  });
                  if (included == true) {
                    arr.push({ ...itemMenu, qty: itemQty });
                  } else {
                    arr.push({ ...itemMenu, qty: "BOOK" });
                  }
                } else {
                  arr.push({ ...itemMenu, qty: "BOOK" });
                }
              });
              menuItem.menu = arr;
            });
            this.setState({
              chefMenu: array,
            });
          }, 100);
          if (this.state.chefKitchenDesc.length > 153) {
            var entry = this.state.chefKitchenDesc;
            /* var entryArray = entry.split(); */
            var entryArray = entry.match(/.{1,152}/g);
            /*  console.log(this.state.chefKitchenDesc.length); */
            this.setState({
              kitchenDesc: entryArray[0],
            });
          } else {
            this.setState({
              kitchenFlag: true,
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        /*  console.log(error); */
      });
  };

  handleVegCheckClick = (event) => {
    if (event.target.checked) {
      this.setState(
        {
          dietType: "veg",
        },
        () => {
          this.getChefData();
        }
      );
    } else {
      this.setState(
        {
          dietType: "",
        },
        () => {
          this.getChefData();
        }
      );
    }
  };

  handleEggCheckClick = (event) => {
    if (event.target.checked) {
      this.setState(
        {
          dietType: "egg",
        },
        () => {
          this.getChefData();
        }
      );
    } else {
      this.setState(
        {
          dietType: "",
        },
        () => {
          this.getChefData();
        }
      );
    }
  };

  displayPopup = (event) => {
    event.preventDefault();
    this.setState({ showEnquire: true });
  };

  closePopup = (event) => {
    event.preventDefault();
    this.setState({ showEnquire: false });
  };

  saveData = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.enquiryMobileNo === "") {
      formErrors.enquiryMobileNo = "Mobile Number is required";
    }
    if (this.state.enquiryEmailId === "") {
      formErrors.enquiryEmailId = "Email ID is required";
    }
    if (this.state.enquiryMessage === "") {
      formErrors.enquiryMessage = "Enquire Message is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      var chefId = localStorage.getItem("chefID");
      /*    var chefId = ""; */
      return enquireMessageAPI(
        chefId,
        this.state.enquiryName,
        this.state.enquiryMobileNo,
        this.state.enquiryEmailId,
        this.state.enquiryMessage
      )
        .then((response) => {
          if (response.data.status === 200) {
            NotificationManager.success(
              response.data["message"],
              "Successful!",
              2000
            );
            this.setState({ showEnquire: false, enquiryMessage: "" });
            if (!localStorage.getItem("userName")) {
              this.setState({
                enquiryName: "",
                enquiryMobileNo: "",
                enquiryEmailId: "",
              });
            }
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          /* console.log(error); */
        });
    }
  };

  displayTimePopup = () => {
    this.getTimeSlots(this.state.openingTime, this.state.closingTime);
    this.setState({
      showTimeSlot: true,
      resetTimeSlot: true,
    });
  };

  closeTimePopup = (event) => {
    event.preventDefault();
    this.setState({ showTimeSlot: false });
    if (!this.state.isScheduled) {
      this.setState({
        selectedTimeSlot: "",
      });
    }
  };

  closeCartPopup = (event) => {
    event.preventDefault();
    this.props.history.push("/checkout");
  };

  getTimeSlots(startTime, endTime) {
    return timeSlotAPI(startTime, endTime).then((response) => {
      if (response.status === 200) {
        let timeArray = [];
        for (var key in response.data.data) {
          timeArray.push({ ...response.data.data[key], selected: false });
        }
        let tempArr = this.state.chefMenu;
        tempArr.forEach((category) => {
          category.menu.forEach((item) => {
            item.time_slot.forEach((time) => {
              time["date_time_slot"] = timeArray;
            });
          });
        });
        this.setState({
          chefMenu: tempArr,
        });
      }
    });
  }

  refreshCartItems = () => {
    let array = this.state.chefMenu;
    array.forEach((menuItem) => {
      let arr = [];
      menuItem.menu.forEach((itemMenu) => {
        if (this.props.cartItems.length) {
          var included = false;
          var itemQty = "";
          this.props.cartItems.forEach((item) => {
            if (itemMenu.menu_id == item.menu_id) {
              included = true;
              itemQty = item.qty;
            }
          });
          if (included == true) {
            arr.push({ ...itemMenu, qty: itemQty });
          } else {
            arr.push({ ...itemMenu, qty: "BOOK" });
          }
        } else {
          arr.push({ ...itemMenu, qty: "BOOK" });
        }
      });
      menuItem.menu = arr;
    });
    this.setState({
      chefMenu: array,
    });
  };

  randomString = () => {
    let length = 32;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  addCartItem = (
    categoryMenu,
    itemMenu,
    quantity,
    menuId,
    catName,
    startTime,
    endTime
  ) => {
    if (this.state.isChefAvailable === "1") {
      if (this.state.chefMemberShip === "basic") {
        this.setState({
          displayOopsPopup: true,
        });
      } else {
        if (
          this.state.isChefAvailable == "" ||
          this.state.isChefAvailable == "1" ||
          this.state.isChefAvailable == 1
        ) {
          this.setState({
            selectedMenuId: menuId,
            selectedCatId: categoryMenu,
            selectedMenuIndex: itemMenu,
          });
          let arrCategoryMenu = this.state.chefMenu;
          if (
            !this.state.isScheduled &&
            arrCategoryMenu[categoryMenu].menu[itemMenu].qty === "BOOK"
          ) {
            this.getTimeSlots(startTime, endTime);
            this.setState({
              showTimeSlot: true,
            });
          } else {
            let arrCategoryMenu = this.state.chefMenu;
            if (
              this.props.cartItems.length &&
              this.props.cartItems[0].chef_id !== this.props.chefData.chefId
            ) {
              this.setState({
                showChangeChefAlert: true,
                newChefStartTime: startTime,
                newChefEndTime: endTime,
              });
            } else {
              if (quantity == "BOOK") {
                arrCategoryMenu[categoryMenu].menu[itemMenu].qty = 1;
              } else {
                arrCategoryMenu[categoryMenu].menu[itemMenu].qty++;
              }
              this.setState({
                chefMenu: arrCategoryMenu,
              });
              NotificationManager.success(
                arrCategoryMenu[categoryMenu].menu[itemMenu].item_name +
                  " is added to cart",
                "Success"
              );
              setTimeout(() => {
                let menuItem = {
                  chef_id: this.props.chefData.chefId,
                  menu_id: arrCategoryMenu[categoryMenu].menu[itemMenu].menu_id,
                  category_name:
                    arrCategoryMenu[categoryMenu].menu[itemMenu].category_name,
                  item_name:
                    arrCategoryMenu[categoryMenu].menu[itemMenu].item_name,
                  diet_type:
                    arrCategoryMenu[categoryMenu].menu[itemMenu].diet_type,
                  menu_image:
                    arrCategoryMenu[categoryMenu].menu[itemMenu].menu_image,
                  item_price:
                    arrCategoryMenu[categoryMenu].menu[itemMenu].item_price,
				  actual_price:
                    arrCategoryMenu[categoryMenu].menu[itemMenu].actual_price,
                  qty: arrCategoryMenu[categoryMenu].menu[itemMenu].qty,
                };
                const cartItems = this.props.cartItems;
                if (!cartItems.length) {
                  let randomId = this.randomString();
                  localStorage.setItem("cartRandomStringId", randomId);
                }
                this.props.increaseQuantity(cartItems, menuItem);
                this.refreshCartItems();
              }, 100);
            }
          }
        } else {
          this.setState({
            displayUnavailablePopup: true,
          });
        }
      }
    }
  };

  removeCartItem = (categoryMenu, itemMenu, quantity) => {
    let arrCategoryMenu = this.state.chefMenu;
    let menuItem = arrCategoryMenu[categoryMenu].menu[itemMenu];
    if (quantity > 1) {
      arrCategoryMenu[categoryMenu].menu[itemMenu].qty--;
    } else {
      arrCategoryMenu[categoryMenu].menu[itemMenu].qty = "BOOK";
    }

    const cartItems = this.props.cartItems;

    this.setState({
      chefMenu: arrCategoryMenu,
      displayTimePopup: false,
    });
    NotificationManager.success(
      arrCategoryMenu[categoryMenu].menu[itemMenu].item_name +
        " is removed from cart",
      "Success"
    );
    this.props.decreaseQuantity(cartItems, menuItem);

    setTimeout(() => {
      if (!this.props.cartItems.length) {
        this.props.removeTimeSlot();
        localStorage.removeItem("cartRandomStringId");
        this.setState({
          isScheduled: false,
          selectedTimeSlot: "",
        });
      }
    }, 100);

    setTimeout(() => {
      this.refreshCartItems();
    }, 100);
  };

  handleTimeRadioChange = (e, ct, m, t, ti) => {
    let array = this.state.chefMenu;
    array[ct].menu[m].time_slot[t].date_time_slot[ti].selected = true;

    array[ct].menu[m].time_slot.map((slot, s) => {
      if (t == s) {
        this.setState({ selectedDaySlot: slot.date });
        slot.date_time_slot.map((item, i) => {
          if (i == ti) {
            item.selected = true;
            this.setState({
              selectedTimeSlot: item.start + "-" + item.end,
            });
          } else {
            item.selected = false;
          }
        });
      }
    });
    this.setState({
      chefMenuArr: array,
    });
  };

  handleScheduleClick = () => {
    this.props.emptyCart();
    this.props.addTimeSlot(
      this.state.selectedDaySlot,
      this.state.selectedTimeSlot
    );
    if (!this.state.resetTimeSlot) {
      let arrCategoryMenu = this.state.chefMenu;
      let categoryMenu = this.state.selectedCatId;
      let itemMenu = this.state.selectedMenuIndex;

      arrCategoryMenu[categoryMenu].menu[itemMenu].qty = 1;
      this.setState({
        chefMenu: arrCategoryMenu,
        isScheduled: true,
      });
      setTimeout(() => {
        let menuItem = {
          chef_id: this.props.chefData.chefId,
          menu_id: arrCategoryMenu[categoryMenu].menu[itemMenu].menu_id,
          category_name:
            arrCategoryMenu[categoryMenu].menu[itemMenu].category_name,
          item_name: arrCategoryMenu[categoryMenu].menu[itemMenu].item_name,
          diet_type: arrCategoryMenu[categoryMenu].menu[itemMenu].diet_type,
          menu_image: arrCategoryMenu[categoryMenu].menu[itemMenu].menu_image,
          actual_price: arrCategoryMenu[categoryMenu].menu[itemMenu].actual_price,
          item_price: arrCategoryMenu[categoryMenu].menu[itemMenu].item_price,
          qty: arrCategoryMenu[categoryMenu].menu[itemMenu].qty,
        };
        const cartItems = this.props.cartItems;

        if (!cartItems.length) {
          let randomId = this.randomString();
          localStorage.setItem("cartRandomStringId", randomId);
        }

        this.props.increaseQuantity(cartItems, menuItem);

        NotificationManager.success(
          menuItem.item_name + " is added to cart",
          "Success"
        );

        this.setState({
          showTimeSlot: false,
        });
        setTimeout(() => {
          this.refreshCartItems();
        }, 100);
      }, 100);
    }
    this.setState({
      resetTimeSlot: false,
    });
  };

  nextClick = (e) => {
    const { slideItems } = this.state;
    console.log(slideItems.length);
    var c1 = slideItems.length - (slideItems.length % 2);
    var c2 = slideItems.length + 2 - (slideItems.length % 2);
    console.log("c1:" + c1 + "  | " + "c2:" + c2);

    if (e >= c1) {
      if ((slideItems.length - 1) % 2 == 1) {
        this.setState({
          currentSlide: slideItems.length - 1,
        });
      } else {
        this.setState({
          currentSlide: slideItems.length - 1,
        });
      }
    } else {
      this.setState({
        currentSlide: e,
      });
    }
  };

  handleClearTimeSlot = () => {
    this.props.emptyCart();
    this.getChefData();
    this.props.removeTimeSlot();
    this.setState({
      isScheduled: false,
    });
    document.getElementById("closeMenuFormButton").click();
  };

  handleClearChef = () => {
    document.getElementById("closeMenuFormButton2").click();
    const { newChefStartTime, newChefEndTime } = this.state;
    this.getTimeSlots(newChefStartTime, newChefEndTime);
    this.setState({
      showTimeSlot: true,
    });
  };

  accordionToggle = (m) => {
    this.accordionContent[m].scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };

  getKitchenDesc = () => {
    this.setState({
      kitchenFlag: true,
    });
  };

  handleGuestLogin = () => {
    this.props.history.push("/diner-signin-with-otp?chef-innerpage");
    localStorage.setItem("chefCity", this.props.match.params.city);
    localStorage.setItem("chefSlug", this.props.match.params.slug);
  };

  handleGuestSignup = () => {
    this.props.history.push("/diner-signup-mobile?chef-innerpage");
    localStorage.setItem("chefCity", this.props.match.params.city);
    localStorage.setItem("chefSlug", this.props.match.params.slug);
  };

  handleDisplayPop = () => {
    this.setState({
      displayLocationPopup: true,
    });
  };

  displayChefReply = (id) => {
    console.log(id);
    this.setState(
      {
        ratingID: id,
      },
      () => {
        this.setState({
          chefReplyFlag: !this.state.chefReplyFlag,
        });
        console.log(this.state.ratingID);
      },
      100
    );
  };

  /* addBookmark function starts here */
  addBookmark = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var chefId = localStorage.getItem("chefID");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getChefData();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = () => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    var chefId = localStorage.getItem("chefID");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getChefData();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    NotificationManager.success("Url Copied", "Success!", 3000);
  };

  render() {
    if (!this.props.match.params.slug) {
      return <Redirect to="/" />;
    }
    /* if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }  */

    let quantity = 0;
    this.props.cartItems.forEach((item) => {
      quantity = quantity + item.qty;
    });
    const { formErrors, currentSlide, slideItems } = this.state;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slideItems && slideItems.length > 2 ? 3 : 1,
      slidesToScroll: 1,
      afterChange: this.nextClick,
      autoplaySpeed: 2000,
      autoplay: true,
    };
    return (
      <div
        className={
          !this.state.hideContent ? "css2" : "css2 mt-0 chef_inner_mobile_padd"
        }
      >
        {this.props.match.params.slug === "savoury-and-sage" ? (
          <Helmet>
            <title>Savoury and Sage - Chef Pin</title>
            <meta name="title" content="Savoury and Sage - Chef Pin" />
            <meta
              name="description"
              content="Savoury and Sage. by Aatika Kapoor. Asian, North Indian. Aatika Kapoor is known for cooking the best Tea Cakes , Quiches, Pizza, Pasta, Pad Thai Noodles, ..."
            />
          </Helmet>
        ) : this.props.match.params.slug === "cakewalk" ? (
          <Helmet>
            <title>CakeWalk - by Shravani Dang - Chef Pin</title>
            <meta
              name="title"
              content="CakeWalk - by Shravani Dang - Chef Pin"
            />
            <meta
              name="description"
              content="Bengali, Bakery And Confectionary, Desserts, Continental, Health Food, British. Shravani Dang is known for cooking the best Roast Chicken etc."
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>ChefPin</title>
            <meta name="title" content="ChefPin" />
            <meta name="description" content="ChefPin" />
          </Helmet>
        )}
        {!this.state.hideContent ? <DinnerHeader /> : null}
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <section className="chef_inner">
            <div className="container">
              <div className="row flex-flex-column-reverse flex-md-row-reverse">
                <div className="col-lg-6 d-flex justify-content-end pad_0">
                  <div className="slider_wrap">
                    <div className="res_back_btn_wrap">
                      <button className="res_back_btn">
                        <img
                          src="/assets/images/icons/white-left-arrow.png"
                          alt="left-arrow-black"
                          onClick={() => this.props.history.goBack()}
                        />
                      </button>
                    </div>
                    {this.state.chefFeatureImage.length ? (
                      <Slider
                        className="slider_wrapper slide-wrap "
                        {...this.state.imageSlide}
                      >
                        {this.state.chefFeatureImage.map((feature, f) => {
                          return (
                            <div className="img_wrap" key={"qsde" + f}>
                              <img
                                src={feature.image}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    ) : this.state.chefProfileImage ? (
                      <div className="img_wrap">
                        <img
                          src={this.state.chefProfileImage}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    ) : (
                      <img
                        src="/assets/images/noimage.png "
                        alt=" Selected for You "
                        className=" img-fluid "
                      />
                    )}
                    <div className="logo_icon">
                      {this.state.chefBrandLogo == "" ||
                      this.state.chefBrandLogo == null ? null : (
                        <img src={this.state.chefBrandLogo} alt="logo-slider" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text_wrap">
                    {this.state.brandName != "" &&
                    this.state.brandName != null ? (
                      <span>
                        <h3>
                          {this.state.brandName}
                          {this.state.chefVerified == 1 ? (
                            <img
                              src="/assets/images/icons/verified.svg"
                              alt=""
                              className="inner_verified_icon"
                            />
                          ) : null}
                        </h3>
                        <h6>by {this.state.chefName}</h6>
                      </span>
                    ) : (
                      <h3>
                        {this.state.chefName}
                        {this.state.chefVerified == 1 ? (
                          <img
                            src="/assets/images/icons/verified.svg"
                            className="inner_verified_icon"
                          />
                        ) : null}
                      </h3>
                    )}

                    <div className="fssai_logo">
                      {this.state.FASSINumber &&
                        this.state.FASSINumber !== "null" &&
                        this.state.FASSINumber !== "" && (
                          <img
                            src="/assets/images/icons/fssai.svg"
                            alt="Fssai"
                          />
                        )}
                      {this.state.FASSINumber &&
                        this.state.FASSINumber !== "null" &&
                        this.state.FASSINumber !== "" && (
                          <span>License No : {this.state.FASSINumber}</span>
                        )}
                      <span
                        className={
                          this.state.flagFSSAI == "No"
                            ? "social_share_single social_share d-none d-lg-block"
                            : "social_share d-none d-lg-block"
                        }
                        onClick={this.copyToClipboard}
                      >
                        <img src="/assets/images/icons/share.svg" />
                      </span>
                      <RWebShare
                        data={{
                          text: "",
                          url: window.location.href,
                          title: "Chefpin",
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <span
                          className={
                            this.state.flagFSSAI == "No"
                              ? "social_share_single social_share d-block d-lg-none"
                              : "social_share d-block d-lg-none"
                          }
                        >
                          <img src="/assets/images/icons/share.svg" />
                        </span>
                      </RWebShare>
                    </div>

                    <ul className="icon_list">
                      {this.state.chefMemberShip !== "basic" &&
                      this.state.reviewTotalRating != 0 ? (
                        <li className="icons start">
                          <img src="/assets/images/icons/star.svg" alt="" />
                          {this.state.reviewTotalRating}
                          <span className="grey">/5</span>
                        </li>
                      ) : null}
                      {slideItems && (
                        <li
                          className="icons pins_achieved"
                          data-toggle="modal"
                          data-target="#pins_achieved"
                        >
                          <img
                            src="/assets/images/icons/pins-achieved.svg"
                            alt=""
                          />
                          <span>Pins achieved</span>
                          <img
                            src="/assets/images/icons/pins-achieved-right-arrow.svg"
                            alt=""
                            className="right_icon"
                          />
                        </li>
                      )}

                      <li
                        className="icons bookmark"
                        onClick={() => {
                          localStorage.getItem("userID") !== null
                            ? this.state.bookMarkFlag == 1
                              ? this.removeBookmark()
                              : this.addBookmark()
                            : this.storageHandler();
                        }}
                      >
                        <img
                          src={
                            this.state.bookMarkFlag == 1
                              ? "/assets/images/icons/bookmark.svg"
                              : "/assets/images/icons/bookmark-empty.svg"
                          }
                          alt=""
                        />
                        {this.state.bookMarkFlag != 0
                          ? "Bookmarked"
                          : "Bookmark"}
                      </li>
                    </ul>

                    <h6>{this.state.chefCuisines}</h6>
                    {this.state.chefSpecialityDish != "" ? (
                      <p>
                        {this.state.chefName} is known for cooking the best{" "}
                        {this.state.chefSpecialityDish} etc.
                      </p>
                    ) : null}

                    <div className="border_line"></div>
                    <p
                      style={{
                        display: this.state.kitchenFlag ? "none" : "block",
                      }}
                    >
                      {this.state.kitchenDesc + "..."}
                      <span
                        style={{
                          display: this.state.kitchenFlag ? "none" : "block",
                        }}
                        className="orange_read"
                        onClick={this.getKitchenDesc}
                      >
                        Read More
                      </span>
                    </p>
                    <p
                      style={{
                        display: this.state.kitchenFlag ? "block" : "none",
                      }}
                    >
                      {this.state.chefKitchenDesc}
                    </p>
                  </div>
                </div>
              </div>
              <div className="border_bottom"></div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="ul_list">
                    <ul>
                      {this.state.chefTakeaway == "1" ? (
                        <li className="icon orders location">
                          Available for Delivery & Takeaway
                          <span>{this.state.chefAddress}</span>
                          <a
                            href={
                              "https://www.google.com/maps/dir/" +
                              this.state.dinerAddress +
                              "/" +
                              this.state.chefNewAddress
                            }
                            target="_blank"
                          >
                            View on Google Maps
                          </a>
                        </li>
                      ) : (
                        <li className="icon orders location">
                          Available for Delivery
                          <span>{this.state.chefLocality}</span>
                        </li>
                      )}
                      {this.state.chefStartTime != null ? (
                        <li className="hours icon">
                          Operating hours
                          <ul className="hours_list">
                            <li>
                              {this.state.chefStartTime}-{" "}
                              {this.state.chefEndTime} |{" "}
                              {this.state.chefOperatingDay}
                            </li>
                            <li>
                              Advance notice required for all orders : 24 hrs
                            </li>
                          </ul>
                        </li>
                      ) : null}
                      {/* {this.state.chefMinimumAmt != "" &&
                        this.state.chefMinimumAmt != null ? (
                        <li className="rupee icon">
                          Minimum order amount
                          <ul className="hours_list">
                            <li>INR {this.state.chefMinimumAmt}</li>
                          </ul>
                        </li>
                      ) : null} */}
                      {this.state.offerLabel ? (
                        <li className="icon percentage">
                          {this.state.offerLabel}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="ul_list">
                    <ul>
                      {this.state.chefDietary.length ? (
                        <li className="dietary icon">
                          Dietary requirements catered
                          <ul className="gluten_list">
                            {this.state.chefDietary.map((diet, d) => {
                              return (
                                <li key={"xcd" + d}>
                                  {diet.requirement_and_allergies_name}
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      ) : null}
                      {this.state.chefHalal == "Yes" ? (
                        <li className="halal icon">
                          <ul className="gluten_list">
                            <li>Halal</li>
                          </ul>
                        </li>
                      ) : null}

                      {this.state.chefTakesPartyOrder == "1" ? (
                        <li className="icon orders">
                          Takes party orders
                          <a
                            href=""
                            data-toggle="modal"
                            data-target="#Enquire_Party_Orders"
                            onClick={this.displayPopup}
                          >
                            Enquire now
                          </a>
                        </li>
                      ) : null}
					  
                    </ul>
                    <ul className="recy_organic_lsit">
					  {this.state.isWhatsAppVisible == true ? (
                        <li>
						  <a href={"https://api.whatsapp.com/send/?phone="+this.state.whatsAppMobileNumber+"&text="+this.state.whatsAppText+"&type=phone_number&app_absent=0"} target="_blank">
                          <img
                            src="/assets/images/icons/whatsapp.png"
                            alt="Recyclable Packaging Icon"
                          />
                          {this.state.whatsAppLabel}
						  </a>
                        </li>
                      ) : null}
                      {this.state.chefRecycle == "Yes" ? (
                        <li>
                          <img
                            src="/assets/images/icons/recycle-symbol.svg"
                            alt="Recyclable Packaging Icon"
                          />
                          Recyclable Packaging
                        </li>
                      ) : null}
                      {this.state.chefOrganicProd == "Yes" ? (
                        <li>
                          <img
                            src="/assets/images/icons/organic-food.svg"
                            alt="Organic Produce Icon"
                          />
                          Organic Produce
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="border_bottom mb-0"></div>
            </div>

            <div
              className="tabs_menu"
              style={{
                position: "relative",
              }}
            >
              <div className="menu_sticky">
                <div className="container ">
                  <div className="text_wrap">
                    {this.state.brandName != "" ? (
                      <span>
                        <h3>{this.state.brandName}</h3>
                        <h6>by {this.state.chefName}</h6>
                      </span>
                    ) : (
                      <h3>{this.state.chefName}</h3>
                    )}
                  </div>
                  {/* <p className="menu_text_chef_inner d-flex align-items-center">
                    Menu
                  </p>  */}
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className={
                          localStorage.getItem("openReview")
                            ? "nav-link"
                            : "nav-link active"
                        }
                        id="Menu-tab"
                        data-toggle="tab"
                        href="#Menu"
                        role="tab"
                        aria-controls="Menu"
                        aria-selected="true"
                      >
                        Menu
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={
                          localStorage.getItem("openReview")
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="Reviews-tab"
                        data-toggle="tab"
                        href="#Reviews"
                        role="tab"
                        aria-controls="Reviews"
                        aria-selected="false"
                      >
                        Reviews
                      </a>
                    </li>

                    {this.state.chefMemberShip !== "basic" &&
                    this.state.chefBlogUrl != null &&
                    this.state.chefBlogUrl.length !== 0 ? (
                      <li className="nav-item">
                        <a
                          target="_blank"
                          className="nav-link"
                          href={this.state.chefBlogUrl}
                        >
                          Blogs
                        </a>
                      </li>
                    ) : null}
                  </ul>
                  {this.props.timeSlot.slotDay ? (
                    <div
                      className="time_list"
                      data-toggle="modal"
                      data-target="#deleteTimeSlotModal"
                    >
                      <img
                        src="/assets/images/icons/chronometer.svg"
                        alt="Chronometer Icons"
                        className="time_icon"
                      />
                      <p>
                        Time slot selected :{" "}
                        <span>
                          {this.props.timeSlot.slotDay} -{" "}
                          {this.props.timeSlot.slotTime}
                        </span>
                      </p>
                      <img
                        src="/assets/images/icons/right-black-full.svg"
                        alt="Right Icon"
                        className="right_icon"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="container">
                <div className="tab-content" id="myTabContent">
                  <div
                    id="Menu"
                    className={
                      localStorage.getItem("openReview")
                        ? "tab-pane fade"
                        : "tab-pane fade show active"
                    }
                    role="tabpanel"
                    aria-labelledby="Menu-tab"
                  >
                    <div className="row">
                      <div className="col-lg-3 pr-0 d-none d-lg-block">
                        <div id="sticky-anchor"></div>

                        <div className="sidebar_menu sidebar_sticky">
                          <nav className="nav_chef_inner" id="sticky">
                            {this.state.chefMenu.map((menu, m) => {
                              return menu.menu.length ? (
                                <a
                                  className={
                                    this.state.selectedTab === m
                                      ? "navigation__link active"
                                      : "navigation__link"
                                  }
                                  // href={"#section-" + m}
                                  key={"cvgfvbt" + m}
                                  onClick={
                                    () => this.accordionToggle(m)
                                    // this.setState({ selectedTab: m })
                                  }
                                >
                                  {menu.category_name} ({menu.menu.length})
                                </a>
                              ) : null;
                            })}
                          </nav>
                        </div>
                      </div>
                      <div className="col-lg-9 pl-lg-0">
                        <div className="right_section sidebar_sticky">
                          <ul className="filter_sliders_list">
                            <li>
                              <div className="active_deact_toggle veg">
                                <span className="act de_active">Veg</span>
                                <div className="toggle_box">
                                  <input
                                    type="checkbox"
                                    checked={this.state.dietType === "veg"}
                                    onChange={this.handleVegCheckClick}
                                  />
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="active_deact_toggle egg">
                                <span className="act de_active">Egg</span>
                                <div className="toggle_box">
                                  <input
                                    type="checkbox"
                                    checked={this.state.dietType === "egg"}
                                    onChange={this.handleEggCheckClick}
                                  />
                                </div>
                              </div>
                            </li>
                          </ul>

                          <div className="dash_line d-block d-lg-none"></div>
                          {this.state.dietType !== "" &&
                          this.state.isItemListEmpty ? (
                            <div className="page_section hero border-0 pt-0">
                              <div className="wrap">
                                <div className="text">
                                  <h6 className="pr-0">
                                    Sorry, there are no items that match your
                                    selection
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ) : (
                            this.state.chefMenu.map((menuItem, item) => {
                              return menuItem.menu.length ? (
                                <div
                                  className={
                                    item == 0
                                      ? "page_section hero pt-0"
                                      : "page_section hero"
                                  }
                                  id={"section-" + item}
                                  key={"ikjnbfrt" + item}
                                >
                                  <h5
                                    data-toggle="collapse"
                                    href={"#collapseExample" + item}
                                    role="button"
                                    ref={(ref) =>
                                      (this.accordionContent[item] = ref)
                                    }
                                    aria-expanded="true"
                                    aria-controls="collapseExample"
                                  >
                                    {menuItem.category_name} (
                                    {menuItem.menu.length})
                                  </h5>
                                  <div
                                    id={"collapseExample" + item}
                                    className="collapse show"
                                  >
                                    {menuItem.menu.map((dishItem, d) => {
                                      return (
                                        <div
                                          className={
                                            d == 0 ? "wrapper pt-0" : "wrapper"
                                          }
                                          key={"osdfd" + d}
                                        >
                                          <div className="wrap">
                                            <div
                                              className={
                                                dishItem.enabled === "0" ||
                                                dishItem.is_available_now ===
                                                  "0" ||
                                                this.state.isChefAvailable ===
                                                  "0"
                                                  ? "img_wrap not_avail_active"
                                                  : "img_wrap"
                                              }
                                            >
                                              {dishItem.diet_type ===
                                              "Non Veg" ? (
                                                <div className="icon_logo">
                                                  <img
                                                    src="/assets/images/icons/non-veg.svg"
                                                    alt=""
                                                  />
                                                </div>
                                              ) : null}
                                              {dishItem.diet_type === "Veg" ? (
                                                <div className="icon_logo">
                                                  <img
                                                    src="/assets/images/icons/veg-icon.svg"
                                                    alt=""
                                                  />
                                                </div>
                                              ) : null}
                                              {dishItem.diet_type ===
                                              "Contains Egg" ? (
                                                <div className="icon_logo">
                                                  <img
                                                    src="/assets/images/icons/egg-icon.svg"
                                                    alt=""
                                                  />
                                                </div>
                                              ) : null}
                                              {dishItem.menu_image === null ? (
                                                <img
                                                  src="/assets/images/noimage.png"
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              ) : (
                                                <img
                                                  src={dishItem.menu_image}
                                                  alt="Paneer Biryani"
                                                />
                                              )}
                                              {dishItem.enabled === "0" ||
                                              dishItem.is_available_now ===
                                                "0" ||
                                              this.state.isChefAvailable ===
                                                "0" ? (
                                                <div className="not_available">
                                                  Not Available
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="text">
                                              <h6>
                                                {dishItem.item_name}
                                                {dishItem.enabled !== "0" ? (
                                                  <div
                                                    className={
                                                      dishItem.qty != "BOOK"
                                                        ? "button_add color_fill"
                                                        : "button_add"
                                                    }
                                                  >
                                                    {this.state
                                                      .chefMemberShip !==
                                                      "basic" &&
                                                      dishItem.is_available_now !==
                                                        "0" &&
                                                      this.state
                                                        .isChefAvailable !==
                                                        "0" &&
                                                      dishItem.enabled !==
                                                        "0" && (
                                                        <form
                                                          id="myform"
                                                          method="POST"
                                                          action="#"
                                                        >
                                                          <input
                                                            type="button"
                                                            value="-"
                                                            className={
                                                              this.state
                                                                .chefMemberShip ===
                                                                "basic" ||
                                                              this.state
                                                                .isChefAvailable ===
                                                                "0" ||
                                                              dishItem.enabled ===
                                                                "0"
                                                                ? "disabled-add-cart-btn qtyminus"
                                                                : "qtyminus"
                                                            }
                                                            field="quantity"
                                                            onClick={this.removeCartItem.bind(
                                                              this,
                                                              item,
                                                              d,
                                                              dishItem.qty
                                                            )}
                                                            disabled={
                                                              dishItem.qty ==
                                                              "BOOK"
                                                            }
                                                          />
                                                          <input
                                                            type="text"
                                                            name="quantity"
                                                            value={dishItem.qty}
                                                            className={
                                                              dishItem.qty !==
                                                              "BOOK"
                                                                ? "qty "
                                                                : "qty cursor-qty"
                                                            }
                                                            disabled={
                                                              dishItem.qty !==
                                                              "BOOK"
                                                            }
                                                            onClick={
                                                              localStorage.getItem(
                                                                "addressSelected"
                                                              )
                                                                ? this.addCartItem.bind(
                                                                    this,
                                                                    item,
                                                                    d,
                                                                    dishItem.qty,
                                                                    dishItem.menu_id,
                                                                    dishItem.category_name,
                                                                    dishItem.opening_time,
                                                                    dishItem.closing_time
                                                                  )
                                                                : this
                                                                    .handleDisplayPop
                                                            }
                                                            readOnly
                                                          />
                                                          <input
                                                            type="button"
                                                            value="+"
                                                            className="qtyplus"
                                                            field="quantity"
                                                            className={
                                                              this.state
                                                                .chefMemberShip ===
                                                                "basic" ||
                                                              this.state
                                                                .isChefAvailable ===
                                                                "0" ||
                                                              dishItem.enabled ===
                                                                "0"
                                                                ? "disabled-add-cart-btn qtyplus"
                                                                : "qtyplus"
                                                            }
                                                            onClick={
                                                              localStorage.getItem(
                                                                "addressSelected"
                                                              )
                                                                ? this.addCartItem.bind(
                                                                    this,
                                                                    item,
                                                                    d,
                                                                    dishItem.qty,
                                                                    dishItem.menu_id,
                                                                    dishItem.category_name,
                                                                    dishItem.opening_time,
                                                                    dishItem.closing_time
                                                                  )
                                                                : this
                                                                    .handleDisplayPop
                                                            }
                                                          />
                                                        </form>
                                                      )}
                                                  </div>
                                                ) : null}
                                              </h6>
                                              {menuItem.category_name ===
                                              "Chef's Specials" ? (
                                                <p>
                                                  In {menuItem.category_name}
                                                </p>
                                              ) : null}
                                              <ul>
                                                <li>
                                                  INR{" "}{this.state.chefDiscountFlag == true ? 
												  ( 
													<span>
														<del class="strikeprice">
															{ dishItem.actual_price }
														</del>{" "}
														{dishItem.item_price}{" "}				
														<span class="strikepricediscount">{"("}{ this.state.chefDiscount }{"% Off)"}</span>
													</span>
												   )  : (
													dishItem.item_price
												   )}
                                                </li>
                                                {dishItem.unit === "portion" ? (
                                                  <li>
                                                    {"Serves " +
                                                      dishItem.standard_quantity_or_portion_size}
                                                  </li>
                                                ) : (
                                                  <li>
                                                    {
                                                      dishItem.standard_quantity_or_portion_size
                                                    }{" "}
                                                    {dishItem.unit}
                                                  </li>
                                                )}
                                              </ul>
                                              <p className="para">
                                                {dishItem.item_description}
                                              </p>
                                            </div>
                                          </div>
                                          <p className="para2">
                                            {dishItem.item_description}
                                          </p>
                                          <ul className="bottom_list">
                                            {dishItem.menu_uses_material.map(
                                              (material, m) => {
                                                return (
                                                  <li key={"bnjuygb" + m}>
                                                    {material.material_name}
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : null;
                            })
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="Reviews"
                    className={
                      localStorage.getItem("openReview")
                        ? "tab-pane fade custom-review-wrap show active"
                        : "tab-pane fade custom-review-wrap"
                    }
                    role="tabpanel"
                    aria-labelledby="Reviews-tab"
                  >
                    <div className="text_wrap review_icon">
                      <div className="container">
                        <ul className="icon_list mb-0">
                          {this.state.reviewTotalRating != 0 ? (
                            <li className="icons start border-0 font-weight-bold">
                              <StarRatings
                                rating={
                                  this.state.reviewTotalRating
                                    ? parseFloat(this.state.reviewTotalRating)
                                    : 0
                                }
                                starDimension="30px"
                                starSpacing="13px"
                                starRatedColor="#FFC107"
                                starEmptyColor="#EAEAEA"
                              />
                              {this.state.reviewTotalRating
                                ? this.state.reviewTotalRating
                                : 0}
                              /<span className="grey">5</span>
                            </li>
                          ) : null}
                          <li>
                            <button
                              className="btn add_new_btn"
                              onClick={this.getRatingReview}
                            >
                              <span>+</span> Add a REVIEW
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {this.state.reviewFlag ? (
                      <div className="outer">
                        <div className="inner current_orders_card">
                          <form onSubmit={this.submitRatings} id="ratingForm">
                            <div className="order_wrap rate_review_wrap">
                              <div className="save_add_wrap">
                                <div className="card mb-0 pb-0 pt-0 shadow_none bg_none border-0">
                                  <h6 className=""> Add a review</h6>
                                  <hr className="m-0" />
                                  <div className="box px-0 pb-0">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Meal Ordered</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="mealOrder"
                                            onChange={this.handleInputChange}
                                            value={this.state.mealOrder}
                                            maxLength="40"
                                            placeholder="Enter the dish you ordered"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Date of order</label>
                                          {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                                          <DatePicker
                                            className="form-control"
                                            selected={this.state.mealOrderDate}
                                            onChange={
                                              this.handleStartDateChange
                                            }
                                            placeholderText="DD/MM/YYYY"
                                            maxDate={new Date()}
                                            // maxDate={new Date(new Date().getTime()+(-1*24*60*60*1000))}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <hr className="m-0" />
                                    <ul className="rate_star_list">
                                      <li>
                                        <span className="fw_500">
                                          Food Quality
                                        </span>
                                        <span className="star-cb-group">
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="foodQuality-rating-1"
                                              name="foodQuality"
                                              value="1"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="foodQuality-rating-2"
                                              name="foodQuality"
                                              value="2"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="foodQuality-rating-3"
                                              name="foodQuality"
                                              value="3"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="foodQuality-rating-4"
                                              name="foodQuality"
                                              value="4"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="foodQuality-rating-5"
                                              name="foodQuality"
                                              value="5"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="fw_500">
                                          Portion Size
                                        </span>
                                        <span className="star-cb-group">
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="portionSize-rating-1"
                                              name="portionSize"
                                              value="1"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="portionSize-rating-2"
                                              name="portionSize"
                                              value="2"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="portionSize-rating-3"
                                              name="portionSize"
                                              value="3"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="portionSize-rating-4"
                                              name="portionSize"
                                              value="4"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="portionSize-rating-5"
                                              name="portionSize"
                                              value="5"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="fw_500">
                                          Packaging
                                        </span>
                                        <span className="star-cb-group">
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="packaging-rating-1"
                                              name="packaging"
                                              value="1"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="packaging-rating-2"
                                              name="packaging"
                                              value="2"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="packaging-rating-3"
                                              name="packaging"
                                              value="3"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="packaging-rating-4"
                                              name="packaging"
                                              value="4"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="packaging-rating-5"
                                              name="packaging"
                                              value="5"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="fw_500">
                                          Value for Money
                                        </span>
                                        <span className="star-cb-group">
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="valueForMoney-rating-1"
                                              name="valueForMoney"
                                              value="1"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="valueForMoney-rating-2"
                                              name="valueForMoney"
                                              value="2"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="valueForMoney-rating-3"
                                              name="valueForMoney"
                                              value="3"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="valueForMoney-rating-4"
                                              name="valueForMoney"
                                              value="4"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                          <div className="wrap">
                                            <input
                                              type="checkbox"
                                              id="valueForMoney-rating-5"
                                              name="valueForMoney"
                                              value="5"
                                              onChange={this.handleInputChange}
                                            />
                                            <label className="icons star_icon"></label>
                                          </div>
                                        </span>
                                      </li>
                                    </ul>
                                    {this.state.ratingError.length > 0 && (
                                      <small className="text-danger">
                                        {this.state.ratingError}
                                      </small>
                                    )}

                                    <hr className="m-0" />

                                    <div className="save_add_wrap">
                                      <div className="box px-0 pb-0">
                                        <h6 className="font-weight-normal">
                                          Write a review
                                        </h6>
                                        <p>
                                          Review can be maximum 1000 characters
                                          in length
                                        </p>
                                        <div className="form-group mb-0">
                                          <textarea
                                            className="form-control"
                                            name="comment"
                                            onChange={this.handleInputChange}
                                            maxLength="1000"
                                          ></textarea>
                                        </div>
                                        {formErrors.comment.length > 0 && (
                                          <small className="text-danger">
                                            {formErrors.comment}
                                          </small>
                                        )}
                                      </div>
                                      <div className="form-group save_btn_next btn-group d-flex justify-content-center justify-content-sm-end align-items-end mw-100 mb-0">
                                        <button
                                          className="btn previous_btn"
                                          type="submit"
                                          onClick={this.closeRatingReview}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          disabled={this.state.btnDisabled}
                                          className="btn add_new_btn"
                                          type="submit"
                                        >
                                          {this.state.submitBtnText}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}

                    <div className="page_section hero pt-0 reviews_page">
                      {this.state.reviewListArr &&
                        this.state.reviewListArr.map((rating, r) => {
                          var rt = parseInt(rating.total);
                          var chefName = "";
                          if (rating.comments.length) {
                            chefName = rating.comments.name;
                          }

                          return (
                            <div className="wrapper" key={r}>
                              <div className="wrap mb-0">
                                <div className="mediem_circle_loc">
                                  <div className="inner">
                                    <img
                                      src="/assets/images/user.png"
                                      alt="Profile Images"
                                    />
                                  </div>
                                </div>
                                <div className="text">
                                  <h6 className="pr-0">
                                    {rating.name}
                                    {rating.verified_order === true ? (
                                      <span className="veri_order">
                                        Verified Order
                                      </span>
                                    ) : null}
                                    <p className="mb-0 d-none d-sm-inline-block">
                                      {Moment(rating.created_at).format(
                                        "Do MMM, YYYY"
                                      )}
                                    </p>
                                  </h6>
                                  <span className="review_star">
                                    <StarRatings
                                      rating={rt}
                                      starDimension="20px"
                                      starSpacing="4px"
                                      starRatedColor="#FFC107"
                                    />
                                    {rating.total == "5.0" ||
                                    rating.total == "4.0" ||
                                    rating.total == "3.0" ||
                                    rating.total == "2.0" ||
                                    rating.total == "1.0"
                                      ? parseInt(rating.total)
                                      : rating.total}
                                    <span className="grey">/5</span>
                                  </span>
                                  <h6 className="d-inline-block d-sm-none mb-0">
                                    <p className="mb-0">
                                      {Moment(rating.created_at).format(
                                        "Do MMM, YYYY"
                                      )}
                                    </p>
                                  </h6>
                                  <p className="para">{rating.review}</p>
                                  {rating.comments.length ? (
                                    <div className="view_comment_text">
                                      <hr className="dash_line"></hr>
                                      <span
                                        onClick={() =>
                                          this.displayChefReply(rating.id)
                                        }
                                      >
                                        View Comment
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {rating.id === this.state.ratingID
                                ? rating.comments.map((comment, c) => {
                                    return (
                                      <div
                                        className="wrap mb-0"
                                        key={"adv" + c}
                                      >
                                        {rating.id === this.state.ratingID ? (
                                          <div className="mediem_circle_loc">
                                            <div className="inner">
                                              <img
                                                src="/assets/images/user.png"
                                                alt="Profile Images"
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                        {rating.id === this.state.ratingID ? (
                                          <div className="text">
                                            <h6>
                                              {comment.name}
                                              <p className="mb-0 d-none d-sm-inline-block">
                                                {Moment(
                                                  comment.created_at
                                                ).format("Do MMM, YYYY")}
                                              </p>
                                            </h6>
                                            <h6 className="d-inline-block d-sm-none mb-0">
                                              <p className="mb-0">
                                                {Moment(
                                                  comment.created_at
                                                ).format("Do MMM, YYYY")}
                                              </p>
                                            </h6>
                                            <p className="para">
                                              {comment.review_comments}
                                            </p>
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {/* <div
                    id="Blogs"
                    className="tab-pane fade"
                    role="tabpanel"
                    aria-labelledby="Blogs-tab"
                  >
                    ...
                  </div> */}
                </div>
              </div>
            </div>

            {this.props.cartItems.length ? (
              <div id="AddToCart" className="add_cart_orange active">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 pr-0">
                      <div className="left">
                        <h6>
                          {quantity == 1
                            ? quantity + " Item"
                            : quantity + " Items"}{" "}
                          | INR{" "}
                          {this.props.cartItems.reduce(
                            (a, c) => a + c.item_price * c.qty,
                            0
                          )}{" "}
                          <span>+ delivery &amp; taxes</span>
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-9 pl-0 d-flex align-items-center">
                      <div className="right_section sidebar_sticky">
                        <h6 className="text-white">
                          Time Slot : {this.props.timeSlot.slotDay} -{" "}
                          {this.props.timeSlot.slotTime}
                        </h6>
                        <h6
                          className="view_cart text-white"
                          onClick={this.closeCartPopup}
                        >
                          View Cart
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </section>
        )}
        <div className="chef_inner_foot">
          <WhatsAppButton />
        </div>
        {/* {!this.state.hideContent ? <DinerFooter /> : null} */}

        {slideItems && (
          <div
            className="pins_achieved_modal modal fade "
            id="pins_achieved"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="pins_achievedTitle"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="pins_achievedTitle">
                    Pins achieved
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="/assets/images/icons/black-close.svg"
                      alt="Close"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="wrap-modal-slider open">
                    <Slider {...settings} className="pin_slider slider-nav">
                      {slideItems.map((item, i) => {
                        return (
                          <div className="icons" key={"grkw" + i}>
                            <div
                              onClick={() => this.setState({ currentSlide: i })}
                            >
                              <img src={item.image} alt={item.title} />
                            </div>
                            <span>{item.title}</span>
                          </div>
                        );
                      })}
                    </Slider>
                    <Slider className="pin_slider slider-for">
                      {slideItems.map((item, i) => {
                        return (
                          currentSlide == i && (
                            <div className="bottom_text" key={"gfdrkg" + i}>
                              <img src={item.image} alt={item.title} />
                              <span> {item.desc}</span>
                            </div>
                          )
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.showEnquire ? (
          <div
            className={
              this.state.showEnquire
                ? "pins_achieved_modal modal fade enquire_orders show d-block"
                : "pins_achieved_modal modal fade enquire_orders"
            }
            id="Enquire_Party_Orders"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="pins_achievedTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="pins_achievedTitle">
                    Enquire for Party Orders
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closePopup}
                  >
                    <img
                      src="/assets/images/icons/black-close.svg"
                      alt="Close"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="wrap-modal-slider">
                    <form>
                      <div className="form-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="enquiryName"
                          value={this.state.enquiryName}
                          onChange={this.handleMapPopupChange}
                          maxLength="40"
                          autoFocus
                        />
                      </div>
                      <div className="form-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile No."
                          name="enquiryMobileNo"
                          value={this.state.enquiryMobileNo}
                          onChange={this.handleMapPopupChange}
                          maxLength="10"
                        />
                      </div>
                      <small className="text-danger">
                        {formErrors.enquiryMobileNo}
                      </small>
                      <div className="form-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email ID"
                          name="enquiryEmailId"
                          value={this.state.enquiryEmailId}
                          onChange={this.handleMapPopupChange}
                          maxLength="40"
                        />
                      </div>
                      <small className="text-danger">
                        {formErrors.enquiryEmailId}
                      </small>
                      <div className="form-group mb-2">
                        <textarea
                          className="form-control"
                          id="Typeyourenquiry"
                          rows="3"
                          name="enquiryMessage"
                          placeholder="Message"
                          value={this.state.enquiryMessage}
                          onChange={this.handleMapPopupChange}
                          maxLength="250"
                        ></textarea>
                        <label className="bottom_label">
                          Type your enquiry
                        </label>
                      </div>
                      <small className="text-danger">
                        {formErrors.enquiryMessage}
                      </small>
                      <div className="form-group text-center mb-0">
                        <button className="btn" onClick={this.saveData}>
                          submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showEnquire ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
        {this.state.showTimeSlot ? (
          <div
            className={
              this.state.showTimeSlot
                ? "modal bottom_pop fade map_pop modal2 time_slot show d-block"
                : "modal bottom_pop fade map_pop modal2 time_slot"
            }
            id="Time_Modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="Time_Modal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="more-details">
                    <div className="form_wrap edit-delivery-address">
                      <div className="address-header">
                        <div className="top_wrap">
                          <h5 className="text-uppercase color_a05">
                            WHEN WOULD YOU LIKE THIS ORDER TO BE READY?
                            <button
                              type="button"
                              className="close_icon"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={this.closeTimePopup}
                            >
                              <img
                                src="/assets/images/icons/black-close.svg"
                                alt="Close"
                              />
                            </button>
                          </h5>
                          <p>
                            This is the time slot when your order will be picked
                            up for delivery or take-away. If a dish is
                            unavailable at your desired slot the home chef has
                            disabled it or it falls outside their operating
                            hours.
                          </p>
                        </div>
                        <div id="accordion" className="mt-0">
                          {this.state.chefMenu.map((cat, ct) =>
                            cat.menu.map((menu, m) => {
                              return (
                                <div key={"ssfsdsf" + m}>
                                  {this.state.selectedCatId == ct &&
                                  this.state.selectedMenuId == menu.menu_id
                                    ? menu.time_slot.map((timeSlot, t) => {
                                        return (
                                          <div
                                            className="card"
                                            key={"nfhtdd" + t}
                                          >
                                            <div
                                              className="slot_title"
                                              id={"heading" + t}
                                            >
                                              <h6>{timeSlot.date}</h6>

                                              {timeSlot.is_available == true ? (
                                                <p
                                                  data-toggle="collapse"
                                                  data-target={"#collapse" + t}
                                                  aria-expanded={
                                                    t == 0 ? "true" : "false"
                                                  }
                                                  aria-controls={
                                                    "#collapse" + t
                                                  }
                                                >
                                                  Available slots
                                                </p>
                                              ) : (
                                                <p>
                                                  This dish is not available on
                                                  this date.
                                                </p>
                                              )}
                                            </div>
                                            <div
                                              id={"collapse" + t}
                                              className={
                                                t == 0
                                                  ? "collapse show"
                                                  : "collapse"
                                              }
                                              aria-labelledby={"heading" + t}
                                              aria-expanded="false"
                                              data-parent="#accordion"
                                            >
                                              {timeSlot.is_available == true ? (
                                                <div className="card-body">
                                                  {timeSlot.is_available ==
                                                  true ? (
                                                    <div className="radio-box">
                                                      <ul className="radio_list d-block">
                                                        {timeSlot.date_time_slot
                                                          ? timeSlot.date_time_slot.map(
                                                              (time, ti) => {
                                                                return (
                                                                  <li
                                                                    key={
                                                                      "oiuikn" +
                                                                      ti
                                                                    }
                                                                  >
                                                                    <div
                                                                      className={
                                                                        ti == 0
                                                                          ? "custome_check my-2"
                                                                          : "custome_check"
                                                                      }
                                                                    >
                                                                      <label
                                                                        htmlFor={
                                                                          timeSlot.date.replace(
                                                                            /\s+/g,
                                                                            ""
                                                                          ) + ti
                                                                        }
                                                                      >
                                                                        {
                                                                          time.start
                                                                        }{" "}
                                                                        -{" "}
                                                                        {
                                                                          time.end
                                                                        }
                                                                        <input
                                                                          type="radio"
                                                                          id={
                                                                            timeSlot.date.replace(
                                                                              /\s+/g,
                                                                              ""
                                                                            ) +
                                                                            ti
                                                                          }
                                                                          value={
                                                                            time.start +
                                                                            "-" +
                                                                            time.end
                                                                          }
                                                                          name={timeSlot.date.replace(
                                                                            /\s+/g,
                                                                            ""
                                                                          )}
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            this.handleTimeRadioChange(
                                                                              e,
                                                                              ct,
                                                                              m,
                                                                              t,
                                                                              ti
                                                                            )
                                                                          }
                                                                          checked={
                                                                            time.selected &&
                                                                            this
                                                                              .state
                                                                              .selectedDaySlot ==
                                                                              timeSlot.date
                                                                          }
                                                                        />
                                                                        <span className="radiobtn"></span>
                                                                      </label>
                                                                    </div>
                                                                  </li>
                                                                );
                                                              }
                                                            )
                                                          : null}
                                                      </ul>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                      <div className="form-group save-btn mb-0">
                        <button
                          disabled={!this.state.selectedTimeSlot}
                          type="button"
                          className="btn"
                          onClick={this.handleScheduleClick}
                        >
                          schedule
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showTimeSlot ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
        <div className="menu_btn">
          <img
            src="/assets/images/icons/menu-icon.svg"
            alt="Organic Produce Icon"
          />
          <p>Menu</p>
          <div className="custPopup">
            <ul>
              {this.state.chefMenu.map((menu, m) => {
                return menu.menu.length ? (
                  <li key={"vbnhf" + m}>
                    <a
                      className={
                        this.state.selectedTab === m
                          ? "navigation__link active"
                          : "navigation__link"
                      }
                      href={"#section-" + m}
                      onClick={() => this.setState({ selectedTab: m })}
                    >
                      {menu.category_name} ({menu.menu.length})
                    </a>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
          <div className="custPopup_over"></div>
        </div>
        <div
          className="modal fade popup bottom_pop map_pop cancel_order_pop"
          id="deleteTimeSlotModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="LeaveModalTitle"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body text-center">
                <button
                  type="button"
                  className="close_icon"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="/assets/images/icons/black-close.svg" alt="Close" />
                </button>
                <h6>Want to change the time slot?</h6>
                <p>
                  Please note that if you change the time slot your cart will be
                  emptied and you will have to add the dishes again.{" "}
                </p>
                <div className="btn-group">
                  <div>
                    <button
                      id="closeMenuFormButton"
                      className="btn previous_btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className="btn" onClick={this.handleClearTimeSlot}>
                      {this.state.removeTimeSlotBtnText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showChangeChefAlert ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          showChangeChefAlert: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <h6>Replace Cart Items?</h6>
                    <p>
                      Your cart already contains dishes from a Home Chef.
                      Proceeding further will discard the previously added
                      items.
                    </p>
                    <div className="btn-group">
                      <div>
                        <button
                          id="closeMenuFormButton2"
                          className="btn previous_btn"
                          onClick={() =>
                            this.setState({
                              showChangeChefAlert: false,
                            })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                      <div>
                        <button className="btn" onClick={this.handleClearChef}>
                          {this.state.removeChefBtnText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}

        {this.state.displayOopsPopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          displayOopsPopup: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <p className="mb-0">
                      Oops! This home chef has not enabled online orders, but we
                      will let them know that diners are interested in ordering
                      from them.
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.displayUnavailablePopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          displayUnavailablePopup: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <p className="mb-0">
                      Oops! This home chef is not taking orders at this time.
                      Please select another home chef.
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.displayLocationPopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          displayLocationPopup: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <p className="mb-0">
                      To place your order now, log in to your existing account
                      or sign up.
                    </p>
                    <div className="btn-group mt-3">
                      <div>
                        <button
                          className="btn"
                          onClick={this.handleGuestSignup}
                        >
                          Sign up
                        </button>
                      </div>
                      <div>
                        <button className="btn" onClick={this.handleGuestLogin}>
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.displayReviewPopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() => {
                        localStorage.removeItem("openReview");
                        this.setState({
                          displayReviewPopup: false,
                        });
                      }}
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <p className="mb-0">
                      To add review, log in to your existing account or sign up.
                    </p>
                    <div className="btn-group mt-3">
                      <div>
                        <button
                          className="btn"
                          onClick={this.handleGuestSignup}
                        >
                          Sign up
                        </button>
                      </div>
                      <div>
                        <button className="btn" onClick={this.handleGuestLogin}>
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.showChangeChefAlert ||
        this.state.displayOopsPopup ||
        this.state.displayUnavailablePopup ||
        this.state.displayLocationPopup ||
        this.state.displayReviewPopup ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.items,
  timeSlot: state.timeSlot.items,
  userData: state.user.items,
  chefData: state.chef.items,
});

export default connect(mapStateToProps, {
  increaseQuantity,
  decreaseQuantity,
  emptyCart,
  addTimeSlot,
  removeTimeSlot,
  saveChefData,
})(DinerInnerPage);
