/*
#############################################################################
# Name : popularHomeChef.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the popular chef data
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../../components/dinnerHeader";
import DinerFooter from "../../../components/dinerFooter";
import WhatsAppButton from "../../../components/whatsappButton";
import LoadingDiv from "../../../components/LoadingDiv";
import {
  popularHomeChefListingAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
} from "../../../core/services/Phase2APIservice";
import DinerMenuTab from "../../../components/dinerMenuTab";
import { Redirect, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import config from "../../../config/config";
import PromotedBadge from "../../../components/PromotedBadge";
class PopularHomeChef extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      premiumChefArr: [],
      errorStatus: false,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.getPremiumChef(); // Premium chef data is fetched
  }

  /* getPremiumChef function starts here */
  getPremiumChef = async () => {
    var city = localStorage.getItem("selectedLocationName");
    var pincode = localStorage.getItem("pincode");
    var latitude = localStorage.getItem("latitude");
    var longitude = localStorage.getItem("longitude");
    var userId = "";
    if (localStorage.getItem("userID")) {
      userId = localStorage.getItem("userID");
    }
    try {
      const response = await popularHomeChefListingAPI(
        city,
        pincode,
        latitude,
        longitude,
        userId
      );
      if (response.data.status === 200) {
        this.setState({
          premiumChefArr: response.data.data.premium_chef_list,
          isLoading: false,
        });
        if (response.data.data.premium_chef_list.length === 0) {
          this.setState({
            errorStatus: true,
          });
        } else {
          this.setState({
            errorStatus: false,
          });
        }
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      /* console.log(error); */
    }
  };
  /* getPremiumChef function ends here */

  /* addBookmark function starts here */
  addBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return getBookMarkAPI(userId, token, 1, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getPremiumChef();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* addBookmark function ends here */

  /* removeBookmark function starts here */
  removeBookmark = (chefId) => {
    var userId = localStorage.getItem("userID");
    var token = localStorage.getItem("accessToken");
    return deleteBookMarkAPI(userId, token, chefId)
      .then((response) => {
        if (response.data.status === 200) {
          NotificationManager.success(
            response.data.message,
            "Successful!",
            3000
          );
          this.getPremiumChef();
        }
      })
      .catch((error) => {
        /* console.log(error); */
      });
  };
  /* removeBookmark function ends here */

  /* getInerPage function starts here */
  getInerPage = (chefID, slugUrl, kitchenName, chefAvailable) => {
    localStorage.setItem("chefID", chefID);
    var city = config.slugify(localStorage.getItem("selectedLocation"));
    this.props.history.push("/" + city + "/chef-inner-homepage/" + slugUrl);
  };
  /* getInerPage function ends here */

  storageHandler = (e) => {
    localStorage.clear();
    this.props.history.push("/user-selection");
  };

  render() {
    if (!localStorage.getItem("addressSelected")) {
      return <Redirect to="/" />;
    }
    return (
      <div className="css2">
        <DinnerHeader />
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <div>
            <section className="shoe_all_bg text-white">
              <div className="container">
                <h4>Explore More</h4>
              </div>
            </section>
            <section className="selected_you selected_for_you see_space">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <div className="row">
                      {this.state.premiumChefArr.map((premium, i) => {
                        return (
                          <div
                            className="col-sm-6 col-lg-3 col-xl-3"
                            key={"ytrfdc" + i}
                          >
                            <div
                              className={
                                premium.is_chef_available === "1"
                                  ? "card"
                                  : "card unavailable-chef-card"
                              }
                            >
                              <div
                                className="direction"
                                onClick={() => {
                                  localStorage.getItem("userID") !== null
                                    ? premium.is_bookmarked == 1
                                      ? this.removeBookmark(premium.chef_id)
                                      : this.addBookmark(premium.chef_id)
                                    : this.storageHandler();
                                }}
                              >
                                <img
                                  src={
                                    premium.is_bookmarked == 1
                                      ? "/assets/images/icons/bookmark.svg"
                                      : "/assets/images/icons/bookmark-empty.svg"
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                onClick={() =>
                                  this.getInerPage(
                                    premium.chef_id,
                                    premium.slug_url,
                                    premium.name,
                                    premium.is_chef_available
                                  )
                                }
                              >
                                {premium.promoted == 1 ? (
                                  <PromotedBadge/>
                                ) : null}
                                <div className="img_wrap">
                                  {premium.is_offer_available === "1" ? (
                                    <div className="offer">OFFER</div>
                                  ) : null}
                                  {premium.profile_image != null ? (
                                    <img
                                      src={premium.profile_image}
                                      alt=" Selected for You "
                                      className=" img-fluid "
                                    />
                                  ) : (
                                    <img
                                      src="assets/images/noimage.png"
                                      alt=" Selected for You "
                                      className=" img-fluid "
                                    />
                                  )}
                                </div>
                                <div className="text_wrap">
                                  <h6>
                                    {premium.verified == 1 ? (
                                      <span className="correct_icn">
                                        {premium.brand_name !== ""
                                          ? premium.brand_name
                                          : premium.name}
                                      </span>
                                    ) : (
                                      <span>
                                        {premium.brand_name !== ""
                                          ? premium.brand_name
                                          : premium.name}
                                      </span>
                                    )}
                                    {premium.total_ratings !== 0 ? (
                                      <span className="star">
                                        {premium.total_ratings}
                                        <span className="grey">/ 5</span>
                                      </span>
                                    ) : null}
                                  </h6>
                                  {premium.cuisines == "" ? (
                                    "-"
                                  ) : (
                                    <p className="sub_title">
                                      {premium.cuisines}
                                    </p>
                                  )}
                                  {premium.special_dishes != "" ? (
                                    <p>
                                      Known for cooking the best{" "}
                                      {premium.special_dishes}.
                                    </p>
                                  ) : (
                                    <p>
                                      Known for cooking the best Dosa's, Idli's,
                                      Vada's etc.
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {this.state.errorStatus ? (
                  <div className="not_found">
                    <div className="container text-center">
                      <img
                        src="assets/images/icons/not-found.svg"
                        alt="Not Found"
                        className="img-fluid"
                      />
                      <h6>SORRY! NO RESULTS FOUND.</h6>
                      <p>
                        We can’t find anything related to your <br /> search.
                        Try a different search.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>
          </div>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default PopularHomeChef;
