/*
#############################################################################
# Name : ChefOrderToAccept.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to fetch the chef order to accept data and displayed 
#############################################################################
*/
import React, { Component } from "react";
import LoadingDiv from "../../components/LoadingDiv";
import { Link } from "react-router-dom";

class ChefOrderToAccept extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { isLoading, toAcceptOrders, acceptBtnDisabled } = this.props;
    return (
      <div className="outer">
        {toAcceptOrders.length === 0 ? (
          <div className="not_found empty_section py-0">
            <div className="text-center">
              <img
                src="assets/images/icons/your-cart-is-empty.svg"
                alt="Not Found"
                className="img-fluid"
              />
              <h6 className="justify-content-center">NO ORDERS IN PROGRESS</h6>
              <Link className="btn" to="/chef-dashboard">
                go back to dashboard
              </Link>
            </div>
          </div>
        ) : (
          <div className="inner current_orders_card">
            {toAcceptOrders &&
              toAcceptOrders.map((order, i) => {
                return (
                  <div
                    className={
                      order.is_takeaway_selected == "1"
                        ? "order_wrap takeaway"
                        : "order_wrap"
                    }
                    key={"ktejx" + i}
                  >
                    <div className="order_title">
                      <h6 className="order_num">
                        <span>
                          {order.is_takeaway_selected == "1"
                            ? "Takeaway"
                            : "Delivery"}
                        </span>
                        Order Number : {"#" + order.order_number}
                      </h6>
                      <p className="accept_order">
                        Accept Order in :{" "}
                        <span className="time">
                          {order.hours > 9 ? order.hours : "0" + order.hours} :{" "}
                          {order.minutes > 9
                            ? order.minutes
                            : "0" + order.minutes}{" "}
                          :{" "}
                          {order.seconds > 9
                            ? order.seconds
                            : "0" + order.seconds}
                        </span>
                      </p>
                      {order.minutes <= 15 &&
                        order.hours == 0 &&
                        order.minutes !== 0 && (
                          <p className="auto_cancelled">
                            The order will be auto-cancelled if you do not
                            accept in the next {order.minutes} minutes.
                          </p>
                        )}
                      {order.minutes == 0 &&
                        order.hours == 0 &&
                        order.seconds !== 0 && (
                          <p className="auto_cancelled">
                            The order will be auto-cancelled if you do not
                            accept in the next {order.seconds} seconds.
                          </p>
                        )}
                      {order.hours == 0 &&
                        order.minutes == 0 &&
                        order.seconds == 0 && (
                          <p className="auto_cancelled">
                            The order has been cancelled automatically due to
                            timeout.
                          </p>
                        )}
                    </div>
                    <div className="save_add_wrap">
                      <div className="card">
                        <div className="box">
                          <h6>{order.first_name + " " + order.last_name}</h6>
                          <p className="add">{order.drop_location}</p>
                        </div>
                        <hr className="dash_line" />
                        <div className="box title_price">
                          {order.order_details &&
                            order.order_details.map((item, i) => {
                              return (
                                <h6 key={"wkxjed" + i}>
                                  <img
                                    src={
                                      item.diet_type === "Veg"
                                        ? "assets/images/icons/veg-icon.svg"
                                        : item.diet_type === "Non Veg"
                                        ? "assets/images/icons/non-veg.svg"
                                        : item.diet_type === "Contains Egg"
                                        ? "assets/images/icons/egg-icon.svg"
                                        : null
                                    }
                                    alt=""
                                  />
                                  {item.item_name} x {item.itemQuantity}
                                  {/* <span>INR {parseFloat(item.item_price) * parseFloat(item.itemQuantity)}</span> */}
                                </h6>
                              );
                            })}

                          {/* <p className="total_bill">
                            Total Bill : INR{" "}
                            {order.amount_paid ? order.amount_paid : "-"}{" "}
                            (including taxes and charges)
                          </p> */}
                          <p className="total_bill">
                            Amount : INR {order.dish_amount}{" "}
                          </p>
                          {order.coupon_discount_amount == "0.00" ||
                          order.coupon_discount_amount == null ? null : (
                            <p className="total_bill">
                              Discount Amount : INR{" "}
                              {order.coupon_discount_amount} (including taxes
                              and charges)
                            </p>
                          )}
                        </div>
                        {order.special_instructions &&
                          order.special_instructions.length > 0 && (
                            <hr className="dash_line" />
                          )}
                        {order.special_instructions &&
                          order.special_instructions.length > 0 && (
                            <div className="box">
                              <p className="request">
                                <img
                                  src="assets/images/icons/post-it.svg"
                                  alt="Post It"
                                />
                                Requested customization
                              </p>
                              <p className="request_text">
                                {order.special_instructions}
                              </p>
                            </div>
                          )}
                        <hr className="dash_line" />
                        <div className="box time_slot_wrap">
                          <p className="time_slot">
                            {order.is_takeaway_selected == "1"
                              ? "Takeaway"
                              : "Delivery"}{" "}
                            time slot
                          </p>
                          <div className="d-flex justify-content-between">
                            <h6>{order.selected_time_slot}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="btn-group can_itm_grp d-flex justify-content-end">
                        <button
                          type="button"
                          disabled={
                            acceptBtnDisabled ||
                            (order.hours == 0 &&
                              order.minutes == 0 &&
                              order.seconds == 0)
                          }
                          className="btn previous_btn cancel_btn"
                          onClick={() =>
                            this.props.handleRejectOrder(order.order_number)
                          }
                        >
                          Reject
                        </button>
                        <button
                          type="button"
                          disabled={
                            acceptBtnDisabled ||
                            (order.hours == 0 &&
                              order.minutes == 0 &&
                              order.seconds == 0)
                          }
                          className="btn"
                          onClick={() =>
                            this.props.handleAcceptOrder(order.order_number)
                          }
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  }
}

export default ChefOrderToAccept;
