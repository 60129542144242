/*
#############################################################################
# Name : ChefOrderInProgress.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to fetch the delivered order data and displayed 
#############################################################################
*/
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import WhatsAppButton from "../../components/whatsappButton";
import LoadingDiv from "../../components/LoadingDiv";
import ChefAccountSidebar from "./chefAccountSidebar";
import { chefDeliveredOrderAPI } from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Moment from "moment";
import { removeMobileView } from "../../redux/actions/chefMenuActions";

class DeliveredOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      deliveredOrders: [],
      hideContent: window.matchMedia("(max-width: 991px)").matches,
    };
  }
  componentDidMount() {
    this.chefDeliveredOrderAPI(); // Chef Delivered Order data is fetched
  }

  /* chefDeliveredOrderAPI function starts here */
  chefDeliveredOrderAPI = () => {
    return chefDeliveredOrderAPI(
      this.props.userData.userId,
      this.props.userData.accessToken
    )
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            deliveredOrders: response.data.data.order,
            isLoading: false,
          });
        } else if (response.data["status"] === 401) {
          NotificationManager.error(
            "Session expired. Please log in.",
            "Error!"
          );
          this.props.history.push("/diner-signin-with-otp");
          localStorage.clear();
        } else {
          NotificationManager.error(response.data.message, "Error!");
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
        this.setState({
          isLoading: false,
        });
      });
  };
  /* chefDeliveredOrderAPI function ends here */

  render() {
    if (this.props.userData.userType !== "Chef") {
      return (
        <Redirect
          to={
            "/signin-with-otp?redirect=" +
            this.props.location.pathname.split("/")[1]
          }
        />
      );
    }
    const { isLoading, deliveredOrders } = this.state;
    const { history, mobileView } = this.props;
    return (
      <div className={this.state.hideContent ? "css2 header_none" : "css2"}>
        {this.state.hideContent ? null : <Header />}
        <section className="chef_flow">
          <div className="container">
            <div className="row justify-content-center">
              <ChefAccountSidebar />
              <div
                className={
                  mobileView
                    ? "col-md-12 col-lg-8 col-xl-9 active"
                    : "col-md-12 col-lg-8 col-xl-9"
                }
                id="SidebarContent"
              >
                <div className="diner_inner_loading">
                  <div className="chef_right diner_my_acc">
                    <div className="sticky_wrap">
                      <h6 className="res_back_btn_wrap">
                        <a
                          onClick={() => this.props.removeMobileView()}
                          className="res_back_btn mobile_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </a>
                        <Link
                          to="/chef-dashboard"
                          className="res_back_btn desktop_sidebar_back_btn"
                        >
                          <img
                            src="assets/images/icons/left-arrow-black.svg"
                            alt="left-arrow-black"
                          />
                        </Link>
                        Delivered Orders
                      </h6>
                    </div>
                    <div className="outer">
                      {isLoading ? (
                        <LoadingDiv />
                      ) : !deliveredOrders.length ? (
                        <div className="text-center">
                          <img
                            src="assets/images/icons/no-delivered-orders.svg"
                            alt="Not Found"
                            className="img-fluid"
                          />
                          <h6 className="justify-content-center">
                            NO DELIVERED ORDERS
                          </h6>
                          <Link className="btn" to="/chef-dashboard">
                            go back to dashboard
                          </Link>
                        </div>
                      ) : (
                        <div className="inner current_orders_card">
                          {deliveredOrders.map((order, i) => {
                            return (
                              <div
                                className={
                                  order.is_takeaway_selected == "1"
                                    ? "order_wrap takeaway"
                                    : "order_wrap"
                                }
                                key={"ktejx" + i}
                              >
                                <div className="order_title">
                                  <h6 className="order_num">
                                    <span>
                                      {order.is_takeaway_selected == "1"
                                        ? "Takeaway"
                                        : "Delivery"}
                                    </span>
                                    Order Number : {"#" + order.order_number}
                                  </h6>
                                </div>
                                <div className="save_add_wrap">
                                  <div className="card">
                                    <div className="box">
                                      <h6>
                                        {order.first_name +
                                          " " +
                                          order.last_name}
                                      </h6>
                                      <p className="add">
                                        {order.drop_location}
                                      </p>
                                    </div>
                                    <hr className="dash_line" />
                                    <div className="box title_price">
                                      {order.order_details.map((item, i) => {
                                        return (
                                          <h6 key={"wkxjed" + i}>
                                            <img
                                              src={
                                                item.diet_type === "Veg"
                                                  ? "assets/images/icons/veg-icon.svg"
                                                  : item.diet_type === "Non Veg"
                                                  ? "assets/images/icons/non-veg.svg"
                                                  : item.diet_type ===
                                                    "Contains Egg"
                                                  ? "assets/images/icons/egg-icon.svg"
                                                  : null
                                              }
                                              alt=""
                                            />
                                            {item.item_name} x{" "}
                                            {item.itemQuantity}{" "}
                                            {/* <span>
                                              INR{" "}
                                              {parseFloat(item.item_price) *
                                                parseFloat(item.itemQuantity)}
                                            </span> */}
                                          </h6>
                                        );
                                      })}
                                      {/* <p className="total_bill">
                                        Total Bill : INR {order.amount_paid}{" "}
                                        (including taxes and charges)
                                      </p> */}
                                      <p className="total_bill">
                                        Amount : INR {order.dish_amount}{" "}
                                      </p>
                                      {order.coupon_discount_amount == "0.00" ||
                                      order.coupon_discount_amount ==
                                        null ? null : (
                                        <p className="total_bill">
                                          Discount Amount : INR{" "}
                                          {order.coupon_discount_amount}{" "}
                                          (including taxes and charges)
                                        </p>
                                      )}
                                    </div>
                                    <hr className="dash_line" />
                                    <div className="box time_slot_wrap">
                                      <p className="time_slot delivered">
                                        Delivered on :{" "}
                                        <span>
                                          {Moment(order.delivered_at).format(
                                            "MMM DD, hh:mm a"
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? null : <Footer />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(DeliveredOrders);
